import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, ButtonLink } from '../../../../components/Buttons';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../../components/W3Dropdown';
import ActionFilter from '../ActionFilter';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { STATUS_IDS } from '../../../../components/Utils';

export default class CardOrderMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedCardOrders: PropTypes.array,
    lockOrders: PropTypes.func.isRequired,
    exportOrders: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    actions: {},
    items: [],
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { selectedCardOrders, items } = props;
    const ids = selectedCardOrders ? selectedCardOrders.map(item => item.id) : [];
    let selected = [];
    if (items && items.length > 0) {
      selected = items.filter(item => ids.includes(item.id));
    }
    console.log('CardOrderMenu.getState()', selectedCardOrders, selected);
    const newItems = [];
    const editItems = [];
    selected.forEach(item => {
      if (item.status_id === STATUS_IDS.NEW) { newItems.push(item); }
      else if (item.status_id === STATUS_IDS.PROCESSING) { editItems.push(item); }
    });
    return { newItems, editItems };
  }
  onLockOrders = (event) => {
    if (event) event.preventDefault();
    const { lockOrders } = this.props;
    const { newItems } = this.state;
    this.taskDialog.show(
      true,
      {
        title: 'Neue Karten/Flyer-Bestellungen abnehmen',
        text: `
          Durch die Abnahme, werden alle offene Bestellungen in die Bearbeitung genommen und so für jegliche 
          Anpassung durch die Besteller gesperrt. Wollen Sie die ausgewählte Bestellung${newItems.length > 1 ? 'en' : ''} nun abnehmen?
        `
      },
      () => lockOrders(newItems)
    );
  }
  onExport = (event) => {
    if (event) event.preventDefault();
    const { selectedCardOrders } = this.props;
    let counter = 0;
    if (selectedCardOrders && selectedCardOrders.length > 0) {
      counter += selectedCardOrders.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `Karten/Flyer-Bestellungen exportieren`,
        text: `
          Alle ${counter > 0 ? 'ausgewählte ' : ''}Bestellungen werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `,
      },
      () => this.props.exportOrders({})
    );
  }
  render() {
    const { className/* , selectedCardOrders */ } = this.props;
    const isExportable = true; // (selectedCardOrders && selectedCardOrders.length > 0);
    const {
      newItems,
      // editItems,
    } = this.state;
    // console.log('CardOrderMenu.render()', this.state);

    const isLockable = (newItems.length > 0);
    // const isApprovable = (editItems.length > 0);
    // const isCSVDownloadable = (sentItems.length > 0);

    return (
      <div className={className}>
        <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler as={PrimaryButton} size="small-">{`Optionen`}</W3DropdownToggler>
          <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '280px'}}>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onLockOrders}
              disabled={!isLockable}>
                {`Bestellungen abnehmen`}
            </ButtonLink>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onExport}
              disabled={!isExportable}>
                {`Bestellungen exportieren (CSV)`}
            </ButtonLink>
            {/* <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              to={`/wizard/aufgabe/import/device`}
              disabled={false}>
                {`Bestellungen importieren (CSV)`}
            </ButtonLink> */}
          </W3DropdownContent>
        </W3Dropdown>
        <ConfirmationDialog ref={ref => this.taskDialog = ref}/>
      </div>
    );
  }
}
