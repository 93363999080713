import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SecondaryButton } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import { toUIDate, showFile, CoverText, TableEmptyMessage } from '../../../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';


export default class TechKeyListPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, model, rows } = this.props;
    const { first } = this.state;
    const fileBtnTemplate = (rowData) => {
      let btnMarkup = null;
      if (rowData.path) {
        const id = `pathBtn${rowData.index}`;
        btnMarkup = (
          <SecondaryButton
            id={id}
            size="small"
            onClick={() => showFile(rowData.path)}
          >
            {`Datei`}
          </SecondaryButton>
        );
      }
      return (<div>{btnMarkup}</div>);
    };
    const renderItemTable = (items, actionTemplate) => {
      if (items.length === 0) {
        return (<CoverText>{'Ihnen wurden noch keine Technikerschlüssel zugewiesen'}</CoverText>);
      }
      const data = items.map((item, index) => ({
        ...item,
        index,
        createdDate: toUIDate(item.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
      }));
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Schließmittel"
              itemName="Schließmittel"
              itemNamePlural="Schließmittel"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Schließmittel" min/>)}
        >
          <Column
            field="name"
            header="Schließmittel"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            body={item => (item.name || item.productName)}
          />  
          <Column
            field="createdDate"
            header="erstellt am"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            body={fileBtnTemplate}
            header="Übergabevereinbarung"
            style={{textAlign:'center', width: '14em'}}
          />
          {/* <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '6em'}}
          /> */}
        </DataTable>
      );
    };
    return (
      <ItemListManager
        className={className}
        title={(<h3 className="no-margin-top">Technikerschlüssel</h3>)}
        addBtnHidden
        items={model}
        getItemFormTitle={() => (<div/>)}
        renderItemTable={renderItemTable}
        renderItemForm={() => (<div/>)}
        namePrefix="techKey"
      />
    );
  }
}
