import * as actionTypes from './actions';
import isArray from 'lodash/isArray';
import { openFile, notifySuccess, notifyError, ACTION_IDS, STATUS_IDS, isDeviceManager } from '../../components/Utils';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  devices: [],
  device: null,
  userDevices: [],
  userDevice: null,
  loaded: false,
  
  deviceManagerCompanies: [],
  deviceManagers: [],
  deviceUserCompanies: [],
  deviceUsers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load devices
    case actionTypes.LOAD_DEVICES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        devices: action.result
      };
    case actionTypes.LOAD_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        devices: [],
        error: action.result
      };
    // load device
    case actionTypes.LOAD_DEVICE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.LOAD_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        device: (isArray(action.result) ? action.result[0] : action.result)
      };
    case actionTypes.LOAD_DEVICE_FAILURE:
      return {
        ...state,
        loading: false,
        device: null,
        error: action.result
      };
    // save device
    case actionTypes.SAVE_DEVICE:
        return {
          ...state,
          saving: true
        };
    case actionTypes.SAVE_DEVICE_SUCCESS:
      notifySuccess({
        summary: 'Referenzmessgeräte',
        detail: `Das Messgerät ${action.device.name} wurde erfolgreich gespeichert!`
      });
      return {
        ...state,
        device: null,
        saving: false
      };
    case actionTypes.SAVE_DEVICE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // cancel device
    case actionTypes.CANCEL_DEVICE_WIZARD:
      return {
        ...state,
        device: null
      };
    // select device
    case actionTypes.SELECT_DEVICE:
      return {
        ...state,
        selectedDevice: action.device,
      };
    // deselect device
    case actionTypes.DESELECT_DEVICE:
      return {
        ...state,
        selectedDevice: null,
        dependentUserDevices: [],
      };
    // load dependent user devices
    case actionTypes.LOAD_DEPENDENT_USER_DEVICES:
      return {
        ...state,
        selectedDevice: action.device,
        isLoading: true
      };
    case actionTypes.LOAD_DEPENDENT_USER_DEVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dependentUserDevices: action.result,
      };
    case actionTypes.LOAD_DEPENDENT_USER_DEVICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    // delete device
    case actionTypes.DELETE_DEVICE:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.DELETE_DEVICE_SUCCESS:
      notifySuccess({
        summary: `Referenzmessgerät löschen`,
        detail: `Das Referenzmessgerät ${action.device.name} wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        isLoading: false,
        selectedDevice: null,
        dependentUserDevices: [],
      };
    case actionTypes.DELETE_DEVICE_FAILURE:
      notifyError({
        summary: `Referenzmessgerät löschen`,
        detail: `Das Referenzmessgerät ${action.device.name} konnte nicht gelöscht werden.`
      });
      return {
        ...state,
        isLoading: false
      };
    // load user devices
    case actionTypes.LOAD_USER_DEVICES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_USER_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        userDevices: action.result
      };
    case actionTypes.LOAD_USER_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        userDevices: [],
        error: action.result
      };
    // load user device
    case actionTypes.LOAD_USER_DEVICE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.LOAD_USER_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        userDevice: (isArray(action.result) ? action.result[0] : action.result)
      };
    case actionTypes.LOAD_USER_DEVICE_FAILURE:
      return {
        ...state,
        loading: false,
        userDevice: null,
        error: action.result
      };
    // save user device
    case actionTypes.SAVE_USER_DEVICE:
        return {
          ...state,
          saving: true
        };
    case actionTypes.SAVE_USER_DEVICE_SUCCESS:
      if (action.device.status_id === STATUS_IDS.DELETING) {
        notifySuccess({
          summary: 'Messgeräte löschen',
          detail: `Die Löschung des Messgerätes ${action.device.device} wurde erfolgreich beantragt!`
        });
      } else {
        notifySuccess({
          summary: 'Messgeräte',
          detail: `Das Messgerät ${action.device.device} wurde erfolgreich gespeichert!`
        });
      }
      return {
        ...state,
        userDevice: null,
        deviceManagers: [],
        deviceUsers: [],
        saving: false
      };
    case actionTypes.SAVE_USER_DEVICE_FAILURE:
      if (action.device.status_id === STATUS_IDS.DELETING) {
        notifyError({
          summary: 'Messgeräte löschen',
          detail: `Die Löschung des Messgerätes ${action.device.device} konnte nicht beantragt werden!`
        });
      } else {
        notifyError({
          summary: 'Messgeräte',
          detail: `Das Messgerät ${action.device.device} konnte nicht gespeichert werden!`
        });
      }
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // delete user device
    case actionTypes.DELETE_USER_DEVICE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.DELETE_USER_DEVICE_SUCCESS: {
      const userDevice = action.device;
      let username = '';
      if (userDevice.userPrimary && userDevice.userPrimary !== '') {
        username = ', Hauptbenutzer: ' + userDevice.userPrimary;
      }
      notifySuccess({
        summary: 'Messgerät löschen',
        detail: `Das Messgerät ${userDevice.device} (Seriennummer: ${userDevice.serialNumber}${username}) wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.DELETE_USER_DEVICE_FAILURE: {
      const userDevice = action.device;
      let username = '';
      if (userDevice.userPrimary && userDevice.userPrimary !== '') {
        username = ', Hauptbenutzer: ' + userDevice.userPrimary;
      }
      notifyError({
        summary: 'Messgerät löschen',
        detail: `Das Messgerät ${userDevice.device} (Seriennummer: ${userDevice.serialNumber}${username}) konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    }
    // cancel user device
    case actionTypes.CANCEL_USER_DEVICE_WIZARD:
      return {
        ...state,
        userDevice: null,
        deviceManagers: [],
        deviceUsers: [],
      };
    // load userDevice companies
    case actionTypes.LOAD_USER_DEVICE_COMPANIES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_USER_DEVICE_COMPANIES_SUCCESS: {
      console.log('REDUX REDUCER user device companies loaded:', action);
      let companies = action.result && isArray(action.result) ? action.result : [];
      const key = action.isManager ? 'deviceManagerCompanies' : 'deviceUserCompanies';
      // if (!action.isManager && action.companyId !== 0) {
      //   companies = companies.filter(item => (
      //     // (item.id === action.companyId) || (item.contractGivers && item.contractGivers.includes(action.companyId))
      //     (item.id === action.companyId) || (item.parent_id && item.parent_id === action.companyId)
      //   ));
      // }
      return {
        ...state,
        loading: false,
        [key]: companies,
        deviceManagers: action.deviceManagers ? action.deviceManagers : (state.deviceManagers || [])
      };
    }
    case actionTypes.LOAD_USER_DEVICE_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // load userDevice workers
    case actionTypes.LOAD_USER_DEVICE_WORKERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_USER_DEVICE_WORKERS_SUCCESS: {
      console.log('REDUX REDUCER user device workers loaded:', action.result, action);
      const { result, isManager/* , companyId, currentUser  */} = action;
      const workers = (result && isArray(result) ? result : []).filter(item => {
        const isValid = isManager ? isDeviceManager(item) : true;
        return isValid;
        // const companies = item.companies.map(company => company.id);
        // return companies.includes(companyId) && isValid;
      });
      const key = isManager ? 'deviceManagers' : 'deviceUsers';
      // // check if currentUser (device manager or Admin) works for the chosen company
      // if (!isManager && currentUser && companyId === currentUser.company) {
      //   // add current user to the company workers if absent
      //   // case: current user (device manager) works for a simple company and cannot see its workers
      //   const found = workers.find(item => item.id === currentUser.id);
      //   if (!found) {
      //     workers.push({ ...currentUser });
      //   }
      // }
      return {
        ...state,
        loading: false,
        [key]: workers
      };
    }
    case actionTypes.LOAD_USER_DEVICE_WORKERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // load devices
    case actionTypes.LOAD_TECH_DEVICES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_TECH_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        techDevices: action.result
      };
    case actionTypes.LOAD_TECH_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        techDevices: [],
        error: action.result
      };
    // export companies
    case actionTypes.EXPORT_USER_DEVICES:
      return {
        ...state,
        exporting: true,
      }
    case actionTypes.EXPORT_USER_DEVICES_SUCCESS: {
      if (action.result.filePath) {
        notifySuccess({
          summary: `Messgeräte exportieren`,
          detail: `Die Messgeräte wurden erfolgreich exportiert!`
        });
        openFile(action.result.filePath);
      }
      return {
        ...state,
        exporting: false
      }
    }
    case actionTypes.EXPORT_USER_DEVICES_FAILURE:
      notifyError({
        summary: `Messgeräte exportieren`,
        detail: `Die Messgeräte konnten nicht exportiert werden!`
      });
      return {
        ...state,
        exporting: false,
        error: action.error
      }
    case LOGOUT_SUCCESS:
    case actionTypes.RESET:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
