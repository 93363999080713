import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label, Dropdown } from '../../../components/Inputs';


export default class UserTechDeviceForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    techDevices: PropTypes.array,
    companies: PropTypes.array
  }
  static defaultProps = {
    className: '',
    model: {
      companyName: '',
      device: '',
      serialNumber: '',
      type: '',
      comment: ''
    },
    techDevices: [],
    companies: [],
  }
  constructor(props) {
    super(props);
    const { model, companies } = props;
    if (model.companyName === '' && companies.length === 1) {
      model.companyName = companies[0].name;
    }
    this.state = {
      companyName: model.companyName || '',
      device: model.device || '',
      serialNumber: model.serialNumber || '',
      type: model.type || '',
      comment: model.comment || '',
      error: {
        device: null,
        serialNumber: null,
        type: null,
        // lastCheckDate: null,
      }
    };
  }
  validate = () => {
    const { error, optionList, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    const { id, index } = this.props.model;
    return { id, index, ...model, comment: model.comment || '' };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    // console.log('UserTechDeviceForm.render()', this.state);
    const { className, techDevices, companies } = this.props
    const { companyName, device, serialNumber, type, comment, error } = this.state;
    const CompanyOptions = companies.map(item => ({ label: item.name, value: item.name }));
    const DeviceOptions = techDevices.map(item => ({ label: item, value: item }));
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top- form-group-item ${error.device ? 'w3-border-red' : ''}`}>
              <Label htmlFor="companyName">{`Firma*`}</Label>
              <Dropdown
                id="companyName"
                className="w3-block w3-border"
                value={companyName}
                placeholder="Bitte auswählen"
                options={CompanyOptions}
                onChange={(event) => this.setState({companyName: event.value})}
                showClear
              />
            </div>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.device ? 'w3-border-red' : ''}`}>
              <Label htmlFor="device">{`Gerät*`}</Label>
              <Dropdown
                id="device"
                className="w3-block w3-border"
                value={device}
                placeholder="Bitte auswählen"
                options={DeviceOptions}
                onChange={(event) => this.setState({device: event.value})}
                showClear
              />
            </div>
            <div className={`pad-top form-group-item ${error.type ? 'w3-border-red' : ''}`}>
              <Label htmlFor="type">{`Typ*`}</Label>
              <InputText id="type"className="w3-block w3-border" value={type}
                onChange={(event) => this.setState({type: event.target.value})}/>
            </div>
            <div className={`pad-top form-group-item ${error.serialNumber ? 'w3-border-red' : ''}`}>
              <Label htmlFor="serialNumber">{`Seriennummer*`}</Label>
              <InputText id="serialNumber" className="w3-block w3-border" value={serialNumber}
                onChange={(event) => this.setState({serialNumber: event.target.value})}/>
            </div>
          </div>
          <div className="w3-col s6 pad-top pad-lr">
            <Label htmlFor="comment">{`Bemerkungen`}</Label>
            <InputTextarea
              id="comment"
              className="w3-block w3-border"
              value={comment}
              style={{height: '183px'}}
              autoResize
              onChange={(event) => this.setState({comment: event.target.value})}
            />
          </div>
        </div>
      </div>
    );
  }
}
