import { getLinkParentUrl, getLinkArchiveUrl } from "../../components/Utils";
import { isProductPage } from "./components/Utils";

export const LOAD_TREE = 'tree/load';
export const LOAD_TREE_SUCCESS = 'tree/load/success';
export const LOAD_TREE_FAILURE = 'tree/load/failure';

export const EDIT_PAGE = '/html/edit';
export const CANCEL_PAGE = '/html/cancel';

export const LOAD_HTML = 'html/load';
export const LOAD_HTML_SUCCESS = 'html/load/success';
export const LOAD_HTML_FAILURE = 'html/load/failure';

export const SAVE_HTML = 'html/save';
export const SAVE_HTML_SUCCESS = 'html/save/success';
export const SAVE_HTML_FAILURE = 'html/save/failure';


export const CREATE_PATH = 'uri/create';
export const CANCEL_PATH = 'uri/cancel';

export const SAVE_PATH = 'uri/save';
export const SAVE_PATH_SUCCESS = 'uri/save/success';
export const SAVE_PATH_FAILURE = 'uri/save/failure';


export const SHOW_PATHMOVEMENT_DIALOG = 'uri/move/show';
export const HIDE_PATHMOVEMENT_DIALOG = 'uri/move/hide';

export const UPDATE_PATH = 'uri/update';
export const UPDATE_PATH_SUCCESS = 'uri/update/success';
export const UPDATE_PATH_FAILURE = 'uri/update/failure';


export const loadTree = ({ pathname }, onSuccess) => {
  console.log(`REDUX-ACTION: loadTree(${pathname}) ...`);
  return {
    types: [
      LOAD_TREE,
      LOAD_TREE_SUCCESS,
      LOAD_TREE_FAILURE
    ],
    promise: (client) => client.get(`/pages/tree/${encodeURIComponent(pathname)}`),
    onSuccess: () => onSuccess && onSuccess(),
    pathname: pathname.replace('/', ''),
  };
};

export const createOrUpdatePagePath = ({ parentUrl, linkData, config = {} }) => {
  console.log(`REDUX-ACTION: createOrUpdatePagePath(${parentUrl || ''})`, linkData);
  return { type: CREATE_PATH, parentUrl, linkData, config };
};

export const savePagePath = ({ data, type, isProductDetailsPage }, onSuccess) => {
  console.log(`REDUX-ACTION: savePagePath()`, data);
  return {
    types: [
      SAVE_PATH,
      SAVE_PATH_SUCCESS,
      SAVE_PATH_FAILURE
    ],
    promise: (client) => client.put(`/pages/tree`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadTree({ pathname: 'main' }, onSuccess));
    },
    data,
    isProductPage: isProductPage(type) && !isProductDetailsPage
  };
};

export const savePathToArchive = ({ linkData, type }, onSuccess) => {
  console.log(`REDUX-ACTION: savePathToArchive()`, linkData, type);
  const { id, label, url } = linkData;
  const data = (linkData.data || {});
  const model = {
    id,
    label,
    url,
    is_archived: 1,
    data: {
      ...data,
      archiveUrl: getLinkArchiveUrl(url)
    }
  };
  return {
    types: [
      SAVE_PATH,
      SAVE_PATH_SUCCESS,
      SAVE_PATH_FAILURE
    ],
    promise: (client) => client.put(`/pages/tree`, { data: model }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadTree({ pathname: 'main' }));
    },
    data: linkData,
    savingToArchive: true
  };
}

export const cancelPagePath = () => {
  console.log(`REDUX-ACTION: cancelPagePath() ...`);
  return { type: CANCEL_PATH };
};



export const loadContent = ({ key, label }) => {
  console.log(`REDUX-ACTION: loadContent(${key}) ...`);
  return {
    types: [
      LOAD_HTML,
      LOAD_HTML_SUCCESS,
      LOAD_HTML_FAILURE
    ],
    promise: (client) => client.get(`/pages/${encodeURIComponent(key)}`),
    key,
    label
  };
};

export const saveContent = (data) => {
  console.log('REDUX ACTION saveContent:', data);
  const { pathname } = data;
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const model = {
      ...data,
      modified_by_user_id: user.id,
      created_by_user_id: user.id
    };
    if (model.contents) {
      model.contents = model.contents.map(item => ({
        ...item,
        modified_by_user_id: user.id,
        created_by_user_id: user.id
      }));
    }
    dispatch({
      types: [SAVE_HTML, SAVE_HTML_SUCCESS, SAVE_HTML_FAILURE],
      promise: (client) => client.put(`/pages`, { data: model }),
      onSuccess: dispatch => dispatch(loadContent({ key: pathname })),
      key: pathname
    });
  }
};

export const editPageContent = () => ({ type: EDIT_PAGE });
export const cancelPageContent = () => ({ type: CANCEL_PAGE });


export const showPathMovementDialog = ({ linkData, treeLinks }) => {
  console.log(`REDUX-ACTION: showPathMovementDialog()`, linkData);
  return { type: SHOW_PATHMOVEMENT_DIALOG, linkData, treeLinks };
};

export const hidePathMovementDialog = () => {
  console.log(`REDUX-ACTION: hidePathMovementDialog() ...`);
  return { type: HIDE_PATHMOVEMENT_DIALOG };
};

export const updateSubTree = ({ linkData, to }, onSuccess) => {
  console.log(`REDUX-ACTION: updateSubTree()`, linkData, to);
  const data = { node: linkData, to };
  return {
    types: [
      UPDATE_PATH,
      UPDATE_PATH_SUCCESS,
      UPDATE_PATH_FAILURE
    ],
    promise: (client) => client.put(`/pages/tree/move`, { data }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadTree({ pathname: 'main' }));
    },
    data: linkData,
    savingToArchive: true
  };
};
