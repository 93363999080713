import * as Components from './components';
import Reducer from './reducer';
import WorkerTableModule from './containers/WorkerTable';
import WorkerWizardModule from './containers/WorkerWizard';
import WorkerPanelModule from './containers/WorkerPanel';

export const WorkerListMenu = Components.WorkerListMenu;
export const WorkerTable = WorkerTableModule;
export const WorkerWizard = WorkerWizardModule;
export const WorkerPanel = WorkerPanelModule;
export const workerReducer = Reducer;
