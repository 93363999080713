import { loadCompanies } from "../../Company/actions";
import { loadCurrentWorker } from "../../Worker/actions";

export const SHOW_SETTINGS = 'form/settings/show';
export const HIDE_SETTINGS = 'form/settings/hide';

export const LOAD_SETTINGS = 'form/settings/load';
export const LOAD_SETTINGS_SUCCESS = 'form/settings/load/success';
export const LOAD_SETTINGS_FAILURE = 'form/settings/load/failure';

export const SAVE_SETTINGS = 'form/settings/save';
export const SAVE_SETTINGS_SUCCESS = 'form/settings/save/success';
export const SAVE_SETTINGS_FAILURE = 'form/settings/save/failure';

export const LOAD_BOOKING_FORM_DATA = 'form/booking/formData/load';
export const LOAD_BOOKING_FORM_DATA_SUCCESS = 'form/booking/formData/load/success';
export const LOAD_BOOKING_FORM_DATA_FAILURE = 'form/booking/formData/load/failure';

export const SAVE_BOOKING = 'form/booking/save';
export const SAVE_BOOKING_SUCCESS = 'form/booking/save/success';
export const SAVE_BOOKING_FAILURE = 'form/booking/save/failure';

export const LOAD_MNVTIP_FORM_DATA = 'form/mnvTip/formData/load';
export const LOAD_MNVTIP_FORM_DATA_SUCCESS = 'form/mnvTip/formData/load/success';
export const LOAD_MNVTIP_FORM_DATA_FAILURE = 'form/mnvTip/formData/load/failure';

export const SAVE_MNVTIP = 'form/mnvTip/save';
export const SAVE_MNVTIP_SUCCESS = 'form/mnvTip/save/success';
export const SAVE_MNVTIP_FAILURE = 'form/mnvTip/save/failure';


export const showSettings = ({ key }) => {
  console.log(`REDUX-ACTION: showSettings(${key})`);
  return { type: SHOW_SETTINGS, key };
};


export const hideSettings = ({ key }) => {
  console.log(`REDUX-ACTION: hideSettings(${key})`);
  return { type: HIDE_SETTINGS, key };
};


export const loadSettings = ({ key }) => {
  console.log(`REDUX-ACTION: loadSettings(${key})`);
  return {
    types: [
      LOAD_SETTINGS,
      LOAD_SETTINGS_SUCCESS,
      LOAD_SETTINGS_FAILURE
    ],
    promise: (client) => client.get(`/sales/forms/${key}/settings`),
    key
  }
};


export const saveSettings = ({ key, data }) => {
  console.log(`REDUX-ACTION: saveSettings for ${key}`, data);
  return {
    types: [
      SAVE_SETTINGS,
      SAVE_SETTINGS_SUCCESS,
      SAVE_SETTINGS_FAILURE
    ],
    promise: (client) => client.post(`/sales/forms/${key}/settings`, { data }),
    key
  }
};


export const initBookingForm = () => {
  console.log(`REDUX-ACTION: initBookingForm()`);
  return (dispatch) => {
    const fetch = () => {
      return dispatch({
        types: [
          LOAD_BOOKING_FORM_DATA,
          LOAD_BOOKING_FORM_DATA_SUCCESS,
          LOAD_BOOKING_FORM_DATA_FAILURE
        ],
        promise: (client) => client.get(`/sales/forms/booking/init`)
      });
    }
    dispatch(loadCurrentWorker());
    return dispatch(loadCompanies(fetch));
  }
};


export const saveBooking = ({ data }, onSuccess) => {
  console.log(`REDUX-ACTION: saveBooking()`, data);
  return {
    types: [
      SAVE_BOOKING,
      SAVE_BOOKING_SUCCESS,
      SAVE_BOOKING_FAILURE
    ],
    promise: (client) => client.post('/sales/forms/booking', { data }),
    onSuccess: () => onSuccess && onSuccess()
  };
};


export const initMNVTipForm = () => {
  console.log(`REDUX-ACTION: initMNVTipForm()`);
  return (dispatch) => {
    const fetch = () => {
      return dispatch({
        types: [
          LOAD_MNVTIP_FORM_DATA,
          LOAD_MNVTIP_FORM_DATA_SUCCESS,
          LOAD_MNVTIP_FORM_DATA_FAILURE
        ],
        promise: (client) => client.get(`/sales/forms/mnvTip/init`),
      });
    }
    return dispatch(loadCompanies(fetch));
  }
};


export const saveMNVTip = ({ data }, onSuccess) => {
  console.log(`REDUX-ACTION: saveMNVTip()`, data);
  return {
    types: [
      SAVE_MNVTIP,
      SAVE_MNVTIP_SUCCESS,
      SAVE_MNVTIP_FAILURE
    ],
    promise: (client) => client.post('/sales/forms/mnvTip', { data }),
    onSuccess: () => onSuccess && onSuccess()
  };
};
