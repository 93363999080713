import isArray from 'lodash/isArray';
import { isMasterCompany, encodeObjectToBase64, isWorker } from '../../components/Utils';

export const LOAD_COMPANY_TYPES = 'company/types/load';
export const LOAD_COMPANY_TYPES_SUCCESS = 'company/types/load/success';
export const LOAD_COMPANY_TYPES_FAILURE = 'company/types/load/failure';

export const LOAD_COMPANIES = 'companies/load';
export const LOAD_COMPANIES_SUCCESS = 'companies/load/success';
export const LOAD_COMPANIES_FAILURE = 'companies/load/failure';

export const LOAD_CONTRACTORS = 'companies/contractors/load';
export const LOAD_CONTRACTORS_SUCCESS = 'companies/contractors/load/success';
export const LOAD_CONTRACTORS_FAILURE = 'companies/contractors/load/failure';

export const LOAD_COMPANY = 'company/load';
export const LOAD_COMPANY_SUCCESS = 'company/load/success';
export const LOAD_COMPANY_FAILURE = 'company/load/failure';

export const LOAD_TECH_KEYS = 'company/techKeys/load';
export const LOAD_TECH_KEYS_SUCCESS = 'company/techKeys/load/success';
export const LOAD_TECH_KEYS_FAILURE = 'company/techKeys/load/failure';

export const CHANGE_COMPANY = 'company/change';

export const INIT_COMPANY_WIZARD = 'company/init';
export const CANCEL_COMPANY_WIZARD = 'company/cancel';

export const SAVE_COMPANY = 'company/save';
export const SAVE_COMPANY_SUCCESS = 'company/save/success';
export const SAVE_COMPANY_FAILURE = 'company/save/failure';

export const ACTIVATE_COMPANY = 'company/activate';
export const ACTIVATE_COMPANY_SUCCESS = 'company/activate/success';
export const ACTIVATE_COMPANY_FAILURE = 'company/activate/failure';

export const DEACTIVATE_COMPANY = 'company/deactivate';
export const DEACTIVATE_COMPANY_SUCCESS = 'company/deactivate/success';
export const DEACTIVATE_COMPANY_FAILURE = 'company/deactivate/failure';

export const DELETE_COMPANY = 'company/delete';
export const DELETE_COMPANY_SUCCESS = 'company/delete/success';
export const DELETE_COMPANY_FAILURE = 'company/delete/failure';

export const EXPORT_COMPANIES = 'companies/export';
export const EXPORT_COMPANIES_SUCCESS = 'companies/export/success';
export const EXPORT_COMPANIES_FAILURE = 'companies/export/failure';

export const EXPORT_SUBCOMPANIES = 'subcompanies/export';
export const EXPORT_SUBCOMPANIES_SUCCESS = 'subcompanies/export/success';
export const EXPORT_SUBCOMPANIES_FAILURE = 'subcompanies/export/failure';

const DEFAULT_CONTRACT_GIVER = 1075;
const DEFAULT_CONTRACT_GIVER_NAME = 'Vodafone Kabel Deutschland GmbH';
export const DUMMY_COMPANY = {
  name: '',
  type_id: 4,
  address: {
    street: '',
    houseNumber: '',
    areaCode: '',
    city: ''
  },
  groups: [],
  techKeys: [],
  regions: [],
  contractGivers: [],
  contractGiver: DEFAULT_CONTRACT_GIVER,
  contractGiverName: DEFAULT_CONTRACT_GIVER_NAME,
};

export const defaultFilter = {
  first: 0,
  rows: 10,
  name: null,
  contractGiver: null,
  typeName: null,
  address: null,
  status_id: null,
  activeEmployees: null,
  sortFields: {},
};

export const loadCompanyTypes = ( type, onSuccess ) => {
  console.log('REDUX-ACTION: loadCompanyTypes()', type, onSuccess ? 'onSucces given' : '');
  return (dispatch, getState) => {
    const company = getState().company.company;
    console.log('company:', company);
    const fetch = () => {
      const types = getState().company.types;
      if (isArray(types) && types.length > 0) {
        console.log('<< Company Types already fetched => skipping...');
        return dispatch({
          type: LOAD_COMPANY_TYPES_SUCCESS,
          result: types,
          isMasterCompany: isMasterCompany(company),
          companyType: company ? company.type_id : null
        });
      }
      return dispatch({
        types: [ LOAD_COMPANY_TYPES, LOAD_COMPANY_TYPES_SUCCESS, LOAD_COMPANY_TYPES_FAILURE ],
        promise: client => client.get(`/companies/types/${type || 'null'}`),
        onSuccess: () => onSuccess && onSuccess(),
        isMasterCompany: isMasterCompany(company),
        companyType: company ? company.type_id : null
      });
    }
    if (!company) {
      return dispatch(loadCurrentCompany(fetch));
    }
    return fetch();
  }
}

export const loadTechKeys = () => {
  return {
    types: [
      LOAD_TECH_KEYS,
      LOAD_TECH_KEYS_SUCCESS,
      LOAD_TECH_KEYS_FAILURE
    ],
    promise: (client) => client.get(`/companies/0/technicianKeys`)
  };
};

export const loadCompanies = (onSuccess, filter, isKdl) => {
  console.log('REDUX-ACTION: loadCompanies() ... filter :', filter);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin && !user.company && !(user.companies && user.companies.length > 0)) {
      dispatch({
        type: LOAD_COMPANIES_FAILURE,
        result: { message: 'Missing user company!' }
      });
      return;
    }
    const company = getState().company.company;
    dispatch({
      types: [
        LOAD_COMPANIES,
        LOAD_COMPANIES_SUCCESS,
        LOAD_COMPANIES_FAILURE
      ],
      promise: (client) => client.get(`/companies/0/summaries?related=true&form=true${isKdl ? "&kdl=true" : ""}`),
      onSuccess: () => onSuccess && onSuccess(),
      isMasterCompany: isMasterCompany(company)
    });
  }
};

export const loadFilteredCompanies = (onSuccess, filter = defaultFilter, isKdl = false) => {
  console.log('REDUX-ACTION: loadFilteredCompanies() ... filter :', filter);
  return (dispatch, getState) => {
    const { user, isAdmin, isWorkerLogisticsAssistant } = getState().auth;
    const { company } = getState().company;
    // const company = getState().company.company;
    // const user = getState().auth.user;
    // const isAdmin = getState().auth.isAdmin;
    if (!isAdmin && !user.company && !(user.companies && user.companies.length > 0)) {
      dispatch({
        type: LOAD_COMPANIES_FAILURE,
        result: { message: 'Missing user company!' }
      });
      return;
    }
    // case of simple worker who is also logistics assistant
    if (isWorkerLogisticsAssistant && company.contractGivers && company.contractGivers.length > 0) {
      dispatch({
        type: LOAD_COMPANIES_SUCCESS,
        result: [{
          ...company.contractGivers[0],
          address: `
            ${company.contractGivers[0].street} ${company.contractGivers[0].house}, 
            ${company.contractGivers[0].areacode} ${company.contractGivers[0].city}`
        }]
      });
      return;
    }
    let filterJsonStringBase64 = '';
    if (filter) {
      filterJsonStringBase64=encodeObjectToBase64(filter);
    }
    let companyId = 0;
    if (isWorker(user) && company.contractGivers && company.contractGivers.length > 0) {
      companyId = company.contractGivers[0].id || 0;
    }
    dispatch({
      types: [
        LOAD_COMPANIES,
        LOAD_COMPANIES_SUCCESS,
        LOAD_COMPANIES_FAILURE
      ],
      promise: (client) => client.get(`/companies/${companyId}/summaries?related=true&filter=${filterJsonStringBase64}${isKdl ? "&kdl=true" : ""}`),
      onSuccess: () => onSuccess && onSuccess(),
      isMasterCompany: isMasterCompany(company)
    });
  }
};

export const loadContractors = (onSuccess) => {
  console.log('REDUX-ACTION: loadContractors() ...');
  // return {
  //   types: [
  //     LOAD_CONTRACTORS,
  //     LOAD_CONTRACTORS_SUCCESS,
  //     LOAD_CONTRACTORS_FAILURE
  //   ],
  //   promise: (client) => client.get(`/companies/contractors`),
  //   onSuccess: () => onSuccess && onSuccess()
  // };
  return (dispatch, getState) => {
    const { user, isAdmin, isWorkerLogisticsAssistant } = getState().auth;
    const { company } = getState().company;
    // case of simple worker who is also logistics assistant
    if (isWorkerLogisticsAssistant && company.contractGivers && company.contractGivers.length > 0) {
      dispatch({
        type: LOAD_COMPANIES_SUCCESS,
        result: [{ ...company }]
      });
      return;
    }
    // normal case
    dispatch({
      types: [
        LOAD_CONTRACTORS,
        LOAD_CONTRACTORS_SUCCESS,
        LOAD_CONTRACTORS_FAILURE
      ],
      promise: (client) => client.get(`/companies/contractors`),
      onSuccess: () => onSuccess && onSuccess()
    });
  };
};

export const loadCompany = ({ id, wizard }, onSuccess) => {
  console.log(`REDUX-ACTION: loadCompany(id:${encodeURIComponent(id)}, wizard:${wizard || false}) ...`);
  return (dispatch, getState) => {
    if (id === 'neu') {
      const company = getState().company.company;
      let contractGiver = DEFAULT_CONTRACT_GIVER;
      if (company && !isMasterCompany(company)) {
        contractGiver = company.id;
      }
      return dispatch({
        type: LOAD_COMPANY_SUCCESS,
        result: { ...DUMMY_COMPANY, contractGiver },
        onSuccess: () => onSuccess && onSuccess(),
        wizard
      });
    }
    return dispatch({
      types: [
        LOAD_COMPANY,
        LOAD_COMPANY_SUCCESS,
        LOAD_COMPANY_FAILURE
      ],
      promise: (client) => client.get(`/companies/${id}`),
      onSuccess: () => onSuccess && onSuccess(),
      wizard
    });
  };
};

export const changeCompany = (company) => {
  return {
    type: CHANGE_COMPANY,
    result: company
  };
}

export const loadCurrentCompany = (onSuccess) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    dispatch(loadCompany({ id: user.companyId }, onSuccess));
  };
};

export const initCompany = () => {
  return (dispatch) => {
    dispatch(loadCompanyTypes());
    dispatch(loadContractors());
    dispatch(loadKeys());
  };
};
export const cancelCompany = () => ({ type: CANCEL_COMPANY_WIZARD });

export const saveCompany = (company, onSuccess) => {
  console.log(`REDUX-ACTION: saveCompany()`, company);
  return {
    types: [
      SAVE_COMPANY,
      SAVE_COMPANY_SUCCESS,
      SAVE_COMPANY_FAILURE
    ],
    promise: (client) => client.put(`/companies/`, { data: company }),
    onSuccess,
    company
  };
};

export const activateCompany = (company, filter, isKdl, onSuccess) => {
  console.log(`REDUX-ACTION: activateCompany()`, company);
  return {
    types: [
      ACTIVATE_COMPANY,
      ACTIVATE_COMPANY_SUCCESS,
      ACTIVATE_COMPANY_FAILURE
    ],
    promise: (client) => client.put(`/companies/${company.id}/changeStatus`, {data: {value: 2 }}),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredCompanies(onSuccess, filter, isKdl))
    },
    company,
    activate: true
  }
};

export const deactivateCompany = (company, filter, isKdl, onSuccess) => {
  console.log(`REDUX-ACTION: deactivateCompany()`, company);
  return {
    types: [
      DEACTIVATE_COMPANY,
      DEACTIVATE_COMPANY_SUCCESS,
      DEACTIVATE_COMPANY_FAILURE
    ],
    promise: (client) => client.put(`/companies/${company.id}/changeStatus`, {data: {value: 6 }}),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredCompanies(onSuccess, filter, isKdl))
    },
    company,
    activate: false
  }
};

export const deleteCompany = (company, filter, isKdl, onSuccess) => {
  console.log(`REDUX-ACTION: deleteCompany()`, company);
  return {
    types: [
      DELETE_COMPANY,
      DELETE_COMPANY_SUCCESS,
      DELETE_COMPANY_FAILURE
    ],
    promise: (client) => client.del(`/companies/delete/${company.id}`),
    // onSuccess: dispatch => dispatch(loadCompanies()),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredCompanies(onSuccess, filter, isKdl))
    },
    company
  };
};

export const exportCompanies = (props = {}) => {
  console.log(`REDUX-ACTION: exportCompanies()`, props);
  let params = [];
  for (const key in props) {
    params.push(`${key}=${encodeURIComponent(props[key])}`);
  }
  params = params.join('&');
  return (dispatch, getState) => {
    const header = getState().company.header;
    dispatch({
      types: [
        EXPORT_COMPANIES,
        EXPORT_COMPANIES_SUCCESS,
        EXPORT_COMPANIES_FAILURE
      ],
      // promise: (client) => client.get(`/companies/csv?related=true&filter=${header["x-filter"] ? header["x-filter"]: null}`)
      promise: (client) => client.get(
        `/companies/csv?kdl=true&related=true&filter=${header["x-filter"] ? header["x-filter"]: null}`
      )
    });
  }
}

export const exportSubCompanies = (props = {}) => {
  console.log(`REDUX-ACTION: exportSubCompanies()`, props);
  return (dispatch, getState) => {
    const { isAdmin, user } = getState().auth;
    let companyId = props.companyId;
    if (!companyId) {
      companyId = isAdmin ? 0 : user.companyId;
    }
    const header = getState().company.header;
    dispatch({
      types: [EXPORT_SUBCOMPANIES, EXPORT_COMPANIES_SUCCESS, EXPORT_COMPANIES_FAILURE],
      promise: (client) => client.get(
        `/companies/csv?related=true&companyId=${companyId}&filter=${header["x-filter"] ? header["x-filter"]: null}`
      )
    });
  }
}
