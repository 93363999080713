import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu, DeviceFormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import DebitorForm from './DebitorForm';
import { createSortByAttr, DEBITOR_STATUS_LABELS, STATUS_IDS, ACTION_IDS, TableEmptyMessage } from '../../../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';


export default class DebitorListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    regions: PropTypes.array,
    workers: PropTypes.array,
    contractors: PropTypes.array,
    getBaseData: PropTypes.func,
    isCompanySpecialKDL: PropTypes.bool,
    company: PropTypes.object,
    getCompany: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10,
  }
  validate = () => {
    const debitors = this.debitors.getModel();
    const model = [ ...debitors ];
    // console.log('DebitorList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  toggleAddition = (disabled) => {
    this.debitors.toggleAddition(disabled);
  }
  setRegions = (pRegions) => {
    this.debitors.setRegions(pRegions);
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <Debitor
          ref={(ref) => this.debitors = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class Debitor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    regions: PropTypes.array,
    workers: PropTypes.array,
    contractors: PropTypes.array,
    getBaseData: PropTypes.func,
    isCompanySpecialKDL: PropTypes.bool,
    company: PropTypes.object,
    getCompany: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    const regions = this.getValidRegions(props.regions, props.model);
    this.state = {
      items: props.model.map((item, index) => ({ ...item, index })),
      regions,
      addDisabled: (regions.length === 0),
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    const model = items.map(item => {
      const { region, debitorKey, name, comment, address, person, id, company_id, status_id, action_id } = item;
      const debitor = { region, debitorKey, name, comment, address, id, company_id, status_id, action_id };
      if (person) { debitor.person = person; }
      return debitor;
    });
    return model;
  }
  onSave = (onHide, updating) => {
    const model = this.form.getModel();
    // console.log('>> saving debitor ...', model, this.state.items);
    if (!model) {
      console.error("Form Validation Error => check your entries...");
      return;
    }
    model.status_id = updating ? STATUS_IDS.UPDATING : STATUS_IDS.NEW;
    model.action_id = updating ? ACTION_IDS.UPDATE : ACTION_IDS.CREATE;
    const items = this.state.items;
    const newState = {};
    if (model.index !== undefined/*  && items[model.index] !== undefined */) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    if (this.props.getBaseData) {
      const baseData = this.props.getBaseData();
      newState.regions = this.getValidRegions(baseData.regions, items);
      newState.addDisabled = (newState.regions.length === 0);
    }
    newState.items = items;
    // console.log('<< new debitors', newState.items);
    this.setState(newState);
    this.props.onChange && this.props.onChange(items);
    onHide && onHide();
  }
  onDelete = (selected) => {
    if (selected.id) {
      // console.log('>> marking debitor for deletion', selected);
      const { items } = this.state;
      if (items[selected.index]) {
        items[selected.index].status_id = STATUS_IDS.DELETING;
        items[selected.index].action_id = ACTION_IDS.DELETE;
        items[selected.index].status = null; // [STATUS_IDS.DELETING];
        this.setState({ items });
        this.props.onChange && this.props.onChange(items);
      } else {
        console.error('<< delphi connection to be deleted was not found', this.state.items);
      }
    } else {
      // console.log('>> deleting', selected);
      const items = [];
      this.state.items.forEach(item => {
        // console.log('>>>> comparing', item.name, 'with', selected.name);
        if (item.debitorKey !== selected.debitorKey) {
          // console.log('<<<< adding', item.debitorKey, '...');
          items.push(item);
        }
      });
      this.setState({ items });
      this.props.onChange && this.props.onChange(items);
    }
  }
  toggleAddition = (disabled) => {
    this.setState({addDisabled: disabled});
  }
  setRegions = (pRegions) => {
    // console.log('DebitorListForm.setRegions()', pRegions);
    const regions = this.getValidRegions(pRegions, this.state.items);
    const addDisabled = (regions.length === 0);
    this.setState({ regions, addDisabled });
  }
  getValidRegions = (regions, debitors) => {
    const validRegions = [];
    if (regions && debitors) {
      const debitorCountMap = {};
      const debitorNrMap = {};
      // initialize map
      regions.forEach(region => { debitorCountMap[region.name] = 0; });
      // count nr of debitors pro region
      debitors.forEach(item => {
        const debKeyTokens = item.debitorKey.split('-');
        const debNr = parseInt(debKeyTokens[debKeyTokens.length - 1]);
        // console.log('>>',item.debitorKey,'debitor Key nr:', debNr);
        if (debitorCountMap[item.region] === undefined) {
          debitorCountMap[item.region] = 1;
        }
        debitorCountMap[item.region] ++;

        if (debitorNrMap[item.region] === undefined) {
          debitorNrMap[item.region] = [];
        }
        debitorNrMap[item.region].push(debNr);
      });
      regions.forEach(region => {
        // console.log('>> checking', region, ':', debitorCountMap[region.name], 'items, debitorKey Nrs:', debitorNrMap[region.name]);
        if (region.debitorMax > debitorCountMap[region.name] && (this.props.isCompanySpecialKDL || (region.debitorKey && region.debitor !== ''))) {
          let suffix = 0;
          if (debitorNrMap[region.name]) {
            const list = debitorNrMap[region.name].sort((a, b) => parseInt(a) - parseInt(b));
            // console.log('>> Current DebitorKey Nrs for Region', region.name, ':', list);
            if (list.length === 1) {
              if (list[0] === 1) {
                suffix = 1;
              }
            } else if (list.length > 1) {
              for (let index = 1; index < list.length; index ++) {
                // console.log(' >>>>>> searching for gap btw', list[index-1], 'and', list[index]);
                if (list[index] - list[index - 1] > 1) {
                  // console.log('<< found free debitorKey suffix between',list[index - 1], 'and', list[index]);
                  suffix = list[index - 1];
                  break;
                }
              }
              // suffix = suffix || debitorCountMap[region.name];
              suffix = suffix || list[list.length - 1];
            }
          }
          // suffix = suffix || debitorCountMap[region.name];
          validRegions.push({ ...region, suffix });
        }
      });
      // console.log('DebitorList.getValidRegions()', regions, debitors, validRegions);
    }
    return validRegions;
  }
  render() {
    const { className, rows, workers, contractors, isCompanySpecialKDL, company, getCompany } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});

    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => {
        const copy = JSON.parse(JSON.stringify(item));
        const { street, houseNumber, areaCode, city } = item.address;
        const addressStr = `${item.name}, ${street} ${houseNumber}, ${areaCode} ${city}`;
        const status = item.status || DEBITOR_STATUS_LABELS[item.status_id];
        return {
          ...copy,
          regionName: item.region || item.regionName,
          index,
          addressStr,
          status
        };
      }).sort(createSortByAttr('debitorKey'));
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Debitoren"
              itemName="Debitor"
              itemNamePlural="Debitoren"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Debitoren" min/>)}
        >
          <Column
            field="regionName"
            header="Region"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '8em'}}
          />
          <Column
            field="debitorKey"
            header="Kennung"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '10em'}}
          /> 
          <Column
            field="name"
            header="Firma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '25%'}}
          /> 
          <Column
            field="addressStr"
            header="Adresse"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Freigabestatus"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '10em'}}
          /> 
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };

    const isEditDisabled = (item) => (
      (item.readOnly) || (item.id && item.status_id !== STATUS_IDS.FINISHED) || item.inUse
    );
    
    const renderItemForm = (item, onHide) => {
      const debitor = item || {
        region: '',
        debitorKey: '',
        name: '',
        address: {
          street: '',
          houseNumber: '',
          areaCode: '',
          city: ''
        },
        comment: '',
        status_id: 1
      };
      const currentCompany = { ...company };
      // const baseData = getBaseData();
      // if (!currentCompany.id) {
      //   if (baseData && baseData.company && baseData.company.address) {
      //     currentCompany.address = { ...baseData.company.address };
      //   }
      // }
      const debitorKeys = this.state.items.map(item => item.debitorKey);
      // console.log('DebitorListForm.renderItemForm()', debitor, currentCompany);
      return (
        <div>
          <DebitorForm
            ref={(ref) => this.form = ref}
            key={Math.random()}
            model={debitor}
            regions={this.state.regions}
            workers={workers}
            isCompanySpecialKDL={isCompanySpecialKDL}
            contractors={contractors}
            company={currentCompany}
            getCompany={getCompany}
            debitorKeys={debitorKeys}/>
          <DeviceFormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)}
            onUpdate={() => this.onSave(onHide, true)}
            onCancel={onHide}
            cancelBtnLabel={debitor.inUse ? 'Schließen' : 'Abbrechen'}
            saveHidden={debitor.inUse || isEditDisabled(debitor)}
            updateHidden={debitor.inUse || isEditDisabled(debitor)}
            saveDisabled={debitor.status_id !== STATUS_IDS.NEW || debitor.inUse}
            updateDisabled={debitor.status_id !== STATUS_IDS.FINISHED || debitor.inUse}
            saveBtnLabel="Speichern"
            updateBtnLabel="Ändern"
            saveBtnName="saveDebitorBtn"
            updateBtnName="updateDebitorBtn"
          />
        </div>
      );
    };

    const isDeleteDisabled = (item) => (
      (item.id && item.status_id !== STATUS_IDS.FINISHED) || (item.inUse)
    );

    const getItemFormTitle = (debitor) => {
      // console.log('DebitorListForm.getItemFormTitle()', debitor);
      // if (!debitor) return 'Debitor hinzufügen';
      // return 'Debitor bearbeiten';
      return (<h3 className="no-margin">{`Debitor ${debitor ? 'bearbeiten' : 'hinzufügen'}`}</h3>);
    }

    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">Debitoren</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addCompanyDebitorBtn"
        addBtnDisabled={this.state.addDisabled}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="debitor"
        isEditDisabled={isEditDisabled}
        itemDeleteable
        hideDisabledDelete
        isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Debitor löschen"
        getDeleteText={(item) => `Wollen Sie den Debitor ${item.debitorKey} unwiderruflich löschen?`}
        onDelete={this.onDelete}
      />
    );
  }
}
