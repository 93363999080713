import React from 'react';
import PropTypes from 'prop-types';
// import { Spinner } from 'primereact/spinner';
import { InputText, Label } from '../../../../components/Inputs';
import RegionForm from './RegionForm';
import DebitorListForm from './DebitorListForm';

export default class RegionDebitorForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    isAdmin: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    model: {
      regions: [],
      debitorKey: '',
      debitorMax: 1,
      debitors: []
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      debitorKey: props.model.debitorKey || '',
      debitorMax: props.model.debitorMax || 1,
      error: {
        debitorKey: null,
      }
    };
  }
  componentDidMount() {
    if (this.regionsForm) {
      const isDebitorKeyAndRegionsValid = (this.state.debitorKey !== '') && this.regionsForm.isValid();
      this.debitorsForm.toggleAddition(!isDebitorKeyAndRegionsValid);
    }
  }
  validate = () => {
    const { error, ...model } = this.state;
    let isValid = true;
    // debitorKey
    if (model.debitorKey === '') {
      error.debitorKey = true;
      isValid = false;
    }
    // debitorMax
    model.debitorMax = parseInt(model.debitorMax);
    if (isNaN(model.debitorMax) || model.debitorMax < 1) {
      error.debitorMax = true;
      isValid = false;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    // regions (required)
    model.regions = this.regionsForm.getModel();
    if (!model.regions) {
      return null;
    }
    // debitors
    model.debitors = this.debitorsForm.getModel();
    // if (!model.debitors) {
    //   return null;
    // }
    console.log('RegionDebitor Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  onDebitorKeyChange = (value) => {
    this.setState({debitorKey: value});
    const isDebitorKeyAndRegionsValid = (value !== '') && this.regionsForm.isValid();
    this.debitorsForm.toggleAddition(!isDebitorKeyAndRegionsValid);
  }
  onRegionsChange = (values) => {
    const { debitorKey } = this.state;
    const isDebitorKeyAndRegionsValid = (values && values.length > 0 && debitorKey !== '');
    this.debitorsForm.toggleAddition(!isDebitorKeyAndRegionsValid);
  }
  getDebitorBaseData = () => {
    return { 
      debitorKey: this.state.debitorKey,
      regionList: this.regionsForm.getRegions()
    };
  }
  render() {
    const { className, model: { regions, debitors }, isAdmin } = this.props;
    const { debitorKey, debitorMax, error } = this.state;
    const adminMarkup = !isAdmin ? null : (
      <div className="neg-margin-lr">
        <div className="w3-row">
          <div className={`w3-col s6 pad-lr pad-top${error.debitorKey ? ' error-group' : ''}`}>
            <Label htmlFor="debitorKey">{`Debitor-Key*`}</Label>
            <InputText id= "debitorKey"className="w3-block w3-border" value={debitorKey}
              onChange={(event) => this.onDebitorKeyChange(event.target.value)}/>
          </div>
          <div className={`w3-col s6 pad-lr pad-top${error.debitorKey ? ' error-group' : ''}`}>
            <Label htmlFor="debitorMax">{`Anzahl an erlaubten Debitoren*`}</Label>
            <InputText id="debitorMax" className="w3-block w3-border" value={debitorMax} type="number"
              onChange={(event) => this.setState({debitorMax: event.target.value})}/>
          </div>
          <RegionForm
            ref={ref => this.regionsForm = ref}
            className="w3-col s6 pad-top pad-lr"
            model={regions}
            onModelChanged={this.onRegionsChange}/>
        </div>
      </div>
    );
    const markup = (
      <DebitorListForm
        ref={(ref) => this.debitorsForm = ref}
        className="pad-top"
        model={debitors}
        getBaseData={this.getDebitorBaseData}
        debitorKey={debitorKey}
        regions={regions}
      />
    );
    return (
      <div className={className}>
        {adminMarkup}{markup}
      </div>
    );
  }
}
