import React from 'react';
import { withRouter/* , Link */ } from 'react-router-dom';
import {
  getOrganisationStaticTreeLevel,
  getSalesTreeLevel,
  getTechnikTreeLevel
} from '../MainNav';
import { SecondaryButtonLink } from '../../components/Buttons';

import { connect } from 'react-redux';
import { buildMapLinks } from '../MainNav/components';
import { getVisibilityConfig, sortByOptionalPosition } from '../../components/Utils';
import { isArchivePage } from '../Static/components/Utils';
// import { isArray } from 'lodash';

const mapStateToProps = (state) => {
  return {
    tree: state.staticPage.tree,
    isAdmin: state.auth.isAdmin,
  };
};
const mapDispatchToProps = null;

const Component = ({
  location = {},
  tree = {},
  isAdmin,
  isArchive,
  exclude = []
}) => {
  const { pathname } = location;
  if (!pathname || exclude.includes(pathname)) {
    return null;
  }
  const { technik, sales, organisation } = tree;
  const node = getTechnikTreeLevel(pathname, buildMapLinks(technik))
    || getSalesTreeLevel(pathname, buildMapLinks(sales))
    || getOrganisationStaticTreeLevel(pathname, buildMapLinks(organisation));
  let links = null;
  if (node && node.subMenu) {
    links = sortByOptionalPosition(node.subMenu.links, true);
    links = links.map((link, index) => {
      // don't show archive link
      if (link.data && isArchivePage(link.data.type)) {
        return null;
      }
      const visibilityConfig = getVisibilityConfig(link);
      const { visibility, visibilityClassName, title } = visibilityConfig;
      // normal users don't see invisible links
      if (!visibility.isVisible && !isAdmin) {
        return null;
      }
      // check if archived
      if ((!isArchive && link.is_archived !== 0) || (isArchive && link.is_archived === 0)) {
        return null;
      }
      if (isArchive) {
        visibilityClassName = '';
      }
      return (
        <div key={index} className="w3-col s4 pad-lr pad-big-top">
          <SecondaryButtonLink
            className={`w3-block alg-ctr link-${visibilityClassName}`}
            size="big"
            to={link.to}
            title={title}
          >
            {link.label}
          </SecondaryButtonLink>
        </div>
      )
    });
    links = (<div className="neg-margin-lr"><div className="w3-row">{links}</div></div>);
  }
  return links;
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Component)
);
