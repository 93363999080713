import React from "react";
import { PlusIconButton, PrimaryButton, SecondaryButton, SecondaryButtonLink } from "../../../../components/Buttons";
import moment from "moment";
import { PAGE_TYPES } from "../Utils";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const ProductColumnContent = ({ model, editMode, editPath, saveToArchive, onChange }) => {
	let data = {};
	try {
		data = JSON.parse(model.body || '{"title":"Column Title","products":[]}');
	} catch (err) {
		console.error("ProductColumnContent Error:", model, err);
		data = { title: "Column Title" };
	}
	// console.log("ProductColumnContent.render()", model, data, editMode);
	let dates = null;
	if (data.data) {
		const { endDate, publicationDate } = data.data;
		const endDateMarkup = !endDate ? null : (
			<div className="w3-cell-row">
				<div className="w3-cell pad-sm-top bold alg-right pad-rht">
					{"Gültig bis"}
				</div>
				<div className="w3-cell pad-sm-top alg-left pad-lft">
					{moment(endDate).format("DD.MM.YYYY")}
				</div>
			</div>
		);
		const pubDateMarkup = !publicationDate ? null : (
			<div className="w3-cell-row">
				<div className="w3-cell pad-sm-top bold alg-right pad-rht">
					{"Gültig ab"}
				</div>
				<div className="w3-cell pad-sm-top alg-left pad-lft">
					{moment(publicationDate).format("DD.MM.YYYY")}
				</div>
			</div>
		);
		if (editMode) {
			dates = (
				<div>
					{endDateMarkup}
					{pubDateMarkup}
				</div>
			);
		}
	}
	let visibleProducts = data.products || [];
	if (!editMode) {
		// const today = moment();
		visibleProducts = visibleProducts.filter((product) => {
			if (product.visibilityConfig.hide) {
				return false;
			}
			// if (product.data) {
			// 	const { endDate, publicationDate } = product.data;
			// 	if (endDate) {
			// 		const date = moment(endDate);
			// 		if (date.isValid() && date.isSameOrBefore(today, "day")) {
			// 			return false;
			// 		}
			// 	}
			// 	if (publicationDate) {
			// 		const date = moment(publicationDate);
			// 		if (date.isValid() && date.isAfter(today)) {
			// 			return false;
			// 		}
			// 	}
			// }
			return true;
		});
	}
	const dialogRef = React.useRef();
	const moveToArchive = React.useCallback((product) => {
		// console.log('moveToArchive()', product, dialogRef);
		dialogRef.current && dialogRef.current.show(
			true,
			{
				title: `Archivierung`,
				text: (
					<>
						<div>{`Wollen Sie die folgende Seite jetzt archivieren?`}</div>
						<div className="bold pad-top">{`${product.label}`}</div>
						<div className="bold pad-btm">{`${product.url}`}</div>
						<div>{`Bitte beachten Sie dass dieser Vorgang nicht rückgängig gemacht werden kann!`}</div>
					</>
				)
			},
			() => saveToArchive({ linkData: product })
		);
	});
	const config = {
		type: PAGE_TYPES.PRODUCT,
		nrOld: data.oldProducts,
		nrNew: data.newProducts
	};
	const products = visibleProducts.map((product, index) => {
		const labelStyle = {};
		let dates = null;
		let visibilityStatus = null;
		if (product.data) {
			const { color, endDate, publicationDate, isVisible } = product.data;
			if (color) labelStyle.color = `${product.data.color}`;
			if (editMode) {
				dates = [];
				if (publicationDate) dates.push(`ab ${moment(publicationDate).format("DD.MM.YYYY")}`);
				if (endDate) dates.push(`bis ${moment(endDate).format("DD.MM.YYYY")}`);
				dates = (dates.length > 0) ? dates.join(' ') : 'immer';
			}
			visibilityStatus = (
				<>
					<span className="margin-lr">|</span>
					<span className={`w3-text-${isVisible ? 'green' : 'red'}`}>
						{`${isVisible ? '' : 'un'}sichtbar`}
					</span>
				</>
			);
		}
		const { visibilityClassName, title } = product.visibilityConfig;
		let markup = (
			<div className={visibilityClassName} title={title}>
				<SecondaryButtonLink
					className="w3-block"
					size=""
					to={product.to}
				>
					<div style={labelStyle}>{product.label}</div>
				</SecondaryButtonLink>
			</div>
		);
		if (editMode) {
			const status = (
				<>
					<span style={labelStyle}>{labelStyle.color && labelStyle.color !== 'black' ? 'alt' : 'aktuell'}</span>
					{visibilityStatus}
				</>
			);
			const productProps = [
				{ label: 'Status', value: status },
				{ label: 'Publiziert', value: dates }
			].map((item, index) => (
				<div key={index} className="w3-cell-row pad-top">
					<div className="w3-cell w3-cell-middle">{item.label}</div>
					<div className="w3-cell w3-cell-middle alg-right">{item.value}</div>
				</div>
			));
			markup = (
				<div className="w3-block w3-border pad-big">
					<div className="bold">{product.label}</div>
					{productProps}
					<div className="w3-row neg-margin-lr">
						<div className="w3-col s6 pad-top pad-lr">
							<SecondaryButton
								className="w3-block"
								size="small"
								onClick={() => moveToArchive(product)}
							>
								{'archivieren'}
							</SecondaryButton>
						</div>
						<div className="w3-col s6 pad-top pad-lr">
							<PrimaryButton
								className="w3-block"
								size="small"
								onClick={() => editPath({ linkData: product, config })}
							>
								{'bearbeiten'}
							</PrimaryButton>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div key={index} className="pad-big-top">
				{markup}
				<ConfirmationDialog ref={dialogRef}/>
			</div>
		);
	});
	const menu = editMode && (
		<div className="w3-right">
			<PlusIconButton
				size="small"
				title="Produkt hinzufügen"
				onClick={() => editPath({ parentUrl: data.url, config })}
			/>
		</div>
	);
	return (
		<div>
			<div className={`w3-border alg-${editMode ? 'left' : 'center'} pad bg-secondary clearfix`}>
				{menu}
				<h4 className={`no-margin ${editMode ? 'pad' : ''}`}>{data.title}</h4>
				{dates}
			</div>
			{products}
		</div>
	);
};

export default ProductColumnContent;
