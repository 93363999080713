import React from 'react';
import PropTypes from 'prop-types';
import CardOrderTaskForm from '../../components/cards/CardOrderTaskForm';
import { FormMenu } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadCardOrder,
} from '../../../Product/actions/cards';


const frontload = async (props) => {
  const id = props.id;
  await props.getOrder({ id });
};
const mapStateToProps = (state) => {
  return {
    cardOrder: state.card.cardOrder,
    cards: state.card.cards,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadCardOrder(...args)),
});

class Holder extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    cardOrder: PropTypes.shape({}),
    cards: PropTypes.array,
    getOrder: PropTypes.func.isRequired,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    onSave: PropTypes.func,
    // onUpdate: PropTypes.func,
    onCancel: PropTypes.func,
  }
  static defaultProps = {
    className: ''
  }
  getModel = () => {
    return this.form.getModel();
  }
  onSave = () => {
    const { onSave } = this.props;
    onSave && onSave(this.getModel());
  }
  render() {
    const { className, cardOrder, cards, readOnly, onCancel } = this.props;
    console.log('CardOrderTaskHolder.render()', this.props);
    if (!cardOrder) {
      return null;
    }
    return (
      <div className={`card-order-task-holder clearfix ${className}`}>
        <CardOrderTaskForm
          ref={(ref) => this.form = ref}
          model={cardOrder}
          cards={cards}
          readOnly={readOnly}
        />
        <FormMenu
          className="margin-big-top w3-border-top pad-big"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave} onCancel={onCancel}
          saveHidden={readOnly}
          saveBtnName="saveDeviceBtn"
          cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Holder)
);
