import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import PagePathMovementForm from '../components/PagePathMovementForm';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { updateSubTree, hidePathMovementDialog } from "../actions";
import { getLinkParentUrl } from '../../../components/Utils';

const mapStateToProps = (state) => {
  return {
    isVisible: state.staticPage.showPathMovementDialog,
    linkData: state.staticPage.currentLinkData,
    treeLinks: state.staticPage.currentTreeLinks
  };
};
const mapDispatchToProps = (dispatch) => ({
  onSave: (...args) => dispatch(updateSubTree(...args)),
  onCancel: (...args) => dispatch(hidePathMovementDialog(...args))
});

class PageMovementDialog extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    linkData: PropTypes.shape({}),
    treeLinks: PropTypes.array,
    history: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }
  onHide = () => {
    this.props.onCancel();
  }
  onSave = () => {
    const model = this.form.getModel();
    if (!model) {
      return;
    }
    const { onSave, history } = this.props;
    onSave(model, () => {
      this.onHide();
      history.replace(getLinkParentUrl(model.to))
    });
  }
  render() {
    const { isVisible, linkData, treeLinks } = this.props;
    if (!isVisible) {
      return null;
    }
    console.log('PageMovementDialog.render()', this.props);
    // header
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{`Seite verschieben: ${linkData.label}`}</h3>
      </div>
    );
    // footer
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton className="margin-lft" onClick={() => this.onSave()}>
          {'Speichern'}
        </PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onHide()}>
          {'Abbrechen'}
        </SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}
      >
        <PagePathMovementForm
          ref={ref => this.form = ref}
          model={linkData}
          treeLinks={treeLinks}
        />
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageMovementDialog);

