import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDataDisplayColumns } from './DisplayUtils';

export default class LevelDisplay extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    levelLabel: PropTypes.string.isRequired,
    levelBody: PropTypes.func.isRequired,
    extraCols: PropTypes.func,
    data: PropTypes.array,
    dataKey: PropTypes.string,
    hideSales: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    data: [],
    dataKey: 'day',
  }
  render() {
    const { className, levelLabel, levelBody, extraCols, data, dataKey, columnNames, hideSales } = this.props;
    return (
      <div className={`${className}`}>
        <DataTable
          value={data}
          dataKey={dataKey}
        >
          <Column
            header={(<div className="alg-left">{levelLabel}</div>)}
            body={levelBody}
          />
          {extraCols && extraCols()}
          {getDataDisplayColumns(columnNames, hideSales)}
        </DataTable>
      </div>
    );
  }
}


// import React from 'react';
// import PropTypes from 'prop-types';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';

// export default class LevelDisplay extends React.Component {
//   static propTypes = {
//     className: PropTypes.string,
//     levelLabel: PropTypes.string.isRequired,
//     levelBody: PropTypes.func.isRequired,
//     extraCols: PropTypes.func,
//     ChildComponent: PropTypes.node,
//     data: PropTypes.array,
//     getData: PropTypes.func
//   }
//   static defaultProps = {
//     className: '',
//     data: []
//   }
//   render() {
//     const { className, levelLabel, levelBody, extraCols, data, getData, ChildComponent } = this.props;
//     const rowExpansionTemplate = !ChildComponent ? null : (data) => {
//       return (
//         <ChildComponent
//           key={Math.random()}
//           getData={getData}
//           init={data}
//         />
//       );
//     };
//     return (
//       <div className={`${className}`}>
//         <DataTable
//           value={data}
//           dataKey="id"
//           rowExpansionTemplate={rowExpansionTemplate}
//         >
//           <Column
//             header={levelLabel}
//             body={levelBody}
//           />
//           {extraCols && extraCols()}
//           <Column
//             field="sales"
//             header="Absätze"
//             style={{textAlign: 'center'}}
//           />
//           <Column
//             field="netto"
//             header="ist Netto"
//             style={{textAlign: 'center'}}
//           />
//           <Column
//             field="open"
//             header="offen"
//             style={{textAlign: 'center'}}
//           />
//           <Column
//             field="canceled"
//             header="Widerruf"
//             style={{textAlign: 'center'}}
//           />
//         </DataTable>
//       </div>
//     );
//   }
// }
