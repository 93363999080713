
export const LOAD_TASKS = 'tasks/load';
export const LOAD_TASKS_SUCCESS = 'tasks/load/success';
export const LOAD_TASKS_FAILURE = 'tasks/load/failure';

export * from './connection';
export * from './debitor';
export * from './device';
export * from './card';
export * from './key';
export * from './employee';

export function loadOpenTasks() {
  // console.log('REDUX-ACTION: loadOpenTasks() ...');
  return {
    types: [
      LOAD_TASKS,
      LOAD_TASKS_SUCCESS,
      LOAD_TASKS_FAILURE
    ],
    promise: (client) => client.get(`/companies/userSessions`)
  };
}
