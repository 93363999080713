import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Dashboard from '../../../components/Dashboard';
import { PageHeight30, WizardTitle, notifyError, isProductDeleted, toUIDate } from '../../../components/Utils';
import { FormMenu } from '../../../components/Buttons';
import InPageNavMenu from '../../../components/InPageNavMenu';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadProduct, cancelProduct, saveProduct } from '../actions/products';

import ProductBasicDataForm from '../components/products/ProductBasicDataForm';
import ProductProvisionForm from '../components/products/ProductProvisionForm';
// import ProductProvisionListForm from '../components/products/ProductProvisionListForm';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getProduct({ id });
};
const mapStateToProps = (state) => {
  return {
    product: state.salesProduct.product
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProduct: (...args) => dispatch(loadProduct(...args)),
  cancelProduct: (...args) => dispatch(cancelProduct(...args)),
  saveProduct: (...args) => dispatch(saveProduct(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    product: PropTypes.shape({}),
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProduct: PropTypes.func.isRequired,
    cancelProduct: PropTypes.func.isRequired,
    saveProduct: PropTypes.func.isRequired,
    className: PropTypes.string,
    rows: PropTypes.number,
    cancelUrl: PropTypes.string
  }
  static defaultProps = {
    className: '',
    row: 10,
    cancelUrl: '/sales/produktverwaltung'
  }
  constructor() {
    super();
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelProduct();
  }
  onCancel = () => {
    console.log('ProductWizard.onCancel()');
    const { cancelProduct, history, cancelUrl } = this.props;
    cancelProduct();
    history.replace(cancelUrl);
  }
  onSave = () => {
    console.log('ProductWizard.onSave()');
    let model = this.basicData.getModel();
    if (!model) {
      return notifyError({
        summary: 'Sales - Produktverwaltung',
        detail: `Das Produkt konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    // provisions
    const provision = this.provision.getModel();
    if (provision) {
      model = { ...model, ...provision };
    }

    console.log('Saving Model: ', model);
    const { history, saveProduct, cancelUrl } = this.props;
    const onSuccess = () => history.replace(cancelUrl);
    saveProduct(model, onSuccess);
  }
  renderMenu = () => {
    const links = [
      { to: 'daten', label: 'Allgemeine Daten' },
      { to: 'historie', label: 'Produkthistorie' }
    ];
    return (
      <InPageNavMenu
        className="under-wizard-title"
        initialActiveId='daten'
        links={links}
      />
    );
  }
  render() {
    const { className, rows, product } = this.props;
    console.log('ProductWizard.render()', product);
    const Menu = this.renderMenu();
    if (!product) return null;
    const isDeleted = isProductDeleted(product);
    // provisions
    const provisions = (product.provisions || []).map(item => ({
      ...item,
      startDate: toUIDate(item.start_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
      endDate: (item.end_date ? toUIDate(item.end_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ') : ''),
    }));
    // if (!product.provisions) {
    //   product.provisions = [{
    //     product_provision: product.product_provision,
    //     start_date: product.start_date
    //   }];
    // }
    return (
      <div className={`product-wizard clearfix ${className}`} product={Math.random()}>
        <WizardTitle
          type="sales-product"
          model={product}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveProductBtn2"
              cancelBtnLabel={'Schließen'/* isDeleted ? 'Schließen' : 'Abbrechen' */}
              saveHidden={true || isDeleted}
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30 id="daten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <h3 className="no-margin-top">{'Allgemeine Daten'}</h3>
              <ProductBasicDataForm
                ref={ref => this.basicData = ref}
                model={product}
                readOnly
              />
            </PageHeight30>
            <PageHeight30 id="historie" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <h3 className="no-margin-top">{'Produkthistorie'}</h3>
              {/* <ProductProvisionForm
                ref={ref => this.provision = ref}
                model={product}
              /> */}
              {/* <div className="w3-border-top w3-border-lr pad-big-top pad-big-lr">
                <h3 className="no-margin-top">{'Historie'}</h3>
              </div> */}
              <DataTable
                value={provisions}
                paginator={provisions.length > rows}
                rows={rows}
              >
                <Column
                  field="product_name"
                  header="Produktname"
                  sortable
                  filter={provisions.length > rows}
                  filterMatchMode="contains"
                />
                <Column
                  field="product_provision"
                  header="Provision"
                  sortable
                  filter={provisions.length > rows}
                  filterMatchMode="contains"
                  style={{width: '15em'}}
                />
                <Column
                  field="product_npv"
                  header="NPV"
                  sortable
                  filter={provisions.length > rows}
                  filterMatchMode="contains"
                  style={{width: '15em'}}
                />
                <Column
                  field="startDate"
                  header="Start" // "Gültig von"
                  dateFormat="dd.mm.yy"
                  sortable
                  filter={provisions.length > rows}
                  filterMatchMode="contains"
                  style={{textAlign:'center', width: '12em'}}
                />
                <Column
                  field="endDate"
                  header="Gültig bis"
                  dateFormat="dd.mm.yy"
                  sortable
                  filter={provisions.length > rows}
                  filterMatchMode="contains"
                  style={{textAlign:'center'}}
                />
              </DataTable>
              {/* <ProductProvisionListForm
                ref={ref => this.provisions = ref}
                model={product.provisions}
              /> */}
            </PageHeight30>
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveProductBtn"
              cancelBtnLabel={'Schließen'/* isDeleted ? 'Schließen' : 'Abbrechen' */}
              saveHidden={true || isDeleted}
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
