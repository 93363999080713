import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PageHeight80, PageHeightAuto, isAdmin } from '../../../components/Utils';
import RegionDebitorForm from '../components/wizard/RegionDebitorForm';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadCompany, saveCompany } from '../actions';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getCompany({ id });
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.company.company
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompany: (...args) => dispatch(loadCompany(...args)),
  saveCompany: (...args) => dispatch(saveCompany(...args))
});

class CompanyPanel extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    company: PropTypes.object,
    getCompany: PropTypes.func.isRequired,
    saveCompany: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  onSave = () => {
    console.log('CompanyPanel.onSave()');
    const personalData = this.basisDaten.getModel();
    if (!personalData) return null;
    const { name, address } = personalData;
    // groups
    const groups = this.groupDaten.getModel();
    // techKeys
    const techKeys = this.techKeys.getModel();
    // regions and debitors
    const regionDebitors = this.debitors.getModel() || {};
    // model
    const model = {
      // initial attributes
      ...this.props.company,
      // new and possibly changed attributes
      name,
      address,
      groups,
      techKeys,
      ...regionDebitors,
      auftraggeber: 'Vodafone Kabel Deutschland GmbH',
      // auftragnehmer: []
    };
    console.log('Saving Model:', model);
    const { history } = this.props;
    const onSuccess = () => history.replace('/organisation/firmen');
    this.props.saveCompany(model, onSuccess);
  }
  onCancel = () => {
    console.log('CompanyPanel.onCancel()');
    this.props.history.replace('/organisation/firma');
  }
  render() {
    const { className, company, user } = this.props;
    console.log('CompanyPanel.render()', company);
    if (!company) return null;
    const { debitorKey, regions, debitors } = company;
    const regionDebitors = { debitorKey, regions, debitors };
    // groups
    const groupMarkup = company.groups.map((item, index) => (
      <div key={index} className="pad-sm-tb">{item}</div>
    ));
    // tech keys
    const techMarkup = company.techKeys.map((item, index) => (
      <div key={index} className="pad-sm-tb">{item}</div>
    ));
    return (
      <div className={`company-panel clearfix ${className}`}>
        <PageHeight80 className="pad-big w3-border">
          <h2 className="wizard-title">{company.name}</h2>
          <PageHeightAuto id="/basisdaten" className="w3-border- margin-big-top- pad-big- pad-top" isUnterWizardTitle>
            <h3 className="no-margin-top">Firmenadresse</h3>
            <div className="pad-sm-tb">{`${company.address.companyName}`}</div>
            <div className="pad-sm-tb">{`${company.address.street} ${company.address.houseNumber}`}</div>
            <div className="pad-sm-tb">{`${company.address.areaCode} ${company.address.city}`}</div>
          </PageHeightAuto>
          <PageHeightAuto id="/gewerke" className="w3-border- margin-big-top- pad-big- pad-top" isUnterWizardTitle>
            <h3 className="no-margin-top">Gewerke</h3>
            {groupMarkup}
          </PageHeightAuto>
          <PageHeightAuto id="/technikerschlüssel" className="w3-border- margin-big-top- pad-big- pad-top" isUnterWizardTitle>
            <h3 className="no-margin-top">Technikerschlüssel</h3>
            {techMarkup}
          </PageHeightAuto>
          <PageHeightAuto id="/debitoren" className="w3-border- margin-big-top- pad-big- pad-top" isUnterWizardTitle>
            <h3 className="no-margin-top">Regionen und Debitoren</h3>
            <RegionDebitorForm ref={ref => this.debitors = ref} model={regionDebitors} isAdmin={isAdmin(user)}/>
          </PageHeightAuto>
          <PageHeightAuto id="/auftraggeber" className="w3-border- margin-big-top- pad-big- pad-top" isUnterWizardTitle>
            <h3 className="no-margin-top">Auftraggeber</h3>
            <label>{'Vodafone Kabel Deutschland GmbH'}</label>
          </PageHeightAuto>
        </PageHeight80>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(withRouter(CompanyPanel))
);
