import { loadTree } from '../../modules/Static/actions';
import { loadExtendedRoles } from '../../modules/Worker/actions';

export const LOAD_CURRENT_USER_SESSION = 'auth/current-user/session/load';
export const LOAD_CURRENT_USER_SESSION_SUCCESS = 'auth/current-user/session/load/success';
export const LOAD_CURRENT_USER_SESSION_FAILURE = 'auth/current-user/sesiosn/load/failure';

export const LOAD_CURRENT_USER = 'auth/current-user/load';
export const LOAD_CURRENT_USER_SUCCESS = 'auth/current-user/load/success';
export const LOAD_CURRENT_USER_FAILURE = 'auth/current-user/load/failure';

export const LOAD_USER_PROFILE = 'auth/user/profile/load';
export const LOAD_USER_PROFILE_SUCCESS = 'auth/user/profile/load/success';
export const LOAD_USER_PROFILE_FAILURE = 'auth/user/profile/load/failure';

export const LOGIN = 'auth/login';
export const LOGIN_SUCCESS = 'auth/login/success';
export const LOGIN_FAILURE = 'auth/login/failure';

export const CONFIRM_LOGIN = 'auth/login/confirm';
export const CONFIRM_LOGIN_SUCCESS = 'auth/login/confirm/success';
export const CONFIRM_LOGIN_FAILURE = 'auth/login/confirm/failure';

export const LOGOUT = 'auth/logout';
export const LOGOUT_SUCCESS = 'auth/logout/success';
export const LOGOUT_FAILURE = 'auth/logout/failure';

export const RESET_PASSWORD = 'auth/password/reset';
export const RESET_PASSWORD_SUCCESS = 'auth/password/reset/success';
export const RESET_PASSWORD_FAILURE = 'auth/password/reset/failure';

export const SAVE_PASSWORD = 'auth/password/save';
export const SAVE_PASSWORD_SUCCESS = 'auth/password/save/success';
export const SAVE_PASSWORD_FAILURE = 'auth/password/save/failure';

export const UPDATE_BASIC_DATA = 'auth/basic-data/update';
export const UPDATE_BASIC_DATA_SUCCESS = 'auth/basic-data/update/success';
export const UPDATE_BASIC_DATA_FAILURE = 'auth/basic-data/update/failure';

export const UPDATE_NAMES = 'auth/names/update';
export const UPDATE_NAMES_SUCCESS = 'auth/names/update/success';
export const UPDATE_NAMES_FAILURE = 'auth/names/update/failure';

export const LOAD_APP_VERSION = 'app/version/load';
export const LOAD_APP_VERSION_SUCCESS = 'app/version/load/success';
export const LOAD_APP_VERSION_FAILURE = 'app/version/load/failure';


export function loadCurrentUser() {
  console.log('REDUX-ACTION: loadCurrentUser() ...');
  return {
    types: [LOAD_CURRENT_USER, LOAD_CURRENT_USER_SUCCESS, LOAD_CURRENT_USER_FAILURE],
    promise: (client) => client.get(`/loadAuth`),
    onSuccess: dispatch => {
      dispatch(loadTree({ pathname: 'main' }));
      dispatch(loadCurrentUserSession());
      dispatch(loadExtendedRoles());
    },
    onFailure: dispatch => dispatch(loadAppVersion())
  };
}

export function loadCurrentUserSession() {
  console.log('REDUX-ACTION: loadCurrentUserSession() ...');
  return {
    types: [
      LOAD_CURRENT_USER_SESSION,
      LOAD_CURRENT_USER_SESSION_SUCCESS,
      LOAD_CURRENT_USER_SESSION_FAILURE
    ],
    promise: (client) => client.get(`/companies/userSessions`),
    onSuccess: dispatch => dispatch(loadAppVersion())
  };
}

export function loadAppVersion() {
  // console.log('REDUX-ACTION: loadAppVersion() ...');
  return {
    types: [ LOAD_APP_VERSION, LOAD_APP_VERSION_SUCCESS, LOAD_APP_VERSION_FAILURE ],
    promise: (client) => client.get('/currentVersion')
  }
}

export function loadUserProfile(user) {
  return {
    types: [LOAD_USER_PROFILE, LOAD_USER_PROFILE_SUCCESS, LOAD_USER_PROFILE_FAILURE],
    promise: (client) => client.get(`/companies/profiles?userId=${user.id}`)
  };
}

export function loadCurrentUserProfile() {
  console.log(`REDUX-ACTION: loadCurrentUserProfile() ...`);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    return dispatch(loadUserProfile(user));
  };
}

export function login(credentials) {
  console.log('REDUX-ACTION: login() ...', credentials);
  const data = { ...credentials, userUTCoffset: new Date().getTimezoneOffset() };
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: (client) => client.post(`/login`, { data }),
    credentials
  };
}

export function confirmLogin(credentials) {
  console.log(`REDUX-ACTION: confirmLogin(${credentials})...`);
  return (dispatch, getState) => {
    const unconfirmedUser = getState().auth.unconfirmedUser;
    // if (`${window.ENABLE_2FA}` !== '1') {
    if (`${window.ENABLE_2FA}` !== '1' || (window.NODE_ENV !== 'production')) {
      console.log('## 2FA Disabled => skipping verification ...');
      dispatch({ type: CONFIRM_LOGIN_SUCCESS });
      return dispatch(loadCurrentUser());
      // return dispatch({ type: CONFIRM_LOGIN_FAILURE });
    }
    const data = {
      ...credentials,
      verification_id: unconfirmedUser.verification_id,
    };
    dispatch({
      types: [
        CONFIRM_LOGIN,
        CONFIRM_LOGIN_SUCCESS,
        CONFIRM_LOGIN_FAILURE
      ],
      promise: (client) => client.post(`/verifyOTP`, { data }),
      onSuccess: () => dispatch(loadCurrentUser())
    });
  }
}

export function logout(onSuccess) {
  console.log('REDUX-ACTION: logout() ...');
  return {
    types: [
      LOGOUT,
      LOGOUT_SUCCESS,
      LOGOUT_FAILURE
    ],
    promise: (client) => client.get(`/logout`),
    onSuccess: () => onSuccess && onSuccess()
  };
}

export function resetPassword(credentials, onSuccess) {
  console.log(`REDUX-ACTION: resetPassword()...`, credentials);
  const data = { ...credentials, userUTCoffset: new Date().getTimezoneOffset() };
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    promise: (client) => client.post(`/companies/passwords/request`, { data }),
    credentials,
    onSuccess: () => onSuccess && onSuccess()
    // onSuccess: dispatch => dispatch(logout())
  };
}

export function savePassword(credentials, onSuccess) {
  return (dispatch, getState) => {
    const data = { ...credentials };
    if (!data.user_id) {
      const user = getState().auth.user;
      if (!user) {
        return console.error('Not logged in => aborting !');
      }
      data.user_id = user.id;
    }
    console.log(`REDUX-ACTION: savePassword()...`, data);
    return dispatch({
      types: [SAVE_PASSWORD, SAVE_PASSWORD_SUCCESS, SAVE_PASSWORD_FAILURE],
      promise: (client) => client.put(`/companies/passwords`, { data }),
      credentials,
      onSuccess: () => onSuccess && onSuccess()
    });
  };
}

export function updateBasicData(data, onSuccess) {
  console.log(`REDUX-ACTION: updateBasicData()...`, data);
  return {
    types: [
      UPDATE_BASIC_DATA,
      UPDATE_BASIC_DATA_SUCCESS,
      UPDATE_BASIC_DATA_FAILURE
    ],
    promise: (client) => client.put(`/companies/users`, { data }),
    onSuccess: () => onSuccess && onSuccess(),
    data
  };
};

export function updateNames(data, onSuccess) {
  console.log(`REDUX-ACTION: updateNames()...`, data);
  return {
    types: [
      UPDATE_NAMES,
      UPDATE_NAMES_SUCCESS,
      UPDATE_NAMES_FAILURE
    ],
    promise: (client) => client.put(`/companies/users`, { data }),
    onSuccess: () => onSuccess && onSuccess(),
    data
  };
}; 

function delay(n) {  
  n = n || 1000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, n);
  });
}

export const checkUserInState = async (getState) => {
  let userLoaded = getState().auth.loadedCurrentUser;
  let counter = 0;
  while(!userLoaded && counter < 5) {
    console.log('>>> user not loaded => wait 1000 ms ...');
    await delay(1000);
    userLoaded = getState().auth.loadedCurrentUser;
    counter ++;
    console.log('>>> ::', userLoaded, counter);
  }
}