import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SecondaryButton } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
// import TechKeyForm from './TechKeyForm';
import { toUIDate, showFile, TableEmptyMessage } from '../../../../components/Utils';
import TechKeyListFormHolder from '../../containers/TechKeyListFormHolder';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';


export default class TechKeyListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    company: PropTypes.shape({}),
    companyKeys: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 5
  }
  validate = () => {
    const techKeys = this.techKeys.getModel();
    if (!techKeys) return;
    const model = [ ...techKeys ];
    // console.log('TechKeyList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <TechKey
          ref={(ref) => this.techKeys = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class TechKey extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    company: PropTypes.shape({}),
    companyKeys: PropTypes.array,
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map(item => {
      return { ...item };
    });
    return model;
  }
  onSave = (model, onHide) => {
    // console.log('TechKeyListForm.onSanve()', model);
    if (!model) {
      return;
    }
    model.company_id = this.props.company.id;
    // console.log('>> saving techKey ...', model);
    const { items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    // console.log('>> deleting techKey from list', selected);
    const items = [];
    for (const index in this.state.items) {
      // console.log('>> delete: comparing index', index, 'with selected index', selected.index);
      if (index != selected.index) {
        items.push(this.state.items[index]);
      }
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, company, companyKeys, rows } = this.props;
    const userTechKeys = this.state.items;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const techKeys = companyKeys.filter(item => !userTechKeys.find(key => (key.name === item)) );
    techKeys.sort();
    const fileBtnTemplate = (rowData) => {
      let btnMarkup = null;
      if (rowData.path) {
        const id = `pathBtn${rowData.index}`;
        btnMarkup = (
          <SecondaryButton
            id={id}
            size="small"
            onClick={() => showFile(rowData.path)}
          >
            {`Datei`}
          </SecondaryButton>
        );
      }
      return (<div>{btnMarkup}</div>);
    };
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => ({
        ...item,
        index,
        createdDate: toUIDate(item.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
      }));
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Schließmittel"
              itemName="Schließmittel"
              itemNamePlural="Schließmittel"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Schließmittel" min/>)}
        >
          <Column
            field="number"
            header="Schließmittel"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />  
          <Column
            field="createdDate"
            header="Datum"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            body={fileBtnTemplate}
            header="Übergabevereinbarung"
            style={{textAlign:'center', width: '14em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const techKey = item || {
        name: '',
        path: '',
      };
      console.log('>> editing', techKey);
      let keyList = techKeys;
      if (techKey.name !== '' && keyList.findIndex(item => item === techKey.name) < 0) {
        keyList.push(techKey.name);
        keyList.sort();
      }
      console.log('old TechKeys:', techKeys);
      console.log('new TechKeys:', keyList);
      return (
        <TechKeyListFormHolder
          key={Math.random()}
          model={techKey}
          companyKeys={keyList/* techKeys companyKeys */}
          companyName={company.name}
          onSave={(model) => this.onSave(model, onHide)}
          onCancel={onHide}
        />
      );
    };
    const getItemFormTitle = (techKey) => {
      return (<h3 className="no-margin">{`Schließmittel ${(techKey && techKey.number !== '' ? 'bearbeiten' : 'hinzufügen')}`}</h3>);
    }
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">Technikerschlüssel</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addWorkerTechKeyBtn"
        items={userTechKeys}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="techKey"
        itemDeleteable
        hideDisabledDelete
        // isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Schließmittel löschen"
        getDeleteText={(item) => `Wollen Sie den Schließmittel ${item.number} unwiderruflich löschen?`}
        onDelete={this.onDelete}
      />
    );
  }
}
