import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ViewIconNavButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadModems, loadWeeklyModemOrders, setDebitor, setSelection } from '../actions';
import { scrollToTop, TableEmptyMessage, toUIDate } from '../../../components/Utils';
import InformationDialog from '../../../components/InformationDialog';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  await props.getModems();
  if (props.isTask) {
    await props.getWeeklyModemOrders({ isTask: props.isTask });
    return;
  }
  await props.getWeeklyModemOrders({ week: props.week, year: props.year });
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.modem.loading,
    orderWeeklyOverview: state.modem.modemOrderWeeklyGroupedOverview,
    // orderWeeklyOverviewMap: state.modem.modemOrderWeeklyOverviewMap,
    modems: state.modem.catalogModems,
    selectedModems: state.modem.selectedModems
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModems: (...args) => dispatch(loadModems(...args)),
  getWeeklyModemOrders: (...args) => dispatch(loadWeeklyModemOrders(...args)),
  setDebitor: (...args) => dispatch(setDebitor(...args)),
  setSelection: (...args) => dispatch(setSelection(...args))
});

class ModemOrderWeeklyGroupedOverviewTable extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    orderWeeklyOverview: PropTypes.array,
    // orderWeeklyOverviewMap: PropTypes.object,
    modems: PropTypes.array,
    selectedModems: PropTypes.array,
    getModems: PropTypes.func.isRequired,
    getWeeklyModemOrders: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    setSelection: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    isTask: PropTypes.bool,
    week: PropTypes.string,
    year: PropTypes.string
  }
  static defaultProps = {
    rows: 12,
    className: ''
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidMount() {
    scrollToTop();
  }
  componentDidUpdate() {
  }
  onPage = (event) => {
    this.setState({ first: event.first });
    scrollToTop();
  }
  onShowDetails = (summary, history) => {
    console.log('Editing', summary);
    const { week, year } = this.props;
    if (!this.props.isTask && week != null && year != null) {
      return history.push(`/shop/modems/uebersicht/jahr/${year}/woche/${week}/region/${summary.debitorKey}`);
    }
    history.push(`/aufgaben/modems/uebersicht/jahr/${summary.year}/woche/${summary.week}/region/${summary.debitorKey}`);
  }
  actionTemplate = (rowData/* , column */) => {
    return (
      <div>
        <ViewIconNavButton onClick={(history) => this.onShowDetails(rowData, history)}/>
      </div>
    );
  }
  render() {
    const {
      className,
      isAdmin,
      isLoading,
      orderWeeklyOverview,
      modems,
      rows,
      selectedModems,
      setSelection,
    } = this.props;
    if (!isAdmin || !orderWeeklyOverview || !modems) {
      return null;
    }
    console.log('ModemOrderWeeklyGroupedOverviewTable.render()', orderWeeklyOverview, this.props);
    const data = orderWeeklyOverview.map((item, index) => {
      const model = {
        ...item,
        index,
        orderDate: toUIDate(item.created_date)
      };
      return model;
    });
    const mini = true; //  isTask;
    const formatFieldName = (value) => value.split('.').join('').split(' ').join('-');
    const columns = mini ? modems.map((modem, index) => {
      return (
        <Column
          key={index}
          field={formatFieldName(modem.name)}
          header={modem.name}
          sortable={data.length > 1}
          filter={data.length > rows}
          filterMatchMode="contains"
        />
      );
    }) : [
      (
        <Column
          key="1"
          field="orderDate"
          header="Bestellungsdatum"
          sortable={data.length > 1}
          filter={data.length > rows}
          filterMatchMode="contains"
          style={{width: '12em'}}
        />
      )
    ];
    return (
      <div className={`modem-order-weekly-grouped-overview-table clearfix ${className}`}>
        <DataTable
          value={data}
          paginator={data.length > rows}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Modembestellungen"
              itemName="Modembestellung"
              itemNamePlural="Modembestellungen"
            />
          }
          selection={selectedModems}
          onSelectionChange={event => setSelection(event.value)}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Modembestellungen"/>)}
        >
          <Column 
            selectionMode="multiple"
            style={{width:'3em'}}
          />
          <Column
            field="debitorKey"
            header="Debitor"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {columns}
          <Column
            field="statusName"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '6em !important'}}
          />
          <Column
            body={this.actionTemplate}
            className="alg-center"
            style={{width: '5em'}}
          />
        </DataTable>
        <InformationDialog ref={ref => this.dialog = ref}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ModemOrderWeeklyGroupedOverviewTable)
);
