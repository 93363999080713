import React from 'react';
import PropTypes from 'prop-types';
import { CoverText } from '../../../../components/Utils';

export default class UserTrainingPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    isEditable: PropTypes.bool,
  }
  static defaultProps = {
    className: ''
  }
  render() {
    const { className, model, isEditable } = this.props;
    if (!model) return null;
    const items = model.length === 0 ? (
      <CoverText>{`${isEditable ? 'Es' : 'Für Sie'} liegen noch keine Schulungsdaten vor`}</CoverText>
    ) : model.map((userTraining, index) => {
      return (
        <div key={index}>
          <div className="w3-cell w3-cell-top pad-rht">
            <h4 className="no-margin">{`Abgeschlossene Schulungen:`}</h4>
          </div>
          <div className="w3-cell w3-cell-top w3-large pad-lr">
            <h4 className="no-margin">{`${userTraining['abgeschlossene schulungen']} von ${userTraining['mögliche schulungen']}`}</h4>
          </div>
        </div>
      );
    });
    return (<div className={className}>{items}</div>);
  }
}
