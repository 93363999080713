import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditIconNavButton, ViewIconNavButton, SecondaryButton } from '../../../components/Buttons';
import { toUIDate, STATUS_IDS, MODEM_ORDER_STATUS_IDS, scrollToTop, TableEmptyMessage, createSortByAttr } from '../../../components/Utils';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadModemOrders, loadOrderAVISFile } from '../actions';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  await props.getOrders();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.modem.loading,
    orders: state.modem.modemOrders
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrders: (...args) => dispatch(loadModemOrders(...args)),
  getOrderAvisFile: (...args) => dispatch(loadOrderAVISFile(...args))
});

class ModemOrderTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    orders: PropTypes.array,
    getOrders: PropTypes.func.isRequired,
    getOrderAvisFile: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 12,
    className: ''
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowDetails = (modem, history) => {
    console.log('Editing', modem);
    history.push(`/wizard/shop/bestellung/modem/${modem.orderNumber}/debitoren`);
  }
  getAvisFile = (order) => {
    this.props.getOrderAvisFile(order);
  }
  render() {
    const { className, isLoading, orders, rows } = this.props;
    // console.log('ModemOrderTable.render()', orders);
    if (!orders) {
      return null;
    }
    const data = (orders || [])
      .map((order, index) => ({
        ...order,
        index,
        order_date: order.orderDate,
        orderDate: toUIDate(order.orderDate, 'YYYY-MM-DDTHH:mm:ss:SSSZ')
      }))
      .sort(createSortByAttr("order_date", true, true));
    const actionTemplate = (rowData) => {
      const id = `editOrderBtn${rowData.index}`;
      const Component = rowData.status_id !== STATUS_IDS.NEW ? ViewIconNavButton : EditIconNavButton;
      return (
        <div>
          <Component id={id} name={id} onClick={(history) => this.onShowDetails(rowData, history)}/>
        </div>
      );
    };
    const avisTemplate = (rowData) => {
      let btnMarkup = null;
      // if (rowData.status_id === MODEM_ORDER_STATUS_IDS.CLOSED) {
      if (rowData.status_id >= MODEM_ORDER_STATUS_IDS.SENT && rowData.avis_path) {
        const id = `getAvisBtn${rowData.index}`;
        btnMarkup = (
          <SecondaryButton
            id={id}
            size="small"
            onClick={() =>this.getAvisFile(rowData)}
          >
            {`AVIS-Datei`}
          </SecondaryButton>
        );
      }
      return (<div>{btnMarkup}</div>);
    }
    return (
      <div className={`modem-order-table clearfix ${className}`}>
        <div className="w3-border no-border-btm pad-big">
          {`Modembestellungen sind für die aktuelle Woche immer bis Montag 10:00 Uhr abzusenden. 
          Aus wirtschaftlichen Gründen ist nur eine Bestellung pro Debitor und Woche zulässig.`}
        </div>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Modembestellungen"
              itemName="Modembestellung"
              itemNamePlural="Modembestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Modembestellungen"/>)}
        >
          <Column
            field="orderNumber"
            header="Bestellnummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '11em'}}
          />
          <Column
            field="companyName"
            header="KDL"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            // style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="orderGiver"
            header="Besteller"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            // style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="orderDate"
            header="Bestelldatum"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            field="week"
            header="KW"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"style={{textAlign:'center', width: '6em'}}
          />
          <Column
            body={avisTemplate}
            header="AVIS"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            field="status"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '4em'}}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ModemOrderTable)
);
