
export const LOAD_TRAINING_LINK = 'training/link/load';
export const LOAD_TRAINING_LINK_SUCCESS = 'training/link/load/success';
export const LOAD_TRAINING_LINK_FAILURE = 'training/link/load/failure';

export const CANCEL_LINK = 'training/link';

export const LOAD_USER_TRAINING = 'training/load';
export const LOAD_USER_TRAINING_SUCCESS = 'training/load/success';
export const LOAD_USER_TRAINING_FAILURE = 'training/load/failure';

export const LOAD_USER_RATING = 'rating/load';
export const LOAD_USER_RATING_SUCCESS = 'rating/load/success';
export const LOAD_USER_RATING_FAILURE = 'rating/load/failure';


export const getTrainingLink = () => {
  console.log('REDUX-ACTION: getTrainingLink() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (user) {
      dispatch({
        types: [ LOAD_TRAINING_LINK, LOAD_TRAINING_LINK_SUCCESS, LOAD_TRAINING_LINK_FAILURE ],
        promise: async (client) => {return {body: ({url:`/data-api/schulung/SsoUrl/`})}}
      });
    }
  };
};

export const cancelTraining = () => {
  return { type: CANCEL_LINK };
};

export const getUserTraining = (userId) => {
  console.log(`REDUX-ACTION: getUserTraining(${userId}) ...`);
  return {
    types: [ LOAD_USER_TRAINING, LOAD_USER_TRAINING_SUCCESS, LOAD_USER_TRAINING_FAILURE ],
    promise: client => client.get(`/schulung/schulungen/${userId}`)
  };
}

export const getMyUserTraining = () => {
  console.log('REDUX-ACTION: getMyUserTraining() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (user) {
      dispatch(getUserTraining(user.id));
    }
  };
}

export const getUserRating = (userId) => {
  console.log(`REDUX-ACTION: getUserRating(${userId}) ...`);
  return {
    types: [ LOAD_USER_RATING, LOAD_USER_RATING_SUCCESS, LOAD_USER_RATING_FAILURE ],
    promise: client => client.get(`/mytechie/averageRating/${userId}`)
  };
}

export const getMyUserRating = () => {
  console.log('REDUX-ACTION: getMyUserRating() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (user) {
      dispatch(getUserRating(user.id));
    }
  };
}
