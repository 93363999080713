import { Column } from "primereact/column";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useEffect, useState } from "react";

import "./style.css";
import ColToggleDataTable from "./ColToggleDataTable";
import SalesTablePageReport from "./SalesTablePageReport";
import SalesTableUtil from "./SalesTableUtil";
import { EditIconNavButton, DeleteIconButton } from "../../../components/Buttons";

const getInitialColumnsToShow = () => {
  return [
    "weborder_id",
    "weborder_date",
    "order_state",
    "state_final",
    "customer_nr",
    "product_code",
    "product_name",
    "product_group",
    "product_npv",
    "vpkn",
    "smb_promo",
    "sales_agent",
    "referrer",
    "delphi_order_nr"
  ];
};

const SalesTable = ({ className, isSalesReportEditor, onEdit, onDelete, rows, value, loading, salesColumns, setSalesColumns, getSales, header, salesOptions, onFilterChange, onSort }) => {
  const dt = useRef(null);
  const actionTemplate = useCallback(
    (rowData /* , column */) => {
      const editBtn = rowData.id && (
        <div className="w3-cell w3-cell-top pad-rht">
          <EditIconNavButton onClick={(history) => onEdit(rowData, history)} />
        </div>
      );
      const deleteBtn = (rowData.pm_weborderid && !rowData.s_weborderid) && (
        <div className="w3-cell w3-cell-top pad-rht">
          <DeleteIconButton className="pad" onClick={() => onDelete(rowData)} />
        </div>
      );
      return (<div className="pad-lr">{editBtn}{deleteBtn}</div>);
    },
    [onEdit, onDelete]
  );
  const actionColumn = !isSalesReportEditor ? null : (
    <Column
      header="Aktionen"
      field="action"
      body={actionTemplate}
      className="report-table-column-action"
    />
  );

  // this effect is used to ensure that the table body has always a functioning
  // horizontal scrollbar
  useEffect(() => {
    const [headerTable] = document.getElementsByClassName(
      "p-datatable-scrollable-header-table",
      dt.current.conainer
    );
    if (!headerTable) {
      return;
    }

    const [bodyTable] = document.getElementsByClassName(
      "p-datatable-scrollable-body-table",
      dt.current.container
    );
    if (!bodyTable) {
      return;
    }

    bodyTable.style.height = "0.01px";
    bodyTable.style.width = `${headerTable.clientWidth}px`;
  });

  // // filter values
  // const [ filterValues, setFilterValues] = useState({});
  // // update filterValues any time salesColumns changes
  // useEffect(() => {
  //   const getFilterValues = (current) => {
  //     const values = {};
  //     salesColumns.forEach(column => {
  //       values[column] = (current && current[column] !== undefined) ? current[column] : null;
  //     });
  //     return values;
  //   };
  //   setFilterValues(getFilterValues(filterValues));
  // }, [salesColumns]);
  // // handle filter change event
  // const onFilterChange = (field, value) => {
  //   const newValues = { ...filterValues };
  //   newValues[field] = value;
  //   // check for change
  //   let isChanged = false;
  //   if (Object.keys(newValues))
  //   for (const column in newValues) {
  //     if (newValues[column] !== filterValues[column]) {
  //       isChanged = true;
  //       break;
  //     }
  //   }
  //   console.log('SalesTable.onFilterChange()', field, value, filterValues, isChanged);
  //   setFilterValues(newValues);
  //   // console.log('SalesTable.onFilterChange()', newValues);
  //   // getSales(newValues);
  // };

  // const useBooleanFilter = SalesTableUtil.createUseBooleanFilter(dt.current, onFilterChange);
  // const useDateFilter = SalesTableUtil.createUseDateFilter(dt.current, onFilterChange);
  const useRangeFilter = SalesTableUtil.createUseRangeFilter(dt.current, onFilterChange);
  const useInputFilter = SalesTableUtil.createUseInputFilter(dt.current, 400, onFilterChange);
  const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(dt.current, value, salesOptions, onFilterChange);

  const regionFilter = useMultiSelectFilter("region");
  const weborderIdFilter = useInputFilter("weborder_id");
  const weborderDateFilter = useRangeFilter("weborder_date");
  const weborderWeekFilter = useMultiSelectFilter("weborder_week");
  const weborderStateFilter = useMultiSelectFilter("weborder_state");
  const workorderStateFilter = useMultiSelectFilter("workorder_state");
  const workorderClosedAtFilter = useRangeFilter("workorder_closed_at");
  const revokedFilter = useMultiSelectFilter("revoked"); // useBooleanFilter
  const orderStateFilter = useMultiSelectFilter("order_state");
  const stateFinalFilter = useMultiSelectFilter("state_final");
  const customerNrFilter = useInputFilter("customer_nr");
  const customerNameFilter = useMultiSelectFilter("customer_name");
  const productCodeFilter = useMultiSelectFilter("product_code");
  const productNameFilter = useMultiSelectFilter("product_name");
  const productGroupFilter = useMultiSelectFilter("product_group");
  const productNpvFilter = useMultiSelectFilter("product_npv");
  const vpknFilter = useMultiSelectFilter("vpkn");
  const companyNameFilter = useMultiSelectFilter("company_name");
  const importCreatedAtFilter = useRangeFilter("import_created_at");
  const importUpdatedAtFilter = useRangeFilter("import_updated_at");
  const projectNameFilter = useMultiSelectFilter("project_name");
  const smbPromoFilter = useMultiSelectFilter("smb_promo"); // useBooleanFilter
  const salesAgentFilter = useMultiSelectFilter("sales_agent");
  const referrerFilter = useMultiSelectFilter("referrer");
  const delphiOrderNrFilter = useInputFilter("delphi_order_nr");
  const delphiOrderClassFilter = useMultiSelectFilter("delphi_order_class");
  const delphiOrderReasonFilter = useMultiSelectFilter("delphi_order_reason");
  const delphiOrderBzuFilter = useMultiSelectFilter("delphi_order_bzu");
  const createdAtFilter = useRangeFilter("created_at");
  const createdByFilter = useInputFilter("created_by");
  const updatedAtFilter = useRangeFilter("updated_at");

  const [
    sortProps,
    sortColumnProps,
    sorting
  ] = SalesTableUtil.useAsyncSort(dt.current, value);

  console.log('SalesTable:', salesColumns);
  // const [columnsToShow, setColumnsToShow] = useState(getInitialColumnsToShow);

  const [filteredCount, setFilteredCount] = useState(value.length);
  useEffect(() => {
    setFilteredCount(value.length);
  }, [value.length]);
  const [firstRow, setFirstRow] = useState(0);

  const handlePaging = useCallback((event) => {
    console.log("<<<<<<<<<<<<<<<<<<<<  +++++++++++++ handlePaging event: ", event);
    setFirstRow(event.first);
    getSales(event);
  }, []);

  const handleValueChange = useCallback((nextValue) => {
    // when paging, `nextValue` is undefined
    console.log("<<<<<<<<<<<<<<<<<<<<  +++++++++++++ handleValueChange nextValue: ", nextValue);
    if (nextValue) {
      setFirstRow(0);
      setFilteredCount(nextValue.length);
    }
  }, []);
  // console.log("<<<<<<<<<<<<<<<<<<<<  header : ", header);
  const totalRecords = header ? header["x-result-count"] : value.length;
  // console.log("<<<<<<<<<<<<<<<<<<<< totalRecords, value.length, filteredCount, firstRow, rows : ",
  //   totalRecords, value.length, filteredCount, firstRow, rows);

  return (
    <div className={`report-table clearfix ${className}`}>
      <ColToggleDataTable
        id="report-table"
        // columnsToShow={columnsToShow}
        // onSelectColumn={setColumnsToShow}
        columnsToShow={salesColumns}
        onSelectColumn={setSalesColumns}
        // overriden by `sortProps`
        value={value}
        rowClassName={SalesTableUtil.getRowClassName}
        rows={rows}
        scrollable
        loading={loading}
        ref={dt}
        // make sure to update the `font-size` when changing the
        // `virtualRowHeight'
        virtualRowHeight={19}
        onValueChange={handleValueChange}
        first={firstRow}
        lazy
        totalRecords={Number(totalRecords)}
        paginator
        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink"
        onPage={handlePaging}
        paginatorLeft={
          <SalesTablePageReport
            totalRecords={totalRecords}
            filteredRecords={filteredCount}
            first={firstRow}
            rows={rows}
          />
        }
        // {...sortProps}
        onSort={onSort}
      >
        {SalesTableUtil.createColumn({
          header: "Region",
          field: "region",
          ...regionFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Weborder ID",
          field: "weborder_id",
          ...weborderIdFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "Weborderdatum",
          field: "weborder_date",
          ...weborderDateFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createCalendarWeekColumn({
          header: "Weborder KW",
          field: "weborder_week",
          ...weborderWeekFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Weborderstatus",
          field: "weborder_state",
          ...weborderStateFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "WO-Status",
          field: "workorder_state",
          ...workorderStateFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "WO-Closed",
          field: "workorder_closed_at",
          ...workorderClosedAtFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createBooleanColumn({
          header: "Widerruf Kunde",
          field: "revoked",
          ...revokedFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Vertragsstatus",
          field: "order_state",
          ...orderStateFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Status Final",
          field: "state_final",
          ...stateFinalFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Kundennummer",
          field: "customer_nr",
          ...customerNrFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Kundenname",
          field: "customer_name",
          ...customerNameFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Produktcode",
          field: "product_code",
          ...productCodeFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Produktname",
          field: "product_name",
          ...productNameFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Produktgruppe",
          field: "product_group",
          ...productGroupFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "NPV",
          field: "product_npv",
          ...productNpvFilter,
          ...sortColumnProps,
        })}
        {SalesTableUtil.createColumn({
          header: "VPKN",
          field: "vpkn",
          ...vpknFilter,
          ...sortColumnProps,
        })}
        {SalesTableUtil.createColumn({
          header: "Partner",
          field: "company_name",
          ...companyNameFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "Create Date",
          field: "import_created_at",
          ...importCreatedAtFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "Change Date",
          field: "import_updated_at",
          ...importUpdatedAtFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Projekt",
          field: "project_name",
          ...projectNameFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createBooleanColumn({
          header: "SMB-Promo",
          field: "smb_promo",
          ...smbPromoFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Sales Agent",
          field: "sales_agent",
          ...salesAgentFilter,
          ...sortColumnProps,
          style: {minWidth: '160px'}
        })}
        {SalesTableUtil.createColumn({
          header: "Tippgeber",
          field: "referrer",
          ...referrerFilter,
          ...sortColumnProps,
          style: {minWidth: '160px'}
        })}
        {SalesTableUtil.createColumn({
          header: "DELPHI Auftragsnr.",
          field: "delphi_order_nr",
          ...delphiOrderNrFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "DELPHI Auftragsklasse",
          field: "delphi_order_class",
          ...delphiOrderClassFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "DELPHI Ursache",
          field: "delphi_order_reason",
          ...delphiOrderReasonFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "DELPHI bzu",
          field: "delphi_order_bzu",
          ...delphiOrderBzuFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "Datum gemeldet",
          field: "created_at",
          ...createdAtFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createColumn({
          header: "Creator",
          field: "created_by",
          ...createdByFilter,
          ...sortColumnProps
        })}
        {SalesTableUtil.createDateColumn({
          header: "Datum geändert",
          field: "updated_at",
          ...updatedAtFilter,
          ...sortColumnProps
        })}
        {actionColumn}
      </ColToggleDataTable>
    </div>
  );
};

SalesTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  header: PropTypes.object,
  salesOptions: PropTypes.shape({}),
};

SalesTable.defaultProps = {
  className: null,
  loading: false
};

export default SalesTable;
