import React from "react";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import {
  validateAreaCode,
  createSortByLabel,
} from "../../../../components/Utils";

export default class CompanyAddressForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    companies: PropTypes.any,
    model: PropTypes.object,
    isVertical: PropTypes.bool,
    namePrefix: PropTypes.string,
    company: PropTypes.object,
    getCompany: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      company_id: "",
      name: "",
      address: {
        street: "",
        houseNumber: "",
        areaCode: "",
        city: "",
      },
    },
    companies: [],
    namePrefix: "",
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  // componentWillReceiveProps(props) {
  //   console.log('Props', this.props, 'Next props', props);
  //   this.setState(this.getState(props));
  // }
  getState = (props) => {
    const { model } = props;
    const address = model.address || {};
    return {
      company_id: model.company_id || "",
      name: model.name || "",
      street: address.street || "",
      houseNumber: address.houseNumber || "",
      areaCode: address.areaCode || "",
      city: address.city || "",
      error: {
        name: null,
        street: null,
        houseNumber: null,
        areaCode: null,
        city: null,
      },
    };
  };
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined && model[key] === "") {
        error[key] = true;
        isValid = false;
      }
    });
    if (!validateAreaCode(model.areaCode)) {
      isValid = false;
      error.areaCode = true;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    const { company_id, name, ...address } = model;
    const newModel = { name, address };
    if (this.props.model && this.props.model.address) {
      newModel.address = { ...this.props.model.address, ...address };
    }
    // get company id
    // newModel.company_id = (company_id && company_id !== '') ? parseInt(company_id) : 0;
    const companyId = parseInt(company_id);
    newModel.company_id = !isNaN(companyId) ? companyId : 0;
    return newModel;
  };
  getModel = () => {
    const model = this.validate();
    // console.log('CompanyAddressForm.getModel()', model);
    return model;
  };
  setCompany = (company_id) => {
    const { companies, getCompany, company } = this.props;
    // console.log(
    //   "CompanyAddressForm.setCompany() id:",
    //   company_id,
    //   "companies",
    //   companies,
    //   "company",
    //   company
    // );
    let state = { company_id };
    // company is new and not yet saved
    if (!companies || companies.length === 0) {
      const company = getCompany();
      const { street, houseNumber, areaCode, city } = company.address;
      state = {
        ...state,
        name: company.name,
        street,
        houseNumber,
        areaCode,
        city,
      };
    } else {
      console.log(">> find company by id", company_id);
      let found = (companies || []).find((item) => item.id === company_id);
      if (!found && company && company_id === company.id) {
        found = company;
      }
      if (found) {
        const address = isArray(found.address)
          ? found.address[0]
          : found.address;
        const { street, houseNumber, areaCode, city } = address;
        state = {
          ...state,
          name: found.name,
          street,
          houseNumber,
          areaCode,
          city,
        };
      }
    }
    this.setState(state);
  };
  renderStreetHouse = (model, className) => {
    const { namePrefix, disabled } = this.props;
    const { name, street, houseNumber } = model || {};
    return (
      <div className={`${className}`}>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}address_form_street`}
        >{`Straße`}</Label>
        <Label className="display-inline-block">{`/`}</Label>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}address_form_housenumber`}
        >{`Hausnummer *`}</Label>
        <div className="w3-row">
          <div className="w3-col s8 pad-sm-rht">
            <InputText
              id={`${namePrefix}address_form_street`}
              className={`w3-block w3-border`}
              value={street}
              disabled={disabled || name === ""}
              onChange={(event) =>
                this.setState({ street: event.target.value })
              }
            />
          </div>
          <div className="w3-col s4 pad-sm-lft">
            <InputText
              id={`${namePrefix}address_form_housenumber`}
              className={`w3-block w3-border`}
              value={houseNumber}
              disabled={disabled || name === ""}
              onChange={(event) =>
                this.setState({ houseNumber: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  renderAreaCity = (model, className) => {
    const { namePrefix, disabled } = this.props;
    const { name, areaCode, city } = model || {};
    return (
      <div className={`${className}`}>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}area_code`}
        >{`PLZ`}</Label>
        <Label className="display-inline-block">{`/`}</Label>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}city`}
        >{`Ort *`}</Label>
        <div className="w3-row">
          <div className="w3-col s4 pad-sm-rht">
            <InputText
              id={`${namePrefix}area_code`}
              className={`w3-block w3-border`}
              value={areaCode}
              disabled={disabled || name === ""}
              onChange={(event) =>
                this.setState({ areaCode: event.target.value })
              }
            />
          </div>
          <div className="w3-col s8 pad-sm-lft">
            <InputText
              id={`${namePrefix}city`}
              className={`w3-block w3-border`}
              value={city}
              disabled={disabled || name === ""}
              onChange={(event) => this.setState({ city: event.target.value })}
            />
          </div>
        </div>
      </div>
    );
  };
  render() {
    const {
      className,
      companies,
      isVertical,
      namePrefix,
      company,
      model,
      disabled,
    } = this.props;
    const { company_id, name, error } = this.state;
    const isSaved = model && (model.address.id || model.id);

    const CompanyOptions = (companies || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));
    if (company) {
      CompanyOptions.push({ label: company.name, value: company.id });
      CompanyOptions.sort(createSortByLabel());
    }
    const companyMarkup = isSaved ? null : (
      <div className={`pad-top form-group-item`}>
        <Label htmlFor={`${namePrefix}company_id`}>{`Firma*`}</Label>
        <Dropdown
          id={`${namePrefix}company_id`}
          className={`w3-block w3-border`}
          value={company_id}
          placeholder="Bitte auswählen"
          options={CompanyOptions}
          onChange={(event) => this.setCompany(event.value)}
          showClear
          disabled={disabled}
        />
      </div>
    );
    return (
      <div className={className}>
        {companyMarkup}
        <div className="pad-top form-group-item">
          <Label htmlFor={`${namePrefix}name`}>{`Firmenname*`}</Label>
          <InputText
            id={`${namePrefix}name`}
            className={`w3-block w3-border ${
              error.name ? "w3-border-red" : ""
            }`}
            value={name}
            disabled={disabled || company_id === ""}
            onChange={(event) => this.setState({ name: event.target.value })}
          />
        </div>
        <div className="neg-margin-lr">
          <div className="w3-row">
            {this.renderStreetHouse(
              this.state,
              `w3-col ${
                isVertical ? "m12" : "m6"
              } pad-lr pad-top form-group-item`
            )}
            {this.renderAreaCity(
              this.state,
              `w3-col ${
                isVertical ? "m12" : "m6"
              } pad-lr pad-top form-group-item`
            )}
          </div>
        </div>
      </div>
    );
  }
}
