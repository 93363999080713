import React from 'react';
import PropTypes from 'prop-types';
import { Label, Dropdown, CalendarRange } from '../../../components/Inputs';
import { DisplayOptions } from './DisplayUtils';
import { toDBDateTime } from '../../../components/Utils';

export default class TimeRangeFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    onCancel: PropTypes.func,
    toggleFormState: PropTypes.func,
    intervalChanged: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    model: {
      fromDate: null,
      toDate: null,
      display: '',
    }
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillUnmount() {
    this.props.onCancel && this.props.onCancel();
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      fromDate: model.fromDate || null,
      toDate: model.toDate || null,
      display: model.display || '',
      error: {
        range: null,
        display: null
      }
    }
  }
  validate = (state, noRefresh) => {
    const { error, ...model } = (state || this.state);
    // check if all values are set
    let isValid = true;
    // dates
    if (!model.fromDate || !model.toDate) {
      error.range = true;
      isValid = false;
    } else {
      error.range = null;
    }
    // display
    if (model.display === '') {
      error.display = true;
      isValid = false;
    } else {
      error.display = null;
    }
    // console.log('validated:', model, error, !noRefresh);
    if (!noRefresh) {
      // console.log('>> refreshing ...')
      this.setState({ error });
    }
    if (!isValid) {
      return null;
    }
    const original = this.props.model || {};
    return {
      ...original,
      fromDate: toDBDateTime(model.fromDate, null),
      toDate: toDBDateTime(model.toDate, null),
      display: model.display
    };
  }
  getModel = (noRefresh) => {
    const model = this.validate(null, noRefresh);
    return model;
  }
  setDates = (dates) => {
    console.log('TimeRangeFilter.setDates()', dates);
    const state = {
      fromDate: (dates && dates.length > 0) ? dates[0] : null,
      toDate: (dates && dates.length > 1) ? dates[1] : null
    };
    this.setState(state);
    this.props.intervalChanged(state);
    this.checkValidity({ ...this.state, ...state });
  }
  setDate = (key, value) => {
    // console.log('TimeRangeFilter.setDate()', key, value);
    const state = { [key]: value };
    if (key === 'fromDate') {
      state.toDate = null;
      // const toDate = moment(state.toDate);
      // if (toDate.isValid() && toDate.isBefore(moment(value))) {
      //   state.toDate = null;
      // }
    }
    this.setState(state);
    t
    this.checkValidity({ ...this.state, ...state });
  }
  setDisplay = (display) => {
    const state = { display };
    this.setState(state);
    this.checkValidity({ ...this.state, ...state });
  }
  checkValidity = (state) => {
    const { toggleFormState } = this.props;
    if (!toggleFormState) return;
    const model = this.validate(state, true);
    toggleFormState(model !== null);
  }
  render() {
    const { className } = this.props;
    const { fromDate, toDate, display, error } = this.state;
    console.log('TimeRangeFilter.render()', this.state);
    const range = [];
    fromDate && range.push(fromDate);
    toDate && range.push(toDate);
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.range && 'error-group'}`}>
            <Label htmlFor="range">{'Zeitraum von - bis'}</Label>
            <CalendarRange
              id="range"
              className="w3-block w3-border w3-round"
              value={range}
              dateFormat="dd.mm.yy"
              maxDate={new Date()}
              onChange={(event) => this.setDates(event.value)}
            />
          </div>
          {/* <div className={`w3-col s3 pad-lr form-group-item pad-top ${error.fromDate && 'error-group'}`}>
            <Label htmlFor="fromDate">{'Zeitraum von'}</Label>
            <Calendar
              id="fromDate"
              className="w3-block w3-border w3-round"
              value={fromDate}
              dateFormat="dd.mm.yy"
              maxDate={new Date()}
              onChange={(event) => this.setDate('fromDate', event.value)}
            />
          </div>
          <div className={`w3-col s3 pad-lr form-group-item pad-top ${error.toDate && 'error-group'}`}>
            <Label htmlFor="toDate">{'Zeitraum bis'}</Label>
            <Calendar
              id="toDate"
              className="w3-block w3-border w3-round"
              value={toDate}
              dateFormat="dd.mm.yy"
              minDate={fromDate}
              maxDate={new Date()}
              onChange={(event) => this.setDate('toDate', event.value)}
            />
          </div> */}
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.display && 'error-group'}`}>
            <Label htmlFor="display">{`Darstellung`}</Label>
            <Dropdown
              id="display"
              className="w3-block w3-border"
              value={display}
              placeholder="Bitte auswählen"
              options={DisplayOptions}
              onChange={(event) => this.setDisplay(event.value)}
              showClear
            />
          </div>
        </div>
      </div>
    );
  }
}
