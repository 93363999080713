import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { Label } from './Inputs';

export default class DescriptionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    label: 'Bemerkungen',
    model: {
      description: ''
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      description: model.description || '',
      error: {}
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    const { id } = this.props.model;
    return { id, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    const { className, disabled, label } = this.props
    const { description } = this.state;
    return (
      <div className={className}>
        <Label htmlFor="description">{label}</Label>
        <InputTextarea
          id="description"
          className="w3-block w3-border"
          value={description}
          rows={3}
          autoResize
          disabled={disabled}
          onChange={(event) => this.setState({description: event.target.value})}
        />
      </div>
    );
  }
}

