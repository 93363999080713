import * as actionTypes from '../actions/form';
// import isArray from 'lodash/isArray';
import {
  notifyError,
  notifySuccess
} from '../../../components/Utils';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';

const initialState = {
  bookingIsVisible: false,
  bookingSettings: {
    email: ''
  },
  bookingProducts: [],
  bookingPromos: [],
	bookingVouchers: [],

  mnvTipIsVisible: false,
  mnvTipSettings: {
    email: ''
  },
  mnvTipTechSupplyOptions: [],
  mnvTipTvFilterOptions: [],
  mnvTipContactSources: [],
  mnvTipInterests: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // show settings
    case actionTypes.SHOW_SETTINGS:
      return {
        ...state,
        [`${action.key}IsVisible`]: true
      };
    // show settings
    case actionTypes.HIDE_SETTINGS:
      return {
        ...state,
        [`${action.key}IsVisible`]: false
      };
      
    // load settings
    case actionTypes.LOAD_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOAD_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        [`${action.key}Settings`]: action.result
      };
    }
    case actionTypes.LOAD_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save settings
    case actionTypes.SAVE_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SAVE_SETTINGS_SUCCESS: {
      notifySuccess({
        summary: `Einstellungen: ${action.key === 'booking' ? 'Buchungsanforderung' : 'MNV-Tipp'}`,
        detail: `Die Formulareinstellungen wurden erfolgreich aktualisiert.`
      });
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.SAVE_SETTINGS_FAILURE: {
      notifyError({
        summary: `Einstellungen: ${action.key === 'booking' ? 'Buchungsanforderung' : 'MNV-Tipp'}`,
        detail: `Die Formulareinstellungen konnten nicht aktualisiert werden.`
      });
      return {
        ...state,
        loading: false,
      };
    }

    // load booking form data
    case actionTypes.LOAD_BOOKING_FORM_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOAD_BOOKING_FORM_DATA_SUCCESS: {
      // console.log('Booking Form Data:', action.result);
      const { products, vouchers, promos } = action.result;
      return {
        ...state,
        loading: false,
        bookingProducts: products,
        bookingVouchers: vouchers,
        bookingPromos: promos
      };
    }
    case actionTypes.LOAD_BOOKING_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save booking
    case actionTypes.SAVE_BOOKING:
        return {
          ...state,
          loading: true
        };
    case actionTypes.SAVE_BOOKING_SUCCESS:
      notifySuccess({
        summary: 'Buchungsanforderungen',
        detail: `Ihre Daten wurden erfolgreich übermittelt!`
      });
      return {
        ...state,
        loading: false
      };
    case actionTypes.SAVE_BOOKING_FAILURE: {
      notifyError({
        summary: 'Buchungsanforderungen',
        detail: `Ihre Daten konnten nicht übermittelt werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    // load mnvTip form data
    case actionTypes.LOAD_MNVTIP_FORM_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOAD_MNVTIP_FORM_DATA_SUCCESS: {
      const { techSupplyOptions, tvFilterOptions, contactSources, interests } = action.result;
      return {
        ...state,
        loading: false,
        mnvTipTechSupplyOptions: techSupplyOptions,
        mnvTipTvFilterOptions: tvFilterOptions,
        mnvTipContactSources: contactSources,
        mnvTipInterests: interests
      };
    }
    case actionTypes.LOAD_MNVTIP_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save mnvTip
    case actionTypes.SAVE_MNVTIP:
        return {
          ...state,
          loading: true
        };
    case actionTypes.SAVE_MNVTIP_SUCCESS:
      notifySuccess({
        summary: 'MNV-Tipp',
        detail: `Ihre Daten wurden erfolgreich übermittelt!`
      });
      return {
        ...state,
        loading: false
      };
    case actionTypes.SAVE_MNVTIP_FAILURE: {
      notifyError({
        summary: 'MNV-Tipp',
        detail: `Ihre Daten konnten nicht übermittelt werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    // renitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };

      default:
        return {...state};
    }
  }  
