import React from 'react';
import { hydrate } from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { Provider } from 'react-redux';
// import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import createStore from './redux/store';

// constants
window.ENABLE_2FA = decodeURIComponent(escape(atob(window.CONSTANTS[0])));
window.TAG = decodeURIComponent(escape(atob(window.CONSTANTS[1])));
window.NODE_ENV = decodeURIComponent(escape(atob(window.CONSTANTS[2])));

const { store, history } = createStore();
const Application = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Frontload noServerRender={true}>
        <App />
      </Frontload>
    </ConnectedRouter>
  </Provider>
);

hydrate(
  Application,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
