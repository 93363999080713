import moment from 'moment';
import MODEMS from './data/modems';
import DEBITORS from './data/debitors';

const MODEM_ORDERS = [];

export const TESTING = false;

export const getModems = () => {
  return [ ...MODEMS ];
};

export const getModem = ({ id }) => {
  return MODEMS.find(modem => (`${modem.id}` === `${id}`));
};

export const saveModem = (modem) => {
  MODEMS.push({ ...modem, id: MODEMS.length + 1});
};


function getOrderForDebitor(debitorKey, week) {
  const order = MODEM_ORDERS.find(order => (order.debitor.debitorKey === debitorKey && order.debitor.week === week) );
  return order;
}
export const getModemOrderDebitors = ({ week }) => {
  const debitors = DEBITORS.map(item => {
    const order = getOrderForDebitor(item.debitorKey, week);
    return { ...item, isOrdered: (order ? true : false) };
  });
  return debitors;
}

export const getModemOrders = (user, props = {}) => {
  // console.log('Test.getModemOrders() user', user, MODEM_ORDERS);
  // const filterMethod = (order) => {
  //   const tests = [() => (`${order.userId}` === `${user.id}`)];
  //   Object.keys(props).forEach(key => {
  //     if (key === 'week' || key === 'debitorKey') tests.push(() => (order.debitor ? order.debitor[key] === props[key] : true));
  //   });
  //   for(const test of tests) {
  //     const isValid = isValid && test();
  //     if (!isValid) return false;
  //   }
  //   return true;
  // }
  // return MODEM_ORDERS.filter(filterMethod);
  const orders = MODEM_ORDERS.filter(order => (`${order.userId}` === `${user.id}`));
  console.log('Test.getModemOrders() userid', user.id, MODEM_ORDERS, orders);
  return orders;
};

export const getModemOrder = ({ id }) => {
  return MODEM_ORDERS.find(order => (`${order.id}` === `${id}`));
};

export const saveModemOrder = (order) => {
  const orderDate = moment().format('DD.MM.YYYY');
  const id = `${MODEM_ORDERS.length + 1}`;
  MODEM_ORDERS.push({
    ...order,
    id,
    orderDate,
    orderNumber: `M000${id}${orderDate.split('.').join('')}`
  });
};
