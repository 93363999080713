import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  createSortByAttr,
  DEBITOR_STATUS_LABELS,
  PageHeight40,
  scrollToTop,
  TableEmptyMessage
} from '../../../components/Utils';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadDebitorMapping } from '../actions';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => (
  await props.getDebitorMapping()
);
const mapStateToProps = (state) => {
  return {
    isLoading: state.debitorMapping.loading,
    debitorMapping: state.debitorMapping.mappings
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDebitorMapping: (...args) => dispatch(loadDebitorMapping(...args)),
});

class DebitorMappingTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    debitorMapping: PropTypes.arrayOf(PropTypes.shape({
      debitorKey: PropTypes.string,
      storage_location: PropTypes.string,
    })),
    getDebitorMapping: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    console.log('DebitorMappingTable.render()', this.props);
    const { className, isLoading, debitorMapping, rows } = this.props;
    const data = debitorMapping.map((item, index) => {
      const copy = JSON.parse(JSON.stringify(item));
      const { street, house, areacode, city } = item;
      const addressStr = `${item.name}, ${street} ${house}, ${areacode} ${city}`;
      const status = item.status || DEBITOR_STATUS_LABELS[item.status_id];
      return {
        ...copy,
        regionName: item.region || item.regionName,
        index,
        addressStr,
        status
      };
    }).sort(createSortByAttr('debitorKey'));
    return (
      <PageHeight40 className={`modem-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Kein Mapping"
              itemName="Debitor-Mapping"
              itemNamePlural="Debitor-Mapping"
            />
          }
          rows={rows}
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Debitor-Mapping"/>)}
        >
          <Column
            field="region"
            header="Region"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '12rem'}}
          />
          <Column
            field="storageLocation"
            header="Storage Location"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '12rem'}}
            body={(row) => (
              <span
                className="display-inline-block"
                style={{textAlign:"left",width:"4rem"}}
              >
                {row.storageLocation}
              </span>
            )}
          /> 
          <Column
            field="companyName"
            header="Firma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            // style={{width: '25%'}}
          /> 
          {/* <Column
            field="addressStr"
            header="Adresse"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Freigabestatus"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center', width: '10em'}}
          />  */}
        </DataTable>
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(DebitorMappingTable)
);
