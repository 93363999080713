import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { notifyError } from '../../../../components/Utils';

export default class BasicPageContentForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: { title: '', subtitle: '' }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      title: model.title || '',
      subtitle: model.subtitle || '',
      error: {
        title: null
      }
    };
  }
  validate = (noValidate) => {
    const { error, title, subtitle } = this.state;
    let isValid = true;
    if (!title || title === '') {
      error.title = true;
      isValid = false;
    }
    const model = { title, subtitle };
    if (!noValidate && !isValid) {
      notifyError({
        summary: `Seiteninhalt`,
        detail: `Sie müssen einen Seitentitel angeben!`
      });
      this.setState({ error });
      return null;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    // console.log('BasicPageContentForm.render()', this.state);
    const { className } = this.props
    const { title, subtitle, error } = this.state;
    return (
      <div className={`${className} pad-big`}>
        <div className={`form-group-item ${error.title ? 'error-group' : ''}`}>
          <Label htmlFor="title">{`Titel`}<span>*</span></Label>
          <InputText id="title" className="w3-block w3-border" value={title}
            onChange={(event) => this.setState({title: event.target.value})}/>
          {error.title && (<span className="w3-block w3-red">{error.title}</span>)}
        </div>
        <div className="form-group-item pad-top">
          <Label htmlFor="subtitle">{`Untertitel`}</Label>
          <InputText id= "subtitle" className="w3-block w3-border" value={subtitle}
            onChange={(event) => this.setState({subtitle: event.target.value})}/>
        </div>
      </div>
    );
  }
}
