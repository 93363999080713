import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { PrimaryButton, ButtonLink } from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../components/W3Dropdown';
import {
  MODEM_ORDER_STATUS_IDS,
  getModemOrderMoveConfirmationText,
  getWeekOptions,
  getModemOrderMoveWeekOptions,
} from '../../../components/Utils';

import { connect } from 'react-redux';
import {
  loadWeeklyModemOrders,
  lockModemOrders,
  loadLoxxessOrderCSVFile,
  loadLoxxessAddressCSVFile,
  changeOrderWeek
} from '../actions';
import MoveWeekDialog from '../components/task/MoveWeekDialog';
// import { selectCard } from '../../Product/actions/cards';

const mapStateToProps = (state) => ({
  orderWeeklyOverview: state.modem.modemOrderWeeklyOverview,
  selectedModems: state.modem.selectedModems,
  taskWeek: state.modem.taskWeek,
});
const mapDispatchToProps = (dispatch) => ({
  getWeeklyModemOrders: (...args) => dispatch(loadWeeklyModemOrders(...args)),
  lockOrders: (...args) => dispatch(lockModemOrders(...args)),
  getOrdersAsCSV: (...args) => dispatch(loadLoxxessOrderCSVFile(...args)),
  getAddressesAsCSV: (...args) => dispatch(loadLoxxessAddressCSVFile(...args)),
  changeWeek: (...args) => dispatch(changeOrderWeek(...args)),
});

export class ModemAdminTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    orderWeeklyOverview: PropTypes.array,
    selectedModems: PropTypes.array,
    taskWeek: PropTypes.object,
    history: PropTypes.object,
    getWeeklyModemOrders: PropTypes.func.isRequired,
    lockOrders: PropTypes.func.isRequired,
    getOrdersAsCSV: PropTypes.func.isRequired,
    getAddressesAsCSV: PropTypes.func.isRequired,
    changeWeek: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    actions: {}
  }
  constructor(props) {
    super(props);
    const state = this.getState(props);
    this.state = state;
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const {
      orderWeeklyOverview,
      selectedModems,
      taskWeek
    } = props;
    const orderNumbers = selectedModems ? selectedModems.map(item => item.orderNumber) : [];
    let selected = [];
    if (orderWeeklyOverview && orderWeeklyOverview.length > 0) {
      selected = orderWeeklyOverview.filter(item => orderNumbers.includes(item.orderNumber));
    }
    console.log('ModemAdminTaskMenu.render()', selectedModems, selected);
    const newItems = [];
    const editItems = [];
    const sentItems = [];
    selected.forEach(item => {
      if (item.status_id === MODEM_ORDER_STATUS_IDS.NEW) { newItems.push(item); }
      else if (item.status_id === MODEM_ORDER_STATUS_IDS.PROCESSING) { editItems.push(item); }
      else if (item.status_id === MODEM_ORDER_STATUS_IDS.SENT) { sentItems.push(item); }
    });
    return {
      newItems,
      editItems,
      sentItems,
      selected,
      taskWeek: taskWeek || { week: 0, year: 0 }
    };
  }
  onLockOrders = (event) => {
    if (event) event.preventDefault();
    const { lockOrders } = this.props;
    const { newItems } = this.state;
    this.dialog.show(
      true,
      {
        title: 'Neue Bestellungen abnehmen',
        text: `
          Durch die Abnahme, werden alle neuen Bestellungen in die Bearbeitung genommen und so für jegliche 
          Anpassung durch die Besteller gesperrt. Wollen Sie die ausgewählte Bestellung${newItems.length > 1 ? 'en' : ''} nun abnehmen?
        `
      },
      () => lockOrders(newItems)
    );
  }
  onDownloadCSV = (event) => {
    if (event) event.preventDefault();
    this.props.getOrdersAsCSV(this.state.sentItems);
  }
  onDownloadAddresses = (event) => {
    if (event) event.preventDefault();
    this.props.getAddressesAsCSV();
  }
  onMoveToWeek = (event) => {
    if (event) event.preventDefault();
    const { taskWeek, newItems } = this.state;
    const map = {};
    newItems.forEach(item => {
      map[item.parentDebitorKey] = item;
    });
    const debitors = Object.keys(map);
    const orders = debitors.map(item => map[item]);
    this.weekDialog.show(
      true,
      {
        title: `Bestellungen verschieben`,
        text: getModemOrderMoveConfirmationText(debitors),
        fromWeek: taskWeek.week,
        toWeek: `${taskWeek.week}:${taskWeek.year}`,
      },
      (data) => {
        const [week, year] = data.toWeek.split(":");
        const props = orders.map(item => {
          const { id, orderNumber, order_debitor_id } = item;
          return { id, orderNumber, order_debitor_id, week, year };
        });
        console.log(`Moving from ${taskWeek.week}(${taskWeek.year}) to ${week}(${year});`, props);
        this.props.changeWeek(props);
      }
    );
  }
  setWeek = (week) => {
    const { taskWeek } = this.state;
    this.props.getWeeklyModemOrders({
      week,
      year: taskWeek.year,
      isTask: true
    });
  }
  setYear = (year) => {
    const { taskWeek } = this.state;
    // console.log("setYear", taskWeek, year);
    this.props.getWeeklyModemOrders({
      week: taskWeek.week,
      year,
      isTask: true
    });
  }
  render() {
    const { className } = this.props;
    const {
      newItems,
      editItems,
      sentItems,
      taskWeek
    } = this.state;
    console.log('ModemAdminTaskMenu.render()', this.state);

    const isLockable = (newItems.length > 0);
    const isApprovable = (editItems.length > 0);
    const isCSVDownloadable = (sentItems.length > 0);
    const isMoveable = (newItems.length > 0);

    const maxCount = moment().year() + 2/* 6 */ - 2020;
    const yearOptions = [];
    for (let count = 0; count < maxCount; count ++) {
      const value = 2020 + count;
      yearOptions.push({ label: `${value}`, value });
    }
    const weekOptions = getWeekOptions({year: taskWeek.year});

    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-rht">
            <Dropdown
              id="year"
              className="w3-block w3-border"
              style={{width: '100px'}}
              value={taskWeek.year}
              placeholder="Jahr"
              options={yearOptions}
              onChange={(event) => this.setYear(event.value)}
              showClear
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-rht">
            <Dropdown
              id="week" 
              className="w3-block w3-border"
              style={{width: '250px'}}
              value={taskWeek.week}
              placeholder="Kalendarwoche"
              options={weekOptions}
              onChange={(event) => this.setWeek(event.value)}
              showClear
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler as={PrimaryButton}>{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '280px'}}>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onLockOrders}
                  disabled={!isLockable}>
                    {`Bestellungen abnehmen`}
                </ButtonLink>
                <ButtonLink
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  to="/wizard/aufgabe/bestellung/modems"
                  disabled={!isApprovable}>
                    {`Bestellungen freigeben`}
                </ButtonLink>
                <a href="#" className="w3-bar-item separator"><div className="w3-border-top" style={{margin: '0 -4px'}}/></a>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onMoveToWeek}
                  disabled={!isMoveable}>
                    {`Bestellungen verschieben`}
                </ButtonLink>
                <a href="#" className="w3-bar-item separator"><div className="w3-border-top" style={{margin: '0 -4px'}}/></a>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onDownloadCSV}
                  disabled={!isCSVDownloadable}>
                    {`CSV herunterladen`}
                </ButtonLink>
                <a href="#" className="w3-bar-item separator"><div className="w3-border-top" style={{margin: '0 -4px'}}/></a>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onDownloadAddresses}>
                    {`Loxxess-Adressen herunterladen`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
        <MoveWeekDialog
          ref={ref => this.weekDialog = ref}
          weekOptions={getModemOrderMoveWeekOptions({ year: taskWeek.year, showNextYear: true })}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemAdminTaskMenu);
