import React from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

export class Accordion extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    activeIndex: PropTypes.number,
    onTabChange: PropTypes.func,
    onItemDragged: PropTypes.func,
    children: PropTypes.node,
    notDraggable: PropTypes.bool,
    singleActiveIndex: PropTypes.bool,
    onOpenTabsChanged: PropTypes.func
  }
  static defaultProps = {
    className: '',
    onTabChange: null
  }
  constructor(props) {
    super(props);
    this.state = { activeIndex: props.activeIndex };
  }
  componentWillReceiveProps(props) {
    this.setState({ activeIndex: this.getActiveIndex(props.activeIndex) });
  }
  selectTab = (index) => {
    const { onTabChange } = this.props;
    if (onTabChange) {
      onTabChange({ index });
    } else {
      this.setState({ activeIndex: this.getActiveIndex(index) });
    }
  }
  setIsOpen = (index, isOpen) => {
    const { onOpenTabsChanged } = this.props;
    if (onOpenTabsChanged) {
      onOpenTabsChanged(index, isOpen);
    }
  }
  getActiveIndex = (index) => {
    const { activeIndex } = this.state;
    return (this.props.children.length > 0 && index === activeIndex )? null : index;
  }
  onDragStart = () => {
    const holderId = `${this.props.id}Holder`;
    const holder = document.getElementById(holderId);
    if (holder) {
      holder.style.color = 'orange';
      holder.style.transition = 'background-color 0.2s ease';
    }
  }
  onDragUpdate = update => {
    // const { destination } = update;
    // const opacity = destination 
    //   ? destination.index /Object.keys(this.state.tasks).length
    //   : 0;
    // document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
  }
  onDragEnd = (result) => {
    const holderId = `${this.props.id}Holder`;
    const holder = document.getElementById(holderId);
    if (holder) {
      holder.style.color = 'inherit';
      holder.style.backgroundColor = 'inherit';
    }

    const { destination, source, draggableId } = result;
    if(!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (this.props.onItemDragged) {
      this.props.onItemDragged(source.index, destination.index);
    }
  }
  render() {
    // console.log('Accordion.render()', this.props);
    const { className, children, id, notDraggable, singleActiveIndex } = this.props;
    const { activeIndex } = this.state;
    const selectTab = (index) => this.selectTab(index);
    const setIsOpen = (...args) => this.setIsOpen(...args);
    if (notDraggable) {
      const content = React.Children.toArray(children).map((child, index) => {
        const onToggle = () => selectTab(index);
        const onSetIsOpen = (isOpen) => setIsOpen(index, isOpen);
        const isOpen = (index === activeIndex);
        const itemClassName = index == 0 ? '' : 'margin-sm-top';
        return React.cloneElement(child, { isOpen, singleActiveIndex, itemClassName, onToggle, onSetIsOpen })
      });
      return (<div className={className}>{content}</div>);
    }
    return (
      <div id={`${id}Holder`} className={className}>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId={id}>
            {
              (provided, snapshot) => (
                <DroppablePanel
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  <InnerList
                    selectTab={selectTab}
                    activeIndex={activeIndex}
                  >
                    {children}
                  </InnerList>
                  {provided.placeholder}
                </DroppablePanel>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}


const DroppablePanel = React.forwardRef((props, ref) => {
  const Component = ({ forwardedRef, isDraggingOver, children, ...props }) => {
    return (
      <div
        ref={forwardedRef}
        // className={`${isDraggingOver ? 'bg-secondary' : 'w3-white'}`}
        style={{ background: `${isDraggingOver ? '#f5f5f5' : '#fff'}`}}
        {...props}
      >
        {children}
      </div>
    );
  };
  return <Component {...props} forwardedRef={ref} />;
});


class InnerList extends React.Component {
  render() {
    const { selectTab, activeIndex, children } = this.props;
    return React.Children.toArray(children).map((child, index) => {
      const onToggle = () => selectTab(index);
      const isOpen = (index === activeIndex);
      const itemClassName = index == 0 ? '' : 'margin-sm-top';
      return (
        <Draggable
          key={index}
          draggableId={`dragItem${index}`}
          index={index}
        >
          {
            (provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className="pad-lr pad-top"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {React.cloneElement(child, { isOpen, singleActiveIndex: true, itemClassName, onToggle, isDragging: snapshot.isDragging })}
              </div>
            )
          }
        </Draggable>
      )
    });
  }
}


export class AccordionTab extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onToggle: PropTypes.func,
    header: PropTypes.string,
    menu: PropTypes.node,
    isOpen: PropTypes.bool,
    stateOpen: PropTypes.bool,
    isDragging: PropTypes.bool,
    singleActiveIndex: PropTypes.bool,
    onSetIsOpen: PropTypes.func,
    children: PropTypes.node
  }
  static defaultProps = {
    className: '',
    header: 'Titel'
  }
  constructor(props) {
    super();
    const { isOpen, stateOpen, singleActiveIndex } = props;
    this.state = {
      isOpen: singleActiveIndex ? isOpen : stateOpen
    };
  }
  onToggle = () => {
    const { singleActiveIndex, onToggle, onSetIsOpen } = this.props;
    // console.log('AccordionTab.onToggle() state:', this.state, 'singleActiveIndex:', singleActiveIndex);
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
    if (onSetIsOpen) {
      onSetIsOpen(!this.state.isOpen)
    }
    if (singleActiveIndex && onToggle) {
      // console.log('<< informing parent Accordion ...');
      onToggle();
    }
  }
  render() {
    const { className, header, menu, isDragging, children } = this.props;
    const { isOpen } = this.state;
    // console.log('AccordionTab.render() state:', this.state);
    const body = isDragging ? null : (
      <div className={`w3-${isOpen ? 'show' : 'hide'}`}>
        <div className="body w3-border-left w3-border-right w3-border-bottom">{children}</div>
      </div>
    );
    return (
      <div className={`${className} w3-white`}>
        <div className={`header ${isDragging ? 'bg-secondary' : 'w3-light-grey'} w3-border`}>
          <div className="w3-cell-row">
            <div className="w3-cell w3-cell-middle clickable" onClick={this.onToggle}>
              <h4 className="no-margin pad">{header}</h4>
            </div>
            <div className="w3-cell w3-cell-middle alg-right pad-rht" style={{width:'180px'}}>{menu}</div>
          </div>
        </div>
        {body}
      </div>
    );
  }
}