// import * as actionTypes from '../actions';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';

import {
  initialState as successState,
  reducer as successReducer }
from './success';

const initialState = {
  loaded: false,
  ...successState,
};

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      break;
  }

  // success
  newState = successReducer(state, action);
  if (newState) return newState;
  
  return { ...state };
}
