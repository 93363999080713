import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AppLogo from '../AppLogo';
import MainNav from '../MainNav';
import UserNav from '../UserNav';
import './styles.css';
import LocationChangeListener from '../../components/LocationChangeListener';
import { HamburgerMenu } from '../../components/VFIcon';

const mapStateToProps = (state) => {
  return {
    // tree: state.staticPage.tree,
    technikTree: state.staticPage.tree.technik,
    salesTree: state.staticPage.menuTree.sales,
    organisationTree: state.staticPage.menuTree.organisation,
    // user: state.auth.user,
    isAdmin: state.auth.isAdmin,
    isUserTaskManager: state.auth.isUserTaskManager,
    isCEO: state.auth.isCEO,
    isWorkerPlus: state.auth.isWorkerPlus,
    isWorker: state.auth.isWorker,
    isCompanyProfileViewer: state.auth.isCompanyProfileViewer,
    isWorkerLogisticsAssistant: state.auth.isWorkerLogisticsAssistant,
    isShopViewer: state.auth.isShopViewer,
    isLogisticsAssistant: state.auth.isLogisticsAssistant,
    isLogisticsCards: state.auth.isLogisticsCards,
    isLogisticsKeys: state.auth.isLogisticsKeys,
    isNewsAssistant: state.auth.isNewsAssistant,
    isDeviceManager: state.auth.isDeviceManager,
    // Sales - Reporting
    isSalesViewer: state.auth.isSalesViewer,
    isSalesImporter: state.auth.isSalesImporter,
    // Sales - Successes,  WorkerSales
    isSalesAchievementsViewer: state.auth.isSalesAchievementsViewer,
    isSalesSuccessViewer: state.auth.isSalesSuccessViewer,
    isSalesWorkerSalesViewer: state.auth.isSalesWorkerSalesViewer,
    isSalesConversionReportViewer: state.auth.isSalesConversionReportViewer,
    // Sales - Booking, MNV-Tip 
    isSalesBookingViewer: state.auth.isSalesBookingViewer,
    isSalesMNVTipViewer: state.auth.isSalesMNVTipViewer,
    //
    taskCounter: state.task.taskCounter
  };
};
const mapDispatchToProps = null;

class AppHeader extends React.Component {
  static propTypes = {
    // tree: PropTypes.shape({
    //   technik: PropTypes.shape({}),
    //   sales: PropTypes.shape({})
    // }).isRequired,
    technikTree: PropTypes.shape({}),
    salesTree: PropTypes.shape({}),
    organisationTree: PropTypes.shape({}),
    // user: PropTypes.object,
    isAdmin: PropTypes.bool,
    isUserTaskManager: PropTypes.bool,
    isCEO: PropTypes.bool,
    isWorkerPlus: PropTypes.bool,
    isWorker: PropTypes.bool,
    isCompanyProfileViewer: PropTypes.bool,
    isWorkerLogisticsAssistant: PropTypes.bool,
    isShopViewer: PropTypes.bool,
    isLogisticsAssistant: PropTypes.bool,
    isLogisticsCards: PropTypes.bool,
    isLogisticsKeys: PropTypes.bool,
    isNewsAssistant: PropTypes.bool,
    isDeviceManager: PropTypes.bool,
    isSalesViewer: PropTypes.bool,
    isSalesImporter: PropTypes.bool,
    isSalesBookingViewer: PropTypes.bool,
    isSalesMNVTipViewer: PropTypes.bool,
    isSalesAchievementsViewer: PropTypes.bool,
    isSalesSuccessViewer: PropTypes.bool,
    isSalesWorkerSalesViewer: PropTypes.bool,
    isSalesConversionReportViewer: PropTypes.bool,
    taskCounter: PropTypes.number
  }

  constructor () {
    super();
    // this mechanism only affects smaller screens where the menu is only
    // accessable via the `HamburgerMenu`

    this.hideMenu = this.hideMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      showMenu: false
    };
  }

  toggleMenu () {
    this.setState((state) => ({
      showMenu: !state.showMenu
    }));
  }

  hideMenu () {
    this.setState({ showMenu: false });
  }

  render() {
    const { technikTree, salesTree, organisationTree, taskCounter, ...userFlags } = this.props;
    const tree = {
      technik: technikTree,
      sales: salesTree,
      organisation: organisationTree,
    };
    // const title = user ? null : (<span className="app-title">AN-Portal</span>);
    const { showMenu } = this.state;
    const title = (<span className="app-title">AN-Portal</span>);
    return (
      <>
        <LocationChangeListener onChange={this.hideMenu} />
        <header className={classnames("app-header", {
          "nav-display": showMenu
        })}>
          <div className="menu-pane w3-border-bottom">
            <div className="container">
              <div className="w3-row">
                <div className="w3-col logo-holder">
                  <Link className="app-home-link" to="/">
                    <AppLogo />
                    {title}
                  </Link>
                </div>
                <div className="w3-col w3-right burger-holder">
                  <HamburgerMenu onClick={this.toggleMenu} />
                </div>
                <div className="w3-col w3-right user-nav-holder">
                  <UserNav
                    userFlags={userFlags}
                    taskCounter={taskCounter}
                  />
                </div>
                <div className="w3-rest">
                  <MainNav
                    userFlags={userFlags}
                    tree={tree}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
