import React from 'react';
import PropTypes from 'prop-types';
import KeyOrderTaskForm from '../../components/keys/KeyOrderTaskForm';
import { ProductFormMenu } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadKeyOrder, cancelKeyOrder } from '../../../Product/actions/keys';
import { closeKeyOrder } from '../../actions';
import { PRODUCT_ORDER_STATUS_IDS, PageTitle, PRODUCT_ORDER_STATUS_LABELS } from '../../../../components/Utils';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getOrder({ id });
};
const mapStateToProps = (state) => {
  return {
    keyOrder: state.key.keyOrder,
    keys: state.key.catalogKeys,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadKeyOrder(...args)),
  closeOrder: (...args) => dispatch(closeKeyOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelKeyOrder(...args)),
});

class KeyOrderTaskDetails extends React.Component {
  static propTypes = {
    keyOrder: PropTypes.shape({}),
    keys: PropTypes.array,
    getOrder: PropTypes.func.isRequired,
    closeOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  onSave = () => {
    const keyOrder = this.form.getModel(true);
    console.log('KeyOrderTaskDetails.onSave() ...', keyOrder);
    if (!keyOrder) return;
    if (keyOrder.address) {
      keyOrder.address.areaCode = keyOrder.address.areaCode || keyOrder.address.areacode;
      keyOrder.address.houseNumber = keyOrder.address.houseNumber || keyOrder.address.house;
    }
    const { closeOrder } = this.props;
    console.log(`>> saving key order...`, keyOrder);
    closeOrder({ keyOrder, saveOnly: true });
  }
  onSaveAndClose = () => {
    const keyOrder = this.form.getModel();
    console.log('KeyOrderTaskDetails.onSaveAndClose() ...', keyOrder);
    if (!keyOrder) return;
    keyOrder.status_id = PRODUCT_ORDER_STATUS_IDS.SENT;
    keyOrder.status = PRODUCT_ORDER_STATUS_LABELS[keyOrder.status_id];
    if (keyOrder.address) {
      keyOrder.address.areaCode = keyOrder.address.areaCode || keyOrder.address.areacode;
      keyOrder.address.houseNumber = keyOrder.address.houseNumber || keyOrder.address.house;
    }
    const { closeOrder } = this.props;
    console.log(`>> saving key order...`, keyOrder);
    closeOrder({ keyOrder }, this.onCancel);
  }
  onCancel = () => {
    const { history, cancelOrder } = this.props;
    cancelOrder();
    history.replace(`/aufgaben/schliessmittel/uebersicht`);
  }
  render() {
    const { className, keyOrder, keys } = this.props;
    console.log('KeyOrderTaskDetails.render()', this.props);
    if (!keyOrder) {
      return null;
    }
    const readOnly = (keyOrder.status_id !== PRODUCT_ORDER_STATUS_IDS.PROCESSING);
    const title = `Schließmittelbestellung ${readOnly ? 'ansehen' : 'bearbeiten'}`;
    return (
      <div className={`key-order-task-holder clearfix ${className}`}>
        <PageTitle>
          <div className="w3-right">
            <ProductFormMenu
              className=""
              onSave={this.onSave}
              onCancel={this.onCancel}
              onSaveAndClose={this.onSaveAndClose}
              saveHidden={readOnly}
              saveBtnName="saveKeyOrderBtn2"
              saveAndCloseHidden={readOnly}
              saveAndCloseBtnName="saveAndCloseCardOrderBtn2"
              saveAndCloseBtnLabel="Speichern und Freigeben"
              cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}
            />
          </div>
          <h3 className="no-margin pad-btm">{title}</h3>
        </PageTitle>
        <KeyOrderTaskForm
          ref={(ref) => this.form = ref}
          model={keyOrder}
          keys={keys}
          readOnly={readOnly}
        />
        <ProductFormMenu
          className="w3-border margin-big-top pad-big w3-light-grey"
          // style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          onSaveAndClose={this.onSaveAndClose}
          saveHidden={readOnly}
          saveBtnName="saveKeyOrderBtn"
          saveAndCloseHidden={readOnly}
          saveAndCloseBtnName="saveAndCloseKeyOrderBtn"
          saveAndCloseBtnLabel="Speichern und Freigeben"
          cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(KeyOrderTaskDetails)
);
