import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ViewIconNavButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadModems, loadWeeklyModemOrders, setDebitor, loadWeeklyRegionalModemOrders } from '../actions';
import { scrollToTop, TableEmptyMessage, toUIDate } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  const { week, year, fromMap, debitorKey } = props;
  await props.getModems();
  if (fromMap && debitorKey) {
    await props.getWeeklyRegionalModemOrders({ debitorKey });
  } else {
    await props.getWeeklyModemOrders(props.isTask ? {} : { week, year });
  }
  // if (props.isTask) {
  //   await props.getWeeklyModemOrders();
  //   return;
  // }
  // await props.getWeeklyModemOrders({ week, year });
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.modem.loading,
    orderWeeklyOverview: state.modem.modemOrderWeeklyOverview,
    orderWeeklyOverviewRegion: state.modem.modemOrderWeeklyOverviewRegion,
    modems: state.modem.catalogModems
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModems: (...args) => dispatch(loadModems(...args)),
  getWeeklyModemOrders: (...args) => dispatch(loadWeeklyModemOrders(...args)),
  getWeeklyRegionalModemOrders: (...args) => dispatch(loadWeeklyRegionalModemOrders(...args)),
  setDebitor: (...args) => dispatch(setDebitor(...args))
});

class ModemOrderWeeklyOverviewTable extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    orderWeeklyOverview: PropTypes.array,
    orderWeeklyOverviewRegion: PropTypes.array,
    modems: PropTypes.array,
    getModems: PropTypes.func.isRequired,
    getWeeklyModemOrders: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    isTask: PropTypes.bool,
    week: PropTypes.string,
    year: PropTypes.string,
    fromMap: PropTypes.bool,
    debitorKey: PropTypes.string,
  }
  static defaultProps = {
    rows: 12,
    className: ''
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowDetails = (modem, history) => {
    console.log('Editing', modem);
    const isViewingArchive = (this.props.week != null && this.props.year != null);
    if (!this.props.isTask && isViewingArchive) {
      console.log('>> Viewing archived order ...');
      this.props.setDebitor(modem, true);
      history.push(`/shop/modems/archiv/${modem.orderNumber}/debitor/${modem.debitor_id}`);
      return;
    }
    history.push(`/wizard/aufgabe/bestellung/modem/${modem.orderNumber}/debitor/${modem.debitor_id}`);
  }
  actionTemplate = (rowData/* , column */) => {
    return (
      <div>
        <ViewIconNavButton onClick={(history) => this.onShowDetails(rowData, history)}/>
      </div>
    );
  }
  render() {
    const { className, isAdmin, isLoading, orderWeeklyOverview, orderWeeklyOverviewRegion, modems, rows, isTask, fromMap, debitorKey } = this.props;
    const overviewData = (fromMap && debitorKey) ? orderWeeklyOverviewRegion : orderWeeklyOverview;
    if (!isAdmin || !overviewData || !modems) {
      return null;
    }
    const formatFieldName = (value) => value.split('.').join('').split(' ').join('-');
    const data = overviewData.map((item, index) => {
      const model = {
        index,
        debitorKey: item.debitor[0].debitorKey,
        orderDate: toUIDate(item.created_date)
      };
      for (const key in item) {
        model[formatFieldName(key)] = item[key];
      }
      return model;
    });
    const mini = true; //  isTask;
    const columns = mini ? modems.map((modem, index) => {
      return (
        <Column
          key={index}
          field={formatFieldName(modem.name)}
          header={modem.name}
          sortable={data.length > 1}
          filter={data.length > rows}
          filterMatchMode="contains"
        />
      );
    }) : [
      (
        <Column
          key="1"
          field="orderDate"
          header="Bestellungsdatum"
          sortable filter
          style={{textAlign:'center', width: '12em'}}
        />
      )
    ];
    return (
      <div className={`modem-order-weekly-overview-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Modembestellungen"
              itemName="Modembestellung"
              itemNamePlural="Modembestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Modembestellungen"/>)}
        >
          <Column
            field="debitorKey"
            header="Debitor"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {columns}
          <Column
            field="statusName"
            header="Status"
            className="alg-center"
            style={{width: '6em !important'}}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {!isTask && (
            <Column
              body={this.actionTemplate}
              className="alg-center"
              style={{width: '4em'}}
            />
          )}
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ModemOrderWeeklyOverviewTable)
);
