import DownloadList from './DownloadListForm';
import HtmlContent from './HtmlContentForm';
import ImageContent from './ImageUploadForm';
import PanelContent from './PanelForm';
import TextContent from './TextContentForm';
import HtmlImageContent from './HtmlImageContentForm';

export const DownloadListForm = DownloadList;
export const HtmlContentForm = HtmlContent;
export const ImageContentForm = ImageContent;
export const PanelContentForm = PanelContent;
export const TextContentForm = TextContent;
export const HtmlImageContentForm = HtmlImageContent;

