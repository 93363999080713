import React from "react";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
// import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from "primereact/inputtextarea";
import { InputText, Label, Dropdown } from "../../../components/Inputs";
import {
	validateAreaCode,
	createSortByLabel,
	addressToString,
} from "../../../components/Utils";

export default class OrderAddressForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		addresses: PropTypes.any,
		companies: PropTypes.array,
		company: PropTypes.shape({}),
		model: PropTypes.shape({}),
		isAdmin: PropTypes.bool,
		getAddresses: PropTypes.func,
		isVertical: PropTypes.bool,
		namePrefix: PropTypes.string,
	};
	static defaultProps = {
		className: "",
		model: {
			company_id: "",
			person: "",
			address: {
				street: "",
				houseNumber: "",
				areaCode: "",
				city: "",
			},
			description: "",
		},
		company: null,
		companies: [],
		addresses: [],
		namePrefix: "",
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	getState = (props) => {
		const { model } = props;
		const address = model.address || {};
		return {
			company_id: model.company_id || "",
			address_id: address.id || "",
			person: model.person || "",
			street: address.street || "",
			houseNumber: address.houseNumber || address.house || "",
			areaCode: address.areaCode || address.areacode || "",
			city: address.city || "",
			description: model.description || "",
			error: {
				company_id: null,
				person: null,
				street: null,
				houseNumber: null,
				areaCode: null,
				city: null,
			},
		};
	};
	componentDidMount() {
		const { company, isAdmin } = this.props;
		if (!isAdmin && company && this.state.company_id === "") {
			this.setCompany(company.id);
			// const company_id = company.id;
			// const { street, houseNumber, areaCode, city } = company.address;
			// this.setState({ company_id, street, houseNumber, areaCode, city});
		}
	}
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!validateAreaCode(model.areaCode)) {
			isValid = false;
			error.areaCode = true;
		}
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		const { company_id, person, address_id, description, ...address } = model;
		const newModel = { person, address, description };
		// set address id
		if (address_id && address_id !== "") {
			newModel.address.id = parseInt(address_id);
		}
		// get company id and name
		const companyId = parseInt(company_id);
		newModel.company_id = !isNaN(companyId) ? companyId : 0;
		const company = (this.props.companies || []).find(
			(item) => item.id === company_id
		);
		if (company) {
			newModel.companyName = company.name;
		}
		return newModel;
	};
	getModel = () => {
		const model = this.validate();
		// console.log('CompanyAddressForm.getModel()', model);
		return model;
	};
	setCompany = (company_id) => {
		const { companies, getAddresses } = this.props;
		let state = { company_id };
		console.log(">> find company by id", company_id);
		const found = (companies || []).find((item) => item.id === company_id);
		let companyName = null;
		if (found) {
			console.log("Company", found);
			companyName = found.name;
			const { street, houseNumber, house, areaCode, areacode, city } = found;
			state = {
				...state,
				street,
				houseNumber: houseNumber || house,
				areaCode: areaCode || areacode,
				city,
			};
		}
		this.setState(state);
		getAddresses && getAddresses({ companyName });
	};
	setAddress = (address_id) => {
		const { addresses } = this.props;
		let state = { address_id };
		console.log(">> find address by id", address_id);
		const found = (addresses || []).find((item) => item.id === address_id);
		if (found) {
			const { street, houseNumber, areaCode, city } = found;
			state = { ...state, street, houseNumber, areaCode, city };
		}
		this.setState(state);
	};
	renderStreetHouse = (model, className) => {
		const { namePrefix } = this.props;
		const { street, houseNumber, error } = model || {};
		return (
			<div className={`${className}`}>
				<Label
					className="display-inline-block"
					htmlFor={`${namePrefix}address_form_street`}
				>{`Straße`}</Label>
				<Label className="display-inline-block">{`/`}</Label>
				<Label
					className="display-inline-block"
					htmlFor={`${namePrefix}address_form_housenumber`}
				>{`Hausnummer *`}</Label>
				<div className="w3-row">
					<div
						className={`w3-col s8 pad-sm-rht ${error.street && "error-group"}`}
					>
						<InputText
							id={`${namePrefix}address_form_street`}
							className={`w3-block w3-border`}
							value={street}
							onChange={(event) =>
								this.setState({ street: event.target.value, address_id: "" })
							}
						/>
					</div>
					<div
						className={`w3-col s4 pad-sm-lft ${
							error.houseNumber && "error-group"
						}`}
					>
						<InputText
							id={`${namePrefix}address_form_housenumber`}
							className={`w3-block w3-border`}
							value={houseNumber}
							onChange={(event) =>
								this.setState({
									houseNumber: event.target.value,
									address_id: "",
								})
							}
						/>
					</div>
				</div>
			</div>
		);
	};
	renderAreaCity = (model, className) => {
		const { namePrefix } = this.props;
		const { areaCode, city, error } = model || {};
		return (
			<div className={`${className}`}>
				<Label
					className="display-inline-block"
					htmlFor={`${namePrefix}area_code`}
				>{`PLZ`}</Label>
				<Label className="display-inline-block">{`/`}</Label>
				<Label
					className="display-inline-block"
					htmlFor={`${namePrefix}city`}
				>{`Ort *`}</Label>
				<div className="w3-row">
					<div
						className={`w3-col s4 pad-sm-rht ${
							error.areaCode && "error-group"
						}`}
					>
						<InputText
							id={`${namePrefix}area_code`}
							className={`w3-block w3-border`}
							value={areaCode}
							onChange={(event) =>
								this.setState({ areaCode: event.target.value, address_id: "" })
							}
						/>
					</div>
					<div
						className={`w3-col s8 pad-sm-lft ${error.city && "error-group"}`}
					>
						<InputText
							id={`${namePrefix}city`}
							className={`w3-block w3-border`}
							value={city}
							onChange={(event) =>
								this.setState({ city: event.target.value, address_id: "" })
							}
						/>
					</div>
				</div>
			</div>
		);
	};
	render() {
		const {
			className,
			companies,
			addresses,
			isVertical,
			namePrefix,
		} = this.props;
		const { company_id, address_id, person, description, error } = this.state;
		// console.log('CompanyAddress.render() state', this.state);
		const CompanyOptions = (companies || [])
			.map((item) => ({ label: item.name, value: item.id }))
			.sort(createSortByLabel());
		const AddressOptions = (addresses || [])
			.map((item) => ({ label: addressToString(item), value: item.id }))
			.sort(createSortByLabel());
		//   {
		//   const label = addressToString(item);
		//   return { label, value: item };
		// }).sort(createSortByLabel());

		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div
						className={`w3-col s6 pad-lr pad-top form-group-item ${
							error.company_id && "error-group"
						}`}
					>
						<Label
							htmlFor={`${namePrefix}company_id`}
						>{`Beschäftigungsfirma*`}</Label>
						<Dropdown
							name="companyId"
							id="company_id"
							className={`w3-block w3-border`}
							value={company_id}
							placeholder="Bitte auswählen"
							options={CompanyOptions}
							onChange={(event) => this.setCompany(event.value)}
							showClear
						/>
					</div>
					<div
						className={`w3-col s6 pad-lr pad-top form-group-item ${
							error.person && "error-group"
						}`}
					>
						<Label htmlFor={`${namePrefix}person`}>{`Ansprechpartner*`}</Label>
						<InputText
							id={`${namePrefix}person`}
							className={`w3-block w3-border`}
							value={person}
							onChange={(event) =>
								this.setState({ person: event.target.value })
							}
						/>
					</div>
				</div>
				<div className="w3-row neg-margin-lr">
					<div className={`w3-col s6 pad-lr`}>
						<div className="w3-row neg-margin-lr">
							{this.renderStreetHouse(
								this.state,
								`w3-col ${
									isVertical ? "m12" : "m6"
								} pad-lr pad-top form-group-item`
							)}
							{this.renderAreaCity(
								this.state,
								`w3-col ${
									isVertical ? "m12" : "m6"
								} pad-lr pad-top form-group-item`
							)}
						</div>
					</div>
					<div className={`w3-col s6 pad-lr pad-top form-group-item`}>
						<Label
							htmlFor={`${namePrefix}address`}
						>{`Zuletzt verwendete Lieferadressen`}</Label>
						<Dropdown
							id={`${namePrefix}address`}
							className={`w3-block w3-border`}
							value={address_id}
							placeholder="Bitte auswählen"
							options={AddressOptions}
							onChange={(event) => this.setAddress(event.value)}
							showClear
						/>
					</div>
				</div>
				<div className={`pad-top form-group-item`}>
					<Label htmlFor="description">{`Bestellvermerk`}</Label>
					<InputTextarea
						id="description"
						className="w3-block w3-border"
						value={description}
						rows={5}
						autoResize
						onChange={(event) =>
							this.setState({ description: event.target.value })
						}
					/>
				</div>
				{/* <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.company_id && 'error-group'}`}>
              <Label htmlFor={`${namePrefix}company_id`}>{`Beschäftigungsfirma*`}</Label>
              <Dropdown
                id={`${namePrefix}company_id`}
                className={`w3-block w3-border`}
                value={company_id}
                placeholder="Bitte auswählen"
                options={CompanyOptions}
                onChange={(event) => this.setCompany(event.value)}
              />
            </div>
            <div className={`pad-top form-group-item ${error.person && 'error-group'}`}>
              <Label htmlFor={`${namePrefix}person`}>{`Ansprechpartner*`}</Label>
              <InputText
                id={`${namePrefix}person`}
                className={`w3-block w3-border`}
                value={person}
                onChange={(event) => this.setState({person: event.target.value})}
              />
            </div>
            <div className="w3-row neg-margin-lr">
              {this.renderStreetHouse(this.state, `w3-col ${isVertical ? 'm12' : 'm6'} pad-lr pad-top form-group-item`)}
              {this.renderAreaCity(this.state, `w3-col ${isVertical ? 'm12' : 'm6'} pad-lr pad-top form-group-item`)}
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item`}>
              <Label htmlFor={`${namePrefix}address`}>{`Zum Laden einer Adresse hier auswählen`}</Label>
              <Dropdown
                id={`${namePrefix}address`}
                className={`w3-block w3-border`}
                value={address_id}
                placeholder="Bitte auswählen"
                options={AddressOptions}
                onChange={(event) => this.setAddress(event.value)}
              />
            </div>
            <div className={`pad-top form-group-item`}>
              <Label htmlFor="description">{`Bestellvermerk`}</Label>
              <InputTextarea
                id="description"
                className="w3-block w3-border"
                value={description}
                rows={5}
                autoResize
                onChange={(event) => this.setState({description: event.target.value})}
              />
            </div>
          </div>
        </div> */}
			</div>
		);
	}
}
