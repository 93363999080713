import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsPriv = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export const PrivateRoute = connect(mapStateToPropsPriv, null)(PrivateRouteModule);


const PublicRouteModule = ({
  component: Component,
  ...rest
}) => {
  let query = queryString.parse(rest.location.search);
  return (
    <Route
      {...rest}
      render={props =>
        !rest.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={query.redirect || '/'} />
        )
      }
    />
  );
};
const mapStateToPropsPub = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export const PublicRoute = connect(mapStateToPropsPub, null)(PublicRouteModule);

// Admin
const AdminRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAdmin ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/start' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsAR = state => ({
  isAdmin: state.auth.isAdmin,
  isAuthenticated: state.auth.isAuthenticated
});
export const AdminRoute = connect(mapStateToPropsAR, null)(AdminRouteModule);

// Worker Plus
const WorkerPlusRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isWorkerPlus ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/start' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsWPR = state => ({
  isWorkerPlus: state.auth.isWorkerPlus,
  isAuthenticated: state.auth.isAuthenticated
});
export const WorkerPlusRoute = connect(mapStateToPropsWPR, null)(WorkerPlusRouteModule);

// Company Profile Viewer
const CompanyProfileViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      (rest.isCompanyProfileViewer) ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/start' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsCPV = state => ({
  isCompanyProfileViewer: state.auth.isCompanyProfileViewer,
  isAuthenticated: state.auth.isAuthenticated
});
export const CompanyProfileViewerRoute = connect(mapStateToPropsCPV, null)(CompanyProfileViewerRouteModule);

// Company Profile Editor
const CompanyProfileEditorRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      (rest.isCompanyProfileEditor) ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/start' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsCPE = state => ({
  isCompanyProfileEditor: state.auth.isCompanyProfileEditor,
  isAuthenticated: state.auth.isAuthenticated
});
export const CompanyProfileEditorRoute = connect(mapStateToPropsCPE, null)(CompanyProfileEditorRouteModule);

// Device Manager
const DeviceManagerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isDeviceManager ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? `/organisation` : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsDMR = state => ({ isDeviceManager: state.auth.isDeviceManager, isAuthenticated: state.auth.isAuthenticated });
export const DeviceManagerRoute = connect(mapStateToPropsDMR, null)(DeviceManagerRouteModule);


// Logistics Assistant
const LogisticsAssistantRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isLogisticsAssistant ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/profil' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsLAR = state => ({
  isLogisticsAssistant: state.auth.isLogisticsAssistant,
  isAuthenticated: state.auth.isAuthenticated
});
export const LogisticsAssistantRoute = connect(mapStateToPropsLAR, null)(LogisticsAssistantRouteModule);


// Worker Logistics Assistant
const WorkerLogisticsAssistantRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isWorkerLogisticsAssistant ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/profil' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsWLAR = state => ({
  isWorkerLogisticsAssistant: state.auth.isWorkerLogisticsAssistant,
  isAuthenticated: state.auth.isAuthenticated
});
export const WorkerLogisticsAssistantRoute = connect(mapStateToPropsWLAR, null)(WorkerLogisticsAssistantRouteModule);


// Sales Reporter
const SalesReporterRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesReporter ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSR = state => ({ isSalesReporter: state.auth.isSalesReporter, isAuthenticated: state.auth.isAuthenticated });
export const SalesReporterRoute = connect(mapStateToPropsSR, null)(SalesReporterRouteModule);


// Sales Importer
const SalesImporterRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesImporter ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSI = state => ({
  isSalesImporter: state.auth.isSalesImporter,
  isAuthenticated: state.auth.isAuthenticated
});
export const SalesImporterRoute = connect(mapStateToPropsSI, null)(SalesImporterRouteModule);


// Sales Viewr
const SalesViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesViewer ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSV = state => ({ isSalesViewer: state.auth.isSalesViewer, isAuthenticated: state.auth.isAuthenticated });
export const SalesViewerRoute = connect(mapStateToPropsSV, null)(SalesViewerRouteModule);

// Sales BookingViewer
const SalesBookingViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesBookingViewer ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSBV = state => ({ isSalesBookingViewer: state.auth.isSalesBookingViewer, isAuthenticated: state.auth.isAuthenticated });
export const SalesBookingViewerRoute = connect(mapStateToPropsSBV, null)(SalesBookingViewerRouteModule);

// Sales AchievementsViewer
const SalesAchievementsViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesAchievementsViewer ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSAV = state => ({
  isSalesAchievementsViewer: state.auth.isSalesAchievementsViewer,
  isAuthenticated: state.auth.isAuthenticated
});
export const SalesAchievementsViewerRoute = connect(mapStateToPropsSAV, null)(SalesAchievementsViewerRouteModule);


// Sales SuccessViewer
const SalesSuccessViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesSuccessViewer ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSSV = state => ({ isSalesSuccessViewer: state.auth.isSalesSuccessViewer, isAuthenticated: state.auth.isAuthenticated });
export const SalesSuccessViewerRoute = connect(mapStateToPropsSSV, null)(SalesSuccessViewerRouteModule);


// Sales WorkerSalesViewer
const SalesWorkerSalesViewerRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isSalesWorkerSalesViewer ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/sales' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsSWSV = state => ({ isSalesWorkerSalesViewer: state.auth.isSalesWorkerSalesViewer, isAuthenticated: state.auth.isAuthenticated });
export const SalesWorkerSalesViewerRoute = connect(mapStateToPropsSWSV, null)(SalesWorkerSalesViewerRouteModule);

// Logistics Cards
const LogisticsCardsRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isLogisticsCards ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/profil' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsLCR = state => ({
  isLogisticsCards: state.auth.isLogisticsCards,
  isAuthenticated: state.auth.isAuthenticated
});
export const LogisticsCardsRoute = connect(mapStateToPropsLCR, null)(LogisticsCardsRouteModule);

// Logistics Keys
const LogisticsKeysRouteModule = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      rest.isLogisticsKeys ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/profil' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsLKR = state => ({
  isLogisticsKeys: state.auth.isLogisticsKeys,
  isAuthenticated: state.auth.isAuthenticated
});
export const LogisticsKeysRoute = connect(mapStateToPropsLKR, null)(LogisticsKeysRouteModule);

// User Task Manager
const UserTaskManagerRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isUserTaskManager ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/aufgaben' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsUTMR = state => ({
  isUserTaskManager: state.auth.isUserTaskManager,
  isAuthenticated: state.auth.isAuthenticated
});
export const UserTaskManagerRoute = connect(mapStateToPropsUTMR, null)(UserTaskManagerRouteModule);

// isProfileChangeManager
const ProfileChangeManagerRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isProfileChangeManager ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/aufgaben/benutzer' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsPCMR = state => ({
  isProfileChangeManager: state.auth.isProfileChangeManager,
  isAuthenticated: state.auth.isAuthenticated
});
export const ProfileChangeManagerRoute = connect(mapStateToPropsPCMR, null)(ProfileChangeManagerRouteModule);

// isFailedCompanyEmailManager
const FailedCompanyEmailManagerRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isFailedCompanyEmailManager ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/aufgaben/benutzer' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsFCEMR = state => ({
  isFailedCompanyEmailManager: state.auth.isFailedCompanyEmailManager,
  isAuthenticated: state.auth.isAuthenticated
});
export const FailedCompanyEmailManagerRoute = connect(mapStateToPropsFCEMR, null)(FailedCompanyEmailManagerRouteModule);

// isFailedUserEmailManager
const FailedUserEmailManagerRouteModule = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isFailedUserEmailManager ? (
        <Component {...props} />
      ) : (
        <Redirect to={rest.isAuthenticated ? '/aufgaben/benutzer' : `/login?redirect=${props.location.pathname}`} />
      )
    }
  />
);
const mapStateToPropsFUEMR = state => ({
  isFailedUserEmailManager: state.auth.isFailedUserEmailManager,
  isAuthenticated: state.auth.isAuthenticated
});
export const FailedUserEmailManagerRoute = connect(mapStateToPropsFUEMR, null)(FailedUserEmailManagerRouteModule);

// User Task Manager Redirect
const UserTaskManagerRedirectModule = ({
  isProfileChangeManager,
  isFailedCompanyEmailManager,
  isFailedUserEmailManager,
}) => {
  let targetUrl = "/aufgaben";
  if (isProfileChangeManager) {
    targetUrl += "/benutzer/aenderung-kontaktdaten";
  } else if (isFailedCompanyEmailManager) {
    targetUrl += "/benutzer/unversandte-emails-firmen";
  } else if (isFailedUserEmailManager) {
    targetUrl += "/benutzer/unversandte-emails-benutzer";
  }
  return (<Redirect to={targetUrl}/>);
};
const mapStateToPropsUTMDR = state => ({
  isUserTaskManager: state.auth.isUserTaskManager,
  isProfileChangeManager: state.auth.isProfileChangeManager,
  isFailedCompanyEmailManager: state.auth.isFailedCompanyEmailManager,
  isFailedUserEmailManager: state.auth.isFailedUserEmailManager,
  isAuthenticated: state.auth.isAuthenticated
});
export const UserTaskManagerRedirect = connect(mapStateToPropsUTMDR, null)(UserTaskManagerRedirectModule);

