import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, ButtonLink } from '../../../../components/Buttons';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../../components/W3Dropdown';
import ActionFilter from '../ActionFilter';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

export default class DeviceTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedDevices: PropTypes.array,
    deviceTaskType: PropTypes.string,
    exportDevices: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    actions: {},
    items: [],
  }
  onExport = (event) => {
    if (event) event.preventDefault();
    const { selectedDevices } = this.props;
    let counter = 0;
    if (selectedDevices && selectedDevices.length > 0) {
      counter += selectedDevices.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `Messgeräte exportieren`,
        text: `
          Alle ${counter} ausgewählte Messgeräte werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `,
      },
      () => this.props.exportDevices({ deviceTaskType: this.props.deviceTaskType })
    );
  }
  render() {
    const { className, selectedDevices, filterDevices, deviceTaskType, items } = this.props;
    const isExportable = (selectedDevices && selectedDevices.length > 0);
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-rht">
            <ActionFilter
              type="Device"
              taskType={deviceTaskType}
              setTaskType={filterDevices}
              itemsToCount={items}
              className="display-inline-block"
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler as={PrimaryButton} size="small-">{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '280px'}}>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onExport}
                  disabled={!isExportable}>
                    {`Messgeräte exportieren (CSV)`}
                </ButtonLink>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  to={`/wizard/aufgabe/import/device`}
                  disabled={false}>
                    {`Messgeräte importieren (CSV)`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ConfirmationDialog ref={ref => this.taskDialog = ref}/>
      </div>
    );
  }
}
