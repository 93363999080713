import React from 'react';
import Slider from '../../../components/Slider';

const ImageSlider = ({ className = '', sliderConfig = {}, showTitle, showSubtitle, images }) => {
  const config = {
    autoplay: true,
    ...sliderConfig
  };
  const items = images.map((image, index) => (
    <div key={index} className="image-item">
      <img src={image.file} alt={image.alt || 'slider image'}/>
      <div className="text">
        {showTitle && (<div className="title">{image.title}</div>)}
        {showSubtitle && (<div className="subtitle">{image.subtitle}</div>)}
      </div>
    </div>
  ));
  return (
    <Slider className={`image-slider ${className}`} config={config}>
      {items}
    </Slider>
  );
};

export default ImageSlider;
