import * as actionTypes from './actions';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';
import { convertListToTree } from '../MainNav/components';
import { notifyError, notifySuccess } from '../../components/Utils';
import { buildLabelMap, buildOrganisationTree, buildSalesTree } from '../MainNav';
import { emptyClipboard } from './ClipBoard';

const initialState = {
  isEditingPage: false,
  productPagePathSaved: false,
  // currentPageUrl: null,
  html: {},
  menuTree: {
    // technik: null,
    sales: null,
    organisation: null,
  },
  routes: {
    technik: [],
    sales: [],
    organisation: [],
  },
  tree: {
    technik: null,
    sales: null,
    organisation: null,
  },
  archives: {
    technik: [],
    sales: [],
    organisation: []
  },
  labelMap: {
    technik: {},
    sales: {},
    organisation: {},
    search: {}
  },
  showPathDialog: false,
  parentUrl: '',
  currentLinkData: null,
  currentLinkDialogConfig: {},
  showPathMovementDialog: false,
  currentTreeLinks: null,
  loaded: false,

  TechnikLabelMap: {},
  SalesLabelMap: {},
  OrganisationLabelMap: {},
  SearchLabelMap: {}
};


export default (state = initialState, action) => {
  switch (action.type) {
    // load tree
    case actionTypes.LOAD_TREE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_TREE_SUCCESS: {
      const routes = { ...state.routes };
      const tree = { ...state.tree };
      const archives = { ...state.archives };
      const labelMap = { ...state.labelMap };

      const keys = Object.keys(action.result);
      keys.forEach(key => {
        routes[key] = action.result[key].filter(item => item.is_archived === 0);
        tree[key] = convertListToTree(routes[key]);
        archives[key] = action.result[key].filter(item => item.is_archived !== 0);
        labelMap[key] = buildLabelMap(convertListToTree(action.result[key]));
      });
      labelMap.search = {
        ...labelMap.technik,
        ...labelMap.sales,
        ...labelMap.organisation,
      };
      const menuTree = { ...state.menuTree };
      menuTree.sales = buildSalesTree(tree.sales);
      menuTree.organisation = buildOrganisationTree(tree.organisation);

      return {
        ...state,
        loading: false,
        routes,
        tree,
        archives,
        labelMap,
        menuTree,
      };
    }
    case actionTypes.LOAD_TREE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    // create path
    case actionTypes.CREATE_PATH:
      return {
        ...state,
        showPathDialog: true,
        parentUrl: action.parentUrl,
        currentLinkData: action.linkData,
        currentLinkDialogConfig: action.config,
      };
    // cancel path
    case actionTypes.CANCEL_PATH:
      return {
        ...state,
        showPathDialog: false,
        parentUrl: '',
        currentLinkData: null,
        currentLinkDialogConfig: {},
      };
    // save path
    case actionTypes.SAVE_PATH:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_PATH_SUCCESS: {
      if (action.savingToArchive) {
        const message = 'archiviert';
        notifySuccess({
          summary: `Content-Bereich: Archivierung`,
          detail: `Die Seite "${action.data.label}" wurde erfolgreich ${message}!`
        });
      } else {
        const message = action.data.id ? 'aktualisiert' : 'angelegt';
        notifySuccess({
          summary: `Content-Bereich`,
          detail: `Die Seite "${action.data.label}" wurde erfolgreich ${message}!`
        });
      }
      return {
        ...state,
        loading: false,
        showPathDialog: false,
        parentUrl: '',
        currentLinkData: null,
        currentLinkDialogConfig: {},
        productPagePathSaved: action.isProductPage
      };
    }
    case actionTypes.SAVE_PATH_FAILURE: {
      const message = action.data.id ? 'aktualisiert' : 'angelegt';
      notifyError({
        summary: `Content-Bereich`,
        detail: `Die Unterseite "${action.data.label}" konnte nicht ${message} werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    }

    // show tree movement dialog
    case actionTypes.SHOW_PATHMOVEMENT_DIALOG:
      return {
        ...state,
        showPathMovementDialog: true,
        currentLinkData: action.linkData,
        currentTreeLinks: action.treeLinks,
      };
    // hide tree movement dialog
    case actionTypes.HIDE_PATHMOVEMENT_DIALOG:
      return {
        ...state,
        showPathMovementDialog: false,
        currentLinkData: null,
        currentTreeLinks: null
      };
    // update subtree (path)
    case actionTypes.UPDATE_PATH:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_PATH_SUCCESS: {
      notifySuccess({
        summary: `Content-Bereich: Seite verschieben`,
        detail: `Die Seite "${action.data.label}" wurde erfolgreich verschoben!`
      });
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.UPDATE_PATH_FAILURE: {
      notifyError({
        summary: `Content-Bereich: Seite verschiebe`,
        detail: `Die Seite "${action.data.label}" konnte nicht verschoben werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      }
    }
    
    // load html
    case actionTypes.LOAD_HTML: {
      let isEditingPage = state.isEditingPage;
      let productPagePathSaved = state.productPagePathSaved;
      if (productPagePathSaved) {
        productPagePathSaved = false;
        isEditingPage = true;
      }
      return {
        ...state,
        loading: true,
        isEditingPage,
        productPagePathSaved
      };
    }
    case actionTypes.LOAD_HTML_SUCCESS:{
      console.log('HTML loaded:', action);
      const staticData = action.result[0] || {
        title: '', subtitle: '', contents: []
      };
      return {
        ...state,
        loading: false,
        html: {
          ...state.html,
          [action.key]: staticData
        }
      };
    }
    case actionTypes.LOAD_HTML_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // edit and cancel page
    case actionTypes.EDIT_PAGE:
      return {
        ...state,
        isEditingPage: true
      };
    case actionTypes.CANCEL_PAGE:
      return {
        ...state,
        isEditingPage: false
      };
    
    // save html
    case actionTypes.SAVE_HTML:
      return {
        ...state,
        saving: true
      };
    case actionTypes.SAVE_HTML_SUCCESS: {
      notifySuccess({
        summary: `Content-Bereich`,
        detail: `Der Seiteninhalt wurde erfolgreich gespeichert!`
      });
      return {
        ...state,
        saving: false,
        isEditingPage: false
      };
    }
    case actionTypes.SAVE_HTML_FAILURE: {
      notifyError({
        summary: `Content-Bereich`,
        detail: `Der Seiteninhalt konnte nicht gespeichert werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
    }

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      emptyClipboard();
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
