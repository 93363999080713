import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useRef, useState } from "react";
import { HashRouter, NavLink } from "react-router-dom";
import { CancelButtonLink, PrimaryButton } from "../../../components/Buttons";
import Dashboard from "../../../components/Dashboard";
import {
  notifyError,
  PageHeightAuto,
  TableEmptyMessage,
  WizardTitle
} from "../../../components/Utils";
import SalesTablePageReport from "../../Reporting/components/SalesTablePageReport";
import DebitorMappingImportForm from "../components/DebitorMappingImportForm";


const Menu = ({
  created,
  imported,
  rejected,
  isUploaded
}) => {
  let rejectedLink;
  if (isUploaded) {
  // if (rejected.length !== 0) {
    rejectedLink = (
      <li>
        <NavLink
          to={`/abgelehnt`}
          activeClassName="active">
            {'Abgelehnte Einträge'}
        </NavLink>
      </li>
    );
  }

  let importedLink;
  if (isUploaded) {
  // if (imported.length !== 0) {
    importedLink = (
      <li>
        <NavLink
          to={`/importiert`}
          activeClassName="active">
            {'Importierte Einträge'}
        </NavLink>
      </li>
    );
  }

  let createdLink;
  if (isUploaded) {
  // if (created.length !== 0) {
    createdLink = (
      <li>
        <NavLink
          to={`/neu`}
          activeClassName="active">
            {'Neue Einträge'}
        </NavLink>
      </li>
    );
  }
  return (
    <HashRouter>
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li>
              <NavLink
                to={`/upload`}
                activeClassName="active">
                  {'CSV hochladen'}
              </NavLink>
            </li>
            {createdLink}
            {importedLink}
            {rejectedLink}
          </ul>
        </div>
      </div>
    </HashRouter>
  );
};


const RecordsTable = ({
  data,
  rows = 10,
  showMessage = false,
}) => {
  const dt = useRef(null);
  const [firstRow, setFirstRow] = useState(0);
  const onPage = (event) => {
    setFirstRow(event.first);
  };
  return (
    <DataTable
      ref={dt}
      value={data}
      paginator={data.length > rows}
      first={firstRow}
      onPage={onPage}
      paginatorLeft={
        <SalesTablePageReport
          className="pad-lft"
          totalRecords={data.length}
          filteredRecords={data.length}
          first={firstRow}
          rows={rows}
          emptyText="Keine Einträge"
          itemName="Eintrag"
          itemNamePlural="Einträge"
        />
      }
      rows={rows}
      emptyMessage={(<TableEmptyMessage itemNamePlural="Einträge" min/>)}
      removableSort
    >
      <Column
        field="region"
        header="Region"
        sortable={data.length > 1}
        filter={data.length > rows}
        filterMatchMode="contains"
        style={{textAlign: 'center', width: '12rem'}}
      />
      <Column
        field="storageLocation"
        header="Storage Location"
        sortable={data.length > 1}
        filter={data.length > rows}
        filterMatchMode="contains"
        style={{textAlign: 'center', width: '12rem'}}
        template={(row) => (
          <span
            className="display-inline-block"
            style={{textAlign:"left",width:"4rem"}}
          >
            {row.storageLocation}
          </span>
        )}
      /> 
      <Column
        field="companyName"
        header="Firma"
        sortable={data.length > 1}
        filter={data.length > rows}
        filterMatchMode="contains"
        // style={{width: '25%'}}
      />
      {showMessage && (
      <Column
        field="message"
        header="Fehler"
        sortable={data.length > 1}
        filter={data.length > rows}
        filterMatchMode="contains"
        // style={{width: '25%'}}
        body={(row) => {
          switch (row.message) {
            case "labels.empty.storage.location":
              return "Storage Location fehlt";
            case "labels.company.not.exists":
              return "Ungültige Firma";
            case "labels.region.not.exists":
              return "Ungültige Region";
          }
          return row.message;
        }}
      />
      )}
    </DataTable>
  );
};


const DebitorMappingImportWizard = ({
  className,
  // history,
}) => {
  const [state, setState] = useState({
    created: [],
    imported: [],
    rejected: [],
    isUploaded: false
  });
  const { created, imported, rejected, isUploaded } = state;

  const onError = (event) => {
    const error = event.xhr.response;
    const errorMessage = error && error.message;
    notifyError({
      summary: "Debitor-Mapping: Importfehler",
      detail: errorMessage
    });
    setState({
      created: [],
      imported: [],
      rejected: [],
      isUploaded: false
    });
  };
  const onSuccess = (event) => {
    const {
      new : created = [],
      imported = [],
      rejected = []
    } = event.xhr.response;
    setState({
      created,
      imported,
      rejected,
      isUploaded: true
    });
  };
  const onReset = () => {
    setState({
      created: [],
      imported: [],
      rejected: [],
      isUploaded: false
    });
  };
  const onCancel = (history) => {
    history.replace("/shop/modems/debitor-mapping");
  };

  let rejectedSection = null;
  if (isUploaded /* rejected.length !== 0 */) {
    rejectedSection = (
      <PageHeightAuto
        id="/abgelehnt"
        className="w3-border margin-big-top pad-big"
        isUnterWizardTitle
      >
        <h3 className="no-margin-top">Abgelehnte Einträge</h3>
        <RecordsTable data={rejected} showMessage/>
      </PageHeightAuto>
    );
  }

  let importedSection = null;
  if (isUploaded /* imported.length !== 0 */) {
    importedSection = (
      <PageHeightAuto
        id="/importiert"
        className="w3-border margin-big-top pad-big"
        isUnterWizardTitle
      >
        <h3 className="no-margin-top">Importierte Einträge</h3>
        <RecordsTable data={imported}/>
      </PageHeightAuto>
    );
  }

  let createdSection = null;
  if (isUploaded /* created.length !== 0 */) {
    createdSection = (
      <PageHeightAuto
        id="/neu"
        className="w3-border margin-big-top pad-big"
        isUnterWizardTitle
      >
        <h3 className="no-margin-top">Neue Einträge</h3>
        <RecordsTable data={created}/>
      </PageHeightAuto>
    );
  }

  return (
    <div className={`${className} clearfix`}>
      <WizardTitle
        title={"Debitor-Mapping importieren"}
        menu={
          <div className="pad-big-top">
            {isUploaded && (
              <PrimaryButton
                className={"margin-rht"}
                onClick={onReset}
              >
                {"Nochmal hochladen"}
              </PrimaryButton>
            )}
            <CancelButtonLink
              label="Schließen"
              onCancel={onCancel}
            />
          </div>
        }
      />
      <div className="container">
        <Dashboard
          menu={<Menu {...state} />}
          menuSize={3}
        >
          <PageHeightAuto
            id="/upload"
            className="w3-border margin-big-top pad-big"
            isUnterWizardTitle
          >
            <h3 className="no-margin-top">CSV hochladen</h3>
            <DebitorMappingImportForm
              url={`/data-api/companies/debitors/mappings/import/csv`}
              onError={onError}
              onUpload={onSuccess}
              disabled={isUploaded}
            />
          </PageHeightAuto>
          {createdSection}
          {importedSection}
          {rejectedSection}
        </Dashboard>
      </div>
    </div>
  );
};

export default DebitorMappingImportWizard;
