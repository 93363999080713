import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'primereact/slider';
import ImageUploadForm from './ImageUploadForm';
import HtmlContentForm from './HtmlContentForm';
import { getContentTypeIdFromType, CONTENT_IMAGE_HTML } from '../Utils';
import { SecondaryButton } from '../../../../components/Buttons';

export default class HtmlImageContentForm extends React.PureComponent {
  static propTypes = {
    pageId: PropTypes.string,
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: { body: '' }
  }
  constructor(props) {
    super(props);
    // const model = props.model || {};
    // let size = 50;
    // let data = [];
    // try {
    //   data = JSON.parse(model.body || '[]');
    // } catch(err) {
    //   console.error('Error:', model, err);
    // }
    // if (data.length === 2) {
    //   const width = parseInt(((data[0].style || {}).width || '50%').replace('%', ''));
    //   // console.log('<< Data', data, width);
    //   if (!isNaN(width)) {
    //     size = width;
    //   }
    // }
    // this.state = { size, isImageLeft: true };
    this.state = this.generateState(props);
  }
  generateState = (props) => {
    const model = props.model || {};
    let size = 50;
    let data = [];
    let isImageLeft = true;
    try {
      data = JSON.parse(model.body || '[]');
    } catch(err) {
      console.error('Error:', model, err);
    }
    if (data.length === 2) {
      const width = parseInt(((data[0].style || {}).width || '50%').replace('%', ''));
      // console.log('<< Data', data, width);
      if (!isNaN(width)) {
        size = width;
      }
      // console.log('HtmlImageContentForm.constructor() - Image Data:', data[0]);
      if (data[0].isLeft !== undefined && !data[0].isLeft) {
        isImageLeft = false;
        size = 100 - size;
      }
    }
    return { size, isImageLeft, data };
  }
  getData = (noValidate, pIsImageLeft) => {
    const { size } = this.state;
    const imageData = this.imageForm.getModel(noValidate);
    const htmlData = this.htmlForm.getModel(noValidate);
    if (!imageData || !htmlData) {
      return null;
    }
    // console.log('<< HtmlImageContentForm.getData() size', size);
    // set html
    const isImageLeft = (pIsImageLeft !== undefined) ? pIsImageLeft : this.state.isImageLeft;
    if (imageData) {
      imageData.style = { width: `${isImageLeft ? size : 100 - size}%` };
    }
    if (htmlData) {
      htmlData.style = { width: `${isImageLeft ? 100 - size : size}%` };
    }
    return [ imageData, htmlData ];
  }
  getModel = (noValidate) => {
    const { isImageLeft } = this.state;
    const body = this.getData(noValidate, isImageLeft);
    if (!body) {
      return null;
    }
    const { id } = this.props.model;
    body[0].isLeft = isImageLeft;
    return {
      id,
      content_type_id: getContentTypeIdFromType(CONTENT_IMAGE_HTML),
      body: JSON.stringify(body)
    };
  }
  switchPositions = () => {
    const isImageLeft = !this.state.isImageLeft;
    const size = this.state.size;
    const data = this.getData(true, isImageLeft);
    this.setState({ size, isImageLeft, data });
  }
  render() {
    // console.log('HtmlImageContentForm.render()', this.props);
    // const { className, model } = this.props;
    // const { size } = this.state;
    // let data = [];
    // try {
    //   data = JSON.parse(model.body || '[]');
    // } catch(err) {
    //   console.error('Error:', model, err);
    // }
    const { className, pageId } = this.props;
    const { size, isImageLeft, data } = this.state;
    const imageMarkup = (
      <ImageUploadForm
        ref={ref => this.imageForm = ref}
        model={data[0]}
        expand
      />
    );
    const suffix = `${Math.random()}`.split('.').join('-');
    const panelId=`${pageId}-HI${suffix}`;
    const htmlMarkup = (
      <div id={panelId}>
        <HtmlContentForm
          ref={ref => this.htmlForm = ref}
          model={data[1]}
          pageId={panelId}
        />
      </div>
    );
    console.log('HtmlImageContetnForm.render()', this.state)
    return (
      <div className={`html-image-content-form ${className} pad`}>
        <div className="pad-tb clearfix">
          <SecondaryButton
            size="small"
            onClick={this.switchPositions}
          >
            {`Bild nach ${isImageLeft ? 'rechts' : 'links'}`}
          </SecondaryButton>
        </div>
        <div className="pad-top pad-big-btm clearfix" style={{zIndex: 2}}>
          <Slider
            value={size}
            onChange={(e) => this.setState({size: e.value})}
          />
        </div>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-top pad-sm-rht" style={{ width: `${size}%`}}>
            {isImageLeft ? imageMarkup : htmlMarkup}
          </div>
          <div className="w3-cell w3-cell-top pad-sm-lft" style={{ width: `${100 - size}%`}}>
            <div className="display-block">{isImageLeft ? htmlMarkup : imageMarkup}</div>
          </div>
        </div>
      </div>
    );
  }
}
