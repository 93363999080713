import * as actionTypes from './actions';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';
// import isArray from 'lodash/isArray';

const initialState = {
  results: null,
  searchedText: '',
  searching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return {
        ...state,
        searching: true,
        results: null
      };
    case actionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        results: action.result,
        searchedText: action.searchedText
      };
    case actionTypes.SEARCH_FAILURE:
      return {
        ...state,
        searching: false,
        results: null,
        error: action.result
      };
    
    case actionTypes.CANCEL_SEARCH:
      return {
        ...state,
        results: null,
        searchedText: ''
      }

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
