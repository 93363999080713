import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  PageHeight40,
  PageTitle,
  scrollToTop,
  TableEmptyMessage,
  EMPLOYEE_TASKTYPE_LABELS
} from '../../../../components/Utils';

import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
// import UserTaskForm from '../../components/debitors/UserTaskForm';
import UserTaskMenu from '../../components/user/UserTaskMenu';
// import { filterTasksByTaskType } from '../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import {
  closeEmployeeTasks,
  exportEmployeeTasks,
} from '../../actions/employee';

const mapStateToProps = (state) => {
  return {
    isCEO: state.auth.isCEO,
    isWorkerPlus: state.auth.isWorkerPlus,
    isLoading: state.task.loading,
    employeeTasks: state.task.employeeTasks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeTasks: (...args) => dispatch(closeEmployeeTasks(...args)),
  exportTasks: (...args) => dispatch(exportEmployeeTasks(...args)),
});


class UserTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isCEO: PropTypes.bool,
    isWorkerPlus: PropTypes.bool,
    isLoading: PropTypes.bool,
    rows: PropTypes.number,
    employeeTasks: PropTypes.array,
    employeeTaskType: PropTypes.string,
    // filterUserTasks: PropTypes.func.isRequired,
    closeTasks: PropTypes.func.isRequired,
    exportTasks: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: '',
    rows: 10,
    employeeTasks: []
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
      selection: [],
    }
  }
  componentDidMount() {
    scrollToTop();
  }
  onClose = (task) => {
    const { closeTasks } = this.props;
    console.log(`UserTaskList.onClose()`, task);
    closeTasks({ tasks: [ task ] });
  }
  render() {
    const {
      className, isLoading, rows,
      employeeTasks,
      isCEO, isWorkerPlus,
      closeTasks,
      exportTasks,
    } = this.props;
    const { selection } = this.state;
    const setSelection = (value) => this.setState({ selection: value });
    // console.log("UserTaskList()", employeeTasks)

    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item) => ({
        ...item,
        employee: `${item.userData.firstname} ${item.userData.lastname}`,
        vfEmail: item.userData.vf_email,
        typeName: item.typeName || EMPLOYEE_TASKTYPE_LABELS[item.type]
      })); // .sort(createSortByAttr('debitorKey'));
      return (
        <div className="w3-border-left w3-border-right" style={{overflow: "hidden"}}>
          <div style={{margin: "0 -2px 0 -1px"}}>
            <DataTable
              value={taskItems}
              paginator={taskItems.length > rows}
              rows={rows}
              first={this.state.first}
              onPage={(event) => {
                this.setState({first: event.first});
                scrollToTop();
              }}
              paginatorLeft={
                <SalesTablePageReport
                  className="pad-lft"
                  totalRecords={taskItems.length}
                  filteredRecords={taskItems.length}
                  first={this.state.first}
                  rows={rows}
                  emptyText="Keine Benutzeraufgaben"
                  itemName="Benutzeraufgabe"
                  itemNamePlural="Benutzeraufgaben"
                />
              }
              selection={selection}
              onSelectionChange={event => setSelection(event.value)}
              removableSort
              sortMode="multiple"
              emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Benutzeraufgaben"/>)}
              scrollable
            >
              <Column
                id="icheck"
                selectionMode="multiple"
                style={{ width: "3em" }}
              />
              <Column
                field="parentCompanyName"
                header="Vertragspartner"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '16em'}}
              />
              <Column
                field="employee"
                header="Mitarbeiter"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '12em'}}
              />
              <Column
                field="vfEmail"
                header="Vodafone E-Mail"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '14em'}}
              />
              <Column
                field="typeName"
                header="Geändert"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '10em'}}
              />
              <Column
                field="fromValue"
                header="Von"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '14em'}}
              />
              <Column
                field="toValue"
                header="Zu"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '14em'}}
              />
              <Column
                field="requester"
                header="Anforderer"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{width: '12em'}}
              />
              {/* <Column
                field="status"
                header="Status"
                sortable={taskItems.length > 1}
                filter={taskItems.length > rows}
                filterMatchMode="contains"
                style={{textAlign:'center-', width: '9em'}}
              /> */}
              <Column
                body={actionTemplate}
                style={{textAlign:'center', width: '4.5em'}}
              />
            </DataTable>
          </div>
        </div>
      );
    };

    const renderItemForm = (item, onHide) => {
      return (
        <div>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)}
            onCancel={onHide}
            saveBtnName="saveEmployeeTaskBtn"
            saveBtnLabel="Bestätigen"
          />
        </div>
      );
    };
    const getItemFormTitle = (/* employeeTask */) => {
      let title = 'Benutzeraufgabe bearbeiten';
      return (
        <h3 className="no-margin">{title}</h3>
      );
    }
    const title = (
      <PageTitle>
        <UserTaskMenu
          className="w3-right"
          selectedUserTasks={selection}
          closeTasks={closeTasks}
          exportTasks={exportTasks}
          items={employeeTasks}
        />
        <h3 className="no-margin pad-btm">
          {'Änderung Kontaktdaten'}
        </h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={employeeTasks}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`Benutzer`}
          // itemDeleteable
          // deleteTitle={`Benutzeraufgabe löschen`}
          // getDeleteText={(item) => `Wollen Sie diese Aufgabe unwiderruflich löschen?`}
          // onDelete={this.onDelete}
          // showItemDeleteReason
          itemCloseableOnly
          hideDisabledClose
          isCloseDisabled={() => (isWorkerPlus && !isCEO)}
          closeTitle={`Benutzeraufgabe schließen`}
          getCloseText={(/* item */) => `Wollen Sie diese Aufgabe wirklich schlißen?`}
          onClose={this.onClose}

        />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTaskList);
