import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connectDisplay, getDataDisplayColumns } from '../components/DisplayUtils';
import { SubDisplayWeeks} from './DisplayWeeks';


class DisplayMonths extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    columnNames: PropTypes.array,
    filterConfig: PropTypes.shape({}),
    getSales: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: '',
    data: []
  }
  constructor() {
    super();
    this.state = {
      expandedRows: null
    };
  }
  getSales = (event) => {
    const { filterConfig, getSales } = this.props;
    // console.log('DisplayMonths.getSales() item', event.data);
    getSales({ filterConfig, item: event.data });
  }
  render() {
    const { className, data, columnNames } = this.props;
    console.log('DisplayMonths.render()', this.props);
    const rowExpansionTemplate = (item) => {
      // console.log('>> display months as sub component ...', item);
      // const style = { margin: '-0.429em -0.857em', paddingLeft: '2.9em' };
      const style = { margin: '-0.429em -0.925em', paddingLeft: '2.85em' };
      return (
        <div style={style}>
          <SubDisplayWeeks
            key={Math.random()}
            className="no-table-border"
            item={item}
          />
        </div>
      );
    };
    return (
      <div className={`${className}`}>
        <DataTable
          value={data}
          dataKey="month"
          expandedRows={this.state.expandedRows}
          onRowToggle={(event) => this.setState({ expandedRows: event.data })}
          onRowExpand={this.getSales}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column
            expander
            style={{ width: '2.9em' }}
          />
          <Column
            header={(<div className="alg-left">{'Monat'}</div>)}
            body={(item) => (<div className="bold">{item.month || item.value}</div>)}
          />
          {getDataDisplayColumns(columnNames)}
        </DataTable>
      </div>
    );
  }
}

export const SubDisplayMonths = connectDisplay(DisplayMonths, 'month', true);

export default connectDisplay(DisplayMonths, 'month');
