import { createSortByName, isKDL } from "../../../components/Utils";

export const buildSalesKDLs = (user, companies) => {
  if (!user || !companies) {
    return [];
  }
  const kdlCompanies = companies
    .filter(item => isKDL(item))
    .sort(createSortByName());
  const userKdlCompanies = (user.companies || []).filter(company => {
    const extendedRolesData = company.extendedRolesData || [];
    for (let index = 0; index < extendedRolesData.length; index ++) {
      const role = extendedRolesData[index].label.toLowerCase();
      if (role.indexOf('sale') >= 0) {
        return true;
      }
    }
    return false;
  });
  console.log('>> KdlCompanies:', kdlCompanies, '\n>> userCompanies', user.companies, '\n>> userKdlCompanies', userKdlCompanies);
  // merge
  userKdlCompanies.forEach(company => {
    const found = kdlCompanies.find(item => item.id === company.id);
    if (!found) kdlCompanies.push({ name: company.contractGiver });
  });
  return kdlCompanies;
};
