import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ItemListManager from '../../../components/ItemListManager';
import {
  toUIDate,
  STATUS_LABELS,
  DEVICE_ACTION_IDS,
  isDeviceStatusEditable,
  DEVICE_STATUS_LABELS,
  DEVICE_STATUS_IDS,
  TableEmptyMessage
} from '../../../components/Utils';
import { DEFAULT_USER_DEVICE, getDeviceDeleteMsg } from './Utils';
import UserDeviceFormHolder from '../containers/UserDeviceFormHolder';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';


export default class UserDeviceListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    isEditable: PropTypes.bool,
    isAdmin: PropTypes.bool,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  validate = () => {
    const userDevices = this.userDevices.getModel();
    const model = [ ...userDevices ];
    console.log('UserDeviceList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  toggleAddition = (disabled) => {
    this.userDevices.toggleAddition(disabled);
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <UserDevice
          ref={(ref) => this.userDevices = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class UserDevice extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    isEditable: PropTypes.bool,
    isAdmin: PropTypes.bool,
    rows: PropTypes.number
  }
  static defaultProps = {
    rows: 10,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      addDisabled: false,
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map(item => {
      const {
        device, serialNumber, options, regions, company_id, companyId,
        userInChargeCompanyName, userInChargeCompanyId, companyName, contractGiver,
        userInCharge, userPrimary, userSecondary, userInChargeId, userPrimaryId, userSecondaryId,
        isCalibrated, comparismDevice, macAddress, lastCheckDate, nextCheckDate, modifyDate,
        locationText, account, id, status_id, action_id, status, oldStatusId
      } = item;
      const userDevice = {
        device, serialNumber, options, regions,
        company_id: (companyId || company_id),
        companyId: (companyId || company_id),
        userInChargeCompanyName, userInChargeCompanyId, companyName, contractGiver,
        userInCharge, userPrimary, userSecondary, userInChargeId, userPrimaryId,
        isCalibrated,
        // isCalibrated: isCalibrated ? 1 : 0,
        comparismDevice, macAddress, lastCheckDate, nextCheckDate, modifyDate,
        locationText, account, status_id, action_id, status, oldStatusId
      };
      if (id !== undefined) {
        userDevice.id = id;
      }
      if (!isNaN(userSecondaryId) && userSecondaryId > 0) {
        userDevice.userSecondaryId = userSecondaryId;
      }
      return userDevice;
    });
    return model;
  }
  toggleAddition = (disabled) => {
    this.setState({addDisabled: disabled});
  }
  onSave = (model, onHide, updating, saveOnly, deleting) => {
    // const model = this.form.getModel();
    if (!model) return;
    if (!saveOnly) {
      if (updating) {
        model.status_id = DEVICE_STATUS_IDS.UPDATING;
        model.action_id = DEVICE_ACTION_IDS.UPDATE
      } else if (deleting) {
        model.status_id = DEVICE_STATUS_IDS.DELETING;
        model.action_id = DEVICE_ACTION_IDS.DELETE_ACCOUNT
      } else {
        model.status_id = DEVICE_STATUS_IDS.NEW;
        model.action_id = DEVICE_ACTION_IDS.CREATE;
      }
    } else {
      if (!model.status_id) {
        model.status_id = DEVICE_STATUS_IDS.FINISHED;
      }
    }
    const { items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    // const { isAdmin } = this.props;
    const account = (selected.account && selected.account !== '') ? selected.account : null;
    // (isAdmin || !account)
    if (selected.id && account) {
      // console.log('>> marking user device for deletion', selected);
      const { items } = this.state;
      if (items[selected.index]) {
        items[selected.index].status_id = DEVICE_STATUS_IDS.DELETING;
        items[selected.index].action_id = DEVICE_ACTION_IDS.DELETE;
        items[selected.index].status = null;
        this.setState({ items });
        this.props.onChange && this.props.onChange(items);
      } else {
        console.error('<< user device to be deleted was not found', this.state.items);
      }
    } else {
      // console.log('>> deleting user device from list', selected);
      const items = [];
      for (const index in this.state.items) {
        // console.log('>> delete: comparing index', index, 'with selected index', selected.index);
        if (index != selected.index) {
          items.push(this.state.items[index]);
        }
      }
      this.setState({ items });
      this.props.onChange && this.props.onChange(items);
    }
  }
  render() {
    const { className, getBaseData, isEditable, rows } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map(item => {
        const copy = JSON.parse(JSON.stringify(item));
        let status = item.status || STATUS_LABELS[item.status_id] || '';
        if (item.oldStatusId !== undefined && item.oldStatusId !== item.status_id) {
          status = STATUS_LABELS[item.status_id];
        }
        return {
          ...copy,
          nextCheckDateStr: toUIDate(copy.nextCheckDate),
          status
        };
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Messgeräte"
              itemName="Messgerät"
              itemNamePlural="Messgeräte"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Messgeräte" min/>)}
        >
          <Column
            field="device"
            header="Messgerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="userPrimary"
            header="Hauptbenutzer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="nextCheckDateStr"
            header="Prüfung fällig"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Status"
            body={rowData => {
              let markup = rowData.status;
              if (rowData.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT) {
                markup = DEVICE_STATUS_LABELS[DEVICE_STATUS_IDS.DELETING_ACCOUNT];
              }
              return markup;
            }}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const setPrimaryUser = (device, props) => {
        const { currentWorker, currentWorkerCompany } = props;
        if (device) {
          if (currentWorker) {
            device.userPrimaryId = currentWorker.id;
          }
          if (currentWorkerCompany) {
            device.companyName = currentWorkerCompany.name;
            device.companyId = currentWorkerCompany.id;
          }
        }
        return device;
      };
      const baseData = getBaseData ? getBaseData() : {};
      const userDevice = item || setPrimaryUser({ ...DEFAULT_USER_DEVICE }, baseData);
      console.log('UserDeviceListForm.renderItemForm()', userDevice, baseData);
      return (
        <UserDeviceFormHolder
          key={Math.random()}
          userDevice={userDevice}
          {...baseData}
          isEditable={isEditable}
          onSave={!isEditable ? null : (model, saveOnly) => this.onSave(model, onHide, false, saveOnly)}
          onUpdate={!isEditable ? null : (model) => this.onSave(model, onHide, true)}
          onDelete={!isEditable ? null : (model) => this.onSave(model, onHide, false, false, true)}
          onCancel={onHide}
        />
      );
    };
    const getItemFormTitle = (userDevice) => {
      console.log("getItemFormTitle()", userDevice)
      // const isVisibleOnly = !isEditable || !userDevice || !isDeviceStatusEditable(userDevice.status_id, userDevice.id, true);
      const isVisibleOnly = !isEditable || (userDevice && !isDeviceStatusEditable(userDevice.status_id, userDevice.id, true));
      const isNew = (!userDevice);
      return (
        <h3 className="no-margin">
          {/* {`Messgerät ${isVisibleOnly ? 'ansehen' : (isEditing ? 'bearbeiten' : 'hinzufügen')}`} */}
          {`Messgerät ${isNew ? "hinzufügen" : (isVisibleOnly ? "ansehen" : "bearbeiten")}`}
        </h3>
      );
    }
    const isEditDisabled = (item) => (
      !isEditable || !isDeviceStatusEditable(item.status_id, item.id, true)
    );
    const isDeleteDisabled = (item) => (
      (item.id && item.status_id !== DEVICE_STATUS_IDS.FINISHED)/*  || (!item.account || item.account === '' ) */
    );
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">Messgeräte</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnDisabled={!isEditable}
        addBtnName="addWorkerDeviceBtn"
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="userDevice"
        itemVisibleOnly={!isEditable}
        isEditDisabled={isEditDisabled}
        itemDeleteable={false}
        hideDisabledDelete
        isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Messgerät löschen"
        getDeleteText={(item) => getDeviceDeleteMsg(item/* , isAdmin */)}
        onDelete={this.onDelete}
      />
    );
  }
}
