import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { isPlainObject } from "lodash";
import { PrimaryButton } from "../../../components/Buttons";

const ColToggleDataTable = React.forwardRef(({
  children,
  columnsToShow,
  onSelectColumn,
  ...props
}, ref) => {
  const childrenArray = React.Children.toArray(children);
  const availableColumns = childrenArray.map(
    (child) => ({
      label: child.props.header,
      value: child.props.field
    })
  );
  const columnsToShowSet = new Set(columnsToShow);
  const selectedColumns = availableColumns.filter(
    (column) => columnsToShowSet.has(column.value)
  ).map(item => isPlainObject(item) && item.value ? item.value : item);
  const columns = childrenArray.filter(
    (child) => columnsToShowSet.has(child.props.field) || child.props.field === 'action'
  );
  // const handleChange = useCallback((event) => {
  //   const newColumnsToShow = event.value.map((item) => isPlainObject(item) && item.value ? item.value : item);
  //   onSelectColumn(newColumnsToShow);
  // }, [onSelectColumn]);

  const onChange = useCallback((event) => {
    console.log('>> onChange :', event.value);
    onSelectColumn(event.value);
  }, [ onSelectColumn ]);
  const { id } = props;
  const colToggleId = `${id}-col-toggle`;
  return (
    <>
      <div className="pad-big w3-border-left w3-border-right">
        <div className="w3-row">
          <div className="w3-col s3 pad-rht">
            <MultiSelect
              id={colToggleId}
              className="w3-border expand"
              options={availableColumns}
              value={selectedColumns}
              // onChange={handleChange}
              // // dataKey="value"
              onChange={onChange}
              optionLabel="label"
              filter
              // appendTo={document.body}
              selectedItemsLabel="{0} Spalten ausgewählt"
              placeholder="Wählen Sie Spalten aus"
            />
          </div>
        </div>
      </div>
      <DataTable ref={ref} {...props}>
        {columns}
      </DataTable>
    </>
  );
});

ColToggleDataTable.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  columnsToShow: PropTypes.arrayOf(PropTypes.string),
  onSelectColumn: PropTypes.func.isRequired
};

ColToggleDataTable.defaultProps = {
  columnsToShow: []
};

export default ColToggleDataTable;
