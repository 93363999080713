import React from 'react';
import PropTypes from 'prop-types';
import { Password, Label } from '../../../components/Inputs';
import { validatePassword } from '../../../components/Utils';

export default class PasswordChangeFormContent extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      error: {
        newPassword: null,
        newPassword2: null,
        oldPassword: null
      }
    }
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = '';
        isValid = false;
      }
    });
    // new password
    const test = {};
    if (!validatePassword(model.newPassword, test)) {
      error.newPassword = test.error || 'ungültiges Passwort';
      isValid = false;
    }
    // new password repeated
    if (model.newPassword !== model.newPassword2) {
      error.newPassword2 = 'Beide Passwörter stimmen nicht überein';
      isValid = false;
    }
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  submit = () => {
    const model = this.getModel();
    if (!model) return;
    // validate
    this.props.onSubmit(model);
  }
  render() {
    const { className } = this.props;
    const { oldPassword, newPassword, newPassword2, error } = this.state;
    return (
      <div className={className}>
        <div className={`${error.oldPassword && 'error-group'}`}>
          <Label htmlFor="oldPassword">{`Altes Passwort`}<span>*</span></Label>
          <Password
            id="oldPassword"
            autoFocus
            className="" value={oldPassword}
            onChange={(event) => this.setState({oldPassword: event.target.value})}/>
        </div>
        <div className={`pad-top ${error.newPassword && 'error-group'}`}>
          <Label htmlFor="newPassword">{`Neues Passwort`}<span>*</span></Label>
          <Password
            id="newPassword" className="" value={newPassword}
            onChange={(event) => this.setState({newPassword: event.target.value})}/>
          {error.newPassword && (<div className="pad-sm-top">{error.newPassword}</div>)}
        </div>
        <div className={`pad-top ${error.newPassword2 && 'error-group'}`}>
          <Label htmlFor="newPassword2">{`Neues Passwort wiederholen`}<span>*</span></Label>
          <Password
            id="newPassword2" className="" value={newPassword2}
            onChange={(event) => this.setState({newPassword2: event.target.value})}
            onKeyUp={(event) => (event.keyCode === 13) && this.submit()}/>
          {error.newPassword2 && (<div className="pad-sm-top">{error.newPassword2}</div>)}
        </div>
      </div>
    );
  }
}
