import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label } from '../../../components/Inputs';
import Parser from 'html-react-parser';

export default class KeyBasicDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({})
  }
  static defaultProps = {
    className: '',
    model: {
      name: '',
      number: '',
      description: '',
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      name: model.name || '',
      number: model.number || '',
      // description: model.description || '',
      description: (model.description && model.description !== '') ? Parser(model.description) : '',
      error: {
        name: null,
        number: null,
      }
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (error[key] !== undefined) {
        if (model[key] === '') {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    !noValidate && this.setState({ error });
    if (!isValid) {
      return null;
    }
    // const { id, index } = this.props.model;
    return { ...this.props.model, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    const { className } = this.props
    const { name, number, unit, description, error } = this.state;
    // console.log('CardBasicDataForm.render()', this.state);  
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.name && 'error-group'}`}>
              <Label htmlFor="name">{`VKDG-Bezeichnung*`}</Label>
              <InputText
                id="name"
                className="w3-block w3-border"
                value={name}
                onChange={(event) => this.setState({
                  name: event.target.value,
                  error: { ...error, name: ((event.target.value === ''))}
                })}
              />
            </div>
            <div className={`pad-top form-group-item ${error.number && 'error-group'}`}>
              <Label htmlFor="number">{`VKDG-Bestell-Nr*`}</Label>
              <InputText
                id="number"
                className="w3-block w3-border"
                value={number}
                onChange={(event) => this.setState({
                  number: event.target.value,
                  error: { ...error, number: ((event.target.value === ''))}
                })}
              />
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="pad-top">
              <Label htmlFor="description">{`Zusatzbemerkung`}</Label>
              <InputTextarea
                id="description"
                className="w3-block w3-border"
                value={description}
                rows={8}
                autoResize
                onChange={(event) => this.setState({
                  description: event.target.value
                })}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
