import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label } from '../../../../components/Inputs';
// import ImageUploadForm from './PersonForm';

function toTwoDigits(n) {
  return n > 9 ? "" + n: "0" + n;
}

export default class ModemBasicDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      name: '',
      shortname: '',
      material_number: '',
      description: '',
      imagePath: '',
      status_id: 1
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      name: model.name || '',
      shortname: model.shortname || '',
      material_number: model.material_number || '',
      description: model.description || '',
      error: {
        name: null
      }
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }
    // imagePath (optional)
    model.imagePath = this.props.model.imagePath;
    // const image = this.imageForm.getModel();
    // if (image && image.filePath) {
    //   model.imagePath = image.filePath;
    // }
    const { id, index, status_id } = this.props.model;
    return { id, index, status_id, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    // console.log('>> adding debitor:', model);
    return model;
  }
  render() {
    const { className, model: { imagePath } } = this.props
    const { name, shortname, packageSize, material_number, description, error } = this.state;
    return (
      <div className={className}>
      {/* <div className={className} key={Math.random()}> */}
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.name && 'error-group'}`}>
              <Label htmlFor="name">{`Name*`}</Label>
              <InputText id="name" className="w3-block w3-border" value={name}
                onChange={(event) => this.setState({name: event.target.value})}/>
            </div>
            <div className="pad-top form-group-item">
              <Label htmlFor="shortname">{`Kurzname`}</Label>
              <InputText id="shortname" className="w3-block w3-border" value={shortname}
                onChange={(event) => this.setState({shortname: event.target.value})}/>
            </div>
            {/* <div className="pad-top form-group-item">
              <Label htmlFor="material_number">{`Materialnummer`}</Label>
              <InputText id="material_number" className="w3-block w3-border" value={material_number}
                onChange={(event) => this.setState({material_number: event.target.value})}/>
            </div> */}
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="pad-top">
              <Label htmlFor="description">{`Bemerkungen`}</Label>
              <InputTextarea
                id="description"
                className="w3-block w3-border"
                value={description}
                // rows={8}
                style={{height: "115px"}}
                // autoResize
                onChange={(event) => this.setState({description: event.target.value})}/>
            </div>
          </div>
          {/* <div className="w3-col s6 pad-lr pad-top">
            <Label htmlFor= "image">{`Bild`}</Label>
          </div> */}
        </div>
      </div>
    );
  }
}

