import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { PrivateRoute, PublicRoute, UserTaskManagerRoute } from '../components/Routes';
import NotFound from './general/NotFound';

const PublicHome = Loadable({
  loader: () => import(/* webpackChunkName: "PublicHome" */ './Home'),
  loading: () => null,
  modules: ['PublicHome'],
  webpack: () => [require.resolveWeak('./Home')]
});

const UserHome = Loadable({
  loader: () => import(/* webpackChunkName: "UserHome" */ './general/Home'),
  loading: () => null,
  modules: ['UserHome'],
  webpack: () => [require.resolveWeak('./general/Home')]
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ './general/Login'),
  loading: () => null,
  modules: ['Login'],
  webpack: () => [require.resolveWeak('./general/Login')]
});
const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "ForgotPassword" */ './general/ForgotPassword'),
  loading: () => null,
  modules: ['ForgotPassword'],
  webpack: () => [require.resolveWeak('./general/ForgotPassword')]
});
const Profile = Loadable({
  loader: () => import(/* webpackChunkName: "Profile" */ './profile/index'),
  loading: () => null,
  modules: ['Profile'],
  webpack: () => [require.resolveWeak('./profile/index')]
});
const WebShops = Loadable({
  loader: () => import(/* webpackChunkName: "WebShops" */ './shops/index'),
  loading: () => null,
  modules: ['WebShops'],
  webpack: () => [require.resolveWeak('./shops/index')]
});
const Technik = Loadable({
  loader: () => import(/* webpackChunkName: "Technik" */ './technik/index'),
  loading: () => null,
  modules: ['Technik'],
  webpack: () => [require.resolveWeak('./technik/index')]
});
const Sales = Loadable({
  loader: () => import(/* webpackChunkName: "Sales" */ './sales/index'),
  loading: () => null,
  modules: ['Sales'],
  webpack: () => [require.resolveWeak('./sales/index')]
});
const Organisation = Loadable({
  loader: () => import(/* webpackChunkName: "Organisation" */ './organisation/index'),
  loading: () => null,
  modules: ['Organisation'],
  webpack: () => [require.resolveWeak('./organisation/index')]
});
const Wizard = Loadable({
  loader: () => import(/* webpackChunkName: "Wizard" */ './wizard/index'),
  loading: () => null,
  modules: ['Wizard'],
  webpack: () => [require.resolveWeak('./wizard/index')]
});
const News = Loadable({
  loader: () => import(/* webpackChunkName: "News" */ './news/index'),
  loading: () => null,
  modules: ['News'],
  webpack: () => [require.resolveWeak('./news/index')]
});
const Tasks = Loadable({
  loader: () => import(/* webpackChunkName: "Tasks" */ './tasks/index'),
  loading: () => null,
  modules: ['Tasks'],
  webpack: () => [require.resolveWeak('./tasks/index')]
});
const Search = Loadable({
  loader: () => import(/* webpackChunkName: "News" */ './search/index'),
  loading: () => null,
  modules: ['Search'],
  webpack: () => [require.resolveWeak('./search/index')]
});
const Privacy = Loadable({
  loader: () => import(/* webpackChunkName: "Privacy" */ './general/Privacy'),
  loading: () => null,
  modules: ['Privacy'],
  webpack: () => [require.resolveWeak('./general/Privacy')]
});
const PrivacyPolicy = Loadable({
  loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ './general/PrivacyPolicy'),
  loading: () => null,
  modules: ['PrivacyPolicy'],
  webpack: () => [require.resolveWeak('./general/PrivacyPolicy')]
});
const Impressum = Loadable({
  loader: () => import(/* webpackChunkName: "Impressum" */ './general/Impressum'),
  loading: () => null,
  modules: ['Impressum'],
  webpack: () => [require.resolveWeak('./general/Impressum')]
});
const Abbreviations = Loadable({
  loader: () => import(/* webpackChunkName: "Abbreviations" */ './general/Abbreviations'),
  loading: () => null,
  modules: ['Abbreviations'],
  webpack: () => [require.resolveWeak('./general/Abbreviations')]
});
const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "Contact" */ './general/Contact'),
  loading: () => null,
  modules: ['Contact'],
  webpack: () => [require.resolveWeak('./general/Contact')]
});
const NewsService = Loadable({
  loader: () => import(/* webpackChunkName: "NewsService" */ './news-service/index'),
  loading: () => null,
  modules: ['NewsService'],
  webpack: () => [require.resolveWeak('./news-service/index')]
});

export default () => (
  <Switch>
    <Route exact path="/" component={PublicHome} />
    <UserTaskManagerRoute path="/aufgaben(/*)" component={Tasks} />
    <PublicRoute path="/login(/*)" component={Login} />
    <Route path="/news(/*)" component={News} />
    <Route path="/news-service(/*)" component={NewsService} />
    <PrivateRoute path="/organisation(/*)" component={Organisation} />
    <PublicRoute path="/passwort-vergessen(/*)" component={ForgotPassword} />
    <PrivateRoute path="/profil(/*)" component={Profile} />
    <PrivateRoute path="/sales(/*)" component={Sales} />
    <PrivateRoute path="/search(/*)" component={Search} />
    <PrivateRoute path="/shop(/*)" component={WebShops} />
    <PrivateRoute path="/start(/*)" component={UserHome} />
    <PrivateRoute path="/technik(/*)" component={Technik} />
    <PrivateRoute path="/wizard(/*)" component={Wizard} />
    <PrivateRoute path="/datenschutz(/*)" component={Privacy} />
    <PrivateRoute path="/datenschutzerklaerung(/*)" component={PrivacyPolicy} />
    <Route path="/impressum(/*)" component={Impressum} />
    <PrivateRoute path="/abkuerzungen(/*)" component={Abbreviations} />
    <PrivateRoute path="/kontakt(/*)" component={Contact} />
    <Route component={NotFound} />
  </Switch>
);
