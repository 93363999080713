import * as actionTypes from './actions';
import isArray from 'lodash/isArray';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  trainingUrl: null,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load training url
    case actionTypes.LOAD_TRAINING_LINK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_TRAINING_LINK_SUCCESS: {
      console.log('<< REDUX RESULT:', action);
      return {
        ...state,
        loading: false,
        trainingUrl: action.result.url
      };
    }
    case actionTypes.LOAD_TRAINING_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        trainingUrl: null,
        error: action.result
      };
    // cancel training
    case actionTypes.CANCEL_LINK:
      return {
        ...state,
        trainingUrl: null
      }
    // user rating
    case actionTypes.LOAD_USER_TRAINING:
      return {
        ...state,
        loading: true,
        userTrainings: []
      };
    case actionTypes.LOAD_USER_TRAINING_SUCCESS:
      return {
        ...state,
        loading: false,
        userTrainings: action.result
      };
    case actionTypes.LOAD_USER_TRAINING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // user rating
    case actionTypes.LOAD_USER_RATING:
      return {
        ...state,
        loading: true,
        userRatings: []
      };
    case actionTypes.LOAD_USER_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        userRatings: action.result
      };
    case actionTypes.LOAD_USER_RATING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return { ...state };
  }
}
