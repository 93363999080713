import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
import { Label } from '../../../../components/Inputs';

// const RoleList = [
//   { name}
// ]

const getRoleNames = (roleList, model) => {
  return model.map(roleLabel => {
    const role = roleList.find(item => item.label === roleLabel);
    return role ? role.name : roleLabel; // return label if the name is not found
  })
}

const getRoleLabels = (roleList, model) => {
  return model.map(roleName => {
    const role = roleList.find(item => item.name === roleName);
    return role ? role.label : roleName; // return name if the label is not found
  })
}

export default class RoleForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    roleList: PropTypes.array,
    isAdmin: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    model: [],
    roleList: []
  }
  constructor(props) {
    super(props);
    const { model, roleList } = props;
    this.state = {
      model: getRoleLabels(roleList, model),
      isContractor: false
    };
  }
  // componentWillReceiveProps(props) {
  //   this.setState({ model: props.model });
  // }
  validate = () => {
    const { model } = this.state;
    // return [ ...model ];
    return getRoleNames(this.props.roleList, model);
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  onCheckboxChange = (event) => {
    let model = [ ...this.state.model ];
    if(event.checked)
      model.push(event.value);
    else
      model.splice(model.indexOf(event.value), 1);
    this.setState({ model });
  }
  setForContractor = (isContractor) => {
    const model = [ 'Techniker' ];
    this.setState({ model, isContractor })
  }
  getColumn = (roles, number) => {
    const col = [];
    let size = Math.floor(roles.length / 3);// this.props.isAdmin ? 5 : 3;
    if (roles.length - (size * 3) > 1) {
      size ++;
    }
    let startIndex = 0;
    let endIndex = size - 1;
    if (number === 1 || number === 2 || number === 3) {
      startIndex = (number - 1) * size;
      endIndex = startIndex + size - 1;
      if (number === 3) {
        endIndex = roles.length - 1;
      }
    }
    roles.forEach((role, index) => {
      if (index >= startIndex && index <= endIndex) {
        col.push(role.label);
      }
    });
    return col;
  }
  render() {
    const { className, roleList } = this.props
    const { model, isContractor } = this.state;
    const createCheckbox = (prefix) => (value, index) => (
      <div key={index} className="p-col-12">
        <Checkbox inputId={`${prefix}${index}`} value={value} onChange={this.onCheckboxChange}
          checked={model.includes(value)} disabled={isContractor}>
        </Checkbox>
        <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
      </div>
    );
    const col1 = this.getColumn(roleList, 1).map(createCheckbox('cb1-'));
    const col2 = this.getColumn(roleList, 2).map(createCheckbox('cb2-'));
    const col3 = this.getColumn(roleList, 3).map(createCheckbox('cb3-'));
    return (
      <div className={className}>
        <Label>{`Aufgabenbereiche`}</Label>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s4 pad-lr">{col1}</div>
            <div className="w3-col s4 pad-lr">{col2}</div>
            <div className="w3-col s4 pad-lr">{col3}</div>
          </div>
        </div>
      </div>
    );
  }
}
