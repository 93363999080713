import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { Label, Dropdown } from '../../../components/Inputs';

export default class TechFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    companies: PropTypes.array,
    workers: PropTypes.array,
    getWorkers: PropTypes.func.isRequired,
    getFormData: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    toggleFormState: PropTypes.func
  }
  static defaultProps = {
    className: '',
    model: {
      companyName: null,
      username: null,
    },
    companies: [],
    workers: []
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillUnmount() {
    this.props.onCancel && this.props.onCancel();
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      companyName: model.companyName || '',
      username: model.username || '',
      error: {
        companyName: null,
        username: null
      }
    }
  }
  validate = (state, noRefresh) => {
    const { error, ...model } = (state || this.state);
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (model[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      } else {
        error[key] = null;
      }
    })
    // console.log('validated:', model, error, !noRefresh);
    if (!noRefresh) {
      // console.log('>> refreshing ...')
      this.setState({ error });
    }
    if (!isValid) {
      return null;
    }
    const original = this.props.model || {};
    return { ...original, ...model };
  }
  getModel = (noRefresh) => {
    const model = this.validate(null, noRefresh);
    return model;
  }
  setCompany = (companyName) => {
    const state = { companyName, username: '' };
    this.setState(state);
    this.props.getWorkers({ companyName });
    this.checkValidity({ ...this.state, ...state });
  }
  setWorker = (username) => {
    const state = { username };
    this.setState(state);
    this.props.getFormData({ username });
    this.checkValidity({ ...this.state, ...state });
  }
  checkValidity = (state) => {
    const { toggleFormState } = this.props;
    if (!toggleFormState) return;
    const model = this.validate(state, true);
    toggleFormState(model !== null);
  }
  render() {
    const { className, companies, workers } = this.props;
    const { companyName, username, error } = this.state;
    const CompanyOptions = companies.map(item => ({ label: item.name, value: item.name }));
    const WorkerOptions = workers.map(item => ({ label: item.username, value: item.username }));
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.companyName && 'error-group'}`}>
            <Label htmlFor="companyName">{'KDL/Firma'}</Label>
            <Dropdown
              id="companyName"
              className="w3-block w3-border w3-round"
              value={companyName}
              placeholder="Bitte auswählen"
              options={CompanyOptions}
              onChange={(event) => this.setCompany(event.value)}
              filter
              showClear
            />
          </div>
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.username && 'error-group'}`}>
            <Label htmlFor="username">{`Mitarbeiter`}</Label>
            <Dropdown
              id="username"
              className="w3-block w3-border"
              value={username}
              placeholder="Bitte auswählen"
              options={WorkerOptions}
              onChange={(event) => this.setWorker(event.value)}
              filter
              showClear
            />
          </div>
        </div>
      </div>
    );
  }
}
