import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { CalendarRange } from "../../../../components/Inputs";
import { toDBDateTime } from "../../../../components/Utils";

const RangeFilter = ({ dt, field, onChange, ...props }) => {
  const handleChange = useCallback((event) => {
    const { value } = event;
    if (onChange) {
      onChange(field, (value || []).map(item => toDBDateTime(item)));
    } else {
      dt.filter(value, field, "custom");
    }
  }, [dt, field]);

  console.log('RangeFilter.render()');
  return (
    <CalendarRange
      onChange={handleChange}
      {...props}
    />
  );
};

RangeFilter.propTypes = {
  dt: PropTypes.shape({
    filter: PropTypes.func.isRequired
  }),
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default React.memo(RangeFilter);
