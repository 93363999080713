import React from 'react';
import PropTypes from 'prop-types';
// // import { Dropdown } from 'primereact/dropdown';
// import { MultiSelect } from 'primereact/multiselect';
import { Label, MultiSelect } from '../../../components/Inputs';

export default class DelphiNrFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    delphiNrOptions: PropTypes.array,
    onCancel: PropTypes.func,
    toggleFormState: PropTypes.func
  }
  static defaultProps = {
    className: '',
    model: {
      delphiNrs: [],
    },
    delphiNrOptions: []
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillUnmount() {
    this.props.onCancel && this.props.onCancel();
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      delphiNrs: model.delphiNrs || [],
      error: {
        delphiNrs: null
      }
    }
  }
  validate = (state, noRefresh) => {
    const { error, delphiNrs } = (state || this.state);
    // check if all values are set
    let isValid = true;
    if (delphiNrs.length === 0) {
      error.delphiNrs = true;
      isValid = false;
    } else {
      error.delphiNrs = null;
    }

    !noRefresh && this.setState({ error });
    if (!isValid) {
      return null;
    }
    const original = this.props.model || {};
    return { ...original, delphiNrs, display: 'delphi' };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  setDelphiNrs = (delphiNrs) => {
    this.setState({ delphiNrs });
    this.checkValidity({ ...this.state, delphiNrs });
  }
  checkValidity = (state) => {
    const { toggleFormState } = this.props;
    if (!toggleFormState) return;
    const model = this.validate(state, true);
    toggleFormState(model !== null);
  }
  render() {
    const { className, delphiNrOptions } = this.props;
    const { delphiNrs, error } = this.state;
    const Options = delphiNrOptions.map(item => ({ label: item, value: item }));
    console.log('DelphiNrFilter.render()', this.state, Options);
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.delphiNrs && 'error-group'}`}>
            <Label htmlFor="delphiNrs">{`DELPHI-Auftragsnummer`}</Label>
            <MultiSelect
              id="delphiNrs"
              // className="w3-block w3-border"
              value={delphiNrs}
              placeholder="Bitte auswählen"
              options={Options}
              onChange={(event) => this.setDelphiNrs(event.value)}
              maxSelectedLabels={4}
              selectedItemsLabel={`{0} ausgewählte Delphi-Nr`}
              showClear
            />
          </div>
        </div>
      </div>
    );
  }
}
