import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
	PageTitle,
	createSortByAttr,
	toUIDate,
	PRODUCT_ORDER_STATUS_LABELS,
	PRODUCT_ORDER_STATUS_IDS,
	scrollToTop,
	TableEmptyMessage,
} from "../../../../components/Utils";
import CardOrderMenu from "../../components/cards/CardOrderMenu";

import { connect } from "react-redux";
import {
	setSelectedCardOrders,
	lockCardOrders,
	exportCardOrders,
	closeCardOrder,
	deleteCardOrder,
} from "../../actions/card";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import {
	ViewIconButton,
	EditIconButton,
	DeleteIconButton,
} from "../../../../components/Buttons";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";

const mapStateToProps = (state) => {
	return {
    isLoading: state.task.loading,
		cardOrders: state.task.cardOrders,
		selection: state.task.selectedCardOrders,
	};
};
const mapDispatchToProps = (dispatch) => ({
	setSelection: (...args) => dispatch(setSelectedCardOrders(...args)),
	lockOrders: (...args) => dispatch(lockCardOrders(...args)),
	exportOrders: (...args) => dispatch(exportCardOrders(...args)),
	closeOrder: (...args) => dispatch(closeCardOrder(...args)),
	deleteOrder: (...args) => dispatch(deleteCardOrder(...args)),
});

class CardOrderTaskOverview extends React.Component {
	static propTypes = {
		className: PropTypes.string,
    isLoading: PropTypes.bool,
		cardOrders: PropTypes.array,
		selection: PropTypes.array,
		lockOrders: PropTypes.func.isRequired,
		exportOrders: PropTypes.func.isRequired,
		closeOrder: PropTypes.func.isRequired,
		deleteOrder: PropTypes.func.isRequired,
		rows: PropTypes.number,
		namePrefix: PropTypes.string,
	};
	static defaultProps = {
		className: "",
		namePrefix: "",
		rows: 10,
	};
	constructor(props) {
		super(props);
		this.state = {
			first: null,
		};
	}
  componentDidMount() {
    scrollToTop();
  }
	onShowDetails = (cardOrder) => {
		console.log("CardOrderList.onShowDetails() ...", cardOrder);
		const { history } = this.props;
		history.replace(`/aufgaben/karten/uebersicht/${cardOrder.id}`);
	};
	onDelete = (cardOrder) => {
		console.log(`CardOrderList.onDelete()`, cardOrder);
		const { deleteOrder } = this.props;
		this.dialog.show(
			true,
			{
				title: `Karten/Flyer-Bestellung löschen`,
				text: `Wollen Sie die Bestellung ${cardOrder.orderNumber} unwiderruflich löschen?`,
			},
			() => deleteOrder({ cardOrder })
		);
	};
	render() {
		console.log(
			"CardOrderList.render() selectedCardOrders:",
			this.props.selection
		);
		const {
			className,
			isLoading,
			rows,
			cardOrders,
			namePrefix,
			selection,
			setSelection,
			lockOrders,
			exportOrders,
		} = this.props;
		const taskItems = cardOrders
			.map((item, index) => ({
				...item,
				index,
				orderDate: item.order_date
					? toUIDate(item.order_date, "YYYY-MM-DDTHH:mm:ss:SSSZ")
					: "",
				createdDate: toUIDate(item.created_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
				status: item.status || PRODUCT_ORDER_STATUS_LABELS[item.status_id],
			}))
			.sort(createSortByAttr("orderNumber", true));
		const actionTemplate = (rowData) => {
			const isEditDisabled =
				rowData.status_id !== PRODUCT_ORDER_STATUS_IDS.PROCESSING;
			const EditComponent = isEditDisabled ? ViewIconButton : EditIconButton;
			return (
				<div>
					<div className="w3-cell w3-cell-top">
						<EditComponent
							id={`${namePrefix}-editBtn${rowData.index || rowData.name || ""}`}
							onClick={() => this.onShowDetails(rowData)}
						/>
					</div>
					<div className="w3-cell w3-cell-top">
						<DeleteIconButton
							id={`${namePrefix}-deleteBtn${
								rowData.index || rowData.name || ""
							}`}
							onClick={() => this.onDelete(rowData)}
							disabled
						/>
					</div>
				</div>
			);
		};
		return (
			<div>
				<PageTitle>
					<CardOrderMenu
						className="w3-right"
						selectedCardOrders={selection}
						lockOrders={lockOrders}
						exportOrders={exportOrders}
						items={cardOrders}
					/>
					<h3 className="no-margin pad-btm">{"Karten/Flyer-Bestellungen"}</h3>
				</PageTitle>
				<DataTable
					value={taskItems}
					paginator={taskItems.length > rows}
					rows={rows}
					first={this.state.first}
          onPage={(event) => {this.setState({first: event.first}); scrollToTop();}}
					selection={selection}
					onSelectionChange={(event) => setSelection(event.value)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Kartenbestellungen"
              itemName="Bestellung"
              itemNamePlural="Kartenbestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Kartenbestellungen"/>)}
				>
					<Column
						id="icheck"
						selectionMode="multiple"
						style={{ width: "3em" }}
					/>
					<Column
						field="orderNumber"
						header="Bestellungsnummer"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
					/>
					<Column
						field="orderDate"
						header="Bestellungsdatum"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
						style={{ textAlign: "center", width: "12em" }}
					/>
					<Column
						field="companyName"
						header="Firma"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
						style={{ width: "18em" }}
					/>
					{/* <Column
            field="createdDate"
            header="Abgeben am"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          /> */}
					<Column
						field="requester"
						header="Anforderer"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
						style={{ width: "14em" }}
					/>
					<Column
						field="status"
						header="Status"
						sortable={taskItems.length > 1}
						filter={taskItems.length > rows}
						filterMatchMode="contains"
						style={{ textAlign: "center-", width: "9em" }}
					/>
					<Column
						body={actionTemplate}
						style={{ textAlign: "center", width: "7.8em" }}
					/>
				</DataTable>
				<ConfirmationDialog ref={(ref) => (this.dialog = ref)} />
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CardOrderTaskOverview);
