import classnames from "classnames";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import NavEntry from "./components/NavEntry";
import AppLinks from './utils/appLinks';

import { buildSalesTree as buildSTree } from "./utils/salesTree";
import { buildOrganisationTree as buildOrgTree } from './utils/organisationTree';
import { UserTrainingLink } from "../Training";
import { isArchivePage } from "../Static/components/Utils";
import { sortByOptionalPosition } from "../../components/Utils";

export const buildSalesTree = buildSTree;
export const buildOrganisationTree = buildOrgTree;

export function buildSubMenu(subMenu, onClickEvents = {}, flags) {
	if (!subMenu) return null;
	const links = subMenu.links.map((link, index) => {
		const {
			label,
			icon,
			subMenu,
			subLinks,
			onClick,
			isVisibleForFlag,
			component,
			...props
		} = link;
		if (isVisibleForFlag !== undefined && flags && !flags[isVisibleForFlag]) {
			return null;
		}
		// console.log('buildSubMenu() props', props);
		const subLinkList = buildSubMenu(subMenu);
		if (subLinkList) {
			return (
				<span key={index} className={`sm-holder`}>
					<Link className="sub-nav" {...props} onClick={onClickEvents[onClick]}>
						<span>{label || icon}</span>
					</Link>
					{subLinkList}
				</span>
			);
		}
		return (
			<Link
				key={index}
				className="sub-nav"
				{...props}
				onClick={onClickEvents[onClick]}
			>
				<span>{label || icon}</span>
			</Link>
		);
	});
	return (
		<div className={subMenu.className}>
			<div className="container">
				<div className="sub-menu">{links}</div>
			</div>
		</div>
	);
}

export const buildNav = (className, links, showTrainingLink) => {
	return ({ isVisible, onClickEvents = {}, counter, flags }) => {
		if (!isVisible) return null;
		const markup = links
			.map((link) => {
				return { ...link, className: "nav", activeClassName: "active" };
			})
			.map((link, index) => {
				const {
					label,
					comments,
					icon,
					subMenu,
					subLinks,
					onClick,
					render,
					isVisibleForFlag,
					as: NavComponent = NavEntry,
					...props
				} = link;
				// (label === "News") && console.log("News Links:", link);
				if (
					isVisibleForFlag !== undefined &&
					flags &&
					!flags[isVisibleForFlag]
				) {
					return null;
				}
				const subLinkList = buildSubMenu(subMenu, onClickEvents, flags);
				(label === "News") && console.log("News subLinkList:", subLinkList);

				let linkContent;
				if (subLinkList) {
					linkContent = label || icon;
				} else {
					linkContent = render ? render(counter) : label || icon;
				}

				const linkElement = (
					<NavLink
						className={classnames({
							"sub-nav": subLinkList,
						})}
						{...props}
						onClick={onClickEvents[onClick]}
					>
						{linkContent}
					</NavLink>
				);

				return (
					<NavComponent
						key={index}
						className={classnames("nav-entry", {
							"sm-holder": subLinkList,
						})}
						subLinkList={subLinkList}
						link={linkElement}
					/>
				);
			});
		const trainingLink = showTrainingLink && (
			<NavEntry link={<UserTrainingLink />} />
		);
		return (
			<div className={classnames(className, "nav-entry-wrapper")}>
				{markup}
				{trainingLink}
			</div>
		);
	};
};


export function buildMap(tree) {
  let map = {};
  // map node
  map[tree.url] = tree.label;
  if (tree.data && tree.data.noPage) {
    map[`${tree.url}#NOPAGE`] = true;
  }
  // map children 
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach(child => {
      const childMap = buildMap(child);
      map = { ...map, ...childMap };
    });
  }
  return map;
}

export function buildMapLinks(tree, level = 0) {
	const { children, ...rest } = tree;
	const link = { ...rest, to: tree.to || tree.url };
	// build sublinks
	if (children && children.length > 0) {
		link.subMenu = { className: `nav-level-${level + 1}`, links: [] };
		children.forEach((child) => {
			link.subMenu.links.push(buildMapLinks(child, level + 1));
		});
		if (link.type !== undefined) {
			link.subLinks = JSON.parse(JSON.stringify(link.subMenu.links));
		}
	}
	return link;
}

const insertIntoTree = (tree, item, tokens, index) => {
	// console.log('insertIntoTree()', tree.url, item.url);
	if (!tree.children) {
		tree.children = [];
	}
	// let child = tree.children.find((child) => child.name === tokens[index]);
	let child = tree.children.find((child) => (
		child.name === tokens[index] && item.url.indexOf(child.url) === 0
	));
	if (child) {
		insertIntoTree(child, item, tokens, index + 1);
	} else {
		tree.children.push({
			...item,
			to: item.url,
			name: tokens[index],
		});
	}
};

export const convertListToTree = (list) => {
	let tree = null;
	list.forEach((item) => {
		const tokens = item.url.split("/");
		if (!tree) {
			// root node
			tree = item;
			tree.to = item.url;
			tree.name = tokens[1];
			tree.children = [];
		} else {
			insertIntoTree(tree, item, tokens, 2);
		}
		// console.log('convertListToTree() link:', item, 'tree:', JSON.parse(JSON.stringify(tree)));
	});
	return tree;
};

//--------------------//
// AppLinks
//--------------------//

// sort links and put the archive page at the last position
const sortLinksContainingArchive = (links) => {
	const linksWithoutArchive = links.filter(item => (!item.data || !isArchivePage(item.data.type)));
  const archiveLink = links.find(item => item.data && isArchivePage(item.data.type));
	const sortedLinks = sortByOptionalPosition(linksWithoutArchive, true);
  if (archiveLink) {
    sortedLinks.push(archiveLink);
  }
	return sortedLinks;
};

const buildAppLinks = (technikTree, salesTree, organisationTree) => {
	const links = [ ...AppLinks ];
	// console.log("TechnikTree:", technikTree);
	technikTree.children = sortLinksContainingArchive(technikTree.children);
	links[1] = buildMapLinks(technikTree);
	links[2] = buildMapLinks(salesTree);
	links[4] = buildMapLinks(organisationTree);
	return links;
};
export const AppNavFactory = (technikTree, salesTree, organisationTree) => {
	const links = buildAppLinks(technikTree, salesTree, organisationTree);
	return buildNav("app-nav", links, true);
};
