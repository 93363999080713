import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText, Label } from "../../../../components/Inputs";
import {
	SimpleAddress,
	toDBDate,
	notifyError,
} from "../../../../components/Utils";
import ProductImageOverlayPanel from "../../../Product/components/ProductImageOverlayPanel";
import { createProductModel } from "../../../Product/components";
import ProductAmount from "../../../Product/components/ProductAmount";

export default class KeyOrderTaskForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.shape({
			keys: PropTypes.array,
			companyName: PropTypes.string,
			person: PropTypes.string,
			description: PropTypes.string,
			address: PropTypes.object,
		}),
		readOnly: PropTypes.bool,
	};
	static defaultProps = {
		className: "",
		model: {
			keys: [],
			address: {},
			companyName: "",
			person: "",
			description: "",
		},
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props, true);
	}
	componentWillReceiveProps(props) {
		this.setState(this.getState(props));
	}
	getState = (props, initializing) => {
		const model = props.model || {};
		const { keys } = props;
		// console.log('KeyOrderTaskForm.render()', this.props.model, this.state);
		const orderItems = model.items || [];
		const items = [];
		keys.forEach((key, index) => {
			let data = createProductModel(key, index);
			const found = orderItems.find((item) => item.product_id === key.id);
			data = found ? { ...data, ...found } : { ...data, approved: null };
			if (initializing && data.approved === null) {
				data.approved = data.amount;
			}
			items.push(data);
		});
		items.sort((a, b) => {
			if (a.approved && !b.approved) return -1;
			if (!a.approved && b.approved) return 1;
			return 0;
		});
		return {
			orderNumber: model.orderNumber || "",
			orderDate: model.orderDate || "",
			items,
			error: {
				orderNumber: model.orderNumber === "",
				orderDate: model.orderDate === "",
			},
		};
	};
	validate = (saveOnly) => {
		const originalModel = this.props.model || {};
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		// order number
		if (!saveOnly && model.orderNumber === "") {
			error.orderNumber = true;
			isValid = false;
		}
		// order date
		const orderDate = moment(model.orderDate);
		if (!saveOnly && !orderDate.isValid()) {
			error.orderDate = true;
			isValid = false;
		}
		const items = [];
		this.props.keys.forEach((key, index) => {
			const item = this[`item${index}`] ? this[`item${index}`].getValue() : "";
			if (item) {
				(item.approved > 0 || item.order_id) && items.push(item);
			} else {
				isValid = false;
			}
		});
		this.setState({ error });
		// if (items.length === 0) {
		//   isValid = false;
		//   notifyError({
		//     summary: `Schließmittelbestellung freigeben`,
		//     detail: `Sie müssen eine Menge (ungleich 0) für mindestens 1 Schließmittel eingeben.`
		//   });
		// }
		if (!isValid) {
			return null;
		}
		const newModel = { ...originalModel };
		newModel.orderNumber = model.orderNumber;
		newModel.orderDate = toDBDate(model.orderDate);
		newModel.items = items; /* items.map((item) => {
      const origItem = originalModel.items.find(elem => elem.product_id === item.product_id);
      let data = { ...item };
      if (origItem) {
        data = { ...data, ...origItem };
      }
      data.approved = item.amount;
      return data;
    }); */
		console.log(
			">> Saving \n old:",
			originalModel.items,
			"\n new:",
			newModel.items
		);
		return newModel;
	};
	getModel = (saveOnly) => {
		const model = this.validate(saveOnly);
		return model;
	};
	reset = () => {
		this.setState({
			orderNumber: "",
			orderDate: "",
			items: [],
			error: {
				orderNumber: null,
				orderDate: null,
			},
		});
	};
	onChange = (index, item) => {
		console.log("KeyOrderTaskForm.onChange()", index, item);
		const { items } = this.state;
		const itemIndex = items.findIndex((item) => item.index === index);
		items[itemIndex] = item;
		this.setState(items);
	};
	render() {
		const { className, model, readOnly } = this.props;
		const { companyName, person, address, description } = model || {};
		const { orderNumber, orderDate, items, error } = this.state;
		console.log("KeyOrderTaskForm.render() items", items);
		const amountColProps = {
			field: "approved",
			header: "Menge",
			style: { width: "6em" },
		};
		if (!readOnly) {
			amountColProps.body = (rowData) => {
				const id = `amt${rowData.index}`;
				return (
					<ProductAmount
						ref={(ref) => (this[`item${rowData.index}`] = ref)}
						id={id}
						model={rowData}
						onChange={(item) => this.onChange(rowData.index, item)}
						minAmount={0}
						isTask
					/>
				);
			};
		}
		return (
			<div className={className}>
				<div className="w3-border no-border-btm pad-big">
					<div className={`w3-row neg-margin-big-lr`}>
						<div className={`w3-col s4 pad-big-lr`}>
							<div className={` ${error.orderNumber && "error-group"}`}>
								<Label htmlFor="orderNumber">{`Bestellungsnummer`}</Label>
								<InputText
									id="orderNumber"
									value={orderNumber}
									onChange={(event) =>
										this.setState({
											orderNumber: event.target.value,
											error: {
												...error,
												orderNumber: event.target.value === "",
											},
										})
									}
									disabled={readOnly}
								/>
							</div>
							<div className={`pad-top ${error.orderDate && "error-group"}`}>
								<Label htmlFor="orderDate">{`Bestellungsdatum`}</Label>
								<Calendar
									id="orderDate"
									className="w3-block w3-border"
									value={orderDate}
									readOnlyInput={true}
									dateFormat="dd.mm.yy"
									maxDate={new Date()}
									onChange={(event) =>
										this.setState({
											orderDate: event.value,
											error: {
												...error,
												orderDate: !moment(event.value).isValid(),
											},
										})
									}
									disabled={readOnly}
								/>
							</div>
						</div>
						<div className="w3-col s8 pad-big-lr">
							<div className="w3-row">
								<div className="w3-col s6 pad-rht">
									<h4 className="no-margin pad-btm w3-light-grey-">{`Lieferanschrift`}</h4>
									<div className="pad-top-">
										<div className="pad-sm-top-">{companyName}</div>
										<div className="pad-sm-top">{person}</div>
										<SimpleAddress address={address} />
									</div>
								</div>
								<div className="w3-col s6 pad-lft">
									<h4 className="no-margin pad-btm w3-light-grey-">{`Bestellvermerk`}</h4>
									<div className="">{description}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w3-border-rht">
					<DataTable value={items} scrollable={true} scrollHeight="210px">
						<Column
							field="productNumber"
							header="VKDG-Bestell-Nr."
							sortable
							filter
							style={{ textAlign: "center", width: "12em" }}
						/>
						<Column
							field="productName"
							header="VKDG-Bezeichnung"
							body={(item) => <ProductImageOverlayPanel model={item} />}
							sortable
							filter
							style={{ width: "18em" }}
						/>
						<Column
							field="productComments"
							header="Zusatzbemerkung"
							sortable
							filter
						/>
						<Column {...amountColProps} />
					</DataTable>
				</div>
			</div>
		);
	}
}
