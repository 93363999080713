import React from 'react';
// import PropTypes from 'prop-types';
import { STATUS_IDS } from '../../../components/Utils';
import { DeviceFormMenu } from '../../../components/Buttons';

export default class UserDeviceFormMenu extends React.Component {
  constructor(props) {
    super(props);
    const userDevice = props.userDevice || {};
    this.state = {
      isMacAddressSet: (userDevice.macAddress && userDevice.macAddress !== ''),
      isMacAddressChanged: false
    };
  }
  onMacAddressChanged = (value) => {
    const isMacAddressSet = (value !== '');
    const oldUserDevice = this.props.userDevice || {};
    const isOldMacAddressSet = oldUserDevice.macAddress && oldUserDevice.macAddress !== '';
    this.setState({
      isMacAddressSet,
      isMacAddressChanged: (isMacAddressSet && value !== oldUserDevice.macAddress && isOldMacAddressSet)
    });
  }
  render() {
    const { userDevice, ...rest } = this.props;
    const { isMacAddressSet, isMacAddressChanged } = this.state;
    if (!userDevice) return null;
    // console.log('UserDeviceFormMenu.render()', this.state, userDevice.macAddress);

    const isSaveable = 
      (userDevice.status_id === 0 || userDevice.status_id === STATUS_IDS.FINISHED)
      && isMacAddressSet
      && (!userDevice.account || userDevice.account === '');
    const isUpdateable =
      userDevice.status_id === STATUS_IDS.FINISHED
      && isMacAddressSet && isMacAddressChanged
      && (userDevice.account && userDevice.account !== '');
    const isChangeable = 
      (userDevice.status_id === 0 || userDevice.status_id === STATUS_IDS.FINISHED);
    const isDeleteable =
      userDevice.status_id === STATUS_IDS.FINISHED
      && isMacAddressSet
      && (userDevice.account && userDevice.account !== '');

    return (
      <DeviceFormMenu
        {...rest}
        saveHidden={!isSaveable}
        updateHidden={!isUpdateable}
        changeHidden={!isChangeable}
        deleteHidden={!isDeleteable}
      />
    );
  }
}
