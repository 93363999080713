import React from "react";
import PropTypes from "prop-types";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import RoleForm from "./RoleForm";
import {
  isContractor as isCompanyContractor,
  validateTelNumber,
  createSortByLabel,
} from "../../../../components/Utils";

const findAttrByAttr = (list, value, listAttr = "id", retAttr = "name") => {
  let found = list.find((item) => item[listAttr] === value);
  if (found) {
    found = found[retAttr];
  }
  return found;
};

export default class CompanyDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    namePrefix: PropTypes.string,
    model: PropTypes.object,
    workerKdl: PropTypes.number,
    getWorkerCompanies: PropTypes.func.isRequired,
    isMain: PropTypes.bool,
    company: PropTypes.object,
    companyList: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    roleList: PropTypes.array,
    extendedRoleList: PropTypes.array,
    isAdmin: PropTypes.bool,
    isWorkerPlus: PropTypes.bool,
    userExists: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    namePrefix: "",
    model: {
      id: 0,
      contractGiver: "",
      contractGiverId: 0,
      role: "",
      telephoneNumber: "",
      extendedRoles: [],
    },
    companyList: [],
    kdlCompanyList: [],
    roleList: [],
    extendedRoleList: [],
  };
  constructor(props) {
    console.log("CompanyDataForm.constructor()", props);
    super(props);
    this.state = this.getState(props);
  }
  componentDidMount() {
    const { id, contractGiverId, contractGiver } = this.state;
    if (contractGiver !== "" && contractGiverId === 0) {
      const found = this.props.kdlCompanyList.find(
        (item) => item.name === contractGiver
      );
      if (found) {
        this.setContractGiverId(found.id);
      }
    } else if (id !== 0) {
      this.setCompany(id);
    }
  }
  getState = (props) => {
    const model = props.model || {};
    let id = model.id || "";
    let contractGiver = model.contractGiver || "";
    let contractGiverId = 0;
    if (!props.isAdmin && props.userCompany && contractGiver === "") {
      contractGiver = props.userCompany.name;
      contractGiverId = props.userCompany.id;
    } else if (props.workerKdl) {
      contractGiverId = props.workerKdl;
      const found = props.kdlCompanyList.find(
        (item) => item.id === props.workerKdl
      );
      if (found) {
        contractGiver = found.name;
      }
    }
    return {
      id,
      contractGiver,
      contractGiverId,
      role: model.role || "",
      telephoneNumber: model.telephoneNumber || "",
      isContractor: false,
      error: {
        id: null,
        role: null,
        telephoneNumber: null,
      },
    };
  };
  validate = (noValidate) => {
    const { isMain, companyList, kdlCompanyList } = this.props;
    const { error, isContractor, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (
        error[key] !== undefined &&
        model[key] === "" &&
        (isMain || key !== "role")
      ) {
        error[key] = true;
        isValid = false;
      }
    });
    // telephone number
    if (!validateTelNumber(model.telephoneNumber)) {
      error.telephoneNumber = "ungültige Telefonnummer";
      isValid = false;
    }
    !noValidate && this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    // extended roles
    model.extendedRoles = this.extendedRoles.getModel();
    // model.extendedRoles = isContractor ? [] :  this.extendedRoles.getModel();

    // original id and contract giver
    const oldModel = this.props.model || {};
    model.oldName = oldModel.name;
    model.oldId = oldModel.id;
    model.oldContractGiver = oldModel.contractGiver;
    model.oldContractGiverId =
      oldModel.contractGiverId ||
      findAttrByAttr(kdlCompanyList, oldModel.contractGiver, "name", "id");
    // // set company name
    // // const found = companyList.find((item) => item.id === model.id);
    // // if (found) {
    // //   model.name = found.name;
    // // }
    // set company name
    model.name = findAttrByAttr(companyList, model.id, "id", "name");
    // console.log("<< CompanyData:", model);
    return model;
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  setContractGiverId = (value) => {
    if (value && value !== 0) {
      this.props.getWorkerCompanies(value);
    }
  };
  setCompany = (value) => {
    const state = {
      id: value,
      error: {
        ...this.state.error,
        id: null,
      },
    };
    const company = this.props.companyList.find((item) => item.id === value);
    if (company && company.type_id > 3 /* isCompanyContractor(company) */) {
      if (this.props.model.role === "") {
        state.role = "Mitarbeiter";
      }
      state.isContractor = true;
      // this.extendedRoles && this.extendedRoles.setForContractor(true);
    } else {
      state.isContractor = false;
    }
    this.setState(state);
  };
  render() {
    const {
      className,
      companyList,
      kdlCompanyList,
      roleList,
      extendedRoleList,
      isMain,
      isAdmin,
      userExists,
      model,
      namePrefix,
    } = this.props;
    const extendedRoles =
      model && model.extendedRoles ? model.extendedRoles : [];
    const {
      id,
      contractGiverId,
      contractGiver,
      role,
      telephoneNumber,
      isContractor,
      error,
    } = this.state;
    console.log("CompanyDataForm.render()", this.props, this.state);
    const KDLCompanyOptions = kdlCompanyList
      .map((company) => ({
        label: company.name,
        value: company.id,
      }))
      .sort(createSortByLabel());
    // const CompanyOptions = kdlCompanyList.map(company => (
    const CompanyOptions = companyList
      .map((company) => ({
        label: company.name,
        value: company.id,
      }))
      .sort(createSortByLabel());

    // contract giver (KDL)
    const contractGiverMarkup = isAdmin ? (
      <Dropdown
        id={`${namePrefix}contractGiver`}
        className="w3-block w3-border"
        value={contractGiverId}
        placeholder="Bitte auswählen"
        options={KDLCompanyOptions}
        onChange={(event) => this.setContractGiverId(event.value)}
        showClear
      />
    ) : (
      <InputText
        id={`${namePrefix}contractGiver`}
        className="w3-block w3-border"
        value={contractGiver}
        disabled
      />
    );

    // company (Beschäftigungsfirma)
    const companyMarkup =
      userExists && !isAdmin ? (
        <InputText
          id={`${namePrefix}name`}
          className="w3-block w3-border"
          value={model.name}
          disabled
        />
      ) : (
        <Dropdown
          id={`${namePrefix}name`}
          className="w3-block w3-border"
          value={id}
          placeholder="Bitte auswählen"
          options={CompanyOptions}
          onChange={(event) => this.setCompany(event.value)}
          showClear
        />
      );

    // Role of employee in the company (Beschäftigungsfirma)
    const RoleOptions = roleList
      .filter((item) => {
        if (!isAdmin) {
          return isContractor
            ? item.label.toLowerCase() === "mitarbeiter"
            : item.id >= 2 && item.id <= 4;
        }
        return isContractor ? item.id >= 4 : true;
      })
      .map((item) => ({ label: item.label, value: item.name }));
    console.log("RoleOptions:", RoleOptions);
    const roleMarkup =
      !isAdmin && role.toLowerCase() === "kein zugang" ? (
        <InputText
          id={`${namePrefix}role`}
          className="w3-block w3-border"
          value={role}
          disabled
        />
      ) : (
        <Dropdown
          id={`${namePrefix}role`}
          className="w3-block w3-border"
          value={role}
          placeholder="Bitte auswählen"
          options={RoleOptions}
          onChange={(event) => this.setState({ role: event.value })}
          showClear
        />
      );

    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.contractGiver ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}contractGiver`}>
              {`KDL/DL`}
              <span>*</span>
            </Label>
            {contractGiverMarkup}
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.id ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}name`}>
              {`Beschäftigungsfirma`}
              <span>*</span>
            </Label>
            {companyMarkup}
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.role && isMain ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}role`}>
              {`Position`}
              <span>*</span>
            </Label>
            {roleMarkup}
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.telephoneNumber ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}telephoneNumber`}>
              {`Telefon (dienstlich)`}
              <span>*</span>
            </Label>
            <InputText
              id={`${namePrefix}telephoneNumber`}
              className="w3-block w3-border"
              value={telephoneNumber}
              onChange={(event) =>
                this.setState({
                  telephoneNumber: event.target.value,
                  error: {
                    ...this.state.error,
                    telephoneNumber: null,
                  },
                })
              }
            />
            {error.telephoneNumber && (
              <div className="pad-sm-top">{error.telephoneNumber}</div>
            )}
          </div>
        </div>
        <RoleForm
          ref={(ref) => (this.extendedRoles = ref)}
          className="pad-top"
          model={extendedRoles}
          roleList={extendedRoleList}
          isAdmin={isAdmin}
          isContractor={isContractor}
        />
      </div>
    );
  }
}
