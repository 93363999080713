import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '../../../../components/Inputs';
import { DeleteIconButton, UpIconButton, DownIconButton } from '../../../../components/Buttons';

export default class DownloadListItemForm extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onRemove: PropTypes.func
  }
  static defaultProps = {
    className: '',
    item: {}
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title || '',
      error: {
        title: null
      }
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ title: props.title });
  }
  validate = (noValidate) => {
    const { error, title } = this.state;
    let isValid = true;
    if (!title || title === '') {
      error.title = true;
      isValid = false;
    }
    const model = { title };
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    // console.log('BasicPageContentForm.render()', this.state);
    const { className, text, onMoveUp, onMoveDown, onRemove } = this.props
    const { title, error } = this.state;
    return (
      <div className={`${className}`}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`${error.title ? 'error-group' : ''}`}>
              <InputText
                className="w3-block w3-border"
                value={title}
                onChange={(event) => this.setState({title: event.target.value})}
              />
              {error.title && (<span className="w3-block w3-red">{error.title}</span>)}
            </div>
          </div>
          <div className="w3-col s3 pad">{text}</div>
          <div className="w3-col s3 pad-lr clearfix alg-right-">
            <DeleteIconButton
              className="w3-border w3-right"
              onClick={onRemove}
            />
            <UpIconButton
              className="w3-border"
              onClick={onMoveUp}
              disabled={onMoveUp === null}
            />
            <DownIconButton
              className="w3-border"
              onClick={onMoveDown}
              disabled={onMoveDown === null}
            />
          </div>
        </div>
      </div>
    );
  }
}
