import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
// import { Button } from 'primereact/button';
import EditImage from './media/edit.svg';
import ViewImage from './media/viewed.svg';
import UnviewedImage from './media/unviewed.svg';
import SearchImage from './media/search.svg';
import SaveImage from './media/save.svg';
import DeleteImage from './media/delete.svg';
import CancelImage from './media/cancel.svg';
import MinusImage from './media/minus.svg';
import PlusImage from './media/plus.svg';
import ChevronUpImage from './media/chevron-up.svg';
import ChevronDownImage from './media/chevron-down.svg';
import PDFFileImage from './media/document-pdf.svg';
import ExcelFileImage from './media/document-xls.svg';
export { default as CheckCircleImage } from './media/check-circle.svg';

const CopyImage = ({ style }) => (
  <span className="display-inline-block pos-rel" style={style}>
    <i className="pi pi-copy" style={{fontSize:'20px',position:'absolute',top:'2px',left:'2px'}}/>
  </span>
);

const FileImage = ({ style }) => (
  <span className="display-inline-block pos-rel" style={style}>
    <i className="pi pi-file" style={{fontSize:'20px',position:'absolute',top:'2px',left:'2px'}}/>
  </span>
);


export function NavButton({ onClick }) {
  let history = useHistory();
  function handleClick() {
    onClick && onClick(history);
  }
  return (
    <SecondaryButton className={className} onClick={handleClick}/>
  );
};

export const ImageStyle = {
  width: '24px',
  height: '24px'
};
export const IconStyle = {
  width: '24px',
  height: '16px'
};

export const createIconBtn = (Image, props, alt = 'icon', isNotImage) => {
  const { className, disabled, onClick, title, tabIndex, ...rest } = props;
  // alt==='bearbeiten' && console.log('Props', props);
  const tClassName = className || '';
  const padding = tClassName.indexOf('pad') >= 0 ? '' : 'pad-icon';
  const body = isNotImage ? (
    <Image style={IconStyle}/>
  ) : (
    <img src={Image} style={ImageStyle} alt={alt} />
  );
  const onEnter = (event) => {
    if (event.keyCode === 13) {
      onClick && onClick();
    }
  };
  return (
    <span {...rest}
      className={`
        display-inline-block ${padding} w3-round
        w3-white w3-hover-light-grey w3-border-hover
        ${tClassName} ${disabled ? 'w3-opacity' : 'clickable'}
      `}
      onClick={disabled ? null : onClick}
      onKeyUp={disabled ? null : onEnter}
      title={disabled ? null : (title || alt)}
      tabIndex={tabIndex !== undefined ? tabIndex : 0}
    >
      {body}
    </span>
  );
};

export const EditIconButton = (props) => createIconBtn(EditImage, props, 'bearbeiten');
export const ViewIconButton = (props) => createIconBtn(ViewImage, props, 'anzeigen');
export const SaveIconButton = (props) => createIconBtn(SaveImage, props, 'speichern');
export const DeleteIconButton = (props) => createIconBtn(DeleteImage, props, 'löschen');
export const CancelIconButton = (props) => createIconBtn(CancelImage, props, 'abbrechen');
export const MinusIconButton = (props) => createIconBtn(MinusImage, props, 'minus');
export const PlusIconButton = (props) => createIconBtn(PlusImage, props, 'plus');
export const UpIconButton = (props) => createIconBtn(ChevronUpImage, props, 'oben');
export const DownIconButton = (props) => createIconBtn(ChevronDownImage, props, 'unten');
export const SearchIconButton = (props) => createIconBtn(SearchImage, props, 'suche');
export const ActivateIconButton = (props) => createIconBtn(ViewImage, props, 'aktivieren');
export const DeactivateIconButton = (props) => createIconBtn(UnviewedImage, props, 'deaktivieren');
export const PDFFileIconButton = (props) => createIconBtn(PDFFileImage, props, 'öffnen');
export const ExcelFileIconButton = (props) => createIconBtn(ExcelFileImage, props, 'öffnen');
export const CopyIconButton = (props) => createIconBtn(CopyImage, props, 'kopieren', true);
export const FileIconButton = (props) => createIconBtn(FileImage, props, 'herunterladen', true);


export const PlusIcon = ({ className, style = {} }) => (
  <span className={className} style={style}>
    <img src={PlusImage} style={ImageStyle} alt={''} />
  </span>
);
export const MinusIcon = ({ className, style = {} }) => (
  <span className={className} style={style}>
    <img src={MinusImage} style={ImageStyle} alt={''} />
  </span>
);


export function createIconNavButton(image, props = {}) {
  const { className, onClick, title, disabled, ...rest } = props;
  let history = useHistory();
  function handleClick() {
    onClick && onClick(history);
  }
  const tClassName = className || '';
  const padding = tClassName.indexOf('pad') >= 0 ? '' : 'pad';
  return (
    <span {...rest}
      onClick={disabled ? null : handleClick}
      className={`
        display-inline-block w3-white ${padding} w3-border-hover w3-round
        ${tClassName} ${disabled ? 'w3-opacity' : 'clickable'}
      `}
      title={disabled ? null : title}
    >
      <img src={image} style={ImageStyle} alt={title || 'image'}/>
    </span>
  );
};
export const EditIconNavButton = ({ title, ...props}) => createIconNavButton(EditImage, { title: title || 'bearbeiten', ...props });
export const ViewIconNavButton = ({ title, ...props}) => createIconNavButton(ViewImage, { title: title || 'anzeigen', ...props });
export const DeleteIconNavButton = ({ title, ...props}) => createIconNavButton(DeleteImage, { title: title || 'löschen', ...props });
// export function EditIconNavButton({ onClick, title, ...props }) {
//   let history = useHistory();
//   function handleClick() {
//     onClick && onClick(history);
//   }
//   return (
//     <span {...props}
//       onClick={handleClick}
//       className="clickable display-inline-block w3-white w3-border-hover w3-round pad"
//       title={title || 'bearbeiten'}
//     >
//       <img src={EditImage} style={ImageStyle} alt={title || 'bearbeiten'}/>
//     </span>
//   );
// };
// export function ViewIconNavButton({ onClick, title, ...props }) {
//   let history = useHistory();
//   function handleClick() {
//     onClick && onClick(history);
//   }
//   return (
//     <span {...props}
//       onClick={handleClick}
//       className="clickable display-inline-block w3-white w3-border-hover w3-round pad"
//       title={title || 'anzeigen'}
//     >
//       <img src={ViewImage} style={ImageStyle} alt={title || 'anzeigen'}/>
//     </span>
//   );
// };
// export function DeleteIconNavButton({ onClick, title, ...props }) {
//   let history = useHistory();
//   function handleClick() {
//     onClick && onClick(history);
//   }
//   return (
//     <span {...props}
//       onClick={handleClick}
//       className="clickable display-inline-block w3-white w3-border-hover w3-round pad"
//       title={title || 'anzeigen'}
//     >
//       <img src={DeleteImage} style={ImageStyle} alt={title || 'löschen'}/>
//     </span>
//   );
// };


function createBtn (className, disabled, props, isLink = false) {
  const { size, ...otherProps } = props;
  const finalClassName = `
    ${className} 
    ${disabled ? ' w3-opacity' : ''} 
    ${size}
  `;
  const isBlock = className.indexOf('block') >= 0;
  let markup = (<button {...otherProps} className={finalClassName}/>);
  if (isLink) {
    markup = (<Link {...otherProps} className={finalClassName}/>);
  }
  if (disabled) {
    markup = (
      <div className={`display${isBlock ? '' : '-inline'}-block pos-rel`}>
        {markup}<div className="overlay"/>
      </div>
    );
  }
  return markup;
}

// export const MenuButtonClassName = "w3-border-transparent w3-border w3-round w3-white w3-hover-border";
export const MenuButtonClassName = "menu btn btn-secondary";
export const MenuLinkClassName = `menu btn btn-secondary`;

export const MenuButton = ({ className, ...props }) => (
  <button {...props} className={`menu btn btn-secondary ${className}`}/>
);

export const MenuLink = ({ className, ...props }) => (
  <Link {...props} className={`menu btn btn-secondary ${className}`}/>
);

export const PrimaryButton = ({ className, disabled, ...props }) => createBtn(`btn btn-primary ${className}`, disabled, props);
export const PrimaryButtonLink = ({ className, disabled, ...props }) => createBtn(`btn btn-primary ${className}`, disabled, props, true);

export const SecondaryButton = ({ className, disabled, ...props }) => createBtn(`btn btn-secondary ${className}`, disabled, props);
export const SecondaryButtonLink = ({ className, disabled, ...props }) => createBtn(`btn btn-secondary ${className}`, disabled, props, true);

export const ButtonLink = ({ className, disabled, ...props }) => createBtn(`${className}`, disabled, props, true);


export function CancelButtonLink({ className, onCancel, label = "Abbrechen" }) {
  let history = useHistory();
  function handleClick() {
    onCancel && onCancel(history);
  }
  return (
    <SecondaryButton className={className} onClick={handleClick}>{label}</SecondaryButton>
  );
}

export const FormMenu = ({
    className,
    style = {},
    onSave,
    onCancel,
    saveDisabled = false,
    saveHidden = false,
    saveBtnName,
    saveBtnLabel = 'Speichern',
    cancelBtnName,
    cancelHidden = false,
    cancelBtnLabel = 'Abbrechen',
    disableOnClick = false
  }) => {
  // console.log('FormMenu.render() saveDisabled', saveDisabled);
  const [ saveBtnClicked, setSaveBtnClicked ] = React.useState(false);
  const onSaveBtnClicked = onSave ? React.useCallback(() => {
    if (disableOnClick) {
      setSaveBtnClicked(true);
    }
    onSave();
  }) : null;
  return (
    <div className={`${className} alg-rht`} style={style}>
      {!saveHidden && (
        <PrimaryButton
          id={saveBtnName}
          name={saveBtnName}
          className={`${saveDisabled ? 'w3-opacity' : ''} ${saveBtnClicked ? 'disabled-link' : ''}`}
          onClick={onSaveBtnClicked}
          disabled={saveDisabled}>
          {saveBtnLabel}
        </PrimaryButton>
      )}
      {!cancelHidden && (<SecondaryButton
        id={cancelBtnName}
        name={cancelBtnName}
        className="margin-lft"
        onClick={onCancel}>
        {cancelBtnLabel}
      </SecondaryButton>)}
    </div>
  );
};

export const ProductFormMenu = ({
    className,
    style = {},
    onSave,
    onSaveAndClose,
    onCancel,
    saveDisabled = false,
    saveHidden = false,
    saveBtnName,
    saveBtnLabel = 'Speichern',
    saveAndCloseDisabled = false,
    saveAndCloseHidden = false,
    saveAndCloseBtnName,
    saveAndCloseBtnLabel = 'Speichern und Abschließen',
    cancelBtnName,
    cancelHidden = false,
    cancelBtnLabel = 'Abbrechen'
  }) => {
  // console.log('FormMenu.render() saveDisabled', saveDisabled);
  return (
    <div className={`${className} alg-rht`} style={style}>
      {!saveHidden && (<PrimaryButton
        id={saveBtnName}
        name={saveBtnName}
        className={saveDisabled ? 'w3-opacity' : ''}
        onClick={saveDisabled ? null : onSave}
        disabled={saveDisabled}>
        {saveBtnLabel}
      </PrimaryButton>)}
      {!saveAndCloseHidden && (<PrimaryButton
        id={saveAndCloseBtnName}
        name={saveAndCloseBtnName}
        className={`margin-lft ${saveAndCloseDisabled ? 'w3-opacity' : ''}`}
        onClick={saveAndCloseDisabled ? null : onSaveAndClose}
        disabled={saveAndCloseDisabled}>
        {saveAndCloseBtnLabel}
      </PrimaryButton>)}
      {!cancelHidden && (<SecondaryButton
        id={cancelBtnName}
        name={cancelBtnName}
        className="margin-lft"
        onClick={onCancel}>
        {cancelBtnLabel}
      </SecondaryButton>)}
    </div>
  );
};

export class DeviceFormMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSave: PropTypes.func,
    onUpdate: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    saveDisabled: PropTypes.bool,
    saveHidden: PropTypes.bool,
    updateDisabled: PropTypes.bool, 
    updateHidden: PropTypes.bool,
    changeDisabled: PropTypes.bool,
    changeHidden: PropTypes.bool,
    deleteDisabled: PropTypes.bool,
    deleteHidden: PropTypes.bool,
    saveBtnName: PropTypes.string,
    updateBtnName: PropTypes.string,
    changeBtnName: PropTypes.string,
    deleteBtnName: PropTypes.string,
    cancelBtnName: PropTypes.string,
    saveBtnLabel: PropTypes.string,
    updateBtnLabel: PropTypes.string,
    changeBtnLabel: PropTypes.string,
    deleteBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    style: {},
    saveDisabled: false,
    saveHidden: false,
    updateDisabled: false,
    updateHidden: false,
    changeDisabled: true,
    changeHidden: true,
    deleteDisabled: true,
    deleteHidden: true,
    // saveBtnName,
    // updateBtnName,
    // changeBtnName,
    // cancelBtnName,
    saveBtnLabel: 'Account beantragen',
    updateBtnLabel: 'Account ändern',
    deleteBtnLabel: 'Account löschen',
    changeBtnLabel: 'Speichern',
    cancelBtnLabel: 'Abbrechen'
  }
  render() {
    const {
      className, style,
      onSave, onUpdate, onDelete, onChange, onCancel,
      saveDisabled, saveHidden,
      updateDisabled, updateHidden,
      deleteDisabled, deleteHidden,
      changeDisabled, changeHidden,
      saveBtnName, updateBtnName, deleteBtnName, changeBtnName, cancelBtnName,
      saveBtnLabel,updateBtnLabel,deleteBtnLabel, changeBtnLabel, cancelBtnLabel
    } = this.props;
    return (
      <div className={`${className} alg-rht`} style={style}>
        {!updateHidden && (
          <PrimaryButton
            id={updateBtnName}
            name={updateBtnName}
            className={updateDisabled ? 'w3-opacity' : ''}
            onClick={updateDisabled ? null : onUpdate}
            disabled={updateDisabled}>
            {updateBtnLabel}
          </PrimaryButton>
        )}
        {!deleteHidden && (
          <PrimaryButton
            id={deleteBtnName}
            name={deleteBtnName}
            className={`margin-lft ${deleteDisabled} ? 'w3-opacity' : ''`}
            onClick={deleteDisabled ? null : onDelete}
            disabled={deleteDisabled}>
            {deleteBtnLabel}
          </PrimaryButton>
        )}
        {!saveHidden && (
          <PrimaryButton
            id={saveBtnName}
            name={saveBtnName}
            className={`margin-lft ${saveDisabled ? 'w3-opacity' : ''}`}
            onClick={saveDisabled ? null : onSave}
            disabled={saveDisabled}>
            {saveBtnLabel}
          </PrimaryButton>
        )}
        {!changeHidden && (
          <PrimaryButton
            id={changeBtnName}
            name={changeBtnName}
            className={`margin-lft ${changeDisabled ? 'w3-opacity' : ''}`}
            onClick={changeDisabled ? null : onChange}
            disabled={changeDisabled}>
            {changeBtnLabel}
          </PrimaryButton>
        )}
        <SecondaryButton
          id={cancelBtnName}
          name={cancelBtnName}
          className="margin-lft"
          onClick={onCancel}>
          {cancelBtnLabel}
        </SecondaryButton>
      </div>
    );
  }
}
/* 
export const DeviceFormMenu = ({
    className, style = {},
    onSave, onUpdate, onChange, onCancel,
    saveDisabled = false, saveHidden = false,
    updateDisabled = false, updateHidden = false,
    changeDisabled = true, changeHidden = true,
    saveBtnName, updateBtnName, changeBtnName, cancelBtnName,
    saveBtnLabel = 'Account beantragen',
    updateBtnLabel = 'Account ändern',
    changeBtnLabel = 'Speichern',
    cancelBtnLabel = 'Abbrechen'
  }) => {
  return (
    <div className={`${className} alg-rht`} style={style}>
      {!updateHidden && (
        <PrimaryButton
          id={updateBtnName}
          name={updateBtnName}
          className={updateDisabled ? 'w3-opacity' : ''}
          onClick={onUpdate}
          disabled={updateDisabled}>
          {updateBtnLabel}
        </PrimaryButton>
      )}
      {!saveHidden && (
        <PrimaryButton
          id={saveBtnName}
          name={saveBtnName}
          className={`margin-lft ${saveDisabled ? 'w3-opacity' : ''}`}
          onClick={onSave}
          disabled={saveDisabled}>
          {saveBtnLabel}
        </PrimaryButton>
      )}
      {!changeHidden && (
        <PrimaryButton
          id={changeBtnName}
          name={changeBtnName}
          className={`margin-lft ${changeDisabled ? 'w3-opacity' : ''}`}
          onClick={onChange}
          disabled={changeDisabled}>
          {changeBtnLabel}
        </PrimaryButton>
      )}
      <SecondaryButton
        id={cancelBtnName}
        name={cancelBtnName}
        className="margin-lft"
        onClick={onCancel}>
        {cancelBtnLabel}
      </SecondaryButton>
    </div>
  );
};
 */

export class WizardButtonPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onPrevious: PropTypes.func,
    onSave: PropTypes.func,
    onNext: PropTypes.func,
    onCancel: PropTypes.func,
    disableIfAbsent: PropTypes.bool,
    previousLabel: PropTypes.string,
    saveLabel: PropTypes.string,
    nextLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    nameSuffix: PropTypes.string
  }
  static defaultProps = {
    className: '',
    style: {},
    previousLabel: 'Zurück',
    saveLabel: 'Speichern',
    nextLabel: 'Weiter',
    cancelLabel: 'Abbrechen',
    nameSuffix: ''
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => ({
    prevDisabled: !props.onPrevious,
    saveDisabled: !props.onSave,
    nextDisabled: !props.onNext,
    cancelDisabled: !props.onCancel
  })
  disableBtn = (name, flag) => {
    switch(name) {
      case 'previous': return this.setState({prevDisabled: flag});
      case 'next': return this.setState({nextDisabled: flag});
      case 'save': return this.setState({saveDisabled: flag});
      case 'cancel': return this.setState({cancelDisabled: flag});
      default: return;
    }
  }
  render() {
    const {
      className,
      style,
      onPrevious,
      onSave,
      onNext,
      onCancel,
      disableIfAbsent,
      previousLabel,
      saveLabel,
      nextLabel,
      cancelLabel,
      nameSuffix
    } = this.props;
    const { prevDisabled, saveDisabled, nextDisabled, cancelDisabled } = this.state;
    // console.log('WizardButtonPanel.render()', nextDisabled, onNext);
    const prevBtn = (prevDisabled && !disableIfAbsent) ? null : (
      <PrimaryButton
        id={`previousBtn${nameSuffix}`}
        className={`margin-rht`}
        onClick={prevDisabled ? null : onPrevious}
        disabled={prevDisabled}
      >
        {previousLabel}
      </PrimaryButton>
    );
    const saveBtn = (saveDisabled) ? null : (
      <PrimaryButton
        id={`saveBtn${nameSuffix}`}
        className={`margin-rht`}
        onClick={saveDisabled ? null : onSave}
        disabled={saveDisabled}
      >
        {saveLabel}
      </PrimaryButton>
    );
    const nextBtn = (nextDisabled && !disableIfAbsent) ? null : (
      <PrimaryButton
        id={`nextBtn${nameSuffix}`}
        className={`margin-rht`}
        onClick={nextDisabled ? null : onNext}
        disabled={nextDisabled}
      >
        {nextLabel}
      </PrimaryButton>
    );
    const cancelBtn = (cancelDisabled && !disableIfAbsent) ? null : (
      <SecondaryButton
        id={`cancelBtn${nameSuffix}`}
        className="margin-lft-"
        onClick={cancelDisabled ? null : onCancel}
        disabled={cancelDisabled}
      >
        {cancelLabel}
      </SecondaryButton>
    )
    return (
      <div className={`${className} alg-rht w3-light-grey`} style={style}>
        {prevBtn}{saveBtn}{nextBtn}{cancelBtn}
      </div>
    );
  }
}