import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import AuditForm from './AuditForm';
import { toUIDate, showFile, TableEmptyMessage } from '../../../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

export default class AuditListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    company: PropTypes.shape({}),
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  validate = () => {
    const audits = this.audits.getModel();
    if (!audits) return;
    const model = [ ...audits ];
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <Audit
          ref={(ref) => this.audits = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class Audit extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    company: PropTypes.shape({}),
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map(item => {
      return { ...item };
    });
    return model;
  }
  onSave = (model, onHide) => {
    // console.log('AuditListForm.onSanve()', model);
    if (!model) {
      return;
    }
    model.company_id = this.props.company.id;
    // console.log('>> saving audit ...', model);
    const { items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    // console.log('>> deleting audit from list', selected);
    const items = [];
    for (const index in this.state.items) {
      // console.log('>> delete: comparing index', index, 'with selected index', selected.index);
      if (index != selected.index) {
        items.push(this.state.items[index]);
      }
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, rows } = this.props;
    const userAudits = this.state.items;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    // const fileBtnTemplate = (rowData) => {
    //   let btnMarkup = null;
    //   if (rowData.path) {
    //     const id = `pathBtn${rowData.index}`;
    //     btnMarkup = (
    //       <SecondaryButton
    //         id={id}
    //         size="small"
    //         onClick={() => showFile(rowData.path)}
    //       >
    //         {`Datei`}
    //       </SecondaryButton>
    //     );
    //   }
    //   return (<div>{btnMarkup}</div>);
    // };
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => {
        let control = 'nicht fällig';
        if (item.controlNeeded) {
          if (item.isControlled && item.control_date) {
            control = `ausgeführt am ${toUIDate(item.control_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ')}`
          } else {
            control = `fällig${item.control_date ? (' am ' + toUIDate(item.control_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ')) : ''}`; 
          }
        }
        return {
          ...item,
          index,
          auditDate: toUIDate(item.audit_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
          control
        };
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Audits"
              itemName="Audit"
              itemNamePlural="Audits"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Audits" min/>)}
        > 
          <Column
            field="auditDate"
            header="Datum des Vor-Ort-Audits"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            // style={{textAlign:'center', width: '10em'}}
          />
          <Column
            field="control"
            header="Nachkontrolle"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            // style={{textAlign:'center', width: '10em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const audit = item || {
        audit_date: '',
        controlNeeded: false,
        isControlled: false,
        control_date: '',
      };
      return (
        <div>
          <AuditForm
            key={Math.random()}
            ref={(ref) => this.form = ref}
            model={audit}/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)}
            onCancel={onHide}
            saveBtnName="saveAuditBtn"/>
        </div>
      );
    };
    const getItemFormTitle = (audit) => {
      return (<h3 className="no-margin">{`Vor-Ort-Audit ${(audit && audit.product_id !== '' ? 'bearbeiten' : 'hinzufügen')}`}</h3>);
    }
    return (
      <ItemListManager
        className={className}
        title={(<h3 className="no-margin-top">Auditierung</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addWorkerAuditBtn"
        items={userAudits}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="audit"
        itemDeleteable
        hideDisabledDelete
        // isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Vor-Ort-Audit löschen"
        getDeleteText={(item) => `Wollen Sie den Vor-Ort-Audit vom ${item.auditDate} unwiderruflich löschen?`}
        onDelete={this.onDelete}
      />
    );
  }
}
