import * as Test from './test';

export const LOAD_IMAGES = 'images/load';
export const LOAD_IMAGES_SUCCESS = 'images/load/success';
export const LOAD_IMAGES_FAILURE = 'images/load/failure';

export const loadImages = ({ sliderType }) => {
  console.log(`REDUX-ACTION: loadImages(${sliderType}) ...`);
  if (Test.TESTING) {
    const images = Test.getImages(sliderType);
    return { type: LOAD_IMAGES_SUCCESS, result: images, sliderType };
  }
  return {
    types: [LOAD_IMAGES, LOAD_IMAGES_SUCCESS, LOAD_IMAGES_FAILURE],
    promise: (client) => client.get(`/images/${type}`),
    type
  };
};
