import React from 'react';
import ImageSlider from '../components/ImageSlider';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadImages } from '../actions';

const frontload = async (props) => {
  await props.loadImages({ sliderType: 'home' });
};
const mapStateToProps = (state) => {
  return {
    images: state.slider.home
  };
};
const mapDispatchToProps = (dispatch) => ({
  loadImages: (...args) => dispatch(loadImages(...args))
});

const sliderConfig = { arrows: false, autoplay: false, dots: false };

const HomeImageSlider = ({ className = '', images }) => {
  console.log('HomeImageSlider.render()', images);
  return (
    <ImageSlider
      className={`home-image-slider ${className}`}
      images={images}
      sliderConfig={sliderConfig}
    />
  );
};

// export default HomeImageSlider;
export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(HomeImageSlider)
);
