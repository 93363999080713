import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadOpenTasks } from '../actions';

const frontload = async (props) => (
  await props.getTasks()
);
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getTasks: (...args) => dispatch(loadOpenTasks(...args))
});

class AllNewTasks extends React.Component {
  static propTypes = {
    getTasks: PropTypes.func.isRequired,
    children: PropTypes.node
  }
  render() {
    return (
      <div className={`new-tasks clearfix`}>{this.props.children}</div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(AllNewTasks)
);
