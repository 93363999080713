import React from 'react';
import { connect } from 'react-redux';
import { SecondaryButton } from '../../../../components/Buttons';
import { loadLoxxessAddressCSVFile } from '../../../Modem/actions';


const mapDispatchToPropsAL = (dispatch) => ({
  getReport: (...args) => dispatch(loadLoxxessAddressCSVFile(...args)),
});
const AddressesLinkModule = ({
  className,
  getReport
}) => {
  return (
    <div className={`${className} w3-hover-light-grey`}>
      <div className="w3-cell-row">
        <div className="w3-cell w3-cell-middle bold">{`Loxxess-Adressen`}</div>
        <div className="w3-cell w3-cell-middle alg-rht" style={{width: '300px'}}>
          <SecondaryButton
            size="small"
            onClick={getReport}
          >
            {`Report herunterladen`}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};
export const AddressesLink = connect(null, mapDispatchToPropsAL)(AddressesLinkModule);

export default function DebitorReports({
  className
}) {
  return (
    <div className={`report-task-debitors ${className}`}>
      <AddressesLink className="clearfix pad neg-margin-lr"/>
    </div>
  );
};
