import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DebitorItem from './DebitorItem';
import ModelImageOverlayPanel from './ModelImageOverlay';
import { createSortByAttr } from '../../../components/Utils';

export default class DebitorOrderOverview extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    hideDebitorSubtitle: PropTypes.bool,
    debitor: PropTypes.object,
    modems: PropTypes.array,
    showApproved: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    title: 'Debitor',
    debitor: {},
    modems: []
  }
  nameTemplate = (rowData) => {
    return (<ModelImageOverlayPanel modem={rowData}/>);
  }
  render() {
    const { className, debitor, modems, title, hideDebitorSubtitle, showApproved } = this.props;
    if (!debitor || !modems) {
      return null;
    }
    const modemMap = {};
    const groupedModems = [];
    modems.forEach(item => {
      const productName = (item.productName || item.product_name).toLowerCase();
      const key = `${item.orderNumber}${productName}`;
      // console.log('>> Modem key', key, modemMap[key]);
      if (!modemMap[key]) {
        groupedModems.push(item);
        modemMap[key] = true;
      }
    });
    const modemList = groupedModems.map((item, index) => ({
      ...item,
      index: (item.index != null) ? item.index : index,
      productName: (item.productName || item.product_name),
      product_name: (item.productName || item.product_name)
    })).sort(createSortByAttr('productName'));
    console.log('DebitorOrderOverview.render() modems', modemList, 'debitor', debitor);
    const approvedTemplate = (rowData) => {
      let value = rowData.approved;
      (rowData.distributions || []).forEach(item => {
        value += item.approved;
      });
      return value;
    }
    return (
      <div className={`debitor-order-overview ${className} clearfix`}>
        <div className="w3-border pad-big-lr pad-tb w3-light-grey">
          <h3 className="no-margin">{title}</h3>
        </div>
        <div className="w3-border no-border-tb pad-big">
          {!hideDebitorSubtitle && (<h4 className="no-margin">{'Debitor'}</h4>)}
          <DebitorItem
            className="margin-sm-top"
            debitor={debitor}
            tableView
          />
        </div>
        <div className="w3-border no-border-btm pad-big-lr pad-tb">
          <h4 className="no-margin">{'Modems'}</h4>
        </div>
        <DataTable value={modemList} paginator={false}>
          <Column field="productName" header="Modem" body={this.nameTemplate}/>
          <Column field="used" header="Verbrauch" className="alg-center"/>
          <Column field="stock" header="Bestand" className="alg-center"/>
          <Column field="quantity" header="Bestellt" className="alg-center"/>
          {showApproved && (<Column field="approved" header="Freigegeben" className="alg-center" body={approvedTemplate}/>)}
        </DataTable>
      </div>
    );
  }
}
