import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { validateAreaCode } from "../../../../components/Utils";

export default class MNVObjectOwnerForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		isVertical: PropTypes.bool,
		resetState: PropTypes.bool,
		disabled: PropTypes.bool,
		hideName: PropTypes.bool,
		namePrefix: PropTypes.string,
		isPresentOptions: PropTypes.array,
	};
	static defaultProps = {
		className: "",
		model: {
			isOwnerPresent: "",
			telephone: "",
			cellNumber: "",
			name: {
				firstName: "",
				lastName: "",
			},
			address: {
				street: "",
				houseNumber: "",
				areaCode: "",
				city: "",
			},
		},
		namePrefix: "",
		isPresentOptions: [{ name: "Nein" }, { name: "Ja" }],
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		if (!this.props.resetState) return;
		console.log("Props", this.props, "Next props", props);
		this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		const address = model.address || {};
		const name = model.name || {};
		return {
			cellNumber: model.cellNumber || "",
			firstName: name.firstName || "",
			lastName: name.lastName || "",
			telephone: model.telephone || "",
			email: model.email || "",
			isOwnerPresent: model.isOwnerPresent || "",
			street: address.street || "",
			houseNumber: address.houseNumber || "",
			areaCode: address.areaCode || "",
			city: address.city || "",
			error: {
				cellNumber: null,
				firstName: null,
				lastName: null,
				telephone: null,
				email: null,
				techsupply: null,
				street: null,
				houseNumber: null,
				areaCode: null,
				city: null,
				isOwnerPresent: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!validateAreaCode(model.areaCode)) {
			isValid = false;
			error.areaCode = true;
		}
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		const {
			cellNumber,
			telephone,
			isOwnerPresent,
			firstName,
			lastName,
			email,
			...address
		} = model;
		return {
			cellNumber,
			telephone,
			isOwnerPresent,
			firstName,
			lastName,
			email,
			address: { ...this.props.address, ...address },
		};
	};
	getModel = () => {
		const model = this.validate();
		return model;
	};
	renderName = (className) => {
		const { namePrefix, disabled } = this.props;
		const { firstName, lastName, error } = this.state;
		return (
			<div
				className={`${className} ${
					error.firstName || error.lastName ? " error-group-" : ""
				}`}
			>
				<div className="w3-row">
					<div
						className={`w3-col s6 pad-sm-rht ${
							error.firstName ? " error-group" : ""
						}`}
					>
						<Label htmlFor="firstName">{`Vorname*`}</Label>
						<InputText
							id="firstName"
							className="w3-block w3-border"
							value={firstName}
							onChange={(event) =>
								this.setState({ firstName: event.target.value })
							}
							disabled={disabled}
						/>
					</div>

					<div
						className={`w3-col s6 pad-sm-rht ${
							error.lastName ? " error-group" : ""
						}`}
					>
						<Label htmlFor="lastName">{`Nachname*`}</Label>
						<InputText
							id="lastName"
							className="w3-block w3-border"
							value={lastName}
							onChange={(event) =>
								this.setState({ lastName: event.target.value })
							}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};
	renderStreetHouse = (className) => {
		const { namePrefix, disabled } = this.props;
		const { street, houseNumber, error } = this.state;
		return (
			<div
				className={`${className} ${
					error.street || error.houseNumber ? " error-group-" : ""
				}`}
			>
				<div className="w3-row">
					<div
						className={`w3-col s8 pad-sm-rht ${
							error.street ? " error-group" : ""
						}`}
					>
						<Label htmlFor="street">{`Straße*`}</Label>
						<InputText
							name="street1"
							id="street"
							className="w3-block w3-border"
							value={street}
							onChange={(event) =>
								this.setState({ street: event.target.value })
							}
							disabled={disabled}
						/>
					</div>

					<div
						className={`w3-col s4 pad-sm-lft ${
							error.houseNumber ? " error-group" : ""
						}`}
					>
						<Label htmlFor="houseNumber">{`Hausnummer*`}</Label>
						<InputText
							name="houseNumber"
							id="houseNumber"
							className="w3-block w3-border"
							value={houseNumber}
							onChange={(event) =>
								this.setState({ houseNumber: event.target.value })
							}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};
	renderAreaCity = (className) => {
		const { namePrefix, disabled } = this.props;
		const { areaCode, city, error } = this.state;
		return (
			<div
				className={`${className} ${
					error.areaCode || error.city ? " error-group-" : ""
				}`}
			>
				<div className="w3-row">
					<div
						className={`w3-col s4 pad-sm-rht ${
							error.areaCode ? " error-group" : ""
						}`}
					>
						<Label
							className="display-inline-block"
							htmlFor="areaCode"
						>{`PLZ*`}</Label>
						<InputText
							name="areaCode1"
							id="areaCode"
							className="w3-block w3-border"
							value={areaCode}
							onChange={(event) =>
								this.setState({ areaCode: event.target.value })
							}
							disabled={disabled}
						/>
					</div>

					<div
						className={`w3-col s8 pad-sm-lft ${
							error.city ? " error-group" : ""
						}`}
					>
						<Label
							className="display-inline-block"
							htmlFor="city"
						>{`Ort*`}</Label>
						<InputText
							name="city1"
							id="city"
							className="w3-block w3-border"
							value={city}
							onChange={(event) => this.setState({ city: event.target.value })}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};
	render() {
		const { className, disabled } = this.props;
		const { cellNumber, telephone, isOwnerPresent, email, error } = this.state;
		const YesNoOptions = [
			{ label: "Nein", value: "Nein" },
			{ label: "Ja", value: "Ja" },
		];

		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item${
								error.isOwnerPresent ? " error-group" : ""
							}`}
						>
							<Label htmlFor="isOwnerPresent">{`Eingentümer wohnt im Objekt*`}</Label>
							<Dropdown
								id="isOwnerPresent"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={isOwnerPresent}
								options={YesNoOptions}
								onChange={(event) =>
									this.setState({ isOwnerPresent: event.value })
								}
								showClear
							/>
						</div>

						<div className="pad-top form-group-item">{this.renderName()}</div>

						<div className="pad-top form-group-item">
							{this.renderStreetHouse()}
						</div>

						<div className="pad-top form-group-item">
							{this.renderAreaCity()}
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item${
								error.telephone ? " error-group" : ""
							}`}
						>
							<Label htmlFor="telephone">{`Rufnummer (Festnetz)*`}</Label>
							<InputText
								id="telephone"
								className={`w3-block w3-border ${
									error.telephone ? "w3-border-red" : ""
								}`}
								value={telephone}
								onChange={(event) =>
									this.setState({ telephone: event.target.value })
								}
								disabled={disabled}
							/>
						</div>

						<div
							className={`pad-top form-group-item${
								error.cellNumber ? " error-group" : ""
							}`}
						>
							<Label htmlFor="cellNumber">{`Rufnummer (Mobil)*`}</Label>
							<InputText
								id="cellNumber"
								className="w3-block w3-border"
								value={cellNumber}
								onChange={(event) =>
									this.setState({ cellNumber: event.target.value })
								}
								disabled={disabled}
							/>
						</div>

						<div
							className={`pad-top form-group-item${
								error.email ? " error-group" : ""
							}`}
						>
							<Label htmlFor="email">{`Email*`}</Label>
							<InputText
								id="email"
								className="w3-block w3-border"
								value={email}
								onChange={(event) =>
									this.setState({ email: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
