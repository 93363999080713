import React from 'react';
import './styles.css';
import logo from './vf-logo.svg';

const AppLogo = () => (
  <span className="logo">
    <img src={logo} className="main-logo" alt="logo" />
  </span>
);
// const AppLogo = () => (
//   <span className="mod-glnav">
//     <span className="logo">
//       <span className="icon icon-vf-inner"/>
//     </span>
//   </span>
// );
export default AppLogo;
