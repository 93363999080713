import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';

export default class ADConnectionTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      user: '',
      adUsername: '',
      adPassword: ''
    }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      adUsername: model.adUsername || '',
      adPassword: model.adPassword || '',
      error: {
        adUsername: null,
        adPassword: null,
      }
    };
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    let isChanged = false;
    let isValid = false;
    Object.keys(model).forEach(key => {
      if (model[key] !== originalModel[key]) {
        isChanged = true;
      }
      if (error[key] !== undefined && error[key] === '') {
        error[key] = true;
        isValid = true;
      }
    });
    this.setState({ error });
    if (!isValid && !isChanged) {
      return null;
    }
    return { ...originalModel, ...model };
  }
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  }
  render() {
    const { className, model } = this.props;
    const { adUsername, adPassword, error } = this.state;
    return (
      <div className={`${className} w3-large`}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Benutzer`}</Label>
            <InputText className="w3-block w3-border" value={model.user} disabled/>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.adUsername ? ' error-group' : ''}`}>
            <Label htmlFor="adUsername">{`Kennung*`}</Label>
            <InputText
              id="adUsername"
              className="w3-block w3-border"
              value={adUsername}
              onChange={(event) => this.setState({
                adUsername: event.target.value,
                error: {...error, adUsername: null}
              })}
            />
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.adPassword ? ' error-group' : ''}`}>
            <Label htmlFor="adPassword">{`Passwort*`}</Label>
            <InputText 
              id="adPassword"
              className="w3-block w3-border"
              value={adPassword}
              onChange={(event) => this.setState({
                adPassword: event.target.value,
                error: {...error, adPassword: null}
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}
