import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
import { createSortByAttr } from '../../../../components/Utils';

export default class TechForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    techKeys: PropTypes.array,
    minimal: PropTypes.bool,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: [],
    techKeys: []
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      model: model ? model.map(item => item.id) : []
    };
  }
  // componentWillReceiveProps(props) {
  //   this.setState({model: props.model});
  // }
  validate = () => {
    const { techKeys } = this.props;
    const { model } = this.state;
    return model.map(id => {
      const item = techKeys.find(item => item.id === id);
      return { id, number: (item ? item.number : null) };
    });
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  onCheckboxChange = (event) => {
    let model = [ ...this.state.model ];
    if(event.checked)
      model.push(event.value);
    else
      model.splice(model.indexOf(event.value), 1);
    this.setState({ model });
  }
  getColumn = (items, number) => {
    const col = [];
    let size = Math.floor(items.length / 3);
    if (items.length - (size * 3) > 1) {
      size ++;
    }
    let startIndex = 0;
    let endIndex = size - 1;
    if (number === 1 || number === 2 || number === 3) {
      startIndex = (number - 1) * size;
      endIndex = startIndex + size - 1;
      if (number === 3) {
        endIndex = items.length - 1;
      }
    }
    items.forEach((item, index) => {
      if (index >= startIndex && index <= endIndex) {
        col.push(item);
      }
    });
    return col;
  }
  render() {
    const { className, minimal } = this.props
    const { model } = this.state;
    // const createCheckbox = (prefix) => (value, index) => (
    //   <div key={index} className="p-col-12">
    //     <Checkbox
    //       inputId={`${prefix}${index}`}
    //       value={value}
    //       onChange={this.onCheckboxChange}
    //       checked={model.includes(value)}
    //       disabled={this.props.disabled}>
    //     </Checkbox>
    //     <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
    //   </div>
    // );
    // let col1 = ['A04 (NE4)', 'HSSL (NE4)', 'B01 (NE3)', 'B02 (NE3)', 'Sonstige'].map(createCheckbox('tkey-cb1-'));
    // let col2 = ['D/U (NE4) - Reg4', 'AX (NE3) - Reg1', 'DHS (NE3) - Reg4', 'HHS (NE3) - Reg7'].map(createCheckbox('tkey-cb2-'));
    // let col3 = ['KHS (NE3) - Reg9', 'BX (NE3) - Reg2', 'KU (NE4) - Reg9', 'CHS (NE3) - Reg3'].map(createCheckbox('tkey-cb3-'));
    // if (minimal) {
    //   col1 = ['A04 (NE4)', 'HSSL (NE4)', 'Sonstige'].map(createCheckbox('tkey-cb1-'));
    //   col2 = ['B01 (NE3)', 'B02 (NE3)'].map(createCheckbox('tkey-cb2-'));
    //   col3 = ['D/U (NE4) - Reg4', 'AX (NE3) - Reg1'].map(createCheckbox('tkey-cb3-'));
    // }
    const techKeys = this.props.techKeys.sort(createSortByAttr('number'));
    const createCheckbox = (prefix) => (item, index) => (
      <div key={index} className="p-col-12">
        <Checkbox
          inputId={`${prefix}${index}`}
          value={item.id}
          onChange={this.onCheckboxChange}
          checked={model.includes(item.id)}
          disabled={this.props.disabled}>
        </Checkbox>
        <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{item.number}</label>
      </div>
    );
    const col1 = this.getColumn(techKeys, 1).map(createCheckbox('cb1-'));
    const col2 = this.getColumn(techKeys, 2).map(createCheckbox('cb2-'));
    const col3 = this.getColumn(techKeys, 3).map(createCheckbox('cb3-'));
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s4 pad-lr">{col1}</div>
            <div className="w3-col s4 pad-lr">{col2}</div>
            <div className="w3-col s4 pad-lr">{col3}</div>
          </div>
        </div>
      </div>
    );
  }
}
