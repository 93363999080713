import React from 'react';
import PropTypes from 'prop-types';
import { WizardButtonPanel } from '../../../../../components/Buttons';

import { connect } from 'react-redux';
import { saveKeyOrder } from '../../../actions/keys';
import { PRODUCT_ORDER_STATUS_LABELS, PageTitle, PRODUCT_ORDER_STATUS_IDS, notifyError } from '../../../../../components/Utils';
import ProductOrderOverview from '../../../components/ProductOrderOverview';

const mapStateToProps = (state) => {
  return {
    keyOrder: state.key.keyOrder
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveOrder: (...args) => dispatch(saveKeyOrder(...args))
});

class OrderOverview extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    keyOrder: PropTypes.object,
    keys: PropTypes.array,
    // actions
    saveOrder: PropTypes.func.isRequired,
    // rest
    match: PropTypes.object,
    history: PropTypes.object,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  componentDidMount() {
    const { keyOrder } = this.props;
    if (!keyOrder || !keyOrder.items || keyOrder.items.length === 0) {
      console.log('<< Data Models not set => go to previous page ...');
      this.onPrevious();
    }
  }
  onPrevious = () => {
    const { history, match, onPrevious } = this.props;
    onPrevious && onPrevious({ history, match });
  }
  onNext = () => {
    const { keyOrder, history, match, onNext } = this.props;
    const onSuccess = () => onNext && onNext({ history, match });
    this.props.saveOrder({ keyOrder }, onSuccess)
  }
  onCancel = () => {
    const { history, match, onCancel } = this.props;
    onCancel && onCancel({ history, match });
  }
  renderNav = (className = 'margin-big-top w3-border pad-big w3-light-grey') => {
    return (
      <WizardButtonPanel
        className={className}
        onPrevious={this.onPrevious}
        onNext={this.onNext}
        onCancel={this.onCancel}
        nextLabel={`Speichern und senden`}
        disableIfAbsent
      />
    );
  }
  render() {
    const {
      className,
      keyOrder,
      isArchive,
      readOnly
    } = this.props;
    console.log('OrderOverview.render()', keyOrder);
    if (!keyOrder) {
      return null;
    }
    const isUpdatable = false
    const isNotEditable = false
    return (
      <div className={`key-catalog`}>
        <div className={`${className}`}>
          <div className="w3-border no-border-btm pad bg-secondary- w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{`Bestellungsübersicht`}</h3>
          </div>
          <ProductOrderOverview
            ref={ref => this.form = ref}
            className=''
            model={keyOrder}
            showAvis
            showAdminFiles
            isArchive={isArchive}
          />
        </div>
        {this.renderNav()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
