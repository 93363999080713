import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { Calendar } from "primereact/calendar";
import { toDBDate, toUIInputDate } from "../../../../components/Utils";

export default class MNVRatingForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		isVertical: PropTypes.bool,
		resetState: PropTypes.bool,
		disabled: PropTypes.bool,
		hideName: PropTypes.bool,
		namePrefix: PropTypes.string,
		contactSources: PropTypes.array,
		interests: PropTypes.array,
	};
	static defaultProps = {
		className: "",
		model: {
			contactSource: "",
			contactName: "",
			contactTelephone: "",
			interest: "",
			product: "",
			contactDate: "",
		},
		namePrefix: "",
		contactSources: [],
		interests: [],
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		if (!this.props.resetState) return;
		console.log("Props", this.props, "Next props", props);
		this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		return {
			contactSource: model.contactSource || "",
			contactName: model.contactName || "",
			contactTelephone: model.contactTelephone || "",
			interest: model.interest || "",
			product: model.product || "",
			contactDate: toUIInputDate(model.contactDate) || "",
			error: {
				contactTelephone: null,
				contactSource: null,
				contactName: null,
				interest: null,
				product: null,
				contactDate: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});

		if (!isValid) {
			this.setState({ error });
			return null;
		}
		const {
			contactTelephone,
			contactSource,
			contactName,
			interest,
			product,
			contactDate,
		} = model;
		return {
			contactTelephone,
			contactSource,
			contactName,
			interest,
			product,
			contactDate: toDBDate(model.contactDate),
		};
	};
	getModel = () => {
		const model = this.validate();
		return model;
	};
	render() {
		const {
			className,
			isVertical,
			namePrefix,
			disabled,
			contactSources,
			interests,
		} = this.props;
		const {
			contactTelephone,
			contactSource,
			contactName,
			interest,
			product,
			contactDate,
			error,
		} = this.state;
		const ContactSourcesOptions = contactSources.map((item) => ({
			label: item,
			value: item,
		}));
		const InterestsOptions = interests.map((item) => ({
			label: item,
			value: item,
		}));

		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item${
								error.contactSource ? " error-group" : ""
							}`}
						>
							<Label htmlFor="contactSource">{`Kontakt war mit*`}</Label>
							<Dropdown
								id="contactSource"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={contactSource}
								options={ContactSourcesOptions}
								onChange={(event) =>
									this.setState({ contactSource: event.value })
								}
								showClear
							/>
						</div>
						<div
							className={`pad-top form-group-item${
								error.contactName ? " error-group" : ""
							}`}
						>
							<Label htmlFor="contactName">{`Name des Kontaktes*`}</Label>
							<InputText
								id="contactName"
								className="w3-block w3-border"
								value={contactName}
								onChange={(event) =>
									this.setState({ contactName: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
						<div
							className={`pad-top form-group-item${
								error.contactTelephone ? " error-group" : ""
							}`}
						>
							<Label htmlFor="contactTelephone">{`Telefonnummer des Kontaktes*`}</Label>
							<InputText
								id="contactTelephone"
								className="w3-block w3-border"
								value={contactTelephone}
								onChange={(event) =>
									this.setState({ contactTelephone: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item${
								error.interest ? " error-group" : ""
							}`}
						>
							<Label htmlFor="interest">{`Interesse*`}</Label>
							<Dropdown
								id="interest"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={interest}
								options={InterestsOptions}
								onChange={(event) => this.setState({ interest: event.value })}
								showClear
							/>
						</div>
						<div
							className={`pad-top form-group-item${
								error.product ? " error-group" : ""
							}`}
						>
							<Label htmlFor="product">{`Konkretes Produkt*`}</Label>
							<InputText
								id="product"
								className="w3-block w3-border"
								value={product}
								onChange={(event) =>
									this.setState({ product: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
						<div
							className={`pad-top form-group-item${
								error.contactDate ? " error-group" : ""
							}`}
						>
							<Label htmlFor="contactDate">{`Kontakt am*`}</Label>
							<Calendar
								id="contactDate"
								className="w3-block w3-border"
								readOnlyInput={true}
								dateFormat="dd.mm.yy"
								value={contactDate}
								onChange={(event) => {
									this.setState({ contactDate: event.value });
									console.log("contactDate", event.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
