import * as actionTypes from './actions';
import { generateUsername, getITConnections } from './components/Utils';
import isArray from 'lodash/isArray';
import {
  openFile,
  notifySuccess,
  isLogisticsAssistant,
  // isSalesManager,
  // isDisponent,
  // isLS4,
  // isTechnician,
  // isSalesAdmin,
  // isSalesOperator,
  // isSalesAgent,
  // createSortByUsername,
  notifyError, createSortById
} from '../../components/Utils';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  loaded: false,
  loading: false,
  workers: [],
  header: {},
  filteredWorkers: [],
  companyWorkers: [],
  worker: null, // { ...actionTypes.DUMMY_WORKER },
  currentWorker: null,
  roles: [],
  extendedRoles: [],
  userExists: null,
  userChecked: null,
  isInitialized: false,
  workerKDL: null,
  workerKDLs: [],
  workerCompanies: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load extended roles
    case actionTypes.LOAD_EXTENDED_ROLES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_EXTENDED_ROLES_SUCCESS: {
      const roles = action.result.filter(role => (
        !role.extended && (!action.isAdmin ? !role.admin_only : true)
      ));
      let extendedRoles = action.result.filter(role => (
        role.extended && (!action.isAdmin ? !role.admin_only : true)
      ));
      return {
        ...state,
        loading: false,
        roles,
        extendedRoles
      };
    }
    case actionTypes.LOAD_EXTENDED_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        workers: [],
        error: action.result
      };
    // load workers
    case actionTypes.LOAD_WORKERS: {
      const name = action.onlyLogisticsAssistants ? 'companyWorkers' :'workers';
      return {
        ...state,
        loading: true,
        [name]: [],
      };
    }
    case actionTypes.LOAD_WORKERS_SUCCESS: {
      let data = (action.result || []);
      const name = action.onlyLogisticsAssistants ? 'companyWorkers' : 'workers';
      if (action.onlyLogisticsAssistants) {
        data = data.filter(worker => isLogisticsAssistant(worker));
      } else {
        // sort by id in descending order
        data = data.sort(createSortById(true));
      }
      return {
        ...state,
        loading: false,
        [name]: data
      };
    }
    case actionTypes.LOAD_WORKERS_FAILURE: {
      const name = action.onlyLogisticsAssistants ? 'companyWorkers' :'workers';
      return {
        ...state,
        loading: false,
        [name]: [],
        error: action.result
      };
    }
    // load filtered workers
    case actionTypes.LOAD_FILTERED_WORKERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LOAD_FILTERED_WORKERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        filteredWorkers: action.result,
        header: action.header,
      };
    }
    case actionTypes.LOAD_FILTERED_WORKERS_FAILURE: {
      return {
        ...state,
        loading: false,
        filteredWorkers: [],
        error: action.result
      };
    }

    // load worker KDLs
    case actionTypes.LOAD_WORKER_KDLS: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_WORKER_KDLS_SUCCESS:
      return {
        ...state,
        loading: false,
        workerKDLs: action.result
      };
    case actionTypes.LOAD_WORKER_KDLS_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // load worker companies
    case actionTypes.LOAD_WORKER_COMPANIES: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_WORKER_COMPANIES_SUCCESS: 
      return {
        ...state,
        loading: false,
        workerKDL: action.companyId,
        workerCompanies: action.result
      };
    case actionTypes.LOAD_WORKER_COMPANIES_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // load current worker
    case actionTypes.LOAD_CURRENT_WORKER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CURRENT_WORKER_SUCCESS: {
      const currentWorker = isArray(action.result) ? action.result[0] : action.result;
      return {
        ...state,
        loading: false,
        currentWorker,
      };
    }
    case actionTypes.LOAD_CURRENT_WORKER_FAILURE:
      return {
        ...state,
        loading: false,
        currentWorker: null,
        error: action.result
      };
    // load worker
    case actionTypes.LOAD_WORKER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_WORKER_SUCCESS: {
      const worker = isArray(action.result) ? action.result[0] : action.result;
      if (!worker.company && isArray(worker.companies) && worker.companies.length > 0) {
        worker.company = worker.companies[0].name;
      }
      return {
        ...state,
        loading: false,
        worker,
        userChecked: (action.workerId === 'neu') ? false : null
      };
    }
    case actionTypes.LOAD_WORKER_FAILURE:
      return {
        ...state,
        loading: false,
        worker: null,
        error: action.result
      };
    // check new worker
    case actionTypes.CHECK_WORKER:
      return {
        ...state,
        checking: true
      };
    case actionTypes.CHECK_WORKER_SUCCESS: {
      const result = (isArray(action.result) ? action.result[0] : action.result) || {};
      const userExists = (result.id !== undefined && result.id !== null);
      let worker = { ...state.worker, ...action.worker };
      if (userExists) {
        console.log('REDUX REDUCER - user exists: result', result, '\nold worker:', worker);
        notifySuccess({
          summary: 'Mitarbeiterprofil',
          detail: `Mitarbeiter ${worker.firstname} ${worker.lastname} wurde bereits im AN-Portal angelegt. Das bestehende Profil wird übernommen.`
        });
        // copying data of found user
        worker.companies[0].id = result.roles[0].companyId;
        worker.companies[0].name = result.roles[0].companyName;
        worker = { ...worker, ...result, password: '************' };
        const itConnectionData = getITConnections(result);
        worker.itConnectionData = { ...worker.itConnectionData, ...itConnectionData };
        console.log('REDUX REDUCER - new worker:', worker);
      } else {
        worker.password = 'wird generiert';
        worker.username = result.username || generateUsername(action.worker);
      }
      // const userNamesExist = result.username;
      // const worker = userExists ? result : { ...action.worker, ...result };
      // worker.password = userExists ? '************' : 'wird generiert';
      // if (!userExists && !userNamesExist) {
      //   worker.username = generateUsername(action.worker);
      // }
      return {
        ...state,
        checking: false,
        worker,
        userExists,
        userChecked: true,
      };
    }
    case actionTypes.CHECK_WORKER_FAILURE:
      return {
        ...state,
        checking: false,
        userExists: null,
        userChecked: false,
        error: action.result
      };
    // save worker
    case actionTypes.SAVE_WORKER:
        return {
          ...state,
          saving: true
        };
    case actionTypes.SAVE_WORKER_SUCCESS:
      const worker = action.worker;
      notifySuccess({
        summary: 'Mitarbeiterprofile',
        detail: `Der Mitarbeiter ${worker.firstname} ${worker.lastname} wurde erfolgreich ${worker.id ? 'aktualisiert' : 'angelegt'}!`
      });
      return {
        ...state,
        saving: false,
        worker: null,
        userExists: null,
        userChecked: null,
        isInitialized: false
      };
    case actionTypes.SAVE_WORKER_FAILURE:
      notifyError({
        summary: 'Mitarbeiterprofile',
        detail: `Der Mitarbeiter ${action.worker.firstname} ${action.worker.lastname} konnte nicht ${action.worker.id ? 'aktualisiert' : 'angelegt'} werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
      // initializing the worker wizard
    case actionTypes.INIT_WORKER_WIZARD:
      return {
        ...state,
        isInitialized: action.result
      };
    // (de)activate worker
    case actionTypes.ACTIVATE_WORKER:
    case actionTypes.DEACTIVATE_WORKER:
      return {
        ...state,
        loading: true
      }
    case actionTypes.ACTIVATE_WORKER_SUCCESS:
    case actionTypes.DEACTIVATE_WORKER_SUCCESS:
      notifySuccess({
        summary: `Mitarbeiter ${action.activate ? '' : 'de'}aktivieren`,
        detail: `Der Mitarbeiter wurde erfolgreich ${action.activate ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false
      }
    case actionTypes.ACTIVATE_WORKER_FAILURE:
    case actionTypes.DEACTIVATE_WORKER_FAILURE: {
      const { message } = action.error;
      let suffix = "";
      switch (message) {
        case "label.company.is.not.activated":
          suffix = " denn seine Firma ist deaktiviert";
          break;
        default:
          break;
      }
      notifyError({
        summary: `Mitarbeiter ${action.activate ? '' : 'de'}aktivieren`,
        detail: `Der Mitarbeiter konnte nicht ${action.activate ? '' : 'de'}aktiviert werden${suffix}!`
      });
      return {
        ...state,
        loading: false
      }
    }
    // cancel worker
    case actionTypes.CANCEL_WORKER_WIZARD:
      return {
        ...state,
        worker: null,
        userExists: null,
        userChecked: null,
        isInitialized: false
      };
    // export workers
    case actionTypes.EXPORT_WORKERS:
      return {
        ...state,
        exporting: true,
      }
    case actionTypes.EXPORT_WORKERS_SUCCESS: {
      if (action.result.filePath) {
        notifySuccess({
          summary: `Mitarbeiter exportieren`,
          detail: `Die Mitarbeiterdatei (CSV) wurde erfolgreich erzeugt und kann heruntergeladen werden!`
        });
        openFile(action.result.filePath);
      }
      return {
        ...state,
        exporting: false
      }
    }
    case actionTypes.EXPORT_WORKERS_FAILURE:
      notifyError({
        summary: `Mitarbeiter exportieren`,
        detail: `Die Mitarbeiterdatei (CSV) konnte nicht erzeugt werden!`
      });
      return {
        ...state,
        exporting: false,
        error: action.error
      }
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
