import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { ManagerSalesMenu, AdminSalesMenu } from '../components';
import { toggleFullScreen } from '../actions/reporting';
import { PageHeight40, PageTitle } from '../../../components/Utils';

const mapStateToProps = (state) => {
  return {
    isSalesGuruOnly: state.auth.isSalesGuruOnly,
    isSalesReporterOnly: state.auth.isSalesReporter,
    isFullScreen: state.report.isFullScreen,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setFullScreen: (...args) => dispatch(toggleFullScreen(...args)),
});

class Menu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isSalesGuruOnly: PropTypes.bool,
    isSalesReporterOnly: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  renderMenu = (className) => {
    const { isSalesGuruOnly, isSalesReporterOnly, isFullScreen, setFullScreen } = this.props;
    return (
      <div className={className}>
        <ManagerSalesMenu
          isVisible={isSalesReporterOnly && !isSalesGuruOnly}
          isFullScreen={isFullScreen}
          setFullScreen={setFullScreen}
        />
        <AdminSalesMenu
          isVisible={isSalesGuruOnly}
          isFullScreen={isFullScreen}
          setFullScreen={setFullScreen}
        />
      </div>
    );
  }
  render() {
    console.log('SalesMenu.render()', this.props );
    return (
      <PageTitle noMarginTop={this.props.isFullScreen}>
        {this.renderMenu('w3-right')}
        <h3 className="no-margin">Reporting-Portal</h3>
      </PageTitle>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);


const mapStateToPropsSTH = (state) => {
  return {
    isFullScreen: state.report.isFullScreen,
  };
};
const SalesTableHolderModule = ({ isFullScreen, children }) => {
  console.log('SalesTableHolderModule.render()', isFullScreen );
  const style = !isFullScreen ? {} : {
    position: 'fixed',
    top: '72px',
    left: '0',
    zIndex: 2,
    width: '100%',
    height: 'calc(100vh - 72px)',
    // overflowY: 'scroll',
    background: '#fff'
  };
  return (
    <div style={style}>
      <PageHeight40>{children}</PageHeight40>
    </div>
  );
};
export const SalesTableHolder = connect(mapStateToPropsSTH, null)(SalesTableHolderModule);
