import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
// import { Dropdown } from 'primereact/dropdown';
// import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label, Dropdown } from '../../../../components/Inputs';
import { DelphiConnectionPermissionOptions } from '../Utils';
import { isContractor, createSortByLabel } from '../../../../components/Utils';

export default class DelphiConnectionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companyList: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    isAdmin: PropTypes.bool,
    readOnly: PropTypes.bool,
    requireDescription: PropTypes.bool,
    defaultDescription: PropTypes.string
  }
  static defaultProps = {
    className: '',
    model: {
      companyName: '',
      parentCompanyName: '',
      username: '',
      permissions: '',
      regions: ''
    },
    companyList: [],
    kdlCompanyList: []
  }
  constructor(props) {
    super(props);
    this.state = this.generateState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.generateState(props));
  }
  generateState(props) {
    console.log('DelphiConnectionForm.generateState()', props);
    const model = props.model || {};
    let companyName = model.companyName || '';
    if (props.companyList && props.companyList.length === 1) {
      companyName = props.companyList[0].name;
    }
    return {
      companyName,
      byCompanyName: model.byCompanyName || '',
      parentCompanyName: model.parentCompanyName || '',
      username: model.username || '',
      password: model.password || '',
      description: model.description || '',
      permissions: model.permissions ? model.permissions.split(', ') : [],
      regions: model.regions ? model.regions.split(',').map(item => item.trim()) : [],
      error: {
        companyName: null,
        parentCompanyName: null,
        permissions: null,
        regions: null,
        description: null
      }
    };
  }
  getEmptyModel = () => {
    return {
      companyName: '',
      byCompanyName: '',
      parentCompanyName: '',
      username: '',
      password: '',
      description: '',
      permissions: [],
      regions: []
    };
  }
  findCompanyId = (companies, value) => {
    const found = companies.find(item => item.name === value);
    return found ? found.id : null;
  }
  mergePermissions = (oPermissions, newPermissions) => {
    const permissions = [];
    // add any old permisions
    const oldPermissions = oPermissions.split(',').map(item => item.trim());
    oldPermissions.forEach(permission => {
      const found = DelphiConnectionPermissionOptions.find(item => item.value === permission);
      if (!found) permissions.push(permission);
    });

    return permissions.concat(newPermissions).join(', ');
  }
  validateDescription = (requireDescription, defaultDescription, description) => {
    return requireDescription && (description === '' || description.trim() === defaultDescription.trim())
  }
  validate = (initialize) => {
    const { companyList, kdlCompanyList, requireDescription, defaultDescription } = this.props;
    const { error, ...model } = this.state;
    const originalModel = this.props.model || {}
    // console.log('DelphiConnection.validate() reqDesc', requireDescription, 'old', this.props.model, 'new', model);
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined) {
        if (key === 'regions' || key === 'permissions') {
          if (model[key].length === 0) {
            error[key] = true;
            isValid = false;
          }
        } else if (key === 'description') {
          if (this.validateDescription(requireDescription, defaultDescription, model[key])) {
            error[key] = true;
            isValid = false;
          }
        } else if (model[key] === '') {
          error[key] = true;
          isValid = false;
        }
      }
    });
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    if (initialize) {
      const state = this.getEmptyModel();
      this.setState(state);
    }
    const { id, index, status, status_id } = this.props.model;
    // const permissions = this.mergePermissions(this.props.model.permissions, model.permissions);
    return {
      ...model, id, index, status, status_id,
      oldStatusId: status_id,
      by_company_id: this.findCompanyId(kdlCompanyList, model.byCompanyName),
      company_id: this.findCompanyId(companyList, model.companyName),
      parent_company_id: this.findCompanyId(kdlCompanyList, model.parentCompanyName),
      regions: model.regions.join(', '),
      permissions: model.permissions.join(', ')
    };
  }
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  }
  onCheckboxChange = (event) => {
    let regions = [ ...this.state.regions ];
    if(event.checked) {
      regions.push(event.value);
    } else {
      regions.splice(regions.indexOf(event.value), 1);
    }
    this.setState({
      regions,
      error: {
        ...this.state.error,
        regions: (regions.length === 0)
      }
    });
  }
  renderRegions = (model, holderClassName = '', className = '') => {
    const { readOnly } = this.props;
    const { companyName, kdlCompanyList, regions } = model;
    let markup = null;
    const currentCompany = kdlCompanyList.find(item => item.name === companyName);
    console.log('DelphiConnection.renderRegions()', currentCompany, model);
    if (currentCompany || readOnly) {
      const createCheckbox = (prefix) => (value, index) => (
        <div key={index} className={className}>
          <div className="p-col-12 delphiRegion">
            <Checkbox
              inputId={`${prefix}${index}`}
              value={value}
              onChange={this.onCheckboxChange}
              checked={regions.includes(value)}
              readOnly={readOnly}
            />
            <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
          </div>
        </div>
      );
      let regionList = [];
      if (currentCompany) {
        regionList = currentCompany.companyRegions || (currentCompany.regions ? currentCompany.regions.map(item => item.name) : [])
      } else if (readOnly) {
        regionList =  regions;
      }
      const items = (regionList || []).map(createCheckbox('delphiCb-'));
      markup = (<div className="w3-row">{items}</div>);
    }
    return (
      <div className={holderClassName}>
        <Label>{`Regionen*`}</Label>
        <div className="neg-margin-lr" style={{color: '#000 !important'}}>{markup}</div>
      </div>
    );
  }
  onPermissionsChange = (event) => {
    let permissions = [ ...this.state.permissions ];
    if(event.checked) {
      permissions.push(event.value);
    } else {
      permissions.splice(permissions.indexOf(event.value), 1);
    }
    this.setState({
      permissions,
      error: {
        ...this.state.error,
        permissions: (permissions.length === 0)
      }
    });
  }
  renderPermissions = (model, holderClassName = '', className = '') => {
    const { options, permissions } = model;
    const createCheckbox = (prefix) => (value, index) => (
      <div key={index} className={className}>
        <div className="p-col-12">
          <Checkbox
            inputId={`${prefix}${index}`}
            value={value}
            onChange={this.onPermissionsChange}
            checked={permissions.includes(value)}
            readOnly={this.props.readOnly}
          />
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
        </div>
      </div>
    );
    const items = (options || []).map(item => item.value).map(createCheckbox('delphiPermCb-'));
    return (
      <div className={holderClassName}>
        <Label>{`Rechte*`}</Label>
        <div className="w3-row neg-margin-lr" style={{color: '#000 !important'}}>{items}</div>
      </div>
    );
  }
  addToList = (company, companyOptions) => {
    // console.log('DelphiConnection.addToList()', company, companyOptions);
    if (!company || !companyOptions) return;
    const found = companyOptions.find(item => item.value === company.value);
    if (!found) {
      companyOptions.push(company);
      companyOptions.sort(createSortByLabel())
    }
  }
  onParentCompanyNameChanged = (parentCompanyName) => {
    const state = {
      parentCompanyName,
      byCompanyName: parentCompanyName,
      error: {
        ...this.state.error,
        parentCompanyName: (parentCompanyName === '')
      }
    };
    // if (!this.props.isAdmin) {
    //   state.byCompanyName = parentCompanyName;
    // }
    this.setState(state);
  }
  render() {
    const { className, companyList, kdlCompanyList, isAdmin, requireDescription, defaultDescription, readOnly } = this.props;
    const {
      companyName, byCompanyName, parentCompanyName,
      username, password, permissions, regions,
      description, error
    } = this.state;
    const CompanyOptions = companyList.map(item => ({ label: item.name, value: item.name }));
    const KDLCompanyOptions = kdlCompanyList.filter(item => !isContractor(item)).map(item => ({ label: item.name, value: item.name }));
    // this.addToList({ label: model.byCompanyName, value: model.byCompanyName }, KDLCompanyOptions);
    // this.addToList({ label: model.parentCompanyName, value: model.parentCompanyName }, KDLCompanyOptions);

    // /* 
    //   determine  which company's regions are used for the delphi connection
    //   Standard case: it is the parentCompany (KDL) which is also the byCompany
    //   Special case Cableway: byCompany is Cableway, but parentCompany is Cableway Nord or Süd, so regions are based on byCompany
    // */
    // let regionsCompanyName = parentCompanyName;
    // if (isAdmin && byCompanyName === 'Cableway AG') {
    //   regionsCompanyName = byCompanyName;
    // }
    /* ---------------------------------------------------------------------------
      Update
      use byCompanyName since it is normally the same as parentCompanyName.
      In the case of Cableway, it is different but we still get the right regions
     ---------------------------------------------------------------------------*/
    const regionsCompanyName = byCompanyName;

    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            {/* {isAdmin && (<div className={`w3-col s4 pad-lr form-group-item ${error.companyName ? 'error-group' : ''}`}>
              <Label htmlFor="byCompanyName">{`Firma*`}</Label>
              <Dropdown
                id="byCompanyName"
                className="w3-block w3-border"
                value={byCompanyName}
                placeholder="Bitte auswählen"
                options={KDLCompanyOptions}
                onChange={(event) => this.setState({
                  byCompanyName: event.value,
                  error: {
                    ...error,
                    byCompanyName: (event.value === '')
                  }
                })}
                showClear
                readOnly={readOnly}
              />
            </div>)} */}
            <div className={`w3-col s6 pad-lr form-group-item ${error.companyName ? 'error-group' : ''}`}>
              <Label htmlFor="companyName">{`Beschäftigungsfirma*`}</Label>
              <Dropdown
                id="companyName"
                className="w3-block w3-border"
                value={companyName}
                placeholder="Bitte auswählen"
                options={CompanyOptions}
                onChange={(event) => this.setState({
                  companyName: event.value,
                  error: {
                    ...error,
                    companyName: (event.value === '')
                  }
                })}
                showClear
                readOnly={readOnly}
              />
            </div>
            <div className={`w3-col s6 pad-lr form-group-item ${error.parentCompanyName ? 'error-group' : ''}`}>
              <Label htmlFor="parentCompanyName">{`KDL/DL*`}</Label>
              <Dropdown
                id="parentCompanyName"
                className="w3-block w3-border"
                value={parentCompanyName}
                placeholder="Bitte auswählen"
                options={KDLCompanyOptions}
                onChange={(event) => this.onParentCompanyNameChanged(event.value)}
                showClear
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-col s12 pad-lr">
              {this.renderPermissions(
                { options: DelphiConnectionPermissionOptions, permissions },
                `${error.permissions ? ' error-group' : ''}`,
                'w3-col s4 pad-top pad-lr'
              )}
            </div>
            {this.renderRegions(
              { companyName: regionsCompanyName, kdlCompanyList, regions },
              `w3-col s12 pad-lr${error.regions ? ' error-group' : ''}`,
              'w3-col s4 pad-top pad-lr'
            )}
          </div>
          <div className="w3-row">
            <div className="w3-col s6 pad-lr pad-top form-group-item">
              <Label htmlFor="username">{`Kennung`}</Label>
              <InputText
                id="username"
                className="w3-block w3-border w3-round pad"
                value={username}
                disabled={true/* !isAdmin */}
                onChange={(event) => this.setState({username: event.target.value})}
                readonly={readOnly}
              />
            </div>
            {isAdmin && (
              <div className="w3-col s6 pad-lr pad-top form-group-item">
                <Label htmlFor="password">{`Passwort`}</Label>
                <InputText
                  id="password"
                  className="w3-block w3-border w3-round pad"
                  value={password}
                  disabled={true/* !isAdmin */}
                  onChange={(event) => this.setState({password: event.target.value})}
                  readonly={readOnly}
                />
              </div>
            )}
            <div className={`w3-col s12 pad-lr pad-top ${requireDescription && error.description ? ' error-group' : ''}`}>
              <Label htmlFor="description">{`Bemerkungen${requireDescription ? '*' : ''}`}</Label>
              <InputTextarea
                id="description"
                className="w3-block w3-border"
                value={description}
                rows={3}
                autoResize
                onChange={(event) => this.setState({
                  description: event.target.value,
                  error: {
                    ...error,
                    description: this.validateDescription(requireDescription, defaultDescription, event.target.value)
                  }
                })}
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
