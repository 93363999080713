import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WizardButtonPanel } from '../../../../../components/Buttons';
import { PageHeight40, notifyError, PRODUCT_STATUS_IDS } from '../../../../../components/Utils';
import HtmlText from '../../../../../components/HtmlText';
import ProductAmount from '../../../components/ProductAmount';

import { connect } from 'react-redux';
import {
  saveOrderCatalog
} from '../../../actions/keys';
import { createProductModel } from '../../../components';
import ProductImageOverlayPanel from '../../../components/ProductImageOverlayPanel';

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.key.loading,
    keyOrder: state.key.keyOrder,
    keys: state.key.catalogKeys
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveCatalog: (...args) => dispatch(saveOrderCatalog(...args)),
});

class KeyCatalog extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    keyOrder: PropTypes.object,
    keys: PropTypes.array,
    // actions
    saveCatalog: PropTypes.func.isRequired,
    // rest
    match: PropTypes.object,
    history: PropTypes.object,
    onNext: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const keyOrder = props.keyOrder || {};
    const keys = props.keys || [];
    // console.log('KeyCatalog.render()', keyOrder, keys);
    const orderItems = (keyOrder.items || [])
    const items = [];
    keys.forEach((key, index) => {
      // // accept only activated products
      // if (key.status_id !== PRODUCT_STATUS_IDS.ACTIVATED) {
      //   return;
      // }
      const data = createProductModel(key, index);
      const found = orderItems.find(item => item.product_id === key.id);
      data.amount = found ? found.amount : 0;
      items.push(data);
    });
    items.sort((a, b) => {
      if (a.amount && !b.amount) return -1;
      if (!a.amount && b.amount) return 1;
      return 0;
    });
    return { items };
  }
  getModel = () => {
    const items = [];
    let isValid = true;
    this.props.keys.forEach((key, index) => {
      const item = this[`item${index}`] ? this[`item${index}`].getValue() : '';
      console.log('<< item', index, item);
      if (item) {
        (item.amount > 0) && items.push(item);
      } else {
        isValid = false;
      }
    });
    return isValid ? items : null;
  }
  onNext = () => {
    const { history, match, onNext } = this.props;
    const items = this.getModel();
    console.log('KeyCatalog.onNext()', items);
    if (!items) {
      return;
    } else if (items.length === 0) {
      return notifyError({
        summary: `Schließmittel-Shop Katalog`,
        detail: `Sie müssen eine Menge (ungleich 0) für mindestens 1 Schließmittel eingeben.`
      });
    }
    const onSuccess = () => onNext && onNext({ history, match });
    this.props.saveCatalog({ items }, onSuccess)
  }
  onCancel = () => {
    const { history, match, onCancel } = this.props;
    onCancel && onCancel({ history, match });
  }
  onChange = (index, item) => {
    const { items } = this.state;
    const itemIndex = items.findIndex(item => item.index === index);
    items[itemIndex] = item;
    this.setState(items);
  }
  renderNav = (className = 'margin-big-top w3-border pad-big w3-light-grey') => {
    return (
      <WizardButtonPanel
        className={className}
        onNext={this.onNext}
        onCancel={this.onCancel}
        nextLabel={`Weiter`}
        disableIfAbsent
      />
    );
  }
  render() {
    const { className } = this.props;
    const { items } = this.state;
    // console.log('KeyCatalog.render()', items);
    const nameTemplate = (item) => (<ProductImageOverlayPanel model={item}/>);
    const amountTemplate = (rowData) => {
      const id = `amt${rowData.index}`;
      return (
        <ProductAmount
          ref={ref => this[`item${rowData.index}`] = ref}
          id={id}
          model={rowData}
          onChange={(item) => this.onChange(rowData.index, item)}
        />
      );
    }
    console.log('KeyCatalog.render() catalog items', items);
    return (
      <div className={`key-catalog`}>
        <PageHeight40 className={`${className}`}>
          <div className="w3-border pad bg-secondary- w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{`Katalog`}</h3>
          </div>
          <DataTable value={items} scrollable scrollHeight="416px">
            <Column
              field="productNumber"
              header="VKDG-Bestell-Nr."
              sortable
              filter
              filterMatchMode="contains"
              style={{textAlign:'center', width: '12em'}}
            />
            <Column
              field="productName"
              header="VKDG-Bezeichnung"
              body={nameTemplate}
              sortable
              filter
              filterMatchMode="contains"
              style={{width: '18em'}}
            />
            <Column
              field="productComments"
              header="Zusatzbemerkung"
              body={(item) => (<HtmlText text={item.productComments}/>)}
              sortable
              filter
              filterMatchMode="contains"
            />
            <Column
              body={amountTemplate}
              header="Menge"
              style={{textAlign:'center', width: '6em'}}
            />
          </DataTable>
        </PageHeight40>
        {this.renderNav()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyCatalog);
