import React from 'react';
import PropTypes from 'prop-types';
import BasicDataChangeFormContent from '../components/BasicDataChangeFormContent';
import { FormMenu } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { updateBasicData } from '../../../redux/actions/auth';
import { loadCurrentWorker } from '../../Worker/actions';

const mapStateToProps = (state) => {
  return {
    user: state.worker.currentWorker,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateData: (...args) => dispatch(updateBasicData(...args)),
  loadProfile: (...args) => dispatch(loadCurrentWorker(...args)),
});

class BasicDataChangeForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.shape({}),
    updateData: PropTypes.func.isRequired,
    loadProfile: PropTypes.func.isRequired,
    onClose: PropTypes.func
  }
  static defaultProps =  {
    className: ''
  }
  onSave = () => {
    const { updateData, onClose, loadProfile } = this.props;
    const model = this.form.getModel();
    if (!model) return;
    console.log('>> updating basic data', model);
    updateData(model, () => {
      onClose();
      loadProfile();
    });
  }
  onCancel = () => {
    const { onClose } = this.props;
    onClose && onClose();
  }
  render() {
    const { className, user } = this.props;
    return (
      <div className={`${className}`}>
        <BasicDataChangeFormContent
          ref={ref => this.form = ref}
          model={user}
        />
        <FormMenu
          className="margin-lg-top w3-border-top pad-big w3-light-grey"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          saveBtnName="updateBasicDataBtn"/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicDataChangeForm);