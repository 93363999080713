import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import { TableEmptyMessage } from '../../../../components/Utils';

export default class Panel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    companies: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    companies: [],
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, companies, rows } = this.props;
    const { first } = this.state;
    // console.log('ContractorCompanyPanel.render()', companies);
    const data = (companies || [])
    .filter(company => company.id && company.id !== '')
    // .filter(company => company.name !== company.contractGiver)
    .map(company => {
      const position = [];
      // if (company.role) position.push(company.role);
      if (company.extendedRoles) {
        company.extendedRoles.forEach(item => position.push(item));
      }
      const regionssStr = (company.companyRegions || []).join(', ');
      return {
        ...company,
        role: company.role || 'Mitarbeiter',
        position: position.join(', '),
        regionssStr
      };
    });
    return (
      <div className={className}>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Tätigkeiten"
              itemName="Tätigkeit"
              itemNamePlural="Tätigkeiten"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Tätigkeiten" min/>)}
        >
          <Column
            field="name"
            header="Beschäftigungsfirma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="contractGiver"
            header="KDL"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="role"
            header="Position"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          />
          <Column
            field="position"
            header="Aufgaben"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {/* <Column field="regionssStr" header="Tätigkeitsregionen" sortable filter style={{width: '12em'}}/> */}
        </DataTable>
      </div>
    );
  }
}
