import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label } from '../../../../components/Inputs';
import AddressForm from '../../../Company/components/wizard/AddressForm';
import PersonForm from '../../../Company/components/wizard/PersonForm';

export default class DebitorTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      region: '',
      debitorKey: '',
      name: '',
      address: {
        street: '',
        houseNumber: '',
        areaCode: '',
        city: ''
      },
      comment: '',
      status_id: 1
    }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      region: model.region || '',
      debitorKey: model.debitorKey || '',
      comment: model.comment || '',
      error: {
        region: null,
        debitorKey: null,
        // comment: null,
      }
    };
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    let isChanged = false;
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (model[key] !== originalModel[key]) {
        isChanged = true;
      }
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });

    this.setState({ error });
    if (!isValid/*  && !isChanged */) {
      return null;
    }
    
    // person (optional)
    const person = this.personForm.getModel();
    if (person) {
      const originalPerson = originalModel.person || {};
      model.person = {
        ...originalPerson,
        ...person
      };
    }

    // address (required)
    const addressModel = this.addressForm.getModel();
    if (addressModel) {
      const { name, address } = addressModel;
      model.name = name;
      // model.company_id = company_id;
      model.address = address;
    } else {
      return null;
    }
    return { ...originalModel, ...model };
  }
  getModel = (initialize = false) => {
    // console.log('DebitorTaskForm.getModel() - initialize:', initialize);
    const model = this.validate(initialize);
    return model;
  }
  render() {
    const {
      className,
      model: {
        company_id,
        name,
        address,
        person
      }
    } = this.props;
    const { region, debitorKey, comment, error } = this.state;
    const company = { company_id, name, address };
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top">
            <Label htmlFor= "region">{`Region*`}</Label>
            <InputText
              id="region"
              className="w3-block w3-border"
              value={region}
              disabled={true}
              onChange={(event) => this.setState({region: event.target.value})}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top">
            <Label htmlFor= "debitorKey">{`Kennung*`}</Label>
            <InputText
              id="debitorKey"
              className="w3-block w3-border"
              value={debitorKey}
              disabled={true}
              onChange={(event) => this.setState({debitorKey: event.target.value})}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top">
            <h4 className="no-margin-">{`Lieferanschrift*`}</h4>
            <AddressForm
              ref={ref => this.addressForm = ref}
              model={company}
              isVertical
              namePrefix="debitor_"
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top">
            <h4 className="no-margin-">{`Ansprechpartner`}</h4>
            <PersonForm
              ref={ref => this.personForm = ref}
              model={person}
              // workers={workers}
            />
          </div>
        </div>
        <div className="pad-top">
          <Label htmlFor= "comment">{`Bemerkungen`}</Label>
          <InputTextarea
            id= "comment"
            className="w3-block w3-border"
            value={comment}
            rows={3}
            autoResize
            onChange={(event) => this.setState({comment: event.target.value})}/>
        </div>
      </div>
    );
  }
}
