import React from 'react';
import { Route, Switch, Link } from "react-router-dom";
import { DataView } from 'primereact/dataview';
import { PageFactory } from '../index';
// import BreadCrumbs from '../../BreadCrumbs';

import { connect } from 'react-redux';
import { PageHeight40, PageTitle, scrollToTop } from '../../../components/Utils';


export default function ArchiveFactory(key, rootUrl, title) {
  const SectionRoutes = ({ className = '', links }) => {
    // console.log('Archive()', key, rootUrl, title, links);
    const routes = links.map((link, index) => {
      const { url, label } = link;
      const data = link.data || {};
      const type = data ? data.type : link.type;
      const Component = PageFactory(url, label, type, null, null, link);
      return (
        <Route
          key={index}
          exact
          path={`${rootUrl}${url}`}
          component={Component}
        />
      );
    });
    const HomePage = ({}) => (
      <Home
        rootUrl={rootUrl}
        title={title}
        links={links}
      />
    );
    return (
      <div className={className}>
        <Switch>
          {routes}
          <Route path="/*" component={HomePage}/>
        </Switch>
      </div>
    );
  };

  const mapStateToProps = (state) => {
    return {
      links: state.staticPage.archives[key],
    };
  };

  return connect(mapStateToProps, null)(SectionRoutes);
};

const Home = ({ rootUrl, title, links }) => {
  React.useEffect(() => {
    scrollToTop();
  }, []);
  const renderItem = (item) => {
    const { label, url } = item;
    let linkClassName = 'w3-block pad';
    if (item.index < links.length - 1) {
      linkClassName += ' w3-border-bottom';
    }
    return (
      <Link className={linkClassName} to={rootUrl + url}>
        <h4 className="no-margin">{label}</h4>
        <div className="bold">{url}</div>
      </Link>
    )
  };
  const markup = (links.length > 0) ? (
    <PageHeight40>
      <div className="w3-border">
        <DataView
          // className="margin-lg-top"
          value={links.map((item, index) => ({ ...item, index}))}
          itemTemplate={renderItem}
          emptyMessage=""
        />
      </div>
    </PageHeight40>
  ) : (
    <PageHeight40 className="w3-border"/>
  );
  return (
    <div>
      <PageTitle noMarginTop>
        <h3 className="no-margin">{title}</h3>
      </PageTitle>
      {markup}
    </div>
  );
};
