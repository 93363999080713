import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { validateEmail } from "../../../../components/Utils";

export default class BookingReporterForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		updateable: PropTypes.bool,
		companies: PropTypes.array,
		workers: PropTypes.array,
		getWorkers: PropTypes.func.isRequired,
	};
	static defaultProps = {
		className: "",
		model: {},
		companies: [],
		workers: [],
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		this.props.updateable && this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		return {
			companyName: model.companyName || "",
			workerId: model.workerId || "",
			email: model.email || "",
			error: {
				companyName: null,
				workerId: null,
				email: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		// validate email
		if (!validateEmail(model.email)) {
			isValid = false;
			error.email = true;
		}
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		// set company id
		let found = this.props.companies.find(
			(item) => item.companyId === model.companyName
		);
		if (found) {
			model.id = found.name;
		}
		// set worker name
		found = this.props.workers.find((item) => item.id === model.workerId);
		if (found) {
			model.workerName = found.username;
		}
		return model;
	};
	getModel = () => {
		const model = this.validate();
		return model;
	};
	setCompany = (companyName) => {
		const { getWorkers } = this.props;
		this.setState({ companyName });
		console.log('>> finding sales agents for', companyName);
		getWorkers({ companyName });
		// const { companies, getWorkers } = this.props;
		// this.setState({ companyName });
		// const found = companies.find((item) => item.id === companyId);
		// if (found) {
		// 	const companyName = found.name;
		// 	getWorkers({ companyName });
		// }
	};
	render() {
		const { className, companies, workers } = this.props;
		const { companyName, workerId, email, error } = this.state;
		const CompanyOptions = companies.map((item) => ({
			label: item.name,
			value: item.name,
		}));
		const WorkerOptions = workers.map((item) => ({
			label: `${item.username}`,
			value: item.id,
		}));
		console.log("BookingReporterForm.render", this.props);

		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.companyName ? "error-group" : ""
							}`}
						>
							<Label htmlFor="companyName">{`KDL*`}</Label>
							<Dropdown
								name="companyName"
								id="companyName"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								options={CompanyOptions}
								value={companyName}
								onChange={(event) => this.setCompany(event.value)}
								showClear
							/>
						</div>
						<div
							className={`pad-top form-group-item ${
								error.workerId ? "error-group" : ""
							}`}
						>
							<Label htmlFor="workerId">{`Salesagent*`}</Label>
							<Dropdown
								id="workerId"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								options={WorkerOptions}
								value={workerId}
								onChange={(event) => this.setState({ workerId: event.value })}
								showClear
							/>
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.email ? "error-group" : ""
							}`}
						>
							<Label htmlFor="email">{`Ihre E-Mail Adresse*`}</Label>
							<InputText
								id="email"
								className="w3-block w3-border"
								value={email}
								onChange={(event) =>
									this.setState({ email: event.target.value })
								}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
