import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecondaryButtonLink } from '../../../../components/Buttons';

const mapStateToProps = (state) => {
  return {
    adConnections: state.task.adConnections,
    ptConnections: state.task.ptConnections,
    delphiConnections: state.task.delphiConnections,
    isVisible: state.auth.isAdmin
  };
};
const mapDispatchToProps = null;

class TaskDashboard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    holderClassName: PropTypes.string,
    adConnections: PropTypes.array,
    ptConnections: PropTypes.array,
    delphiConnections: PropTypes.array,
    isAdmin: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    holderClassName: null,
  }
  renderItem = (label, value) => {
    const labelClassName = value > 0 ? 'w3-text-green' : '';
    return (
      <li className="no-pad-tb">
        <span className={`w3-right ${labelClassName}`}>{value || 'Keine'}</span>
        <span>{label}</span>
      </li>
    );
  }
  render() {
    const {
      className, holderClassName,
      adConnections, ptConnections, delphiConnections, isVisible
    } = this.props;
    console.log('ITTaskDashboard.render()', this.props);
    if (!isVisible) {
      return null;
    }
    let markup = (
      <SecondaryButtonLink className={className} to="/aufgaben/it-zugaenge" style={{minHeight: '250px'}}>
        <h3 className="no-margin alg-center pad-big-tb ">IT-Zugänge</h3>
        <ul className="w3-ul">
          {this.renderItem('Active Directory', adConnections.length)}
          {this.renderItem('iProv-Tool', ptConnections.length)}
          {this.renderItem('Delphi', delphiConnections.length)}
        </ul>
      </SecondaryButtonLink>
    );
    if (holderClassName) {
      markup = (<div className={holderClassName}>{markup}</div>);
    }
    return markup;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDashboard);
