import * as actionTypes from '../actions/card';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';

export const initialState = {
  cardOrders: [],
  selectedCardOrders: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // setting selected card orders
    case actionTypes.SET_SELECTED_CARD_ORDERS:
      return {
        ...state,
        selectedCardOrders: [ ...action.selected ]
      };

    // export card orders as CSV
    case actionTypes.EXPORT_CARD_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXPORT_CARD_ORDERS_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `Karten/Flyer-Bestellungen exportieren`,
        detail: `Die gewählte Bestellungen wurden erfolgreich exportiert.`
      });
      return {
        ...state,
        loading: false,
        selectedCardOrders: [],
      };
    case actionTypes.EXPORT_CARD_ORDERS_FAILURE:
      notifyError({
        summary: `Karten/Flyer-Bestellungen  exportieren`,
        detail: `Die gewählte Bestellungen konnten nicht exportiert werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // close card order
    case actionTypes.CLOSE_CARD_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_CARD_ORDER_SUCCESS:
      notifySuccess({
        summary: `Karten/Flyer-Bestellung ${action.saveOnly ? 'speichern' : 'freigeben'}`,
        detail: `Die gewählte Bestellung wurde erfolgreich ${action.saveOnly ? 'gespeichert' : 'freigegeben'}.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_CARD_ORDER_FAILURE:
      notifyError({
        summary: `Karten/Flyer-Bestellung ${action.saveOnly ? 'speichern' : 'freigeben'}`,
        detail: `Die gewählte Bestellung konnte nicht ${action.saveOnly ? 'gespeichert' : 'freigegeben'} werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // delete card order
    case actionTypes.DELETE_CARD_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_CARD_ORDER_SUCCESS:
      notifySuccess({
        summary: `Karten/Flyer-Bestellung löschen`,
        detail: `Die gewählte Bestellung wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_CARD_ORDER_FAILURE:
      notifyError({
        summary: `Karten/Flyer-Bestellung löschen`,
        detail: `Die gewählte Bestellung konnte nicht gelöscht werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    default:
      return null;
  }
}
