import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { Label, Dropdown } from "../../../../components/Inputs";
import { PrimaryButton, SecondaryButton } from "../../../../components/Buttons";

export default class MoveWeekDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    weekOptions: PropTypes.array,
  };
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      onConfirm: props.onConfirm,
      isVisible: false,
      fromWeek: null,
      toWeek: null,
    };
  }
  show = (isVisible, data, onConfirm) => {
    console.log("MoveWeekDialog.show()", data);
    const newState = data || {};
    this.setState({
      ...newState,
      onConfirm,
      isVisible,
    });
  };
  onHide = () => {
    this.setState({ isVisible: false });
  };
  onClick = (isYes) => {
    this.onHide();
    if (isYes && this.state.onConfirm) {
      const { toWeek } = this.state;
      const data = { toWeek };
      this.state.onConfirm(data);
    }
  };
  render() {
    const { title, text, fromWeek, toWeek, isVisible } = this.state;
    const header = (
      <div className="bg-secondary-" style={{ margin: "-1em", padding: "1em" }}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    const footer = (
      <div
        className="alg-right w3-light-grey"
        style={{ margin: "-0.571em -1em", padding: "1em" }}
      >
        <PrimaryButton
          className="margin-lft"
          onClick={() => this.onClick(true)}
          disabled={!toWeek || toWeek === "" || toWeek === fromWeek}
        >
          {"Verschieben"}
        </PrimaryButton>
        <SecondaryButton
          className="margin-lft"
          onClick={() => this.onClick(false)}
        >
          {`Abbrechen`}
        </SecondaryButton>
      </div>
    );
    const WeekOptions = this.props.weekOptions || [];
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{ width: "600px" }}
        modal={true}
        onHide={this.onHide}
      >
        <div className="w3-large">{text}</div>
        <div className="neg-margin-lr w3-row pad-top">
          <div className="w3-col s6 pad-lr form-group-item">
            <Label htmlFor="toWeek">{`Verschieben von KW${fromWeek} nach`}</Label>
            <Dropdown
              id="toWeek"
              className="w3-block w3-border"
              // style={{width: '75px'}}
              value={toWeek}
              placeholder="Kalendarwoche"
              options={WeekOptions}
              onChange={(event) => this.setState({ toWeek: event.value })}
              showClear
            />
          </div>
        </div>
        <div className="w3-large pad-top">{`Wollen Sie mit der Verschiebung fortfahren?`}</div>
      </Dialog>
    );
  }
}
