import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import CertificateForm from '../wizard/CertificateForm';
import { CoverText, TableEmptyMessage } from '../../../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

export default class CertificationListPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, model, rows } = this.props;
    const { first } = this.state;
    const renderItemTable = (items/* , actionTemplate */) => {
      if (items.length === 0) {
        return (<CoverText>{'Für Sie liegen noch keine Zertifizierungen vor'}</CoverText>);
      }
      const data = items.map((item, index) => ({ ...item, index }));
      return (
        <div>
          <h4 className="no-margin alg-center pad-big-btm">{'Update für diesen Bereich erfolgt mit einer weiteren Version.'}</h4>
          <DataTable
            value={data}
            rows={rows}
            paginator={data.length > rows}
            first={first}
            onPage={this.onPage}
            paginatorLeft={
              <SalesTablePageReport
                className="pad-lft"
                totalRecords={data.length}
                filteredRecords={data.length}
                first={first}
                rows={rows}
                emptyText="Keine Zertifizierungen"
                itemName="Zertifizierung"
                itemNamePlural="Zertifizierungen"
              />
            }
            emptyMessage={(<TableEmptyMessage itemNamePlural="Zertifizierungen" min/>)}
          >
            <Column
              field="issuer"
              header="Art"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
            />
            <Column
              field="name"
              header="Zertifizierung"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
            />
            <Column
              field="registrationDate"
              header="angemeldet"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center', width: '10em'}}
            />
            <Column
              field="status"
              header="Status"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center', width: '10em'}}
            />
          </DataTable>
        </div>
      );
    };
    const renderItemForm = (item, onHide) => {
      const certification = item;
      return (
        <div>
          <CertificateForm ref={(ref) => this.form = ref} model={certification} readOnly/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onCancel={onHide}
            saveDisabled
            saveBtnName="saveCertificateBtn"
            saveHidden
            cancelBtnLabel="Schließen"
          />
        </div>
      );
    };
    const getItemFormTitle = (certification) => {
      return (<h3 className="no-margin">{`Zertifizierung ansehen`}</h3>);
    }
    return (
      <ItemListManager
        className={className}
        title={(<h3 className="no-margin-top">Zertifizierungen / Kompetenznachweise</h3>)}
        addBtnHidden
        itemVisibleOnly
        items={model}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="certificate"
      />
    );
  }
}
