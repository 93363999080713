import React from 'react';
import PropTypes from 'prop-types';
import { Rating } from 'primereact/rating';
import { CoverText } from '../../../../components/Utils';

export default class UserRatingPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    delphiConnections: PropTypes.array,
    isEditable: PropTypes.bool,
  }
  static defaultProps = {
    className: ''
  }
  createFilter = (delphiConnections) => {
    return (item) => {
      const found = (delphiConnections || []).find(conn => conn.username === item.technician_delphi_id);
      // console.log('>> filtering', item, 'found', found);
      return found || false;
    };
  }
  render() {
    const { className, model, delphiConnections, isEditable } = this.props;
    if (!model) return null;
    // console.log('UserRatingPanel.render()', this.props);
    const validItems = model.filter(this.createFilter(delphiConnections));
    const items = validItems.length === 0 ? (
      <CoverText>{`${isEditable ? 'Es' : 'Für Sie'} liegen noch keine Bewertungen vor`}</CoverText>
    ) : validItems.map((userRating, index) => {
      return (
        <div key={index} className="pad-big-btm">
          <div className="w3-cell w3-cell-middle w3-large pad-rht">
            <label className="bold">{`DELPHI-Kennung:`}</label>
          </div>
          <div className="w3-cell w3-cell-middle w3-large pad-big-rht">
            <h4 className="no-margin">{userRating.technician_delphi_id}</h4>
          </div>
          <div className="w3-cell w3-cell-bottom">
            <Rating value={userRating.averageRating} readonly/>
          </div>
          <div className="w3-cell w3-cell-middle w3-large pad-lr">
            <h4 className="no-margin">{userRating.averageRating.toFixed(2)}</h4>
          </div>
          <div className="w3-cell w3-cell-middle w3-large pad-lr">
            <h4 className="no-margin">{`(${userRating.ratingsCount} Bewertungen)`}</h4>
          </div>
        </div>
      );
    });
    return (<div className={className}>{items}</div>);
  }
}
