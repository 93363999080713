import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { validateAreaCode } from "../../../../components/Utils";

export default class MNVObjectForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		isVertical: PropTypes.bool,
		resetState: PropTypes.bool,
		disabled: PropTypes.bool,
		hideName: PropTypes.bool,
		namePrefix: PropTypes.string,
		techSupplyOptions: PropTypes.array,
		tvFilters: PropTypes.array,
	};
	static defaultProps = {
		className: "",
		model: {
			objectId: "",
			buildings: "",
			techSupply: "",
			tvFilter: "",
			address: {
				street: "",
				houseNumber: "",
				areaCode: "",
				city: "",
			},
		},
		namePrefix: "",
		techSupplyOptions: [],
		tvFilterOptions: [],
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		if (!this.props.resetState) return;
		console.log("Props", this.props, "Next props", props);
		this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		const address = model.address || {};
		return {
			objectId: model.objectId || "",
			buildings: model.buildings || "",
			techSupply: model.techSupply || "",
			tvFilter: model.tvFilter || "",
			street: address.street || "",
			houseNumber: address.houseNumber || "",
			areaCode: address.areaCode || "",
			city: address.city || "",
			error: {
				objectId: null,
				buildings: null,
				techSupply: null,
				tvFilter: null,
				street: null,
				houseNumber: null,
				areaCode: null,
				city: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!validateAreaCode(model.areaCode)) {
			isValid = false;
			error.areaCode = true;
		}
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		const { objectId, buildings, techSupply, tvFilter, ...address } = model;
		return {
			objectId,
			buildings,
			techSupply,
			tvFilter,
			address: { ...this.props.address, ...address },
		};
	};
	getModel = () => {
		const model = this.validate();
		return model;
	};
	renderStreetHouse = (className) => {
		const { namePrefix, disabled } = this.props;
		const { street, houseNumber, error } = this.state;
		return (
			<div
				className={`${className} ${
					error.street || error.houseNumber ? " error-group-" : ""
				}`}
			>
				<div className="w3-row">
					<div
						className={`w3-col s8 pad-sm-rht ${
							error.street ? " error-group" : ""
						}`}
					>
						<Label htmlFor="street">{`Straße*`}</Label>
						<InputText
							id="street"
							className="w3-block w3-border"
							value={street}
							onChange={(event) =>
								this.setState({ street: event.target.value })
							}
							disabled={disabled}
						/>
					</div>
					<div
						className={`w3-col s4 pad-sm-lft ${
							error.street ? " error-group" : ""
						}`}
					>
						<Label
							htmlFor={`${namePrefix}address_form_housenumber`}
						>{`Hausnummer*`}</Label>
						<InputText
							id={`${namePrefix}address_form_housenumber`}
							className={`w3-block w3-border ${
								error.houseNumber ? "w3-border-red" : ""
							}`}
							value={houseNumber}
							onChange={(event) =>
								this.setState({ houseNumber: event.target.value })
							}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};
	renderAreaCity = (className) => {
		const { namePrefix, disabled } = this.props;
		const { areaCode, city, error } = this.state;
		return (
			<div
				className={`${className} ${
					error.areaCode || error.city ? " error-group-" : ""
				}`}
			>
				<div className="w3-row">
					<div
						className={`w3-col s4 pad-sm-rht ${
							error.areaCode ? " error-group" : ""
						}`}
					>
						<Label
							className="display-inline-block"
							htmlFor="areaCode"
						>{`PLZ*`}</Label>
						<InputText
							id="areaCode"
							className="w3-block w3-border"
							value={areaCode}
							onChange={(event) =>
								this.setState({ areaCode: event.target.value })
							}
							disabled={disabled}
						/>
					</div>

					<div
						className={`w3-col s8 pad-sm-lft ${
							error.city ? " error-group" : ""
						}`}
					>
						<Label
							className="display-inline-block"
							htmlFor="city"
						>{`Ort*`}</Label>
						<InputText
							id="city"
							className="w3-block w3-border"
							value={city}
							onChange={(event) => this.setState({ city: event.target.value })}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};
	render() {
		const {
			className,
			disabled,
			hideName,
			techSupplyOptions,
			tvFilterOptions,
		} = this.props;
		const { objectId, buildings, techSupply, tvFilter, error } = this.state;
		const TechSupplysOptions = techSupplyOptions.map((item) => ({
			label: item,
			value: item,
		}));
		const TvFiltersOptions = tvFilterOptions.map((item) => ({
			label: item,
			value: item,
		}));
		return (
			<div className={className}>
				{!hideName && (
					<div className="w3-row neg-margin-lr">
						<div className="w3-col s6 pad-lr">
							<div
								className={`pad-top form-group-item${
									error.objectId ? " error-group" : ""
								}`}
							>
								<Label htmlFor="objectId">{`Objekt-ID*`}</Label>
								<InputText
									id="objectId"
									className={`w3-block w3-border ${
										error.objectId ? "w3-border-red" : ""
									}`}
									value={objectId}
									onChange={(event) =>
										this.setState({ objectId: event.target.value })
									}
									disabled={disabled}
								/>
							</div>
							<div className="pad-top form-group-item">
								{this.renderStreetHouse()}
							</div>
							<div className="pad-top form-group-item">
								{this.renderAreaCity()}
							</div>
						</div>
						<div className="w3-col s6 pad-lr">
							<div
								className={`pad-top form-group-item${
									error.buildings ? " error-group" : ""
								}`}
							>
								<Label htmlFor="buildings">{`Wohneinheiten*`}</Label>
								<InputText
									id="buildings"
									className={`w3-block w3-border ${
										error.buildings ? "w3-border-red" : ""
									}`}
									value={buildings}
									onChange={(event) =>
										this.setState({ buildings: event.target.value })
									}
									disabled={disabled}
								/>
							</div>

							<div
								className={`pad-top form-group-item${
									error.techSupply ? " error-group" : ""
								}`}
							>
								<Label htmlFor="techSupply">{`Technische Versorgung*`}</Label>
								<Dropdown
									id="techSupply"
									className="w3-block w3-border"
									placeholder="Bitte auswählen"
									value={techSupply}
									options={TechSupplysOptions}
									onChange={(event) =>
										this.setState({ techSupply: event.value })
									}
									showClear
								/>
							</div>
							<div
								className={`pad-top form-group-item${
									error.tvFilter ? " error-group" : ""
								}`}
							>
								<Label htmlFor="tvFilter">{`TV Filter*`}</Label>
								<Dropdown
									id="tvFilter"
									className="w3-block w3-border"
									placeholder="Bitte auswählen"
									value={tvFilter}
									options={TvFiltersOptions}
									onChange={(event) => this.setState({ tvFilter: event.value })}
									showClear
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
