import { FileUpload } from "primereact/fileupload";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

const adjustXHR = (event) => {
  event.xhr.responseType = "json";
};

const DebitorMappingImportForm = ({
  max = 1,
  disabled,
  ...props
}) => {
  // const [nrFiles, setNrFiles] =useState(0);
  // const onSelect = useCallback(
  //   (...args) => {
  //     console.log("onSelect()", ...args);
  //     setNrFiles(nrFiles + 1);
  //   },
  //   [ nrFiles ]
  // );
  return (
    <FileUpload
      {...props}
      name="fileName"
      accept="text/csv"
      onBeforeSend={adjustXHR}
      chooseLabel="Datei auswählen"
      // chooseOptions={{
      //   style: {
      //     pointerEvents: nrFiles < max ? "auto" : "none"
      //   }
      // }}
      uploadLabel="Importieren"
      cancelLabel="Abbrechen"
      // mode="basic"
      disabled={disabled}
      // onSelect={onSelect}
    />
  );
};

export default DebitorMappingImportForm;
