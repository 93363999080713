import React from "react";
import PropTypes from "prop-types";
import { PrimaryButtonLink, PrimaryButton } from "../../../components/Buttons";
import { connect } from "react-redux";
import { exportCompanies } from "../actions";


const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompaniesAsCSV: (...args) => dispatch(exportCompanies(...args)),
});

class CompanyListMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    getCompaniesAsCSV: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ""
  }
  onExportCompanies = (event) => {
    if (event) event.preventDefault();
    this.props.getCompaniesAsCSV();
  }
  render() {
    const { className, isAdmin } = this.props;
    return (
      <div className={`company list-menu ${className}`}>
        {isAdmin && (
          <PrimaryButtonLink to={`/wizard/organisation/firma/neu`} name="addCompanyBtn">
            {`Servicepartner hinzufügen`}
          </PrimaryButtonLink>
        )}
        <PrimaryButton className="margin-lft" name="exportCompanyBtn" onClick={this.onExportCompanies}>
          {"CSV Exportieren"}
        </PrimaryButton>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyListMenu);