import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';

export default class ConfirmationDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      onConfirm: props.onConfirm,
      isVisible: false
    }
  }
  show = (isVisible, data, onConfirm) => {
    const newState = data || {};
    this.setState({
      ...newState,
      onConfirm,
      isVisible
    });
  }
  onHide = () => {
    this.setState({isVisible: false});
  }
  onClick = (isYes) => {
    this.onHide();
    if (isYes && this.state.onConfirm) {
      this.state.onConfirm();
    }
  }
  render() {
    const { title, text, isVisible } = this.state;
    const footer = (
      <div className="alg-right">
        <PrimaryButton className="margin-lft" onClick={() => this.onClick(true)}>Ja</PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onClick(false)}>Nein</SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={title}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '400px'}}
        modal={true}
        onHide={this.onHide}>
        <p>{text}</p>
      </Dialog>
    );
  }
}
