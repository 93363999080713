import React from 'react';
import PropTypes from 'prop-types';
import { FormMenu } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { resetPassword } from '../../../redux/actions/auth';

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (...args) => dispatch(resetPassword(...args)),
});

class PasswordResetConfirmationDialog extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.shape({}),
    resetPassword: PropTypes.func.isRequired,
    onClose: PropTypes.func
  }
  static defaultProps = {
    className: ''
  }
  onSave = () => {
    const { resetPassword, onClose, user } = this.props;
    // console.log('>> reseting user password ...', user);
    resetPassword(user);
    onClose && onClose();
  }
  onCancel = () => {
    const { onClose } = this.props;
    onClose && onClose();
  }
  render() {
    const { className, user } = this.props;
    if (!user) return;
    const { firstname, lastname } = user;
    const text = `Wollen Sie das Passwort für den Mitarbeiter ${firstname} ${lastname} wirklich zurücksetzen?`;
    return (
      <div className={className}>
        <div className="w3-large">{text}</div>
        <FormMenu
          className="margin-lg-top w3-border-top pad-big w3-light-grey"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          saveBtnName="resetPasswordBtn"
          saveBtnLabel="Ja"
          cancelBtnLabel="Nein"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetConfirmationDialog);
