import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { isConnectionPasswordVisible, STATUS_LABELS, TableEmptyMessage, CoverText } from '../../../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import { InputText, Label } from '../../../../components/Inputs';

export default class ITConnectionPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    rows: PropTypes.number,
    children: PropTypes.node
  }
  static defaultProps = {
    className: '',
    model: {
      ptConnection: '',
      ptUsername: '',
      adConnection: '',
      adUsername: '',
      delphiConnections: []
    },
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, model, rows } = this.props;
    const { first } = this.state;
    const {
      ptConnection, ptUsername, ptPassword, ptStatus, ptStatus_id, ptModified_date,
      adConnection, adUsername, adPassword, adStatus, adStatus_id, adModified_date,
      delphiConnections
    } = model;
    const showADPassword = (adPassword && adPassword !== '' && isConnectionPasswordVisible(adModified_date));
    const adMarkup = (!adConnection || adConnection.toLowerCase() !== 'ja') ? null : (
      <div>
        <div className="w3-row pad-top">
          <div className="w3-col s2 pad-lr pad-sm-tb">
            <Label>{`AD-Zugang`}</Label>
            <InputText className="w3-block" value={adConnection} readonly/>
          </div>
          <div className="w3-col s8 pad-lr">
            <div className="w3-row neg-margin-lr">
              <div className="w3-col s4 pad-lr pad-sm-tb">
                <Label>{`Kennung`}</Label>
                <InputText className="w3-block" value={adUsername} readonly/>
              </div>
              <div className="w3-col s4 pad-lr pad-sm-tb">
                {this.props.children}
              </div>
              {showADPassword && (
                <div className="w3-col s4 pad-lr pad-sm-tb">
                  <Label>{`Passwort`}</Label>
                  <InputText className="w3-block" value={adPassword} readonly/>
                </div>
              )}
            </div>
          </div>
          <div className="w3-col s2 pad-lr pad-sm-tb">
            <Label>{`Status`}</Label>
            <InputText className="w3-block" value={adStatus || STATUS_LABELS[adStatus_id]} readonly/>
          </div>
        </div>
      </div>
    );
    const showPTPassword = (ptPassword && ptPassword !== '' && isConnectionPasswordVisible(ptModified_date));
    const ptMarkup = (!ptConnection || ptConnection.toLowerCase() !== 'ja') ? null : (
      <div className="w3-row pad-top">
        <div className="w3-col s2 pad-lr pad-sm-tb">
          <Label>{`Prov.-tool-Zugang`}</Label>
          <InputText className="w3-block" value={ptConnection} readonly/>
        </div>
        <div className="w3-col s8 pad-lr">
          <div className="w3-row neg-margin-lr">
            <div className="w3-col s3 pad-lr pad-sm-tb">
                <Label>{`Kennung`}</Label>
                <InputText className="w3-block" value={ptUsername} readonly/>
            </div>
            {showPTPassword && (
              <div className="w3-col s3 pad-lr pad-sm-tb">
                <Label>{`Passwort`}</Label>
                <InputText className="w3-block" value={ptPassword} readonly/>
              </div>
            )}
          </div>
        </div>
        <div className="w3-col s2 pad-lr pad-sm-tb">
          <Label>{`Status`}</Label>
          <InputText className="w3-block" value={ptStatus || STATUS_LABELS[ptStatus_id]} readonly/>
        </div>
      </div>
    );
    let delphiMarkup = null;
    if (delphiConnections && delphiConnections.length > 0) {
      const data = delphiConnections.map(item => ({
        ...item,
        password: isConnectionPasswordVisible(item.modified_date) ? item.password : ''
        // permissions: item.permissions.join(', ')
      }));
      delphiMarkup = (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Delphi-Zugänge"
              itemName="Zugang"
              itemNamePlural="Delphi-Zugänge"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Delphi-Zugänge" min/>)}
        >
          <Column
            field="byCompanyName"
            header="Firma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="permissions"
            header="Rechte"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="regions"
            header="Regionen"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="username"
            header="Kennung"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column field="password"
            header="Passwort"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '10em', textAlign: 'center'}}
          /> 
        </DataTable>
      );
    }
    // check if no IT Connections exist
    let markup = null;
    if (!ptMarkup && !adMarkup && !delphiMarkup) {
      markup = (
        <CoverText>{'Ihnen wurden noch keine IT-Zugänge zugewiesen'}</CoverText>
      );
    } else {
      markup = (
        <div>
          <div className="neg-margin-lr">{adMarkup}{ptMarkup}</div>
          <h4 className="no-margin pad-tb">{'Delphi-Zugänge'}</h4>
          {delphiMarkup}
        </div>
      );
    }
    return (<div className={className}>{markup}</div>);
  }
}
