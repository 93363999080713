import React from "react";
// import PropTypes from "prop-types";

const SalesTablePageReport = ({
  totalRecords,
  // filteredRecords,
  first,
  rows,
  className = 'report-table-page-report',
  emptyText = 'Keine Ergebnisse',
  itemName = 'Zeile',
  itemNamePlural = 'Zeilen'
}) => {
  // const firstRow = Math.min(first + 1, filteredRecords);
  // const lastRow = Math.min(first + rows, filteredRecords);
  // let text = `Zeige Zeilen ${firstRow} bis ${lastRow} von ${filteredRecords} Zeilen`;
  // if (totalRecords !== undefined && filteredRecords !== undefined && totalRecords !== filteredRecords) {
  //   text += `\n(gefiltert von insgesamt ${totalRecords} Zeilen)`;
  // }
  const firstRow = (totalRecords === 0) ? 0 : (first + 1);
  const lastRow = (totalRecords === undefined ? (first + rows) : Math.min(first + rows, totalRecords));
  let text = `Zeige ${itemName} ${firstRow} bis ${lastRow}`;
  if (totalRecords !== undefined && !isNaN(totalRecords)) {
    text += ` von insgesamt ${totalRecords} ${totalRecords > 1 ? itemNamePlural : itemName}`;
    console.log('SalesTablePageReport.render()', totalRecords, first, rows);
    if (`${totalRecords}` === '0') {
      text = emptyText;
    } else if (`${totalRecords}` === '1') {
      text = `Zeige ${itemName} ${firstRow}`;
    }
  } else {
    text = '';
  }
  return (
    <div className={className}>{text}</div>
  );
};

export default SalesTablePageReport;
