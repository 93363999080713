import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import KeyOrderWizardTitle from './KeyOrderWizardTitle';

import KeyCatalog from './wizard/KeyCatalog';
import OrderAddress from './wizard/OrderAddress';
import OrderOverview from './wizard/OrderOverview';
import OrderOverviewUpdate from './wizard/OrderOverviewUpdate';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadKeyOrder,
  cancelKeyOrder
} from '../../actions/keys';
import { loadCompanies } from '../../../Company/actions';
import { PRODUCT_ORDER_STATUS_IDS } from '../../../../components/Utils';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getCompanies();
  await props.getOrder({ id });
};
const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  keyOrders: state.key.keyOrders,
});
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadKeyOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelKeyOrder(...args)),
  getCompanies: (...args) => dispatch(loadCompanies(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    keyOrders: PropTypes.array,
    getOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.setMenu();
  }
  componentDidUpdate() {
    this.setMenu();
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  setMenu = () => {
    // console.log('KeyOrderWizard.setMenu()');
    // console.log('>> Page1', this.page1);
    // console.log('>> Page2', this.page2);
    // console.log('>> Page3', this.page3);
    const page = this.page1 || this.page2 || this.page3 || this.page4;
    console.log('KeyOrderWizard.setMenu() page', page);
    if (page && page.renderNav && this.wizardTitle) {
      const menu = page.renderNav('bg-trans margin-big-top');
      if (menu) {
        this.wizardTitle.setNavMenu(menu);
      } else {
        page.registerNavListener && page.registerNavListener(this.setMenu);
      }
    }
  }
  cancelOrder = () => {
    // console.log('KeyOrderWizard.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/shop/schliessmittel/bestellungen');
  }
  renderMenu = () => {
    const isEditing = (this.props.match.params.id !== 'neu');
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li><NavLink to={`katalog`} className="disabled-link" activeClassName="active">{'Schließmittel'}</NavLink></li>
            <li><NavLink to={`lieferanschrift`} className="disabled-link" activeClassName="active">{'Lieferanschrift'}</NavLink></li>
            <li><NavLink to={`uebersicht`} className="disabled-link" activeClassName="active">{'Übersicht'}</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const { className, isAdmin, keyOrders, history, match } = this.props;
    const id = match.params.id;
    // console.log('KeyOrderWizard.render()', this.props);
    if (id === 'neu' && !keyOrders) {
      return null;
    }
    // check if atleast on uncompleted key order exists
    const found = (keyOrders || []).find(item => item.status_id !== PRODUCT_ORDER_STATUS_IDS.FINISHED);
    if (id === 'neu' && !isAdmin && found !== null && found !== undefined) {
      console.error('<< There is on uncompleted key order => aborting ...');
      return null;
    }

    const Menu = this.renderMenu();
    const pageProps = {
      history,
      match,
      onCancel: this.cancelOrder
    };
    const CatalogPage = React.forwardRef((props, ref) => {
      // console.log('CatalogPage.render()', KeyCatalog);
      return (
        <KeyCatalog
          forwardedRef={ref}
          {...props}
          {...pageProps}
          onNext={() => history.replace(`/wizard/shop/bestellung/schliessmittel/${id}/lieferanschrift`)}
          nextLabel={`Weiter`}
        />
      );
    });
    const AddressPage = React.forwardRef((props, ref) => {
      const onPrevious = () => history.replace(`/wizard/shop/bestellung/schliessmittel/${id}/katolog`);
      const onNext = () => history.replace(`/wizard/shop/bestellung/schliessmittel/${id}/uebersicht`);
      return (
        <OrderAddress
          forwardedRef={ref}
          {...props}
          {...pageProps}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      );
    });
    const OverviewPage = React.forwardRef((props, ref) => {
      const onPrevious = () => history.replace(`/wizard/shop/bestellung/schliessmittel/${id}/lieferanschrift`);
      const onNext = () => history.replace(`/shop/schliessmittel/bestellungen`);
      return (
        <OrderOverview
          forwardedRef={ref}
          {...props}
          {...pageProps}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      );
    });
    const ArchivePage = React.forwardRef((props, ref) => (
      <OrderOverviewUpdate
        forwardedRef={ref}
        {...props}
        {...pageProps}
      />
    ));
    const NavTitle = React.forwardRef((props, ref) => (
      <KeyOrderWizardTitle {...props} forwardedRef={ref} />
    ));
    return (
      <div className={`key-order-wizard clearfix ${className}`}>
        <NavTitle ref={ref => this.wizardTitle = ref}/>
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <div className="pad-big-top">
              <Switch>
                <Route
                  path="/wizard/shop/bestellung/schliessmittel/:id/katalog"
                  exact
                  render={props => (<CatalogPage {...props} ref={ref => this.page1 = ref}/>)}
                />
                <Route
                  path="/wizard/shop/bestellung/schliessmittel/:id/lieferanschrift"
                  exact
                  render={props => (<AddressPage {...props} ref={ref => this.page2 = ref}/>)}
                />
                <Route
                  path="/wizard/shop/bestellung/schliessmittel/:id/uebersicht"
                  exact
                  render={props => (<OverviewPage {...props} ref={ref => this.page3 = ref}/>)}
                />
                <Route
                  path="/wizard/shop/bestellung/schliessmittel/:id/uebersicht/archiv"
                  exact
                  render={props => (<ArchivePage {...props} ref={ref => this.page4 = ref}/>)}
                />
                <Route
                  path="*"
                  render={(props) => (
                    <Redirect to={`/wizard/shop/bestellung/schliessmittel/${this.props.match.params.id || 'neu'}/katalog`} />
                  )}
                />
              </Switch>
            </div>
          </Dashboard>
        </div>
      </div>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
