import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../components/Inputs';
import { generateUsername } from '../../Worker/components/Utils';

export default class UsernameChangeFormContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  constructor(props) {
    super();
    const model = props.model || {};
    this.state = {
      firstname: model.firstname || '',
      lastname: model.lastname || '',
      username: model.username || '',
      error: {
        firstname: null,
        lastname: null,
        username: null,
      }
    }
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = '';
        isValid = false;
      }
    });
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    // copy original data
    const { id, username, email, cellNumber } = this.props.model;
    return {
      ...model,
      id,
      email,
      cellNumber,
      old_username: username
    };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  submit = () => {
    const model = this.getModel();
    if (!model) return;
    // validate
    this.props.onSubmit(model);
  }
  onSetName = (key, value) => {
    const { lastname, firstname, username } = this.state;
    const state = { lastname, firstname, username };
    if (key === 'lastname') {
      state.lastname = value;
      state.username = generateUsername(state); // `${value}.${firstname}`;
    } else if (key === 'firstname') {
      state.firstname = value;
      state.username = generateUsername(state); // `${lastname}.${value}`;
    }
    this.setState(state);
  }
  render() {
    console.log('UsernameChangeFormContent.render()', this.props)
    const { className } = this.props;
    const { firstname, lastname, username, error } = this.state;
    return (
      <div className={className}>
        <div className={`${error.lastname && 'error-group'}`}>
          <Label htmlFor="lastname">{`Nachname`}<span>*</span></Label>
          <InputText
            id="lastname"
            className=""
            value={lastname}
            onChange={(event) => this.onSetName('lastname', event.target.value)}
          />
          {/* {error.lastname && (<div className="pad-sm-top">{error.lastname}</div>)} */}
        </div>
        <div className={`pad-top ${error.firstname && 'error-group'}`}>
          <Label htmlFor="firstname">{`Vorname`}<span>*</span></Label>
          <InputText
            id="firstname"
            autoFocus
            className=""
            value={firstname}
            onChange={(event) => this.onSetName('firstname', event.target.value)}
          />
        </div>
        <div className={`pad-top ${error.username && 'error-group'}`}>
          <Label htmlFor="username">{`Benutzername`}<span>*</span></Label>
          <InputText
            id="username"
            className=""
            value={username}
            onChange={(event) => this.setState({username: event.target.value})}
            disabled
          />
          {/* {error.username && (<div className="pad-sm-top">{error.username}</div>)} */}
        </div>
      </div>
    );
  }
}
