import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  EditIconNavButton,
  DeleteIconNavButton,
  ActivateIconButton,
  DeactivateIconButton,
  ViewIconNavButton
} from '../../../../components/Buttons';
// import { toUIDate, STATUS_IDS } from '../../../../components/Utils';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import {
  PRODUCT_STATUS_LABELS,
  PRODUCT_STATUS_IDS,
  getActivationText,
  isProductActivated,
  isProductDeactivated,
  isProductDeleted,
  PageHeight40,
  scrollToTop,
  TableEmptyMessage
} from '../../../../components/Utils';
import { sortProductsByName } from '../../components';
import ProductImageOverlayPanel from '../../components/ProductImageOverlayPanel';
import { ProductStatusFilter } from '../../../../components/ColumnFilters';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadCards,
  changeCardStatus,
  selectCardForDeletion
} from '../../actions/cards';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  await props.getProducts();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.card.loading,
    products: state.card.cards
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProducts: (...args) => dispatch(loadCards(...args)),
  changeProductStatus: (...args) => dispatch(changeCardStatus(...args)),
  selectForDeletion: (...args) => dispatch(selectCardForDeletion(...args)),
});

class CardTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    products: PropTypes.array,
    getProducts: PropTypes.func.isRequired,
    changeProductStatus: PropTypes.func.isRequired,
    selectForDeletion: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowDetails = (product, history) => {
    console.log('Editing', product);
    history.push(`/wizard/produkte/karte/${product.id}`);
  }
  onEditProduct = (product, history) => {
    console.log('Editing', product);
    history.push(`/wizard/produkte/karte/${product.id}`);
  }
  onDeactivate = (product) => {
    console.log('CardTable.onDeactivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Karte/Flyer deaktivieren',
        text: getActivationText('die Karte/den Flyer', `${product.name} (${product.number})`, true)
        // `Wollen Sie die Karte/den Flyer ${product.name} (${product.number}) wirklich deaktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.DEACTIVATED
      })
    );
  }
  onActivate = (product) => {
    console.log('CardTable.onActivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Karte/Flyer aktivieren',
        text: getActivationText('die Karte/den Flyer', `${product.name} (${product.number})`, false)
        // `Wollen Sie die Karte/den Flyer ${product.name} (${product.number}) wirklich aktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.ACTIVATED
      })
    );
  }
  onDeleteProduct = (product) => {
    console.log('CardTable.onDeleteProduct()', product);
    this.props.selectForDeletion(product);
  }
  actionTemplate = (rowData) => {
    const editId = `editProductBtn${rowData.id}`;
    const EditComponent = isProductDeleted(rowData) ? ViewIconNavButton : EditIconNavButton;
    const actId = `actProductBtn${rowData.id}`;
    const activateBtn = !isProductActivated(rowData) && !isProductDeleted(rowData) && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivate(rowData)}
      />
    );
    const deactId = `deactProductBtn${rowData.id}`;
    const deactivateBtn = isProductActivated(rowData) && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivate(rowData)}
      />
    );
    const delId = `delProductBtn${rowData.id}`;
    const deleteBtn = !isProductDeleted(rowData) && (
      <DeleteIconNavButton
        id={delId}
        name={delId}
        onClick={() => this.onDeleteProduct(rowData)}
      />
    );
    return (
      <div>
        <div className="w3-cell w3-cell-middle">
          <EditComponent
            id={editId}
            name={editId}
            onClick={(history) => this.onEditProduct(rowData, history)}
          />
        </div>
        <div className="w3-cell w3-cell-middle">
          {activateBtn}
          {deactivateBtn}
        </div>
        <div className="w3-cell w3-cell-middle">
          {deleteBtn}
        </div>
      </div>
    );
  }
  onStatusChange = (selectedStatus) => {
    this.dt.filter(selectedStatus, 'status_id', 'equals');
    this.setState({ selectedStatus });
  }
  render() {
    const { className, isLoading, products, rows } = this.props;
    // console.log('CardTable.render()', products);
    if (!products) {
      return null;
    }
    const data = sortProductsByName(products).map((product, index) => ({
      ...product,
      index,
      status: product.status || PRODUCT_STATUS_LABELS[product.status_id || PRODUCT_STATUS_IDS.ACTIVATED]
    }));
    const statusBodyTemplate = (rowData) => (
      <span className={`status-text product-status-text-${rowData.status_id}`}>{rowData.status}</span>
    );
    const statusFilter = (
      <ProductStatusFilter
        value={this.state.selectedStatus}
        onChange={this.onStatusChange}
      />
    );
    return (
      <PageHeight40 className={`card-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Karten/Flyer"
              itemName=""
              itemNamePlural="Karten/Flyer"
            />
          }
          rows={rows}
          rowClassName={(data) => ({
            'w3-text-grey product-table-deactivated': isProductDeactivated(data),
            'w3-text-red product-table-deleted': isProductDeleted(data)
          })}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Karten"/>)}
        >
          <Column
            field="number"
            header="VKDG-Bestell-Nr."
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="name"
            header="VKDG-Bezeichnung"
            body={(item) => (<ProductImageOverlayPanel model={item} attributeName="name"/>)}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="unit"
            header="Einheit"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '9em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{/* textAlign:'center',  */width: '12em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '10.6em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref} />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(CardTable)
);
