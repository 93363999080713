import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecondaryButtonLink } from '../../../components/Buttons';

export default function TaskDashboardFactory({ stateKey, url, title, visibilityKey }) {
  class TaskDashboard extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      holderClassName: PropTypes.string,
      data: PropTypes.array,
      isVisible: PropTypes.bool
    }
    static defaultProps = {
      className: '',
      holderClassName: null,
      data: []
    }
    render() {
      const { className, holderClassName, data, isVisible } = this.props;
      if (!isVisible) {
        return null;
      }
      const count = data.length;
      const labelClassName = count > 0 ? 'w3-text-green' : '';
      let markup = (
        <SecondaryButtonLink className={className} to={url}>
          <div className="pad-big-tb alg-center">
            <h3 className="no-margin">{title}</h3>
            <div className={`pad-top w3-xlarge ${labelClassName}`}>
              {stateKey ? (count || 'Keine') : (<span>&nbsp;</span>)}
            </div>
          </div>
        </SecondaryButtonLink>
      );
      if (holderClassName) {
        markup = (<div className={holderClassName}>{markup}</div>);
      }
      return markup;
    }
  };
  if (stateKey) {

    const mapStateToProps = (state) => {
      let data = [];
      if (Array.isArray(stateKey)) {
        stateKey.forEach(key => {
          data = data.concat(state.task[key]);
        })
      } else {
        data = state.task[stateKey];
      }
      return {
        data,
        // data: state.task[stateKey],
        isVisible: state.auth[visibilityKey]
      };
    };
    return connect(mapStateToProps)(TaskDashboard);
  }
  return TaskDashboard;
}
