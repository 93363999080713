import React from 'react';
import { PrimaryButton } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { showSettings } from '../../actions/form';


const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin
});
const mapDispatchToProps = (dispatch) => ({
  showDialog: (...args) => dispatch(showSettings(...args)),
});

const MenuFactory = (key) => {
  return ({ isAdmin, showDialog }) => {
    if (!isAdmin) {
      return null;
    }
    return (
      <PrimaryButton onClick={() => showDialog({ key })}>
        {`Einstellungen`}
      </PrimaryButton>
    );
  };
};

export const BookingSettingsMenu = connect(mapStateToProps, mapDispatchToProps)(MenuFactory('booking'));
export const MNVTipSettingsMenu = connect(mapStateToProps, mapDispatchToProps)(MenuFactory('mnvTip'));
