import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PageHeight40, PageTitle, createSortByAttr, DEBITOR_STATUS_LABELS, scrollToTop, TableEmptyMessage } from '../../../../components/Utils';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import DebitorTaskForm from '../../components/debitors/DebitorTaskForm';
import DebitorTaskMenu from '../../components/debitors/DebitorTaskMenu';
import { filterTasksByTaskType } from '../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import {
  setSelectedDebitors,
  filterDebitors,
  closeDebitorTask,
  deleteDebitorTask
} from '../../actions/debitor';

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    debitors: state.task.debitorTasks,
    // selection: state.task.selectedDebitors,
    debitorTaskType: state.task.debitorTaskType
  };
};
const mapDispatchToProps = (dispatch) => ({
  // setSelection: (...args) => dispatch(setSelectedDebitors(...args)),
  filterDebitors: (...args) => dispatch(filterDebitors(...args)),
  closeTask: (...args) => dispatch(closeDebitorTask(...args)),
  deleteTask: (...args) => dispatch(deleteDebitorTask(...args)),
});

class DebitorTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    debitors: PropTypes.array,
    // selection: PropTypes.array,
    debitorTaskType: PropTypes.string,
    // setSelection: PropTypes.func.isRequired,
    filterDebitors: PropTypes.func.isRequired,
    rows: PropTypes.number
  }
  static defaultProps = {
    className: '',
    rows: 10
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    }
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = (onHide) => {
    // console.log('DebitorTaskList.onSave()');
    const task = this.form.getModel();
    if (!task) return;
    const { debitorTaskType, closeTask } = this.props;
    console.log(`>> saving debitor task...`, task, debitorTaskType);
    closeTask({ task, debitorTaskType }, onHide);
  }
  onDelete = (task) => {
    const { debitorTaskType, deleteTask } = this.props;
    console.log(`DebitorTaskList.onDelete()`, task, debitorTaskType);
    deleteTask({ task, debitorTaskType });
  }
  render() {
    console.log('DebitorTaskList.render()', this.props);
    const {
      className, isLoading, rows, debitors,
      // selection, setSelection,
      filterDebitors, debitorTaskType
    } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item, index) => {
        const copy = JSON.parse(JSON.stringify(item));
        const { street, houseNumber, areaCode, city } = item.address;
        const addressStr = `${item.name}, ${street} ${houseNumber}, ${areaCode} ${city}`;
        const status = item.status || DEBITOR_STATUS_LABELS[item.status_id];
        return {
          ...copy,
          regionName: item.region || item.regionName,
          index,
          addressStr,
          status
        };
      }).sort(createSortByAttr('debitorKey'));
      return (
        <DataTable
          value={taskItems}
          paginator={taskItems.length > rows}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {this.setState({first: event.first}); scrollToTop();}}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Debitoraufgaben"
              itemName="Debitor"
              itemNamePlural="Debitoren"
            />
          }
          // selection={selection}
          // onSelectionChange={event => setSelection(event.value)}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Debitoren"/>)}
        >
          <Column
            field="regionName"
            header="Region"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          /> 
          <Column
            field="debitorKey"
            header="Kennung"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          /> 
          <Column
            field="name"
            header="Firma"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="addressStr"
            header="Adresse"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="requester"
            header="Anforderer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="status"
            header="Status"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center-', width: '9em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const debitor = item;
      return (
        <div>
          <DebitorTaskForm
            ref={(ref) => this.form = ref}
            model={debitor}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveDebitorBtn"
            saveBtnLabel="Bestätigen"/>
        </div>
      );
    };
    const getItemFormTitle = (/* debitor */) => {
      let title = 'Debitoraufgabe bearbeiten';
      // if (debitor) {
      // 
      // }
      return (<h3 className="no-margin">{title}</h3>);
    }
    const title = (
      <PageTitle>
        <DebitorTaskMenu
          className="w3-right"
          // selectedDebitors={selection}
          // exportDebitors={exportDebitors}
          filterDebitors={filterDebitors}
          debitorTaskType={debitorTaskType}
          items={debitors}
        />
        <h3 className="no-margin pad-btm">{'Debitoren'}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={debitors.filter(item => filterTasksByTaskType(item, debitorTaskType))}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`Debitor`}
          itemDeleteable
          deleteTitle={`Debitoraufgabe löschen`}
          getDeleteText={(item) => `Wollen Sie diese Aufgabe unwiderruflich löschen?`}
          onDelete={this.onDelete}
          showItemDeleteReason
        />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebitorTaskList);
