import React from 'react';
import PropTypes from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import { Dialog } from 'primereact/dialog';
import PagePathForm from '../components/PagePathForm';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { savePagePath, cancelPagePath } from "../actions";
import { PAGE_TYPES } from '../components/Utils';

const mapStateToProps = (state) => {
  return {
    isVisible: state.staticPage.showPathDialog,
    parentUrl: state.staticPage.parentUrl,
    linkData: state.staticPage.currentLinkData,
    config: state.staticPage.currentLinkDialogConfig
  };
};
const mapDispatchToProps = (dispatch) => ({
  savePath: (...args) => dispatch(savePagePath(...args)),
  cancelPath: (...args) => dispatch(cancelPagePath(...args))
});

class PageCreationDialog extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    parentUrl: PropTypes.string,
    linkData: PropTypes.shape({}),
    config: PropTypes.shape({}),
    savePath: PropTypes.func.isRequired,
    cancelPath: PropTypes.func.isRequired,
  }
  onHide = () => {
    this.props.cancelPath();
  }
  onSave = () => {
    const data = this.form.getModel();
    if (!data) {
      return;
    }
    const { parentUrl, savePath } = this.props;
    const { type, isProductDetailsPage } = this.props.config || {};
    savePath({ data, parentUrl, type, isProductDetailsPage });
  }
  render() {
    const { isVisible, parentUrl, linkData } = this.props;
    const config = this.props.config || {};
    if (!isVisible) {
      return null;
    }
    console.log('PageCreationDialog.render()', this.props)
    // header
    const isProduct = (config.type === PAGE_TYPES.PRODUCT);
    let title = isProduct ? 'Neues Produkt' : 'Neue Seite';
    if (linkData && linkData.url) {
      title = `${isProduct ? 'Produkt' : 'Seiten'}link bearbeiten`;
    }
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    // footer
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton className="margin-lft" onClick={() => this.onSave()}>
          {'Speichern'}
        </PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onHide()}>
          {'Abbrechen'}
        </SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}
      >
        <PagePathForm
          {...config}
          ref={ref => this.form = ref}
          parentUrl={parentUrl}
          model={linkData}
          isEditing={isPlainObject(linkData)/* linkData !== null */}
        />
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageCreationDialog);

