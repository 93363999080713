import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DebitorItem from '../DebitorItem';
import { WizardButtonPanel } from '../../../../components/Buttons';
import DebitorOrderOverview from '../DebitorOrderOverview';

export default class TaskModemOverviewContent extends React.Component {
  static propTypes = {
    // mapped from state
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    // actions
    updateOrderDebitor: PropTypes.func.isRequired,
    // passed by parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    rows: 12
  }
  openCatalog = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    const debitorId = match.params.debitorId;
    history.replace(`/wizard/aufgabe/bestellung/modem/${id}/debitor/${debitorId}/katalog`);
  }
  onUpdate = () => {
    const { newModems, selectedDebitor, updateOrderDebitor } = this.props;
    updateOrderDebitor({ debitor: selectedDebitor, modems: newModems, isTask: true }, this.openDebitors);
  }
  render() {
    const { className, selectedDebitor, newModems, cancelOrder, rows } = this.props;
    console.log('TaskModemOverviewContent.render()', selectedDebitor);
    if (!selectedDebitor || !newModems) {
      return null;
    }
    return (
      <div className={`modem-shopping-cart clearfix`}>
        <DebitorOrderOverview
          className={className}
          title="Debitor Übersicht"
          hideDebitorSubtitle
          debitor={selectedDebitor}
          modems={newModems}/>
        <WizardButtonPanel
          className="margin-big-top w3-border pad-big w3-light-grey"
          onPrevious={this.openCatalog}
          onNext={this.onUpdate}
          onCancel={cancelOrder}
          nextLabel="Speichern"
          disableIfAbsent
        />
      </div>
    );
  }
}
