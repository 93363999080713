import React from 'react';
import PropTypes from 'prop-types';
// import isArray from 'lodash/isArray';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { InputText } from 'primereact/inputtext';
import {
  COMPANY_STATUS_IDS,
  COMPANY_STATUS_LABELS,
  COMPANY_TYPE_LABELS,
  companyHasActiveEmployees,
  companyHasEmployees,
  createSortById,
  getCompanyDeleteText,
  isAdmin,
  isCompanyActivated,
  isCompanyDeactivated,
  isContractor,
  // isLogisticsAssistant,
  scrollToTop,
  TableEmptyMessage
} from '../../../components/Utils';
import {
  EditIconNavButton,
  ActivateIconButton,
  DeactivateIconButton,
  DeleteIconButton
} from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import SalesTableUtil from '../../Reporting/components/SalesTableUtil';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadFilteredCompanies,
  activateCompany,
  deactivateCompany,
  deleteCompany,
  defaultFilter
} from '../actions';


const getFilterValues = (props, current) => {
  let values =  { ...defaultFilter };
  if (current) {
    values = {
      ...values,
      rows: (props.rows || defaultFilter.rows),
      ...current
    };
  }
  return values;
};


const frontload = async (props) => {
  const filterValues = getFilterValues(props);
  await props.getCompanies(null, filterValues, true)
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    user: state.auth.userProfile,
    company: state.company.company,
    isLoading: state.company.loading,
    companies: state.company.companies,
    header: state.company.header
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompanies: (...args) => dispatch(loadFilteredCompanies(...args)),
  activateCompany: (...args) => dispatch(activateCompany(...args)),
  deactivateCompany: (...args) => dispatch(deactivateCompany(...args)),
  deleteCompany: (...args) => dispatch(deleteCompany(...args)),
});

class CompanyTable extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    user: PropTypes.shape({}),
    company: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    companies: PropTypes.array,
    header: PropTypes.object,
    getCompanies: PropTypes.func.isRequired,
    activateCompany: PropTypes.func.isRequired,
    deactivateCompany: PropTypes.func.isRequired,
    deleteCompany: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    logisticAssistant: PropTypes.bool
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor(props) {
    super();
    this.filterValues = getFilterValues(props);
    this.filterTimerId = null;
    this.state = {
      first: 0,
      filteredCount: (props.companies || []).length
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onEditCompany = (company, history) => {
    console.log('Editing', company);
    history.push(`/wizard/organisation/firma/${encodeURIComponent(company.id)}`);
  }
  onDeactivateCompany = (company) => {
    console.log('CompanyTable.deactivateCompany()', company);
    this.dialog.show(
      true,
      {
        title: 'Servicepartner deaktivieren',
        text: `Wollen Sie den Servicepartner ${company.name} wirklich deaktivieren?`
      },
      () => this.props.deactivateCompany(company, this.filterValues, true)
    );
  }
  onActivateCompany = (company) => {
    console.log('CompanyTable.activateCompany()', company);
    this.dialog.show(
      true,
      {
        title: 'Servicepartner aktivieren',
        text: `Wollen Sie den Servicepartner ${company.name} wirklich aktivieren?`
      },
      () => this.props.activateCompany(company, this.filterValues, true)
    );
  }
  onDeleteCompany = (company) => {
    this.dialog.show(
      true,
      {
        title: 'Servicepartner löschen',
        text: getCompanyDeleteText(company),
      },
      () => this.props.deleteCompany(company, this.filterValues, true)
    );
  }
  specialSort = (user, companies) => {
    const myCompanyName = (user || {}).company || '';
    const myCompany = (companies || []).find(item => item.name === myCompanyName);
    const otherCompanies = (companies || [])
      .filter(item => item.name !== myCompanyName)
      // sort by id in descending order
      .sort(createSortById(true));
    return myCompany ? [myCompany, ...otherCompanies] : otherCompanies;
  }
  actionTemplate = (rowData) => {
    const { isAdmin, logisticAssistant, company } = this.props;
    if (logisticAssistant && isContractor(company)) {
      return null;
    }
    const editId = `editCompanyBtn${rowData.id}`;
    const editBtn = (
      <EditIconNavButton
        id={editId}
        name={editId}
        onClick={(history) => this.onEditCompany(rowData, history)}
      />
    );
    const actId = `actCompanyBtn${rowData.id}`;
    const activateBtn = isAdmin && isCompanyDeactivated(rowData) && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivateCompany(rowData)}
      />
    );
    const deactId = `deactCompanyBtn${rowData.id}`;
    const deactivateBtn = isAdmin && isCompanyActivated(rowData) && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivateCompany(rowData)}
        disabled={companyHasActiveEmployees(rowData)}
      />
    );
    const deleteId = `deleteCompanyBtn${rowData.id}`;
    const deleteBtn = isAdmin && (
      <DeleteIconButton
        id={deleteId}
        name={deleteId}
        onClick={() => this.onDeleteCompany(rowData)}
        disabled={!isCompanyDeactivated(rowData)/* companyHasEmployees(rowData) */}
      />
    );
    return (
      <div>
        {editBtn}
        {activateBtn}
        {deactivateBtn}
        {deleteBtn}
      </div>
    );
  }
  onLazyLoad = (event) => {
    console.log("CompanyTable.onLazyLoad()", event);
    const filterValues = { ...this.filterValues };
    filterValues.first = event.first;
    filterValues.rows = event.rows;
    this.props.getCompanies(
      () => this.setState({ first: event.first }),
      filterValues,
      true
    );
  }
  onValueChange = (nextValue) => {
    console.log('CompanyTable.onValueChange()', nextValue);
    if (nextValue) {
      this.setState({
        first: 0,
        filteredCount: nextValue.length
      });
    }
  }
  onFilterChange = (field, value, noTimeout, noFilter) => {
    // console.log('CompanyTable.onFilterChange()', field, value, noTimeout);
    this.filterValues[field] = value;
    if (noTimeout) {
      (!noFilter) && this.setState({first: 0});
      return (!noFilter) && this.props.getCompanies(null, this.filterValues, true);
    }
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const self = this;
    this.filterTimerId = setTimeout(() => {
      console.log('CompanyTable.onFilterChange(): filterValues', self.filterValues);
      (!noFilter) && self.setState({first: 0});
      (!noFilter) && self.props.getCompanies(null, self.filterValues, true);
    }, 1000);
  }
  onSort = (event) => {
    console.log("CompanyTable.onSort()", event);
    // this.filterValues.sortFields = event.multiSortMeta.map(item => ({...item}));
    this.filterValues.sortFields = {};
    event.multiSortMeta.forEach(item => {
      this.filterValues.sortFields[item.field] = item.order;
    });
    this.props.getCompanies(null, { ...this.filterValues }, true);
  }
  render() {
    const { className, isLoading, user, companies, rows, header } = this.props;
    if (!companies) return null;

    const totalRecords = Number(header ? header["x-result-count"] : companies.length);
    const { first, filteredCount } = this.state;
    // console.log('CompanyTable.render()', user, totalRecords, companies);
    let filteredCompanies = companies;
    // if user is company and Logistics Assistant
    // if (logisticAssistant) {
    //   console.log('>> Current User:', user, companies);
    //   // get companies where the user has the required role
    //   const selectedList = user.extendedRoles
    //     .filter(item => item.label.toLowerCase() === 'logistikverantwortlicher')
    //     .map(item => item.companyName);
    //   filteredCompanies = filteredCompanies.filter(item => selectedList.includes(item.name));
    // }
    // filteredCompanies = this.specialSort(user, filteredCompanies);
    const data = filteredCompanies.map(company => ({
      ...company,
      name: company.name.trim(),
      contractGiver: company.contractGiver,
      typeName: company.typeName || 'KDL',
      address: company.address,
      status_id: company.status_id || COMPANY_STATUS_IDS.ACTIVATED,
      status: company.status || COMPANY_STATUS_LABELS[`${company.status_id || COMPANY_STATUS_IDS.ACTIVATED}`],
    }));

    // options map
    const typeOptions = Object.keys(COMPANY_TYPE_LABELS).map(key => {
      return { label: COMPANY_TYPE_LABELS[key], value: COMPANY_TYPE_LABELS[key] };
    }).filter (item => item.label !== COMPANY_TYPE_LABELS["4"]);
    const isUserAdmin = isAdmin(user);
    let typeName = typeOptions.filter(item => (isUserAdmin ? true : item.value !== COMPANY_TYPE_LABELS['1']));
    if (data.length === 1) {
      typeName = typeName.filter(item => item.value === data[0].typeName);
    }
    const status_id = [
      { label: COMPANY_STATUS_LABELS[COMPANY_STATUS_IDS.ACTIVATED], value: COMPANY_STATUS_IDS.ACTIVATED },
      { label: COMPANY_STATUS_LABELS[COMPANY_STATUS_IDS.DEACTIVATED], value: COMPANY_STATUS_IDS.DEACTIVATED }
    ];
    const optionMap = { typeName, status_id };

    // filters
    const useInputFilter = SalesTableUtil.createUseInputFilter(this.dt, 400, this.onFilterChange);
    const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(this.dt, data, optionMap, this.onFilterChange);
    const nameFilter = useInputFilter('name', { filterOnChange: true });
    // const contractGiverFilter = useInputFilter('contractGiver', { filterOnChange: true });
    const typeNameFilter = useMultiSelectFilter('typeName'); // useInputFilter('typeName'); //
    const addressFilter = useInputFilter('address', { filterOnChange: true });
    // const activeEmployeesFilter = useInputFilter('activeEmployees', { filterOnChange: true });
    const statusFilter = useMultiSelectFilter('status_id');

    // const sortFields = this.filterValues.sortFields;
    const sortFields = data.length <= 1
      ? []
      : Object.keys(this.filterValues.sortFields).map(
        field => ({ field, order: this.filterValues.sortFields[field]})
      );
    return (
      <div className={`company-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          ref={ref => this.dt = ref}
          onValueChange={this.onValueChange}
          first={first}
          lazy={true}
          totalRecords={totalRecords}
          onPage={(event) => this.onLazyLoad(event)}
          paginator
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={totalRecords}
              filteredRecords={filteredCount}
              first={first}
              rows={rows}
              emptyText="Keine Servicepartner"
              itemName="Servicepartner"
              itemNamePlural="Servicepartner"
            />
          }
          removableSort
          sortMode="multiple"
          multiSortMeta={sortFields}
          onSort={this.onSort}
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Servicepartner"/>)}
        >
          <Column
            field="name"
            header="Firma"
            sortable={data.length > 1}
            filter
            {...nameFilter}
          />
          {/* <Column
            field="contractGiver"
            header="Auftraggeber"
            sortable={data.length > 1}
            filter
            {...contractGiverFilter}
          /> */}
          <Column
            field="typeName"
            header="Firmentyp"
            sortable={data.length > 1}
            filter
            {...typeNameFilter}
            style={{ width: '9em' }}
          />
          <Column
            field="address"
            header="Adresse"
            filter
            {...addressFilter}
          />
          <Column
            field="activeEmployees" // "totalEmployees"
            header="Aktive Mitarbeiter"
            // sortable={data.length > 1}
            // filter
            // {...activeEmployeesFilter}
            style={{textAlign:'center', width: '9em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter
            {...statusFilter}
            body={rowData => rowData.status}
            style={{textAlign:'center', width: '9em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{ textAlign: 'center', width: '10em' }}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(CompanyTable)
);
