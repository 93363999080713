import React from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadNewsMessage } from '../actions';
import MessageForm from "../components/MessageForm";
import MediaUploadForm from "../../../components/MediaUploadForm";
import { FormMenu } from "../../../components/Buttons";

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getNewsMessage({ id });
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    message: state.news.message
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNewsMessage: (...args) => dispatch(loadNewsMessage(...args))
});

class NewsArchiveMessage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    message: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    getNewsMessage: PropTypes.func,
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  onCancel = () => {
    this.props.history.replace(`/organisation/news-service/archiv`)
  }
  render() {
    const { className, message } = this.props;
    console.log("NewsArchiveMessage.render()", message);
    if (!message) {
      return null;
    }
    return (
      <div className={`news-archive-message clearfix ${className}`}>
        <MessageForm
          className="pad-big w3-border"
          model={message}
          readOnly
        />
        <MediaUploadForm
          ref={ref => this.mediaForm = ref}
          className="margin-big-top pad-big w3-border"
          title={`Anhänger`}
          itemRowClassName="neg-margin-lr"
          itemColClassName="w3-col s3"
          model={{ files: message.attachments }}
          // max={null}
          showFileName
          readOnly
          titleIsLabel
        />
        <FormMenu
          className="w3-border margin-big-top pad-big w3-light-grey"
          onCancel={this.onCancel}
          cancelBtnLabel="Schließen"
          cancelBtnName="closeMessageBtn"
          saveHidden
        />
      </div>
    );
  }
}

// export default NewsArchiveMessage;
export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(NewsArchiveMessage)
);
