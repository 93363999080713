import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { ClipBoardContent, pasteFromClipboard } from '../ClipBoard';

export default class ClipBoardDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onConfirm: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      onConfirm: props.onConfirm,
      isVisible: false,
      selected: [],
    }
  }
  show = (isVisible, onConfirm) => {
    this.setState({
      onConfirm,
      isVisible,
      selected: []
    });
  }
  onHide = () => {
    this.setState({isVisible: false});
  }
  onClick = (isYes) => {
    if (!isYes) {
      return this.onHide();
    }
    const { selected } = this.state;
    if (!selected || selected.length === 0) {
      return this.messages.replace({
        severity: 'error',
        summary: 'Sie müssen mindestins einen Inhalt auswählen',
        life: 1000 * 10,
        closable: true
      });
    }
    this.messages.clear();
    this.onHide();
    if (isYes && this.state.onConfirm) {
      this.state.onConfirm(pasteFromClipboard(selected));
    }
  }
  onSelect = (selected) => {
    this.setState({ selected });
  }
  render() {
    const { isVisible, selected } = this.state;
    const footer = (
      <div className="alg-right">
        <PrimaryButton className="margin-lft" onClick={() => this.onClick(true)}>{`Einfügen`}</PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onClick(false)}>{`Abbrechen`}</SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={'Zwischenablage'}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '400px'}}
        modal={true}
        onHide={this.onHide}
      >
        <Messages ref={(el) => this.messages = el} />
        <ClipBoardContent
          selected={selected}
          onSelect={this.onSelect}
        />
      </Dialog>
    );
  }
}
