import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import ModemTypeForm from './ModemTypeForm';
import {
  PRODUCT_STATUS_LABELS,
  PRODUCT_STATUS_IDS,
  getProductStatusChangeConfirmationText,
  isProductDeleted,
  isProductActivated,
  isProductDeactivated
} from '../../../../components/Utils';
import { selectCard } from '../../../Product/actions/cards';


export default class ModemTypeListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    rows: PropTypes.number,
    readOnly: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 5
  }
  validate = () => {
    const modemTypes = this.modemTypes.getModel();
    const model = [ ...modemTypes ];
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <ModemType
          ref={(ref) => this.modemTypes = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class ModemType extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    rows: PropTypes.number,
    readOnly: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: props.model.map((item, index) => ({ ...item, index }))
    };
  }
  getModel = () => {
    const { items } = this.state;
    const model = items.map(item => {
      const { id, name, shortname, packageSize, description, imagePath, material_number, status_id } = item;
      const modemType = { id, name, shortname, packageSize, description, imagePath, material_number, status_id };
      return modemType;
    });
    return model;
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving modemType ...', model, this.state.items);
    if (!model) return;
    const items = this.state.items;
    const newState = {};
    if (model.index !== undefined/*  && items[model.index] !== undefined */) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    newState.items = items;
    console.log('<< new modemTypes', newState.items);
    this.setState(newState);
    onHide && onHide();
  }
  onDelete = (selected) => {
    console.log('>> deleting modemtyp from list', selected);
    const items = [ ...this.state.items ];
    items.splice(selected.index, 1);
    // const items = [];
    // for (const index in this.state.items) {
    //   console.log('>> delete: comparing index', index, 'with selected index', selected.index);
    //   if (index != selected.index) {
    //     items.push(this.state.items[index]);
    //   }
    // }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  onDeActivate = (selected, activated) => {
    console.log(`>> ${activated ? '' : 'de'}activating modemtyp`, selected);
    const { items } = this.state;
    items[selected.index].status_id = activated ? PRODUCT_STATUS_IDS.ACTIVATED : PRODUCT_STATUS_IDS.DEACTIVATED;
    items[selected.index].status = PRODUCT_STATUS_LABELS[items[selected.index].status_id];
    this.setState({ items });
  }
  render() {
    // console.log('ModemTypeListForm.render()', this.state);
    const { className, rows, readOnly } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => {
        return {
          ...item,
          index,
          status: item.status || PRODUCT_STATUS_LABELS[item.status_id || PRODUCT_STATUS_IDS.ACTIVATED]
        };
      });
      const statusBodyTemplate = (rowData) => (
        <span className={`status-text product-status-text-${rowData.status_id}`}>{rowData.status}</span>
      );
      return (
        <DataTable
          // key={Math.random()}
          value={data}
          paginator={data.length > rows}
          rows={rows}
        >
          <Column
            field="material_number"
            header="Materialnummer"
            sortable
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          />
          <Column
            field="name"
            header="Name"
            sortable
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="shortname"
            header="Kurzname"
            sortable
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="packageSize"
            header="Paketstückanzahl"
            sortable
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          />
          <Column
            field="status_id"
            header="Status"
            filter={data.length > rows}
            filterMatchMode="contains"
            // filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{width: '10em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '10.6em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const modemType = item || {
        name: '',
        shortname: '',
        packageSize: '',
        description: '',
        imagePath: '',
        material_number: '',
        status_id: 1
      };
      console.log('ModemTypeListForm.renderItemForm()', modemType);
      return (
        <div>
          <ModemTypeForm ref={(ref) => this.form = ref} key={Math.random()} model={modemType}/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveModemTypeBtn"
            cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}
            saveHidden={readOnly}
          />
        </div>
      );
    };
    const getItemFormTitle = (modemType) => {
      return (<h3 className="no-margin">{`ModemType ${modemType ? 'bearbeiten' : 'hinzufügen'}`}</h3>);
    }
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">ModemTypen</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addModemTypeBtn"
        addBtnDisabled={readOnly}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="modemType"
        itemVisibleOnly={readOnly}
        itemDeleteable={false/* !readOnly */}
        deleteTitle="ModemTyp löschen"
        getDeleteText={(item) => getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.DELETED, 'den ModemTyp', item.name)}
        // isDeleteDisabled={(item) => false}
        onDelete={this.onDelete}
        itemDeactivateable={!readOnly}
        activateTitle="ModemTyp aktivieren"
        getActivateText={(item) => getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.ACTIVATED, 'den ModemTyp', item.name)}
        isActivateDisabled={(item) => isProductActivated(item)}
        deactivateTitle="ModemTyp deaktivieren"
        getDeactivateText={(item) => getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.DEACTIVATED, 'den ModemTyp', item.name)}
        isDeactivateDisabled={(item) => isProductDeactivated(item) || isProductDeleted(item)}
        onDeActivate={this.onDeActivate}
      />
    );
  }
}
