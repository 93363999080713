
import Slider1 from './media/Slider1.png';
import Slider2 from './media/Slider2.png';
import Slider3 from './media/Slider3.png';
import Slider4 from './media/SliderAN.png';

export const TESTING = true;

const IMAGES = {
  home: [
    { file: Slider4 },
    // { file: Slider1 },
    // { file: Slider2 },
    // { file: Slider3 }
  ],
  user: [
    { file: Slider4 },
    // { file: Slider1 },
    // { file: Slider2 },
    // { file: Slider3 }
  ]
}
export const getImages = (type) => {
  const images = IMAGES[type] || [];
  return [ ...images ];
};
