import React from "react";
import { Calendar as PrimeCalendar } from "primereact/calendar";

const de = {
  firstDayOfWeek: 1,
  dayNames: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"
  ],
  dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthNames: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "März",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Okt",
    "Nov",
    "Dez"
  ],
  today: "Heute",
  clear: "Leeren",
  dateFormat: "dd.mm.yy",
  weekHeader: "W"
};

const Calendar = (props) => (
  <PrimeCalendar
    locale={de}
    dateFormat={de.dateFormat}
    monthNavigator
    yearNavigator
    yearRange="2000:2030"
    appendTo={document.body}
    {...props}
  />
);

export default Calendar;
