import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";

export default class MNVTipGiverForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		updateable: PropTypes.bool,
		companies: PropTypes.array,
		workers: PropTypes.array,
		getWorkers: PropTypes.func.isRequired,
	};
	static defaultProps = {
		className: "",
		model: {},
		companies: [],
		workers: [],
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		this.props.updateable && this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		return {
			companyId: model.company || "",
			technicianId: model.technicianId || "",
			orderNumber: model.orderNumber || "",
			error: {
				companyId: null,
				technicianId: null,
				orderNumber: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!isValid) {
			this.setState({ error });
			return null;
		}

		// set company name
		let found = this.props.companies.find(
			(item) => item.id === model.companyId
		);
		if (found) {
			model.companyName = found.name;
		}
		// set technician name
		found = this.props.workers.find((item) => item.id === model.technicianId);
		if (found) {
			model.technicianName = found.username;
		}
		return model;
	};
	getModel = (noValidate) => {
		const model = this.validate(noValidate);
		return model;
	};
	setCompany = (companyId) => {
		const { companies, getWorkers } = this.props;
		this.setState({ companyId });
		const found = companies.find((item) => item.id === companyId);
		if (found) {
			const companyName = found.name;
			getWorkers({ companyName });
		}
	};
	render() {
		const { className, companies, workers } = this.props;
		const { companyId, technicianId, orderNumber, error } = this.state;
		const CompanyOptions = companies.map((item) => ({
			label: item.name,
			value: item.id,
		}));
		const WorkerOptions = workers.map((item) => ({
			label: `${item.username}`,
			value: item.id,
		}));
		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.companyId ? "error-group" : ""
							}`}
						>
							<Label htmlFor="companyId">{`KDL*`}</Label>
							<Dropdown
								id="companyId"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								options={CompanyOptions}
								value={companyId}
								onChange={(event) => this.setCompany(event.value)}
								showClear
							/>
						</div>
						<div
							className={`pad-top form-group-item ${
								error.technicianId ? "error-group" : ""
							}`}
						>
							<Label htmlFor="technicianId">{`Techniker*`}</Label>
							<Dropdown
								id="technicianId"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								options={WorkerOptions}
								value={technicianId}
								onChange={(event) =>
									this.setState({ technicianId: event.value })
								}
								showClear
							/>
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.orderNumber ? "error-group" : ""
							}`}
						>
							<Label htmlFor="orderNumber">{`Auftragsnummer*`}</Label>
							<InputText
								id="orderNumber"
								className="w3-block w3-border"
								value={orderNumber}
								onChange={(event) =>
									this.setState({ orderNumber: event.target.value })
								}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
