import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label } from '../../../components/Inputs';
import { PrimaryButton, DeleteIconButton, UpIconButton, DownIconButton } from '../../../components/Buttons';
// import ListItem from '../../Static/components/content/DownloadListItemForm';

export default class DeviceForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      name: '',
      checkInterval: 24,
      description: '',
      options: []
    },
    companies: []
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      name: model.name || '',
      checkInterval: model.checkInterval || 24,
      description: model.description || '',
      options: model.options || [],
      error: {
        name: null,
        checkInterval: null
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    if (this.props.model.id) {
      model.id = this.props.model.id;
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  changeOption = (index, value) => {
    console.log('changeOption()', index, value);
    const { options } = this.state;
    if (index >= 0 && index < options.length) {
      options[index] = value;
    }
    this.setState({ options });
  }
  addOption = () => {
    const { options } = this.state;
    options.push('');
    this.setState({ options });
  }
  removeOption = (index) => {
    const { options } = this.state;
    options.splice(index, 1);
    this.setState({ options });
  }
  move = (currentIndex, up) => {
    const { options } = this.state;
    const targetIndex = up ? currentIndex - 1 : currentIndex + 1;
    const src = options[currentIndex];
    options[currentIndex] = options[targetIndex];
    options[targetIndex] = src;
    this.setState({ options });
  }
  render() {
    const { className } = this.props
    const { name, checkInterval, description, options, error } = this.state;
    const optionsMarkup = options.map((option, index) => {
      console.log('Option', index, option);
      return (
        <div key={index} className="w3-row pad-top neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <InputText className="w3-block w3-border" value={option}
              onChange={(event) => this.changeOption(index, event.target.value)}/>
          </div>
          <div className="w3-col s4 pad-lr pad-sm-top">
            {index > 0 && (<UpIconButton className="w3-border margin-rht" onClick={() => this.move(index, true)}/>)}
            {index < options.length - 1 && (<DownIconButton className="w3-border margin-rht" onClick={() => this.move(index, false)}/>)}
            <DeleteIconButton className="w3-border" onClick={() => this.removeOption(index)}/>
          </div>
        </div>
      );
    });
    return (
      <div className={className}>
        <div className="w3-row pad-top neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item${error.name ? ' error-group' : ''}`}>
              <Label htmlFor="name">{`Name*`}</Label>
              <InputText
                id="name"
                className="w3-block w3-border"
                value={name}
                onChange={(event) => this.setState({name: event.target.value})}
              />
            </div>
            <div className={`pad-top form-group-item${error.checkInterval ? ' error-group' : ''}`}>
              <Label htmlFor="checkInterval">{`Prüffrist (in Monate)*`}</Label>
              <InputText
                id="checkInterval"
                className="w3-block w3-border"
                value={checkInterval}
                type="number"
                onChange={(event) => this.setState({checkInterval: event.target.value})}
              />
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item${error.checkInterval ? ' error-group' : ''}`}>
              <Label htmlFor="description">{`Bemerkungen`}</Label>
              <InputTextarea
                id= "description"
                className="w3-block w3-border"
                value={description}
                style={{height: '116px'}}
                autoResize={false}
                onChange={(event) => this.setState({description: event.target.value})}
              />
            </div>
          </div>
        </div>
        <div className="w3-row pad-big-top neg-margin-lr ">
          <div className="w3-col s6 pad-lr">
            <h4 className="no-margin">{`Messgerätoptionen`}</h4>
          </div>
          <div className="w3-col s2 pad-lr">
            <PrimaryButton className="w3-block" size="small" onClick={this.addOption}>{'Hinzufügen'}</PrimaryButton>
          </div>
        </div>
        {optionsMarkup}
      </div>
    );
  }
}
