import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton, SecondaryButton } from './Buttons';
import DescriptionForm from './DescriptionForm';

export default class ConfirmationDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    showReason: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      onConfirm: props.onConfirm,
      showReason: props.showReason,
      isVisible: false
    }
  }
  show = (isVisible, data, onConfirm) => {
    const newState = data || {};
    this.setState({
      ...newState,
      onConfirm,
      isVisible
    });
  }
  onHide = () => {
    this.setState({isVisible: false});
  }
  onClick = (isYes) => {
    this.onHide();
    if (isYes && this.state.onConfirm) {
      const reason = this.form ? this.form.getModel() : null;
      const description = reason ? reason.description : null;
      this.state.onConfirm(description ? { description } : null);
    }
  }
  render() {
    const { title, text, isVisible, showReason } = this.state;
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton className="margin-lft" onClick={() => this.onClick(true)}>Ja</PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onClick(false)}>Nein</SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}>
        <div className="w3-large">{text}</div>
        {showReason && (
          <DescriptionForm
            ref={ref => this.form = ref}
            className="pad-top"
            label="Grund"
          />
        )}
      </Dialog>
    );
  }
}
