
const AppLinks = [
	{ to: "/start", label: "Start", exact: true },
	{}, // technikLink,
	{}, // salesLink,
	{
		to: "/shop",
		label: "Web Shops",
		isVisibleForFlag: "isShopViewer",
		subMenu: {
			className: "nav-level-1",
			links: [
				{
					to: "/shop/modems",
					label: "Modem-Logistik",
					isVisibleForFlag: "isLogisticsAssistant",
					subMenu: {
						className: "nav-level-2",
						links: [
							{
								to: "/shop/modems/bestellungen",
								label: "Bestellungen",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/modems/uebersicht",
								label: "Übersicht",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/modems/produkte",
								label: "Produktverwaltung",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/modems/debitor-mapping",
								label: "Debitor-Mapping",
								isVisibleForFlag: "isAdmin"
							},
						],
					},
				},
				{
					to: "/shop/schliessmittel",
					label: "Schließmittel",
					isVisibleForFlag: "isLogisticsKeys",
					subMenu: {
						className: "nav-level-2",
						links: [
							{
								to: "/shop/schliessmittel/bestellungen",
								label: "Bestellungen",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/schliessmittel/uebersicht",
								label: "Übersicht",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/schliessmittel/produkte",
								label: "Produktverwaltung",
								isVisibleForFlag: "isAdmin"
							},
						],
					},
				},
				{
					to: "/shop/karten",
					label: "Karten/Flyer",
					isVisibleForFlag: "isLogisticsCards",
					comments: "Karten und Flyer bestellen und Bestellungen verwalten",
					subMenu: {
						className: "nav-level-2",
						links: [
							{
								to: "/shop/karten/bestellungen",
								label: "Bestellungen",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/karten/uebersicht",
								label: "Übersicht",
								isVisibleForFlag: "isAdmin"
							},
							{
								to: "/shop/karten/produkte",
								label: "Produktverwaltung",
								isVisibleForFlag: "isAdmin"
							},
						],
					},
				},
			],
		},
	},
	{}, // organisationLink
  {
    to: "/news-service",
    label: "News",
    subMenu: {
      className: "nav-level-1",
      links: [
        {
          to: "/news-service/home",
          label: "Übersicht",
        },
        {
          to: "/news-service/nachrichten",
          label: "News Einstellen",
          isVisibleForFlag: "isAdmin",
        },
        {
          to: "/news-service/protokolle",
          label: "News Versandprotokolle",
          isVisibleForFlag: "isAdmin",
        },
        {
          to: "/news-service/beitraege",
          label: "News Beiträge",
          isVisibleForFlag: "isAdmin",
        },
        {
          to: "/news-service/archiv",
          label: "Ad-hoc News Archiv",
          isVisibleForFlag: "isAdmin",
        },
      ],
    },
  },
	{
		to: "/aufgaben",
		label: "Aufgaben",
		isVisibleForFlag: "isUserTaskManager",
		subMenu: {
			className: "nav-level-1",
			links: [
				{ to: "/aufgaben/debitoren", label: "Debitoren", isVisibleForFlag: "isAdmin" },
				{
					to: "/aufgaben/it-zugaenge",
					label: "IT-Zugänge",
					isVisibleForFlag: "isAdmin",
					subMenu: {
						className: "nav-level-2",
						links: [
							{ to: "/aufgaben/it-zugaenge/ad-zugaenge", label: "AD-Zugänge" },
							{ to: "/aufgaben/it-zugaenge/pt-zugaenge", label: "PT-Zugänge" },
							{
								to: "/aufgaben/it-zugaenge/delphi-zugaenge",
								label: "DELPHI-Zugänge",
							},
						],
					},
				},
				{ to: "/aufgaben/messgeraete", label: "Messgeräte", isVisibleForFlag: "isAdmin" },
				{ to: "/aufgaben/modems", label: "Modem-Logistik", isVisibleForFlag: "isAdmin" },
				{ to: "/aufgaben/schliessmittel", label: "Schließmittel", isVisibleForFlag: "isAdmin" },
				{ to: "/aufgaben/karten", label: "Karten/Flyer", isVisibleForFlag: "isAdmin" },
				{ to: "/aufgaben/reports", label: "Reports", isVisibleForFlag: "isAdmin" },
				{
					to: "/aufgaben/benutzer",
					label: "Benutzerverwaltung",
					isVisibleForFlag: "isUserTaskManager",
					subMenu: {
						className: "nav-level-2",
						links: [
							{
								to: "/aufgaben/benutzer/aenderung-kontaktdaten",
								label: "Änderung Kontaktdaten",
								isVisibleForFlag: "isProfileChangeManager",
							},
							// {
							// 	to: "/aufgaben/benutzer/unversandte-emails",
							// 	label: "Unversandte E-Mails",
							// 	isVisibleForFlag: "isUserTaskManager",
							// },
							{
								to: "/aufgaben/benutzer/unversandte-emails-firmen",
								label: "Unversandte E-Mails: Firmen",
								isVisibleForFlag: "isFailedCompanyEmailManager",
							},
							{
								to: "/aufgaben/benutzer/unversandte-emails-benutzer",
								label: "Unversandte E-Mails: Benutzer",
								isVisibleForFlag: "isFailedUserEmailManager",
							},
						],
					}
				},
			],
		},
	},
];

export default AppLinks;
