import React from 'react';
import PropTypes from 'prop-types';
import SalesTable from '../components/SalesTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import FormDialog from '../../../components/FormDialog';
import SaleStatusForm from '../components/SaleStatusForm';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadSales, clearSales, setSalesColumns, updateSale, deleteSale } from '../actions/reporting';
import { scrollToTop } from '../../../components/Utils';

const getFilterValues = (props, current) => {
  let values = { sortFields: {} };
  props.salesColumns.forEach(column => {
    values[column] = null;
  });
  if (current) {
    values = { ...values, ...current };
  }
  return values;
};

const frontload = async (props) => {
  const filterValues = getFilterValues(props);
  await props.getSales(filterValues, { options: true })
};
const mapStateToProps = (state) => {
  return {
    isSalesReportEditor: state.auth.isSalesReportEditor,
    loading: state.report.loading,
    error: state.report.salesError,
    sales: state.report.sales,
    salesColumns: state.report.salesColumns,
    header: state.report.header,
    salesOptions: state.report.salesOptions,
    isFullScreen: state.report.isFullScreen,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSales: (...args) => dispatch(loadSales(...args)),
  clearSales: (...args) => dispatch(clearSales(...args)),
  setSalesColumns: (...args) => dispatch(setSalesColumns(...args)),
  updateSale: (...args) => dispatch(updateSale(...args)),
  deleteSale: (...args) => dispatch(deleteSale(...args)),
});

class SalesTableContainer extends React.Component {
  static propTypes = {
    isSalesReportEditor: PropTypes.bool,
    sales: PropTypes.array,
    salesColumns: PropTypes.array,
    salesOptions: PropTypes.shape({}),
    rows: PropTypes.number,
    className: PropTypes.string,
    getSales: PropTypes.func.isRequired,
    clearSales: PropTypes.func.isRequired,
    setSalesColumns: PropTypes.func.isRequired,
    updateSale: PropTypes.func.isRequired,
    deleteSale: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    header: PropTypes.object,
    isFullScreen: PropTypes.bool,
    children: PropTypes.node
  }
  static defaultProps = {
    rows: 50,
    className: '',
    loading: false,
    error: false
  }
  constructor(props) {
    super();
    this.filterValues = /* this. */getFilterValues(props);
    this.filterTimerId = null;
  }
  componentDidUpdate() {
    console.log('SalesTableContainer.componentDidUpdate()', this.props.salesColumns, this.filterValues);
    this.state = /* this. */getFilterValues(this.props, this.filterValues);
    scrollToTop();
  }
  // getFilterValues = (props, current) => {
  //   let values = { sortFields: {} };
  //   props.salesColumns.forEach(column => {
  //     values[column] = null;
  //   });
  //   if (current) {
  //     values = { ...values, ...current };
  //   }
  //   return values;
  // }
  componentWillUnmount() {
    this.props.clearSales();
  }
  onEditSale = (sale) => {
    console.log('Editing', sale, this.form);
    this.formDialog.show(
      true,
      {
        title: `Absatz bearbeiten`,
        body: (
          <SaleStatusForm
            model={sale}
            ref={ref => this.form = ref}
          />
        )
      },
      () => {
        console.log('Updating sale', this.form);
        const model = this.form ? this.form.getModel() : null;
        model && this.props.updateSale({ sale: model });
      }
    );
  }
  onDeleteSale = (sale) => {
    console.log('Deleting', sale);
    this.confirmDialog.show(
      true, {
        title: `Absatz löschen`,
        text: `Wollen Sie den Absatz (weborder ID: ${sale.weborder_id}, Sales Agent: ${sale.sales_agent}) wirklich löschen?`
      },
      () => this.props.deleteSale({ sale })
    );
  }
  onFilterChange = (field, value, noTimeout, noFilter) => {
    console.log('SalesTable.onFilterChange()', field, value, noTimeout);
    this.filterValues[field] = value;
    if (noTimeout) {
      return (!noFilter) && this.props.getSales(this.filterValues);
    }
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const self = this;
    this.filterTimerId = setTimeout(() => {
      console.log('SalesTable.onFilterChange(): filterValues', self.filterValues);
      (!noFilter) && self.props.getSales(self.filterValues);
    }, 1000);
  }
  onSort = (event) => {
    const currentFields = this.filterValues.sortFields;
    currentFields[event.sortField] = (currentFields[event.sortField] === 1) ? -1 : 1;
    this.filterValues.sortFields = currentFields;
    console.log('SalesTableContainer.onSort()', this.filterValues);
    this.props.getSales({ ...this.filterValues });
  }
  getSales = (data) => {
    let filterValues = { ...this.filterValues };
    if (data) {
      filterValues = { ...filterValues, ...data };
    }
    this.props.getSales(filterValues);
  }
  // onResize = () => {
  //   console.log('SalesTable.onResize()');
  //   if (this.resizeTimerId) {
  //     clearTimeout(this.resizeTimerId);
  //   }
  //   const self = this;
  //   this.resizeTimerId = setTimeout(() => {
  //     console.log('SalesTable.onResize() => rerender ...');
  //     self.forceUpdate();
  //   }, 1000);
  // }
  render() {
    console.log('SalesTableContainer.render()');
    const {
      className, sales, isSalesReportEditor, salesColumns, rows,
      loading, error, header, salesOptions,
      isFullScreen, children
    } = this.props;
    if (error) {
      return (
        <div className="w3-border pad-big alg-center error-group">
          <div className="">
            Beim Laden der Reporting-Tabelle ist ein Fehler aufgetreten.
            Bitte versuchen Sie es später erneut.
          </div>
        </div>
      );
    }
    const data = sales.map((sale, index) => ({  
      ...sale, index
    }));
    // const data = [{ region: '1', index: 0}];
    const style = !isFullScreen ? {} : {
      position: 'fixed',
      top: '72px',
      left: '0',
      zIndex: 2,
      width: '100%',
      height: 'calc(100vh - 72px)',
      // overflowY: 'scroll',
      background: '#fff'
    };
    return (
      <div id="salesTableDiv" style={style}>
        {children}
        <SalesTable
          className={className}
          isSalesReportEditor={isSalesReportEditor}
          loading={loading}
          value={data}
          rows={rows}
          onEdit={this.onEditSale}
          onDelete={this.onDeleteSale}
          salesColumns={salesColumns}
          setSalesColumns={this.props.setSalesColumns}
          header={header}
          salesOptions={salesOptions}
          getSales={this.getSales}
          onFilterChange={this.onFilterChange}
          onSort={this.onSort}
        />
        <FormDialog ref={ref => this.formDialog = ref} style={{width: '400px'}}/>
        <ConfirmationDialog ref={ref => this.confirmDialog = ref}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(SalesTableContainer)
);
