// import { InputText } from 'primereact/inputtext';
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { InputText, InputTextHolder } from '../../../../components/Inputs';

const InputTextFilter = ({ field, onChange, showClear, clearTitle = 'Filter löschen', filterOnChange = false }) => {
  const [value, setValue] = useState("");

  const handleChange = useCallback((event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(field, event.target.value, !filterOnChange, !filterOnChange);
    }
  }, []);

  const handleEnter = useCallback((event) => {
    if (event.keyCode === 13 && onChange) {
      onChange(field, event.target.value, true);
    }
  }, []);

  const handleClear = useCallback(() => {
    const val = '';
    setValue(val);
    if (onChange) {
      onChange(field, val, true);
    }
  }, []);
  return (
    <InputTextHolder
      onClear={handleClear}
      title={clearTitle}
    >
      <InputText
        className="w3-block w3-border -p-column-filter"
        value={value}
        onChange={handleChange}
        onKeyUp={handleEnter}
      />
    </InputTextHolder>
  );
};

InputTextFilter.propTypes = {
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default React.memo(InputTextFilter);
