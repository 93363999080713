import superagent from 'superagent';
// import config from '../config';

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  // if (__SERVER__) {
  //   // Prepend host and port of the API server to the path.
  //   return 'http://' + config.apiHost + ':' + config.apiPort + adjustedPath;
  // }
  // Prepend `/api` to relative URL, to proxy to API server.
  return '/data-api' + adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach((method) =>
      this[method] = (path, { params, data } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(path));
        if (params) {
          request.query(params);
        }
        // // read the CSRF token from the <meta> tag and set as custom header against CSRF
        // const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        // request.set('CSRF-Token', token);

        // if (__SERVER__ && req.get('cookie')) {
        //   request.set('cookie', req.get('cookie'));
        // }
        if (data) {
          request.send(data);
        }
        request.end((err, { body, header } = {}) => {
          if (err) {
            if (body) {
              return reject({body, header});
            }else {
              return reject({body: err, header})
            }
            // return reject(body || err);
          } else { 
            resolve({body, header}); 
          }
        });
      }));
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() { }
}
