import React from 'react';
import InPageNavMenu from "../../../../components/InPageNavMenu";
import { PageHeight20, PageHeight80 } from '../../../../components/Utils';
import DebitorReports from '../../components/reports/debitors';
import { MyTechieReports } from '../../components/reports/myTechie';

export default function ReportTaskList({
  className,
  showMenu = false,
}) {

  // render menu
	const renderMenu = () => {
		const links = [
			{ isVisible: true, to: "my-techie", label: "My Techie" },
			{ isVisible: true, to: "debitoren", label: "Debitoren" },
		];
		return (
			<InPageNavMenu
				className="fixed-form-menu"
				links={links}
				initialActiveId="my-techie"
			/>
		);
	};

  // render body
  const renderBody = () => {
		return (
			<PageHeight80>
				<PageHeight20
					id="my-techie"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">{`MyTechie`}</h3>
          <MyTechieReports />
				</PageHeight20>
				{/* <PageHeight20
					id="debitoren"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">{`Debitoren`}</h3>
          <DebitorReports/>
				</PageHeight20> */}
			</PageHeight80>
		);
  };

  const body = renderBody();
  if (showMenu) {
    const menu = renderMenu();
    return (
      <div className="w3-row neg-margin-lr">
        <div className="w3-col m10 pad-lr">{body}</div>
        <div className="w3-col m2 w3-show-large pad-lr">{menu}</div>
      </div>
    );
  }
  return (<div className={`report-task-list clearfix ${className}`}>{body}</div>);
}
