import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import {
  PageHeight30,
  WizardTitle,
  notifyError,
  DEVICE_STATUS_IDS,
  DEVICE_ACTION_IDS
} from '../../../components/Utils';
import UserDeviceForm from '../components/UserDeviceForm';
import UserDeviceFormMenu from '../components/UserDeviceFormMenu';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  reset,
  loadUserDevice,
  loadDevices,
  cancelUserDevice,
  saveUserDevice,
  loadUserDeviceCompanies,
  loadUserDeviceWorkers
} from '../actions';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getUserDevice({ id });
  await props.getDevices();
  await props.getCompanies({ isManager: true });
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAdmin: state.auth.isAdmin,
    isLoading: state.device.loading,
    userDevice: state.device.userDevice,
    devices: state.device.devices,
    deviceManagerCompanies: state.device.deviceManagerCompanies,
    deviceManagers: state.device.deviceManagers,
    companies: state.device.deviceUserCompanies,
    workers: state.device.deviceUsers,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUserDevice: (...args) => dispatch(loadUserDevice(...args)),
  getDevices: (...args) => dispatch(loadDevices(...args)),
  getCompanies: (...args) => dispatch(loadUserDeviceCompanies(...args)),
  getWorkers: (...args) => dispatch(loadUserDeviceWorkers(...args)),
  resetAll: (...args) => dispatch(reset(...args)),
  cancelUserDevice: (...args) => dispatch(cancelUserDevice(...args)),
  saveUserDevice: (...args) => dispatch(saveUserDevice(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    devices: PropTypes.array,
    deviceManagerCompanies: PropTypes.array,
    deviceManagers: PropTypes.array,
    companies: PropTypes.array,
    workers: PropTypes.array,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getUserDevice: PropTypes.func.isRequired,
    getDevices: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getWorkers: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    cancelUserDevice: PropTypes.func.isRequired,
    saveUserDevice: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount() {
    this.props.cancelUserDevice();
  }
  onCancel = () => {
    console.log('UserDeviceWizard.onCancel()');
    const { cancelUserDevice, history } = this.props;
    cancelUserDevice();
    history.replace('/organisation/messgeraete/mitarbeiter');
  }
  onSave = (updating, saveOnly, deleting) => {
    // console.log('UserDeviceWizard.onSave() updating', updating);
    const model = this.form.getModel();
    if (!model) {
      return notifyError({
        summary: 'Messgeräte',
        detail: `Das Messgerät konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    // model.status_id = DEVICE_STATUS_IDS.FINISHED;
    if (!saveOnly && model.macAddress !== '') {
      if (updating) {
        model.status_id = DEVICE_STATUS_IDS.UPDATING;
        model.action_id = DEVICE_ACTION_IDS.UPDATE
      } else if (deleting) {
        model.status_id = DEVICE_STATUS_IDS.DELETING;
        model.action_id = DEVICE_ACTION_IDS.DELETE_ACCOUNT
      } else {
        model.status_id = DEVICE_STATUS_IDS.NEW;
        model.action_id = DEVICE_ACTION_IDS.CREATE;
      }
    } else {
      if (!model.status_id) {
        model.status_id = DEVICE_STATUS_IDS.FINISHED;
      }
    }
    const {
      device, serialNumber, options, regions,
      userInChargeCompanyName, userInChargeCompanyId, companyName, companyId, contractGiver,
      userInCharge, userPrimary, userSecondary, userInChargeId, userPrimaryId, userSecondaryId,
      isCalibrated, comparismDevice, macAddress, lastCheckDate, nextCheckDate, modifyDate,
      locationText, account, id, status_id, action_id, status, oldStatusId
    } = model;
    const userDevice = {
      device, serialNumber, options, regions,
      userInChargeCompanyName, userInChargeCompanyId, companyName, companyId, contractGiver,
      userInCharge, userPrimary, userSecondary, userInChargeId, userPrimaryId,
      isCalibrated: isCalibrated ? 1 : 0, comparismDevice, macAddress, lastCheckDate, nextCheckDate, modifyDate,
      locationText, account, status_id, action_id, status, oldStatusId
    };
    if (id !== undefined) {
      userDevice.id = id;
    }
    if (!isNaN(userSecondaryId) && userSecondaryId > 0) {
      userDevice.userSecondaryId = userSecondaryId;
    }

    console.log('Saving Model:', userDevice, 'updating:', updating);
    const { history } = this.props;
    const onSuccess = () => history.replace('/organisation/messgeraete/mitarbeiter');
    this.props.saveUserDevice(userDevice, onSuccess, updating);
  }
  onMacAddressChanged = (value) => {
    if (this.menuTop) this.menuTop.onMacAddressChanged(value);
    if (this.menuBtm) this.menuBtm.onMacAddressChanged(value);
  }
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li><NavLink to={`/daten`} className="disabled-link" activeClassName="active">{'Allgemeine Daten'}</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const {
      className, isLoading, userDevice, devices,
      deviceManagerCompanies, deviceManagers, companies, workers,
      getWorkers, getCompanies, resetAll, isAdmin, user
    } = this.props;
    // console.log('UserDeviceWizard.render()', this.props);
    const Menu = this.renderMenu();
    if (!userDevice || !deviceManagerCompanies || deviceManagerCompanies.length === 0) return null;
    return (
      <div className={`modem-order-wizard clearfix ${className}`}>
        <WizardTitle
          type="userDevice"
          model={userDevice}
          menu={
            <UserDeviceFormMenu
              ref={ref => this.menuTop = ref}
              className="pad-big-top"
              onSave={() => this.onSave()}
              onUpdate={() => this.onSave(true)}
              onCancel={this.onCancel}
              onChange={() => this.onSave(true, true)}
              onDelete={() => this.onSave(false, false, true)}
              saveBtnName="saveUserDeviceBtn2"
              updateBtnName="updateUserDeviceBtn2"
              changeBtnName="changeUserDeviceBtn2"
              deleteBtnName="deleteUserDeviceBtn2"
              changeDisabled={false}
              deleteDisabled={false}
              userDevice={userDevice}
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30 id="/daten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <UserDeviceForm
                // key={Math.random()}
                ref={ref => this.form = ref}
                isLoading={isLoading}
                model={userDevice}
                devices={devices}
                deviceManagerCompanies={deviceManagerCompanies}
                deviceManagers={deviceManagers}
                companies={companies}
                users={workers}
                isAdmin={isAdmin}
                // currentWorker={isAdmin ? null : user}
                getCompanies={getCompanies}
                getWorkers={getWorkers}
                reset={resetAll}
                onMacAddressChanged={this.onMacAddressChanged}
                optionalPrimaryUser
              />
            </PageHeight30>
            <UserDeviceFormMenu
              ref={ref => this.menuBtm = ref}
              className="pad-big-top"
              onSave={() => this.onSave()}
              onUpdate={() => this.onSave(true)}
              onCancel={this.onCancel}
              onChange={() => this.onSave(true, true)}
              onDelete={() => this.onSave(false, false, true)}
              saveBtnName="saveUserDeviceBtn"
              updateBtnName="updateUserDeviceBtn"
              changeBtnName="changeUserDeviceBtn"
              deleteBtnName="deleteUserDeviceBtn"
              changeDisabled={false}
              deleteDisabled={false}
              userDevice={userDevice}
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
