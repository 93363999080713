
import { loadOpenTasks } from '.';

export const LOAD_EMPLOYEE_TASKS = 'task/employee/changes/load';
export const LOAD_EMPLOYEE_TASKS_SUCCESS = 'task/employee/changes/load/success';
export const LOAD_EMPLOYEE_TASKS_FAILURE = 'task/employee/changes/load/failure';

export const SET_SELECTED_EMPLOYEE_TASKS = 'task/employee/changes/set';

export const CLOSE_EMPLOYEE_TASK = 'tasks/employee/changes/close';
export const CLOSE_EMPLOYEE_TASK_SUCCESS = 'tasks/employee/changes/close/success';
export const CLOSE_EMPLOYEE_TASK_FAILURE = 'tasks/employee/changes/close/failure';

export const CLOSE_EMPLOYEE_TASKS = 'tasks/employees/changes/close';
export const CLOSE_EMPLOYEE_TASKS_SUCCESS = 'tasks/employees/changes/close/success';
export const CLOSE_EMPLOYEE_TASKS_FAILURE = 'tasks/employees/changes/close/failure';

export const EXPORT_EMPLOYEE_TASKS = 'tasks/employees/changes/export';
export const EXPORT_EMPLOYEE_TASKS_SUCCESS = 'tasks/employees/changes/export/success';
export const EXPORT_EMPLOYEE_TASKS_FAILURE = 'tasks/employees/changes/export/failure';

export const LOAD_FAILED_EMAIL_TASKS = 'task/failedEmail/tasks/load';
export const LOAD_FAILED_EMAIL_TASKS_SUCCESS = 'task/failedEmail/tasks/load/success';
export const LOAD_FAILED_EMAIL_TASKS_FAILURE = 'task/failedEmail/tasks/load/failure';

export const SET_SELECTED_FAILED_EMAIL_TASKS = 'task/failedEmail/tasks/set';

export const CLOSE_FAILED_EMAIL_TASK = 'tasks/failedEmail/tasks/close';
export const CLOSE_FAILED_EMAIL_TASK_SUCCESS = 'tasks/failedEmail/tasks/close/success';
export const CLOSE_FAILED_EMAIL_TASK_FAILURE = 'tasks/failedEmail/tasks/close/failure';

export const DEACTIVATE_USER_TASK = 'tasks/failedEmail/user/deactivate';
export const DEACTIVATE_USER_TASK_SUCCESS = 'tasks/failedEmail/user/deactivate/success';
export const DEACTIVATE_USER_TASK_FAILURE = 'tasks/failedEmail/user/deactivate/failure';


export const loadEmployeeTasks = () => {
  return {
    types: [
      LOAD_EMPLOYEE_TASKS,
      LOAD_EMPLOYEE_TASKS_SUCCESS,
      LOAD_EMPLOYEE_TASKS_FAILURE
    ],
    promise: (client) => client.get(`/employee/changes/tasks`)
  }
};

export const setSelectedEmployeeTasks = (selected) => {
  console.log(`REDUX-ACTION: setSelectedEmployeeTasks()`, selected);
  return {
    type: SET_SELECTED_EMPLOYEE_TASKS,
    selected
  };
};

export const closeEmployeeTask = ({ task }, onSuccess) => {
  // console.log(`REDUX-ACTION: closeEmployee()`, task);
  const type = "USER_PROFILE_CHANGE_CONFIRM";
  const data = [ task ];
  return {
    types: [
      CLOSE_EMPLOYEE_TASK,
      CLOSE_EMPLOYEE_TASK_SUCCESS,
      CLOSE_EMPLOYEE_TASK_FAILURE
    ],
    promise: (client) => client.post(`/companies/connections/${type}`, { data }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    task
  };
};

export const closeEmployeeTasks = ({ tasks }, onSuccess) => {
  // console.log(`REDUX-ACTION: closeEmployees()`, tasks);
  const type = "USER_PROFILE_CHANGE_CONFIRM";
  const data = tasks;
  return {
    types: [
      CLOSE_EMPLOYEE_TASKS,
      CLOSE_EMPLOYEE_TASKS_SUCCESS,
      CLOSE_EMPLOYEE_TASKS_FAILURE
    ],
    promise: (client) => client.post(`/companies/connections/${type}`, { data }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    tasks
  };
};

export const exportEmployeeTasks  = () => {
  // console.log(`REDUX-ACTION: exportEmployeeTasks()`);
  const type = "USER_PROFILE_CHANGE_EXPORT";
  return (dispatch, getState) => {
    const { employeeTasks } = getState().task;
    const data = [ ...employeeTasks ];
    dispatch({
      types: [
        EXPORT_EMPLOYEE_TASKS,
        EXPORT_EMPLOYEE_TASKS_SUCCESS,
        EXPORT_EMPLOYEE_TASKS_FAILURE
      ],
      promise: client => client.post(`/companies/load/csv/tasks/${type}`, { data }),
      // onSuccess: dispatch => dispatch(loadOpenTasks()),
    });
  }
};


export const loadFailedEmailTasks = () => {
  return {
    types: [
      LOAD_FAILED_EMAIL_TASKS,
      LOAD_FAILED_EMAIL_TASKS_SUCCESS,
      LOAD_FAILED_EMAIL_TASKS_FAILURE
    ],
    promise: (client) => client.get(`/employee/failedEmail/tasks`)
  }
};

export const setSelectedFailedEmailTasks = (selected) => {
  // console.log(`REDUX-ACTION: setSelectedFailedEmailTasks()`, selected);
  return {
    type: SET_SELECTED_FAILED_EMAIL_TASKS,
    selected
  };
};

export const closeFailedEmailTask = ({ task }, onSuccess) => {
  return {
    types: [
      CLOSE_FAILED_EMAIL_TASK,
      CLOSE_FAILED_EMAIL_TASK_SUCCESS,
      CLOSE_FAILED_EMAIL_TASK_FAILURE
    ],
    promise: (client) => client.put(`/companies/changeEmails/${task.origin}`, { data: task }),
    onSuccess: (dispatch) => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    task
  };
};

export const deactivateUser = ({ task }, onSuccess) => {
  const taskType = "DELETE_IT_CONNECTIONS";
  return {
    types: [
      DEACTIVATE_USER_TASK,
      DEACTIVATE_USER_TASK_SUCCESS,
      DEACTIVATE_USER_TASK_FAILURE
    ],
    promise: client => client.post(`/companies/connections/${taskType}`, { data: [task] }),
    onSuccess: (dispatch) => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    task
  };
};
