
const specialChildren = [
	{
		label: "Reporting-Portal",
		url: '/sales/reporting-portal',
		component: "reporting",
		isVisibleForFlag: "isSalesViewer",
	},
	{
		label: "Meine Leistungen",
		url: "/sales/leistungen",
		component: "achievements",
		isVisibleForFlag: "isSalesAchievementsViewer",
		children: [
			{
				label: "Meine Erfolge",
				url: "/sales/leistungen/meine-erfolge",
				component: "success",
				isVisibleForFlag: "isSalesSuccessViewer",
			},
			{
				label: "Absatz je Mitarbeiter",
				url: "/sales/leistungen/absatz-je-mitarbeiter",
				component: "workerSales",
				isVisibleForFlag: "isSalesWorkerSalesViewer",
			},
			// { label: 'Conversionreport', component: 'conversionReport', isVisibleForFlag: 'isSalesConversionReportViewer' },
		],
	},
	{
		label: "Produktverwaltung",
		url: "/sales/produktverwaltung",
		component: "products",
		isVisibleForFlag: "isSalesImporter",
	},
	{
		label: "Buchungsanforderung",
		url: "/sales/buchungsanforderung",
		component: "booking",
		isVisibleForFlag: "isSalesBookingViewer",
	},
];

export const buildSalesTree = (tree) => {
  const salesTree = JSON.parse(JSON.stringify(tree));
  salesTree.children.splice(1, 0, ...specialChildren);
  // console.log('buildSalesTree() input:', tree, 'output:', salesTree);
  return salesTree;
};
