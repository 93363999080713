import React from 'react';
import PropTypes from 'prop-types';
import TimeRangeFilter from '../components/TimeRangeFilter';
import DelphiNrFilter from '../components/DelphiNrFilter';
import FilterChoice from '../components/FilterChoice';
import { PrimaryButton } from '../../../components/Buttons';

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { getFormData, setFilterType, showDisplay, setTimeRange } from "../actions/success";

const frontload = async (props) => {
	await props.init({});
};
const mapStateToProps = (state) => {
	return {
    filterType: state.achievement.filterType,
    delphiNrOptions: state.achievement.delphiNrOptions
	};
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(getFormData(...args)),
	setFilter: (...args) => dispatch(setFilterType(...args)),
	showSales: (...args) => dispatch(showDisplay(...args)),
  intervalChanged: (...args) => dispatch(setTimeRange(...args)),
});

class FilterPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filterType: PropTypes.string.isRequired,
    delphiNrOptions: PropTypes.array,
    init: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    showSales: PropTypes.func.isRequired,
    intervalChanged: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  constructor() {
    super();
    this.state = {
      isFormValid: false
    };
  }
  onFilter = () => {
    const model = this.filter ? this.filter.getModel() : null;
    if (!model) {
      return;
    }
    // console.log('>> showing sales ...', model);
    this.props.showSales(model);
  }
  toggleFormState = (isFormValid) => {
    // console.log('FilterPanel.toggleFormState()', isFormValid);
    this.setState({ isFormValid });
  }
  render() {
    const { className, filterType, setFilter, delphiNrOptions, intervalChanged } = this.props;
    const Filter = (filterType === 'range') ? TimeRangeFilter : DelphiNrFilter;
    return (
      <div className={`${className}`}>
        <FilterChoice
          className="pad-big"
          filterType={filterType}
          setFilter={setFilter}
        />
        <div className="pad-big w3-border-top">
          <Filter
            ref={ref => this.filter = ref}
            delphiNrOptions={delphiNrOptions}
            toggleFormState={this.toggleFormState}
            intervalChanged={intervalChanged}
          />
        </div>
        <div className="pad-big  w3-border-top w3-light-grey alg-right">
          <PrimaryButton
            onClick={this.onFilter}
            disabled={!this.state.isFormValid}
          >
            {`Filter anwenden`}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(FilterPanel)
);