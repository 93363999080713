import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditIconNavButton, DeleteIconNavButton } from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { createSortById, scrollToTop, TableEmptyMessage } from '../../../components/Utils';
// import SalesTableUtil from '../../Reporting/components/SalesTableUtil';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadDevices, loadDependentUserDevices } from '../actions';

const frontload = async (props) => (
  await props.getDevices()
);
const mapStateToProps = (state) => {
  return {
    isLoading: state.device.loading,
    user: state.auth.user,
    devices: state.device.devices
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDevices: (...args) => dispatch(loadDevices(...args)),
  getUserDevices: (...args) => dispatch(loadDependentUserDevices(...args))
});

class DeviceTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    devices: PropTypes.array,
    onLogin: PropTypes.func,
    rows: PropTypes.number,
    getDevices: PropTypes.func.isRequired,
    getUserDevices: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: '',
    namePrefix: ''
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onEditDevice = (device, history) => {
    console.log('>> Editing', device);
    history.push(`/wizard/organisation/messgeraet/admin/${encodeURIComponent(device.name)}`);
  }
  onDeleteDevice = (device) => {
    console.log('>> Deleting', device);
    this.props.getUserDevices(device);
  }
  actionTemplate = (rowData/* , column */) => {
    const { namePrefix } = this.props;
    return (
      <div>
        <div className="w3-cell w3-cell-top">
          <EditIconNavButton
            id={`${namePrefix}editBtn${rowData.id || rowData.index || rowData.name || ''}`}
            onClick={(history) => this.onEditDevice(rowData, history)}
          />
        </div>
        <div className="w3-cell w3-cell-top">
          <DeleteIconNavButton
            // className="pad"
            id={`${namePrefix}deleteBtn${rowData.id || rowData.index || rowData.name || ''}`}
            onClick={() => this.onDeleteDevice(rowData)}
          />
        </div>
      </div>
    );
  }
  render() {
    const { className, isLoading, devices, rows } = this.props;
    // console.log('DeviceTable.render()', devices);
    const data = devices.map(device => ({
      ...device, optionsStr: (device.options ? device.options.join(', ') : '')
    }))
    // sort by id in descending order
    .sort(createSortById(true));
    return (
      <div className={`device-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginator={data.length > rows}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Messgeräte"
              itemName="Messgerät"
              itemNamePlural="Messgeräte"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Referenzmessgeräte"/>)}
        >
          <Column
            field="name"
            header="Messgerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="optionsStr"
            header="Optionen"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.confirm = ref}/>
        {/* <div id={`refDeviceTableOverlay`} className={`w3-modal w3-${this.props.isLoading ? 'show' : 'hide'}`}/> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(DeviceTable)
);
