import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AdHocNewsRecipentForm from './AdHocNewsRecipentForm';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import { TableEmptyMessage } from '../../../../components/Utils';


export default class AdHocNewsRecipentListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string
    })),
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    namePrefix: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10,
    namePrefix: "",
  }
  validate = () => {
    const recipients = this.recipients.getModel();
    const model = [ ...recipients ];
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <AdHocNewsRecipentList
          ref={(ref) => this.recipients = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class AdHocNewsRecipentList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string
    })),
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    namePrefix: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    model: [],
    namePrefix: ''
  }
  constructor(props) {
    super(props);
    const items = (props.model || []);
    this.state = {
      items: items.map((item, index) => ({ ...item, index })),
      error: null,
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    return [ ...items ];
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving recipient ...', model);
    if (!model) return;
    const items = this.state.items;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
    onHide && onHide();
  }
  onDelete = (selected) => {
    // console.log('>> deleting', selected);
    const items = [];
    this.state.items.forEach(item => {
      // console.log('>>>> comparing', item.name, 'with', selected.name);
      if (item.index !== selected.index) {
        // console.log('<<<< adding item', item, '...');
        items.push(item);
      }
    });
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, rows, disabled, namePrefix } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const renderItemTable = (items, actionTemplate) => {
      return (
        <DataTable
          value={items}
          rows={rows}
          paginator={items.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={items.length}
              filteredRecords={items.length}
              first={first}
              rows={rows}
              emptyText="Keine Einträge"
              itemName="Eintrag"
              itemNamePlural="Einträge"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Einträge" min/>)}
        >
          <Column
            field="name"
            header="Name"
            sortable={items.length > 1}
            filter={items.length > 1}
            filterMatchMode="contains"
          />
          <Column
            field="email"
            header="E-Mail"
            sortable={items.length > 1}
            filter={items.length > 1}
            filterMatchMode="contains"
          />
          {!disabled && (
            <Column
              body={actionTemplate}
              style={{textAlign:'center', width: '8em'}}
            />
          )}
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const model = item || {
        name: '',
        email: '',
      };
      return (
        <div>
          <AdHocNewsRecipentForm
            ref={(ref) => this.form = ref}
            key={Math.random()}
            model={model}
            disabled={disabled}
            namePrefix={namePrefix}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey" 
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)}
            onCancel={onHide}
            saveBtnName="saveRecipientBtn"
          />
        </div>
      );
    };
    const getItemFormTitle = (model) => {
      return (<h3 className="no-margin">{`News Verteilereintrag ${model ? 'bearbeiten' : 'hinzufügen'}`}</h3>);
    }
    const titleClassName = `no-margin-top`;
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className={titleClassName}>{"Ad-hoc News Verteiler"}</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName={`${namePrefix}addRecipientBtn`}
        // addBtnDisabled={this.state.addDisabled}
        addBtnDisabled={disabled}
        addBtnHidden={disabled}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="region"
        itemDeleteable={!disabled}
        deleteTitle="News Verteilereintrag löschen"
        getDeleteText={(item) => `Wollen Sie den Eintrag (${item.name}, ${item.email}) unwiderruflich löschen?`}
        onDelete={this.onDelete}
      />
    );
  }
}