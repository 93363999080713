import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from './content/ConfirmationDialog';
import { Accordion, AccordionTab } from '../../../components/Accordion';
// import { InputText, Label } from '../../../components/Inputs';
import { DeleteIconButton, UpIconButton, DownIconButton, CopyIconButton } from '../../../components/Buttons';
import BasicPageContentForm from './content/BasicPageContentForm';
import {
  ContentMenu, ContentMenuLabelMap,
  getContentTypeFromId, getContentForm,
  getContentDeleteConfirmationText,
  isContentFromClipboard
} from './Utils';
import { copyToClipboard, getClipboardSize, isClipboardEmpty, pasteFromClipboard } from '../ClipBoard';
import { notifyInfo, notifySuccess } from '../../../components/Utils';
import ClipBoardDialog from './ClipBoardDialog';

export default class PageForm extends React.Component {
  static propTypes = {
    pageId: PropTypes.string,
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      title: '',
      subtitle: '',
      contents: []
    },
  }
  constructor(props) {
    super(props);
    const { model } = props;
    const contents = model.contents || []
    this.state = {
      contents,
      activeIndex: 0,
      draggable: false, 
    };
    this.isTabOpenList = contents.map(() => false);
  }
  validate = () => {
    const { contents } = this.state;
    let isValid = true;
    // basic data
    const model = this.basicData.getModel();
    if (!model) {
      return null
    };
    // content list
    model.contents = [];
    contents.forEach((item, index) => {
      if (this[`form${index}`]) {
        let data = this[`form${index}`].getModel();
        if (!data) {
          isValid = false;
          data = item;
        }
        model.contents.push({ ...data, position: (index + 1) });
      }
    });
    if (!isValid) {
      // this.setState({ contents: model.contents });
      return null;
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    if (!model) {
      return null;
    }
    // set model id if present
    const { id } = this.props.model;
    if (id) {
      model.id = id;
      model.contents = model.contents.map(content => ({
        ...content,
        page_id: id
      }));
    }
    return model;
  }
  getCurrentTabContent = (index) => {
    // const { activeIndex } = this.state;
    const activeIndex = (index !== undefined && index !== null) ? index : this.state.activeIndex;
    if (activeIndex !== null && this[`form${activeIndex}`]) {
      return this[`form${activeIndex}`].getModel(true);
    }
    return null;
  }
  updateCurrentContent = () => {
    const { activeIndex } = this.state;
    // get current tab content before closing it
    const oldContents = this.state.contents;
    if (activeIndex !== null) {
      // get current tab content
      const currentData = this.getCurrentTabContent();
      oldContents[activeIndex] = JSON.parse(JSON.stringify(currentData));
    }
    // check list of open contents
    this.isTabOpenList.forEach((isOpen, index) => {
      if (isOpen) {
        const currentData = this.getCurrentTabContent(index);
        if (currentData) {
          oldContents[index] = JSON.parse(JSON.stringify(currentData));
        }
      }
    });
    return oldContents;
  }
  changeTab = (newIndex) => {
    const { activeIndex } = this.state;
    // get current tab content before closing it
    if (this[`form${activeIndex}`]) {
      const src = this[`form${activeIndex}`].getModel(true);
      const contents = [];
      this.state.contents.forEach((item, index) => {
        if (index === activeIndex) {
          contents.push({ ...item, ...src });
        } else {
          contents.push(item);
        }
      });
      // update state
      this.setState({ contents, activeIndex: newIndex });
    }
  }
  onOpenTabsChanged = (index, isOpen) => {
    this.isTabOpenList[index] = isOpen;
  }
  onDraggable = (value) => {
    // get current tab content before closing it
    const oldContents = this.updateCurrentContent();
    // update state
    this.setState({
      contents: oldContents,
      draggable: value
    });
  }
  addContent = (content_type_id, position, type) => {
    // console.log('PageForm.addContent()', content_type_id, position);
    // update old contents
    const oldContents = this.updateCurrentContent();
    // add new content
    const newItemIndex = position ? (position - 1) : contents.length - 1;
    const handleAddition = (items) => {
      oldContents.splice(position - 1, 0, ...items);
      this.setState(() => ({
        contents: oldContents,
        activeIndex: newItemIndex
      }));
    };
    if (isContentFromClipboard(type)) {
      if (isClipboardEmpty()) {
        return notifyInfo({
          summary: 'Zwischenablage is leer',
          detail: 'Es kann nichts hinzugefügt werden!'
        });
      } else if (getClipboardSize() === 1) {
        return handleAddition([ pasteFromClipboard() ]);
      }
      this.clipboardDialog.show(true, handleAddition);
    } else {
      handleAddition([
        {
          content_type_id,
          title: '',
          subtitle: '',
          body: ''
        }
      ]);
    }
  }
  confirmContentRemoval = (type, currentIndex) => {
    const confirmData = getContentDeleteConfirmationText(type);
    if (confirmData) {
      this.dialog.show(
        true,
        confirmData,
        () => this.removeContent(currentIndex)
      );
    }
  }
  removeContent = (currentIndex) => {
    // console.log('PageForm.removeContent()', currentIndex);
    const oldContents = this.updateCurrentContent();
    // remove item at currentIndex
    const contents = [];
    oldContents.forEach((item, index) => {
      if (index !== currentIndex) {
        contents.push(item);
      }
    });
    this.setState(() => ({ contents }));
  }
  move = (currentIndex, up) => {
    const targetIndex = up ? currentIndex - 1 : currentIndex + 1;
    const oldContents = this.updateCurrentContent();
    // build new content
    const contents = [];
    const src = this[`form${currentIndex}`].getModel(true);
    let tgt = this[`form${targetIndex}`].getModel(true);
    tgt = { ...oldContents[targetIndex], ...tgt };
    for (let index = 0; index < oldContents.length; index ++) {
      if (index === currentIndex) {
        contents.push(tgt);
      } else if (index === targetIndex) {
        contents.push(src);
      } else {
        contents.push({ ...oldContents[index] });
      }
    }
    this.setState(() => ({
      contents,
      activeIndex: targetIndex
    }));
  }
  onItemDragged = (srcIndex, tgtIndex) => {
    const { contents, activeIndex } = this.state;
    // update old contents if necessary
    if (activeIndex) {
      // get current tab content
      const currentData = this.getCurrentTabContent();
      contents[activeIndex] = { ...contents[activeIndex], ...currentData };
    }
    // move item from srcIndex to tgtIndex
    const items = contents.splice(srcIndex, 1);
    contents.splice(tgtIndex, 0, ...items);
    this.setState(() => ({ contents }));
  }
  copy = (index) => {
    const data = this[`form${index}`].getModel(true);
    const num = copyToClipboard(JSON.parse(JSON.stringify(data)));
    notifySuccess({
      summary: 'Zwischenablage',
      detail: `Der Inhalt wurde in die Zwischenablage hinzugefügt. Sie enthält nun ${num} Element${num > 0 ? 'e' : ''}`
    });
  }
  render() {
    const { className, model, pageId } = this.props
    const { contents, activeIndex, draggable } = this.state;
    const prefix = Math.random();
    const isTabOpenList = this.isTabOpenList;
    // console.log('PageForm.render() - activeIndex', activeIndex, 'contents', contents);
    const createRefMaker = index => ref => this[`form${index}`] = ref;
    const bodyMarkup = contents
      .filter(item => item !== null)
      .map((item, index) => {
        const { content_type_id, ...model } = item;
        const type = getContentTypeFromId(content_type_id);
        const Component = getContentForm(type);
        if (!Component) {
          console.error('<< Content Type Error: unknown content type', type, item);
          return null;
        }
        const menu = (
          <div className="display-inline-block- clearfix">
            <UpIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.move(index, true)}
              disabled={!(contents.length > 1 && index > 0)}
            />
            <DownIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.move(index, false)}
              disabled={!(contents.length > 1 && index < contents.length - 1)}
            />
            <CopyIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.copy(index)}
              title="in die Zwischenablage kopieren"
            />
            <DeleteIconButton
              className="w3-border pad-sm"
              onClick={() => this.confirmContentRemoval(type, index)}
              
            />
          </div>
        );
        const counter = `${index < 9 ? '0' : ''}${index + 1}. `;
        return (
          <AccordionTab
            key={`${prefix}-${index}`}
            header={counter + ContentMenuLabelMap[type]}
            menu={draggable ? null : menu}
            className={`${draggable ? '' : 'margin-top margin-lr'}`}
            stateOpen={isTabOpenList[index]}
          >
            <Component
              ref={createRefMaker(index)}
              className="w3-border-top-"
              model={model}
              pageId={pageId}
            />
          </AccordionTab>
        );
      });
    const mainKey = draggable ? Math.random() : 'pageForm';
    return (
      <div className={``}>
        <BasicPageContentForm ref={ref => this.basicData = ref} className={className} model={model}/>
        <div className="w3-border w3-light-grey- bg-secondary margin-big-top pad">
          <ContentMenu
            className="w3-right"
            onAddContent={this.addContent}
            positions={contents.length + 1}
            draggable={draggable}
            onChangeDraggable={value => this.onDraggable(value)}
            // onChangeDraggable={value => this.setState({draggable: value})}
          />
          <h4 className="no-margin pad-sm-tb">{'Inhalte'}</h4>
        </div>
        <div className="clearfix margin-big-top pad-btm w3-border " >
          <Accordion
            id="pageContents"
            key={mainKey}
            className="clearfix"
            activeIndex={draggable ? null : activeIndex}
            onTabChange={(event) => this.changeTab(event.index)}
            onItemDragged={this.onItemDragged}
            notDraggable={!draggable}
            singleActiveIndex={false}
            onOpenTabsChanged={this.onOpenTabsChanged}
          >
            {bodyMarkup}
          </Accordion>
        </div>
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
        <ClipBoardDialog ref={ref => this.clipboardDialog = ref}/>
      </div>
    );
  }
}
