import React from 'react';
import ProfileIcon from '../../../components/media/my-vodafone.svg';

export const ProfilePhoto = ({ image }) => {
  return (
    <div className="profile-img-holder">
      <img src={image || ProfileIcon} style={{width: '100%'}}/>
    </div>
  );
};

export const BasicData = ({ className, profile }) => {
  console.log('BasicData.render()', profile);
  if (!profile) return null;
  const showItem = (attribute, index) => (
    <div key={index} className="w3-row">
      <div className="w3-col s4 pad-sm-tb bold">{attribute.name}</div>
      <div className="w3-col s8 pad-sm-tb pad-lft">{attribute.value}</div>
    </div>
  );
  const user = (profile.user || {});
  const appData = [
    { name: 'Benutzername', value: user.username || '' },
    { name: 'Position(en)', value: user.role || (user.roles ? (user.roles.join(', ')) : '') }
  ].map(showItem);
  const attributes = [
    { name: 'ID-Nr.', value: user.idNumber || '' },
    { name: 'VKDG-Ausweis', value: user.vdkId || '' },
    { name: 'Tel.', value: user.telephoneNumber || '' },
    { name: 'Mobil', value: user.cellNumber || '' },
    { name: 'E-Mail', value: user.email || '' }
  ].map(showItem);
  return (
    <div className={`basic-data ${className}`}>
      <div className="section">{attributes}</div>
      <div className="section margin-top w3-border-top">{appData}</div>
    </div>
  );
};

export const BasicDataSummary = ({ username, idNumber, vdkId, telephoneNumber, cellNumber, email }) => {
  const attributes = [
    { name: 'Benutzername', value: username || '' },
    { name: 'ID-Nr.', value: idNumber || '' },
    { name: 'VKDG-Ausweis', value: vdkId || '' },
    { name: 'Tel.', value: telephoneNumber || '' },
    { name: 'Mobil', value: cellNumber || '' },
    { name: 'E-Mail', value: email || '' }
  ].map((attribute, index) => (
    <li key={index}>
      <div className="w3-cell-row">
        <div className="w3-cell w3-cell-middle bold">{attribute.name}</div>
        <div className="w3-cell w3-cell-middle alg-rht">{attribute.value}</div>
      </div>
    </li>
  ));
  return (
    <div className="basic-data-summary"><ul className="w3-ul">{attributes}</ul></div>
  );
};
