import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PublicNav, AdminNav, CompanyNav, TechNav } from './components';

import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  signOut: (...args) => dispatch(logout(...args))
});

class UserNav extends React.Component {
  static propTypes = {
    userFlags: PropTypes.object,
    taskCounter: PropTypes.number,
    history: PropTypes.object,
    signOut: PropTypes.func.isRequired,
  }
  onLogout =  (event) => {
    event.preventDefault();
    const { signOut, history } = this.props;
    signOut(() => history.push('/start'));
  }
  render() {
    const { taskCounter, userFlags: {
      isAdmin,
      isWorkerPlus,
      isWorker,
      isUserTaskManager
    } } = this.props;
    console.log('UserNav.render()', this.props);
    const onClickEvents = {
      onLogout: this.onLogout
    };
    return (
      <div className="user-nav level-0 w3-right">
        <PublicNav isVisible={!isWorker && !isWorkerPlus && !isAdmin}/>
        <TechNav isVisible={isWorker && !isWorkerPlus && !isAdmin} onClickEvents={onClickEvents} />
        <AdminNav
          isVisible={isUserTaskManager}
          counter={{ taskCounter }}
          onClickEvents={onClickEvents}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserNav));
