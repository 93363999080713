import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Crumbs } from "../../BreadCrumbs";
// import { SearchLabelMap } from '../../MainNav';
import { toUIDate } from "../../../components/Utils";

export default class SearchResultItem extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.object,
		labelMap: PropTypes.shape({}).isRequired
	};
	render() {
		const { className, data, labelMap } = this.props;
		// console.log('>> SearchResultItem.render()', data);
		if (!data) return null;
		return (
			<div className={`search-result-item ${className}`}>
				<div className="pad-big w3-hover-light-grey">
					<Link to={data.pathname}>
						<h2 className="no-margin">{data.pageTitle}</h2>
					</Link>
					<div className="clearfix">
						<Crumbs
							className="w3-large"
							pathname={data.pathname}
							labelMap={labelMap}
						/>
						<div className="overlay" />
					</div>
					<div className="w3-text-grey pad-sm-top">
						<span className="bold">{"Letze Änderung: "}</span>
						{toUIDate(data.created_date)}
					</div>
				</div>
			</div>
		);
	}
}
