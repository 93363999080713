
export const LOAD_DEBITOR_MAPPING = 'debitor-mapping/load';
export const LOAD_DEBITOR_MAPPING_SUCCESS = 'debitor-mapping/load/success';
export const LOAD_DEBITOR_MAPPING_FAILURE = 'debitor-mapping/load/failure';

export const EXPORT_DEBITOR_MAPPING = 'debitor-mapping/export';
export const EXPORT_DEBITOR_MAPPING_SUCCESS = 'debitor-mapping/export/success';
export const EXPORT_DEBITOR_MAPPING_FAILURE = 'debitor-mapping/export/failure';


export const loadDebitorMapping = (onSuccess) => {
  return {
    types: [
      LOAD_DEBITOR_MAPPING,
      LOAD_DEBITOR_MAPPING_SUCCESS,
      LOAD_DEBITOR_MAPPING_FAILURE, 
    ],
    promise: client => client.get(`/companies/debitors/mappings`),
    onSuccess: () => {
      onSuccess && onSuccess();
    }
  };
};

export const exportDebitorMapping = (onSuccess) => {
  return {
    types: [
      EXPORT_DEBITOR_MAPPING,
      EXPORT_DEBITOR_MAPPING_SUCCESS,
      EXPORT_DEBITOR_MAPPING_FAILURE, 
    ],
    promise: client => client.get(`/companies/debitors/mappings/export/csv`),
    onSuccess: () => {
      onSuccess && onSuccess();
    }
  };
};
