import ImageSliderModule from './components/ImageSlider';
import HomeImageSliderModule from './containers/HomeImageSlider';
import UserImageSliderModule from './containers/UserImageSlider';
import Reducer from './reducer';

import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 

export const ImageSlider = ImageSliderModule;
export const HomeImageSlider = HomeImageSliderModule;
export const UserImageSlider = UserImageSliderModule;
export const sliderReducer = Reducer;
