import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from '../../../components/Dashboard';
import InPageNavMenu from '../../../components/InPageNavMenu';
import { PageHeight30, PageHeightAuto, UnderConstruction } from '../../../components/Utils';
import { getCompanyModel } from '../components/Utils';

import {
  BasicDataPanel,
  ITConnectionPanel,
  TeckKeysPanel,
  UserDeviceListPanel,
  CertificationListPanel,
  TechKeyListPanel,
  UserTechDeviceListPanel,
  ContractorCompanyPanel,
  UserRatingPanel
} from '../components/panels';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadCurrentWorker } from '../actions';
import UserTrainingPanel from '../components/panels/UserTrainingPanel';
import EmailForm from '../components/wizard/EmailForm';


const frontload = async (props) => {
  await props.getWorker();
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    worker: state.worker.currentWorker
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWorker: (...args) => dispatch(loadCurrentWorker(...args))
});

class WorkerPanel extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    worker: PropTypes.object,
    getWorker: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    worker: {
      username: '',
      name: '',
      lastname: '',
      email: '',
      telephoneNumber: '',
      cellNumber: '',
      company: '',
      position: '',
      roles: [],
    }
  }
  renderMenu = (isAdmin) => {
    const links = [
      { isVisible: true, to: 'basisdaten', label: 'Persönliche Daten' },
      { isVisible: true, to: 'vkdg-ausweis', label: 'VKDG Ausweis' },
      { isVisible: true, to: 'it-zugaenge', label: 'IT-Zugänge'},
      { isVisible: isAdmin, to: 'auftraggeber-taetigkeit', label: 'Auftraggebertätigkeiten'},
      { isVisible: true, to: 'bewertungen', label: 'Bewertungen'},
      { isVisible: true, to: 'messgeraete', label: 'Messgeräte'},
      { isVisible: true, to: 'schluessel', label: 'Technikerschlüssel'},
      { isVisible: true, to: 'zertifizierungen', label: 'Zertifizierungen'},
      { isVisible: true, to: 'vkdg-geraete', label: 'VKDG Geräte'},
      { isVisible: true, to: 'schulungen', label: 'Schulungen'},
      { isVisible: true, to: 'auditierung', label: 'Auditierung'},
    ];
    return (<InPageNavMenu className="" links={links}/>);
  }
  render() {
    const { className, worker, isAdmin } = this.props;
    console.log('WorkerPanel.render()', worker);
    if (!worker) return null;
    const Menu = this.renderMenu(isAdmin || worker.isOwn);

    // IT Connections
    const itConnectionData = worker.itConnectionData ? { ...worker.itConnectionData } : {};
    itConnectionData.delphiConnections = getCompanyModel(worker, 'delphiConnections');
    // Devices
    const userDevices = getCompanyModel(worker, 'devices');
    // Tech Devices
    const userTechDevices = getCompanyModel(worker, 'techDevices');
    // Tech Keys
    const techKeyMarkup = (worker.companies.length > 0) && (
      <TeckKeysPanel company={worker.companies[0]}/>
    );
    return (
      <div className={`profile-panel clearfix ${className}`}>
        <Dashboard menu={Menu} menuSize={3}>
          <PageHeight30 id="basisdaten" className="w3-border margin-big-top pad-big">
            <BasicDataPanel
              model={worker}
              // isAdmin={isAdmin}
            />
          </PageHeight30>
          <PageHeight30 id="vkdg-ausweis" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'VKDG Ausweis'}</h3>
            <UnderConstruction />
          </PageHeight30>
          <PageHeight30 id="it-zugaenge" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'IT-Zugänge'}</h3>
            <ITConnectionPanel model={itConnectionData}>
              <EmailForm 
                model={{email: worker.vf_email}}
                readOnly
              />
            </ITConnectionPanel>
          </PageHeight30>
          {(isAdmin || worker.isOwn) && (
            <PageHeight30 id="auftraggeber-taetigkeit" className="w3-border margin-big-top pad-big">
              <h3 className="no-margin-top">{'Tätigkeiten für Auftraggeber'}</h3>
              <ContractorCompanyPanel companies={worker.companies}/>
            </PageHeight30>
          )}
          <PageHeight30 id="bewertungen" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'Bewertungen'}</h3>
            <UserRatingPanel
              model={worker.averageRating}
              delphiConnections={itConnectionData.delphiConnections}
            />
          </PageHeight30>
          <PageHeight30 id="messgeraete" className="w3-border margin-big-top pad-big">
            <UserDeviceListPanel
              model={userDevices}
            />
          </PageHeight30>
          <PageHeight30 id="schluessel" className="w3-border margin-big-top pad-big">
            <TechKeyListPanel
              model={worker.techKeys}
            />
          </PageHeight30>
          <PageHeight30 id="zertifizierungen" className="w3-border margin-big-top pad-big">
            <CertificationListPanel
              model={worker.certificates}
            />
          </PageHeight30>
          <PageHeight30 id="vkdg-geraete" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'VKDG Geräte / Technikergeräte'}</h3>
            <UnderConstruction />
            {/* <UserTechDeviceListPanel
              model={userTechDevices}
            /> */}
          </PageHeight30>
          <PageHeight30 id="schulungen" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'Schulungen'}</h3>
            <UserTrainingPanel
              model={worker.trainings}
            />
          </PageHeight30>
          <PageHeight30 id="auditierung" className="w3-border margin-big-top pad-big">
            <h3 className="no-margin-top">{'Auditierung'}</h3>
            <UnderConstruction />
          </PageHeight30>
        </Dashboard>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(WorkerPanel)
);
