import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
// import { Label } from '../../../../components/Inputs';
import { getContentTypeIdFromType, CONTENT_TEXT } from '../Utils';

export default class TextContentForm extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: { body: '' }
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = { body: model.body || '' };
  }
  getModel = (noValidate) => {
    const { body } = this.state;
    const { id } = this.props.model;
    return { id, content_type_id: getContentTypeIdFromType(CONTENT_TEXT), body };
  }
  render() {
    console.log('TextContent.render()', this.props);
    const { className } = this.props
    const { body } = this.state;
    return (
      <div className={`${className} pad-`} style={{margin: '-1px'}}>
        <div className="form-group-item-">
          <InputTextarea
            className="w3-block w3-border no-corner"
            value={body}
            rows={5}
            autoResize
            onChange={(event) => this.setState({body: event.target.value})}
          />
        </div>
      </div>
    );
  }
}
