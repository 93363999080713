import React from "react";
import PropTypes from "prop-types";
import "./style.css";

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadSalesOptions } from '../actions/reporting';

const frontload = async (props) => {
  await props.getOptions()
};
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getOptions: (...args) => dispatch(loadSalesOptions(...args)),
});

const SalesTableInplaceDisplay = ({}) => {
  return (
    <div className="report-table-inplace-display">
      Klicken Sie hier um die Absätze zu verwalten.
      Das Laden der Tabelle kann etwas dauern.
      Dabei werden etwa 20MB an Daten übertragen
    </div>
  );
};

SalesTableInplaceDisplay.propTypes = {
  getOptions: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(SalesTableInplaceDisplay)
);
