import * as actionTypes from '../actions/reporting';
import isArray from 'lodash/isArray';
import {
  notifyError,
  notifySuccess,
  createSortByLabel,
  createSortByName
} from '../../../components/Utils';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';

const initialState = {
  sales: [],
  sale: null,
  salesLoaded: false,
  salesError: null,
  salesColumns: [
    "weborder_id",
    "weborder_date",
    "order_state",
    "state_final",
    "customer_nr",
    "product_code",
    "product_name",
    "product_group",
    "product_npv",
    "vpkn",
    "smb_promo",
    "sales_agent",
    "referrer",
    "delphi_order_nr"
  ],
  tipGivers: [],
  salesAgents: [],
  header: {},
  salesOptions: {},
  salesPartners: [],
  isFullScreen: false,
  salesFilter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // sales options
    case actionTypes.LOAD_SALES_OPTIONS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_SALES_OPTIONS_SUCCESS: {
      const salesOptions = {};
      for (const key in action.result) {
        if (isArray(action.result[key])) {
          action.result[key].forEach(item => {
            if (!salesOptions[key]) {
              salesOptions[key] = [];
            }
            item[key] && salesOptions[key].push({ label: `${item[key]}`, value: item[key] });
          })
        }
      }
      const empyStringOptions = [ 'customer_name', 'referrer', 'sales_agent' ];
      for (const key in salesOptions) {
        salesOptions[key].sort(createSortByLabel());
        // customer_name, referrer and sales_agent should an empty string label
        if (empyStringOptions.includes(key)) {
          console.log(`>> checking for ${key} empty string option ...`)
          const found = salesOptions[key].find(item => item.label === '');
          if (!found) {
            console.log(`<< empty string option not found for ${key} => adding it ...`);
            salesOptions[key] = [{label: 'keine', value: ''}].concat(salesOptions[key]);
          }
        }
      }
      console.log('>> Sales options:', salesOptions, action.result);
      // boolean dropdowns (smb_promo)
      const boolOptions = [
        { label: 'ja', value: 1 },
        { label: 'nein', value: 0 }
      ];
      salesOptions.smb_promo = [ ...boolOptions ];
      salesOptions.revoked = [ ...boolOptions ];
      return {
        ...state,
        loading: true,
        salesOptions
      };
    }
    case actionTypes.LOAD_SALES_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // sales partners
    case actionTypes.LOAD_SALES_PARTNERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_SALES_PARTNERS_SUCCESS: 
      return {
        ...state,
        loading: true,
        salesPartners: action.result.map(item => ({ ...item, name: item.company_name })).sort(createSortByName())
      };
    case actionTypes.LOAD_SALES_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // sales
    case actionTypes.LOAD_SALES:
      return {
        ...state,
        loading: true,
        salesError: null,
        header: {}
      };
    case actionTypes.LOAD_SALES_SUCCESS: {
      // const salesOptions = state.salesOptions || {};
      return {
        ...state,
        loading: false,
        salesLoaded: true,
        sales: action.result,
        salesFilter: action.filter,
        header: action.header
      };
    }
    case actionTypes.LOAD_SALES_FAILURE: {
      console.log('>> REDUCER SALES ERROR:', action);
      if (action.error && action.error.message === 'label.to.big.offset') {
        notifyError({
          summary: `Sales - Absätze`,
          detail: `Datenmenge zu groß. Bitte die Filter verwenden um sie zu reduzieren!`
        });
        return {
          ...state,
          loading: false,
          salesError: null,
          header: action.header
        };
      }
      return {
        ...state,
        loading: false,
        sales: [],
        salesError: true,
        error: action.result,
        header: action.header
      };
    }
    // clear sales
    case actionTypes.CLEAR_SALES:
      return {
        ...state,
        salesLoaded: false,
        salesFilter: null
      }
    // load sale
    case actionTypes.LOAD_SALE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.LOAD_SALE_SUCCESS:
      return {
        ...state,
        sale: isArray(action.result) ? action.result[0] : action.result,
        loading: false
      };
    case actionTypes.LOAD_SALE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save sale
    case actionTypes.SAVE_SALE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.SAVE_SALE_SUCCESS:
      notifySuccess({
        summary: 'Absatzmeldung',
        detail: `Der Absatz wurde erfolgreich gemeldet!`
      });
      return {
        ...state,
        sale: null,
        loading: false
      };
    case actionTypes.SAVE_SALE_FAILURE: {
      console.log('SAVE SALE ERROR:', action)
      let message = (action.error || {}).message || [];
      message = message.length > 0 ? ` - ${message[0]}` : ''
      notifyError({
        summary: 'Absatzmeldung',
        detail: `Der Absatz konnte nicht gemeldet werden${message}!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    // update sale
    case actionTypes.UPDATE_SALE:
        return {
          ...state,
          loading: true
        };
    case actionTypes.UPDATE_SALE_SUCCESS:
      notifySuccess({
        summary: 'Absatz bearbeiten',
        detail: `Die Absatz wurde erfolgreich aktualisiert!`
      });
      return {
        ...state,
        sale: null,
        loading: false
      };
    case actionTypes.UPDATE_SALE_FAILURE: {
      console.log('REDUX REDUCER - UPDATE_SALE_ERROR:', action);
      let message = (action.error || {}).message || [];
      message = message.length > 0 ? ` - ${message[0]}` : ''
      notifyError({
        summary: 'Absatz bearbeiten',
        detail: `Der Absatz konnte nicht aktualisiert werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    // cancel sale
    case actionTypes.CANCEL_SALE:
      return {
        ...state,
        sale: null
      };
    // set sales columns
    case actionTypes.SET_SALES_COLUMNS:
      // console.log('REDUX REDUCER set sales columns', action.columns)
      return {
        ...state,
        salesColumns: action.columns
      };
    // deleting sale
    case actionTypes.DELETE_SALE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_SALE_SUCCESS:
      notifyError({
        summary: 'Absatz löschen',
        detail: `Der Absatz wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_SALE_FAILURE:
      console.log('REDUX REDUCER - DELETE_SALE_FAILURE:', action);
      notifyError({
        summary: 'Absatz löschen',
        detail: `Der Absatz konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // load sales workers
    case actionTypes.LOAD_SALES_WORKERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_SALES_WORKERS_SUCCESS: {
      // console.log('REDUX REDUCER sales workers loaded:', action.result);
      return {
        ...state,
        loading: false,
        ...action.result
      };
      // const workerMap = {};
      // const workers = [];
      // (action.result && isArray(action.result) ? action.result : []).forEach(item => {
      //   if (!workerMap[item.username] && isUserActivated(item)) {
      //     workerMap[item.username] = true;
      //     workers.push(item);
      //   }
      // })
      // // tip givers
      // const tipGivers = workers.filter(user => 
      //   isDisponent(user) || isLS4(user) || isTechnician(user)
      // ).sort(createSortByUsername());
      // // sales agents (can also see themselves)
      // const salesAgents = (isSalesAgent(action.currentUser) && !isSalesManager(action.currentUser))
      //   ? [{ ...action.currentUser }]
      //   : workers.filter(user => 
      //     isSalesAgent(user) || isSalesManager(user)/*  || isSalesAdmin(user) || isSalesOperator(user) */
      //   ).sort(createSortByUsername());
      // return {
      //   ...state,
      //   loading: false,
      //   tipGivers,
      //   salesAgents,
      //   // salesWorkers: workers.sort(createSortByUsername())
      // };
    }
    case actionTypes.LOAD_SALES_WORKERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // toggle full screen
    case actionTypes.TOGGLE_FULLSCREEN:
      return {
        ...state,
        isFullScreen: action.isFullScreen
      };
    // renitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };

      default:
        return {...state};
    }
  }  
