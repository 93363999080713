import React from 'react';
import { connectDisplay } from '../components/DisplayUtils';
import LevelDisplay from '../components/LevelDisplay';


const DisplayDays = (props = {}) => {
  const levelBody = (item) => (<div className="bold">{item.day || item.value}</div>);
  return (
    <LevelDisplay
      {...props}
      levelLabel="Tag"
      levelBody={levelBody}
      dataKey="day"
    />
  );
};

export const SubDisplayDays = connectDisplay(DisplayDays, 'day', true);

export default connectDisplay(DisplayDays, 'day');
