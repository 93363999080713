import * as actionTypes from '../actions/key';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';

export const initialState = {
  keyOrders: [],
  selectedKeyOrders: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // setting selected key orders
    case actionTypes.SET_SELECTED_KEY_ORDERS:
      return {
        ...state,
        selectedKeyOrders: [ ...action.selected ]
      };

    // export key orders as CSV
    case actionTypes.EXPORT_KEY_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXPORT_KEY_ORDERS_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `Schließmittelbestellungen exportieren`,
        detail: `Die gewählte Bestellungen wurden erfolgreich exportiert.`
      });
      return {
        ...state,
        loading: false,
        selectedKeyOrders: [],
      };
    case actionTypes.EXPORT_KEY_ORDERS_FAILURE:
      notifyError({
        summary: `Schließmittelbestellungen exportieren`,
        detail: `Die gewählte Bestellungen konnten nicht exportiert werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // close key order
    case actionTypes.CLOSE_KEY_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_KEY_ORDER_SUCCESS:
      notifySuccess({
        summary: `Schließmittelbestellungen ${action.saveOnly ? 'speichern' : 'freigeben'}`,
        detail: `Die gewählte Bestellung wurde erfolgreich ${action.saveOnly ? 'gespeichert' : 'freigegeben'}.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_KEY_ORDER_FAILURE:
      notifyError({
        summary: `Schließmittelbestellung ${action.saveOnly ? 'speichern' : 'freigeben'}`,
        detail: `Die gewählte Bestellung konnte nicht ${action.saveOnly ? 'gespeichert' : 'freigegeben'} werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // delete key order
    case actionTypes.DELETE_KEY_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_KEY_ORDER_SUCCESS:
      notifySuccess({
        summary: `Schließmittelbestellung ablehnen`,
        detail: `Die gewählte Bestellung wurde erfolgreich abgelehnt.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_KEY_ORDER_FAILURE:
      notifyError({
        summary: `Schließmittelbestellung ablehnen`,
        detail: `Die gewählte Bestellung konnte nicht abgelehnt werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    default:
      return null;
  }
}
