import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadModemOrderCompanies,
  // loadModemOrderRegions,
  loadModemOrderDebitors,
  setDebitor,
  saveModemOrder
} from '../../actions';
import CartDebitorListContent from '../../components/cart/CartDebitorListContent';

const frontload = async (props) => {
  // const id = props.match.params.id;
  await props.getCompanies();
  // await props.getDebitors();
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    currentWeek: state.modem.currentWeek,
    companyOptions: state.modem.companyOptions,
    currentCompanyId: state.modem.currentCompanyId,
    currentCompanyName: state.modem.currentCompanyName,
    regionOptions: state.modem.regionOptions,
    currentRegionName: state.modem.currentRegionName,
    debitors: state.modem.debitors,
    message: state.modem.message,
    savedRegion: state.modem.savedRegion
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompanies: (...args) => dispatch(loadModemOrderCompanies(...args)),
  // getRegions: (...args) => dispatch(loadModemOrderRegions(...args)),
  getDebitors: (...args) => dispatch(loadModemOrderDebitors(...args)),
  setDebitor: (...args) => dispatch(setDebitor(...args)),
  saveModemOrder: (...args) => dispatch(saveModemOrder(...args)),
});

class CartDebitorList extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    currentWeek: PropTypes.number,
    companyOptions: PropTypes.array,
    currentCompanyId: PropTypes.number,
    currentCompanyName: PropTypes.string,
    regionOptions: PropTypes.array,
    currentRegionName: PropTypes.string,
    debitors: PropTypes.array,
    message: PropTypes.object,
    savedRegion: PropTypes.string,
    // actions 
    // getRegions: PropTypes.func.isRequired,
    getDebitors: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    saveModemOrder: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  render() {
    console.log('CartDebitorList.render()',
      'currentCompanyName:', this.props.currentCompanyName
    );
    const { companyOptions, regionOptions, debitors } = this.props;
    if (!companyOptions || companyOptions.length === 0 || !regionOptions || !debitors) {
      return null;
    }
    return (<CartDebitorListContent  {...this.props}/>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(CartDebitorList)
);
