import StaticPageFactory from "./containers/StaticPageFactory";
import Reducer from './reducer';

export const PageFactory = StaticPageFactory;
export const staticPageReducer = Reducer;

export { default as TechnikRoutes } from './containers/TechnikRoutes';
export { default as SalesRoutes } from './containers/SalesRoutes';
export { default as OrganisationRoutes } from './containers/OrganisationRoutes';

export { default as PathCreationDialog } from './containers/PathCreationDialog';
export { default as PathMovementDialog } from './containers/PathMovementDialog';
