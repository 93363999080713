import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter, NavLink } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import { PageHeight30, WizardTitle, notifyError, isProductDeleted } from '../../../components/Utils';
import { FormMenu } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadModem, cancelModem, saveModem } from '../actions';

import ModemBasicDataForm from '../components/modem/ModemBasicDataForm';
import ModemTypeListForm from '../components/modem/ModemTypeListForm';
import MediaUploadForm from '../../../components/MediaUploadForm';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getModem({ id });
};
const mapStateToProps = (state) => {
  return {
    modem: state.modem.modem
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModem: (...args) => dispatch(loadModem(...args)),
  cancelModem: (...args) => dispatch(cancelModem(...args)),
  saveModem: (...args) => dispatch(saveModem(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    modem: PropTypes.shape({}),
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getModem: PropTypes.func.isRequired,
    cancelModem: PropTypes.func.isRequired,
    saveModem: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelModem();
  }
  onCancel = () => {
    console.log('ModemWizard.onCancel()');
    const { cancelModem, history } = this.props;
    cancelModem();
    // history.replace('/organisation/modems');
    history.replace('/shop/modems/produkte');
  }
  onSave = () => {
    console.log('ModemWizard.onSave()');
    const model = this.form.getModel();
    if (!model) {
      return notifyError({
        summary: 'Modemverwaltung',
        detail: `Das Modem konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    // modem types/models
    let modemTypes = this.modemTypes.getModel();
    if (model.id) {
      modemTypes = modemTypes.map(item => ({ ...item,product_id: model.id }));
    }
    model.models = modemTypes;

    console.log('Saving Model: ', model);
    const { history } = this.props;
    const onSuccess = () => history.replace('/shop/modems/produkte');
    this.props.saveModem(model, onSuccess);
  }
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <HashRouter hashType="noslash">
          <div className="margin-big-top nav-menu-vertical">
            <ul className="w3-ul w3-border w3-round- w3-large">
              <li><NavLink to={`daten`} className="disabled-link-" activeClassName="active">{'Allgemeine Daten'}</NavLink></li>
              <li><NavLink to={`typen`} className="disabled-link-" activeClassName="active">{'Modeltypen'}</NavLink></li>
              {/* <li><NavLink to={`bilder`} className="disabled-link-" activeClassName="active">{'Bilder'}</NavLink></li> */}
            </ul>
          </div>
        </HashRouter>
      </div>
    );
  }
  render() {
    const { className, modem } = this.props;
    console.log('ModemWizard.render()', modem);
    const Menu = this.renderMenu();
    if (!modem) return null;
    const isDeleted = isProductDeleted(modem);
    return (
      <div className={`modem-order-wizard clearfix ${className}`}>
        <WizardTitle
          type="modem"
          model={modem}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveModemBtn2"
              cancelBtnLabel={isDeleted ? 'Schließen' : 'Abbrechen'}
              saveHidden={isDeleted}
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30 id="daten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <h3 className="no-margin-top">Allgemeine Daten</h3>
              <ModemBasicDataForm
                ref={ref => this.form = ref}
                model={modem}
              />
            </PageHeight30>
            <PageHeight30 id="typen" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <ModemTypeListForm
                ref={ref => this.modemTypes = ref}
                model={modem.models}
                readOnly={isDeleted}
              />
            </PageHeight30>
            {/* <PageHeight30 id="bilder" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <MediaUploadForm
                ref={ref => this.mediaForm = ref}
                title="Bilder"
                model={modem}
              />
            </PageHeight30> */}
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveModemBtn"
              cancelBtnLabel={isDeleted ? 'Schließen' : 'Abbrechen'}
              saveHidden={isDeleted}
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
