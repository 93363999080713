import React from 'react';
import PropTypes from 'prop-types';
import Calendar from '../../../../components/Calendar';
// import { InputNumber } from 'primereact/inputnumber';
import { InputText, Label } from '../../../../components/Inputs';
import { toDBDate, toUIInputDate } from '../../../../components/Utils';

export default class ProductProvisionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({})
  }
  static defaultProps = {
    className: '',
    model: {},
    decimalPointChar: ','
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      product_provision: model.product_provision != null ? model.product_provision  : '',
      start_date: toUIInputDate(model.start_date),
      error: {
        product_provision: null,
        start_date: null,
      }
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (error[key] !== undefined) {
        if (model[key] === '') {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    // product_provision
    if (model.product_provision !== '') {
      const formatedValue = parseInt(model.product_provision);
      if (isNaN(formatedValue)) {
        error.product_provision = 'Bitte eine ganze Zahl eingeben';
        isValid = false;
      } else {
        model.product_provision = formatedValue;
      }
    } else {
      delete error.product_provision;
      model.product_provision = null;
    }

    !noValidate && this.setState({ error });
    if (!isValid) {
      return null;
    }
    // dates
    model.start_date = toDBDate(model.start_date);
    return { id: this.props.model.product_id, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    const { className } = this.props
    const { product_provision, start_date, error } = this.state;
    // console.log('ProductProvisionForm.render()', this.state);  
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.product_provision && 'error-group'}`}>
              <Label htmlFor="product_provision">{`Provision`}</Label>
              {/* <InputNumber
                id="product_provision"
                className="w3-block pad-sm-tb w3-border-"
                value={product_provision}
                mode="decimal"
                minFractionDigits={2}
                maxFracionDigits={2}
                onValueChange={(event) => this.setState({
                  product_provision: event.target.value
                })}
              /> */}
              <InputText
                id="product_provision"
                className="w3-block w3-border"
                value={product_provision}
                placeholder="0,00"
                onChange={(event) => this.setState({
                  product_provision: event.target.value
                })}
              />
              {error.product_provision && error.product_provision !== true && (<span className="error-group-text">{error.product_provision}</span>)}
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.start_date && 'error-group'}`}>
              <Label htmlFor="start_date">{`Start*`}</Label>
              <Calendar
                id="start_date"
                className="w3-block w3-border w3-round"
                value={start_date}
                readOnlyInput={true}
                dateFormat="dd.mm.yy"
                // maxDate={new Date()}
                showButtonBar
                onChange={(event) => this.setState({
                  start_date: event.value
                })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
