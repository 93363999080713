import React from 'react';
import PropTypes from 'prop-types';
import PasswordChangeFormContent from '../components/PasswordChangeFormContent';

import { connect } from 'react-redux';
import { savePassword } from '../../../redux/actions/auth';
import { FormMenu } from '../../../components/Buttons';

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  changePassword: (...args) => dispatch(savePassword(...args)),
});

class PasswordChangeForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    changePassword: PropTypes.func.isRequired,
    onClose: PropTypes.func
  }
  static defaultProps =  {
    className: ''
  }
  onSave = () => {
    const { changePassword, onClose } = this.props;
    const model = this.form.getModel();
    if (!model) return;
    console.log('>> saving new password', model);
    changePassword(model, onClose);
  }
  onCancel = () => {
    const { onClose } = this.props;
    onClose && onClose();
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`${className}`}>
        <PasswordChangeFormContent ref={ref => this.form = ref}/>
        <FormMenu
          className="margin-lg-top w3-border-top pad-big w3-light-grey"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          saveBtnName="changePasswordBtn"/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangeForm);
