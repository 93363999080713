import React from 'react';
import PropTypes from 'prop-types';
// import { PrimaryButton, ButtonLink } from '../../../../components/Buttons';
// import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../../components/W3Dropdown';
import ActionFilter from '../ActionFilter';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

export default class DebitorTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    debitorTaskType: PropTypes.string,
    filterDebitors: PropTypes.func.isRequired,
    // selectedDebitors: PropTypes.array,
    // exportDebitors: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    actions: {},
    items: [],
  }
  // onExport = (event) => {
  //   if (event) event.preventDefault();
  //   const { selectedDebitors } = this.props;
  //   let counter = 0;
  //   if (selectedDebitors && selectedDebitors.length > 0) {
  //     counter += selectedDebitors.length;
  //   }
  //   this.taskDialog.show(
  //     true,
  //     {
  //       title: `Debitoren exportieren`,
  //       text: `
  //         Alle ${counter} ausgewählte Debitoren werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
  //         Wollen Sie fortfahren?
  //       `,
  //     },
  //     () => this.props.exportDebitors({ taskType: this.props.debitorTaskType })
  //   );
  // }
  render() {
    const { className/* , selectedDebitors */, filterDebitors, debitorTaskType, items } = this.props;
    // const isExportable = (selectedDebitors && selectedDebitors.length > 0);
    return (
      <div className={className}>
        <ActionFilter
          type="Debitor"
          taskType={debitorTaskType}
          setTaskType={filterDebitors}
          itemsToCount={items}
          className="display-inline-block pad-big-rht-"
        />
        {/* <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler as={PrimaryButton} size="small-">{`Optionen`}</W3DropdownToggler>
          <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '280px'}}>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onExport}
              disabled={!isExportable}>
                {`Messgeräte exportieren (CSV)`}
            </ButtonLink>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              to={`/wizard/aufgabe/import/debitor`}
              disabled={false}>
                {`Messgeräte importieren (CSV)`}
            </ButtonLink>
          </W3DropdownContent>
        </W3Dropdown> */}
        <ConfirmationDialog ref={ref => this.taskDialog = ref}/>
      </div>
    );
  }
}
