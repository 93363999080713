import * as actionTypes from '../actions/products';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';
import { notifySuccess, notifyError, PRODUCT_STATUS_IDS, isProductActivated, createSortByAttr } from '../../../components/Utils';
import { isArray } from 'lodash';

const initialState = {
  loading: false,
  products: [],
  catalogProducts: [],
  product: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load products
    case actionTypes.LOAD_PRODUCTS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_PRODUCTS_SUCCESS: {
      const products = action.result.sort(createSortByAttr('product_name'));
      const activeProducts = products.filter(product => isProductActivated(product));
      return {
        ...state,
        loading: false,
        products,
        catalogProducts: [ ...activeProducts ],
      };
    }
    case actionTypes.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        products: [],
        catalogProducts: [],
        error: action.result
      };
    // load a product
    case actionTypes.LOAD_PRODUCT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_PRODUCT_SUCCESS: {
      const product = isArray(action.result) ? action.result[0] : action.result;
      console.log('REDUX REDUCER - LOAD_PRODUCT_SUCCESS', product);
      return {
        ...state,
        loading: false,
        product
      };
    }
    case actionTypes.LOAD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        product: null,
        error: action.result
      };
    // save a product
    case actionTypes.SAVE_PRODUCT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_PRODUCT_SUCCESS:
      notifySuccess({
        summary: `Sales - Produktverwaltung`,
        detail: `Das Produkt ${action.product.product_name} wurde erfolgreich ${action.product.product_id ? 'aktualisiert' : 'gespeichert'}!`
      });
      return {
        ...state,
        loading: false,
        product: null
      };
    case actionTypes.SAVE_PRODUCT_FAILURE:
      notifyError({
        summary: `Sales - Produktverwaltung`,
        detail: `Das Produkt ${action.product.product_name} konnte nicht ${action.product.product_id ? 'aktualisiert' : 'gespeichert'} werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
    };
    // cancel product
    case actionTypes.CANCEL_PRODUCT:
      return {
        ...state,
        product: null,
      };
    // delete a product
    case actionTypes.DELETE_PRODUCT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      notifySuccess({
        summary: `Sales - Produktverwaltung`,
        detail: `Das Product ${action.product.product_name} wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false,
        selectedProduct: null,
        selectionPurpose: null
      };
    case actionTypes.DELETE_PRODUCT_FAILURE:
      notifyError({
        summary: `Sales - Produktverwaltung`,
        detail: `Das Product ${action.product.product_name} konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result,
        selectedProduct: null,
        selectionPurpose: null
      };
    // select a product
    case actionTypes.SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.product,
        selectionPurpose: action.purpose
      };
    // cancel a product selection
    case actionTypes.CANCEL_PRODUCT_SELECTION:
      return {
        ...state,
        selectedProduct: null,
        selectionPurpose: null
      };
    // change a product's status
    case actionTypes.CHANGE_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_STATUS_SUCCESS:
      notifySuccess({
        summary: `Sales - Produktverwaltung: Produkt ${action.product.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Produkt ${action.product.product_name} wurde erfolgreich ${action.product.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false,
        product: null,
        selectedProduct: null,
        selectionPurpose: null
      };
    case actionTypes.CHANGE_STATUS_FAILURE:
      notifyError({
        summary: `Sales - Produktverwaltung: Produkt ${action.product.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Produkt ${action.product.product_name} konnte nicht ${action.product.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert werden!`
      });
      return {
        ...state,
        loading: false,
        product: null,
        error: action.result,
        selectedProduct: null,
        selectionPurpose: null
      };

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
