import React from 'react';
import PropTypes from 'prop-types';
import { WizardTitle } from '../../../components/Utils';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.modem.shoppingCart,
    order: state.modem.modemOrder
  };
};
const mapDispatchToProps = null;

class ModemWizardTitle extends React.Component {
  static propTypes = {
    // mapped from state
    order: PropTypes.array,
    shoppingCart: PropTypes.object,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    isTask: PropTypes.bool
  }
  render() {
    const { order, shoppingCart, isTask } = this.props;
    const isNew =  !isTask && !(order && order.length > 0);
    const modemOrder = (order && order.length > 0) ? order[0] : shoppingCart;
    return (
      <div className={`modem-wizard-title`}>
        <WizardTitle type="modemOrder" model={modemOrder} isNew={isNew} isTask={isTask}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemWizardTitle);
