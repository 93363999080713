import React from "react";
import PropTypes from "prop-types";
import { FileUpload } from "primereact/fileupload";
// import { getFileExtension } from '../../../components/Utils';
import { MediaItem } from "../../../components/MediaUploadForm";
import { ViewIconButton, DeleteIconButton } from "../../../components/Buttons";

export default class AvisFileUploadItem extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.shape({}),
		uploadUrl: PropTypes.string,
		accepted: PropTypes.array,
		readOnly: PropTypes.bool,
		uploadLabel: PropTypes.string,
		emptyLabel: PropTypes.node,
		label: PropTypes.node,
	};
	static defaultProps = {
		className: "",
		accepted: ["pdf"],
		uploadLabel: "AVIS-Datei hochladen",
		emptyLabel: "Keine AVIS-Datei",
		label: "AVIS",
	};
	constructor(props) {
		super(props);
		this.state = {
			path: (props.model || {}).path || null,
		};
	}
	getFilePath = () => {
		return this.state.path;
	};
	addFile = (event) => {
		const response = JSON.parse(event.xhr.response);
		console.log("AvisFileUploadItem.addFile()", response);
		this.setState({ path: "/data-api" + response.filePath });
	};
	removeFile = () => {
		this.setState({ path: null });
	};
	render() {
		console.log("AvisFileUploadItem.render()", this.state);
		const {
			className,
			readOnly,
			uploadUrl,
			accepted,
			label,
			uploadLabel,
			emptyLabel,
		} = this.props;
		const { path } = this.state;
		let controls = null;
		let body = null;
		if (path && path !== "") {
			const data = { path, ext: "AVIS" };
			// body = (<MediaItem className="expand" {...data}/>);
			body = (
				<div
					className={`product-media-item bold w3-border w3-light-grey pad-big`}
				>
					{label}
				</div>
			);
			const viewBtn = (
				<span>
					<ViewIconButton
						className="w3-border pad margin-sm-lr"
						onClick={() => window.open(path, "_blank").focus()}
					/>
				</span>
			);
			const deleteBtn = !readOnly && (
				<span>
					<DeleteIconButton
						className="w3-border pad margin-sm-lr"
						onClick={() => this.removeFile()}
					/>
				</span>
			);
			controls = (
				<div
					className="display inline-block"
					style={{ position: "absolute", right: "4px", top: "8px" }}
				>
					{viewBtn}
					{deleteBtn}
				</div>
			);
		} else {
			body = readOnly && (
				<div className="product-media-item w3-opacity w3-border w3-light-grey pad-big">
					{emptyLabel}
				</div>
			);
		}
		const uploadBtn = !readOnly && (
			<div className={`w3-right w3-${path ? "hide" : "show"}`}>
				<FileUpload
					name="image"
					mode="basic"
					auto
					url={uploadUrl}
					multiple={false}
					accept={accepted.join(",")}
					chooseLabel={uploadLabel}
					onUpload={(event) => this.addFile(event)}
				/>
			</div>
		);
		return (
			<div className={`${className} clearfix`}>
				{uploadBtn}
				{controls}
				{body}
			</div>
		);
	}
}
