import React from 'react';
import Parser from 'html-react-parser';

export default function HtmlText({ className = '', style = {}, text }) {
  // const props = {
  //   dangerouslySetInnerHTML: { __html: text },
  // };
  // return (<div className={className} {...props}></div>);
  return (<div  className={className}  style = {style}>{text && Parser(text)}</div>);
}
