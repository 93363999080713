import TaskDashboardFactory from './containers/TaskDashboardFactory';

export { default as AllNewTasks } from './components/AllNewTasks';
// export { default as ITConnectionTasks } from './components/itConnections/ITConnectionTasks';
export { ConnectionTypes } from './components/Utils';

export { default as DebitorTaskList } from './containers/debitors/DebitorTaskList';
export { default as ADConnectionTaskList } from './containers/itConnections/ADConnectionTaskList';
export { default as PTConnectionTaskList } from './containers/itConnections/PTConnectionTaskList';
export { default as DelphiConnectionTaskList } from './containers/itConnections/DelphiConnectionTaskList';

export { default as ITConnectionTaskDashboard } from './containers/itConnections/ITConnectionTaskDashboard';
export { default as ConnectionImportWizard } from './containers/itConnections/ConnectionImportWizard';

export { default as DeviceTaskList } from './containers/device/DeviceTaskList';
export { default as DeviceImportWizard } from './containers/device/DeviceImportWizard';

export { default as CardOrderTaskList } from './containers/order/CardOrderTaskList';
export { default as CardOrderTaskOverview } from './containers/order/CardOrderTaskOverview';
export { default as CardOrderTaskDetails } from './containers/order/CardOrderTaskDetails';

export { default as KeyOrderTaskOverview } from './containers/order/KeyOrderTaskOverview';
export { default as KeyOrderTaskDetails } from './containers/order/KeyOrderTaskDetails';

export { default as ReportTaskList } from './containers/reports/ReportTaskList';

export { default as UserTaskList } from './containers/user/UserTaskList';
export { default as FailedEmailTaskList } from './containers/user/FailedEmailTaskList';

export const DebitorTaskDashboard = TaskDashboardFactory({
  stateKey: 'debitorTasks',
  title: 'Debitoren',
  url: '/aufgaben/debitoren',
  visibilityKey: "isAdmin"
});
export const ModemOrderTaskDashboard = TaskDashboardFactory({
  stateKey: 'modemOrders',
  title: 'Modem Logistik',
  url: '/aufgaben/modems',
  visibilityKey: "isAdmin"
});
export const DeviceTaskDashboard = TaskDashboardFactory({
  stateKey: 'deviceTasks',
  title: 'Messgeräte',
  url: '/aufgaben/messgeraete',
  visibilityKey: "isAdmin"
});
export const CardOrderTaskDashboard = TaskDashboardFactory({
  stateKey: 'cardOrders',
  title: 'Karten/Flyer',
  url: '/aufgaben/karten',
  visibilityKey: "isAdmin"
});
export const KeyOrderTaskDashboard = TaskDashboardFactory({
  stateKey: 'keyOrders',
  title: 'Schließmittel',
  url: '/aufgaben/schliessmittel',
  visibilityKey: "isAdmin"
});
export const ReportTaskDashboard = TaskDashboardFactory({
  stateKey: 'reportTasks',
  title: 'Reports',
  url: '/aufgaben/reports',
  visibilityKey: "isAdmin"
});
export const UserTaskDashboard = TaskDashboardFactory({
  stateKey: [
    'employeeTasks',
    'failedEmailAddresses',
  ],
  title: 'Benutzerverwaltung',
  url: '/aufgaben/benutzer',
  visibilityKey: "isUserTaskManager"
});

export { default as taskReducer } from './reducer';
