import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import "./vf-icon.css";

export const Chevron = ({ className, ...props }) => {
  return (
    <div className={classnames(className, "vf-chevron")} { ...props }>
      <span className="vf-icon" />
    </div>
  );
};

Chevron.propTypes = {
  className: PropTypes.string
};

Chevron.defaultProps = {
  className: null
};

export const HamburgerMenu = ({ onClick }) => {
  return (  
    <div className="burger-nav">
      <Link className="nav" to="#" onClick={onClick}>
        <span className="vf-icon vf-icon-burger" />
      </Link>
    </div>
  );
};

HamburgerMenu.propTypes = {
  onClick: PropTypes.func
};

HamburgerMenu.defaultProps = {
  onClick: null
};
