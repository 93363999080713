import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  createSortByAttr,
  getProductStatusChangeConfirmationText,
  PRODUCT_STATUS_IDS
} from '../../../components/Utils';
import { SecondaryButton, PrimaryButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import {
  deleteModem,
  cancelModemSelection
} from '../actions';

const mapStateToProps = (state) => {
  return {
    isLoading: state.modem.loading,
    product: state.modem.selectedModem,
    selectionPurpose: state.modem.selectionPurpose,
    dependentProductOrders: state.modem.dependentModemOrders
  };
};
const mapDispatchToProps = (dispatch) => ({
  deleteProduct: (...args) => dispatch(deleteModem(...args)),
  cancelSelection: (...args) => dispatch(cancelModemSelection(...args)),
});

class ModemDeleteDialog extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    product: PropTypes.shape({}),
    selectionPurpose: PropTypes.string,
    dependentProductOrders: PropTypes.array,
    deleteProduct: PropTypes.func.isRequired,
    cancelSelection: PropTypes.func.isRequired,
  }
  onHide = () => {
    this.props.cancelSelection();
  }
  onConfirmDelete = (product) => {
    this.props.deleteProduct(product)
  }
  render() {
    console.log('ModemDeleteDialog.render()', this.props);
    const {
      isLoading,
      product,
      selectionPurpose,
      dependentProductOrders
    } = this.props;
    if (selectionPurpose !== 'delete' || !product) {
      return null;
    }
    // header
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{`Modem löschen`}</h3>
      </div>
    );
    // body
    let body = (<div style={{ minHeight: '100px'}}/>);
    const productOrders = (dependentProductOrders || [])
      .map((item, index) => ({
        ...item,
        index,
        status: item.statusName
      })).sort(createSortByAttr('number'));
    if (!isLoading) {
      body = (productOrders.length > 0) ? (
        <div>
          <div className="w3-large pad-big-btm">
            {`Das Modem ${product.name} kann wegen den folgenden noch nicht abgeschlossenen Bestellungen nicht gelöscht werden:`}
          </div>
          <div className="pad-btm">
            <DataTable
              value={productOrders}
              paginator={true}
              rows={5}
            >
              <Column
                field="orderNumber"
                header="Bestellungsnummer"
                sortable
                filter
                filterMatchMode="contains"
              />
              <Column
                field="orderDate"
                header="Bestellungsdatum"
                sortable
                filter
                style={{textAlign:'center', width: '12em'}}
              />
              <Column
                field="status"
                header="Status"
                style={{textAlign:'center', width: '10em'}}
              />
            </DataTable>
          </div>
        </div>
      ) : (
        <div className="w3-large">
          {/* {`Wollen Sie das Modem ${product.name} wirklich löschen?`} */}
          {getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.DELETED, 'das Modem', product.name)}
        </div>
      );
    }

    // footer
    const okBtn = (productOrders.length === 0) && (
      <PrimaryButton
        className="margin-lft"
        onClick={() => this.onConfirmDelete(product)}
      >
        {'Ja'}
      </PrimaryButton>
    );
    const cancelBtn = (
      <SecondaryButton
        className="margin-lft"
        onClick={() => this.onHide()}
      >
        {(productOrders.length === 0) ? 'Nein' : 'Abbrechen'}
      </SecondaryButton>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        {okBtn}
        {cancelBtn}
      </div>
    );
    return (
      <div className="pos-rel">
        <Dialog
          header={header}
          footer={footer}
          iconsTemplate={null}
          visible
          style={{width: '600px'}}
          modal={true}
          onHide={this.onHide}>
          {body}
        </Dialog>
        {/* <div className={`w3-modal-relative w3-${isLoading ? 'show' : 'hide'}`}/> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemDeleteDialog);
