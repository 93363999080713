import Module from "./Module";
import { buildMap } from "./components";
import { Children } from "react";


export default Module;
export { buildSalesTree } from "./utils/salesTree";
export { buildOrganisationTree } from './utils/organisationTree';
export const buildLabelMap = buildMap;

export { default as AppLinks } from './utils/appLinks';
// export { AdminLinks, CEOLinks, WorkerPlusLinks, TechLinks } from "./components";


function getTreeLevel(tree, url) {
	// console.log('getTreeLevel() checking node', tree, 'url', url);
	let found = null;
	if (tree.to === url) {
		found = tree;
	} else if (tree.subMenu) {
		// search children
		for (let index = 0; index < tree.subMenu.links.length; index++) {
			found = getTreeLevel(tree.subMenu.links[index], url);
			if (found) break;
		}
	}
	return found;
}

export const getTechnikTreeLevel = (url, tree = []) => getTreeLevel(tree, url);
export const getSalesTreeLevel = (url, tree = []) => getTreeLevel(tree, url);
export const getOrganisationStaticTreeLevel = (url, tree) => getTreeLevel(tree, url);

export { convertListToTree } from './components';
export const convertTreeToList = (tree) => {
	const { label, to, subMenu, ...rest } = tree;
	let list = [{ label, to, ...rest }];
	if (subMenu) {
		subMenu.links.forEach((child) => {
			list = list.concat(convertTreeToList(child));
		});
	}
	return list;
};

export const getTreeNode = (url, tree, level = 0) => {
	if (!url || !tree) {
		return null;
	}
	let found = null;
	if (tree.url === url) {
		found = tree;
	} else {
		const children = tree.children || [];
		for (const child of children) {
			found = getTreeNode(url, child, level + 1);
			if (found) {
				break;
			}
		}
	}
	return found;
}
