import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ViewIconNavButton, PDFFileIconButton, EditIconNavButton } from '../../../../components/Buttons';
import {
  toUIDate,
  PRODUCT_ORDER_STATUS_IDS,
  PRODUCT_ORDER_STATUS_LABELS,
  PageHeight60,
  scrollToTop,
  TableEmptyMessage,
  createSortByAttr
} from '../../../../components/Utils';
import { ProductOrderStatusFilter } from '../../../../components/ColumnFilters';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadAllKeyOrders, loadOrderAVISFile } from '../../actions/keys';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  await props.getOrders();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.key.loading,
    orders: state.key.keyOrders
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrders: (...args) => dispatch(loadAllKeyOrders(...args)),
  getOrderAvisFile: (...args) => dispatch(loadOrderAVISFile(...args))
});

class KeyOrderOverviewTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    orders: PropTypes.array,
    getOrders: PropTypes.func.isRequired,
    getOrderAvisFile: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 12,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowDetails = (item, history) => {
    console.log('Viewing', item);
    history.push(`/shop/schliessmittel/archiv/${item.id}`);
  }
  getAvisFile = (order) => {
    this.props.getOrderAvisFile(order);
  }
  onStatusChange = (selectedStatus) => {
    this.dt.filter(selectedStatus, 'status_id', 'equals');
    this.setState({ selectedStatus });
  }
  render() {
    const { className, isLoading, orders, rows } = this.props;
    // console.log('KeyOrderOverviewTable.render()', orders);
    if (!orders) {
      return null;
    }
    const data = (orders || [])
      .map((order, index) => ({
        ...order,
        index,
        orderDate: order.order_date ? toUIDate(order.order_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ') : '',
        createdDate: toUIDate(order.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
        status: order.status || PRODUCT_ORDER_STATUS_LABELS[order.status_id]
      }))
      .sort(createSortByAttr("order_date", true, true));
    const actionTemplate = (rowData) => {
      const id = `editOrderBtn${rowData.index}`;
      const isReadOnly = (rowData.status_id !== PRODUCT_ORDER_STATUS_IDS.SENT);
      const Component = isReadOnly ? ViewIconNavButton : EditIconNavButton;
      return (
        <div>
          <Component
            id={id}
            name={id}
            onClick={(history) => this.onShowDetails(rowData, history)}
          />
        </div>
      );
    };
    const avisTemplate = (rowData) => {
      let btnMarkup = null;
      const { avisPath } = (rowData.files || {});
      if (rowData.status_id === PRODUCT_ORDER_STATUS_IDS.FINISHED && avisPath) {
        const id = `getAvisBtn${rowData.index}`;
        btnMarkup = (
          <PDFFileIconButton
            id={id}
            name={id}
            onClick={() =>this.getAvisFile({ orderNumber: rowData.orderNumber, avis_path: avisPath })}
            title="AVIS-Datei öffnen"
          />
        );
      }
      return (<div>{btnMarkup}</div>);
    };
    const statusBodyTemplate = (rowData) => (
      <span className={`status-text product-order-status-text-${rowData.status_id}`}>{PRODUCT_ORDER_STATUS_LABELS[rowData.status_id]}</span>
    );
    const statusFilter = (
      <ProductOrderStatusFilter
        value={this.state.selectedStatus}
        onChange={this.onStatusChange}
      />
    );
    return (
      <PageHeight60 className={`key-order-overview-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Schließmittelbestellungen"
              itemName="Schließmittelbestellung"
              itemNamePlural="Schließmittelbestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Schließmittelbestellungen"/>)}
        >
          <Column
            field="companyName"
            header="Firma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '18em'}}
          />
          <Column
            field="orderNumber"
            header="Bestellungsnummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="orderDate"
            header="Bestellungsdatum"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="createdDate"
            header="Abgeben am"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{width: '13em'}}
          />
          <Column
            body={avisTemplate}
            header="Übergabe"
            style={{textAlign:'center', width: '6em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '4.8em'}}
          />
        </DataTable>
      </PageHeight60>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(KeyOrderOverviewTable)
);
