import React from 'react';
import PropTypes from 'prop-types';

class WorkerSales extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  render() {
    const { className } = this.props;
    return (
      <div className={className}></div>
    );
  }
}

export default WorkerSales;
