import { isArray } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import Calendar from "../../../../components/Calendar";
import { toDBDateTime } from "../../../../components/Utils";

const isComplete = (value, selectionMode) => {
  if(!selectionMode || selectionMode === 'single')
    return true;
  if (value && !value[1]) {
    // the user selected a start date but not an end date yet
    return false;
  }
};

const CalendarFilter = ({ dt, field, onChange, selectionMode = 'single', ...props }) => {
  const [dates, setDates] = useState(selectionMode === 'single' ? null : []);

  const handleChange = useCallback((event) => {
    const { value } = event;
    if (isComplete(value, selectionMode)) {
      if (onChange) {
        onChange(field, isArray(value) ? value.map(item => toDBDateTime(item)) : toDBDateTime(value));
      } else {
        dt.filter(value, field, "custom");
      }
    }
    console.log('CalendarFilter.handleChange()', value, selectionMode);
    setDates(value);
  }, [dt, field]);

  console.log('CalendarFilter.render()', dates);
  return (
    <Calendar
      className="w3-block w3-border -p-column-filter"
      value={dates}
      onChange={handleChange}
      selectionMode={selectionMode}
      // numberOfMonths={3}
      showButtonBar
      // touchUI
      {...props}
    />
  );
};

CalendarFilter.propTypes = {
  dt: PropTypes.shape({
    filter: PropTypes.func.isRequired
  }),
  field: PropTypes.string.isRequired,
  selectionMode: PropTypes.string,
  onChange: PropTypes.func
};

export default React.memo(CalendarFilter);
