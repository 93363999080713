import React, { useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContentMenuLabelMap, getContentTypeFromId } from './components/Utils';

let ITEMS = [];

/**
 * Inserts an element at the end of the clipboard queue
 * @param {object} item 
 */
export const copyToClipboard = (item) => {
  ITEMS.push(item);
  return ITEMS.length;
}

/**
 * removes and returns the first element from the clipboard queue
 */
export const pasteFromClipboard = (selected = []) => {
  if (ITEMS.length === 0) {
    return null;
  }
  
  // paste selected items from the queue
  if (selected && selected.length > 0) {
    let result = [];
    if (selected.length < ITEMS.length) {
      // removing selected items from queue
      const newItems = [];
      for (const index in ITEMS) {
        const found = selected.find(selItem => selItem.index === index);
        if (!found) {
          newItems.push({ ...ITEMS[index] });
        }
      }
      ITEMS = newItems;
    } else {
      // emptying queue
      ITEMS = [];
    }
    result = selected.map(item => {
      delete item.index;
      if (item.id) {
        delete item.id;
      }
      return item;
    });
    return result;
  }

  // paste the first item inthe queue
  const item = ITEMS.shift();
  if (item.id) {
    delete item.id;
  }
  return item;
};

/**
 * Deletes all ITEMS from the clibboard queue
 */
export const emptyClipboard = () => {
  ITEMS = [];
};

/**
 * Check if the Clipboard is empty
 */
export const isClipboardEmpty = () => {
  return ITEMS.length === 0;
}

/**
 * Get the nr of items in the Clipboard
 */
export const getClipboardSize = () => {
  return ITEMS.length;
}

/**
 * 
 * @param {object} param0 
 */
export const ClipBoardContent = ({ selected = [], onSelect }) => {
  // console.log('ClipBoardContent.render()', selected);
  const [ selection, setSelection ] = React.useState(selected);
  const onSelectionChange = (event) => {
    // console.log('ClipBoardContent.onSelectionChange()', event.value);
    setSelection(event.value);
    if (onSelect) {
      onSelect(event.value);
    }
  };

  React.useEffect(() => {
    if (ITEMS.length > 0 && selection.length === 0) {
      setSelection([ ITEMS[0] ]);
    }
  }, []);

  const data = ITEMS.map((item, index) => {
    const counter = `${index < 9 ? '0' : ''}${index + 1}. `;
    const type = getContentTypeFromId(item.content_type_id);
    return {
      ...item,
      index,
      title: `${counter}${ContentMenuLabelMap[type]}`
    };
  });

  return (
    <DataTable
      value={data}
      // paginator={true}
      // rows={rows}
      // onSelectionChange={event => setSelection(event.value)}
      dataKey="index"
      selection={selection}
      onSelectionChange={onSelectionChange}
    >
      <Column
        selectionMode="multiple"
        headerStyle={{width:'3em'}}
      />
      <Column
        header="Inhalt"
        field="title"
      />
    </DataTable>
  )
};