// import {onSessionExpired} from '../modules/auth';
// import {close as closeOverlay} from '../../containers/Overlay/actions';

import { toggleGlobalOverlay } from "../../components/Utils";

export default function clientMiddleware(client) {
  return ({dispatch, getState}) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      toggleGlobalOverlay(true);
      next({...rest, type: REQUEST});

      const actionPromise = promise(client);
      actionPromise.then(
        ({body: result, header}) => {
          // console.log("## success header:", header);
          next({...rest, result, type: SUCCESS, header});
          toggleGlobalOverlay(false);
          if (action.onSuccess && typeof action.onSuccess === 'function') {
            // dispatching onSuccess actions
            action.onSuccess(dispatch, getState, result, header);
          }
        },
        ({body: error, header}) => {
          console.log("## error header:", header);
          toggleGlobalOverlay(false);
          next({...rest, error, type: FAILURE});
          // const status = error.status;
          // switch (status) {
          //   case 401:
          //     // dispatch(onSessionExpired());
          //     break;
          //   default:
          // }
          if (action.onFailure && typeof action.onFailure === 'function') {
            // dispatching onFailure actions ...;
            action.onFailure(dispatch, getState);
          }
        }
      ).catch((error)=> {
        console.error('MIDDLEWARE ERROR:', error);
        toggleGlobalOverlay(false);
        next({...rest, error, type: FAILURE});
      });
      return actionPromise;
    };
  };
}
