import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Label, InputText, Dropdown, MultiSelect } from "../../../components/Inputs";
import RichTextEditor from "../../../components/RichTextEditor";
import HtmlText from "../../../components/HtmlText";

const getTextPreviewHtml = (textPreview) => (
  `<p><strong style="color: rgb(230, 0, 0);">${textPreview}</strong></p>`
);

export default class MessageForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      roles: PropTypes.array.isRequired,
      companyGroups: PropTypes.array.isRequired,
      medium: PropTypes.string.isRequired,
      footPrint: PropTypes.string.isRequired,
      textPreview: PropTypes.string.isRequired,
      titlePrefix: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      attachments: PropTypes.array,
    }),
    roles: PropTypes.array,
    footPrints: PropTypes.shape({}),
    footPrintOptions: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
    footPrintGroups: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
    footPrintGroupOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({})
      )
    ),
    titlePrefixOptions: PropTypes.array,
    companyGroupOptions: PropTypes.array,
    signature: PropTypes.string.isRequired,
    onUpdated: PropTypes.func,
    // getContractGivers: PropTypes.func.isRequired,
    // getContractors: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  }
  static defaultProps = {
    className: "",
    model: {
      roles: [],
      companyGroups: [],
      medium: "email",
      footPrint: "",
      textPreview: "",
      titlePrefix: `${moment().year()}-<Anzahl> ad-hoc News`,
      title: "",
      text: "",
      attachments: []
    },
    roles: [],
    companyList: [],
    footPrints: {},
    footPrintOptions: [],
    footPrintGroups: [{}],
    footPrintGroupOptions: [[]],
    titlePrefixOptions: [],
    companyGroupOptions: [],
    signature: ""
  }
  constructor(props) {
    super(props);
    const model = this.getState(props.model);
    this.state = {
      ...model,
      error: {
        // roles: null,
        medium: null,
        titlePrefix: null,
        footPrint: null,
        title: null,
        text: null
      }
    };
  }
  getState = (model = {}) => {
    // let titlePrefix = `${moment().year()}-<Anzahl> ad-hoc News`;
    let footPrints = this.props.footPrints;
    let footPrintOptions = this.props.footPrintOptions;
    if (this.props.titlePrefixOptions.length > 0) {
      // titlePrefix = this.props.titlePrefixOptions[0].value;
      footPrints = this.props.footPrintGroups[0] || footPrints;
      footPrintOptions = this.props.footPrintGroupOptions[0] || footPrintOptions;
    }
    return {
      roles: model.roles || [],
      companyGroups: model.companyGroups || [],
      medium: model.medium || "email",
      footPrint: "",
      textPreview: "",
      titlePrefix: "",
      title: "",
      text: "<p><br/></p>",
      attachments: model.attachments,
      footPrints,
      footPrintOptions,
    };
  }
  // componentWillReceiveProps(props) {
  //   const model = this.getState(props.model);
  //   this.setState({ ...model });
  // }
  componentDidUpdate() {
    !this.props.readOnly && this.canSendModel()
  }
  canSendModel = () => {
    const model = this.validate(false, true);
    this.props.onUpdated && this.props.onUpdated(model !== null);
  }
  validate = (noValidate, noUpdate) => {
    const {
      error,
      footPrints,
      footPrintOptions,
      ...model
    } = this.state;
    const listValues = [ /* "roles" */ ];
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (!noValidate && error[key] !== undefined) {
        const test = (listValues.includes(key))
          ? (model[key].length === 0)
          : (model[key] === "" || model[key] === null);
        if (test) {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    if (!isValid) {
      // console.log("Invalid State:", error, model)
      if(!noUpdate) this.setState({ error });
      return null;
    }
    // Add greeting
    model.text = `<div><p>Sehr geehrte Damen und Herren,</p></div>` + model.text;
    return model;
  }
  getModel = (noValidate, noUpdate) => {
    const model = this.validate(noValidate, noUpdate);
    if (model) {
      model.text =  model.medium === "sms" ? `Absender KDG-ANP: ${model.text}` : model.text;
    }
    return model;
  }
  reset = () => {
    this.setState(this.getState());
  }
  setTitlePrefix = (titlePrefix) => {
    const {
      titlePrefixOptions,
      footPrintGroups,
      footPrintGroupOptions,
    } = this.props;
    const index = titlePrefixOptions.findIndex(item => item.value === titlePrefix);
    if (index >= 0) {
      this.setState({
        titlePrefix,
        footPrints: footPrintGroups[index],
        footPrintOptions: footPrintGroupOptions[index]
      });
      this.setFootPrint("");
    }
  }
  setFootPrint = (footPrint) => {
    const { footPrints, footPrintOptions } = this.state/* this.props */;
    const textPreview = footPrints[footPrint] || "";
    let text = this.state.text;
    const newTextPreview = getTextPreviewHtml(textPreview);
    let oldTextPreview = null;
    for (const option of footPrintOptions) {
      const value = getTextPreviewHtml(footPrints[option.value]);
      // console.log(`>> >> searching for "${value}" in "${text}"...`);
      if (text.indexOf(value) === 0) {
        oldTextPreview = value;
        break;
      }
    }
    let subString = oldTextPreview ? text.substring(oldTextPreview.length) : text;
    if (subString.length === 0) {
      subString = "<p><br/></p>";
    }
    console.log("setFootPrint()", newTextPreview, subString);
    text = `${newTextPreview}${subString}`;
    this.setState({ footPrint, textPreview, text });
  }
  render() {
    const {
      className,
      // footPrintOptions,
      titlePrefixOptions,
      companyGroupOptions,
      readOnly,
      model,
      signature
    } = this.props;
    const source = (readOnly && model) ? model : this.state;
    const {
      medium, text, roles, companyGroups,
      footPrint, textPreview, titlePrefix, title
    } = source;
    let textMarkup = (<HtmlText className="w3-border pad-big" text={text}/>);
    if (!readOnly) {
      textMarkup = medium === "sms" ? (
        <InputTextarea
          id="text"
          className="w3-block w3-border"
          value={text}
          autoResize
          onChange={(event) => this.setState({text: event.target.value})}
          readOnly={readOnly}
        />
      ) : (
        <div className="clearfix pad-big-lr w3-light-grey">
          <div>
            <p>{"Sehr geehrte Damen und Herren,"}</p>
          </div>
          <RichTextEditor
            id="text"
            className="w3-white"
            value={text}
            onChange={(event) => this.setState({text: event.target.value})}
            showFonts
            // fonts={[]}
          />
          <div className="pad-big-btm">
            <HtmlText text={signature}/>
          </div>
        </div>
      );
    }
    return (
      <div className={`${className} pos-rel`}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className={`w3-col s4 pad-lr form-group-item pad-top`}>
              <Label htmlFor="roles">{`Aufgabengebiet`}</Label>
              {readOnly && (
                <InputText 
                  className="w3-block w3-border"
                  value={roles.map(role => role.name).join(", ")}
                  readOnly
                  style={{padding: "0px 6px", height: "37.34px", lineHeight: "100%"}}
                />
              )}
              {!readOnly && (
                <MultiSelect
                  id="roles"
                  optionLabel="label"
                  value={roles}
                  placeholder="Bitte auswählen"
                  options={this.props.roles}
                  onChange={(event) => this.setState({roles: event.value})}
                  selectedItemsLabel="{0} Rollen ausgewählt"
                  filter
                  appendTo={document.body}
                />
              )}
            </div>
            <div className={`w3-col s4 pad-lr form-group-item pad-top`}>
              <Label htmlFor="roles">{`Vertragspartner`}</Label>
              {readOnly && (
                <InputText 
                  className="w3-block w3-border"
                  value={companyGroups.join(", ")}
                  readOnly
                  style={{padding: "0px 6px", height: "37.34px", lineHeight: "100%"}}
                />
              )}
              {!readOnly && (
                <MultiSelect
                  id="roles"
                  optionLabel="label"
                  value={companyGroups}
                  placeholder="Bitte auswählen"
                  options={companyGroupOptions}
                  onChange={(event) => this.setState({companyGroups: event.value})}
                  selectedItemsLabel="{0} Firmengruppen ausgewählt"
                  filter
                  appendTo={document.body}
                />
              )}
            </div>
            <div className="w3-col s4 pad-lr form-group-item pad-top">
              <Label>{`Schicken`}</Label>
              {readOnly && (
                <InputText 
                  className="w3-block w3-border"
                  value={medium}
                  readOnly
                  style={{padding: "0px 6px", height: "37.34px", lineHeight: "100%"}}
                />
              )}
              {!readOnly && (
                <div className="w3-row">
                  <div className="w3-col s6">
                    <div className="p-col-12">
                      <RadioButton
                        inputId={`ch0`}
                        name="medium"
                        value="email"
                        checked={medium === "email"}
                        onChange={(event) => this.setState({medium: event.value})}
                      />
                      <label htmlFor={`ch0`} className="p-radiobutton-label margin-lft">{`per E-Mail`}</label>
                    </div>
                  </div>
                  <div className="w3-col s6">
                    <div className="p-col-12">
                      <RadioButton
                        inputId={`ch1`}
                        name="medium"
                        value="sms"
                        checked={medium === "sms"}
                        onChange={(event) => this.setState({medium: event.value})}
                      />
                      <label htmlFor={`ch1`} className="p-radiobutton-label margin-lft">{`per SMS`}</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-col s4 pad-lr form-group-item pad-top pad-sm-btm">
              <Label htmlFor="titlePrefix">{`Betreff`}</Label>
              {/* <InputText 
                id="titlePrefix"
                className="w3-block w3-border"
                value={titlePrefix}
                onChange={(event) => this.setState({titlePrefix: event.value})}
                disabled={!readOnly}
                readOnly={readOnly}
              /> */}
              <Dropdown
                id="titlePrefix"
                className="w3-block w3-border"
                value={titlePrefix}
                options={titlePrefixOptions}
                onChange={(event) => this.setTitlePrefix(event.value)}
                placeholder="Bitte auswählen"
                // showClear
                readOnly={readOnly}
              />
            </div>
            <div className="w3-col s8 pad-lr form-group-item pad-top pad-sm-btm">
              <Label htmlFor="title">&nbsp;</Label>
              <InputText 
                id="title"
                className="w3-block w3-border"
                value={title}
                onChange={(event) => this.setState({title: event.target.value})}
                placeholder="Frei definierbarer Betreff"
                readOnly={readOnly}
                style={{padding: "0px 6px", height: "37.34px", lineHeight: "100%"}}
              />
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-col s4 pad-lr form-group-item pad-top pad-sm-btm">
              <Label htmlFor="footprint">{`VF Footprint`}</Label>
              <Dropdown
                id="footPrint"
                className="w3-border w3-round"
                value={footPrint}
                placeholder="Bitte auswählen"
                options={this.state.footPrintOptions}
                onChange={(event) => this.setFootPrint(event.value)}
                showClear
                readOnly={readOnly}
              />
            </div>
            <div className="w3-col s8 pad-lr form-group-item pad-top pad-sm-btm">
              <Label htmlFor="textPreview">{`Text Voransicht`}</Label>
              <InputText 
                id="textPreview"
                className="w3-block w3-border"
                value={textPreview}
                onChange={(event) => this.setState({textPreview: event.value})}
                readOnly
                style={{padding: "0px 6px", height: "37.34px", lineHeight: "100%"}}
              />
            </div>
          </div>
          <div className="w3-row pad-big-top">
            <div className="w3-col s12 pad-lr">
              <Label htmlFor="text">{`Ad-Hoc News Inhalt`}</Label>
              {textMarkup}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
