import React from "react";
import PropTypes from "prop-types";
import { PRODUCT_ORDER_STATUS_IDS } from "../../../../components/Utils";
import { PrimaryButtonLink } from "../../../../components/Buttons";

import { connect } from "react-redux";

const mapStateToProps = (state) => ({
	isAdmin: state.auth.isAdmin,
	keyOrders: state.key.keyOrders,
});
const mapDispatchToProps = null;

class KeyOrderMenu extends React.Component {
	static propTypes = {
		isAdmin: PropTypes.bool,
		keyOrders: PropTypes.array,
		className: PropTypes.string,
	};
	static defaultProps = {
		className: "",
	};
	render() {
		// console.log('KeyOrderMenu.render()', this.props);
		const { className, isAdmin, keyOrders } = this.props;
		const found = (keyOrders || []).find(
			(item) => item.status_id !== PRODUCT_ORDER_STATUS_IDS.FINISHED
		);
		const disabled = !isAdmin && found !== null && found !== undefined;
		return (
			<div className={`cards list-menu ${className}`}>
				<PrimaryButtonLink
					id="locking"
					to={`/wizard/shop/bestellung/schliessmittel/neu`}
					disabled={disabled}
				>
					{`zum Schließmittel-Shop`}
				</PrimaryButtonLink>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyOrderMenu);
