import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditIconNavButton, ViewIconNavButton, DeleteIconNavButton } from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { getDeviceDeleteMsg } from '../components/Utils';
// import SalesTableUtil from '../../Reporting/components/SalesTableUtil';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadUserDevices, saveUserDevice, deleteUserDevice } from '../actions';
import {
  isDeviceStatusEditable,
  createSortById,
  DEVICE_ACTION_IDS,
  DEVICE_STATUS_LABELS,
  DEVICE_STATUS_IDS,
  scrollToTop,
  TableEmptyMessage
} from '../../../components/Utils';

const frontload = async (props) => (
  await props.getDevices()
);
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.device.loading,
    userDevices: state.device.userDevices
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDevices: (...args) => dispatch(loadUserDevices(...args)),
  saveDevice: (...args) => dispatch(saveUserDevice(...args)),
  deleteDevice: (...args) => dispatch(deleteUserDevice(...args))
});

class DeviceTable extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    userDevices: PropTypes.array,
    getDevices: PropTypes.func.isRequired,
    saveDevice: PropTypes.func.isRequired,
    deleteDevice: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    namePrefix: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: '',
    namePrefix: ''
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onEditDevice = (device, history) => {
    console.log('Editing', device);
    history.push(`/wizard/organisation/messgeraet/mitarbeiter/${device.id}`);
  }
  onDeleteDevice = (userDevice) => {
    const { isAdmin, getDevices, saveDevice, deleteDevice } = this.props;
    const text = getDeviceDeleteMsg(userDevice, isAdmin);
    const account = (userDevice.account && userDevice.account !== '') ? userDevice.account : null;
    this.dialog.show(
      true,
      { title: `Messgerät löschen`, text },
      () => {
        if (isAdmin || !account) {
          console.log('<< deleting directly ...')
          return deleteDevice(userDevice);
        }
        // create delete task
        const { id } = userDevice;
        const model = {
          ...userDevice,
          action_id: DEVICE_ACTION_IDS.DELETE,
          status_id: DEVICE_STATUS_IDS.DELETING
        };
        console.log('<< creating deletion task ...', model);
        saveDevice(model, () => getDevices());
      }
    );
  }
  actionTemplate = (rowData/* , column */) => {
    const { isAdmin, namePrefix } = this.props;
    const isEditable = isDeviceStatusEditable(rowData.status_id, rowData.id, true);
    const Button = isEditable ? EditIconNavButton : ViewIconNavButton;
    const isDeletable = (item) => (
      isAdmin || (!item.account || item.account === '' || item.status_id === DEVICE_STATUS_IDS.FINISHED)
    );
    const deleteBtn = isDeletable(rowData) && (
      <div className="w3-cell w3-cell-top">
        <DeleteIconNavButton
          // className="pad"
          id={`${namePrefix}-deleteBtn${rowData.id || rowData.index || rowData.name || ''}`}
          onClick={() => this.onDeleteDevice(rowData)}
        />
      </div>
    );
    return (
      <div>
        <div className="w3-cell w3-cell-top">
          <Button id={``} onClick={(history) => this.onEditDevice(rowData, history)}/>
        </div>
        {deleteBtn}
      </div>
    );
  }
  render() {
    const { className, isLoading, userDevices, rows } = this.props;
    // console.log('DeviceTable.render()', userDevices);
    const data = userDevices.map((device, index) => ({
      ...device,
      index,
      nextCheckDateStr: moment(device.nextCheckDate, 'YYYY-MM-DDTHH:mm:ss:SSSZ').format('DD.MM.YYYY'),
      status: device.status || DEVICE_STATUS_LABELS[`${device.status_id}`] || ''
    }))
    // sort by id in descending order
    .sort(createSortById(true));
    return (
      <div className={`device-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginator={data.length > rows}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Messgeräte"
              itemName="Messgerät"
              itemNamePlural="Messgeräte"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Messgeräte"/>)}
        >
          <Column
            field="companyName"
            header="Firma"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="device"
            header="Messgerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="userPrimary"
            header="Hauptbenutzer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="userInCharge"
            header="Verantwortlich"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="nextCheckDateStr"
            header="Prüfung fällig"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            field="status"
            header="Status"
            body={rowData => {
              let markup = rowData.status;
              if (rowData.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT) {
                markup = DEVICE_STATUS_LABELS[DEVICE_STATUS_IDS.DELETING_ACCOUNT];
              }
              return markup;
            }}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '10em'}}
          />
          <Column body={this.actionTemplate} style={{textAlign:'center', width: '7em'}}/>
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
        {/* <div className={`w3-modal w3-${isLoading ? 'show' : 'hide'}`}/> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(DeviceTable)
);
