import React from 'react';
import PropTypes from 'prop-types';
import Calendar from '../../../../components/Calendar';
// import { InputNumber } from 'primereact/inputnumber';
import { InputText, Label } from '../../../../components/Inputs';
import { toDBDate, toUIDate, toUIInputDate } from '../../../../components/Utils';

export default class ProductBasicDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    decimalPointChar: PropTypes.string,
    readOnly: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {},
    decimalPointChar: ','
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      product_name: model.product_name || '',
      product_shortname: model.product_shortname || '',
      product_category: model.product_category || '',
      product_code: model.product_code || '',
      product_group: model.product_group || '',
      product_truecomp: model.product_truecomp || '',
      product_npv: model.product_npv != null ? model.product_npv : '',
      product_provision: model.product_provision != null ? model.product_provision  : '',
      start_date: toUIInputDate(model.start_date),
      error: {
        product_name: null,
        product_category: null,
        product_code: null,
        product_group: null,
        start_date: null,
      }
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    console.log('>> validating', model);
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (error[key] !== undefined) {
        if (model[key] === '') {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    // product_npv
    if (model.product_npv !== '') {
      const formatedValue = parseInt(model.product_npv);
      if (isNaN(formatedValue)) {
        error.product_npv = 'Bitte eine ganze Zahl eingeben';
        isValid = false;
      } else {
        model.product_npv = formatedValue;
      }
    } else {
      delete error.product_npv;
      model.product_npv = null;
    }
    // product_provision
    if (model.product_provision !== '') {
      const formatedValue = parseInt(model.product_provision);
      if (isNaN(formatedValue)) {
        error.product_provision = 'Bitte eine ganze Zahl eingeben';
        isValid = false;
      } else {
        model.product_provision = formatedValue;
      }
    } else {
      delete error.product_provision;
      model.product_provision = null;
    }

    !noValidate && this.setState({ error });
    if (!isValid) {
      return null;
    }
    // dates
    model.start_date = toDBDate(model.start_date);
    return { id: this.props.model.product_id, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    const { className, readOnly, model } = this.props
    const {
      product_name, product_shortname, product_category,
      product_code, product_group, product_truecomp,
      product_npv, product_provision, start_date,
      error
    } = this.state;
    // console.log('ProductBasicDataForm.render()', this.state);
    const startDateInputMarkup = readOnly ? (
      <InputText
        id="start_date"
        className="w3-block w3-border"
        value={toUIDate(model.start_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ')}
        readOnly={readOnly}
      />
    ) : (
      <Calendar
        id="start_date"
        className="w3-block w3-border w3-round"
        value={start_date}
        readOnlyInput={true}
        dateFormat="dd.mm.yy"
        // maxDate={new Date()}
        showButtonBar
        onChange={(event) => this.setState({
          start_date: event.value
        })}
      />
    )
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.product_name && 'error-group'}`}>
              <Label htmlFor="product_name">{`Produktname*`}</Label>
              <InputText
                id="product_name"
                className="w3-block w3-border"
                value={product_name}
                onChange={(event) => this.setState({
                  product_name: event.target.value,
                  error: { ...error, product_name: ((event.target.value === ''))}
                })}
                readOnly={readOnly}
              />
            </div>
            {/* <div className={`pad-top form-group-item`}>
              <Label htmlFor="product_shortname">{`Produkt-Kurzname`}</Label>
              <InputText
                id="product_shortname"
                className="w3-block w3-border"
                value={product_shortname}
                onChange={(event) => this.setState({
                  product_shortname: event.target.value
                })}
                readOnly={readOnly}
              />
            </div> */}
            <div className={`pad-top form-group-item ${error.product_code && 'error-group'}`}>
              <Label htmlFor="product_code">{`Produkt-Code*`}</Label>
              <InputText
                id="product_code"
                className="w3-block w3-border"
                value={product_code}
                onChange={(event) => this.setState({
                  product_code: event.target.value,
                  error: { ...error, product_code: ((event.target.value === ''))}
                })}
                readOnly={readOnly}
              />
            </div>
            <div className={`pad-top form-group-item ${error.product_code && 'error-group'}`}>
              <Label htmlFor="product_category">{`Produktkategorie*`}</Label>
              <InputText
                id="product_category"
                className="w3-block w3-border"
                value={product_category}
                onChange={(event) => this.setState({
                  product_category: event.target.value,
                  error: { ...error, product_category: ((event.target.value === ''))}
                })}
                readOnly={readOnly}
              />
            </div>
            <div className={`pad-top form-group-item ${error.product_group && 'error-group'}`}>
              <Label htmlFor="product_code">{`Produktgruppe*`}</Label>
              <InputText
                id="product_group"
                className="w3-block w3-border"
                value={product_group}
                onChange={(event) => this.setState({
                  product_group: event.target.value,
                  error: { ...error, product_group: ((event.target.value === ''))}
                })}
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item`}>
              <Label htmlFor="product_truecomp">{`Truecomp`}</Label>
              <InputText
                id="product_truecomp"
                className="w3-block w3-border"
                value={product_truecomp}
                onChange={(event) => this.setState({
                  product_truecomp: event.target.value
                })}
                readOnly={readOnly}
              />
            </div>
            <div className={`pad-top form-group-item ${error.product_npv && 'error-group'}`}>
              <Label htmlFor="product_npv">{`NPV`}</Label>
              <InputText
                id="product_npv"
                className="w3-block w3-border"
                value={product_npv}
                placeholder="0"
                onChange={(event) => this.setState({
                  product_npv: event.target.value
                })}
                readOnly={readOnly}
              />
              {error.product_npv && error.product_npv !== true && (<span className="error-group-text">{error.product_npv}</span>)}
            </div>
            <div className={`pad-top form-group-item ${error.product_provision && 'error-group'}`}>
              <Label htmlFor="product_provision">{`Provision`}</Label>
              {/* <InputNumber
                id="product_provision"
                className="w3-block pad-sm-tb w3-border-"
                value={product_provision}
                mode="decimal"
                minFractionDigits={2}
                maxFracionDigits={2}
                onValueChange={(event) => this.setState({
                  product_provision: event.target.value
                })}
              /> */}
              <InputText
                id="product_provision"
                className="w3-block w3-border"
                value={product_provision}
                placeholder="0"
                onChange={(event) => this.setState({
                  product_provision: event.target.value
                })}
                readOnly={readOnly}
              />
              {error.product_provision && error.product_provision !== true && (<span className="error-group-text">{error.product_provision}</span>)}
            </div>
            <div className={`pad-top form-group-item ${error.start_date && 'error-group'}`}>
              <Label htmlFor="start_date">{`Start*`}</Label>
              {startDateInputMarkup}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
