import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
// import { Dropdown } from 'primereact/dropdown';
// import { Calendar } from 'primereact/calendar';
import { InputText, Label, Calendar, Dropdown } from '../../../../components/Inputs';
import { toDBDate, toUIInputDate } from '../../../../components/Utils';

const IssuerOptions = [
  { value: 'dibkom-Zertifikat', label: 'dibkom-Zertifikat' },
  { value: 'Vodafone Kabel Deutschland', label: 'Vodafone Kabel Deutschland' },
];
const CertificateOptionMap = {
  'dibkom-Zertifikat': [
    { label: 'dibkom - Anschlussnetze NE3' },
    { label: 'dibkom - Koax-Installation NE4' },
    { label: 'dibkom - LWL in Kabelnetzen NE3/NE4' },
    { label: 'dibkom - LWL-Installation' },
    // { label: 'VKD - Azubi in Berufsausbildung' },
    // { label: 'VKD - BK - Projekt' },
    // { label: 'VKD - BK - Projekt IPO' },
    // { label: 'VKD - FN - VF Festnetz Enterprise Advanced' },
    // { label: 'VKD - FN - VF Festnetz Enterprise Basic' },
    // { label: 'VKD - KN1 - Einrichtung HSI in rückkanalfähigen NE4' },
    // { label: 'VKD - KN2 - Inst.-Entstörung NE4' },
    // { label: 'VKD - KN3 - Bau-Entstörung NE3' },
    // { label: 'VKD - KNTB - Montage-Messung am erdverlegten Kabel' },
  ],
  'Vodafone Kabel Deutschland': [
    // { label: 'dibkom - Anschlussnetze NE3' },
    // { label: 'dibkom - Koax-Installation NE4' },
    // { label: 'dibkom - LWL in Kabelnetzen NE3/NE4' },
    // { label: 'dibkom - LWL-Installation' },
    { label: 'VKD - Azubi in Berufsausbildung' },
    { label: 'VKD - BK - Projekt' },
    { label: 'VKD - BK - Projekt IPO' },
    { label: 'VKD - FN - VF Festnetz Enterprise Advanced' },
    { label: 'VKD - FN - VF Festnetz Enterprise Basic' },
    { label: 'VKD - KN1 - Einrichtung HSI in rückkanalfähigen NE4' },
    { label: 'VKD - KN2 - Inst.-Entstörung NE4' },
    { label: 'VKD - KN3 - Bau-Entstörung NE3' },
    { label: 'VKD - KNTB - Montage-Messung am erdverlegten Kabel' },
  ]
};
const TypeOptions = [
  { label: 'Anmeldung zum Zertifikat', value: 'INTENTION' },
  { label: 'Bestehendes Zertifikat', value: 'CERTIFICATE' }
];

const IssuerIDs = [
  { value: 1, label: 'dibkom-Zertifikat' },
  { value: 2, label: 'Vodafone Kabel Deutschland' },
];
const CertificateIDs =[
  { label: 'dibkom - Anschlussnetze NE3', value: 1 },
  { label: 'dibkom - Koax-Installation NE4', value: 2 },
  { label: 'dibkom - LWL in Kabelnetzen NE3/NE4', value: 8 },
  { label: 'dibkom - LWL-Installation', value: 3 },
  { label: 'VKD - Azubi in Berufsausbildung', value: 15 },
  { label: 'VKD - BK - Projekt', value: 12 },
  { label: 'VKD - BK - Projekt IPO', value: 14 },
  { label: 'VKD - FN - VF Festnetz Enterprise Advanced', value: 9 },
  { label: 'VKD - FN - VF Festnetz Enterprise Basic', value: 10 },
  { label: 'VKD - KN1 - Einrichtung HSI in rückkanalfähigen NE4', value: 11 },
  { label: 'VKD - KN2 - Inst.-Entstörung NE4', value: 4 },
  { label: 'VKD - KN3 - Bau-Entstörung NE3', value: 7 },
  { label: 'VKD - KNTB - Montage-Messung am erdverlegten Kabel', value: 13 },
];


export default class CertificateForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companies: PropTypes.array
  }
  static defaultProps = {
    className: '',
    model: {
      issuer: '',
      name: '',
      number: '',
      type: '',
      intension_date: '',
      status: '',
    }
  }
  constructor(props) {
    super();
    const { model } = props;
    this.state = {
      issuer: model.issuer || '',
      name: model.name || '',
      intension_date: toUIInputDate(model.intension_date),
      number: model.number || '',
      type: model.type || '',
      error: {
        issuer: null,
        name: null,
        intension_date: null,
        type: null
      }
    };
  }
  findUserAttributes = (list, value, attributeName) => {
    const found = list.find(item => item.id === value);
    if (found) {
      return {
        [attributeName]: `${found.firstname} ${found.lastname}`,
        [`${attributeName}Id`]: found.id
      };
    }
    return null;
  }
  validate = () => {
    const { error, ...others } = this.state;
    let model = { ...others };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    // registration date
    if (model.intension_date); {
      model.intension_date = toDBDate(model.intension_date);
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    const { id, index } = this.props.model;
    const issuer = IssuerIDs.find(item => item.label === model.issuer);
    if (issuer) { model.issuer_id = issuer.value; }
    const cert = CertificateIDs.find(item => item.label === model.name);
    if (cert) { model.certificate_id = issuer.value; }
    return { id, index, ...model };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  changeIssuer = (event) => {
    const newState = { issuer: event.value };
    if (event.value !== this.state.issuer) {
      newState.name = '';
    }
    this.setState(newState);
  }
  render() {
    const { className } = this.props
    const { issuer, name, number, intension_date, type, error} = this.state;
    const CertificateOptions = (CertificateOptionMap[issuer] || []).map(item => ({
      label: item.label, value: item.value || item.label
    }));
    console.log('CertificateForm.render()', this.state, this.props.model);
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row pad-top">
            <div className="w3-col s6 pad-lr">
              <div className={`${error.issuer ? 'error-group' : ''}`}>
                <Label htmlFor="issuer">{`Quelle*`}</Label>
                <Dropdown
                  id="issuer"
                  className="w3-block w3-border"
                  value={issuer}
                  placeholder="Bitte auswählen"
                  options={IssuerOptions}
                  onChange={this.changeIssuer}
                  showClear
                />
              </div>
              <div className={`pad-top ${error.name ? 'error-group' : ''}`}>
                <Label htmlFor="name">{`Typ*`}</Label>
                <Dropdown
                  id="name"
                  className="w3-block w3-border"
                  value={name}
                  placeholder="Bitte auswählen"
                  options={CertificateOptions}
                  onChange={(event) => this.setState({name: event.value})}
                  showClear
                />
              </div>
              <div className={`pad-top ${error.type ? 'error-group' : ''}`}>
                <Label htmlFor="type">{`Anmeldung/bestehendes Zerzifikat*`}</Label>
                <Dropdown
                  type="type"
                  className="w3-block w3-border certificateRegister"
                  value={type}
                  placeholder="Bitte auswählen"
                  options={TypeOptions}
                  onChange={(event) => this.setState({type: event.value})}
                  showClear
                />
              </div>
            </div>
            <div className="w3-col s6 pad-lr">
              <div className={`${error.intension_date ? 'error-group' : ''}`}>
                <Label htmlFor="intension_date">{`angemeldet für den*`}</Label>
                <Calendar
                  id="intension_date"
                  className="w3-block w3-border"
                  readOnlyInput={true}
                  dateFormat="dd.mm.yy"
                  value={intension_date}
                  onChange={(event) => {this.setState({intension_date: event.value}); console.log('intension_date',event.value)}}
                />
              </div>
              <div className="pad-top">
                <Label htmlFor="number">{`Nummer/Kurzbemerkung`}</Label>
                <InputText
                  id="number"
                  className="w3-block w3-border"
                  value={number}
                  onChange={(event) => this.setState({number: event.target.value})}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
