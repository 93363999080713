import React from "react";
import PropTypes from 'prop-types';
import { validateEmail } from "../../../../components/Utils";
import { InputText, Label } from "../../../../components/Inputs";


export default class FailedEmailTaskform extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
  }
  static defaultProps = {
    className: '',
    model: {
      email: '',
      origin: "",
    }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      to_emails: model.to_emails,
      newEmail: '',
      newEmail2: '',
      error: {
        newEmail: null,
        newEmail2: null,
        to_emails: null
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // allow unset newEmail for companyprofile tasks
    if (this.props.model.origin === "companyprofile" &&
      model.newEmail === "" && model.newEmail2 === model.newEmail)
    {
      return model;
    }
    let isValid = true;
    // check if all values are set
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined/*  && model[key] === '' */) {
        error[key] = '';
        // isValid = false;
      }
    });
    // new email
    const test = {};
    if (!validateEmail(model.newEmail, test)) {
      error.newEmail = test.error || 'ungültige E-Mail';
      isValid = false;
    }
    // new email = old email
    else if (model.newEmail === model.to_emails) {
      error.newEmail = "Neue E-Mail ist gleich die Alte"
      isValid = false;
    }
    console.log("Testing new email:", model, isValid, error);
    // new email repeated
    if (isValid && model.newEmail !== model.newEmail2) {
      error.newEmail2 = 'Beide E-Mails stimmen nicht überein';
      isValid = false;
    }

    this.setState({ error });
    if (!isValid) {
      return null;
    }
    return model;
  }
  getModel = () => {
    const result = this.validate();
    const model = (result === null) ? result : {
      ...this.props.model,
      newEmail: result.newEmail,
    };
    return model;
  }
  render() {
    const { className, model: { origin, username } } = this.props;
    const { to_emails, newEmail, newEmail2, error } = this.state;
    return (
      <div className={className}>
        <div className={``}>
          <Label>{`${origin === "companyprofile" ? "Firma" : "Benutzer"}`}</Label>
          <InputText
            className=""
            defaultValue={username}
            disabled
          />
        </div>
        <div className={`pad-top ${error.to_emails && 'error-group'}`}>
          <Label htmlFor="to_emails">{`Alte E-Mail`}<span>*</span></Label>
          <InputText
            id="to_emails"
            className=""
            value={to_emails}
            onChange={(event) => this.setState({to_emails: event.target.value})}
            disabled
          />
        </div>
        <div className={`pad-top ${error.newEmail && 'error-group'}`}>
          <Label htmlFor="newEmail">{`Neue E-Mail`}<span>*</span></Label>
          <InputText
            autoFocus
            id="newEmail"
            className=""
            value={newEmail}
            onChange={(event) => this.setState({newEmail: event.target.value})}
          />
          {error.newEmail && (<div className="pad-sm-top">{error.newEmail}</div>)}
        </div>
        <div className={`pad-top ${error.newEmail2 && 'error-group'}`}>
          <Label htmlFor="newEmail2">{`Neue E-Mail wiederholen`}<span>*</span></Label>
          <InputText
            id="newEmail2"
            className=""
            value={newEmail2}
            onChange={(event) => this.setState({newEmail2: event.target.value})}
          />
          {error.newEmail2 && (<div className="pad-sm-top">{error.newEmail2}</div>)}
        </div>
      </div>
    );
  }
}

