import React from 'react';
import PropTypes from 'prop-types';
import { MediaPreview } from '../../../components/MediaUploadForm';

export default class ProductImageOverlayPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    attributeName: PropTypes.string,
    model: PropTypes.shape({
      productName: PropTypes.string,
      files: PropTypes.array,
    }),
  }
  static defaultProps = {
    className: '',
    attributeName: 'productName',
    model: {
      productName: '',
      files: []
    }
  }
  render() {
    const { model, attributeName } = this.props;
    const title = model[attributeName] || '';
    let markup = (<div className="product-overlay-text">{title}</div>);
    if (model.files && model.files.length > 0) {
      const itemList = model.files.map((file, index) => (
        <div key={index} className="w3-cell w3-cell-top">
          <MediaPreview className="product-media-item" {...file}/>
        </div>
      ));
      markup = (
        <div className="pos-rel product-overlay-text">
          {title}
          <div className="media-holder">
            <div className="w3-block">{itemList}</div>
          </div>
        </div>
      );
    }
    return (<div className="product-media-overlay">{markup}</div>);
  }
} 