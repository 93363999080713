import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';

export default class PTConnectionTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      user: '',
      ptUsername: '',
      ptPassword: ''
    }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      ptUsername: model.ptUsername || '',
      ptPassword: model.ptPassword || '',
      error: {
        ptUsername: null,
        ptPassword: null,
      },
    };
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    let isChanged = false;
    let isValid = false;
    Object.keys(model).forEach(key => {
      if (model[key] !== originalModel[key]) {
        isChanged = true;
      }
      if (error[key] !== undefined && error[key] === '') {
        error[key] = true;
        isValid = true;
      }
    });
    this.setState({ error });
    if (!isValid && !isChanged) {
      return null;
    }
    return { ...originalModel, ...model };
  }
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  }
  render() {
    const { className, model } = this.props;
    const { ptUsername, ptPassword, error } = this.state;
    return (
      <div className={`${className} w3-large`}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Benutzer`}</Label>
            <InputText className="w3-block w3-border" value={model.user} disabled/>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.ptUsername ? ' error-group' : ''}`}>
            <Label htmlFor="ptUsername">{`Kennung*`}</Label>
            <InputText
              id="ptUsername"
              className="w3-block w3-border"
              value={ptUsername}
              onChange={(event) => this.setState({
                ptUsername: event.target.value,
                error: {...error, ptUsername: null}
              })}
            />
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.ptPassword ? ' error-group' : ''}`}>
            <Label htmlFor="ptPassword">{`Passwort*`}</Label>
            <InputText 
              id="ptPassword"
              className="w3-block w3-border"
              value={ptPassword}
              onChange={(event) => this.setState({
                ptPassword: event.target.value,
                error: {...error, ptPassword: null}
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}
