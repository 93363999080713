import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DebitorItem from '../../components/DebitorItem';
import { WizardButtonPanel } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { saveDebitorOrderInShoppingCart, updateOrderDebitor } from '../../actions';
import DebitorOrderOverview from '../../components/DebitorOrderOverview';

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    debitorOrder: state.modem.shoppingCart,
    selectedDebitor: state.modem.selectedDebitor,
    newModems: state.modem.newModems,
    orderDebitorDetails: state.modem.orderDebitorDetails
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveDebitorOrder: (...args) => dispatch(saveDebitorOrderInShoppingCart(...args)),
  updateOrderDebitor: (...args) => dispatch(updateOrderDebitor(...args)),
}); 

class OrderOverview extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    debitorOrder: PropTypes.object,
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    orderDebitorDetails: PropTypes.object,
    // actions
    saveDebitorOrder: PropTypes.func.isRequired,
    updateOrderDebitor: PropTypes.func.isRequired,
    // passed by parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    rows: 12
  }
  openDebitors = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  }
  openCatalog = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  onSave = () => {
    const { debitorOrder, saveDebitorOrder } = this.props;
    saveDebitorOrder(debitorOrder, this.openDebitors);
  }
  onUpdate = () => {
    const { newModems, selectedDebitor, updateOrderDebitor } = this.props;
    updateOrderDebitor({ debitor: selectedDebitor, modems: newModems }, this.openDebitors);
  }
  isDebitorComplete = (flag) => {
    this.btnPanel && this.btnPanel.disableBtn('next', !flag);
  }
  render() {
    const { className, isAdmin, selectedDebitor, newModems, orderDebitorDetails, cancelOrder, rows } = this.props;
    let modems = [];
    let onNext = null;
    let onPrevious = this.openCatalog;
    let disableIfAbsent = true;
    
    // order
    console.log('OrderOverview.render()', selectedDebitor, orderDebitorDetails);
    if (!selectedDebitor || !orderDebitorDetails) {
      return null;
    }
    // opening a locked order
    if (!isAdmin && orderDebitorDetails &&
      orderDebitorDetails.modems &&
      orderDebitorDetails.modems.length > 0 && 
      orderDebitorDetails.modems[0].status_id !== undefined &&
      orderDebitorDetails.modems[0].status_id !== 1
    ) {
      console.log('<<: Viewing a locked order:', orderDebitorDetails);
      // // this must be set
      // if (!orderDebitorDetails) {
      //   return null
      // }
      modems = orderDebitorDetails.modems.map((item, index) => ({ ...item, index}));
      onPrevious = this.openDebitors;
      disableIfAbsent = false;
    } else {
      console.log('<<: Viewing an editable order:', newModems);
      // newModems must be set by previous page (ModemCatalog)
      if (!newModems) {
        return null
      }
      modems = newModems;
      onNext = this.onUpdate;
    }
    // const approvedColumn = (selectedDebitor.status_id > 2) && (<Column field="approved" header="Freigegeben" className="alg-center"/>);
    return (
      <div className={`modem-shopping-cart clearfix`}>
        <DebitorOrderOverview
          className={className}
          title="Debitor Übersicht"
          hideDebitorSubtitle
          debitor={selectedDebitor}
          modems={modems}
          showApproved={selectedDebitor.status_id > 2}/>
        <WizardButtonPanel
          ref={ref => this.btnPanel = ref}
          className="margin-big-top w3-border pad-big w3-light-grey"
          onPrevious={onPrevious}
          onNext={onNext}
          onCancel={cancelOrder}
          nextLabel="Speichern"
          disableIfAbsent={disableIfAbsent}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
