import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, NavLink, Redirect, withRouter } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';

import { connect } from 'react-redux';
import { cancelModemOrder } from '../actions';

import ModemWizardTitle from './ModemWizardTitle';
import TaskDebitorList from './task/TaskDebitorList';
import TaskModemCatalog from './task/TaskModemCatalog';
import TaskModemOverview from './task/TaskModemOverview';

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  cancelOrder: (...args) => dispatch(cancelModemOrder(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  cancelOrder = () => {
    console.log('ModemOrderTaskWizard.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/aufgaben/modems');
  }
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            {/* <li><NavLink to={`debitoren`} className="disabled-link" activeClassName="active">{'Debitoren'}</NavLink></li> */}
            <li><NavLink to={`katalog`} className="disabled-link" activeClassName="active">{'Modems'}</NavLink></li>
            <li><NavLink to={`uebersicht`} className="disabled-link" activeClassName="active">{'Übersicht'}</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const { className, history, match } = this.props;
    // console.log('ModemOrderTaskWizard.render()', this.props);
    const Menu = this.renderMenu();
    const pageProps = { history, match, cancelOrder: this.cancelOrder };
    const DebitorListPage = () => (<TaskDebitorList {...pageProps}/>);
    const CatalogPage = () => (<TaskModemCatalog {...pageProps}/>);
    const OverviewPage = () => (<TaskModemOverview {...pageProps}/>);
    const redirectUrl = `/wizard/aufgabe/bestellung/modem/${match.params.id}/debitor/${match.params.debitorId || ''}/prev`;
    return (
      <div className={`modem-order-wizard clearfix ${className}`}>
        <ModemWizardTitle isTask/>
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <div className="pad-big-top">
              <Switch>
                <Route path="/wizard/aufgabe/bestellung/modem/:id/debitor/:debitorId/prev" exact component={DebitorListPage}/>
                <Route path="/wizard/aufgabe/bestellung/modem/:id/debitor/:debitorId/katalog" exact component={CatalogPage}/>
                <Route path="/wizard/aufgabe/bestellung/modem/:id/debitor/:debitorId/uebersicht" exact component={OverviewPage}/>
                <Route path="*" render={(props) => (<Redirect to={redirectUrl} />)}/>
              </Switch>
            </div>
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wizard));
