import React from 'react';
import PropTypes from 'prop-types';
// import Dashboard from '../../../../components/Dashboard';
// import CardWizardTitle from './CardWizardTitle';

// import CardCatalog from './wizard/CardCatalog';
import OrderOverview from './wizard/OrderOverview';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadCardOrder, cancelCardOrder } from '../../actions/cards';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getOrder({ id });
};
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadCardOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelCardOrder(...args)),
});

class Panel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  onCancel = () => {
    // console.log('CardOrderArchivePanel.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/shop/karten/uebersicht');
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`card-order-overview-panel clearfix ${className}`}>
        <OrderOverview
          isArchive
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Panel)
);
