import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import ContractorForm from './ContractorForm';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import {
  COMPANY_STATUS_IDS,
  COMPANY_STATUS_LABELS,
  TableEmptyMessage,
  addressToString,
  getCompanyDeleteText,
  isCompanyActivated,
  isCompanyDeactivated
} from '../../../../components/Utils';
import SalesTableUtil from '../../../Reporting/components/SalesTableUtil';


export default class ContractorListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    companies: PropTypes.array,
    getBaseData: PropTypes.func,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    exportSubCompanies: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10,
  }
  validate = () => {
    const regions = this.regions.getModel();
    const model = [ ...regions ];
    console.log('ContractorList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <Contractor
          ref={(ref) => this.regions = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class Contractor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    companies: PropTypes.array,
    getBaseData: PropTypes.func,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    exportSubCompanies: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    const items = (props.model || []);
    this.state = {
      items: items.map((item, index) => ({ ...item, index })),
      error: null,
      first: 0,
      filter: {
        status_id: []
      },
    };
  }
  getModel = () => {
    const { items } = this.state;
    return [ ...items ];
    // const model = items.map(item => {
    //   const { name, id } = item;
    //   const contractor = { name, id };
    //   return contractor;
    // });
    // return model;
  }
  toggleAddition = (disabled) => {
    this.setState({addDisabled: disabled});
  }
  toggleError = (error) => {
    this.setState({ error });
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving contractor ...', model);
    if (!model) return;
    const items = this.state.items;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    // console.log('>> deleting', selected);
    const items = [];
    this.state.items.forEach(item => {
      // console.log('>>>> comparing', item.name, 'with', selected.name);
      if (item.name !== selected.name) {
        // console.log('<<<< adding', item.name, '...');
        items.push(item);
      }
    });
    this.setState({ items });
  }
  onDeActivate = (selected, isActivated) => {
    const items = [];
    this.state.items.forEach(item => {
      if (item.name == selected.name) {
        item.status_id = isActivated ? COMPANY_STATUS_IDS.ACTIVATED : COMPANY_STATUS_IDS.DEACTIVATED;
      }
      items.push(item);
    });
    console.log("onDeActivate, selected", selected, items)
    this.setState({ items });
  }
  onExport = () => {
    const { getBaseData, exportSubCompanies } = this.props;
    const baseData = getBaseData ? getBaseData() : {};
    const company = baseData.getCompany ? baseData.getCompany() : {};
    console.log("onExport()", company);
    exportSubCompanies({ companyId: company.id})
  }
  onFilterChange = (field, value) => {
    console.log("onFilterChange, field, value", field, value);
    this.setState({
      filter: { [field]: value }
    });
  }
  render() {
    const { className, rows, getBaseData, companies, disabled } = this.props;
    const contractors = this.state.items;
    const { first, filter } = this.state;
    const filterKeys = Object.keys(filter);
    const onPage = (event) => this.setState({first: event.first});
    // console.log('ContractorList.render() items', this.state.items, "filter:", filter, filterKeys);

    const contractorList = companies.filter(item => {
      const found = contractors.find(contractor => contractor.id === item.id);
      return !found;
    });

    const status_id = [
      { label: COMPANY_STATUS_LABELS[COMPANY_STATUS_IDS.ACTIVATED], value: COMPANY_STATUS_IDS.ACTIVATED },
      { label: COMPANY_STATUS_LABELS[COMPANY_STATUS_IDS.DEACTIVATED], value: COMPANY_STATUS_IDS.DEACTIVATED }
    ];
    const optionMap = { status_id };
    const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(this.dt, contractors, optionMap, this.onFilterChange, filter.status_id);
    const statusFilter = useMultiSelectFilter('status_id');

    const renderItemTable = (items, actionTemplate) => {
      const data = items.map(item => {
      const address = Array.isArray(item.address) ? item.address[0] : item.address;
      return {
        ...item,
        address,
        addressStr: addressToString(address),
        status_id: item.status_id || COMPANY_STATUS_IDS.ACTIVATED,
        status: item.status || COMPANY_STATUS_LABELS[item.status_id || COMPANY_STATUS_IDS.ACTIVATED]
      };
    }).filter(item => {
      const isValid = [];
      for (const key of filterKeys) {
        if (Array.isArray(filter[key]) && filter[key].length > 0) {
          isValid.push(filter[key].includes(item[key]));
        }
      }
      if (isValid.length > 0) {
        for (const valid of isValid) {
          if (!valid) {
            return false;
          }
        }
      }
      return true;
    });
      return (
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Subunternehmen"
              itemName="Subunternehmen"
              itemNamePlural="Subunternehmen"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Subunternehmen" min/>)}
        >
          <Column
            field="name"
            header="Firma"
            sortable={data.length > 1}
            filter
            filterMatchMode="contains"
          />
          <Column
            field="addressStr"
            header="Adresse"
            sortable={data.length > 1}
            filter
            filterMatchMode="contains"
          />
          <Column
            field="activeEmployees" // "totalEmployees"
            header="Aktive Mitarbeiter"
            sortable={data.length > 1}
            // filter={data.length > 1}
            // filterMatchMode="contains"
            style={{textAlign:'center', width: '8em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter
            // filterMatchMode="contains"
            {...statusFilter}
            body={rowData => rowData.status}
            style={{textAlign:'center', width: '9em'}}
          />
          {!disabled && (
            <Column
              body={actionTemplate}
              style={{textAlign:'center', width: '10em'}}
            />
          )}
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const contractor = item || {
        index: contractors.length,
        name: '',
        type_id: 4,
        groups: [],
      };
      const baseData = getBaseData ? getBaseData() : {};
      return (
        <div>
          <ContractorForm ref={(ref) => this.form = ref}
            key={Math.random()}
            model={contractor}
            companies={contractorList}
            {...baseData}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey" 
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveContractorBtn"/>
        </div>
      );
    };
    const getItemFormTitle = (contractor) => {
      return (<h3 className="no-margin">{`Subunternehmen ${contractor ? 'bearbeiten' : 'hinzufügen'}`}</h3>);
    }
    const titleClassName = `no-margin-top`;
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        // title={(<h3 className={titleClassName}>Auftragnehmer (NAN)</h3>)}
        title={(<h3 className={titleClassName}>{"Subunternehmen"}</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addContractorBtn"
        addBtnHidden={disabled}
        exportBtnName="exportContractorsBtn"
        exportBtnHidden={disabled}
        onExport={() => this.onExport()}
        items={contractors}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="contractor"
        itemDeleteable={!disabled}
        isDeleteDisabled={(item) => {
          // if (!item.id) {
          //   return false;
          // }
          return !isCompanyDeactivated(item); // item.totalEmployees > 0;
        }}
        deleteTitle="Subunternehmen löschen"
        getDeleteText={(item) => getCompanyDeleteText(item, true)}
        onDelete={this.onDelete}
        itemDeactivateable={!disabled}
        showDisabledDeActivate
        activateTitle="Subunternehmen aktivieren"
        getActivateText={(item) => `Wollen Sie das Subunternehmen ${item.name} wirklich aktivieren?`}
        isActivateDisabled={item => isCompanyActivated(item)}
        // isActivateDisabled={(item) => {
        //   console.log("Is Activation disabled, company:", item);
        //   // if contractor is new, then it has no employees and is therefore deactivateable
        //   if (!item.id) {
        //     return true;
        //   }
        //   return isCompanyActivated(item);
        // }}
        deactivateTitle="Subunternehmen deaktivieren"
        getDeactivateText={(item) => `Wollen Sie das Subunternehmen ${item.name} wirklich deaktivieren?`}
        isDeactivateDisabled={(item) => (!isCompanyActivated(item) || item.activeEmployees > 0)}
        onDeActivate={this.onDeActivate}
      />
    );
  }
}
