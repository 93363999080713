import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from '../../../components/Inputs';

export default class SalesFilterForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    companies: PropTypes.array,
    settings: PropTypes.object
  }
  static defaultProps = {
    className: '',
    companies: [],
    settings: {}
  }
  constructor(props) {
    super(props);
    const settings = props.settings || {};
    this.state = {
      company: settings.company || '',
      weborder_id: settings.weborderId || ''
    };
  }
  getModel = () => {
    const { company, weborder_id} = this.state;
    return {
      company,
      weborder_id
    };
  }
  reset = () => {
    this.setState({
      company: '',
      weborder_id: ''
    });
  }
  render() {
    const { className, companies } = this.props;
    const { company, weborder_id } = this.state;
    const companyOptions = [
      { label: 'Alle', value: '' }
    ].concat(companies.map(item => ({
      label: item.name,
      value: item.name
    })));
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">
            <Label htmlFor="company">{`KDL`}</Label>
            <Dropdown
              id="company"
              className="w3-block w3-border"
              value={company}
              placeholder="Bitte auswählen"
              options={companyOptions}
              onChange={(event) => this.setState({company: event.value})}
              showClear
            />
          </div>
          <div className="w3-col s4 pad-lr">
            <Label htmlFor="weborder_id">{`Weborder-ID IAV`}</Label>
            <InputText
              id="weborder_id"
              className="w3-block w3-border"
              value={weborder_id}
              onChange={(event) => this.setState({weborder_id: event.target.value})}
            />
          </div>
          <div className="w3-col s4 pad-lr"></div>
        </div>
      </div>
    );
  }
}