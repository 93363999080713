import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
// import { Calendar } from 'primereact/calendar';
import { InputText, Label, Dropdown, Calendar } from '../../../../components/Inputs';
import { toDBDate } from '../../../../components/Utils';

export default class TechKeyForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({})
  }
  static defaultProps = {
    className: '',
    model: {
      audit_date: '',
      controlNeeded: false,
      isControlled: false,
      control_date: '',
    }
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      audit_date: model.audit_date || '',
      controlNeeded: model.controlNeeded || false,
      isControlled: model.isControlled || false,
      control_date: model.audit_date || '',
      error: {
        audit_date: null,
        controlNeeded: null,
        // isControlled: null,
        control_date: null,
      },
      required: {
        audit_date: null,
        controlNeeded: null,
      }
    };
  }
  validate = () => {
    const { error, requred, ...others } = this.state;
    let model = { ...others };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (requred[key] && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (model.controlNeeded === true && model.isControlled === true) {
      if (model.control_date === '') {
        error.control_date = true;
        isValid = false;
      }
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    // convert dates
    model.audit_date = toDBDate(model.audit_date, 'DD.MM.YYYY');
    if (model.control_date !== '') {
      model.control_date = toDBDate(model.control_date, 'DD.MM.YYYY');
    }
    //
    const { id, index } = this.props.model;
    return { id, index, ...model };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className } = this.props
    const { audit_date, controlNeeded, isControlled, control_date, error} = this.state;
    // const KeyOptions = techKeys.map(item => ({
    //   label: `${item.name}`,
    //   value: item.name
    // }));
    const ControlNeededOptions = [
      { label: 'fällig', value: true },
      { label: 'nicht fällig', value: false },
    ];
    const IsControledOptions = [
      { label: 'Ja', value: true },
      { label: 'Nein', value: false },
    ];
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row pad-top-">
            <div className="w3-col s6 pad-lr">
              <div style={{minHeight: '360px'}}>
                <div className={`pad-top- form-group-item ${error.audit_date ? 'error-group' : ''}`}>
                  <Label htmlFor= "audit_date">{`Vor-Ort-Audit am*`}</Label>
                  <Calendar
                    id="audit_date"
                    className="w3-block w3-border"
                    value={audit_date}
                    // readOnlyInput={true}
                    dateFormat="dd.mm.yy"
                    // maxDate={new Date()}
                    onChange={(event) => this.setState({ audit_date: event.value })}
                  />
                </div>
                <div className={`pad-top form-group-item ${error.controlNeeded ? 'error-group' : ''}`}>
                  <Label htmlFor="controlNeeded">{`Nachkontrolle*`}</Label>
                  <Dropdown
                    id="controlNeeded"
                    className="w3-block w3-border"
                    value={controlNeeded}
                    placeholder="Bitte auswählen"
                    options={ControlNeededOptions}
                    onChange={(event) => this.setState({ controlNeeded: event.value })}
                    showClear
                  />
                </div>
              </div>
              {controlNeeded && (
              <div style={{minHeight: '150px'}}>
                <div className={`form-group-item`}>
                  <Label htmlFor="isControlled">{`Wurde nachkontrolliert?`}</Label>
                  <Dropdown
                    id="isControlled"
                    className="w3-block w3-border"
                    value={isControlled}
                    placeholder="Bitte auswählen"
                    options={IsControledOptions}
                    onChange={(event) => this.setState({ isControlled: event.value })}
                    showClear
                  />
                </div>
                <div className={`pad-top form-group-item ${error.control_date ? 'error-group' : ''}`}>
                  <Label htmlFor= "control_date">{`nachkontrolliert am${isControlled ? '*' : ''}`}</Label>
                  <Calendar
                    id="control_date"
                    className="w3-block w3-border"
                    value={control_date}
                    // readOnlyInput={true}
                    dateFormat="dd.mm.yy"
                    // maxDate={new Date()}
                    onChange={(event) => this.setState({ control_date: event.value })}
                  />
                </div>
              </div>
              )}
            </div>
            <div className="w3-col s6 pad-lr">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
