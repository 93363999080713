import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

class AppFooter extends React.Component {
  static propTypes = {
    showLinks: PropTypes.bool
  }

  static defaultProps = {
    showLinks: false
  }

  render() {
    const { showLinks } = this.props;
    
    let links;
    if ( showLinks ) {
      links = (
        <div className="footer-links pad-top pad-big-btm">
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
          <Link to="/abkuerzungen">Abkürzungen</Link>
          <Link to="/kontakt">Kontakt</Link>
        </div>
      );
    } else {
      links = (
        <div className="footer-links pad-top pad-big-btm">
          <Link to="/impressum">Impressum</Link>
        </div>
      );
    }
    links = (<div className="w3-right pad-big-top">{links}</div>);

    return (
      <footer className="app-footer">
        <div className="container">
          <div className="w3-cell-row">
            <div className="w3-cell w3-cell-middle pad-big-top" style={{width: '100px'}}>
              <div className="title pad-big-top">AN-Portal</div>
            </div>
            <div className="w3-cell w3-cell-middle">{links}</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default AppFooter;
