import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  taskWeek: state.modem.taskWeek,
});
const mapDispatchToProps = null;

export class ModemAdminTaskTitle extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    taskWeek: PropTypes.object,
    debitorKey: PropTypes.string,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: 'no-margin'
  }
  render() {
    const { className, taskWeek, debitorKey } = this.props;
    if (!taskWeek) return null;
    const key = debitorKey ? `: ${debitorKey}` : '';
    const week = `${taskWeek.week < 10 ? '0' : ''}${taskWeek.week}`
    return (
      <h3 className={className}>{`Aktuelle Bestellungen für KW${week}, ${taskWeek.year}${key}`}</h3>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemAdminTaskTitle);
