import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton } from './Buttons';

export default class InformationDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.node
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      isVisible: false
    }
  }
  show = (isVisible, data) => {
    const newState = data || {};
    this.setState({
      ...newState,
      isVisible
    });
  }
  onHide = () => {
    this.setState({isVisible: false});
  }
  render() {
    const { title, text, isVisible } = this.state;
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton className="margin-lft" onClick={() => this.onHide()}>Schliessen</PrimaryButton>
      </div>
    );
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}>
        {text}
      </Dialog>
    );
  }
}
