import React from "react";
import PropTypes from "prop-types";
// import BasicPageContentForm from "./content/BasicPageContentForm";
// import ProductColumnForm from "./products/ProductColumnForm";
import ProductColumnContent from "./products/ProductColumnContent";

export default class ProductPageForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		subLinks: PropTypes.array,
		editPath: PropTypes.func.isRequired,
		saveToArchive: PropTypes.func.isRequired,
	};
	static defaultProps = {
		className: "",
		model: {
			title: "",
			contents: [],
		},
		subLinks: [],
	};
	constructor(props) {
		super();
		const { model } = props;
		let contents = model.contents || [];
		this.state = { contents };
	}
	validate = () => {
		const { contents } = this.state;
		let isValid = true;
		// basic data
		const model = this.basicData.getModel();
		if (!model) {
			return null;
		}
		// product list
		model.contents = [];
		for (const index in contents) {
			if (this[`productForm${index}`]) {
				const data = this[`productForm${index}`].getModel();
				if (!data) {
					isValid = false;
				}
				model.contents.push({ ...data, position: parseInt(index) + 1 });
			}
		}
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		// console.log('ProductFormPage model', model);
		return model;
	};
	getModel = () => {
		const model = this.validate();
		// set model id if present
		const { id } = this.props.model;
		if (id) {
			model.id = id;
			model.contents.map((content) => ({
				...content,
				page_id: id,
			}));
		}
		return model;
	};
	onChange = (columnIndex, productIndex, product) => {
		console.log(
			"ProductPageForm.onChange()",
			columnIndex,
			productIndex,
			product
		);
	};
	render() {
		console.log("ProductPageForm.render()", this.props);
		const { className, model, editPath, saveToArchive } = this.props;

		const style = { width: `${100 / model.contents.length}%` };
		const markup = model.contents.map((item, columnIndex) => {
			return (
				<div key={columnIndex} className="w3-col pad-lr" style={style}>
					<ProductColumnContent
						model={item}
						onChange={(productIndex, product) => this.onChange(columnIndex, productIndex, product)}
						editMode={true}
						editPath={editPath}
						saveToArchive={saveToArchive}
					/>
				</div>
			);
		});
		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">{markup}</div>
			</div>
		);
	}
}
