import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, ButtonLink } from '../../../../components/Buttons';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../../components/W3Dropdown';
import { ConnectionTypes } from '../Utils';
import ITTaskDialog from './ITTaskDialog';
import ActionFilter from '../ActionFilter';

export default class ITConnectionTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedDelphiConnections: PropTypes.array,
    connectionTaskType: PropTypes.string,
    exportDelphiConnections: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    actions: {},
    items: [],
  }
  onExport = (event) => {
    if (event) event.preventDefault();
    const type = 'Delphi';
    const { selectedDelphiConnections } = this.props;
    let counter = 0;
    if (selectedDelphiConnections && selectedDelphiConnections.length > 0) {
      counter += selectedDelphiConnections.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `${type}-Zugänge exportieren`,
        text: `
          Alle ${counter} ausgewählte ${type}-Zugänge werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `,
        type: type.toUpperCase(),
        showValidity: true,
        taskType: this.props.connectionTaskType,
        showTaskType: false
      },
      (data) => this.props.exportDelphiConnections(data)
    );
  }
  render() {
    const { className, selectedDelphiConnections, filterConnections, connectionTaskType, items } = this.props;
    const isDelphiExportable = (selectedDelphiConnections && selectedDelphiConnections.length > 0);
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-rht">
            <ActionFilter
              type="Delphi"
              taskType={connectionTaskType}
              setTaskType={filterConnections}
              itemsToCount={items}
              className="display-inline-block"
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler as={PrimaryButton} size="small-">{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '280px'}}>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onExport}
                  disabled={!isDelphiExportable}>
                    {`Delphi-Zugänge exportieren (CSV)`}
                </ButtonLink>
                <ButtonLink to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  to={`/wizard/aufgabe/import/${ConnectionTypes.DELPHI_CONNECTION.toLowerCase()}`}
                  disabled={false}>
                    {`Delphi-Zugänge importieren (CSV)`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ITTaskDialog ref={ref => this.taskDialog = ref}/>
      </div>
    );
  }
}
