import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditIconNavButton } from '../../../components/Buttons';
import { createSortById, scrollToTop, TableEmptyMessage, toUIDate } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadNews } from '../actions';

const frontload = async (props) => {
  console.log('getNews()', props);
  await props.getNews({ categoryName: props.categoryName });
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    newsSummaries: state.news.teasers
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNews: (...args) => dispatch(loadNews(...args))
});

class NewsArticleTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    newsSummaries: PropTypes.array,
    getNews: PropTypes.func,
    rows: PropTypes.number,
    className: PropTypes.string,
    categoryName: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: '',
    categoryName: 'General'
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onEditModem = (newsItem, history) => {
    console.log('Editing', newsItem);
    let url = `/organisation/news-service/beitrag/${encodeURIComponent(newsItem.id)}`;
    if (this.props.categoryName === 'Sales') {
      url = `/sales/news-feed/beitrag/${encodeURIComponent(newsItem.id)}`;
    }
    history.push(url);
  }
  actionTemplate = (rowData/* , column */) => {
    return (
      <div>
        <EditIconNavButton onClick={(history) => this.onEditModem(rowData, history)}/>
      </div>
    );
  }
  render() {
    const { className, isLoading, newsSummaries, rows } = this.props;
    // console.log('NewsArticleTable.render()', news);
    const data = (newsSummaries || []).map((newsItem, index) => ({
      ...newsItem,
      index,
      changeDate: toUIDate(newsItem.modified_date || newsItem.publication_date)
    }))
    // sort by id in descending order
    .sort(createSortById(true));
    return (
      <div className={`news-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginator={data.length > rows}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Beiträge"
              itemName="Beitrag"
              itemNamePlural="Beiträge"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Beiträge"/>)}
        >
          <Column
            field="title"
            header="Titel"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="visibility"
            header="Sichtbarkeit"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '10em'}}
          /> 
          <Column
            field="changeDate"
            header="Zuletzt geändert"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          />
          <Column
            body={this.actionTemplate}
            className="alg-center"
            style={{width: '6em'}}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(NewsArticleTable)
);
