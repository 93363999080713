
const item = {
  debitorKey: 'R125-',
  name: 'Debitor ',
  address: { street: 'Teststr.', houseNumber: '1', areaCode: '40215', city: 'Debitorstadt'}
};
const debitors = [];
for (let index = 1; index <= 10; index ++) {
  debitors.push({
    ...item,
    id: index,
    debitorKey: item.debitorKey + index,
    name: item.name + index
  });
}

export default debitors;