import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileIconButton } from '../../../components/Buttons';
import { scrollToTop, TableEmptyMessage, toUIDate } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadNewsArchives, searchNewsArchives, loadNewsMessage } from '../actions';


const getFilterValues = (props, current) => {
  let values =  {
    // first: 0,
    // rows: props.rows || 10,
    keyword: null,
    fromDate: null,
    toDate: null,
    // sortFields: {}
  };
  if (current) {
    values = { ...values, ...current };
  }
  return values;
};


const frontload = async (props) => {
  console.log('getNewsArchive()', props);
  const filter = getFilterValues(props);
  await props.getNewsArchive({ filter });
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    archives: state.news.archives,
    filter: state.news.archiveFilter
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNewsArchive: (...args) => dispatch(loadNewsArchives(...args)),
  searchArchives: (...args) => dispatch(searchNewsArchives(...args)),
  getNewsMessage: (...args) => dispatch(loadNewsMessage(...args))
});

class NewsArchiveTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    archives: PropTypes.array,
    filter: PropTypes.shape({
      text: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({})
    }).isRequired,
    getNewsArchive: PropTypes.func.isRequired,
    searchArchives: PropTypes.func.isRequired,
    getNewsMessage: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      first: 0
    };
  }
  componentDidUpdate(prevProps) {
    const { filter, getNewsArchive } = this.props;
    // console.log(">> NewsArchiveTable.componentDidUpdate() newFilter:", filter, "oldFilter:", prevProps.filter);
    scrollToTop();
    if (JSON.stringify(filter) !== JSON.stringify(prevProps.filter)) {
      // console.log("<< filter changed => refetching data ...");
      getNewsArchive({ filter });
    }
  }
  // shouldComponentUpdate(nextProps) {
  //   const filterChanged = (JSON.stringify(nextProps.filter) !== JSON.stringify(this.props.filter));
  //   const archivesChanged = (JSON.stringify(nextProps.archives) !== JSON.stringify(this.props.archives))
  //   console.log(">> NewsArchiveTable.shouldComponentUpdate()",
  //     "newFilter:", nextProps.filter, "oldFilter:", this.props.filter, "changed:", filterChanged,
  //     "newArchives:", nextProps.archives, "oldArchives:", this.props.archives, "changed:", archivesChanged
  //   );
  //   // rerender only when props.filter or props.archives changes
  //   return filterChanged || archivesChanged;
  // }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowMessage = (newsItem, history) => {
    this.props.getNewsMessage(newsItem);
  }
  actionTemplate = (rowData) => {
    return (
      <div>
        <FileIconButton className="pad-sm" onClick={() => this.onShowMessage(rowData)}/>
      </div>
    );
  }
  render() {
    const { className, isLoading, archives, rows, filter } = this.props;
    if (!archives) {
      return null;
    }
    console.log('NewsArchiveTable.render()', filter, archives);
    const data = (archives || []).map((newsItem, index) => ({
      ...newsItem,
      index,
      roles: newsItem.roles,
      title: `${newsItem.titlePrefix||''} ${newsItem.title||'Meldung'}`,
      sendDate: toUIDate(newsItem.sent_date || newsItem.sendDate || newsItem.send_date || newsItem.created_date)
    }));

    return (
      <div className={`news-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Nachrichten"
              itemName="Nachricht"
              itemNamePlural="Nachrichten"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Nachrichten"/>)}
        >
          <Column
            field="title"
            header="Betreff"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="sendDate"
            header="Versandt am"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '12em'}}
          />
          <Column
            body={this.actionTemplate}
            className="alg-center"
            style={{width: '6em'}}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(NewsArchiveTable)
);
