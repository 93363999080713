import { DEFAULT_CARD_ORDER, DEFAULT_CARD } from "../components";
import { OrderManager } from './data';
import { PRODUCT_TYPE_IDS } from "../../../components/Utils";
// import { loadCompanies } from "../../Company/actions";

export const LOAD_CARDS = 'cards/load';
export const LOAD_CARDS_SUCCESS = 'cards/load/success';
export const LOAD_CARDS_FAILURE = 'cards/load/failure';

export const LOAD_CARD = 'card/load';
export const LOAD_CARD_SUCCESS = 'card/load/success';
export const LOAD_CARD_FAILURE = 'card/load/failure';

export const SAVE_CARD = 'cards/save';
export const SAVE_CARD_SUCCESS = 'cards/save/success';
export const SAVE_CARD_FAILURE = 'cards/save/failure';

export const CANCEL_CARD = 'card/cancel';

export const DELETE_CARD = 'cards/delete';
export const DELETE_CARD_SUCCESS = 'cards/delete/success';
export const DELETE_CARD_FAILURE = 'cards/delete/failure';

export const SELECT_CARD = 'cards/select';
export const CANCEL_CARD_SELECTION = 'cards/select/cancel';

export const CHECK_USAGE = 'cards/usage/check';
export const CHECK_USAGE_SUCCESS = 'cards/usage/check/success';
export const CHECK_USAGE_FAILURE = 'cards/usage/check/failure';

export const CHANGE_STATUS = 'cards/status/change';
export const CHANGE_STATUS_SUCCESS = 'cards/status/change/success';
export const CHANGE_STATUS_FAILURE = 'cards/status/change/failure';


export const LOAD_CARD_ORDERS = 'cards/orders/load';
export const LOAD_CARD_ORDERS_SUCCESS = 'cards/orders/load/success';
export const LOAD_CARD_ORDERS_FAILURE = 'cards/orders/load/failure';

export const LOAD_CARD_ORDER = 'card/order/load';
export const LOAD_CARD_ORDER_SUCCESS = 'card/order/load/success';
export const LOAD_CARD_ORDER_FAILURE = 'card/order/load/failure';

export const LOAD_ADDRESSES = 'cards/order/addresses/load';
export const LOAD_ADDRESSES_SUCCESS = 'cards/order/addresses/load/success';
export const LOAD_ADDRESSES_FAILURE = 'cards/order/addresses/load/failure';

export const SAVE_CARD_ORDER_CATALOG = 'cards/order/catalog/save';
export const SAVE_CARD_ORDER_ADDRESS = 'cards/order/address/save';

export const SAVE_CARD_ORDER = 'cards/order/save';
export const SAVE_CARD_ORDER_SUCCESS = 'cards/order/save/success';
export const SAVE_CARD_ORDER_FAILURE = 'cards/order/save/failure';

export const CANCEL_CARD_ORDER = 'cards/orders/cancel';

export const LOAD_ORDER_AVIS_FILE = 'cards/orders/avis/load';
export const LOAD_ORDER_AVIS_FILE_SUCCESS = 'cards/orders/avis/load/success';
export const LOAD_ORDER_AVIS_FILE_FAILURE = 'cards/orders/avis/load/failure';

export const SET_WIZARD_PAGE  = 'cards/wizard/page/set';

/**
 * Loads all cards
 */
export const loadCards = (onSuccess) => {
  console.log('REDUX-ACTION loadCards()');
  const type = 'flyer';
  return {
    types: [
      LOAD_CARDS,
      LOAD_CARDS_SUCCESS,
      LOAD_CARDS_FAILURE
    ],
    promise: (client) => client.get(`/fccShops/${type}/catalogs/0?status=all`),
    onSuccess
  }
};

/**
 * Load a card by its name
 * @param {Object} param0 object containing ID of card to be loaded
 */
export const loadCard = (card = { id }) => {
  console.log('REDUX-ACTION loadCard()', card);
  const cardId = card.id || 'neu';
  if (cardId === 'neu') {
    const result = { ...DEFAULT_CARD };
    // console.log('<< Card:', result)
    return { type: LOAD_CARD_SUCCESS, result };
  }
  const type = 'flyer';
  return {
    types: [
      LOAD_CARD,
      LOAD_CARD_SUCCESS,
      LOAD_CARD_FAILURE
    ],
    promise: (client) => client.get(`/fccShops/${type}/catalogs/${encodeURIComponent(cardId)}?status=all`)
  };
};

/**
 * Save a card
 * @param {*} card the card to be saved
 */
export const saveCard = (card = {}, onSuccess) => {
  console.log('REDUX-ACTION saveCard()', card);
  const { files, type, type_id, ...others } = card;
  const data = {
    ...others,
    type_id: type_id || PRODUCT_TYPE_IDS.FLYER
  };
  if (files && files.length > 0) {
    data.image_paths = JSON.stringify(files);
  }
  return {
    types: [
      SAVE_CARD,
      SAVE_CARD_SUCCESS,
      SAVE_CARD_FAILURE
    ],
    promise: (client) => client.put(`/fccShops/${type || 'flyer'}/catalogs`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadCards());
      onSuccess && onSuccess();
    },
    card
  };
};

/**
 * Cancel a card
 */
export const cancelCard = () => {
  return {
    type: CANCEL_CARD
  };
};

/**
 * Delete a card
 * @param {*} card the card to be deleted
 */
export const deleteCard = (card = {}, onSuccess) => {
  console.log('REDUX-ACTION deleteCard()', card);
  const type = 'flyer';
  return {
    types: [
      DELETE_CARD,
      DELETE_CARD_SUCCESS,
      DELETE_CARD_FAILURE
    ],
    promise: (client) => client.del(`/fccShops/${type}/catalogs/${card.id}/remove`),
    onSuccess: (dispatch) => {
      dispatch(loadCards());
      onSuccess && onSuccess();
    },
    card
  };
};

/**
 * select a card
 */
export const selectCard = ({ card, purpose }) => {
  return {
    type: SELECT_CARD,
    card,
    purpose
  };
};

/**
 * Cancel a card selection
 */
export const cancelCardSelection = () => {
  return {
    type: CANCEL_CARD_SELECTION
  };
};

/**
 * Get all open orders that depend on a particular card
 * @param {object} card 
 */
export const checkCardUsage = (card = {}) => {
  console.log('REDUX-ACTION checkCardUsage()', card);
  const type = 'flyer';
  return {
    types: [
      CHECK_USAGE,
      CHECK_USAGE_SUCCESS,
      CHECK_USAGE_FAILURE
    ],
    promise: (client) => client.get(`/fccShops/${type}/catalogs/${card.id}/inUsed`),
    card
  };
};

/**
 * select a card for deletion
 * @param {object} card 
 */
export const selectCardForDeletion = (card) => {
  return (dispatch) => {
    dispatch(checkCardUsage(card));
    dispatch(selectCard({ card, purpose: 'delete' }));
  }
};

/**
 * Change the status of a card
 * @param {*} card 
 * @param {*} onSuccess 
 */
export const changeCardStatus = (card = {}, onSuccess) => {
  console.log('REDUX-ACTION changeCardStatus()', card);
  const { id, type_id, status_id } = card;
  const data = [{ id, type_id, status_id }];
  const type = 'flyer';
  return {
    types: [
      CHANGE_STATUS,
      CHANGE_STATUS_SUCCESS,
      CHANGE_STATUS_FAILURE
    ],
    promise: (client) => client.post(`/fccShops/${type}/catalogs/${card.id}/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadCards());
      onSuccess && onSuccess();
    },
    card
  };
};

/**
 * Loads all card orders
 */
export const loadAllCardOrders = () => {
  console.log('REDUX-ACTION loadAllCardOrders()');
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('<< ERROR: Operation is not allowed!');
      return dispatch({
        type: LOAD_CARD_ORDERS_FAILURE,
        result: {
          message: 'Nicht erlaubt'
        }
      });
    }
    const productType = 'flyer';
    dispatch({
      types: [
        LOAD_CARD_ORDERS,
        LOAD_CARD_ORDERS_SUCCESS,
        LOAD_CARD_ORDERS_FAILURE
      ],
     promise: (client) => client.get(`/fccShops/companies/0/summaries/productTypes/${productType}?related=true`)
    });
  }
};

/**
 * Loads a user's card orders
 */
export const loadCardOrders = (onSuccess, onFailure) => {
  console.log('REDUX-ACTION loadCardOrders()');
  return (dispatch, getState) => {
    const company = getState().company.company;
    if (!company) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return onFailure && onFailure();
    }
    const productType = 'flyer';
    return dispatch({
      types: [
        LOAD_CARD_ORDERS,
        LOAD_CARD_ORDERS_SUCCESS,
        LOAD_CARD_ORDERS_FAILURE
      ],
      promise: (client) => client.get(`/fccShops/companies/${company.id}/summaries/productTypes/${productType}?related=true`),
      onSuccess: () => onSuccess && onSuccess()
    });
  };
};

/**
 * Loads a card order
 */
export const loadCardOrder = ({ id }, onSuccess, onFailure) => {
  console.log('REDUX-ACTION loadCardOrder()', id);
  return (dispatch, getState) => {
    const fetch = () => {
      const cardId = id || 'neu';
      if (cardId === 'neu') {
        const result = getState().card.cardOrder || { ...DEFAULT_CARD_ORDER };
        console.log('<< CardOrder:', result)
        return dispatch({ type: LOAD_CARD_ORDER_SUCCESS, result });
      }
      const isAdmin = getState().auth.isAdmin;
      const company = getState().company.company;
      if (!company) {
        console.error('COMPANY_NOT_SET: session was not correctly initialized!');
        return onFailure && onFailure();
      }
      const companyId = isAdmin ? 0 : company.id;
      const productType = 'flyer';
      return dispatch({
        types: [
          LOAD_CARD_ORDER,
          LOAD_CARD_ORDER_SUCCESS,
          LOAD_CARD_ORDER_FAILURE
        ],
        promise: (client) => client.get(`/fccShops/companies/${companyId}/productTypes/${productType}/orders/${cardId}`),
        onSuccess: () => onSuccess && onSuccess() 
      });
    }
    const cards = getState().card.cards;
    if (!cards || cards.length === 0) {
      dispatch(loadCards(fetch));
    } else {
      fetch();
    }
  };
};

/**
 * Save the cards in an order
 * @param {*} param0 
 * @param {*} onSuccess 
 */
export const saveOrderCatalog = ({ items }, onSuccess) => {
  console.log('REDUX-ACTION saveOrderCatalog()', items);
  return (dispatch) => {
    dispatch({
      type: SAVE_CARD_ORDER_CATALOG,
      result: items
    });
    onSuccess && onSuccess(dispatch);
  };
};

/**
 * Get Data for creating the order address
 */
export const getOrderAddressData = (props = {}) => {
  console.log('REDUX-ACTION getOrderAddressData()');
  return (dispatch) => {
    // const companyName = props.companyName;
    // if (!companyName) {
    //   console.error('COMPANY_NOT_SET: company name must be given!');
    //   return;
    // }
    const companyId = props.companyId;
    if (!companyId) {
      console.error('COMPANY_NOT_SET: company id must be given!');
      return;
    }
    const productType = 'flyer';
    dispatch({
      types: [
        LOAD_ADDRESSES,
        LOAD_ADDRESSES_SUCCESS,
        LOAD_ADDRESSES_FAILURE
      ],
      promise: (client) => client.get(`/fccShops/companies/${companyId}/productTypes/${productType}/addresses/0`)
    });
  };
};

/**
 * Save the address in an order
 * @param {*} param0 
 * @param {*} onSuccess 
 */
export const saveOrderAddress = ({ address }, onSuccess) => {
  console.log('REDUX-ACTION saveOrderAddress()', address);
  return (dispatch) => {
    dispatch({
      type: SAVE_CARD_ORDER_ADDRESS,
      result: address
    });
    onSuccess && onSuccess(dispatch);
  };
}

/**
 * Saves a card order
 */
export const saveCardOrder = ({ cardOrder, type }, onSuccess) => {
  console.log('REDUX-ACTION saveCardOrder()', cardOrder);
  return (dispatch, getState) => {
    const company = getState().company.company;
    if (!company) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return; // onFailure && onFailure();
    }
    const productType = type || 'flyer';
    const data = cardOrder;
    const companyId = data.companyId || company.id;
    return dispatch({
      types: [
        SAVE_CARD_ORDER,
        SAVE_CARD_ORDER_SUCCESS,
        SAVE_CARD_ORDER_FAILURE
      ],
      promise: (client) => client.put(`/fccShops/companies/${companyId}/productTypes/${productType}/orders`, { data }),
      onSuccess: (dispatch) => {
        dispatch(loadCardOrders());
        onSuccess && onSuccess();
      },
      cardOrder
    });
  };
};

/**
 * Cancel a card order
 */
export const cancelCardOrder = () => {
  return {
    type: CANCEL_CARD_ORDER
  };
};

export const loadOrderAVISFile = ({ orderNumber, avis_path }) => {
  console.log(`REDUX-ACTION: loadOrderAVISFile(${orderNumber}) ...`);
  return {
    type: LOAD_ORDER_AVIS_FILE_SUCCESS,
    orderNumber,
    avis_path
  };
  // return {
  //   types: [ LOAD_ORDER_AVIS_FILE, LOAD_ORDER_AVIS_FILE_SUCCESS, LOAD_ORDER_AVIS_FILE_FAILURE],
  //   promise: (client) => client.get(`/products/cards/orders/${orderNumber}/load/avis`),
  //   orderNumber
  //   // onSuccess
  // };
}

export const setWizardPage = ({ wizardType, page }) => {
  return {
    type: SET_WIZARD_PAGE,
    wizardType,
    page
  };
};
