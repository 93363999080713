import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '../../../components/Inputs';

export default class ProductAmount extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    model: PropTypes.shape({}),
    disabled: PropTypes.bool,
    isTask: PropTypes.bool,
    onChange: PropTypes.func,
    minAmount: PropTypes.number,
  }
  static defaultProps = {
    model: {
      amount: 0,
      approved: null
    },
    onChange: null,
    minAmount: 1
  }
  constructor(props) {
    super(props);
    // this.state = {
    //   value: props.model.approved || props.model.amount || '',
    //   error: null
    // };
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    // console.log('ProductAmount.componentWillReceiveProps()', props.value);
    // this.setState({ value: props.model.approved || props.model.amount || ''});
    this.setState(this.getState(props));
  }
  getState = (props) => {
    // const value = props.model[props.isTask ? 'approved' : 'amount'] || '';
    let value = props.model.amount || '';
    if (props.isTask) {
      value = props.model.approved;
      // console.log('>> Approved value:', value);
      if (value === null || value === undefined) {
        value = '';
      }
    }
    const error = null;
    return { value, error };
  }
  getValue = () => {
    let value = this.state.value;
    let error = null;
    if (value !== '') {
      value = parseInt(value);
      if (isNaN(value) || value < this.props.minAmount) {
        error = isNaN(value) ? true : `Mindestbestellmenge ${this.props.minAmount}`;
      }
      this.setState({ error });
      // console.log('>> ProductAmount.getValue()', this.state.value, value, error);
      if (error) {
        return null;
      }
    } else {
      value = 0;
    }
    const result = { ...this.props.model };
    result[this.props.isTask ? 'approved' : 'amount'] = value;
    return result;
    // return { ...this.props.model, amount: value };
  }
  onChange = (value) => {
    console.log('ProductAmount.onChange()', value);
    if (this.props.onChange) {
      const result = { ...this.props.model };
      result[this.props.isTask ? 'approved' : 'amount'] = value;
      this.props.onChange(result);
      // this.props.onChange({ ...this.props.model, amount: value });
    } else {
      this.setState({ value });
    }
  }
  render() {
    const { id, disabled } = this.props;
    const { value, error } = this.state;
    console.log('ProductAmount.render()', value);
    return (
      <div className={`${error && 'error-group'}`}>
        <InputText
          id={id}
          className="w3-block w3-border"
          value={value}
          type="number"
          disabled={disabled}
          onChange={(event) => this.onChange(event.target.value)}
        />
        <small className="p-invalid p-d-block">{(error && error !== true) ? error : ''}</small>
      </div>
    )
  }
}
