import React from "react";
import { FileUpload } from "primereact/fileupload";

const adjustXHR = (event) => {
  event.xhr.responseType = "json";
};

const ImportForm = ({ ...props }) => {
  return (
    <FileUpload
      accept="text/csv"
      name="fileName"
      onBeforeSend={adjustXHR}
      chooseLabel="Datei auswählen"
      uploadLabel="Hochladen"
      cancelLabel="Abbrechen"
      {...props}/>
  );
};

export default ImportForm;
