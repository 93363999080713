import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../components/Buttons';
import ItemListManager from '../../../components/ItemListManager';
import UserTechDeviceForm from './UserTechDeviceForm';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';
import { TableEmptyMessage } from '../../../components/Utils';


export default class UserTechDeviceListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    isEditable: PropTypes.bool,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  validate = () => {
    const userTechDevices = this.userTechDevices.getModel();
    const model = [ ...userTechDevices ];
    // console.log('UserDeviceList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  toggleAddition = (disabled) => {
    this.userTechDevices.toggleAddition(disabled);
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <UserTechDevice
          ref={(ref) => this.userTechDevices = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class UserTechDevice extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    getBaseData: PropTypes.func,
    isEditable: PropTypes.bool,
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      addDisabled: false,
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map(item => {
      const { companyName, device, serialNumber, type, comment } = item;
      const userTechDevice = { companyName, device, serialNumber, type, comment: comment || '' };
      return userTechDevice;
    });
    return model;
  }
  toggleAddition = (disabled) => {
    this.setState({addDisabled: disabled});
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving userTechDevice ...', model);
    if (!model) return;
    const{ items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    console.log('>> deleting techDevice from list', selected);
    const items = [];
    for (const index in this.state.items) {
      console.log('>> delete: comparing index', index, 'with selected index', selected.index);
      if (index != selected.index) {
        items.push(this.state.items[index]);
      }
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, getBaseData, isEditable, rows } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map(item => {
        const copy = JSON.parse(JSON.stringify(item));
        return { ...copy };
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Geräte"
              itemName="Gerät"
              itemNamePlural="Geräte"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Technikergeräte" min/>)}
        >
          <Column
            field="device"
            header="Gerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="type"
            header="Typ"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const userTechDevice = item || {
        companyName: '',
        device: '',
        typ: '',
        serialNumber: '',
        comment: ''
      };
      let baseData = {};
      if (getBaseData) {
        baseData = getBaseData();
      }
      console.log('UserDeviceListForm.renderItemForm()', userTechDevice, baseData);
      return (
        <div>
          <UserTechDeviceForm ref={(ref) => this.form = ref}
            model={userTechDevice}
            key={Math.random()}
            {...baseData}/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={!isEditable ? null : () => this.onSave(onHide)}
            onCancel={onHide}
            saveDisabled={!isEditable}
            saveBtnName="saveUserTechDeviceBtn"/>
        </div>
      );
    };
    const getItemFormTitle = (userTechDevice) => {
      return (<h3 className="no-margin">{`Technikergerät ${!isEditable ? 'ansehen' : (userTechDevice ? 'bearbeiten' : 'hinzufügen')}`}</h3>);
    }
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">VKDG Geräte / Technikergeräte</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnDisabled={!isEditable}
        addBtnName="addTechDeviceBtn"
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="userTechDevice"
      />
    );
  }
}
