import React from 'react';
import PropTypes from 'prop-types';
import CardOrderTaskForm from '../../components/cards/CardOrderTaskForm';
import { ProductFormMenu } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadCardOrder, cancelCardOrder } from '../../../Product/actions/cards';
import { closeCardOrder } from '../../actions';
import { PRODUCT_ORDER_STATUS_IDS, PageTitle } from '../../../../components/Utils';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getOrder({ id });
};
const mapStateToProps = (state) => {
  return {
    cardOrder: state.card.cardOrder,
    cards: state.card.catalogCards,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadCardOrder(...args)),
  closeOrder: (...args) => dispatch(closeCardOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelCardOrder(...args)),
});

class CardOrderTaskDetails extends React.Component {
  static propTypes = {
    cardOrder: PropTypes.shape({}),
    cards: PropTypes.array,
    getOrder: PropTypes.func.isRequired,
    closeOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  onSave = () => {
    const cardOrder = this.form.getModel(true);
    console.log('CardOrderTaskDetails.onSave() ...', cardOrder);
    if (!cardOrder) return;
    if (cardOrder.address) {
      cardOrder.address.areaCode = cardOrder.address.areaCode || cardOrder.address.areacode;
      cardOrder.address.houseNumber = cardOrder.address.houseNumber || cardOrder.address.house;
    }
    const { closeOrder } = this.props;
    console.log(`>> saving card order...`, cardOrder);
    closeOrder({ cardOrder, saveOnly: true });
  }
  onSaveAndClose = () => {
    const cardOrder = this.form.getModel();
    console.log('CardOrderTaskDetails.onSaveAndClose() ...', cardOrder);
    if (!cardOrder) return;
    cardOrder.status_id = PRODUCT_ORDER_STATUS_IDS.FINISHED;
    if (cardOrder.address) {
      cardOrder.address.areaCode = cardOrder.address.areaCode || cardOrder.address.areacode;
      cardOrder.address.houseNumber = cardOrder.address.houseNumber || cardOrder.address.house;
    }
    const { closeOrder } = this.props;
    console.log(`>> saving card order...`, cardOrder);
    closeOrder({ cardOrder }, this.onCancel);
  }
  onCancel = () => {
    const { history, cancelOrder } = this.props;
    cancelOrder();
    history.replace(`/aufgaben/karten/uebersicht`);
  }
  render() {
    const { className, cardOrder, cards } = this.props;
    console.log('CardOrderTaskDetails.render()', this.props);
    if (!cardOrder) {
      return null;
    }
    const readOnly = (cardOrder.status_id !== PRODUCT_ORDER_STATUS_IDS.PROCESSING);
    const title = `Karten/Flyer-Bestellung ${readOnly ? 'ansehen' : 'bearbeiten'}`;
    return (
      <div className={`card-order-task-holder clearfix ${className}`}>
        <PageTitle>
          <div className="w3-right">
            <ProductFormMenu
              className=""
              onSave={this.onSave}
              onSaveAndClose={this.onSaveAndClose}
              onCancel={this.onCancel}
              saveHidden={readOnly}
              saveBtnName="saveCardOrderBtn2"
              saveAndCloseHidden={readOnly}
              saveAndCloseBtnName="saveAndCloseCardOrderBtn2"
              saveAndCloseBtnLabel="Speichern und Freigeben"
              cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}
            />
          </div>
          <h3 className="no-margin pad-btm">{title}</h3>
        </PageTitle>
        <CardOrderTaskForm
          ref={(ref) => this.form = ref}
          model={cardOrder}
          cards={cards}
          readOnly={readOnly}
        />
        <ProductFormMenu
          className="w3-border margin-big-top pad-big w3-light-grey"
          // style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          onSaveAndClose={this.onSaveAndClose}
          saveHidden={readOnly}
          saveBtnName="saveCardOrderBtn"
          saveAndCloseHidden={readOnly}
          saveAndCloseBtnName="saveAndCloseCardOrderBtn"
          saveAndCloseBtnLabel="Speichern und Freigeben"
          cancelBtnLabel={readOnly ? 'Schließen' : 'Abbrechen'}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(CardOrderTaskDetails)
);
