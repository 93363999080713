import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import CardWizardTitle from './CardWizardTitle';

import CardCatalog from './wizard/CardCatalog';
import OrderAddress from './wizard/OrderAddress';
import OrderOverview from './wizard/OrderOverview';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadCardOrder,
  cancelCardOrder
} from '../../actions/cards';
import { loadCompanies } from '../../../Company/actions';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getCompanies();
  await props.getOrder({ id });
};
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadCardOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelCardOrder(...args)),
  getCompanies: (...args) => dispatch(loadCompanies(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.setMenu();
  }
  componentDidUpdate() {
    this.setMenu();
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  setMenu = () => {
    // console.log('CardOrderWizard.setMenu()');
    const page = this.page1 || this.page2 || this.page3 || this.page4;
    if (page && page.renderNav && this.wizardTitle) {
      this.wizardTitle.setNavMenu(page.renderNav('bg-trans margin-big-top'));
    }
  }
  cancelOrder = () => {
    // console.log('CardOrderWizard.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/shop/karten/bestellungen');
  }
  renderMenu = () => {
    const isEditing = (this.props.match.params.id !== 'neu');
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li><NavLink to={`katalog`} className="disabled-link" activeClassName="active">{'Karten'}</NavLink></li>
            <li><NavLink to={`lieferanschrift`} className="disabled-link" activeClassName="active">{'Lieferanschrift'}</NavLink></li>
            <li><NavLink to={`uebersicht`} className="disabled-link" activeClassName="active">{'Übersicht'}</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const { className, history, match } = this.props;
    console.log('CardOrderWizard.render()', this.props);
    const Menu = this.renderMenu();
    const id = match.params.id;
    const pageProps = {
      history,
      match,
      onCancel: this.cancelOrder
    };
    const CatalogPage = React.forwardRef((props, ref) => {
      return (
        <CardCatalog
          forwardedRef={ref}
          {...props}
          {...pageProps}
          onNext={() => history.replace(`/wizard/shop/bestellung/karte/${id}/lieferanschrift`)}
        />
      );
    });
    const AddressPage = React.forwardRef((props, ref) => (
      <OrderAddress
        forwardedRef={ref}
        {...props}
        {...pageProps}
        onPrevious={() => history.replace(`/wizard/shop/bestellung/karte/${id}/katolog`)}
        onNext={() => history.replace(`/wizard/shop/bestellung/karte/${id}/uebersicht`)}
      />
    ));
    const OverviewPage = React.forwardRef((props, ref) => (
      <OrderOverview
        forwardedRef={ref}
        {...props}
        {...pageProps}
        onPrevious={() => history.replace(`/wizard/shop/bestellung/karte/${id}/lieferanschrift`)}
        onNext={() => history.replace(`/shop/karten/bestellungen`)}
      />
    ));
    const ArchivePage = React.forwardRef((props, ref) => (
      <OrderOverview
        forwardedRef={ref}
        {...props}
        {...pageProps}
        readOnly
      />
    ));
    const NavTitle = React.forwardRef((props, ref) => (
      <CardWizardTitle {...props} forwardedRef={ref} />
    ));
    return (
      <div className={`card-order-wizard clearfix ${className}`}>
        <NavTitle ref={ref => this.wizardTitle = ref}/>
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <div className="pad-big-top">
              <Switch>
                <Route
                  exact
                  path="/wizard/shop/bestellung/karte/:id/katalog"
                  render={props => (<CatalogPage {...props} ref={ref => this.page1 = ref}/>)}
                />
                <Route
                  exact
                  path="/wizard/shop/bestellung/karte/:id/lieferanschrift"
                  render={props => (<AddressPage {...props} ref={ref => this.page2 = ref}/>)}
                />
                <Route
                  exact
                  path="/wizard/shop/bestellung/karte/:id/uebersicht"
                  render={props => (<OverviewPage {...props} ref={ref => this.page3 = ref}/>)}
                />
                <Route
                  exact
                  path="/wizard/shop/bestellung/karte/:id/uebersicht/archiv"
                  render={props => (<ArchivePage {...props} ref={ref => this.page4 = ref}/>)}
                />
                <Route
                  path="*"
                  render={(props) => (
                    <Redirect to={`/wizard/shop/bestellung/karte/${this.props.match.params.id || 'neu'}/katalog`} />
                  )}
                />
              </Switch>
            </div>
          </Dashboard>
        </div>
      </div>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
