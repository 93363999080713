import React from 'react';
import { scrollToElement } from './Utils';

export const InpageNavLink = ({ to, activeClassName, currentTo, setCurrentTo, children, ... props }) => {
  const isActive = (to === currentTo);
  const onClick = (event) => {
    event.preventDefault();
    setCurrentTo(to);
    scrollToElement(to);
  };
  return (<a href={`#${to}`} {...props} className={isActive ? activeClassName : ''} onClick={onClick}>{children}</a>);
};

const Menu = ({
  className = 'under-wizard-title',
  contentClassName='margin-big-top',
  linkClassName='w3-border',
  links,
  initialActiveId = 'basisdaten'
}) => {
  const [ activeId, setActiveId ] = React.useState(initialActiveId);
  const body = links.map((link, index) => {
    const { isVisible, to, label, ...rest } = link;
    const show = isVisible !== undefined ? isVisible : true;
    return !show ? null : (
      <li key={index}>
        <InpageNavLink
          to={to}
          {...rest}
          activeClassName="active"
          currentTo={activeId}
          setCurrentTo={setActiveId}
        >
          {label}
        </InpageNavLink>
      </li>
    );
  });
  return (
    <div className={className}>
        <div className={`${contentClassName} nav-menu-vertical`}>
          <ul className={`w3-ul w3-large ${linkClassName}`}>
            {body}
          </ul>
        </div>
    </div>
  );
};

export default Menu;
