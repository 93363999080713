import React from 'react';
import PropTypes from 'prop-types';
import SalesFilterForm from '../components/SalesFilterForm';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { getSalesKDLs } from '../components';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadCompanies } from '../../Company/actions';
import { loadCurrentWorker } from '../../Worker/actions';

const frontload = async (props) => {
  await props.getCompanies();
  await props.getWorker();
};
const mapStateToProps = (state) => {
  return {
    user: state.worker.currentWorker,
    companies: state.company.companies,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompanies: (...args) => dispatch(loadCompanies(...args)),
  getWorker: (...args) => dispatch(loadCurrentWorker(...args)),
});

class SalesFilterPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    companies: PropTypes.array,
    settings: PropTypes.object,
    getCompanies: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    companies: [],
    settings: {}
  }
  useFilter = () => {
    const settings = this.form.getModel();
    console.log('SalesFilterPanel.useFilter()', settings);
  }
  resetFilter = () => {
    console.log('SalesFilterPanel.resetFilter()');
    this.form.reset();
  }
  render() {
    console.log('SalesFilterPanel.render()', this.props);
    const { className, user, companies, settings } = this.props;
    if (!user || !companies) return null;
    const kdls = getSalesKDLs(user, companies);
    return (
      <div className={className}>
        <SalesFilterForm
          ref={ref => this.form = ref}
          className="pad-big"
          companies={kdls}
          settings={settings}
        />
        <div className="pad-big alg-right w3-border-top w3-light-grey">
          <PrimaryButton onClick={this.useFilter} className="margin-lft">{`Anwenden`}</PrimaryButton>
          <SecondaryButton onClick={this.resetFilter} className="margin-lft">{`Zurücksetzen`}</SecondaryButton>
        </div>
      </div>
    );
  }
}
;
export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(SalesFilterPanel)
);
