import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { validateAreaCode } from '../../../../components/Utils';

export default class AddressForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    isVertical: PropTypes.bool,
    resetState: PropTypes.bool,
    disabled: PropTypes.bool,
    hideName: PropTypes.bool,
    namePrefix: PropTypes.string
  }
  static defaultProps = {
    className: '',
    model: {
      name: '',
      address: {
        street: '',
        houseNumber: '',
        areaCode: '',
        city: ''
      }
    },
    namePrefix: ''
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    if (!this.props.resetState) return;
    console.log('Props', this.props, 'Next props', props);
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    const address = model.address || {};
    return ({
      name: model.name || '',
      street: address.street || '',
      houseNumber: address.houseNumber || '',
      areaCode: address.areaCode || '',
      city: address.city || '',
      error: {
        name: null,
        street: null,
        houseNumber: null,
        areaCode: null,
        city: null
      }
    });
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!validateAreaCode(model.areaCode)) {
      isValid = false;
      error.areaCode = true;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    const { name, ...address } = model;
    return {
      name,
      address: { ...this.props.address, ...address }
    };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  renderStreetHouse = (className) => {
    const { namePrefix, disabled } = this.props;
    const { street, houseNumber, error } = this.state;
    return (
      <div className={`${className} ${(error.street || error.houseNumber) ? ' error-group-' : ''}`}>
      <Label className="display-inline-block" htmlFor={`${namePrefix}address_form_street`}>{`Straße`}</Label>
      <Label className="display-inline-block">{`/`}</Label>
      <Label className="display-inline-block" htmlFor={`${namePrefix}address_form_housenumber`}>{`Hausnummer`}</Label>
        <div className="w3-row">
          <div className="w3-col s8 pad-sm-rht">
            <InputText id={`${namePrefix}address_form_street`} className={`w3-block w3-border ${error.street ? 'w3-border-red' : ''}`} value={street}
              onChange={(event) => this.setState({street: event.target.value})} disabled={disabled}/>
          </div>
          <div className="w3-col s4 pad-sm-lft">
            <InputText id={`${namePrefix}address_form_housenumber`} className={`w3-block w3-border ${error.houseNumber ? 'w3-border-red' : ''}`} value={houseNumber}
              onChange={(event) => this.setState({houseNumber: event.target.value})} disabled={disabled}/>
          </div>
        </div>
      </div>
    );
  }
  renderAreaCity = (className) => {
    const { namePrefix, disabled } = this.props;
    const { areaCode, city, error } = this.state;
    return (
      <div className={`${className} ${(error.areaCode || error.city) ? ' error-group-' : ''}`}>
        <Label className="display-inline-block" htmlFor={`${namePrefix}area_code`}>{`PLZ`}</Label>
        <Label className="display-inline-block">{`/`}</Label>
        <Label className="display-inline-block" htmlFor={`${namePrefix}city`}>{`Ort`}</Label>
        <div className="w3-row">
          <div className="w3-col s4 pad-sm-rht">
            <InputText id={`${namePrefix}area_code`} className={`w3-block w3-border ${error.areaCode ? 'w3-border-red' : ''}`} value={areaCode}
              onChange={(event) => this.setState({areaCode: event.target.value})} disabled={disabled}/>
          </div>
          <div className="w3-col s8 pad-sm-lft">
            <InputText id={`${namePrefix}city`} className={`w3-block w3-border ${error.city ? 'w3-border-red' : ''}`} value={city}
              onChange={(event) => this.setState({city: event.target.value})} disabled={disabled}/>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { className, isVertical, namePrefix, disabled, hideName } = this.props
    const { name, error } = this.state;
    return (
      <div className={className}>
        {!hideName && (<div className="pad-top form-group-item">
          <Label htmlFor={`${namePrefix}name`}>{`Firmenname*`}</Label>
          <InputText id={`${namePrefix}name`} className={`w3-block w3-border ${error.name ? 'w3-border-red' : ''}`} value={name}
            onChange={(event) => this.setState({name: event.target.value})} disabled={disabled}/>
        </div>)}
        <div className="neg-margin-lr">
          <div className="w3-row">
            {this.renderStreetHouse(`w3-col ${isVertical ? 'm12' : 'm6'} pad-lr pad-top form-group-item`)}
            {this.renderAreaCity(`w3-col ${isVertical ? 'm12' : 'm6'} pad-lr pad-top form-group-item`)}
          </div>
        </div>
      </div>
    );
  }
}
