import { DEFAULT_PRODUCT } from "../components";

export const LOAD_PRODUCTS = 'sales/products/load';
export const LOAD_PRODUCTS_SUCCESS = 'sales/products/load/success';
export const LOAD_PRODUCTS_FAILURE = 'sales/products/load/failure';

export const LOAD_PRODUCT = 'product/load';
export const LOAD_PRODUCT_SUCCESS = 'product/load/success';
export const LOAD_PRODUCT_FAILURE = 'product/load/failure';

export const SAVE_PRODUCT = 'sales/products/save';
export const SAVE_PRODUCT_SUCCESS = 'sales/products/save/success';
export const SAVE_PRODUCT_FAILURE = 'sales/products/save/failure';

export const CANCEL_PRODUCT = 'product/cancel';

export const DELETE_PRODUCT = 'sales/products/delete';
export const DELETE_PRODUCT_SUCCESS = 'sales/products/delete/success';
export const DELETE_PRODUCT_FAILURE = 'sales/products/delete/failure';

export const CHANGE_STATUS = 'sales/products/status/change';
export const CHANGE_STATUS_SUCCESS = 'sales/products/status/change/success';
export const CHANGE_STATUS_FAILURE = 'sales/products/status/change/failure';

export const SELECT_PRODUCT = 'sales/products/select';
export const CANCEL_PRODUCT_SELECTION = 'sales/products/select/cancel';


/**
 * Loads all products
 */
export const loadProducts = (onSuccess) => {
  console.log('REDUX-ACTION loadProducts()');
  return {
    types: [ LOAD_PRODUCTS, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAILURE ],
    promise: (client) => client.get(`/sales/products/0?status=all`),
    onSuccess
  }
};

/**
 * Load a product by its id
 * @param {Object} param0 object containing ID of product to be loaded
 */
export const loadProduct = (product = { id }) => {
  console.log('REDUX-ACTION loadProduct()', product);
  const productId = product.id || 'neu';
  if (productId === 'neu') {
    const result = { ...DEFAULT_PRODUCT };
    return { type: LOAD_PRODUCT_SUCCESS, result };
  }
  return {
    types: [ LOAD_PRODUCT, LOAD_PRODUCT_SUCCESS, LOAD_PRODUCT_FAILURE ],
    promise: (client) => client.get(`/sales/products/${encodeURIComponent(productId)}?status=all`)
  };
};

/**
 * Save a product
 * @param {*} product the product to be saved
 */
export const saveProduct = (product = {}, onSuccess) => {
  console.log('REDUX-ACTION saveProduct()', product);
  const { files, type, type_id, ...others } = product;
  const data = product;
  return {
    types: [ SAVE_PRODUCT, SAVE_PRODUCT_SUCCESS, SAVE_PRODUCT_FAILURE ],
    promise: (client) => client.put(`/sales/products`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadProducts());
      onSuccess && onSuccess();
    },
    product
  };
};

/**
 * Change the status of a product
 * @param {*} product 
 * @param {*} onSuccess 
 */
export const changeProductStatus = (product = {}, onSuccess) => {
  console.log('REDUX-ACTION changeProductStatus()', product);
  const { id, status_id } = product;
  const data = [{ id, status_id }];
  return {
    types: [ CHANGE_STATUS, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILURE ],
    promise: (client) => client.post(`/sales/products/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadProducts());
      onSuccess && onSuccess();
    },
    product
  };
};

/**
 * Cancel a product
 */
export const cancelProduct = () => {
  return {
    type: CANCEL_PRODUCT
  };
};

/**
 * Delete a product
 * @param {*} product the product to be deleted
 */
export const deleteProduct = (product = {}, onSuccess) => {
  console.log('REDUX-ACTION deleteProduct()', product);
  return {
    types: [ DELETE_PRODUCT, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE ],
    promise: (client) => client.del(`/sales/products/${product.id}/remove`),
    onSuccess: (dispatch) => {
      dispatch(loadProducts());
      onSuccess && onSuccess();
    },
    product
  };
};

// /**
//  * select a product
//  */
// export const selectProduct = ({ product, purpose }) => {
//   return {
//     type: SELECT_PRODUCT,
//     product,
//     purpose
//   };
// };

// /**
//  * Cancel a product selection
//  */
// export const cancelProductSelection = () => {
//   return {
//     type: CANCEL_PRODUCT_SELECTION
//   };
// };

// /**
//  * select a product for deletion
//  * @param {object} product 
//  */
// export const selectProductForDeletion = (product) => {
//   return (dispatch) => {
//     dispatch(checkProductUsage(product));
//     dispatch(selectProduct({ product, purpose: 'delete' }));
//   }
// };
