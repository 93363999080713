import React from 'react';
import PropTypes from 'prop-types';
import { AppNavFactory } from './components';
import './styles.css';

class MainNav extends React.Component {
  static propTypes = {
    tree: PropTypes.shape({}),
    userFlags: PropTypes.shape({})
  }
  render() {
    const { tree, userFlags } = this.props;
    const { isAdmin, isWorkerPlus, isWorker } = userFlags;
    if (!isAdmin && !isWorkerPlus && !isWorker) {
      return null;
    }
    const { technik, sales, organisation } = (tree || {});
    console.log('MainNav.render()', this.props);
    if (!technik || !sales || !organisation) {
      return null;
    }
    const AppNav = AppNavFactory(technik, sales, organisation);

    return (
      <div className="main-nav level-0">
        <AppNav isVisible flags={userFlags}/>
      </div>
    );
  }
}

export default MainNav;
