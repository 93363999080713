import React from "react";
import PropTypes from "prop-types";
import {
	PageHeight100,
	PageHeight30,
	isKDL,
	createSortByName,
	PageHeight60,
	scrollToTop,
} from "../../../components/Utils";
import MNVTipGiverForm from "../components/forms/MNVTipGiverForm";
import MNVObjectForm from "../components/forms/MNVObjectForm";
import MNVObjectOwnerForm from "../components/forms/MNVObjectOwnerForm";
import MNVRatingForm from "../components/forms/MNVRatingForm";
import { PrimaryButton } from "../../../components/Buttons";
import InPageNavMenu from "../../../components/InPageNavMenu";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadSalesWorkers } from "../actions/reporting";
import { initMNVTipForm, saveMNVTip } from "../actions/form";

const frontload = async (props) => {
	await props.init();
};
const mapStateToProps = (state) => {
	return {
		user: state.worker.currentWorker,
		isAdmin: state.auth.isAdmin,
		companies: state.company.companies,
		workers: state.report.tipGivers, // salesWorkers,
		// products: state.salesForm.bookingProducts,
		techSupplyOptions: state.salesForm.mnvTipTechSupplyOptions,
		tvFilterOptions: state.salesForm.mnvTipTvFilterOptions,
		contactSources: state.salesForm.mnvTipContactSources,
		interests: state.salesForm.mnvTipInterests,
	};
};
const mapDispatchToProps = (dispatch) => ({
	init: (...args) => dispatch(initMNVTipForm(...args)),
	save: (...args) => dispatch(saveMNVTip(...args)),
	getWorkers: (...args) => dispatch(loadSalesWorkers(...args)),
});

class MNVTip extends React.Component {
	static propTypes = {
		isAdmin: PropTypes.bool,
		user: PropTypes.object,
		companies: PropTypes.array,
		workers: PropTypes.array,
		techSupplyOptions: PropTypes.array,
		tvFilterOptions: PropTypes.array,
		contactSources: PropTypes.array,
		interests: PropTypes.array,
		init: PropTypes.func.isRequired,
		save: PropTypes.func.isRequired,
		getWorkers: PropTypes.func.isRequired,
		className: PropTypes.string,
	};
	static defaultProps = {
		className: "",
		companies: [],
		workers: [],
	};
  componentDidMount() {
    scrollToTop();
  }
	reset = () => {
    scrollToTop();
		this.tipGiverForm.reset();
		this.objectForm.reset();
		this.objectOwnerForm.reset();
		this.ratingForm.reset();
	};
	onSave = () => {
		const tipGiver = this.tipGiverForm.getModel();
		const object = this.objectForm.getModel();
		const objectOwner = this.objectOwnerForm.getModel();
		const rating = this.ratingForm.getModel();
		const model = { object, tipGiver, objectOwner, rating };
		if (!tipGiver || !object || !objectOwner || !rating) {
			return null;
		}
		this.props.save({ data: model }, this.reset);
	};
	render() {
		const {
			companies,
			workers,
			getWorkers,
			techSupplyOptions,
			tvFilterOptions,
			contactSources,
			interests,
		} = this.props;
		const kdlCompanies = companies
			.filter((item) => isKDL(item))
			.sort(createSortByName());
		const links = [
			{ isVisible: true, to: "tippgeber", label: "Tippgebber" },
			{ isVisible: true, to: "objekt", label: "Objekt" },
			{ isVisible: true, to: "eigentuemer", label: "Eigentümer" },
			{ isVisible: true, to: "bewertung", label: "Bewertung" },
		];
		const menu = (
			<InPageNavMenu
				className="fixed-form-menu"
				links={links}
				initialActiveId="tippgeber"
			/>
		);
		const body = (
			<PageHeight100>
				<PageHeight30
					id="tippgeber"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">Angaben zum Tippgeber</h3>
					<MNVTipGiverForm
						ref={(ref) => (this.tipGiverForm = ref)}
						companies={kdlCompanies}
						workers={workers}
						getWorkers={getWorkers}
					/>
				</PageHeight30>
				<PageHeight30
					id="objekt"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">Angaben zum Objekt</h3>
					<MNVObjectForm
						ref={(ref) => (this.objectForm = ref)}
						techSupplyOptions={techSupplyOptions}
						tvFilterOptions={tvFilterOptions}
					/>
				</PageHeight30>
				<PageHeight30
					id="eigentuemer"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">
						Angaben zum Eingentümer des Objektes
					</h3>
					<h6>
						Der Eingentümer muss eindeutig identifzierbar sein. Neben dem
						Nachnamen, muss entweder die Adresse/ggf. Wohnlage bei mehrfach
						vorhandenen Nachnamen oder der Kontakt vorliegen.
					</h6>
					<MNVObjectOwnerForm ref={(ref) => (this.objectOwnerForm = ref)} />
				</PageHeight30>
				<PageHeight30
					id="bewertung"
					className="w3-border margin-big-top pad-big"
					isUnderFormTitle
				>
					<h3 className="no-margin-top">Bewertung des Potenzials</h3>
					<MNVRatingForm
						ref={(ref) => (this.ratingForm = ref)}
						contactSources={contactSources}
						interests={interests}
					/>
				</PageHeight30>
				<div className="margin-big-top w3-border pad-big w3-light-grey alg-right">
					<PrimaryButton id="saveMnvTipBtn" onClick={this.onSave}>
						{"Lead senden"}
					</PrimaryButton>
				</div>
			</PageHeight100>
		);
		return (
			<div className="w3-row neg-margin-lr">
				<div className="w3-col m12 l10 pad-lr">{body}</div>
				<div className="w3-col l2 w3-show-large pad-lr">{menu}</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	frontloadConnect(frontload, {
		onMount: true,
		onUpdate: false,
	})(MNVTip)
);
