import React from 'react';
import PropTypes from 'prop-types';
import { WizardTitle, createSortByAttr, notifyError } from '../../../components/Utils';
import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadAllOrderDebitorDetails, saveOrdersAndSendToLoxxess, cancelTaskOrderDebitorApproval } from '../actions';
import { formatFieldModemName } from '../components';
import { InputText } from '../../../components/Inputs';
import { WizardButtonPanel } from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ApprovalItemListForm from '../components/task/ApprovalItemListForm';


const frontload = async (props) => {
  await props.getAllOrderDebitors({});
};
const mapStateToProps = (state) => {
  return {
    modems: state.modem.catalogModems,
    debitorTaskMap: state.modem.debitorTaskMap
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllOrderDebitors: (...args) => dispatch(loadAllOrderDebitorDetails(...args)),
  saveAndSendToLoxxess: (...args) => dispatch(saveOrdersAndSendToLoxxess(...args)),
  cancelApproval: (...args) => dispatch(cancelTaskOrderDebitorApproval(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    modems: PropTypes.array,
    debitorTaskMap: PropTypes.object,
    getAllOrderDebitors: PropTypes.func.isRequired,
    saveAndSendToLoxxess: PropTypes.func.isRequired,
    cancelApproval: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
    controlApprovalForms: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    controlApprovalForms: true
  }
  constructor(props) {
    super(props);
    this.state = this.getState(
      formatFieldModemName((props.modems && props.modems.length > 0) ? props.modems[0].name : ''),
      props,
      true,
      true
    );
  }
  componentWillReceiveProps(props) {
    const currentModemName = formatFieldModemName((props.modems && props.modems.length > 0) ? props.modems[0].name : '');
    this.setState(this.getState(currentModemName, props, false, true));
  }
  componentDidMount() {
    if (this.state.abort) {
      return this.abort();
    }
    this.scrollToActiveItem();
  }
  componentDidUpdate() {
    if (this.state.abort) {
      return this.abort();
    }
    this.scrollToActiveItem();
  }
  componentWillUnmount() {
    this.props.cancelApproval();
  }
  abort = () => {
    console.error('<< ABORTING ...'); 
    const { cancelApproval, history } = this.props;
    cancelApproval();
    history.replace('/aufgaben/modems');
  }
  scrollToActiveItem = () => {
    // console.log('ModemOVerviewTaskWizard.scrollToActiveItem() currentIndex:', this.state.currentIndex);
    const element = document.getElementById(`approvalItem${this.state.currentIndex}`);
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      const offset = 56;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
  calculateSum = (debitors) => {
    const sum = {
      isSum: true,
      debitorKey: 'AA',
      used: 0,
      stock: 0,
      quantity: 0,
      approved: 0,
      index: 0,
      count: 0
    };
    let sumIndex = -1;
    let data = debitors.map((item, index) => {
      if (!item.isSum) {
        sum.used += isNaN(item.used) ? 0 : item.used;
        sum.stock += isNaN(item.stock) ? 0 : item.stock;
        sum.quantity += isNaN(item.quantity) ? 0 : item.quantity;
        sum.approved += isNaN(parseInt(item.approved)) ? 0 : parseInt(item.approved);
        if (item.distributions && item.distributions.length > 0) {
          console.log('Distribution;:', item.distributions);
          item.distributions.forEach(distr => {
            const value = parseInt(distr.approved);
            sum.approved += isNaN(value) ? 0 : value;
          });
        }
      } else sumIndex = index;
      return ({ ...item, index });
    });
    if (sumIndex > -1) {
      data[sumIndex] = sum;
    } else {
      data = [ sum ].concat(data);
    }
    return data;
  }
  getState = (currentModemName, props = this.props, init, fromProps) => {
    // console.log('ModemOverviewTaskWizard.getState()', currentModemName, props, init, fromProps);
    const { modems } = props;
    const debitorTaskMap = fromProps ? props.debitorTaskMap : this.state.debitorTaskMap;
    if (debitorTaskMap && debitorTaskMap.abort/*  || Object.keys(debitorTaskMap).length === 0 */) {
      return { abort: true };
    }
    const currentModem = modems.find(item => formatFieldModemName(item.name) === currentModemName);
    let debitors = debitorTaskMap ? (debitorTaskMap[currentModemName] || []) : [];
    debitors.sort(createSortByAttr('debitorKey'));
    debitors = debitors.map((item, index) => ({ ...item, index: index + 1, count: index + 1  }));
    debitors = this.calculateSum(debitors, init);
    const state = {
      currentModemName,
      currentIndex: 1,
      currentItemIndex: 0,
      previousIndex: -1,
      previousItemIndex: -1,
      modemModels: currentModem && currentModem.models ? currentModem.models : [],
      debitors,
      debitorTaskMap,
      error: {},
      abort: false
    };
    return state;
  }
  validate = (noValidate) => {
    // const { debitorTaskMap } = this.props;
    const { debitors, debitorTaskMap } = this.state;
    // console.log('ModemOverviewTaskWizard.validate() noValidate', noValidate, debitorTaskMap, debitors);
    const error = {};
    let isValid = true;
    for (const rowKey in debitorTaskMap) {
      for (const colKey in debitorTaskMap[rowKey]) {
        const item = debitorTaskMap[rowKey][colKey];
        if (item.quantity > 0) {
          const errorKey = `item${item.rowIndex}${item.colIndex}`; // `item${item.index}` // 
          // collect all values
          const list = [{ approved: item.approved, model_id: item.model_id }];
          (item.distributions || []).forEach(obj => {
            list.push({ ...obj});
          });
          // validate
          // console.log(`>> validating`, item, list);
          error[`${errorKey}`] = [];
          list.forEach((obj) => {
            const err = {};

            // approved
            const approved = obj.approved !== '' ? obj.approved : 0;
            // console.log('Approved:', approved, 'isValid(a number)', !isNaN(parseInt(`${approved}`)));
            if (isNaN(parseInt(`${approved}`))) {
              err.approved = true;
              isValid = false;
            }
            // if (!(noValidate && approved === '') && isNaN(parseInt(`${approved}`))) {
            //   err.approved = true;
            //   isValid = false;
            // }
            // model id
            if (!noValidate && (obj.model_id === '' || obj.model_id === -1) && !err.approved && (approved > 0)) {
              err.model_id = true;
              isValid = false;
            }
            (err.approved || err.model_id) && console.log('>> Validation ERROR for', obj, 'Error:', err);
            error[`${errorKey}`].push(err);
          });
        }
      }
    }
    /* !noValidate &&  */this.setState({ error });
    console.log('ModemOverviewTaskWizard.validate() Result:', debitorTaskMap, error);
    return (isValid/*  || noValidate */) ? debitorTaskMap : null;
  }
  saveOrderOnly = () => {
    const debitorTaskMap = this.validate(true);
    // console.log('ModemOverviewTaskWizard.saveOrderOnly() result', debitorTaskMap);
    if (debitorTaskMap) {
      const { saveAndSendToLoxxess } = this.props;
      saveAndSendToLoxxess({ debitorTaskMap, sendOnly: true });
    } else {
      notifyError({
        summary: `Bestellungsfreigabe`,
        detail: `Ihre Eingaben konnte nicht gespeichert werden. Bitte überprüfen Sie sie nochmal.`
      });
    }
  }
  saveAndSendOrder = () => {
    const debitorTaskMap = this.validate();
    // console.log('ModemOverviewTaskWizard.saveAndSendOrder() validation result', debitorTaskMap);
    if (debitorTaskMap) {
      const { saveAndSendToLoxxess, history } = this.props;
      this.dialog.show(
        true,
        {
          title: 'Bestellungsfreigabe bestätigen',
          text: ` Wollen Sie die Bestellungen wirklich freigeben und herunterladen?`
        },
        () => saveAndSendToLoxxess({
          debitorTaskMap,
          onSuccess: () => history.replace('/aufgaben/modems')
        })
      );
    } else {
      notifyError({
        summary: `Bestellungsfreigabe`,
        detail: `Die Bestestellung konnte nicht freigeben werden. Bitte überprüfen Sie nochmal Ihre Eingaben.`
      });
    }
  }
  cancelApproval = () => {
    // console.log('ModemOverviewTaskWizard.cancelApproval() ');
    const { cancelApproval, history } = this.props;
    this.dialog.show(
      true,
      {
        title: 'Bestellungsfreigabe abbrechen',
        text: `
          Durch den Abbruch, gehen alle nicht gespeicherte Daten verloren.
          Haben Sie Ihre Daten gespeichert und wollen wirklich abbrechen?
        `
      },
      () => {
        cancelApproval();
        history.replace('/aufgaben/modems');
      }
    );
  }
  selectModem = (value) => {
    this.setState(this.getState(value));
  }
  onApprovalChanged = (options, rowIndex, itemIndex) => {
    const { debitors, debitorTaskMap, currentModemName, modemModels } = this.state;
    console.log('>> onApprovalChanged()', options, rowIndex, debitors[rowIndex]);
    const currentIndex = rowIndex;
    const currentItemIndex = itemIndex;
    const previousIndex = currentIndex; // this.state.previousIndex === -1 ? currentIndex : this.state.previousIndex;
    const previousItemIndex = currentItemIndex; // this.state.previousItemIndex === -1 ? currentItemIndex : this.state.previousItemIndex;
    // console.log('>> ModemOverviewTaskWizard.onApprovalChanged() ', options, rowIndex, debitors[rowIndex]);
    debitors[rowIndex].distributions = [];
    // const distributions = [];
    const modelOptions = modemModels.map(item => ({ label: item.name, value: item.id }));
    options.forEach((option, index) => {
      if (index == 0) {
        // console.log('  >> setting approved ...', option, index);
        const isNumber = !isNaN(parseInt(option.approved));
        const model_id = (option.model_id && option.model_id != -1) ? option.model_id: (isNumber ? modelOptions[0].value : -1)
        debitors[rowIndex].approved = option.approved;
        debitors[rowIndex].model_id = model_id; // option.model_id;
      } else {
        // console.log('  >> setting distributions  ...', option, index);
        debitors[rowIndex].distributions.push(options[index]);
      }
    });
    // console.log('<< ModemOverviewTaskWizard.onApprovalChanged() result ', debitors[rowIndex]);
    const data = this.calculateSum(debitors);
    if (this.props.controlApprovalForms) {
      // update task map
      debitorTaskMap[currentModemName] = data.slice(1);
      // console.log('<< ModemOverviewTaskWizard.onApprovalChanged() result ', debitorTaskMap[currentModemName]);
      // update state
      this.setState({
        debitors: data,
        currentIndex,
        currentItemIndex,
        previousIndex,
        previousItemIndex,
        debitorTaskMap
      });
    } else {
      this.state.debitors = data;
      this.state.currentIndex = currentIndex;
      this.state.currentItemIndex = currentItemIndex;
      this.state.previousIndex = previousIndex;
      this.state.previousItemIndex = previousItemIndex;
      this.state.debitorTaskMap[currentModemName] = data.slice(1);
    }
  }
  onTab = (data, rowIndex, itemIndex) => {
    const { debitors } = this.state;
    const previousIndex = this.state.currentIndex;
    const previousItemIndex = this.state.currentItemIndex;
    let currentIndex = rowIndex;
    let currentItemIndex = itemIndex;
    if (!data.isInner) {
      currentIndex = rowIndex + (data.isBackward ? -1 : 1);
      currentItemIndex = 0;
    }
    if (currentIndex >= 0 && currentIndex < debitors.length) {
      if (data.isBackward && !data.isInner) {
        // console.log('Current Element:', debitors[currentIndex])
        currentItemIndex = debitors[currentIndex].distributions ? debitors[currentIndex].distributions.length : 0;
      }
      if (this.props.controlApprovalForms) {
        this.setState({ currentIndex, currentItemIndex, previousIndex, previousItemIndex })
      } else {
        this.state.currentIndex = currentIndex;
        this.state.currentItemIndex = currentItemIndex;
        this.state.previousIndex = previousIndex;
        this.state.previousItemIndex = previousItemIndex;
      }
    }
  }
  approvalTemplate = (rowData) => {
    console.log('ModemOverviewTaskWizard.approvalTemplate()', rowData);
    if (rowData.isSum) {
      return (<div className="alg-center bold pad w3-large">{rowData.approved}</div>);
    }
    const { error, currentIndex, currentItemIndex, previousIndex, previousItemIndex } = this.state;
    // console.log('ModemOverviewTaskWizard.approvalTemplate()', rowData, currentIndex, currentItemIndex, previousIndex, previousItemIndex);
    const modelOptions = this.state.modemModels.map(item => ({ label: item.name, value: item.id }));
    const { approved, model_id, distributions, count, quantity } = rowData;
    const key = `item${count}`; // `item${rowData.rowIndex}${rowData.colIndex}`; // 
    const model = { approved, model_id, distributions, error: error[key], quantity };
    return (
      <div className={``}>
        <ApprovalItemListForm
          key={Math.random()}
          // key={key}
          id={`approvalItem${count}`}
          model={model}
          modelOptions={modelOptions}
          isParentControlled={this.props.controlApprovalForms}
          hasFocus={currentIndex === count}
          focusChanged={currentIndex !== previousIndex}
          currentIndex={currentItemIndex}
          previousIndex={previousItemIndex}
          // onChange={(options) => this.onApprovalChanged(options, rowData.rowIndex, rowData.colIndex)}
          onChange={(options, itemIndex) => this.onApprovalChanged(options, count, itemIndex)}
          onTab={(options, itemIndex) => this.onTab(options, count, itemIndex)}
        />
      </div>
    );
  }
  render() {
    const { className, modems, debitorTaskMap } = this.props;
    if (!modems || !debitorTaskMap) {
      return null;
    }
    const ModemOptions = modems.map(item => ({ label: item.name, value: formatFieldModemName(item.name) }));
    const { currentModemName, debitors } = this.state;
    if (!currentModemName || !debitors) {
      return null;
    }
    // console.log('ModemOverviewTaskWizard.render()', this.state);
    let data = [ ...debitors ];
    // data.sort(createSortByAttr('debitorKey'));
    // data = data.map((item, index) => ({ ...item, index }));
    // // let data = debitors.map((item, index) => {
    // //   return ({ ...item, index });
    // // }).sort(createSortByAttr('debitorKey'));
    const frozenRows = [ data[0] ];
    data = data.slice(1);
    // create templates
    const debitorKeyTemplate = (rowData) => {
      const className = rowData.isSum ? 'bold w3-large' : '';
      const value = rowData.isSum ? 'Summe' : rowData.debitorKey;
      return (<div className={className}>{value}</div>);
    };
    const createTemplate = (field) => (rowData) => {
      const className = rowData.isSum ? 'bold w3-large' : '';
      return (<div className={className}>{rowData[field]}</div>);
    };
    return (
      <div
        className={`modem-order-wizard clearfix ${className}`}
        // key={Math.random()}
      >
        <WizardTitle model={{name: 'Bestellungen freigeben'}}/>
        <div className="container pad-big-top">
          <div className="alg-center">
            <SelectButton
              className="task-overview-modems"
              options={ModemOptions}
              value={currentModemName}
              onChange={(event) => this.selectModem(event.value)}/>
          </div>
          <div className="pad-big-top">
            <DataTable
              value={data}
              frozenValue={frozenRows}
              scrollable
              scrollHeight="45vh"
              rowClassName={(data) => ({
                'w3-border-bottom bg-secondary': data.isSum
              })}
            >
              <Column
                field="debitorKey"
                header="Debitor"
                className="alg-center"
                // sortable
                body={debitorKeyTemplate}
              />
              <Column
                field="used"
                header="Verbrauch"
                className="alg-center"
                // sortable
                body={createTemplate('used')}
              />
              <Column
                field="stock"
                header="Bestand"
                className="alg-center"
                // sortable 
                body={createTemplate('stock')}
              />
              <Column
                field="quantity"
                header="Bedarf"
                className="alg-center"
                // sortable 
                body={createTemplate('quantity')}
              />
              <Column
                field="approved"
                header="Freigegeben"
                body={this.approvalTemplate}
                style={{width: '30%'}}
              />
            </DataTable>
          </div>
          <ConfirmationDialog ref={ref => this.dialog = ref}/>
          <WizardButtonPanel
            className="margin-big-top w3-border pad-big w3-light-grey"
            nextLabel={`Bestellung herunterladen`}
            onNext={this.saveAndSendOrder}
            saveLabel={'Speichern'}
            onSave={this.saveOrderOnly}
            onCancel={this.cancelApproval}/>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
