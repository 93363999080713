import { encodeObjectToBase64, isAdmin, isWorkerPlus, toDBDateTime } from '../../components/Utils';
import * as Auth from '../../redux/actions/auth';

export const LOAD_EXTENDED_ROLES = 'workers/roles/load';
export const LOAD_EXTENDED_ROLES_SUCCESS = 'workers/roles/load/success';
export const LOAD_EXTENDED_ROLES_FAILURE = 'workers/roles/load/failure';

export const LOAD_WORKERS = 'workers/load';
export const LOAD_WORKERS_SUCCESS = 'workers/load/success';
export const LOAD_WORKERS_FAILURE = 'workers/load/failure';

export const LOAD_FILTERED_WORKERS = 'workers/filtered/load';
export const LOAD_FILTERED_WORKERS_SUCCESS = 'workers/filtered/load/success';
export const LOAD_FILTERED_WORKERS_FAILURE = 'workers/filtered/load/failure';

export const LOAD_WORKER_COMPANIES = 'worker/companies/load';
export const LOAD_WORKER_COMPANIES_SUCCESS = 'worker/companies/load/success';
export const LOAD_WORKER_COMPANIES_FAILURE = 'worker/companies/load/failure';

export const LOAD_WORKER_KDLS = 'worker/kdls/load';
export const LOAD_WORKER_KDLS_SUCCESS = 'worker/kdls/load/success';
export const LOAD_WORKER_KDLS_FAILURE = 'worker/kdls/load/failure';

export const LOAD_WORKER = 'worker/load';
export const LOAD_WORKER_SUCCESS = 'worker/load/success';
export const LOAD_WORKER_FAILURE = 'worker/load/failure';

export const LOAD_CURRENT_WORKER = 'worker/current/load';
export const LOAD_CURRENT_WORKER_SUCCESS = 'worker/current/load/success';
export const LOAD_CURRENT_WORKER_FAILURE = 'worker/current/load/failure';

export const INIT_WORKER_WIZARD = 'worker/init';
export const CANCEL_WORKER_WIZARD = 'worker/cancel';

export const CHECK_WORKER = 'worker/check';
export const CHECK_WORKER_SUCCESS = 'worker/check/success';
export const CHECK_WORKER_FAILURE = 'worker/check/failure';

export const SAVE_WORKER = 'worker/save';
export const SAVE_WORKER_SUCCESS = 'worker/save/success';
export const SAVE_WORKER_FAILURE = 'worker/save/failure';

export const ACTIVATE_WORKER = 'worker/activate';
export const ACTIVATE_WORKER_SUCCESS = 'worker/activate/success';
export const ACTIVATE_WORKER_FAILURE = 'worker/activate/failure';

export const DEACTIVATE_WORKER = 'worker/deactivate';
export const DEACTIVATE_WORKER_SUCCESS = 'worker/deactivate/success';
export const DEACTIVATE_WORKER_FAILURE = 'worker/deactivate/failure';

export const DELETE_WORKER = 'worker/delete';
export const DELETE_WORKER_SUCCESS = 'worker/delete/success';
export const DELETE_WORKER_FAILURE = 'worker/delete/failure';

export const EXPORT_WORKERS = 'workers/export';
export const EXPORT_WORKERS_SUCCESS = 'workers/export/success';
export const EXPORT_WORKERS_FAILURE = 'workers/export/failure';

export const LOAD_SALES_WORKERS = 'worker/sales/load';

export const DUMMY_WORKER = {
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  email: '',
  cellNumber: '',
  telephoneNumber: '',
  preferredContactMethod: 'email',
  // status_id: 1,

  companies: [{
    name: '',
    role: '',
    contractGiver: '',
    telephoneNumber: '',
    extendedRoles: [],
    delphiConnections: [],
    devices: [],
    techDevices: [],
    techKeys: [],
    certificates: []
  }],
  itConnectionData: {
    adConnection: '',
    adUsername: '',
    adPassword: '',
    adRemark: '',
    ptConnection: '',
    ptUsername: '',
    ptPassword: '',
    ptRemark: '',
  }
};

export const defaultFilter = {
  first: 0,
  rows: 10,
  username: null,
  role: null,
  companyName: null,
  modified: null,
  status_id: null,
  sortFields: [],
};

export const loadExtendedRoles = () => {
  console.log('REDUX-ACTION: loadExtendedRoles() ...');
  return (dispatch, getState) => {
    const { isAdmin } = getState().auth;
    dispatch({
      types: [
        LOAD_EXTENDED_ROLES,
        LOAD_EXTENDED_ROLES_SUCCESS,
        LOAD_EXTENDED_ROLES_FAILURE
      ],
      promise: (client) => client.get(`/companies/roles/0`),
      isAdmin
    });
  }
};

export const loadWorkers = (onSuccess) => {
  console.log('REDUX-ACTION: loadWorkers() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (isAdmin(user) || isWorkerPlus(user)) {
      // logged in user is admin/manager/worker-plus
      const companyId = isAdmin(user) ? 0 : user.companyId;
      // const workerId = isAdmin(user) ? 0 : user.id; 
      dispatch({
        types: [
          LOAD_WORKERS,
          LOAD_WORKERS_SUCCESS,
          LOAD_WORKERS_FAILURE
        ],
        promise: (client) => client.get(`/companies/${encodeURIComponent(companyId)}/employees/0/summaries?related=true`),
        onSuccess: (dispatch) => onSuccess && onSuccess(dispatch)
      });
    } else {
      // all others
      dispatch({ type: LOAD_WORKERS_FAILURE, result: { message: 'PERMISION DENIED'}});
    }
  }
};

export const loadFilteredWorkers = (onSuccess, filter = defaultFilter) => {
  console.log('REDUX-ACTION: loadWorkers() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (isAdmin(user) || isWorkerPlus(user)) {
      let filterJsonStringBase64 = '';
      if (filter) {
        filterJsonStringBase64 = encodeObjectToBase64(filter);
      }
      // logged in user is admin/manager/worker-plus
      const companyId = isAdmin(user) ? 0 : user.companyId;
      dispatch({
        types: [
          LOAD_FILTERED_WORKERS,
          LOAD_FILTERED_WORKERS_SUCCESS,
          LOAD_FILTERED_WORKERS_FAILURE
        ],
        promise: (client) => client.get(`/companies/${encodeURIComponent(companyId)}/employees/0/filters/summaries?related=true&filter=${filterJsonStringBase64}`),
        onSuccess: (dispatch) => onSuccess && onSuccess(dispatch)
      });
    } else {
      // all others
      dispatch({ type: LOAD_WORKERS_FAILURE, result: { message: 'PERMISION DENIED'}});
    }
  }
};

export const loadLogisticsAssistants = (pCompany) => {
  console.log('REDUX-ACTION: loadLogisticsAssistants() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (isAdmin(user) || isWorkerPlus(user)) {
      // logged in user is admin/manager/worker-plus
      const companyId = isAdmin(user) ? pCompany : user.companyId;
      dispatch({
        types: [
          LOAD_WORKERS,
          LOAD_WORKERS_SUCCESS,
          LOAD_WORKERS_FAILURE
        ],
        promise: (client) => client.get(`/companies/${encodeURIComponent(companyId)}/employees/0`),
        onlyLogisticsAssistants: true
      });
    } else {
      // all others
      dispatch({ type: LOAD_WORKERS_FAILURE, result: { message: 'PERMISION DENIED'}});
    }
  }
};


export const loadWorkerKDLs = (onSuccess) => {
  console.log(`REDUX-ACTION: loadWorkerKDLs() ...`);
  return {
    types: [
      LOAD_WORKER_KDLS,
      LOAD_WORKER_KDLS_SUCCESS,
      LOAD_WORKER_KDLS_FAILURE
    ],
    promise: (client) => client.get(`/companies/0/summaries?related=true&form=true&filter=&kdl=true`),
    onSuccess: () => onSuccess && onSuccess(),
  };
};

export const loadWorkerCompanies = (companyId, onSuccess) => {
  console.log(`REDUX-ACTION: loadWorkerCompanies(${companyId || ""}) ...`);
  return {
    types: [
      LOAD_WORKER_COMPANIES,
      LOAD_WORKER_COMPANIES_SUCCESS,
      LOAD_WORKER_COMPANIES_FAILURE
    ],
    promise: (client) => client.get(`/companies${(companyId != null) ? ("/" + companyId) : ""}/contractors?kdl=true&form=true`),
    onSuccess: () => onSuccess && onSuccess(),
    companyId,
  };
};


export const loadWorker = ({ id }) => {
  console.log(`REDUX-ACTION: loadWorker(${id}) ...`);
  return (dispatch, getState) => {
    const { isAdmin, user } = getState().auth;
    const companyId = isAdmin ? 0 : user.companyId
    // fetch companies
    dispatch(loadWorkerKDLs());
    dispatch(loadWorkerCompanies(companyId));
    // fetch employee profile
    if (id === 'neu') {
      return dispatch({
        type: LOAD_WORKER_SUCCESS,
        result: { ...DUMMY_WORKER },
        workerId: id
      });
    };
    dispatch({
      types: [
        LOAD_WORKER,
        LOAD_WORKER_SUCCESS,
        LOAD_WORKER_FAILURE
      ],
      promise: (client) => client.get(`/companies/${encodeURIComponent(companyId)}/employees/${id}?related=true`),
    });
  }
};

export const loadCurrentWorker = () => {
  console.log(`REDUX-ACTION: loadCurrentWorker()...`);
  return {
    types: [
      LOAD_CURRENT_WORKER,
      LOAD_CURRENT_WORKER_SUCCESS,
      LOAD_CURRENT_WORKER_FAILURE
    ],
    promise: (client) => client.get(`/companies/profiles`),
  };
};

export const loadCurrentUser = () => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (isAdmin(user)) {
      dispatch(loadUser(user));
    } else {
      dispatch(loadWorker(user));
    }
  };
};

export const checkWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: checkWorker()`, worker);
  return {
    types: [
      CHECK_WORKER,
      CHECK_WORKER_SUCCESS,
      CHECK_WORKER_FAILURE
    ],
    promise: (client) => client.post(`/companies/check`, { data: worker }),
    onSuccess,
    worker,
  }
};

export const saveWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: saveWorker()`, worker);
  worker.password = 'secret';
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const isAdmin = getState().auth.isAdmin;
    const companyId = isAdmin ? 0 : user.companyId;
    const updatingMyself = (user.id === worker.id);
    const onSaveSucess = () => {
      onSuccess && onSuccess();
      if (updatingMyself) {
        console.log('### user is updating their own worker profile => reload profile...');
        dispatch(Auth.loadCurrentUser());
      }
    };
    dispatch({
      types: [
        SAVE_WORKER,
        SAVE_WORKER_SUCCESS,
        SAVE_WORKER_FAILURE
      ],
      promise: (client) => client.put(`/companies/${encodeURIComponent(companyId)}/employee`, { data: worker }),
      onSuccess: onSaveSucess,
      worker
    });
  }
};

export const activateWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: activateWorker()`, worker);
  return {
    types: [
      ACTIVATE_WORKER,
      ACTIVATE_WORKER_SUCCESS,
      ACTIVATE_WORKER_FAILURE
    ],
    // promise: (client) => client.get(`/users/${worker.id}/changeStatus/2`),
    promise: (client) => client.get(`/companies/${worker.companies[0].id}/employees/${worker.id}/changeStatus/2`),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredWorkers())
    },
    worker, activate: true
  }
};

export const deactivateWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: deactivateWorker()`, worker);
  return {
    types: [
      DEACTIVATE_WORKER,
      DEACTIVATE_WORKER_SUCCESS,
      DEACTIVATE_WORKER_FAILURE
    ],
    // promise: (client) => client.get(`/users/${worker.id}/changeStatus/6`),
    promise: (client) => client.get(`/companies/${worker.companies[0].id}/employees/${worker.id}/changeStatus/6`),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredWorkers())
    },
    worker, activate: false
  }
};

export const deleteWorker = (worker) => {
  console.log(`REDUX-ACTION: deleteWorker()`, worker);
  return {
    types: [
      DELETE_WORKER,
      DELETE_WORKER_SUCCESS,
      DELETE_WORKER_FAILURE
    ],
    promise: (client) => client.del(`/users`, { data: worker }),
    onSuccess: dispatch => dispatch(loadWorkers()),
    worker
  };
};

export const initWorker = (value) => ({ type: INIT_WORKER_WIZARD, result: value });
export const cancelWorker = () => ({ type: CANCEL_WORKER_WIZARD });

export const exportWorkers = (props = {}) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    console.log(`REDUX-ACTION: exportWorkers()`, props, user);
    const isAdmin = getState().auth.isAdmin;
    const { username, companyId, position, modified_date } = props;
    const params = [
      `username=${encodeURIComponent(!isAdmin ? user.username : username || 'null')}`,
      `companyId=${encodeURIComponent(!isAdmin ? user.companyId : (companyId || 0))}`,
      `modified_date=${encodeURIComponent(toDBDateTime(modified_date) || 'null')}`,
      `position=${encodeURIComponent(position || 'null')}`
    ].join('&');
    dispatch({
      types: [
        EXPORT_WORKERS,
        EXPORT_WORKERS_SUCCESS,
        EXPORT_WORKERS_FAILURE
      ],
      promise: (client) => client.get(`/companies/employees/csv?${params}`)
    });
  }
}
