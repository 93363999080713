import React from 'react';
import { connectProductDisplay } from '../components/DisplayUtils';
import LevelDisplay from '../components/LevelDisplay';


const DisplayProducts = ({ filterType, data, ...props }) => {
  console.log('DisplayProducts.render() filterType:', filterType);
  if (filterType !== 'range' || data === null) {
    return null;
  }
  console.log('DisplayProducts.render()', data, props);
  const levelBody = (item) => (<div className="bold">{item.product_name}</div>);
  return (
    <LevelDisplay
      {...props}
      data={data}
      levelLabel="Produkt"
      levelBody={levelBody}
      dataKey="product"
    />
  );
};

export default connectProductDisplay(DisplayProducts);
