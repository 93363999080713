
export const SEARCH = 'search/load';
export const SEARCH_SUCCESS = 'search/load/success';
export const SEARCH_FAILURE = 'search/load/failure';

export const CANCEL_SEARCH = 'search/cancel';

export const search = ({ text }) => {
  console.log(`REDUX-ACTION: search(${text}) ...`);
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE],
    promise: client => client.get(`/pages/search/${encodeURIComponent(text)}`),
    searchedText: text
  };
};

export const cancelSearch = () => {
  return { type: CANCEL_SEARCH };
};
