import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FileUpload } from 'primereact/fileupload';
import DownloadListItem from './DownloadListItemForm';
// import { Label, InputText } from '../../../../components/Inputs';
import {
  getContentTypeIdFromType,
  CONTENT_DOWNLOAD_LIST,
  getFileTitleFromName,
  getFileTypeLabel,
  formatFileSize
} from '../Utils';

export default class DownloadListForm extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    uploadUrl: PropTypes.string,
    multiple: PropTypes.bool,
    accepted: PropTypes.array
  }
  static defaultProps = {
    className: '',
    model: { body: '[]' },
    uploadUrl: '/data-api/media/upload',
    multiple: false,
    accepted: [
      '.pdf', '.doc', '.docx', '.docx', '.xls', '.xlsx', '.csv',
      '.zip', '.ppt', '.pptx'
    ]
  }
  constructor(props) {
    super(props);
    const { model } = props;
    let files = [];
    try {
      files = JSON.parse(model.body || '[]');
    } catch(err) {
      console.error('Error:', model, err);
      files = [];
    }
    this.state = {
      // title: '',
      // type: 'pdf',
      // path: '',
      files
    };
  }
  getModel = (noValidate) => {
    const { files } = this.state;
    const getData = index => this[`itemForm${index}`].getModel(noValidate);
    const body = files.map((file, index) => {
      const data = getData(index);
      return { ...file, ...data };
    });
    return {
      id: this.props.model.id,
      content_type_id: getContentTypeIdFromType(CONTENT_DOWNLOAD_LIST),
      body: JSON.stringify(body)
    };
  }
  addFile = (event) => {
    // console.log('ImageUploadForm.addFile()', event);
    const response = JSON.parse(event.xhr.response);
    const newFiles = this.props.multiple ? response : [ response ];
    const items = newFiles.map((item, index) => {
      const date = item.date || item.created_date || item.createdDate;
      return {
        path: '/data-api' + item.filePath,
        title: getFileTitleFromName(item.fileName),
        type: getFileTypeLabel(item.fileName),
        size: formatFileSize(event.files[index].size),
        date: (date ? moment(date, 'YYYY-MM-DDTHH:mm:ss:SSSZ') : moment()).format('DD.MM.YYYY')
      };
    });
    const files = [ ...this.state.files ].concat(items);
    // console.log('New Items:', items, files);
    this.setState({ files });
  }
  removeFile = (currentIndex) => {
    const files = [];
    this.state.files.forEach((item, index) => {
      if (index !== currentIndex) {
        files.push(item);
      }
    });
    this.setState(() => ({ files }));
  }
  move = (currentIndex, up) => {
    // const { files } = this.state;
    // const targetIndex = up ? currentIndex - 1 : currentIndex + 1;
    // const src = files[currentIndex];
    // files[currentIndex] = files[targetIndex];
    // files[targetIndex] = src;
    // this.setState({ files });

    const targetIndex = up ? currentIndex - 1 : currentIndex + 1;
    const oldContents = this.state.files;
    // build new content
    const files = [];
    const src = oldContents[currentIndex];
    const tgt = oldContents[targetIndex];
    for (let index = 0; index < oldContents.length; index ++) {
      if (index === currentIndex) {
        files.push(tgt);
      } else if (index === targetIndex) {
        files.push(src);
      } else {
        files.push({ ...oldContents[index] });
      }
    }
    this.setState(() => ({ files }));
  }
  render() {
    console.log('DownloadListForm.render()', this.props);
    const { className, uploadUrl, multiple, accepted } = this.props;
    const { files } = this.state;
    const prefix = Math.random();
    const listMarkup = files.map((item, index) => {
      const { title, type, date, size } = item;
      const props = { title, text: `${date} | ${type} ${size}` };
      return (
        <DownloadListItem
          key={`${prefix}${index}`}
          className="pad-sm-top"
          ref={ref => this[`itemForm${index}`] = ref}
          {...props}
          onMoveUp={(index > 0) ? () => this.move(index, true) : null}
          onMoveDown={(index < files.length - 1) ? () => this.move(index, false) : null}
          onRemove={() => this.removeFile(index)}
        />
      )
    });
    return (
      <div className={`${className} pad`}>
        <div className="w3-row neg-margin-lr form">
          <div className="w3-col s9 pad-lr">
            <h4 className="no-margin pad-sm-tb">Liste</h4>
          </div>
          <div className="w3-col s3 pad-lr alg-right">
            <FileUpload
              name="file"
              // className="btn btn-primary"
              mode="basic"
              auto={true}
              url={uploadUrl}
              multiple={multiple}
              accept={accepted.join(',')}
              chooseLabel="Datei hochladen"
              onUpload={event => this.addFile(event)}
            />
          </div>
        </div>
        <div>{listMarkup}</div>
      </div>
    );
  }
}
