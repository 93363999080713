import React from 'react';
import { PrimaryButtonLink } from '../../../components/Buttons';

export const NewsMenu = ({ className, categoryName = 'general' }) => {
  // console.log('NewsMenu.render()', categoryName);
  let url = '#';
  switch(categoryName) {
    case 'Sales':
      url = `/sales/news-feed/beitrag/neu`; break;
    default:
      url = `/organisation/news-service/beitrag/neu`; break;
  }
  return (
    <div className={`news list-menu ${className}`}>
      <PrimaryButtonLink to={url}>{`Beitrag hinzufügen`}</PrimaryButtonLink>
    </div>
  );
};
