import React from 'react';
import PropTypes from 'prop-types';
// import { OverlayPanel } from 'primereact/overlaypanel';

export default class ModelImageOverlayPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    modem: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    modem: {}
  }
  render() {
    const { modem } = this.props;
    // console.log('ModelImageOverlayPanel.render()modem', modem);
    if (!modem || !(modem.name || modem.productName || modem.product_name)){
      return null;
    }
    const name = (modem.name || modem.productName || modem.product_name);
    let markup = (<span>{name}</span>);
    const images = [];
    // find present images
    // if (modem.image_path && modem.image_path !== '') images.push(modem.image_path);
    // if (modem.models && modem.models.length > 0) {
    //   modem.models.forEach(model => {
    //     if (model.image_path && model.image_path !== '') images.push(model.image_path);
    //   });
    // }
    // ======================================================================================//
    if (images.length === 0) {
      switch(name) {
        case 'Homebox 2': 
          // images.push('/images/modems/HB2-01.png');
          images.push('/images/modems/HB2-02.png');
          break;
        case 'Homebox 3': 
          // images.push('/images/modems/HB3-01.png');
          images.push('/images/modems/HB3-02.png');
          break;
        case 'Homebox 4': 
          // images.push('/images/modems/HB4-01.png');
          images.push('/images/modems/HB4-02.png');
          break;
        case 'D3.0 WLAN': 
          // images.push('/images/modems/D3.0-Modem-01.png');
          images.push('/images/modems/D3.0-Modem-02.png');
          // images.push('/images/modems/D3.0-Modem-03.png');
          images.push('/images/modems/D3.0-Modem-04.png');
          break;
        case 'D3.0 WLAN NextGen': 
          // images.push('/images/modems/D3.0-WLanNG-01.png');
          images.push('/images/modems/D3.0-WLanNG-02.png');
          // images.push('/images/modems/D3.0-WLanNG-03.png');
          images.push('/images/modems/D3.0-WLanNG-04.png');
          break;
        case 'D3.1': 
          // images.push('/images/modems/D3.1-01.png');
          images.push('/images/modems/D3.1-02.png');
          // images.push('/images/modems/D3.1-03.png');
          images.push('/images/modems/D3.1-04.png');
          break;
        default:
          break;
      }
    }
    // ======================================================================================//
    if (images.length > 0) {
      const imageList = images.map((image, index) => (
        <div key={index} className="w3-cell w3-cell-top">
          <img className="thumb-nail" src={image} alt={`Modem Image ${index + 1}`} />
        </div>
      ));
      // markup = (
      //   <div className="pos-rel">
      //     <div className="clickable pos-rel image-overlay" onClick={(e) => this.op.toggle(e)}>
      //       {modem.name || modem.productName || modem.product_name}
      //     </div>
      //     <OverlayPanel ref={(el) => this.op = el}>
      //       <div className="modem-image-overlay-panel image-holder">
      //         <div className="w3-row neg-margin-lr">{imageList}</div>
      //       </div>
      //     </OverlayPanel>
      //   </div>
      // );
      markup = (
        <div className="pos-rel">
          <div className="clickable pos-rel image-overlay-text">
            {modem.name || modem.productName || modem.product_name}
            <div className="modem-image-overlay-panel image-holder">
              <div className="w3-block">{imageList}</div>
            </div>
          </div>
        </div>
      );
    }
    return markup;
  }
} 