import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  EditIconNavButton,
  DeleteIconNavButton,
  ActivateIconButton,
  DeactivateIconButton,
  ViewIconNavButton
} from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import {
  PRODUCT_STATUS_LABELS,
  PRODUCT_STATUS_IDS,
  createSortByAttr,
  isProductActivated,
  isProductDeactivated,
  isProductDeleted,
  PageHeight40,
  scrollToTop
} from '../../../components/Utils';
import { ProductStatusFilter } from '../../../components/ColumnFilters';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadProducts,
  changeProductStatus,
  deleteProduct
} from '../actions/products';

const frontload = async (props) => {
  await props.getProducts();
};
const mapStateToProps = (state) => {
  return {
    products: state.salesProduct.products
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProducts: (...args) => dispatch(loadProducts(...args)),
  changeProductStatus: (...args) => dispatch(changeProductStatus(...args)),
  removeProduct: (...args) => dispatch(deleteProduct(...args)),
});

class ProductTable extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    getProducts: PropTypes.func.isRequired,
    changeProductStatus: PropTypes.func.isRequired,
    removeProduct: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onShowDetails = (product, history) => {
    console.log('Editing', product);
    history.push(`/wizard/sales/produkte/${product.id}`);
  }
  onEditProduct = (product, history) => {
    console.log('Editing', product);
    history.push(`/wizard/sales/produkte/${product.id}`);
  }
  onDeactivate = (product) => {
    console.log('ProductTable.onDeactivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Produkt deaktivieren',
        text: `Wollen Sie das Produkt ${product.product_name} wirklich deaktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.DEACTIVATED
      })
    );
  }
  onActivate = (product) => {
    console.log('ProductTable.onActivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Produkt aktivieren',
        text: `Wollen Sie das Produkt ${product.product_name} wirklich aktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.ACTIVATED
      })
    );
  }
  onDeleteProduct = (product) => {
    console.log('ProductTable.onDeleteProduct()', product);
    this.dialog.show(
      true,
      {
        title: 'Produkt löschen',
        text: `Wollen Sie das Produkt ${product.product_name} wirklich unwiderruflich löschen?`
      },
      () => this.props.removeProduct(product)
    );
  }
  actionTemplate = (rowData) => {
    const editId = `editProductBtn${rowData.id}`;
    const EditComponent = isProductDeleted(rowData) ? ViewIconNavButton : EditIconNavButton;
    const actId = `actProductBtn${rowData.id}`;
    const activateBtn = !isProductActivated(rowData) && !isProductDeleted(rowData) && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivate(rowData)}
      />
    );
    const deactId = `deactProductBtn${rowData.id}`;
    const deactivateBtn = isProductActivated(rowData) && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivate(rowData)}
      />
    );
    const delId = `delProductBtn${rowData.id}`;
    const deleteBtn = !isProductDeleted(rowData) && (
      <DeleteIconNavButton
        id={delId}
        name={delId}
        onClick={() => this.onDeleteProduct(rowData)}
      />
    );
    return (
      <div>
        <div className="w3-cell w3-cell-middle">
          <EditComponent
            id={editId}
            name={editId}
            onClick={(history) => this.onEditProduct(rowData, history)}
          />
        </div>
        <div className="w3-cell w3-cell-middle">
          {activateBtn}
          {deactivateBtn}
        </div>
        <div className="w3-cell w3-cell-middle">
          {deleteBtn}
        </div>
      </div>
    );
  }
  onStatusChange = (selectedStatus) => {
    this.dt.filter(selectedStatus, 'status_id', 'equals');
    this.setState({ selectedStatus });
  }
  render() {
    const { className, products, rows } = this.props;
    // console.log('ProductTable.render()', products);
    if (!products) {
      return null;
    }
    const data = products.map((product, index) => ({
      ...product,
      index,
      status: product.status || PRODUCT_STATUS_LABELS[product.status_id || PRODUCT_STATUS_IDS.ACTIVATED]
    })).sort(createSortByAttr('product_name'));
    const statusBodyTemplate = (rowData) => (
      <span className={`status-text product-status-text-${rowData.status_id}`}>{rowData.status}</span>
    );
    const statusFilter = (
      <ProductStatusFilter
        value={this.state.selectedStatus}
        onChange={this.onStatusChange}
      />
    );
    return (
      <PageHeight40 className={`product-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          rows={rows}
          rowClassName={(data) => ({
            'w3-text-grey product-table-deactivated': isProductDeactivated(data),
            'w3-text-red product-table-deleted': isProductDeleted(data)
          })}
        >
          <Column
            header="Produktname"
            field="product_name"
            sortable
            filter
            filterMatchMode="contains"
          />
          <Column
            header="Produktkategorie"
            field="product_category"
            sortable
            filter
            filterMatchMode="contains"
          />
          <Column
            header="Produktgruppe"
            field="product_group"
            sortable
            filter
            filterMatchMode="contains"
            style={{width: '10em'}}
          />
          <Column
            header="Produkt-Code"
            field="product_code"
            sortable
            filter
            filterMatchMode="contains"
            style={{width: '10em'}}
          />
          <Column
            header="Provision"
            field="product_provision"
            sortable
            filter
            filterMatchMode="contains"
            style={{width: '8em'}}
          />
          <Column
            header="NPV"
            field="product_npv"
            sortable
            filter
            filterMatchMode="contains"
            style={{width: '7em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable
            filter
            filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{width: '10em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '10em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref} />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ProductTable)
);
