import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label } from '../../../components/Inputs';
import { SecondaryButton } from '../../../components/Buttons';
import ModelImageOverlayPanel from './ModelImageOverlay';
import { validateModemOrderAmount } from '../../../components/Utils';

export default class ModemCatalogItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.object.isRequired,
    model: PropTypes.object,
    onAdd: PropTypes.func,
    inCart: PropTypes.bool,
    isEditing: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isTask: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    id: '',
    item: {},
    model: {
      used: '',
      stock: '',
      quantity: '',
      approved: ''
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    // console.log('ModemCatalogItem.componentWillReceiveProps()', props)
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return {
      used: model.used || '',
      stock: model.stock || '',
      quantity: (model.quantity !== undefined && model.quantity !== null) ? model.quantity : '',
      approved: (model.approved !== undefined && model.approved !== null) ? model.approved : '',
      error: {
        used: null,
        stock: null
      },
      required: {
        used: true,
        stock: true
      }
    };
  }
  validate = () => {
    const { isEditing, isAdmin, isTask } = this.props;
    const { error, required, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && required[key]) {
        // if (isNaN(parseInt(model[key]))){
        if (!validateModemOrderAmount(`${model[key]}`)) {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    if (model.quantity === '') {
      model.quantity = 0;
      error.quantity = undefined;
    } else if (!validateModemOrderAmount(`${model.quantity}`)) {
      error.quantity = true;
      isValid = false;
    }
    if (isEditing && isAdmin && isTask) {
      // approved must be set
      if (isNaN(parseInt(model.approved))) {
        error.approved = true;
        isValid = false;
      }
    }
    this.setState({ error });
    if (!isValid) {
      // console.log('ModemCatalogItem.validate() model error', model, error);
      return null;
    }
    const result = {
      used: model.used,
      stock: model.stock,
      quantity: model.quantity
    };
    if (isEditing && isAdmin && isTask) {
      result.approved = model.approved;
    }
    // console.log('Overview Model:', result);
    // return null
    return result;
  }
  getModel = () => {
    const model = this.validate();
    if (model) {
      const { isEditing, isAdmin, isTask } = this.props;
      model.used = parseInt(model.used);
      model.stock = parseInt(model.stock);
      if (model.quantity !== '' && !isNaN(parseInt(model.quantity))) {
        model.quantity = parseInt(model.quantity);
      } else {
        model.quantity = 0; // null;
      }
      if (isEditing && isAdmin && isTask) {
        model.approved = parseInt(model.approved);
        model.company_id = this.props.model.company_id;
        model.created_by_user_id = this.props.model.created_by_user_id;
      }
      const name = this.props.model.product_name || this.props.model.productName || this.props.item.name;
      model.item = { ...this.props.item };
      model.product_id = this.props.model.product_id || this.props.item.id;
      model.product_name = name;
      model.productName = name;
      model.status_id = this.props.model.status_id || 1;
      model.description = this.props.model.description || '';
      if (this.props.model.id !== undefined) {
        model.id = this.props.model.id;
      }
    }
    return model;
  }
  onAddToCart = () => {
    const model = this.getModel();
    this.props.onAdd && this.props.onAdd(model);
  }
  render() {
    const { className, id, item, inCart, isEditing, isAdmin, isTask } = this.props
    const { used, quantity, stock, approved, error } = this.state;
    // console.log(`ModemCatalogItem.render(id: ${id}) modem`, item);
    // values
    const valueSize = 's3'; // isEditing ? 's3' : 's4'; 
    const valueMarkup = (
      <div className="neg-margin-lr w3-row pad-top-">
        <div className={`w3-col pad-lr ${valueSize} form-group-item ${error.used && 'error-group'}`}>
          <Label htmlFor={`${id}-used`}>{'Verbrauch'}</Label>
          <InputText id={`${id}-used`} className="w3-block w3-border" value={used} type="number" disabled={isTask}
            onChange={(event) => this.setState({ used: event.target.value })}/>
        </div>
        <div className={`w3-col pad-lr ${valueSize} form-group-item ${error.stock && 'error-group'}`}>
          <Label htmlFor={`${id}-stock`}>{'Bestand'}</Label>
          <InputText id={`${id}-stock`} className="w3-block w3-border" value={stock} type="number" disabled={isTask}
            onChange={(event) => this.setState({ stock: event.target.value })}/>
        </div>
        <div className={`w3-col pad-lr ${valueSize} form-group-item ${error.quantity && 'error-group'}`}>
          <Label htmlFor={`${id}-quantity`}>{'Bedarf'}</Label>
          <InputText id={`${id}-quantity`} className="w3-quantity w3-border" value={quantity} type="number" disabled={isTask}
            onChange={(event) => this.setState({ quantity: event.target.value })}/>
        </div>
        {isEditing && (
          <div className={`w3-col pad-lr ${valueSize} form-group-item ${isAdmin && error.approved && 'error-group'}`}>
            <Label htmlFor={`${id}-approved`}>{'Freigegeben'}</Label>
            <InputText id={`${id}-approved`} className="w3-block w3-border" value={approved} type="number" disabled={!isAdmin && !isTask}
              onChange={(event) => this.setState({ approved: event.target.value })}/>
          </div>
        )}
      </div>
    );
    // const addBtnMarkup = isEditing ? null : (
    //   <div className="w3-cell w3-cell-bottom" style={{width: '25%'}}>
    //     <div className="pad-lr">
    //       <SecondaryButton id={`${id}-saveItemBtn`} className="w3-block" onClick={this.onAddToCart} disabled={used === '' || stock === ''}
    //         style={{position: 'relative', bottom: '1px'}}>
    //         {inCart ? 'im Warenkorb anpassen' : 'In den Warenkorb'}
    //       </SecondaryButton>
    //     </div>
    //   </div>
    // );
    return (
      <div id={id} className={`modem-catalog-item ${className}`}>
        <div className="pad-top- neg-margin-lr">
          <div className="w3-cell-row">
            <div className="w3-cell w3-cell-top" style={{width: '25%'}}>
              <div className="pad-lr">
                <h4 className="no-margin"><ModelImageOverlayPanel modem={item}/></h4>
              </div>
            </div>
            <div className="w3-cell w3-cell-bottom" style={{width: '75%'/* isEditing ? '75%' : '50%' */}}>
              <div className="pad-lr">{valueMarkup}</div>
            </div>
            {/* addBtnMarkup */}
          </div>
        </div>
      </div>
    );
  }
}
