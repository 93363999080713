import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
import { InputText, Label, Calendar, Dropdown } from '../../../components/Inputs';
import { labelToUrl, toDBDateTime, toUIInputDate } from '../../../components/Utils';
import { isProductPage, PAGE_TYPES } from './Utils';
// import ListItem from '../../Static/components/content/DownloadListItemForm';

export default class PagePathForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    parentUrl: PropTypes.string,
    isEditing: PropTypes.bool,
    type: PropTypes.string,
    nrOld: PropTypes.number,
    nrNew: PropTypes.number,
    nrChildren: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: {
      label: '',
      url: '',
      is_visible: 0,
    },
    parentUrl: '',
    type: PAGE_TYPES.DEFAULT,
    nrOld: 0,
    nrNew: 0,
    nrChildren: 0,
  }
  constructor(props) {
    super(props);
    const { model, parentUrl, type, nrOld, nrNew, nrChildren } = props;
    const data = model.data || {};
    const isActive = (!data.color || data.color === 'black');
    let maxPosition = nrChildren;
    if (isProductPage(type)) {
      maxPosition = isActive ? nrNew : nrOld;
    }
    const defaultPosition = (isProductPage(type) ? 1 : maxPosition);
    this.state = {
      label: model.label || '',
      url: model.url || `${parentUrl}/`,
      isVisible: (model.is_visible === 1),
      isActive,
      position: model.position !== undefined ? model.position : defaultPosition,
      publicationDate: toUIInputDate(data.publicationDate),
      endDate: toUIInputDate(data.endDate),
      error: {
        label: null,
        url: null
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    // position
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    if (this.props.model.id) {
      model.id = this.props.model.id;
    }
    return model;
  }
  getModel = () => {
    const { model, type } = this.props;
    const data = model.data || {};
    const state = this.validate();
    if (!state) {
      return null;
    }
    const result = {
      id: model.id,
      label: state.label,
      url: state.url,
      is_visible: state.isVisible ? 1 : 0,
      position: state.position,
      data: {
        ...data,
        publicationDate: toDBDateTime(state.publicationDate),
        endDate: toDBDateTime(state.endDate)
      }
    };
    if (isProductPage) {
      result.data.color = state.isActive ? 'black' : '#959595';
    }
    return result;
  }
  setLabel = (value) => {
    const { parentUrl, isEditing } = this.props;
    const state = { label: value };
    if (!isEditing) {
      state.url = `${parentUrl}/${labelToUrl(value)}`
    };
    this.setState(state);
  }
  render() {
    const { className, type, isEditing, nrOld, nrNew, nrChildren } = this.props
    const {
      label, url, isVisible, isActive, publicationDate, endDate, position,  error
    } = this.state;
    console.log('PagePathForm.render()', this.props)

    let maxPosition = nrChildren;
    let isActiveMarkup = null;
    if (isProductPage(type)) {
      isActiveMarkup = (
        <div className={`pad-big-top pad-btm`}>
          <div className={`p-col-12`}>
            <Checkbox
              inputId={`isActive`}
              value={'isActive'}
              onChange={(event) => this.setState({isActive: event.checked, position: null})}
              checked={isActive}
            />
            <label htmlFor={`isActive`} className="p-checkbox-label bold">{'aktuell'}</label>
          </div>
        </div>
      );
      maxPosition = isActive ? nrNew : nrOld;
    }

    const positionOptions = [];
    for (let pos = 1; pos <= maxPosition; pos ++) {
      positionOptions.push({
        // label: `${pos}`,
				label: `als ${pos  == 1 ? 'Erster' : (pos === maxPosition ? 'Letzter' : (pos + '.'))}`,
        value: pos
      });
    }

    return (
      <div className={className}>
        <div className={`pad-top- form-group-item${error.label ? ' error-group' : ''}`}>
          <Label htmlFor="label">{`Linktitel`}</Label>
          <InputText
            id="label"
            className="w3-block w3-border"
            value={label}
            onChange={(event) => this.setLabel(event.target.value)}
            maxLength="30"
          />
        </div>
        <div className={`pad-top form-group-item${error.url ? ' error-group' : ''}`}>
          <Label htmlFor="url">{`Pfad`}</Label>
          <InputText
            id="url"
            className="w3-block w3-border"
            value={url}
            disabled={true/* !isEditing */}
            onChange={(event) => this.setState({url: event.target.value})}
          />
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-big-top pad-btm`}>
              <div className={`p-col-12`}>
                <Checkbox
                  inputId={`isVisible`}
                  value={'isVisible'}
                  onChange={(event) => this.setState({isVisible: event.checked})}
                  checked={isVisible}
                />
                <label htmlFor={`isVisible`} className="p-checkbox-label bold">{'ist sichtbar'}</label>
              </div>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            {isActiveMarkup}
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className="w3-row neg-margin-lr">
              <div className="w3-col s6 pad-lr">
                <div className="formgroup-item pad-top">
                  <Label htmlFor="publicationDate">{"Von"}</Label>
                  <Calendar
                    id="publicationDate"
                    className="w3-block w3-border w3-round"
                    value={publicationDate}
                    dateFormat="dd.mm.yy"
                    onChange={(event) => this.setState({publicationDate: event.value})}
                    showButtonBar
                  />
                </div>
              </div>
              <div className="w3-col s6 pad-lr">
                <div className="formgroup-item pad-top">
                  <Label htmlFor="endDate">{"Bis"}</Label>
                  <Calendar
                    id="endDate"
                    className="w3-block w3-border w3-round"
                    value={endDate}
                    dateFormat="dd.mm.yy"
                    onChange={(event) => this.setState({endDate: event.value})}
                    showButtonBar
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="pad-top form-group-item">
              <Label htmlFor="position">{`Position`}</Label>
              <Dropdown
                id="position"
                // className="w3-block w3-border"
                value={position}
                placeholder="als Letzer"
                options={positionOptions}
                onChange={(event) => this.setState({position: event.value})}
                showClear={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
