import React from 'react';
import { Dropdown } from 'primereact/dropdown';
// import { ProgressBar } from 'primereact/progressbar';
// import { Calendar } from 'primereact/calendar';
// import { MultiSelect } from 'primereact/multiselect';
import { PRODUCT_STATUS_LABELS, PRODUCT_ORDER_STATUS_LABELS, USER_STATUS_LABELS } from './Utils';
import Calendar from './Calendar';
import { MultiSelect } from './Inputs';

export const DropdownFilter = ({
  className = 'p-column-filter',
  value,
  options,
  onChange,
  itemTemplate,
  placeholder = 'Bitte auswählen'
}) => {
  return (
    <Dropdown
      value={value}
      options={options}
      onChange={(event) => onChange(event.value)}
      itemTemplate={itemTemplate}
      placeholder={placeholder}
      className={className}
      showClear
    />
  );
};

export const DateFilter = ({
  className = 'p-column-filter',
  value,
  dateFormat = "dd.mm.yy",
  onChange,
  placeholder = 'Bitte auswählen'
}) => {
  return (
    <Calendar
      value={value}
      dateFormat={dateFormat}
      onChange={(event) => onChange(event.value)}
      placeholder={placeholder}
      className={className}
      showClear
    />
  );
};

export const MultiSelectFilter = ({
  className = 'p-column-filter',
  value,
  options,
  optionLabel,
  optionValue,
  onChange,
  itemTemplate,
  placeholder = 'Alle'
}) => {
  return (
    <MultiSelect
      value={value}
      options={options}
      itemTemplate={itemTemplate}
      onChange={(event) => onChange(event.value)}
      optionLabel={optionLabel}
      optionValue={optionValue}
      placeholder={placeholder}
      className={className}
    />
  );
};

/**
 * Filter Component for User Status
 * @param {*} props 
 */
export const UserStatusFilter = (props = {}) => {
  const options = Object.keys(USER_STATUS_LABELS).map(key => ({
    label: USER_STATUS_LABELS[key],
    value: key
  }));
  const itemTemplate = (item) => (
    <span className={`status-text user-status-text-${item.value}`}>{item.label}</span>
  );
  return (
    <DropdownFilter
      {...props}
      options={options}
      placeholder=""
      itemTemplate={itemTemplate}
    />
  );
};

/**
 * Filter Component for Product Status
 * @param {*} props 
 */
export const ProductStatusFilter = (props = {}) => {
  const options = Object.keys(PRODUCT_STATUS_LABELS).map(key => ({
    label: PRODUCT_STATUS_LABELS[key],
    value: key // PRODUCT_STATUS_LABELS[key]
  }));
  const itemTemplate = (item) => (
    <span className={`status-text product-status-text-${item.value}`}>{item.label}</span>
  );
  return (
    <DropdownFilter
      {...props}
      options={options}
      placeholder=""
      itemTemplate={itemTemplate}
    />
  );
};

/**
 * Filter Component for Product Order Status
 * @param {*} props 
 */
export const ProductOrderStatusFilter = (props = {}) => {
  const options = Object.keys(PRODUCT_ORDER_STATUS_LABELS).map(key => ({
    label: PRODUCT_ORDER_STATUS_LABELS[key],
    value: key
  }));
  const itemTemplate = (item) => (
    <span className={`status-text product-order-status-text-${item.value}`}>{item.label}</span>
  );
  return (
    <DropdownFilter
      {...props}
      options={options}
      placeholder=""
      itemTemplate={itemTemplate}
    />
  );
};
