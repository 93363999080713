import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import RegionForm from './RegionForm';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import { TableEmptyMessage } from '../../../../components/Utils';


export default class RegionListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    regions: PropTypes.array,
    debitors: PropTypes.array,
    isAdmin: PropTypes.bool,
    getBaseData: PropTypes.func,
    onChange: PropTypes.func,
    isCompanySpecialKDL: PropTypes.bool,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
  }
  validate = () => {
    const regions = this.regions.getModel();
    const model = [ ...regions ];
    console.log('RegionList Model:', model);
    if (model.length === 0) {
      this.regions.toggleError(true);
      return null;
    } else {
      this.regions.toggleError(false);
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  toggleAddition = (disabled) => {
    this.regions.toggleAddition(disabled);
  }
  setDebitors = (debitors) => {
    this.regions.setDebitors(debitors);
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <Region
          ref={(ref) => this.regions = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class Region extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    regions: PropTypes.array,
    debitors: PropTypes.array,
    isAdmin: PropTypes.bool,
    getBaseData: PropTypes.func,
    onChange: PropTypes.func,
    isCompanySpecialKDL: PropTypes.bool,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    const items = (props.model || []);
    const regions = (props.regions || []).filter(region => {
      let notFound = true;
      for (const item of items) {
        if (item.name === region) {
          notFound = false;
          break;
        }
      }
      return notFound;
    });
    this.state = {
      items: items.map((item, index) => ({ ...item, index })),
      regions,
      addDisabled: false,
      error: null,
      debitors: props.debitors || [],
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return [ ...items ];
    const model = items.map(item => {
      const { id, name, debitorKey, debitorMax, companyName, address, storageLocation } = item;
      const region = { id, name, debitorKey, debitorMax, companyName, address, storageLocation };
      return region;
    });
    return model;
  }
  toggleAddition = (disabled) => {
    this.setState({ addDisabled: disabled });
  }
  setDebitors = (debitors) => {
    // console.log('RegionListForm.setDebitors()', debitors);
    this.setState({ debitors });
  }
  toggleError = (error) => {
    this.setState({ error });
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving region ...', model);
    if (!model) return;
    const items = this.state.items;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    const regions = this.state.regions.filter(item => (item !== model.name));
    const addDisabled = regions.length === 0;
    this.setState({ items, regions, addDisabled });
    this.props.onChange && this.props.onChange(items);
    onHide && onHide();
  }
  onDelete = (selected) => {
    console.log('>> deleting', selected);
    const items = [];
    this.state.items.forEach(item => {
      // console.log('>>>> comparing', item.name, 'with', selected.name);
      if (item.name !== selected.name) {
        console.log('<<<< adding', item.name, '...');
        items.push(item);
      }
    });
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, isAdmin, rows, getBaseData, isCompanySpecialKDL } = this.props;
    const { regions, debitors, first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const getMinDebitorsProRegion = (region) => {
      const regionDebitors = debitors.filter(debitor => debitor.region === region.name);
      return regionDebitors.length;
    };
    const isDeleteDisabled = (region) => {
      const regionDebitors = debitors.filter(debitor => debitor.region === region.name);
      return regionDebitors.length > 0;
    };
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => ({ ...item, index })).sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Regionen"
              itemName="Region"
              itemNamePlural="Regionen"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Regionen" min/>)}
        >
          <Column
            field="name"
            header="Region"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center'}}
          />
          {isAdmin && (
            <Column
              field="storageLocation"
              header="Storage Location"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
              style={{textAlign: 'center'}}
            />
          )}
          <Column
            field="debitorKey"
            header="Kennung"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center'}}
          />
          <Column
            field="debitorMax"
            header="Maximale Debitorenanzahl"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign: 'center'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const region = item || {
        name: '',
        debitorKey: '',
        debitorMax: 10
      };
      const baseData = getBaseData ? getBaseData() : {};
      // console.log('RegionListForm.renderItemForm()', region, baseData);
      return (
        <div>
          <RegionForm ref={(ref) => this.form = ref}
            key={Math.random()}
            isAdmin={isAdmin}
            model={region}
            regions={regions}
            isCompanySpecialKDL={isCompanySpecialKDL}
            disabled={!isAdmin}
            {...baseData}
            minNrDebitors={getMinDebitorsProRegion(region)}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey" 
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)}
            onCancel={onHide}
            saveHidden={!isAdmin}
            saveBtnName="saveRegionBtn"
          />
        </div>
      );
    };
    const getItemFormTitle = (region) => {
      return (<h3 className="no-margin">{`Region ${region ? 'bearbeiten' : 'hinzufügen'}`}</h3>);
    }
    const titleClassName = `no-margin-top`;
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className={titleClassName}>Regionen</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addCompanyRegionBtn"
        addBtnDisabled={this.state.addDisabled}
        addBtnHidden={!isAdmin}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="region"
        itemVisibleOnly={this.props.disabled}
        isDeleteDisabled={isDeleteDisabled}
        hideDisabledDelete
        itemDeleteable={!this.props.disabled}
        deleteTitle="Region löschen"
        getDeleteText={(item) => `Wollen Sie die Region ${item.name} unwiderruflich löschen?`}
        onDelete={this.onDelete}
      />
    );
  }
}
