import React from "react";
import { ButtonLink, PrimaryButton } from "../../../components/Buttons";
import {
  W3Dropdown,
  W3DropdownContent,
  W3DropdownToggler
} from "../../../components/W3Dropdown";

import { connect } from "react-redux";
import { exportDebitorMapping } from "../actions";

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  exportMappings: (...args) => dispatch(exportDebitorMapping(...args)),
});

const DebitorMappingMenuFunc = ({
  className = "",
  exportMappings,
}) => {
  const exportDebitorMapping = () => {
    console.log(">> exporting debitor mapping ...");
    exportMappings();
  };
  return (
    <div className={className}>
      <W3Dropdown className="modems dropdown-list-menu">
        <W3DropdownToggler as={PrimaryButton}>{`Optionen`}</W3DropdownToggler>
        <W3DropdownContent
          className="w3-bar-block w3-border"
          style={{right: '0', width: '280px'}}
        >
          <ButtonLink
            to="/wizard/shop/debitor-mapping/import"
            className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
          >
              {`Debitor-Mapping importieren`}
          </ButtonLink>
          <a href="#" className="w3-bar-item separator"><div className="w3-border-top" style={{margin: '0 -4px'}}/></a>
          <ButtonLink
            to="#"
            className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
            onClick={exportDebitorMapping}>
              {`Debitor-Mapping exportieren`}
          </ButtonLink>
        </W3DropdownContent>
      </W3Dropdown>
    </div>
  );
};
export const DebitorMappingMenu = connect(mapStateToProps, mapDispatchToProps)(DebitorMappingMenuFunc);
