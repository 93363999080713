import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Dropdown } from '../../../../components/Inputs';
import { PlusIconButton, DeleteIconButton } from '../../../../components/Buttons';

export default class ApprovalItemListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    model: PropTypes.object,
    modelOptions: PropTypes.array,
    onChange: PropTypes.func,
    onTab: PropTypes.func,
    isParentControlled: PropTypes.bool,
    hasFocus: PropTypes.bool,
    focusChanged: PropTypes.bool,
    currentIndex: PropTypes.number,
    previousIndex: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    id: '',
    model: {
      approved: 0,
      model_id: 0,
      distributions: []
    },
    models: []
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props, true);
  }
  componentWillReceiveProps(props) {
    if (props.isParentControlled) {
      this.setState(this.getState(props));
    }
    // if (props.onChange) {
    //   this.setState(this.getState(props));
    // }
  }
  // componentDidMount() {
  //   console.log('ApprovalItemListForm.componentDidMount()');
  //   this.selectFocusedInputText()
  // }
  // componentDidUpdate() {
  //   console.log('ApprovalItemListForm.componentDidUpdate()');
  //   this.selectFocusedInputText()
  // }
  // selectFocusedInputText = () => {
  //   // select value of input field with focus
  //   const { hasFocus } = this.props;
  //   if (hasFocus) {
  //     this.state.items.forEach((item, index) => {
  //       if (index === this.state.currentIndex && this[`input${index}`]) {
  //         console.log('>> selecting the text of the input', this[`input${index}`]);
  //         this[`input${index}`].select();
  //       }
  //     });
  //   }
  // }
  getState = (props, init) => {
    console.log('ApprovalItemListForm.getState()', props, init);
    const { model, modelOptions, hasFocus } = props;
    const items = [{
      approved: model.approved || 0,
      model_id: model.model_id || -1,
      // model_id: (model.model_id && model.model_id != -1) ? model.model_id: (model.approved ? modelOptions[0].value : -1),
    }].concat((model.distributions || []).map(item => ({
      approved: item.approved || 0,
      model_id: item.model_id || -1,
    })));
    const error = model.error || items.map(item => ({
      approved: null,
      model_id: null,
    }));
    const totalApproved = items.reduce(
      (prev, curr) => parseInt(prev.approved + curr.approved),
      { approved: 0 }
    );
    const totalError = ((model.quantity > 0 && totalApproved <= 0) ? true : null);
    return { items, currentIndex: (props.currentIndex != null ? props.currentIndex : 0), hasFocus, error, totalError };
  }
  validate = () => {
    const { items, error } = this.state;
    // check if all values are set
    let isValid = true;
    items.forEach((item, index) => {
      if (item.approved == '') {
        error[index].approved = true;
        isValid = false;
      }
      if (item.model_id == '') {
        error[index].model_id = true;
        isValid = false;
      }
    });
    const totalApproved = items.reduce(
      (prev, curr) => parseInt(prev.approved + curr.approved),
      { approved: 0 }
    );
    totalError = ((this.props.model.quantity > 0 && totalApproved <= 0) ? true : null);
    isValid = isValid || (totalError === null);
    this.setState({ error, totalError });
    if (!isValid) {
      return null;
    }
    return items;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  changeItem = (index, key, value) => {
    // console.log('changeItem( )', index, value);
    // replace preceding 0
    const number = (`${value}`.charAt(0) === '0' && `${value}`.length > 0) ? `${value}`.substr(1) : value;
    const { items } = this.state;
    if (index >= 0 && index < items.length) {
      items[index][key] = number;
    }
    const totalApproved = items.reduce(
      (prev, curr) => parseInt(prev.approved + curr.approved),
      { approved: 0 }
    );
    const totalError = ((this.props.model.quantity > 0 && totalApproved <= 0) ? true : null);
    // if (this.props.onChange) this.props.onChange(items);
    // else this.setState({ items });
    const currentIndex = index;
    const { onChange, isParentControlled } = this.props;
    if (isParentControlled) {
      onChange && onChange(items, currentIndex);
    } else this.setState({ items, currentIndex, totalError });
  }
  addItem = () => {
    const { items } = this.state;
    items.push({ approved: 0, model_id: 0, index: items.length });
    // if (this.props.onChange) this.props.onChange(items);
    // else this.setState({ items });
    const currentIndex = items.length - 1;
    const { onChange, isParentControlled } = this.props;
    if (isParentControlled) {
      onChange && onChange(items, currentIndex);
    } else this.setState({ items, currentIndex });
  }
  removeItem = (index) => {
    const { items } = this.state;
    let currentIndex = this.state.currentIndex;
    currentIndex = (currentIndex === (items.length - 1)) ? currentIndex - 1 : currentIndex;
    items.splice(index, 1);
    const totalApproved = items.reduce(
      (prev, curr) => parseInt(prev.approved + curr.approved),
      { approved: 0 }
    );
    const totalError = ((this.props.model.quantity > 0 && totalApproved <= 0) ? true : null);
    // if (this.props.onChange) this.props.onChange(items);
    // else this.setState({ items });
    const { onChange, isParentControlled } = this.props;
    if (isParentControlled) {
      onChange && onChange(items, currentIndex);
    } else this.setState({ items, currentIndex, totalError });
  }
  onKeyDown = (index, event) => {
    // console.log('ApprovalItemListForm.onKeyDown()', index, event, event.shiftKey);
    if (event.keyCode === 9) {
      const { /* currentIndex, */ items } = this.state;
      const isInnerMove = (maxIndex, currentIndex) => {
        const isBackward = event.shiftKey;
        const data = {
          isBackward,
          isInner: isBackward ? (currentIndex - 1 >= 0) : (currentIndex + 1 <= maxIndex),
          index: isBackward ? (currentIndex - 1) : (currentIndex + 1)
        };
        return data;
      };
      const data = isInnerMove(items.length - 1, index);
      // console.log('ApprovalItemListForm.onKeyDown()', items.length - 1, index, data);
      const { onTab } = this.props;
      if (onTab) {
        event.preventDefault();
        onTab(data, data.index);
      }
    }
  }
  render() {
    // console.log('ApprovalItemListForm.render() id', this.props.id, this.state);
    const { className, id, modelOptions, focusChanged, previousIndex, model } = this.props
    const { items, currentIndex, hasFocus, error, totalError } = this.state;
    const ModelOptions = [{ label: 'Bitte auswählen', value: -1 }].concat(modelOptions);
    console.log("Total Approved Error:", totalError);
    const shouldChangeColor = (model.quantity > 0);
    const style = {};
    if (shouldChangeColor) {
      style.outlineColor = totalError ? "#f44336" : "#4CAF50";
    }

    const itemsMarkup = items.map((item, index) => {
      const itemErr = error[index] || {};
      // console.log('Item', index, item, itemErr);
      const btnMarkup = index === 0 ? (
        <PlusIconButton id={`${id}-optAddBtn${index}`} className="w3-border" onClick={() => this.addItem(index)}/>
      ) : (
        <DeleteIconButton id={`${id}-optDelBtn${index}`} className="w3-border" onClick={() => this.removeItem(index)}/>
      );
      return (
        <div key={index} className={`w3-cell-row ${(index > 0) && 'margin-top'}`} id={id}>
          <div
            className={`w3-cell w3-cell-top pad-sm-tr ${(itemErr.approved || totalError) && 'error-group'}`}
            style={{width: '30%'}}
          >
            <InputText
              ref={ref => this[`input${index}`] = ref}
              id={`${id}-optApproved${index}`}
              className={`w3-block w3-border ${shouldChangeColor && !totalError ? "w3-border-green" : ""}`}
              value={item.approved}
              autoFocus={hasFocus && index === currentIndex}
              onChange={(event) => this.changeItem(index, 'approved', event.target.value)}
              onKeyDown={(event) => this.onKeyDown(index, event)}
              onFocus={(event) => {
                // console.log(' >> focusChanged:', focusChanged, 'currentIndex:', currentIndex, 'previousIndex:', previousIndex);
                if (focusChanged || currentIndex !== previousIndex) {
                  event.target.select();
                }
              }}
              onMouseUp={(event) => event.preventDefault()}
              style={style}
            />
          </div>
          <div className={`w3-cell w3-cell-top pad-sm-top pad-sm-lr ${itemErr.model_id && 'error-group'}`}>
            <Dropdown
              id={`${id}-optModel_id${index}`}
              className="w3-block w3-border"
              value={item.model_id}
              options={ModelOptions}
              onChange={(event) => this.changeItem(index, 'model_id', event.value)}
              showClear
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-sm-tl alg-right" style={{width: '38px'}}>{btnMarkup}</div>
        </div>
      );
    });
    return (<div className={className}>{itemsMarkup}</div>);
  }
}
