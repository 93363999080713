import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from '../../../../components/Inputs';
import Connection from './Connection';
import { STATUS_LABELS, statusIsEditable, STATUS_IDS, ACTION_IDS } from '../../../../components/Utils';
import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

const YesNoOptions = [
  { label: 'Ja', value: 'Ja' },
  { label: 'Nein', value: 'Nein' }
];

export default class ITConnectionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companyList: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    isAdmin: PropTypes.bool,
    isWorkerPlus: PropTypes.bool,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: {
      ptConnection: '',
      ptUsername: '',
      ptPassword: '',
      adConnection: '',
      adUsername: '',
      adPassword: '',
      delphiConnections: []
    },
    companyList: [],
    kdlCompanyList: [],
    rows: 5
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      adConnection: model.adConnection || '',
      adUsername: model.adUsername || '',
      adPassword: model.adPassword || '',
      adAction_id: model.adAction_id || '',
      adStatus_id: model.adStatus_id || '',
      adStatus: model.adStatus || STATUS_LABELS[model.adStatus_id],
      ptConnection: model.ptConnection || '',
      ptUsername: model.ptUsername || '',
      ptPassword: model.ptPassword || '',
      ptAction_id: model.ptAction_id || '',
      ptStatus_id: model.ptStatus_id || '',
      ptStatus: model.ptStatus || STATUS_LABELS[model.ptStatus_id],
      error: {
        adConnection: null,
        // adUsername: null,
        ptConnection: null,
        // ptUsername: null,
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    // delphi connections
    const connections = this.connections.getModel();
    model.delphiConnections = connections;

    const pModel = this.props.model || {};
    const { id, ad_id, pt_id/* , adStatus_id, ptStatus_id */ } = pModel;
    model.id = id;
    model.ad_id = ad_id;
    model.pt_id = pt_id;
    // model.adStatus_id = adStatus_id;
    // model.ptStatus_id = ptStatus_id;
    console.log('ITConnection Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  executeChange = (connectionType, actionId, statusId) => {
    const prefix = connectionType.toLowerCase();
    this.setState({
      [`${prefix}Action_id`]: actionId,
      [`${prefix}Status_id`]: statusId,
      [`${prefix}Status`]: STATUS_LABELS[statusId],
    });
  }
  onChange = (connectionType) => {
    const title = `${connectionType}-Zugang ändern`;
    const text = `Wollen Sie eine Verlängerung des ${connectionType}-Zugangs wirklich beantragen?`;
    const execFunc = () => this.executeChange(connectionType, ACTION_IDS.UPDATE, STATUS_IDS.UPDATING);
    this.confirm.show(true, { title, text }, execFunc );
  }
  onDelete = (connectionType) => {
    const title = `${connectionType}-Zugang löschen`;
    const text = `Wollen Sie eine Löschung des ${connectionType}-Zugangs wirklich beantragen?`;
    const execFunc = () => this.executeChange(connectionType, ACTION_IDS.DELETE, STATUS_IDS.DELETING);
    this.confirm.show(true, { title, text }, execFunc );
  }
  setADConnection = (value) => {
    this.setState({ adConnection: value })
  }
  renderConnectionBtns = (connectionType, model) => {
    const idAttr = `${connectionType.toLowerCase()}_id`;
    const statusAttr = `${connectionType.toLowerCase()}Status_id`;
    return (
      <div>
        <PrimaryButton
          size="small"
          disabled={!statusIsEditable(model[statusAttr], model[idAttr])}
          onClick={() => this.onChange(connectionType)}>
          {`Ändern`}
        </PrimaryButton>
        <SecondaryButton
          className="margin-top"
          size="small"
          disabled={!statusIsEditable(model[statusAttr], model[idAttr])}
          onClick={() => this.onDelete(connectionType)}>
            {`Löschen`}
        </SecondaryButton>
      </div>
    );
  }
  render() {
    const { className, companyList, userCompany, kdlCompanyList, isAdmin, isWorkerPlus, model, rows } = this.props
    const {
      adConnection, adUsername, adPassword, adStatus,
      ptConnection, ptUsername, ptPassword, ptStatus
      /* error */
    } = this.state;
    console.log('ITConnection.render() disableDelphi', (adConnection !== 'Ja'), this.state, this.props);
    // const adStatus = model.adStatus || STATUS_LABELS[model.adStatus_id];
    // const ptStatus = model.ptStatus || STATUS_LABELS[model.ptStatus_id];
    return (
      <div className={className}>
        <div className="w3-border-top neg-margin-big-lr margin-btm"/>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-top">
            {/* <div className="w3-row neg-margin-lr">
              <div className="w3-col s3 pad-lr pad-top">
                <Label htmlFor="adConnection">{`AD-Zugang*`}</Label>
                <Dropdown id= "adConnection" className="w3-block w3-border"
                  value={adConnection}
                  placeholder="Bitte auswählen"
                  options={YesNoOptions}
                  onChange={(event) => this.setADConnection(event.value)}
                  showClear
                />
              </div>
              <div className="w3-col s3 pad-lr pad-top">
                <Label htmlFor="adUsername">{`AD-Kennung`}</Label>
                <InputText id="adUsername" className="w3-block w3-border" value={adUsername} disabled={true}
                  onChange={(event) => this.setState({adUsername: event.target.value})}/>
              </div>
              {(isAdmin) && (
                <div className="w3-col s3 pad-lr pad-top">
                  <Label htmlFor="adPassword">{`AD-Passwort`}</Label>
                  <InputText id="adPassword" className="w3-block w3-border" value={adPassword} disabled={true/}
                    onChange={(event) => this.setState({adPassword: event.target.value})}/>
                </div>
              )}
              <div className="w3-col s3 pad-lr pad-top">
                <Label htmlFor="adStatus">{`AD-Zugangstatus`}</Label>
                <InputText id="adStatus" className="w3-block w3-border" value={adStatus} disabled/>
              </div>
            </div> */}
            <div className="w3-row neg-margin-lr">
              <div className="w3-col s2 pad-lr pad-top">
                <Label htmlFor="adConnection">{`AD-Zugang*`}</Label>
                <Dropdown id= "adConnection" className="w3-block w3-border"
                  value={adConnection}
                  placeholder="Bitte auswählen"
                  options={YesNoOptions}
                  onChange={(event) => this.setADConnection(event.value)}
                  showClear
                />
              </div>
              <div className="w3-col s8 pad-lr">
                <div className="w3-row neg-margin-lr">
                  <div className="w3-col s4 pad-lr pad-top">
                    <Label htmlFor="adUsername">{`AD-Kennung`}</Label>
                    <InputText id="adUsername" className="w3-block w3-border" value={adUsername} disabled={true/* !isAdmin */}
                      onChange={(event) => this.setState({adUsername: event.target.value})}/>
                  </div>
                  <div className="w3-col s4 pad-lr pad-top">
                    {this.props.children}
                  </div>
                  {isAdmin && (
                    <div className="w3-col s4 pad-lr pad-top">
                      <Label htmlFor="adPassword">{`AD-Passwort`}</Label>
                      <InputText id="adPassword" className="w3-block w3-border" value={adPassword} disabled={true/* !isAdmin */}
                        onChange={(event) => this.setState({adPassword: event.target.value})}/>
                    </div>
                  )}
                </div>
              </div>
              <div className="w3-col s2 pad-lr pad-top">
                <Label htmlFor="adStatus">{`AD-Zugangstatus`}</Label>
                <InputText id="adStatus" className="w3-block w3-border" value={adStatus} disabled/>
              </div>
            </div>
          </div>
          {this.props.isAdmin && (
            <div className="w3-cell w3-cell-bottom alg-rht" style={{width: '150px'}}>
              {this.props.isAdmin && this.renderConnectionBtns('AD', model)}
            </div>
          )}
        </div>
        <div className="w3-border-top neg-margin-big-lr margin-big-top margin-btm"/>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-top">
            <div className="w3-row neg-margin-lr">
              <div className="w3-col s2 pad-lr pad-top">
                <Label htmlFor="ptConnection">{`Prov.-tool-Zugang*`}</Label>
                <Dropdown id="ptConnection" className="w3-block w3-border"
                  value={ptConnection}
                  placeholder="Bitte auswählen"
                  disabled={adConnection !== 'Ja'}
                  options={YesNoOptions}
                  onChange={(event) => this.setState({ptConnection: event.value})}
                  showClear
                />
              </div>
              <div className="w3-col s8 pad-lr">
                <div className="w3-row neg-margin-lr">
                  <div className="w3-col s4 pad-lr pad-top">
                    <Label htmlFor="ptUsername">{`Prov.-tool-Kennung`}</Label>
                    <InputText
                      id="ptUsername"
                      className="w3-block w3-border"
                      value={ptUsername}
                      disabled={true/* !isAdmin */}
                      onChange={(event) => this.setState({ptUsername: event.value})}
                    />
                  </div>
                  {isAdmin && (
                    <div className="w3-col s4 pad-lr pad-top">
                      <Label htmlFor="ptPassword">{`Prov.-tool-Passwort`}</Label>
                      <InputText
                        id="ptPassword"
                        className="w3-block w3-border"
                        value={ptPassword}
                        disabled={true/* !isAdmin */}
                        onChange={(event) => this.setState({ptPassword: event.target.value})}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w3-col s2 pad-lr pad-top">
                <Label htmlFor="ptStatus">{`PT-Zugangstatus`}</Label>
                <InputText
                  id="ptStatus"
                  className="w3-block w3-border"
                  value={ptStatus}
                  disabled
                />
              </div>
            </div>
          </div>
          {this.props.isAdmin && (
            <div className="w3-cell w3-cell-bottom alg-rht" style={{width: '150px'}}>
              {this.props.isAdmin && this.renderConnectionBtns('PT', model)}
            </div>
          )}
        </div>
        <div className="w3-border-top neg-margin-big-lr margin-big-top margin-btm"/>
        <Connection ref={(ref) => this.connections = ref}
          className="pad-big-top"
          model={model.delphiConnections}
          companyList={companyList}
          kdlCompanyList={kdlCompanyList}
          userCompany={userCompany}
          disableDelphi={adConnection !== 'Ja'}
          isAdmin={isAdmin}
          isWorkerPlus={isWorkerPlus}
          rows={rows}
        />
        <ConfirmationDialog ref={ref => this.confirm = ref} />
      </div>
    );
  }
}
