import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';

import { Label } from './Inputs';
import { PrimaryButton, EditIconButton, DeleteIconButton, ViewIconButton, ActivateIconButton, DeactivateIconButton, CancelIconButton } from './Buttons';
import ConfirmationDialog from './ConfirmationDialog';


export default class ItemListManager extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    addBtnLabel: PropTypes.string,
    addBtnName: PropTypes.string,
    addBtnDisabled: PropTypes.bool,
    addBtnHidden: PropTypes.bool,
    exportBtnLabel: PropTypes.string,
    exportBtnName: PropTypes.string,
    exportBtnDisabled: PropTypes.bool,
    exportBtnHidden: PropTypes.bool,
    onExport: PropTypes.func,
    itemPanelStyle: PropTypes.object,
    items: PropTypes.array,
    getItemFormTitle: PropTypes.func.isRequired,
    renderItemTable: PropTypes.func.isRequired,
    renderItemForm: PropTypes.func.isRequired,
    namePrefix: PropTypes.string,
    itemVisibleOnly: PropTypes.bool,
    itemDeleteable: PropTypes.bool,
    hideDisabledDelete: PropTypes.bool,
    deleteTitle: PropTypes.string,
    getDeleteText: PropTypes.func,
    isDeleteDisabled: PropTypes.func,
    onDelete: PropTypes.func,
    isEditDisabled: PropTypes.func,
    showItemDeleteReason: PropTypes.bool,
    showDisabledDeActivate: PropTypes.bool,
    itemDeactivateable: PropTypes.bool,
    activateTitle: PropTypes.string,
    getActivateText: PropTypes.func,
    isActivateDisabled: PropTypes.func,
    deactivateTitle: PropTypes.string,
    getDeactivateText: PropTypes.func,
    isDeactivateDisabled: PropTypes.func,
    onDeActivate: PropTypes.func,
    itemCloseableOnly: PropTypes.bool,
    closeTitle: PropTypes.string,
    getCloseText: PropTypes.func,
    isCloseDisabled: PropTypes.func,
    onClose: PropTypes.func,
    showItemCloseReason: PropTypes.bool,
    hideDisabledClose: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    title: 'Liste',
    addBtnLabel: 'Hinzufügen',
    addBtnName: '',
    exportBtnLabel: 'Export',
    exportBtnName: 'export',
    exportBtnHidden: true,
    itemPanelStyle: { width: '50vw' },
    getItemFormTitle: (selected) => {
      if (!selected) return 'Anlegen';
      return 'Bearbeiten';
    },
    namePrefix: '',
    getDeleteText: () => 'Wollen Sie wirklich löschen?'
  }
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      items: props.items,
      selected: null
    }
  }
  onSelect = (selected) => {
    // console.log('Editing:', selected);
    this.setState({
      isEditing: true,
      selected
    });
  }
  onActivate = (selected) => {
    const { activateTitle, getActivateText, onDeActivate } = this.props;
    if (activateTitle && getActivateText) {
      this.confirm.show(
        true,
        {
          title: activateTitle,
          text: getActivateText(selected)
        },
        () => (onDeActivate && onDeActivate(selected, true))
      );
    } else {
      onDeActivate && onDeActivate(selected, true);
    }
  }
  onDeactivate = (selected) => {
    const { deactivateTitle, getDeactivateText, onDeActivate } = this.props;
    if (deactivateTitle && getDeactivateText) {
      this.confirm.show(
        true,
        {
          title: deactivateTitle,
          text: getDeactivateText(selected)
        },
        () => (onDeActivate && onDeActivate(selected, false))
      );
    } else {
      onDeActivate && onDeActivate(selected, false);
    }
  }
  onDelete = (selected) => {
    // console.log('Deleting:', selected);
    const { deleteTitle, getDeleteText, onDelete, showItemDeleteReason } = this.props;
    this.confirm.show(
      true,
      { title: deleteTitle, text: getDeleteText(selected) },
      (reason) => {
        let data = selected;
        if (showItemDeleteReason) {
          data = { ...data, ...reason };
        }
        onDelete && onDelete(data);
      }
    );
  }
  onClose = (selected) => {
    // console.log('Closing:', selected);
    const { closeTitle, getCloseText, onClose, showItemCloseReason } = this.props;
    this.confirm.show(
      true,
      { title: closeTitle, text: getCloseText(selected) },
      (reason) => {
        let data = selected;
        if (showItemCloseReason) {
          data = { ...data, ...reason };
        }
        onClose && onClose(data);
      }
    );
  }
  render() {
    const {
      className, title, addBtnLabel, addBtnName, addBtnDisabled, addBtnHidden,
      exportBtnLabel, exportBtnName, exportBtnDisabled, exportBtnHidden, onExport,
      itemDeleteable, hideDisabledDelete, isDeleteDisabled, isEditDisabled, itemVisibleOnly,
      itemDeactivateable, isActivateDisabled, isDeactivateDisabled, showDisabledDeActivate,
      itemPanelStyle, renderItemTable, renderItemForm, getItemFormTitle, namePrefix, showItemDeleteReason,
      itemCloseableOnly, isCloseDisabled, showItemCloseReason, hideDisabledClose
    } = this.props;
    const { isEditing, items, selected } = this.state;
    const actionTemplate = (rowData) => {
      // item closeable
      if (itemCloseableOnly) {
        const closeDisabled = isCloseDisabled ? isCloseDisabled(rowData) : false;
        return (hideDisabledClose && closeDisabled) ? null : (
          <div>
            <div className="w3-cell w3-cell-top">
              <CancelIconButton
                id={`${namePrefix}-cancelBtn${rowData.id || rowData.index || rowData.name || ''}`}
                onClick={() => this.onClose && this.onClose(rowData)}
                disabled={closeDisabled}
                title="schließen"
              />
            </div>
          </div>
        );
      }
      // activate/deactivate buttons
      const activateDisabled = isActivateDisabled ? isActivateDisabled(rowData) : false;
      const activateBtn = activateDisabled ? null : (
        <ActivateIconButton
          id={`${namePrefix}-actBtn${rowData.id}`}
          name={`${namePrefix}-actBtn${rowData.id}`}
          onClick={() => this.onActivate(rowData)}
          title="aktivieren"
          disabled={activateDisabled}
        />
      );
      const deactivateDisabled = isDeactivateDisabled ? isDeactivateDisabled(rowData) : false;
      const deactivateBtn = (deactivateDisabled && !showDisabledDeActivate) ? null : (
        <DeactivateIconButton
          id={`${namePrefix}-deactBtn${rowData.id}`}
          name={`${namePrefix}-deactBtn${rowData.id}`}
          onClick={() => this.onDeactivate(rowData)}
          title="deaktivieren"
          disabled={deactivateDisabled}
        />
      );
      const deactivateMarkup = !itemDeactivateable ? null : (
        <div className="w3-cell w3-cell-top">
          {/* {activateBtn}{deactivateBtn} */}
          {activateBtn || deactivateBtn}
        </div>
      );

      // delete btn
      const deleteDisabled = isDeleteDisabled ? isDeleteDisabled(rowData) : false;
      // console.log('>> Row Item:', rowData, '\ndeleteDisabled', deleteDisabled, 'hideDisabledDelete', hideDisabledDelete);
      const deleteBtnVisible = (itemDeleteable && !(hideDisabledDelete && deleteDisabled));
      const deleteBtn = !deleteBtnVisible ? null : (
        <div className="w3-cell w3-cell-top">
          <DeleteIconButton
            id={`${namePrefix}-deleteBtn${rowData.id}`}
            onClick={() => this.onDelete(rowData)}
            disabled={deleteDisabled}
            title="löschen"
          />
        </div>
      );

      const editDisabled = isEditDisabled ? isEditDisabled(rowData) : false;
      const EditComponent = (itemVisibleOnly || editDisabled) ? ViewIconButton : EditIconButton;
      let editBtn = (
        <EditComponent
          id={`${namePrefix}-editBtn${rowData.id || ''}`}
          onClick={() => this.onSelect(rowData)}
        />
      );
      if (deleteBtnVisible || itemDeactivateable) {
        editBtn = (<div className="w3-cell w3-cell-top">{editBtn}</div>);
      }
      return (<div>{editBtn}{deactivateMarkup}{deleteBtn}</div>);
    };
    const panelTitle = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        {getItemFormTitle(selected)}
      </div>
    );
    // console.log('ItemListManager.render()', className, this.state, this.props, panelTitle);
    const onHide = () => this.setState({isEditing: false});
    const titleMarkup = (typeof title === 'string') ? (<Label className="pad-tb">{title}</Label>) : title;
    const dialog = isEditing && (
      <Dialog
        header={panelTitle}
        visible={isEditing}
        style={itemPanelStyle}
        modal={true}
        onHide={onHide}
      >
        {renderItemForm(selected, onHide)}
      </Dialog>
    );
    const addBtnMarkup = addBtnHidden ? null : (
      <PrimaryButton
        name={addBtnName}
        size="small"
        onClick={() => this.onSelect(null)}
        disabled={addBtnDisabled}
      >
        {addBtnLabel}
      </PrimaryButton>
    );
    const exportBtnMarkup = exportBtnHidden ? null : (
      <PrimaryButton
        name={exportBtnName}
        size="small"
        onClick={() => onExport()}
        disabled={exportBtnDisabled}
      >
        {exportBtnLabel}
      </PrimaryButton>
    );
    return (
      <div className={className}>
        <div className="clearfix">
          <div className="w3-right simple-menu">
            {addBtnMarkup}
            {exportBtnMarkup}
          </div>
          {titleMarkup}
        </div>
        {renderItemTable(items, actionTemplate)}
        {dialog}
        <ConfirmationDialog
          ref={ref => this.confirm = ref}
          showReason={showItemDeleteReason || showItemCloseReason}
        />
      </div>
    );
  }
}
