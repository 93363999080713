
import { parseTaskResults, getTaskTypeByActionID, getTaskTypeByStatusID } from '../components/Utils';
import { loadOpenTasks } from '.';

// export const LOAD_TASKS_NEW = 'tasks/new/load';
// export const LOAD_TASKS_NEW_SUCCESS = 'tasks/new/load/success';
// export const LOAD_TASKS_NEW_FAILURE = 'tasks/new/load/failure';

export const LOAD_TASKS_IT_CONNECTIONS = 'tasks/it-connections/load';
export const LOAD_TASKS_IT_CONNECTIONS_SUCCESS = 'tasks/it-connections/load/success';
export const LOAD_TASKS_IT_CONNECTIONS_FAILURE = 'tasks/it-connections/load/failure';

export const LOAD_TASKS_MODEM_ORDERS = 'tasks/modem-orders/load';
export const LOAD_TASKS_MODEM_ORDERS_SUCCESS = 'tasks/modem-orders/load/success';
export const LOAD_TASKS_MODEM_ORDERS_FAILURE = 'tasks/modem-orders/load/failure';

export const FILTER_CONNECTIONS = 'tasks/connections/filter';

export const SET_SELECTED_AD_CONNECTION = 'task/ad-connection/set';
export const SET_SELECTED_PT_CONNECTION = 'task/pt-connection/set';
export const SET_SELECTED_DEPLPHI_CONNECTION = 'task/delphi-connection/set';

export const EXPORT_ITCONNECTIONS = 'task/it-connections/export';
export const EXPORT_ITCONNECTIONS_SUCCESS = 'task/it-connections/export/success';
export const EXPORT_ITCONNECTIONS_FAILURE = 'task/it-connections/export/failure';

export const IMPORT_ITCONNECTIONS = 'task/it-connections/import';
export const IMPORT_ITCONNECTIONS_SUCCESS = 'task/it-connections/import/success';
export const IMPORT_ITCONNECTIONS_FAILURE = 'task/it-connections/import/failure';

export const EXPORT_PTCONNECTIONS = 'task/pt-connections/export';
export const EXPORT_PTCONNECTIONS_SUCCESS = 'task/pt-connections/export/success';
export const EXPORT_PTCONNECTIONS_FAILURE = 'task/pt-connections/export/failure';

export const IMPORT_PTCONNECTIONS = 'task/pt-connections/import';
export const IMPORT_PTCONNECTIONS_SUCCESS = 'task/pt-connections/import/success';
export const IMPORT_PTCONNECTIONS_FAILURE = 'task/pt-connections/import/failure';

export const EXPORT_ADCONNECTIONS = 'task/ad-connections/export';
export const EXPORT_ADCONNECTIONS_SUCCESS = 'task/ad-connections/export/success';
export const EXPORT_ADCONNECTIONS_FAILURE = 'task/ad-connections/export/failure';

export const IMPORT_ADCONNECTIONS = 'task/ad-connections/import';
export const IMPORT_ADCONNECTIONS_SUCCESS = 'task/ad-connections/import/success';
export const IMPORT_ADCONNECTIONS_FAILURE = 'task/ad-connections/import/failure';

export const EXPORT_DELPHICONNECTIONS = 'task/delphi-connections/export';
export const EXPORT_DELPHICONNECTIONS_SUCCESS = 'task/delphi-connections/export/success';
export const EXPORT_DELPHICONNECTIONS_FAILURE = 'task/delphi-connections/export/failure';

export const DELETE_CONNECTION_TASK = 'tasks/connections/delete';
export const DELETE_CONNECTION_TASK_SUCCESS = 'tasks/connections/delete/success';
export const DELETE_CONNECTION_TASK_FAILURE = 'tasks/connections/delete/failure';

export const CLOSE_CONNECTION_TASK = 'tasks/connections/close';
export const CLOSE_CONNECTION_TASK_SUCCESS = 'tasks/connections/close/success';
export const CLOSE_CONNECTION_TASK_FAILURE = 'tasks/connections/close/failure';


// export const loadOpenTasks = () => {
//   console.log(`REDUX-ACTION: loadOpenTasks() ...`);
//   return (dispatch, getState) => {
//     const isAdmin = getState().auth.isAdmin;
//     if (!isAdmin) {
//       return console.log('<< NOT PERMITTED => aborting !');
//     }
//     const onSuccess = (result) => {
//       console.log('Concluding action cascade:', result)
//       return dispatch({ type: LOAD_TASKS_NEW_SUCCESS, result });
//     };
//     const result = { taskCounter: 0 };
//     return dispatch(loadITConnectionTasks(onSuccess, result));
//   }
// }

export const loadITConnectionTasks = (onSuccess, result) => {
  console.log(`REDUX-ACTION: loadITConnectionTasks() ...`, result);
  return {
    types: [
      LOAD_TASKS_IT_CONNECTIONS,
      LOAD_TASKS_IT_CONNECTIONS_SUCCESS,
      LOAD_TASKS_IT_CONNECTIONS_FAILURE
    ],
    promise: (client) => client.get(`/companies/0/tasks/Neu`),
    onSuccess: (dispatch, getState, results) => {
      console.log('<< IT Connection Tasks:', results);
      if (!onSuccess) return;
      const currentResult = parseTaskResults(results);
      const { taskCounter, ...rest } = currentResult;
      let newResult = result || { taskCounter: 0 };
      newResult.taskCounter += taskCounter;
      newResult = { ...newResult, ...rest };
      return dispatch(loadModemOrderTasks(onSuccess, newResult));
    }
  };
}

export const loadModemOrderTasks = (onSuccess, result) => {
  console.log(`REDUX-ACTION: loadModemOrderTasks() ...`, result);
  return {
    types: [
      LOAD_TASKS_MODEM_ORDERS,
      LOAD_TASKS_MODEM_ORDERS_SUCCESS,
      LOAD_TASKS_MODEM_ORDERS_FAILURE
    ],
    promise: (client) => client.get(`/modems/orders/tasks`),
    onSuccess: (dispatch, getState, results) => {
      console.log('<< Modem Order Tasks:', results);
      if (!onSuccess) return;
      const newResult = result || { taskCounter: 0 };
      newResult.modemOrders = results;
      newResult.taskCounter += results.length;
      return dispatch(onSuccess(newResult));
    }
  };
}

export const filterConnections = (connectionType, taskType) => {
  return { type: FILTER_CONNECTIONS, connectionType, taskType };
};

export const filterADConnections = (taskType) => {
  console.log(`REDUX-ACTION: filterADConnections(${taskType})`);
  return filterConnections('AD', taskType);
};

export const filterPTConnections = (taskType) => {
  console.log(`REDUX-ACTION: filterPTConnections(${taskType})`);
  return filterConnections('PT', taskType);
};

export const filterDelphiConnections = (taskType) => {
  console.log(`REDUX-ACTION: filterDelphiConnections(${taskType})`);
  return filterConnections('Delphi', taskType);
};


export const setSelectedADConnections = (selected) => {
  console.log(`REDUX-ACTION: setSelectedADConnections()`, selected);
  return { type: SET_SELECTED_AD_CONNECTION, selected };
}

export const setSelectedPTConnections = (selected) => {
  console.log(`REDUX-ACTION: setSelectedPTConnections()`, selected);
  return { type: SET_SELECTED_PT_CONNECTION, selected };
}

export const setSelectedDelphiConnections = (selected) => {
  console.log(`REDUX-ACTION: setSelectedDelphiConnections()`, selected);
  return { type: SET_SELECTED_DEPLPHI_CONNECTION, selected };
};

// export const exportITConnections = () => {
//   console.log(`REDUX-ACTION: exportITConnections()`);
//   return (dispatch, getState) => {
//     const { selectedADConnections, selectedPTConnections/* , selectedDelphiConnections  */} = getState().task;
//     const data = [
//       ...selectedADConnections,
//       ...selectedPTConnections,
//       // ...selectedDelphiConnections
//     ];
//     dispatch({
//       types: [ EXPORT_ITCONNECTIONS, EXPORT_ITCONNECTIONS_SUCCESS, EXPORT_ITCONNECTIONS_FAILURE ],
//       promise: client => client.post(`/companies/load/csv/tasks/IT-CONNECTION`, { data })
//     });
//   }
// };


export const exportPTConnections = ({ taskType, valid_until }) => {
  console.log(`REDUX-ACTION: exportPTConnections(${taskType}, ${valid_until})`);
  return (dispatch, getState) => {
    const { selectedPTConnections} = getState().task;
    const data = selectedPTConnections.map(item => (valid_until ? { ...item, valid_until } : { ...item }));
    dispatch({
      types: [ 
        EXPORT_PTCONNECTIONS, 
        EXPORT_PTCONNECTIONS_SUCCESS, 
        EXPORT_PTCONNECTIONS_FAILURE 
      ],
      promise: client => client.post(`/companies/load/csv/tasks/${taskType}`, { data }),
      onSuccess: dispatch => dispatch(loadOpenTasks()),
      connectionType: 'PT'
    });
  }
};

export const exportADConnections = ({ taskType, valid_until }) => {
  console.log(`REDUX-ACTION: exportADConnections(${taskType}, ${valid_until})`);
  return (dispatch, getState) => {
    const { selectedADConnections} = getState().task;
    const data = selectedADConnections.map(item => (valid_until ? { ...item, valid_until } : { ...item }));
    dispatch({
      types: [ 
        EXPORT_ADCONNECTIONS, 
        EXPORT_ADCONNECTIONS_SUCCESS, 
        EXPORT_ADCONNECTIONS_FAILURE 
      ],
      promise: client => client.post(`/companies/load/csv/tasks/${taskType}`, { data }),
      onSuccess: dispatch => dispatch(loadOpenTasks()),
      connectionType: 'AD'
    });
  }
};

export const exportDelphiConnections = ({ taskType, valid_until }) => {
  console.log(`REDUX-ACTION: exportDelphiConnections(${taskType}, ${valid_until})`);
  return (dispatch, getState) => {
    const { selectedDelphiConnections } = getState().task;
    const data = selectedDelphiConnections.map(item => (valid_until ? { ...item, valid_until } : { ...item }));
    dispatch({
      types: [ 
        EXPORT_DELPHICONNECTIONS, 
        EXPORT_DELPHICONNECTIONS_SUCCESS, 
        EXPORT_DELPHICONNECTIONS_FAILURE 
      ],
      promise: client => client.post(`/companies/load/csv/tasks/${taskType}`, { data }),
      onSuccess: dispatch => dispatch(loadOpenTasks()),
      connectionType: 'Delphi'
    });
  }
};

export const closeConnectionTask = ({ connectionType, task }, onSuccess) => {
  const taskType = getTaskTypeByActionID(connectionType, task.action_id)
    || getTaskTypeByStatusID(connectionType, task.status_id);
  console.log(`REDUX-ACTION: closeConnectionTask(${connectionType}, ${taskType})`, task);
  return {
    types: [ 
      CLOSE_CONNECTION_TASK, 
      CLOSE_CONNECTION_TASK_SUCCESS, 
      CLOSE_CONNECTION_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/${taskType}`, { data: [task] }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    connectionType, task
  };
};

export const deleteConnectionTask = ({ connectionType, task }, onSuccess) => {
  const taskType = getTaskTypeByActionID(connectionType, task.action_id)
    || getTaskTypeByStatusID(connectionType, task.status_id);
  console.log(`REDUX-ACTION: deleteConnectionTask(${connectionType}, ${taskType})`, task);
  return {
    types: [ 
      DELETE_CONNECTION_TASK, 
      DELETE_CONNECTION_TASK_SUCCESS, 
      DELETE_CONNECTION_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/reset/${taskType}`, { data: [task] }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    connectionType, task
  };
};

export const importDelphiConnections = () => {
  console.log(`REDUX-ACTION: importDelphiConnections()`);
};