import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { validateEmail } from '../../../../components/Utils';

export default class EmailForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      email: PropTypes.string
    }),
    readOnly: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    model: {
      email: '',
    },
    readOnly: false
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      email: model.email || '',
      error: {
        email: null,
      }
    };
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    // email
    model.email = model.email.trim();
    console.log('>> validating email ...', model.email, validateEmail(model.email));
    if (!validateEmail(model.email)) {
      error.email = 'ungültige E-Mail';
      isValid = false;
    }
    !noValidate && this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    const { className, readOnly } = this.props;
    const { email, error } = this.state;
    // console.log('EmailForm.render()');
    return (
      <div className={`${className} email-form`}>
        {/* <div className="w3-cell-row">
          <div className="w3-cell">
            <div className={`w3-row ${error.email ? ' error-group' : ''}`}>
              <div className="w3-col s3 pad-top">
                <Label htmlFor="vf_email">{`Vodafone E-Mail Adresse`}{!readOnly && (<span>*</span>)}</Label>
              </div>
              <div className="w3-col s6">
                <InputText
                  id="vf_email"
                  className={`w3-block ${readOnly ? 'no-border' : 'w3-border'}`}
                  value={email}
                  type="email"
                  onChange={(event) => this.setState({email: event.target.value})}
                  readOnly={readOnly}
                />
                {error.email && (<div className="pad-sm-top">{error.email}</div>)}
              </div>
            </div>
          </div>
          <div className="w3-cell" style={{width: "160px"}}></div>
        </div> */}
        {/* <div className="w3-row neg-margin-lr">
          <div className={`w3-col s4 pad-lr pad-top form-group-item ${error.email ? ' error-group' : ''}`}>
            <Label htmlFor="email">{`Vodafone E-Mail Adresse`}<span>*</span></Label>
            <InputText
              id="email"
              className="w3-block w3-border"
              value={email}
              type="email"
              onChange={(event) => this.setState({email: event.target.value})}
            />
            {error.email && (<div className="pad-sm-top">{error.email}</div>)}
          </div>
        </div> */}
        <Label htmlFor="email">{`Vodafone E-Mail Adresse`}<span>*</span></Label>
        <InputText
          id="email"
          className={`w3-block w3-border`}
          value={email}
          type="email"
          onChange={(event) => this.setState({email: event.target.value})}
          disabled={readOnly}
        />
        {error.email && (<div className="pad-sm-top">{error.email}</div>)}
      </div>
    );
  }
}
