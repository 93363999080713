import React from 'react';
// import ITConnection from './ITConnectionPanel';
// import BasicData from './BasicDataPanel';

// export const BasicDataPanel = BasicData;
// export const ITConnectionPanel = ITConnection;
export { default as BasicDataPanel } from './BasicDataPanel';
export { default as ITConnectionPanel } from './ITConnectionPanel';
export { default as ContractorCompanyPanel } from './ContractorCompanyPanel';
export { default as CertificationListPanel } from './CertificationListPanel';
export { UserDeviceListPanel, UserTechDeviceListPanel } from '../../../Device';
export { default as UserRatingPanel } from './UserRatingPanel';
export { default as TechKeyListPanel } from './TechKeyListPanel';


export const TeckKeysPanel = ({ company }) => {
  const techKeys = company.techKeys || [];
  let companyKeyMarkup = (<h4>{'Ihnen wurden noch keine technische Schlüssel zugewiesen'}</h4>);
  if (techKeys.length > 0) {
    companyKeyMarkup = techKeys.map((key, index) => (
      <div key={index} className="w3-col s3 pad-lr pad-top">{key}</div>
    ));
    companyKeyMarkup = (<div className="w3-row neg-margin-lr">{companyKeyMarkup}</div>);
  }
  return companyKeyMarkup;
  // return (
  //   <div className="w3-row neg-margin-lr">
  //     <div className="w3-col s3 pad-lr pad-top bold">{company.contractGiver}</div>
  //     <div className="w3-col s9 pad-lr">{companyKeyMarkup}</div>
  //   </div>
  // );
};
