import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const TogglerContext = React.createContext();
const IsOpenContext = React.createContext();

export class W3Dropdown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  }

  static defaultProps = {
    className: null,
    children: null
  }

  constructor () {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle () {
    this.setState((state) => ({
      isOpen: !state.isOpen
    }));
  }

  render () {
    const { children, className } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={classnames(
          className,
          "w3-dropdown-click w3-dropdown-hover w3-round"
        )}
      >
        <TogglerContext.Provider value={this.toggle}>
          <IsOpenContext.Provider value={isOpen}>
            {children}
          </IsOpenContext.Provider>
        </TogglerContext.Provider>
      </div>
    );
  }
}

export const W3DropdownToggler = ({ as: AsComponent, ...props }) => {
  return (
    <TogglerContext.Consumer>
      {
        (toggle) => (
          <AsComponent {...props} onClick={toggle} />
        )
      }
    </TogglerContext.Consumer>
  );
};

W3DropdownToggler.propTypes = {
  as: PropTypes.elementType
};

W3DropdownToggler.defaultProps = {
  as: "button"
};

export const W3DropdownContent = ({ className, ...props }) => {
  return (
    <IsOpenContext.Consumer>
      {
        (isOpen) => (
          <div
            className={classnames(className, "w3-dropdown-content", {
              "w3-show": isOpen
            })}
            {...props}
          />
        )
      }
    </IsOpenContext.Consumer>
  );
};

W3DropdownContent.propTypes = {
  className: PropTypes.string
};

W3DropdownContent.defaultProps = {
  className: null
};
