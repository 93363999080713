import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from '../../../../components/Inputs';

export default class PersonForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    updateable: PropTypes.bool,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {}
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.props.updateable && this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return {
      firstname: model.firstname || '',
      lastname: model.lastname || '',
      telephoneNumber: model.telephoneNumber || '',
      cellNumber: model.cellNumber || '',
      fax: model.fax || '',
      email: model.email || '',
      emailAvis: model.emailAvis || '',
      error: {
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    return model;
  }
  getModel = () => {
    const model = this.validate();
    model.id = 0;
    return model;
  }
  render() {
    const { className, disabled } = this.props;
    const { firstname, lastname, telephoneNumber, cellNumber, email, fax, emailAvis } = this.state;
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="firstname">{`Vorname`}</Label>
            <InputText
              id="firstname"
              className="w3-block w3-border"
              value={firstname}
              onChange={(event) => this.setState({firstname: event.target.value})}
              disabled={disabled}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="lastname">{`Nachname`}</Label>
            <InputText
              id="lastname"
              className="w3-block w3-border"
              value={lastname} 
              onChange={(event) => this.setState({lastname: event.target.value})}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="telephoneNumber">{`Telefon`}</Label>
            <InputText
              id="telephoneNumber"
              className="w3-block w3-border"
              value={telephoneNumber} 
              onChange={(event) => this.setState({telephoneNumber: event.target.value})}
              disabled={disabled}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="cellNumber">{`Mobil`}</Label>
            <InputText
              id="cellNumber"
              className="w3-block w3-border"
              value={cellNumber} 
              onChange={(event) => this.setState({cellNumber: event.target.value})}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="fax">{`Fax`}</Label>
            <InputText id="fax"
              className="w3-block w3-border"
              value={fax} 
              onChange={(event) => this.setState({fax: event.target.value})}
              disabled={disabled}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="email">{`E-Mail`}</Label>
            <InputText
              id="email"
              className="w3-block w3-border"
              value={email} 
              onChange={(event) => this.setState({email: event.target.value})}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="emailAvis">{`E-Mail Avis`}</Label>
            <InputText
              id="emailAvis"
              className="w3-block w3-border"
              value={emailAvis} 
              onChange={(event) => this.setState({emailAvis: event.target.value})}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
/* 
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { InputText, Label } from '../../../../components/Inputs';

export default class PersonForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    workers: PropTypes.array
  }
  static defaultProps = {
    className: '',
    workers: []
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return {
      id: (model || {}).id
    };
  }
  validate = () => {
    const { workers } = this.props;
    if (!workers || workers.length === 0) {
      return null;
    }
    const { id } = this.state;
    const model = workers.find(item => (item.id === id));
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, workers } = this.props
    const { id } = this.state;
    const WorkerOptions = (workers || []).map(worker => ({
      label: `${worker.firstname} ${worker.lastname}`, value: worker.id
    }));
    let markup = null;
    const worker = (workers && []).find(item => (item.id === id));
    if (worker) {
      markup = (
        <div className="">
          <div className="w3-row">
            <div className="w3-col m6 pad-lr pad-top form-group-item">
              <Label htmlFor="worker.telephoneNumber">{`Telefon`}</Label>
              <InputText id="worker.telephoneNumber" className="w3-block w3-border" value={worker.telephoneNumber} readonly/>
            </div>
            <div className="w3-col m6 pad-lr pad-top form-group-item">
              <Label htmlFor="worker.cellNumber">{`Mobil`}</Label>
              <InputText id="worker.cellNumber" className="w3-block w3-border" value={worker.cellNumber} readonly/>
            </div>
          </div>
          <div className="pad-lr pad-top form-group-item">
            <Label htmlFor="worker.email">{`E-Mail`}</Label>
            <InputText id="worker.email" className="w3-block w3-border" value={worker.email} readonly/>
          </div>
        </div>
      );
    }
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="pad-lr pad-top form-group-item">
            <Label htmlFor="WorkerOptions">{`Person`}</Label>
            <Dropdown id= "WorkerOptions" className="w3-block w3-border" value={id} options={WorkerOptions}
              onChange={(event) => this.setState({id: event.value})}/>
          </div>
          {markup}
        </div>
      </div>
    );
  }
}
 */