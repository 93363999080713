import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';
import auth from './auth';
import { sliderReducer as slider } from '../../modules/Slider';
import { companyReducer as company } from '../../modules/Company';
import { workerReducer as worker } from '../../modules/Worker';
import { staticPageReducer as staticPage } from '../../modules/Static';
import { newsReducer as news } from '../../modules/News';
import { deviceReducer as device } from '../../modules/Device';
import { modemReducer as modem } from '../../modules/Modem';
import { taskReducer as task } from '../../modules/Task';
import { searchReducer as search } from '../../modules/Search';
import {
  reportReducer as report,
  salesFormReducer as salesForm,
  salesProductReducer as salesProduct
} from '../../modules/Reporting';
import { trainingReducer as training } from '../../modules/Training';
import { cardReducer as card } from '../../modules/Product';
import { keyReducer as key } from '../../modules/Product';
import { achievementReducer as achievement } from '../../modules/Achievements';
import { debitorMappingReducer as debitorMapping } from '../../modules/DebitorMapping';

export default (router) => combineReducers({
  router,
  reduxAsyncConnect,
  auth,
  slider,
  company,
  worker,
  staticPage,
  news,
  device,
  modem,
  card,
  key,
  task,
  search,
  report,
  salesForm,
  training,
  achievement,
  salesProduct,
  debitorMapping,
});
