import React from 'react';
import PropTypes from 'prop-types';
import ModelCatalogItem from '../ModemCatalogItem';
import { WizardButtonPanel } from '../../../../components/Buttons';
import { notifyError, PageHeight30 } from '../../../../components/Utils';

export default class CartModemCatalogContent extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    loadingDebitor: PropTypes.bool,
    debitorOrder: PropTypes.object,
    modems: PropTypes.array,
    // actions
    getModems: PropTypes.func.isRequired,
    getDebitorDetails: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    saveCart: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    const { modems } = props;
    const model = {};
    modems.forEach(modem => {
      model[modem.name] = null;
    });
    this.state = { model, isVisible: true, error: null };
  }
  validate = () => {
    const { modems } = this.props;
    const { model } = this.state;
    const modelKeys = Object.keys(model);
    // check if all values are set
    let isValid = true;
    if (modelKeys.length !== modems.length) {
      isValid = false;
      error = 'Bitte alle Modems in den Warenkorb hinzufügen';
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  openDebitors = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  }
  openCart = () => {
    console.log('CartModemCatalogContent.openCart()');
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/warenkorb`);
  }
  addAllToCart = () => {
    console.log('CartModemCatalogContent.addAllToCart()');
    const { modems, addToCart } = this.props;
    const items = [];
    let isValid = true;
    for (const index in modems) {
      console.log('')
      if (this[`catalogItem${index}`]) {
        const item = this[`catalogItem${index}`].getModel();
        if (item) {
          items.push(item);
        } else {
          isValid = false;
        }
      }
    }
    // adding list of items
    !isValid && notifyError({
      summary: 'Modembestellung',
      detail: 'Ihre Eingabe ist nicht gültig. Sie müssen Verbrauch und Bestand für ALLE Modems angeben.'
    })
    isValid && addToCart(items);
    return isValid;
  }
  saveCart = () => {
    // const added = this.addAllToCart();
    // added && this.openCart();
    console.log('CartModemCatalogContent.saveCart()');
    const { modems, debitorOrder, saveCart } = this.props;
    const catalogItems = [];
    let isValid = true;
    for (const index in modems) {
      console.log('')
      if (this[`catalogItem${index}`]) {
        const item = this[`catalogItem${index}`].getModel();
        if (item) {
          catalogItems.push(item);
        } else {
          isValid = false;
        }
      }
    }
    // adding list of items
    if (isValid) {
      saveCart({ catalogItems, debitorOrder }, this.openDebitors);
    } else {
      notifyError({
        summary: 'Modembestellung',
        detail: 'Ihre Eingabe ist nicht gültig. Sie müssen Verbrauch und Bestand für ALLE Modems angeben.'
      });
    }
  }
  render() {
    const { className, loadingDebitor, debitorOrder, modems, addToCart, cancelOrder } = this.props;
    let bodyMarkup = (<PageHeight30/>);
    const isLoading = (loadingDebitor || !debitorOrder);
    if (!isLoading) {
      console.log('CartModemCatalogContent.render()', debitorOrder, modems);
      const { error } = this.state;
      // title
      const title = 'Modem-Katalog ' + (debitorOrder.debitor ? `(${debitorOrder.debitor.debitorKey})` : '');
      // map of modems in the shopping cart
      const modemMap = {};
      const debitorOrderModems = debitorOrder.modems || [];
      debitorOrderModems.forEach(modem => {
        modemMap[modem.product_name] = true;
      });
      // modems
      const modemMarkup = modems.map((modem, index) => {
        const bgColor = (index % 2 === 0) ? 'w3-light-grey' : '';
        return (
          <ModelCatalogItem
            key={index}
            id={`catItem${index}`}
            ref={ref => this[`catalogItem${index}`] = ref}
            className={`pad-big-lr pad-tb w3-border-top ${bgColor}`}
            item={modem}
            model={getModemValues(modem, debitorOrderModems)}
            onAdd={addToCart}
            inCart={modemMap[modem.name]}
          />
        );
      });
      bodyMarkup = (
        <div className={`${className} w3-border`}>
          <div className="pad w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{title}</h3>
          </div>
          {error && (<div className="error-text">{error}</div>)}
          {modemMarkup}
        </div>
      );
    }
    return (
      <div className={`modem-catalog`}>
        {bodyMarkup}
        <WizardButtonPanel
          className="margin-big-top w3-border pad"
          onPrevious={this.openDebitors}
          onNext={this.saveCart}
          onCancel={cancelOrder}
          nextLabel={'in Warenkorb speichern'}
          disableIfAbsent
        />
        {/* <div className={`w3-modal w3-${isLoading ? 'show' : 'hide'}`}/> */}
      </div>
    );
  }
}

function getModemValues(modem, modems) {
  // console.log('getModemValues(', modem, ',', modems, ')');
  const found = modems.find(item => {
    const productName = item.product_name || item.productName;
    return productName === modem.name;
  });
  return found || undefined;
}
