import React from 'react';
import PropTypes from 'prop-types';
import { WizardButtonPanel } from '../../../../../components/Buttons';

import { connect } from 'react-redux';
import { saveOrderFiles, saveOrderAVISFile } from '../../../actions/keys';
import { PRODUCT_ORDER_STATUS_LABELS, PageTitle, PRODUCT_ORDER_STATUS_IDS, notifyError } from '../../../../../components/Utils';
import ProductOrderOverview from '../../../components/ProductOrderOverview';

const mapStateToProps = (state) => {
  return {
    keyOrder: state.key.keyOrder
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveFiles: (...args) => dispatch(saveOrderFiles(...args)),
  saveAvis: (...args) => dispatch(saveOrderAVISFile(...args)),
});

class OrderOverviewUpdate extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    keyOrder: PropTypes.object,
    keys: PropTypes.array,
    // actions
    saveFiles: PropTypes.func.isRequired,
    saveAvis: PropTypes.func.isRequired,
    // rest
    isArchive: PropTypes.bool,
    readOnly: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.listeners = [];
  }
  componentDidUpdate() {
    if (this.listeners.length > 0) {
      this.listeners.forEach(listener => {
        listener();
      })
    }
  }
  onSaveFiles = () => {
    const data = this.form.getAdminFiles();
    console.log('>> OrderOverview.onSaveFiles()', data);
    if (!data) {
      return notifyError({
        summary: `Schließmittelbestellung Lieferschein/Übergabeprotokol`,
        detail: `Die Dateien wurden nicht hochgeladen`
      });
    }
    const { keyOrder, saveFiles } = this.props;
    const files = { ...keyOrder.files, ...data };
    saveFiles({ ...keyOrder, files }, this.onCancel);
  }
  onSaveAvis = () => {
    const data = this.form.getAvisFile();
    console.log('>> OrderOverview.onSaveAvis()', data);
    if (!data) {
      return notifyError({
        summary: `Schließmittelbestellung AVIS-Lieferschein `,
        detail: `AVIS-Datei wurde nicht hochgeladen`
      });
    }
    const { keyOrder, saveAvis } = this.props;
    const files = { ...keyOrder.files, ...data };
    saveAvis({ ...keyOrder, files }, this.onCancel);
  }
  componentWillMount() {
    this.listeners = [];
  }
  onCancel = () => {
    const { history, match, onCancel } = this.props;
    onCancel && onCancel({ history, match });
  }
  registerNavListener = (listener) => {
    listener && this.listeners.push(listener);
  }
  renderNav = (className = 'w3-border no-border-top pad-big w3-light-grey') => {
    const { keyOrder, isArchive, isAdmin } = this.props;
    console.log('OrderOverviewUpdate.renderNav()', keyOrder, isArchive);
    if (!keyOrder) {
      return null;
    }
    let isUpdatable = false;
    let onSave = null;
    let saveLabel = 'Speichern';
    if (!isArchive && (keyOrder && keyOrder.status_id === PRODUCT_ORDER_STATUS_IDS.AWAITING_CONFIRMATION)) {
      isUpdatable = true;
      onSave = this.onSaveAvis;
      saveLabel = 'AVIS-Datei Speichern';
    } else if (isAdmin && keyOrder && keyOrder.status_id === PRODUCT_ORDER_STATUS_IDS.SENT) {
      isUpdatable = true;
      onSave = this.onSaveFiles;
      saveLabel = 'Dateien Speichern';
    }
    return (
      <WizardButtonPanel
        className={className}
        onSave={onSave}
        onCancel={this.onCancel}
        saveLabel={saveLabel}
        cancelLabel={isUpdatable ? 'Abbrechen' : `Schließen`}
        disableIfAbsent={false}
      />
    );
  }
  render() {
    const {
      className,
      keyOrder,
      isAdmin,
      isArchive,
    } = this.props;
    console.log('OrderOverviewUpdate.render()', keyOrder);
    if (!keyOrder) {
      return null;
    }
    const title = isArchive ? (
      <PageTitle>
        <div className="w3-right">{this.renderNav('bg-trans')}</div>
        <h3 className="no-margin">
          {/* <div className="w3-right">{keyOrder.status || PRODUCT_ORDER_STATUS_LABELS[keyOrder.status_id]}</div> */}
          {`Bestellungsübersicht: ${keyOrder.orderNumber || ''}`}
        </h3>
      </PageTitle>
    ) : (
      <div className="w3-border no-border-btm pad bg-secondary- w3-light-grey">
        <h3 className="no-margin pad-sm-top pad-lr">{`Bestellungsübersicht`}</h3>
      </div>
    );
    return (
      <div className={`key-catalog`}>
        <div className={`${className}`}>
          {title}
          <ProductOrderOverview
            ref={ref => this.form = ref}
            className=''
            model={keyOrder}
            showAvis
            showAdminFiles
            isAdmin={isAdmin}
            isArchive={isArchive}
            uploadUrl={`/data-api/media/upload?target=fcc-shop`}
          />
        </div>
        {this.renderNav()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverviewUpdate);
