import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'primereact/radiobutton';
import { Label } from '../../../components/Inputs';

export default class FilterChoice extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filterType: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
  }
  setFilter = (value) => {
    const { filterType, setFilter } = this.props;
    if (value !== filterType) {
      setFilter(value);
    }
  }
  render() {
    const { className, filterType } = this.props;
    return (
      <div className={`${className}`}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className="p-field-radiobutton">
              <RadioButton
                value="range"
                name="Range"
                onChange={(event) => this.setFilter(event.value)}
                checked={filterType === 'range'}
              />
              <Label htmlFor="Range" className="w3-large pad">{`Zeitraum`}</Label>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="p-field-radiobutton">
              <RadioButton
                value="delphi"
                name="Delhi"
                onChange={(event) => this.setFilter(event.value)}
                checked={filterType === 'delphi'}
              />
              <Label htmlFor="delphi" className="w3-large pad">{`DELPHI-Auftragsnummer`}</Label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
