import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../components/Buttons';
import ItemListManager from '../../../components/ItemListManager';
import UserTechDeviceForm from './UserTechDeviceForm';
import { CoverText, TableEmptyMessage } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';


export default class UserTechDeviceListPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, model, rows } = this.props;
    const { first } = this.state;
    const renderItemTable = (items) => {
      if (items.length === 0) {
        return (<CoverText>{'Ihnen wurden noch keine Technikergeräte zugewiesen'}</CoverText>);
      }
      const data = items.map(item => {
        const copy = JSON.parse(JSON.stringify(item));
        return { ...copy };
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Geräte"
              itemName="Gerät"
              itemNamePlural="Geräte"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Technikergeräte" min/>)}
        >
          <Column
            field="device"
            header="Gerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="type"
            header="Typ"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const userTechDevice = item;
      return (
        <div>
          <UserTechDeviceForm ref={(ref) => this.form = ref} model={userTechDevice} readonly/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onCancel={onHide}
            saveDisabled
            saveBtnName="saveUserTechDeviceBtn"/>
        </div>
      );
    };
    const getItemFormTitle = (userTechDevice) => {
      return (<h3 className="no-margin">{`Technikergerät ansehen`}</h3>);
    }
    return (
      <ItemListManager
        className={className}
        title={(<h3 className="no-margin-top">VKDG Geräte / Technikergeräte</h3>)}
        addBtnHidden
        items={model}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="userTechDevice"
      />
    );
  }
}
