import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

class LocationChangeListener extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired
    }).isRequired
  }

  constructor (props) {
    super(props);
    this.lastLocation = props.location.pathname;
  }

  componentDidMount () {
    const { history } = this.props;

    this.unlisten = history.listen((location) => {
      if (this.lastLocation !== location.pathname) {
        this.lastLocation = location.pathname;
        this.props.onChange(location);
      }
    })
  }

  render () {
    return null;
  }
}

export default withRouter(LocationChangeListener);
