import React from 'react';
import PropTypes from 'prop-types';
// import { HashRouter, NavLink, Link, useLocation } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import InPageNavMenu from '../../../components/InPageNavMenu';
import {
  isWorkerPlus, isDeviceManager, PageHeight30, WizardTitle,
  notifyError, createSortByName, addCompanyToList, UnderConstruction, scrollToElement, PageHeightAuto
} from '../../../components/Utils';
import { FormMenu } from '../../../components/Buttons';
import { setCompanyModel, getCompanyModel, setCompanyModelDelphi } from '../components/Utils';

import BasicDataForm from '../components/wizard/BasicDataForm';
import ITConnectionForm from '../components/wizard/ITConnectionForm';
import ContractorCompanyPanel from '../components/panels/ContractorCompanyPanel';
import CertificationListForm from '../components/wizard/CertificationListForm';
import UserDeviceListForm from '../../Device/components/UserDeviceListForm';
import UserTechDeviceListForm from '../../Device/components/UserTechDeviceListForm';
import { TechKeyForm } from '../../Company/components';
import TechKeyListForm from '../components/wizard/TechKeyListForm';
import { UserRatingPanel } from '../components/panels';
import UserTrainingPanel from '../components/panels/UserTrainingPanel';
// import PasswordResetMenu from '../components/PasswordResetMenu';
import WorkerBasicDataMenu from '../components/WorkerBasicDataMenu';
import AuditListForm from '../components/wizard/AuditListForm';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  // loadExtendedRoles,
  initWorker,
  loadWorker,
  checkWorker,
  saveWorker,
  cancelWorker,
  loadWorkerCompanies
} from '../actions';
import { loadTechDevices } from '../../Device/actions';
import EmailForm from '../components/wizard/EmailForm';

const frontload = async (props) => {
  await props.getTechDevices();
  // await props.getExtendedRoles();
  const id = props.match.params.id;
  await props.getWorker({ id });
  await props.init(true);
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAdmin: state.auth.isAdmin,
    isInitialized: state.worker.isInitialized,
    worker: state.worker.worker,
    userChecked: state.worker.userChecked,
    userExists: state.worker.userExists,
    roles: state.worker.roles,
    extendedRoles: state.worker.extendedRoles,
    kdls: state.worker.workerKDLs,
    workerKdl: state.worker.workerKDL,
    companies: state.worker.workerCompanies,
    userCompany: state.company.company,
    techDevices: state.device.techDevices,
    userTrainings: state.training.userTrainings,
    techKeyList: state.company.techKeys
    // techKeyList: state.key.catalogKeys,
  };
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(initWorker(...args)),
  getWorkerCompanies: (...args) => dispatch(loadWorkerCompanies(...args)),
  getTechDevices: (...args) => dispatch(loadTechDevices(...args)),
  getWorker: (...args) => dispatch(loadWorker(...args)),
  checkWorker: (...args) => dispatch(checkWorker(...args)),
  saveWorker: (...args) => dispatch(saveWorker(...args)),
  cancelWorker: (...args) => dispatch(cancelWorker(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.shape({}),
    initializing: PropTypes.bool,
    worker: PropTypes.shape({}),
    kdls: PropTypes.array,
    workerKdl: PropTypes.number,
    companies: PropTypes.array,
    userCompany: PropTypes.shape({}),
    userTrainings: PropTypes.array,
    roles: PropTypes.array.isRequired,
    techKeyList:  PropTypes.array,
    extendedRoles: PropTypes.array.isRequired,
    init: PropTypes.func.isRequired,
    getWorkerCompanies: PropTypes.func.isRequired,
    getTechDevices: PropTypes.func.isRequired,
    getWorker: PropTypes.func.isRequired,
    checkWorker: PropTypes.func.isRequired,
    saveWorker: PropTypes.func.isRequired,
    cancelWorker: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    userExists: PropTypes.bool,
    userChecked: PropTypes.bool,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.isInitialized;
  }
  componentWillUnmount() {
    // reset state
    console.log("WorkerWizard.componentWillUnmount() => canceling worker ...")
    this.props.cancelWorker();
  }
  onCheckWorker = (worker) => {
    // console.log('WorkerWizard.onCheckWorker()');
    this.props.checkWorker(worker);
  }
  onSave = () => {
    console.log('WorkerWizard.onSave()');
    const { user, isAdmin, worker } = this.props;
    const workerPlusFlag = isWorkerPlus(user);
    const editingMyself = (workerPlusFlag && worker.isOwn);

    const baseData = this.baseData ? this.baseData.getModel() : null;
    if (!baseData) {
      return notifyError({
        summary: 'Mitarbeiterprofil',
        detail: `Der Mitarbeiter konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    const model = { ...baseData };

    // VF Email
    const vfEmailModel = this.vfEmail ? this.vfEmail.getModel() : null;
    if (vfEmailModel) {
      model.vf_email = vfEmailModel.email;
    }

    // check if company was changed
    let isCompanyChanged = false;
    // for (const index in model.companies) {
    //   isCompanyChanged = (model.companies[index].name !== model.companies[index].oldName);
    //   if (isCompanyChanged) {
    //     console.log(' >> Beschäftigungsfirma changed from', model.companies[index].oldName, 'to', model.companies[index].name);
    //     break;
    //   }
    // }
    const companyChanges = [];
    for (const index in model.companies) {
      const isChanged = (model.companies[index].name !== model.companies[index].oldName);
      if (isChanged) {
        console.log(' >> Beschäftigungsfirma changed from', model.companies[index].oldName, 'to', model.companies[index].name);
        companyChanges.push({
          oldName: model.companies[index].oldName,
          newName: model.companies[index].name
        });
        isCompanyChanged = true;
      }
    }
    if (isAdmin || workerPlusFlag) {
      // correct companies if a MAplus is editing themself bc a MAplus only edits their KDL
      if (editingMyself) {
        console.log('>> Old model.companies:', model.companies);
        worker.companies.forEach(company => {
          // add to model.companies if not found
          const found = model.companies.find(item => (item.name === company.name && item.contractGiver === company.contractGiver));
          if (!found) {
            console.log('KDL', company.contractGiver, `(${company.name}) was not edited => adding old/last unchanged version to model ...`);
            model.companies.push({ ...company });
          }
        });
        console.log('<< New model.companies:', model.companies);
      }
      // IT Connections
      const connectionData = this.itData ? this.itData.getModel() : null;
      if (connectionData) {
        const { delphiConnections, ...itConnectionData } = connectionData;
        console.log('### New Delphi Connections:', delphiConnections, isCompanyChanged, model, companyChanges);
        const unassignedDelphiConnection = setCompanyModelDelphi(model, delphiConnections, isCompanyChanged, companyChanges);
        if (unassignedDelphiConnection) {
          // delphiConnection could not be assigned to company => abort
          const kdlName = unassignedDelphiConnection.byCompanyName;
          const companyName = unassignedDelphiConnection.companyName;
          scrollToElement('it-zugaenge');
          return notifyError({
            summary: 'Mitarbeiterprofil - DELPHI-Zugangsfehler',
            detail: `Der DELPHI-Zugang (KDL: ${kdlName}, Beschäftigungsfirma: ${companyName}) konnte nicht zugewiesen werden.`
          });
        }
        model.itConnectionData = itConnectionData;
        if (!isCompanyChanged && worker.itConnectionData && worker.itConnectionData.id) {
          model.itConnectionData.id = worker.itConnectionData.id;
        }
      }
      // Devices
      const devices = this.userDevices ? this.userDevices.getModel() : null;
      console.log('devices:', devices);
      if (devices) {
        // validate devices in case of incomplete import
        const invalidDevices = [];
        devices.forEach((device, index) => {
          const isNotSet = value => (!value || value === '');
          if (isNotSet(device.lastCheckDate) || isNotSet(device.nextCheckDate)) {
            invalidDevices.push(device);
          }
        });
        if (invalidDevices.length > 0) {
          const deviceNames = invalidDevices.map(device => device.device).join(', ');
          scrollToElement('messgeraete');
          return notifyError({
            summary: 'Mitarbeiterprofil - Messgerätfehler',
            detail: `Die Messgeräte (${deviceNames}) sind wegen fehlenden Prüfdaten ungültig.`
          });
        }
        // assign devices
        const unassignedDevice = setCompanyModel(model, devices, 'devices', 'companyName', isCompanyChanged, companyChanges);
        if (unassignedDevice) {
          // device could not be assigned to company => abort
          const companyName = unassignedDevice.companyName;
          scrollToElement('messgeraete');
          return notifyError({
            summary: 'Mitarbeiterprofil - Messgerätfehler',
            detail: `Das Messgerät (${unassignedDevice.device}, Beschäftigungsfirma: ${companyName}) konnte nicht zugewiesen werden.`
          });
        }
      }
      // Tech Keys
      const techKeys = this.techKeys ? this.techKeys.getModel() : null;
      model.techKeys = techKeys;

      // Tech Devices
      if (isAdmin && this.userTechDevices) {
        const techDevices = this.userTechDevices ? this.userTechDevices.getModel() : null;
        console.log('<< saving tech devices:', techDevices);
        if (techDevices) {
          const unassignedDevice = setCompanyModel(model, techDevices, 'techDevices', 'companyName', isCompanyChanged);
          if (unassignedDevice) {
            // tech device could not be assigned to company => abort
            const companyName = unassignedDevice.companyName;
            scrollToElement('vkdg-geraete');
            return notifyError({
              summary: 'Mitarbeiterprofil - Technikergerätfehler',
              detail: `Das Technikergerät (${unassignedDevice.device}, Beschäftigungsfirma: ${companyName}) konnte nicht zugewiesen werden.`
            });
          }
        }
      }
      // Certifications
      const certificates = this.certificates ? this.certificates.getModel() : null;
      if (certificates) {
        model.certificates = certificates;
      }
    }
    if (worker.id) {
      model.id = worker.id;
    }
    console.log('Saving Model:', model);
    const { history } = this.props;
    const onSuccess = () => history.replace('/organisation/mitarbeiter');
    this.props.saveWorker(model, onSuccess);
  }
  onCancel = () => {
    // console.log('WorkerWizard.onCancel()');
    this.props.cancelWorker();
    this.props.history.replace('/organisation/mitarbeiter');
  }
  getUserDeviceListBaseData = () => {
    const { user, isAdmin, worker } = this.props;
    const baseData = this.baseData.getModel(true);
    console.log("WorkerWizard.getUserDeviceListBaseData()", baseData)
    return {
      // currentWorker: isAdmin ? null : user,
      isAdmin,
      currentWorker: worker,
      currentWorkerCompany: baseData.companies.length > 0 ? baseData.companies[0] : null,
    };
  }
  getUserTechDeviceListBaseData = () => {
    const { techDevices, companies } = this.props;
    return { techDevices, companies };
  }
  renderMenu = (flag, adminFlag) => {
    const links = [
      { isVisible: true, to: 'basisdaten', label: 'Persönliche Daten' },
      { isVisible: flag, to: 'vkdg-ausweis', label: 'VKDG Ausweis' },
      { isVisible: flag, to: 'it-zugaenge', label: 'IT-Zugänge'},
      { isVisible: flag && adminFlag, to: 'auftraggeber-taetigkeit', label: 'Auftraggebertätigkeiten'},
      { isVisible: flag, to: 'bewertungen', label: 'Bewertungen'},
      { isVisible: flag, to: 'messgeraete', label: 'Messgeräte'},
      { isVisible: flag, to: 'schluessel', label: 'Technikerschlüssel'},
      { isVisible: flag, to: 'zertifizierungen', label: 'Zertifizierungen'},
      { isVisible: flag, to: 'vkdg-geraete', label: 'VKDG Geräte'},
      { isVisible: flag, to: 'schulungen', label: 'Schulungen'},
      { isVisible: flag, to: 'auditierung', label: 'Auditierung'},
    ];
    return (
      <InPageNavMenu
        className="under-wizard-title"
        links={links}
      />
    );
  }
  render() {
    const {
      className, user, isAdmin, worker,
      kdls, workerKdl, companies,
      userCompany, roles,
      extendedRoles, userChecked, techKeyList,
      getWorkerCompanies
      // isInitialized
    } = this.props;
    if (!worker) return null;
    console.log('WorkerWizard.render() techKeyList', techKeyList);
  
    // user check status
    const workerPlusFlag = isWorkerPlus(user);
    const userExists = (worker.id !== undefined) || this.props.userExists;
    const editingMyself = (workerPlusFlag && worker.isOwn);

    // main company 
    let mainCompany = (worker.companies.length === 1) ? worker.companies[0] : worker.companies.find(item => (item.role && item.role !== ''));
    if (mainCompany && (mainCompany.name === '' && companies.length === 1)) {
      mainCompany = { ...companies[0] };
    }
    console.log('WorkerWizard.render() props', this.props, '\n workerPlusFlag:', workerPlusFlag);

    const companyList = (mainCompany && (!workerPlusFlag || mainCompany.name === '')) ? companies : [{ ...mainCompany }].sort(createSortByName());
    // const kdlCompanyList = companies/* .filter(item => !isContractor(item)) */.sort(createSortByName());
    const kdlCompanyList = kdls.sort(createSortByName());
    const flag = ((worker.id !== undefined) || userChecked) && (!isAdmin || worker.id);
    const Menu = this.renderMenu(flag, isAdmin);

    // IT Connections
    const itConnectionData = worker.itConnectionData || {};
    let visibleDelphiCompanies = editingMyself ? [ mainCompany ] : null;
    itConnectionData.delphiConnections = getCompanyModel(worker, 'delphiConnections', visibleDelphiCompanies);
    itConnectionData.vfEmail = worker.vf_email;
    // Devices
    const userDevices = getCompanyModel(worker, 'devices');
    // // Tech Devices
    // const userTechDevices = getCompanyModel(worker, 'techDevices');
    return (
      <div className={`worker-wizard clearfix ${className}`}>
        <WizardTitle
          type="worker"
          model={worker}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveDisabled={(!worker.id && userChecked === false)}
              saveBtnName="saveWorkerBtn2"/>
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3} key={Math.random()}>
            <PageHeight30 id="basisdaten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <div className="clearfix">
                {/* <PasswordResetMenu
                  className="w3-right"
                  getUser={() => this.baseData.getModel(true)}
                /> */}
                <WorkerBasicDataMenu
                  className="w3-right"
                  user={worker}
                  isAdmin={isAdmin}
                />
                <h3 className="no-margin-top">{'Persönliche Daten'}</h3>
              </div>
              <BasicDataForm
                ref={ref => this.baseData = ref}
                model={worker}
                editingMyself={editingMyself}
                workerKdl={workerKdl}
                getWorkerCompanies={getWorkerCompanies}
                companyList={companyList}
                kdlCompanyList={kdlCompanyList}
                contractGivers={worker.contractGivers}
                userCompany={userCompany}
                roleList={roles}
                extendedRoleList={extendedRoles}
                checkUser={this.onCheckWorker}
                userChecked={userChecked}
                userExists={userExists}
                isAdmin={isAdmin}
                isWorkerPlus={workerPlusFlag}
              />
            </PageHeight30>
            {flag && (
              <div>
                <PageHeight30 id="vkdg-ausweis" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin-top">{'VKDG Ausweis'}</h3>
                  <UnderConstruction />
                </PageHeight30>
                <PageHeight30 id="it-zugaenge" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin-top">{'IT-Zugänge'}</h3>
                  <ITConnectionForm
                    ref={ref => this.itData = ref}
                    model={itConnectionData}
                    companyList={companyList}
                    kdlCompanyList={kdlCompanyList}
                    userCompany={userCompany}
                    isAdmin={isAdmin}
                    isWorkerPlus={workerPlusFlag}
                  >
                    <EmailForm
                      ref={ref => this.vfEmail = ref}
                      model={{ email: worker.vf_email }}
                      readOnly={!isAdmin}
                    />
                  </ITConnectionForm>
                </PageHeight30>
              </div>
            )}
            {flag && (isAdmin/*  || worker.isOwn */) && (
              <PageHeight30 id="auftraggeber-taetigkeit" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                <h3 className="no-margin-top">{'Tätigkeiten für Auftraggeber'}</h3>
                <ContractorCompanyPanel companies={worker.companies}/>
              </PageHeight30>
            )}
            {flag && (
              <div>
                <PageHeight30 id="bewertungen" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin-top">{'Bewertungen'}</h3>
                  <UserRatingPanel
                    model={worker.averageRating}
                    delphiConnections={itConnectionData.delphiConnections}
                    isEditable
                  />
                </PageHeight30>
                <PageHeight30 id="messgeraete" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <UserDeviceListForm 
                    ref={ref => this.userDevices = ref}
                    model={userDevices}
                    getBaseData={this.getUserDeviceListBaseData}
                    isEditable={isAdmin || isDeviceManager(user)}
                  />
                </PageHeight30>
                <PageHeight30 id="schluessel" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <TechKeyListForm
                    ref={ref => this.techKeys = ref}
                    model={worker.techKeys}
                    company={mainCompany}
                    companyKeys={techKeyList || []/* worker.companies.length > 0 ? worker.companies[0].companyTechKeys : [] */}
                  />
                </PageHeight30>
                <PageHeight30 id="zertifizierungen" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <CertificationListForm
                    ref={ref => this.certificates = ref}
                    model={worker.certificates}
                  />
                </PageHeight30>
                <PageHeight30 id="vkdg-geraete" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin">{'VKDG Geräte / Technikergeräte'}</h3>
                  <UnderConstruction/>
                  {/* <UserTechDeviceListForm 
                    ref={ref => this.userTechDevices = ref}
                    model={userTechDevices}
                    getBaseData={this.getUserTechDeviceListBaseData}
                    isEditable={isAdmin}
                  /> */}
                </PageHeight30>
                <PageHeight30 id="schulungen" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin-top">{'Schulungen'}</h3>
                  <UserTrainingPanel
                    model={worker.trainings}
                    isEditable
                  />
                </PageHeight30>
                <PageHeight30 id="auditierung" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
                  <h3 className="no-margin-top">{'Auditierung'}</h3>
                  <UnderConstruction/>
                  {/* <AuditListForm
                    ref={ref => this.audits = ref}
                    model={worker.audits}
                  /> */}
                </PageHeight30>
              </div>
            )}
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave} onCancel={this.onCancel}
              saveDisabled={(!worker.id && userChecked === false)}
              saveBtnName="saveWorkerBtn"/>
          </Dashboard>
        </div>
        {/* <div className={`w3-modal w3-${(!worker) ? 'show' : 'hide w3-animate-opacity'}`}/> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
