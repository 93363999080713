import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from 'primereact/fileupload';
import {
  getFileTitleFromName,
  getFileTypeLabel,
  formatFileSize,
  getFileExtension,
  showFile
} from './Utils';
import { DeleteIconButton, ViewIconButton } from './Buttons';
import { Label } from './Inputs';

export default class MediaUploadForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    model: PropTypes.object,
    uploadUrl: PropTypes.string,
    multiple: PropTypes.bool,
    accepted: PropTypes.array,
    max: PropTypes.number,
    maxTotalFileSize: PropTypes.number,
    itemRowClassName: PropTypes.string,
    itemColClassName: PropTypes.string,
    uploadBtnClassName: PropTypes.string,
    readOnly: PropTypes.bool,
    btnLabel: PropTypes.string,
    onUpdated: PropTypes.func,
    showFileName: PropTypes.bool,
    titleIsLabel: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    title: null,
    model: {
      files: []
    },
    uploadUrl: '/data-api/media/upload',
    multiple: false,
    accepted: [ '.pdf', '.jpg', '.png'/* , 'image/*' */ ],
    max: 1,
    maxTotalFileSize: null,
    uploadBtnClassName: 'w3-right',
    itemRowClassName: '',
    itemColClassName: 'w3-col s3',
    btnLabel: 'Bild/Datei hochladen'
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  componentDidUpdate() {
    const { maxTotalFileSize, readOnly, onUpdated } = this.props;
    if (!readOnly && maxTotalFileSize != null) {
      onUpdated && onUpdated(this.getTotalFileSize() < maxTotalFileSize);
    }
  }
  getState = (props) => {
    const { model } = props;
    return ({
      files: model.files || []
    });
  }
  getModel = (noValidate) => {
    const { files } = this.state;
    // const getData = index => this[`itemForm${index}`].getModel(noValidate);
    // const media = files.map((file, index) => {
    //   const data = getData(index);
    //   return { ...file, ...data };
    // });
    // return { media };
    return { media: [ ...files ] };
  }
  addFiles = (event) => {
    // console.log('ImageUploadForm.addFile()', event);
    const response = JSON.parse(event.xhr.response);
    // const newFiles = this.props.multiple ? response : [ response ];
    const newFiles = Array.isArray(response) ? response : [ response ];
    const items = newFiles.map((item, index) => {
      return {
        path: '/data-api' + item.filePath,
        title: getFileTitleFromName(item.fileName),
        type: getFileTypeLabel(item.fileName),
        size: formatFileSize(event.files[index].size),
        ext: getFileExtension(item.fileName),
        rawSize: event.files[index].size
      };
    });
    const files = [ ...this.state.files ].concat(items);
    // console.log('New Items:', items, files);
    this.setState({ files });
  }
  removeFile = (currentIndex) => {
    const files = [];
    this.state.files.forEach((item, index) => {
      if (index !== currentIndex) {
        files.push(item);
      }
    });
    this.setState({ files });
  }
  getTotalFileSize = () => {
    let totalSize = 0;
    this.state.files.forEach(file => {
      totalSize += file.rawSize;
    });
    return totalSize;
  }
  canAddFile = () => {
    const { max, maxTotalFileSize, readOnly } = this.props;
    if (readOnly) {
      return false;
    }
    if (maxTotalFileSize != null) {
      return maxTotalFileSize > this.getTotalFileSize(); 
    }
    return (max == null || this.state.files.length <= max);
  }
  render() {
    const {
      className, title, uploadUrl, maxTotalFileSize, titleIsLabel,
      multiple, accepted, readOnly, btnLabel, showFileName,
      uploadBtnClassName, itemRowClassName, itemColClassName
    } = this.props;
    const { files } = this.state;
    const btnMarkup = this.canAddFile() && (
      <div className={uploadBtnClassName}>
        <FileUpload
          name="image"
          mode="basic"
          auto
          url={uploadUrl}
          multiple={multiple}
          accept={accepted.join(',')}
          chooseLabel={btnLabel}
          onUpload={event => this.addFiles(event)}
        />
      </div>
    );
    let errorMarkup = null;
    if (maxTotalFileSize != null && !readOnly && this.getTotalFileSize() >= maxTotalFileSize) {
      errorMarkup = (
        <div className="error-group-text pad-tb">
          {`Gesamtgröße aller Dateien übersteigt das limit von ${formatFileSize(maxTotalFileSize)}`}
        </div>
      );
    }
    const markup = files.map((item, index) => {
      // const { title, ext } = item;
      const space = '10px';
      return (
        <div key={index} className={`${itemColClassName} pad-lr pad-big-top`}>
          <div className="clearfix w3-border w3-light-grey pad-big">
            <div className="display inline-block" style={{position: 'absolute', right: space, top: space}}>
              <div>
                <ViewIconButton
                  className="w3-border pad margin-sm-lr"
                  onClick={() => window.open(item.path, '_blank').focus()}
                />
              </div>
              <div style={{ paddingTop: space}}>
                <DeleteIconButton
                  className="w3-border pad margin-sm-lr"
                  onClick={() => this.removeFile(index)}
                  disabled={readOnly}
                />
              </div>
            </div>
            <MediaItem
              {...item}
              showFileName={showFileName}
              titleIsLabel={titleIsLabel}
            />
          </div>
        </div>
      )
    });
    const TitleHolder = titleIsLabel ? LabelHolder : HeaderHolder;
    return (
      <div className={className}>
        <div className="clearfix">
          {btnMarkup}
          {title && (<TitleHolder className="no-margin">{title}</TitleHolder>)}
        </div>
        {errorMarkup}
        <div className={`w3-row ${itemRowClassName}`}>{markup}</div>
      </div>
    );
  }
}

const LabelHolder = ({ ...props }) => (<Label {...props}/>);
const HeaderHolder = ({ ...props }) => (<h3 {...props}/>)

export const MediaItem = ({ path, ext, className, title, showFileName }) => {
  const extension = (ext || '').toUpperCase();
  let markup = null;
  switch(extension) {
    case 'JPG':
    case 'JPEG':
    case 'PNG':
      markup = (<img src={path} className={`product-media-item ${className}`}/>);
      break;
    default: {
      const titleClassName = showFileName ? "w3-large bold" : "w3-xxlarge";
      markup = (
        <div className={`product-media-item ${titleClassName} ${className}`}>
          {showFileName ? `${title}.${ext}` : extension}
        </div>
      );
      break;
    }
  }
  return (markup);
};

export const MediaPreview = ({ className, path, ext }) => (
  <div className={`${className} clickable clearfix w3-border- w3-light-grey pad-big-`} onClick={() => showFile(path)}>
    <MediaItem path={path} ext={ext}/>
  </div>
);
