import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PageHeight40, PageTitle, createSortByAttr, scrollToTop, TableEmptyMessage } from '../../../../components/Utils';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import ADConnectionTaskForm from '../../components/itConnections/ADConnectionTaskForm';
import ADConnectionTaskMenu from '../../components/itConnections/ADConnectionTaskMenu';

import { connect } from 'react-redux';
import { setSelectedADConnections, exportADConnections, filterADConnections, closeConnectionTask, deleteConnectionTask } from '../../actions';
import { filterTasksByTaskType } from '../../components/Utils';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const CONNECTION_TYPE = 'AD';

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    data: state.task.adConnections,
    selection: state.task.selectedADConnections,
    connectionTaskType: state.task.currentADConnectionType
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedADConnections(...args)),
  exportADConnections: (...args) => dispatch(exportADConnections(...args)),
  filterConnections: (...args) => dispatch(filterADConnections(...args)),
  closeTask: (...args) => dispatch(closeConnectionTask(...args)),
  deleteTask: (...args) => dispatch(deleteConnectionTask(...args)),
});

class ADConnectionTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    selection: PropTypes.array,
    connectionTaskType: PropTypes.string,
    exportADConnections: PropTypes.func.isRequired,
    filterConnections: PropTypes.func.isRequired,
    closeTask: PropTypes.func.isRequired,
    rows: PropTypes.number
  }
  static defaultProps = {
    className: '',
    rows: 10
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    }
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = (onHide) => {
    const task = this.form.getModel();
    if (!task) return;
    console.log(`>> saving ${CONNECTION_TYPE} connection task...`, task);
    this.props.closeTask({ connectionType: CONNECTION_TYPE, task }, onHide);
  }
  onDelete = (task) => {
    console.log(`${CONNECTION_TYPE}ConnectionTaskList.onDelete()`, task);
    this.props.deleteTask({ connectionType: CONNECTION_TYPE, task });
  }
  render() {
    console.log('ADConnectionTaskList.render():', this.props);
    const {
      className, isLoading, rows, data,
      selection, setSelection, exportADConnections,
      filterConnections, connectionTaskType
    } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map(item => ({
        ...item,
        user: item.user.split(',').join(' ')
      })).sort(createSortByAttr('user'));
      return (
        <DataTable
          value={taskItems}
          paginator={true}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {this.setState({first: event.first}); scrollToTop();}}
          selection={selection}
          onSelectionChange={event => setSelection(event.value)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine AD-Zugänge"
              itemName="AD-Zugang"
              itemNamePlural="AD-Zugänge"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="AD-Zugänge"/>)}
        >
          <Column
            selectionMode="multiple"
            style={{width:'3em'}}
          />
          <Column
            field="user"
            header="Mitarbeiter"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="adUsername"
            header="Kennung"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="requester"
            header="Anforderer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="userInCharge"
            header="Bearbeiter"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="status"
            header="Status"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center-', width: '9em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const connection = item;
      return (
        <div>
          <ADConnectionTaskForm
            ref={(ref) => this.form = ref}
            model={connection}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveADConnectionBtn"
          />
        </div>
      );
    };
    const getItemFormTitle = (/* connection */) => {
      let title = 'AD-Zugang bearbeiten';
      // if (connection) {
      // 
      // }
      return (<h3 className="no-margin">{title}</h3>);
    }
    const title = (
      <PageTitle>
        <ADConnectionTaskMenu
          className="w3-right"
          selectedADConnections={selection}
          exportADConnections={exportADConnections}
          filterConnections={filterConnections}
          connectionTaskType={connectionTaskType}
          items={data}
        />
        <h3 className="no-margin">{'AD-Zugänge'}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={data.filter(item => filterTasksByTaskType(item, connectionTaskType))}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`${CONNECTION_TYPE}Connection`}
          itemDeleteable
          deleteTitle={`${CONNECTION_TYPE}-Zugangsaufgabe löschen`}
          getDeleteText={(item) => `Wollen Sie diese Aufgabe unwiderruflich löschen?`}
          onDelete={this.onDelete}
        />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ADConnectionTaskList);
