import React from 'react';
// import PropTypes from 'prop-types';
import { Column } from 'primereact/column';
import LevelDisplay from '../components/LevelDisplay';
import { connectDisplay } from '../components/DisplayUtils';

const DelphiDisplay = (props = {}) => {
  const levelBody = (item) => (<div className="bold">{item.delphi_order_nr}</div>);
  const extraCols = () => (
    <Column
      header={(<div className="alg-left">{'Produkt'}</div>)}
      body={(item) => (<div className="">{item.product_name}</div>)}
    />
  );
  return (
    <LevelDisplay
      {...props}
      levelLabel="Delphi-Nr"
      levelBody={levelBody}
      extraCols={extraCols}
      dataKey="id"
      hideSales
    />
  );
};

// DelphiDisplay.propTypes = {
// };

export default connectDisplay(DelphiDisplay, 'delphi');
