import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { connect } from 'react-redux';
import { loadDependentUserDevices, deleteDevice, deselectDevice } from '../actions';
import { createSortByAttr } from '../../../components/Utils';
import { SecondaryButton, PrimaryButton } from '../../../components/Buttons';

const mapStateToProps = (state) => {
  return {
    isLoading: state.device.loading,
    selectedDevice: state.device.selectedDevice,
    dependentUserDevices: state.device.dependentUserDevices
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDependentUserDevices: (...args) => dispatch(loadDependentUserDevices(...args)),
  deleteDevice: (...args) => dispatch(deleteDevice(...args)),
  cancelSelection: (...args) => dispatch(deselectDevice(...args))
});

class DeviceDeleteDialog extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    selectedDevice: PropTypes.object,
    dependentUserDevices: PropTypes.array,
    getDependentUserDevices: PropTypes.func.isRequired,
    deleteDevice: PropTypes.func.isRequired,
    cancelSelection: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      isVisible: false
    }
  }
  show = (isVisible, data) => {
    const newState = data || {};
    this.setState({
      ...newState,
      isVisible
    });
  }
  onHide = () => {
    this.props.cancelSelection();
  }
  onDelete = (device) => {
    this.props.getDependentUserDevices(device);
  }
  onConfirmDelete = (selectedDevice) => {
    this.props.deleteDevice(selectedDevice)
  }
  render() {
    console.log('DeviceDeleteDialog.render()', this.props);
    const { isLoading, selectedDevice, dependentUserDevices } = this.props;
    if (isLoading || !selectedDevice) {
      return null;
    }
    const isVisible = true; // selectedDevice !== undefined && selectedDevice !== null;

    // header
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{`Referenzmessgerät löschen`}</h3>
      </div>
    );

    // body
    const userDevices = (dependentUserDevices || [])
      .map((item, index) => ({ ...item, index }))
      .sort(createSortByAttr('companyName'));
    const body = (userDevices.length > 0) ? (
      <div>
        <div className="w3-large pad-big-btm">
          {`Das Referenzmessgerät ${selectedDevice.label} kann wegen den folgenden abhängigen Mitarbeitermessgeräte nicht gelöscht werden:`}
        </div>
        <div className="pad-btm">
          <DataTable
            value={userDevices}
            paginator={true}
            rows={5}
          >
            <Column field="companyName" header="Firma" sortable filter/>
            {/* <Column field="device" header="Messgerät" sortable filter/> */}
            <Column field="serialNumber" header="Seriennummer" sortable filter/> 
            <Column field="userPrimary" header="Hauptbenutzer" sortable filter/>
            {/* <Column field="userInCharge" header="Verantwortlich" sortable filter/> */}
          </DataTable>
        </div>
      </div>
    ) : (
      <div className="w3-large">
        {`Wollen Sie das Referenzmessgerät ${selectedDevice.label} wirklich löschen?`}
      </div>
    );

    // footer
    const okBtn = (userDevices.length === 0) && (
      <PrimaryButton
        className="margin-lft"
        onClick={() => this.onConfirmDelete(selectedDevice)}
      >
        {'Ja'}
      </PrimaryButton>
    );
    const cancelBtn = (
      <SecondaryButton
        className="margin-lft"
        onClick={() => this.onHide()}
      >
        {(userDevices.length === 0) ? 'Nein' : 'Abbrechen'}
      </SecondaryButton>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        {okBtn}
        {cancelBtn}
      </div>
    );

    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}>
        {body}
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDeleteDialog);
