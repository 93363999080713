import * as actionTypes from '../actions/employee';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';

export const initialState = {
  employeeTasks: [],
  selectedEmployeeTasks: [],
  failedEmailAddresses: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // setting selected card orders
    case actionTypes.SET_SELECTED_EMPLOYEE_TASKS:
      return {
        ...state,
        selectedEmployeeTasks: [ ...action.selected ]
      };

    // close employee task
    case actionTypes.CLOSE_EMPLOYEE_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_EMPLOYEE_TASK_SUCCESS:
      notifySuccess({
        summary: `Kontaktdatenänderung bestätigen`,
        detail: `Die gewählte Änderung wurde erfolgreich bestätigt.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_EMPLOYEE_TASK_FAILURE:
      notifyError({
        summary: `Kontaktdatenänderung bestätigen`,
        detail: `Die gewählte Änderung konnte nicht bestätigt werden.`
      });
      return {
        ...state,
        loading: false,
        error: (action.error || action.result)
      };

    // export user profile changes
    case actionTypes.EXPORT_EMPLOYEE_TASKS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXPORT_EMPLOYEE_TASKS_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `Kontaḱtdatenänderungen exportieren`,
        detail: `Die Änderungen wurden erfolgreich exportiert.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EXPORT_EMPLOYEE_TASKS_FAILURE:
      notifyError({
        summary: `Kontaḱtdatenänderungen exportieren`,
        detail: `Die Änderungen konnte nicht bestätigt werden.`
      });
      return {
        ...state,
        loading: false,
        error: (action.error || action.result)
      };

    // deactivate user via failed email task
    case actionTypes.DEACTIVATE_USER_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DEACTIVATE_USER_TASK_SUCCESS:
      notifySuccess({
        summary: `Benutzer (AD-Zugang) deaktivieren`,
        detail: `Die Deaktivierung des AD-Zugangs von ${action.task.username} wurde erfolgreich beantragt.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DEACTIVATE_USER_TASK_FAILURE: {
      console.log("Error:", action);
      let msg = ".";
      if (action.error.message && action.error.message === "label.user.has.no.ad.account") {
        msg = ` - ${action.task.username} hat keinen AD-Zugang!`;
      }
      notifyError({
        summary: `Benutzer (AD-Zugang) deaktivieren`,
        detail: `Die Deaktivierung des AD-Zugangs von ${action.task.username} konnte nicht beantragt werden${msg}`
      });
      return {
        ...state,
        loading: false,
        error: (action.error || action.result)
      };
    }
    // close failed email task
    case actionTypes.CLOSE_FAILED_EMAIL_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_FAILED_EMAIL_TASK_SUCCESS:
      notifySuccess({
        summary: `Benutzerverwaltungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe wurde erfolgreich bestätigt.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_FAILED_EMAIL_TASK_FAILURE:
      notifyError({
        summary: `Benutzerverwaltungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe konnte nicht bestätigt werden.`
      });
      return {
        ...state,
        loading: false,
        error: (action.error || action.result)
      };
    
    default:
      return null;
  }
}
