import classnames from "classnames";
import React from 'react';
import PropTypes from 'prop-types';

import "./dashboard.css";

class Dashboard extends React.Component {
  static propTypes = {
    isMenuRight: PropTypes.bool,
    menuSize: PropTypes.number,
    menu: PropTypes.node,
    children: PropTypes.node
  }
  static defaultProps = {
    menuSize: 4
  }
  render() {
    const { isMenuRight, menu, children } = this.props;

    return (
      <div className="neg-margin-lr">
        <div className="w3-row">
          <div
            className={classnames("pad-lr", {
              "w3-col": !isMenuRight,
              "w3-rest": isMenuRight,
              "dashboard-menu": !isMenuRight
            })}
          >
            {isMenuRight ? children : menu} &nbsp;
          </div>
          <div 
            className={classnames("pad-lr", {
              "w3-col": isMenuRight,
              "w3-rest": !isMenuRight,
              "dashboard-menu": isMenuRight
            })}
          >
            {isMenuRight ? menu : children} &nbsp;
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
