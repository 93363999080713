import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Chevron } from '../../../components/VFIcon';

class NavEntry extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    link: PropTypes.node.isRequired,
    subLinkList: PropTypes.node
  }

  static defaultProps = {
    className: null,
    subLinkList: null
  }

  constructor () {
    super();

    this.toggleNav = this.toggleNav.bind(this);

    this.state = {
      isOpen: false
    }
  }

  toggleNav () {
    this.setState((state) => ({
      isOpen: !state.isOpen
    }));
  }

  render () {
    const { className, link, subLinkList } = this.props;
    const { isOpen } = this.state;

    let chevron;
    if (subLinkList) {
      chevron = <Chevron onClick={this.toggleNav} />
    }
    return (
      <div
        className={classnames(className, "nav-entry", {
          "nav-entry-active": isOpen
        })}
      >
        {link}
        {chevron}
        {subLinkList}
      </div>
    );
  }
}

export default NavEntry;
