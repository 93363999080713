import * as actionTypes from '../actions/report';
import { openFile, notifySuccess, notifyError, notifyInfo, toUIDate } from '../../../components/Utils';

export const initialState = {

};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get mytechie interval report
    case actionTypes.LOAD_MYTECHIE_REPORT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_MYTECHIE_REPORT_SUCCESS: {
      if (action.result.filePath) { 
        notifySuccess({
          summary: `MyTechie-Report (CSV) herunterladen`,
          detail: `Der Report wurde erfolgreich als CSV-Datei generiert und kann heruntergeladen werden!`
        });
        openFile(action.result.filePath);
      } else {
        const [ fromDate, toDate ] = action.interval || [];
        notifyInfo({
          summary: `MyTechie-Report (CSV) herunterladen`,
          detail: `Für den Zeitraum (${toUIDate(fromDate, 'YYYY-MM-DD')} - ${toUIDate(toDate, 'YYYY-MM-DD')}) gibt es keine Daten!`
        });
      }
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.LOAD_MYTECHIE_REPORT_FAILURE:
      notifyError({
        summary: `MyTechie-Report (CSV) herunterladen`,
        detail: `Der Report konnte nicht generiert werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      }

    default:
      return null;
  }
};
  