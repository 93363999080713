import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { Label, InputText, Dropdown } from '../../../../components/Inputs';
import AddressForm from './AddressForm';
import { SecondaryButton } from '../../../../components/Buttons';

export default class RegionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isAdmin: PropTypes.bool,
    model: PropTypes.shape({
      name: PropTypes.string,
      storageLocation: PropTypes.string,
      debitorKey: PropTypes.string,
      debitorMax: PropTypes.number,
    }),
    regions: PropTypes.array,
    isCompanySpecialKDL: PropTypes.bool,
    disabled: PropTypes.bool,
    getAddress: PropTypes.func,
    minNrDebitors: PropTypes.number
  }
  static defaultProps = {
    className: '',
    regions: ['1', '2', '3', '4', '7', '9'],
    minNrDebitors: 0,
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    const companyName = model.companyName || '';
    const address = {
      name: companyName,
      address: model.address || {}
    };
    this.state = {
      name: model.name || '',
      storageLocation: model.storageLocation || '',
      debitorKey: model.debitorKey || '',
      debitorMax: (props.isCompanySpecialKDL ? 1 : (model.debitorMax || 10)),
      address,
      error: {
        // debitorKey: null,
        debitorMax: null
      }
    };
  }
  validate = (noValidate) => {
    const { error, address: stateAddress, ...model } = this.state;
    let isValid = true;
    // name
    if (model.name === '') {
      error.name = true;
      isValid = false;
    }
    // // debitorKey
    // if (!this.props.isCompanySpecialKDL && model.debitorKey === '') {
    //   error.debitorKey = true;
    //   isValid = false;
    // }
    // debitorMax
    model.debitorMax = parseInt(model.debitorMax);
    if (isNaN(model.debitorMax)/*  || model.debitorMax < 1 */) {
      error.debitorMax = true;
      isValid = false;
    } else if (model.debitorMax < this.props.minNrDebitors) {
      const isPlural = this.props.minNrDebitors > 1;
      error.debitorMax = `Es gibt schon ${this.props.minNrDebitors} Debitor${isPlural ? 'en' : ''} für diese Region`;
      isValid = false;
    }
    // address
    const addressModel = this.addressForm.getModel();
    if (addressModel) {
      const oldAddress = (this.props.model || {}).address || {};
      const { name, address } = addressModel;
      model.companyName = name;
      model.address = { ...oldAddress.address, ...address }
    }
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }
    const { id, index } = this.props.model;
    return { id, index, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  getData = () => {
    return this.getModel(true);
  }
  copyCompanyAddress = () => {
    const address = this.props.getAddress ? this.props.getAddress() : null;
    console.log('RegionForm.copyCompanyAddress()', address);
    address && this.setState({ address });
  }
  render() {
    const { className, isAdmin, regions, isCompanySpecialKDL, disabled, model } = this.props;
    const { name, storageLocation, debitorKey, debitorMax, address, error } = this.state;
    let RegionOptions = [];
    if (name !== '' && !regions.find(item => item === name)) {
      let newRegions = [ ...regions ]
      newRegions.push(name);
      newRegions.sort();
      RegionOptions = newRegions.map(item => ({ label: item, value: item }));
    } else {
      RegionOptions = [
        { label: 'Bitte wählen', value: '' }
      ].concat(regions.map(item => ({ label: item, value: item })));
    }
    const debitorMaxOptions = [
      { label: '0', value: 0 },
      { label: '1', value: 1 }
    ];
    // const oldModel = (model || {})
    // const companyName = oldModel.companyName || '';
    // const address = {
    //   name: companyName,
    //   address: oldModel.address || {}
    // };
    // // const isAddressSet = (address.idl && address.id !== '');
    return (
      <div className={`${className}`}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr`}>
            <h4 className="no-margin">{`Grunddaten`}</h4>
            <div className={`form-group-item pad-top${error.name ? ' error-group' : ''}`}>
              <Label htmlFor="name">{`Name*`}</Label>
              {disabled ? (
                <InputText id="nameId" className="w3-block w3-border" value={name} disabled/>
              ) : (
                <Dropdown
                  id="nameId"
                  className="w3-block w3-border"
                  value={name}
                  options={RegionOptions}
                  onChange={(event) => this.setState({name: event.value})}
                  showClear
                  style={{height: "40px"}}
                />
              )}
            </div>
            {isCompanySpecialKDL && isAdmin && (
              <div className={`form-group-item pad-top${error.storageLocation ? ' error-group' : ''}`}>
                <Label htmlFor="storageLocation">{`Storage Location`}</Label>
                <InputText
                  id="storageLocation"
                  className="w3-block w3-border"
                  value={storageLocation}
                  disabled={disabled}
                  onChange={(event) => this.setState({storageLocation: event.target.value})}
                />
              </div>
            )}
            {!isCompanySpecialKDL && (
              <div className="w3-row neg-margin-lr">
                {isAdmin && (
                  <div className="w3-col s6 pad-lr">
                    <div className={`form-group-item pad-top${error.storageLocation ? ' error-group' : ''}`}>
                      <Label htmlFor="storageLocation">{`Storage Location`}</Label>
                      <InputText
                        id="storageLocation"
                        className="w3-block w3-border"
                        value={storageLocation}
                        disabled={disabled}
                        onChange={(event) => this.setState({storageLocation: event.target.value})}
                      />
                    </div>
                  </div>
                )}
                <div className={`w3-col s${isAdmin ? "6" : "12"} pad-lr`}>
                  <div className={`form-group-item pad-top${error.debitorKey ? ' error-group' : ''}`}>
                    <Label htmlFor="debitorKey">{`Debitor-Key`}</Label>
                    <InputText
                      id="debitorKey"
                      className="w3-block w3-border"
                      value={debitorKey}
                      disabled={disabled}
                      onChange={(event) => this.setState({debitorKey: event.target.value})}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={`form-group-item pad-top${error.debitorMax ? ' error-group' : ''}`}>
              <Label htmlFor="debitorMax">{`Anzahl an erlaubten Debitoren*`}</Label>
              {
                isCompanySpecialKDL ? (
                  <Dropdown
                    id="debitorMax"
                    className="w3-block w3-border"
                    value={debitorMax}
                    options={debitorMaxOptions}
                    onChange={(event) => this.setState({debitorMax: event.value})}
                    showClear
                  />
                ) : (
                  <InputText
                    id="debitorMax"
                    className="w3-block w3-border"
                    value={debitorMax}
                    type="number"
                    disabled={/* isCompanySpecialKDL ||  */disabled}
                    onChange={(event) => this.setState({debitorMax: event.target.value})}
                  />
                )
              }
              {error.debitorMax && error.debitorMax !== true && (<span className="error-text">{error.debitorMax}</span>)}
            </div>
          </div>
          <div className={`w3-col s6 pad-lr`}>
            <h4 className="no-margin">{`Lieferadresse`}</h4>
            <AddressForm
              ref={ref => this.addressForm = ref}
              model={address}
              isVertical
              resetState
              disabled={disabled}
              namePrefix="region_"
              hideName
            />
            {!disabled && (<div className="pos-rel pad-top form-group-item">
              <SecondaryButton
                style={{position: 'absolute', bottom: 0, left: 0}}
                onClick={this.copyCompanyAddress}>
                  {'Firmenadresse verwenden'}
              </SecondaryButton>
            </div>)}
          </div>
          {/* 
          <div className={`w3-col s6 pad-lr pad-top${error.name ? ' error-group' : ''}`}>
            <Label htmlFor="name">{`Name*`}</Label>
            {disabled ? (
              <InputText id="name" className="w3-block w3-border" value={name} disabled/>
            ) : (
              <Dropdown id="name" className="w3-block w3-border" value={name} options={RegionOptions}
                onChange={(event) => this.setState({name: event.value/* , debitorKey: `R${event.value}-` * /})}/>
            )}
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          {!isCompanySpecialKDL && (
            <div className={`w3-col s6 pad-lr pad-top${error.debitorKey ? ' error-group' : ''}`}>
              <Label htmlFor="debitorKey">{`Debitor-Key*`}</Label>
              <InputText id="debitorKey" className="w3-block w3-border" value={debitorKey} disabled={disabled}
                onChange={(event) => this.setState({debitorKey: event.target.value})}/>
            </div>
          )}
          <div className={`w3-col s6 pad-lr pad-top${error.debitorMax ? ' error-group' : ''}`}>
            <Label htmlFor="debitorMax">{`Anzahl an erlaubten Debitoren*`}</Label>
            <InputText id="debitorMax" className="w3-block w3-border" value={debitorMax} type="number" disabled={isCompanySpecialKDL || disabled}
              onChange={(event) => this.setState({debitorMax: event.target.value})}/>
          </div>
        */}
        </div>
      </div>
    );
  }
}
