import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DataView } from 'primereact/dataview';
import SearchResultItem from '../components/SearchResultItem';

import { connect } from 'react-redux';
import { cancelSearch } from '../actions';
import { scrollToTop } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const mapStateToProps = (state) => ({
  results: state.search.results,
  searchedText: state.search.searchedText,
  labelMap: state.staticPage.labelMap.search,
});
const mapDispatchToProps = (dispatch) => ({
  cancelSearch: (...args) => dispatch(cancelSearch(...args)),
});

class SearchResults extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    rows: PropTypes.number,
    match: PropTypes.object,
    results: PropTypes.array,
    searchedText: PropTypes.string,
    labelMap: PropTypes.shape({}).isRequired,
    cancelSearch: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    rows: 10
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  componentWillUnmount() {
    this.props.cancelSearch();
  }
  renderItem = (item) => {
    return (
      <SearchResultItem
        data={item}
        labelMap={this.props.labelMap}
      />
    );
  }
  render() {
    const { className, rows, results, searchedText } = this.props;
    if (!results) {
      return null;
    }
    let markup = (
      <div className="margin-lg-top alg-center clearfix">
        <h3>Keine Suchergebnisse für</h3>
        <div className="w3-xxlarge">{`"${searchedText}"`}</div>
      </div>
    );
    if (results && results.length > 0) {
      // console.log('<< SearchResults:', results);
      const data = results.map((item, index) => ({ ...item, index}));
      markup = (
        <DataView
          className="margin-lg-top"
          value={data}
          itemTemplate={this.renderItem}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          rows={rows}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
            />
          }
        >
        </DataView>
      );
    }
    return (
      <div className={`search-results ${className}`}>
        <div className="container">
          {markup}
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(SearchResults)
);
