import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PageHeight40, PageTitle, createSortByAttr, toUIDate, PRODUCT_ORDER_STATUS_LABELS, STATUS_IDS, PRODUCT_ORDER_STATUS_IDS } from '../../../../components/Utils';
import ItemListManager from '../../../../components/ItemListManager';
import CardOrderTaskHolder from './CardOrderTaskHolder';
import CardOrderMenu from '../../components/cards/CardOrderMenu';

import { connect } from 'react-redux';
import {
  setSelectedCardOrders,
  lockCardOrders,
  exportCardOrders,
  closeCardOrder,
  deleteCardOrder
} from '../../actions/card';

const mapStateToProps = (state) => {
  return {
    // user: state.auth.user,
    cardOrders: state.task.cardOrders,
    selection: state.task.selectedCardOrders
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedCardOrders(...args)),
  lockOrders: (...args) => dispatch(lockCardOrders(...args)),
  exportOrders: (...args) => dispatch(exportCardOrders(...args)),
  closeOrder: (...args) => dispatch(closeCardOrder(...args)),
  deleteOrder: (...args) => dispatch(deleteCardOrder(...args)),
});

class CardOrderList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    cardOrders: PropTypes.array,
    selection: PropTypes.array,
    lockOrders: PropTypes.func.isRequired,
    exportOrders: PropTypes.func.isRequired,
    closeOrder: PropTypes.func.isRequired,
    deleteOrder: PropTypes.func.isRequired,
    rows: PropTypes.number
  }
  static defaultProps = {
    className: '',
    rows: 10
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    }
  }
  onSave = (cardOrder, onHide) => {
    console.log('CardOrderList.onSave() ...', cardOrder);
    if (!cardOrder) return;
    cardOrder.status_id = PRODUCT_ORDER_STATUS_IDS.FINISHED;
    if (cardOrder.address) {
      cardOrder.address.areaCode = cardOrder.address.areaCode || cardOrder.address.areacode;
      cardOrder.address.houseNumber = cardOrder.address.houseNumber || cardOrder.address.house;
    }
    const { closeOrder } = this.props;
    console.log(`>> saving card order...`, cardOrder);
    closeOrder({ cardOrder }, onHide);
  }
  onDelete = (cardOrder) => {
    const { deleteOrder } = this.props;
    console.log(`CardOrderList.onDelete()`, cardOrder);
    deleteOrder({ cardOrder });
  }
  render() {
    console.log('CardOrderList.render() selectedCardOrders:', this.props.selection);
    const {
      className, rows, cardOrders,
      selection, setSelection, lockOrders, exportOrders
    } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item, index) => ({
        ...item,
        index,
        orderDate: item.order_date ? toUIDate(item.order_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ') : '',
        createdDate: toUIDate(item.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
        status: item.status || PRODUCT_ORDER_STATUS_LABELS[item.status_id]
      })).sort(createSortByAttr('orderNumber', true));
      return (
        <DataTable
          key={Math.random}
          value={taskItems}
          paginator={true}
          rows={rows}
          first={this.state.first}
          onPage={(event) => this.setState({first: event.first})}
          sortable
          filter
          filterMatchMode="contains"
          selection={selection}
          onSelectionChange={event => setSelection(event.value)}
        >
          <Column selectionMode="multiple" style={{width:'3em'}}/>
          <Column field="orderNumber" header="Bestellungsnummer" sortable filter/>
          <Column field="orderDate" header="Bestellungsdatum" sortable filter style={{textAlign:'center', width: '12em'}}/>
          <Column field="companyName" header="Firma" sortable filter style={{textAlign:'center', width: '18em'}}/>
          <Column field="createdDate" header="Abgeben am" sortable filter style={{textAlign:'center', width: '12em'}}/>
          <Column field="status" header="Status" style={{textAlign:'center', width: '10em'}}/>
          <Column body={actionTemplate} style={{textAlign:'center', width: '7.8em'}}/>
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const model = item;
      return (
        <div>
          <CardOrderTaskHolder
            id={model.id}
            readOnly={model.status_id !== STATUS_IDS.PROCESSING}
            onSave={(model) => this.onSave(model, onHide)}
            onCancel={onHide}
          />
          {/* <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveHidden={model.status_id !== STATUS_IDS.PROCESSING}
            saveBtnName="saveDeviceBtn"/> */}
        </div>
      );
    };
    const getItemFormTitle = (model) => {
      let title = `Karten/Flyer-Bestellung`;
      if (model) {
        const readOnly = (model.status_id !== STATUS_IDS.PROCESSING);
        title += ` ${readOnly ? 'ansehen' : 'bearbeiten'}`;
      }
      return (<h3 className="no-margin">{title}</h3>);
    }
    const title = (
      <PageTitle>
        <CardOrderMenu
          className="w3-right"
          selectedCardOrders={selection}
          lockOrders={lockOrders}
          exportOrders={exportOrders}
          items={cardOrders}
        />
        <h3 className="no-margin pad-btm">{'Karten/Flyer-Bestellungen'}</h3>
      </PageTitle>
    );
    const isEditDisabled = (rowData) => (
      rowData.status_id !== STATUS_IDS.PROCESSING
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          itemPanelStyle={{ width: '50vw', minWidth: '1100px' }}
          title={title}
          addBtnHidden
          items={cardOrders}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`cardOrder`}
          isEditDisabled={isEditDisabled}
          itemDeleteable={false}
          deleteTitle={`Karten/Flyer-Bestellung löschen`}
          getDeleteText={(item) => `Wollen Sie die Bestellung ${item.orderNumber} unwiderruflich löschen?`}
          onDelete={this.onDelete}
        />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardOrderList);
