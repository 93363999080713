
import * as actionTypes from '../actions';
import { LOAD_CURRENT_USER_SESSION_SUCCESS, LOGOUT_SUCCESS } from '../../../redux/actions/auth';
import { parseTaskResults, CONNECTION_TASK_TYPES, TASK_TYPES } from '../components/Utils';

import {
  initialState as debitorState,
  reducer as debitorReducer }
from './debitor';

import {
  initialState as connectionState,
  reducer as connectionReducer }
from './connection';

import {
  initialState as deviceState,
  reducer as deviceReducer }
from './device';

import {
  initialState as cardOrderState,
  reducer as cardOrderReducer }
from './card';

import {
  initialState as keyOrderState,
  reducer as keyOrderReducer }
from './key';

import {
  initialState as reportState,
  reducer as reportReducer }
from './report';

import {
  initialState as employeeState,
  reducer as employeeReducer }
from './employee';

const initialState = {
  loaded: false,
  taskCounter: 0,
  ...connectionState,
  ...debitorState,
  ...deviceState,
  ...cardOrderState,
  ...keyOrderState,
  ...reportState,
  modemOrders: [],
  ...employeeState,
};

export default (state = initialState, action) => {
  let newState = null;

  // LOAD_TASKS
  switch (action.type) {
    case actionTypes.LOAD_TASKS:
      return {
        ...state,
        loading: true
      };
    case LOAD_CURRENT_USER_SESSION_SUCCESS:
    case actionTypes.LOAD_TASKS_SUCCESS: {
      const allTasks = parseTaskResults(action.result);
      console.log('<< REDUX REDUCER LOAD_CURRENT_USER_SESSION_SUCCESS/LOAD_TASKS_SUCCESS:\n >> ', action.result, '\n >> ', allTasks);
      const debitorTaskType = (state.debitorTaskType && state.debitorTaskType !== '') ? state.debitorTaskType : `${TASK_TYPES.CREATE}-DEBITOR`;
      const deviceTaskType = (state.deviceTaskType && state.deviceTaskType !== '') ? state.deviceTaskType : `${TASK_TYPES.CREATE}-DEVICE`;
      return {
        ...state,
        loading: false,
        ...allTasks,
        debitorTaskType,
        currentADConnectionType:
          state.currentADConnectionType !== '' ? state.currentADConnectionType : `${CONNECTION_TASK_TYPES.CREATE}-AD-CONNECTION`,
        currentPTConnectionType:
          state.currentPTConnectionType !== '' ? state.currentPTConnectionType : `${CONNECTION_TASK_TYPES.CREATE}-PT-CONNECTION`,
        currentDelphiConnectionType:
          state.currentDelphiConnectionType !== '' ? state.currentDelphiConnectionType : `${CONNECTION_TASK_TYPES.CREATE}-DELPHI-CONNECTION`,
        deviceTaskType,
      };
    }
    case actionTypes.LOAD_TASKS_FAILURE:
      return {
        ...state,
        loading: false
      };
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      break;
  }

  // debitor
  newState = debitorReducer(state, action);
  if (newState) return newState;

  // connection
  newState = connectionReducer(state, action);
  if (newState) return newState;

  // device
  newState = deviceReducer(state, action);
  if (newState) return newState;

  // card orders
  newState = cardOrderReducer(state, action);
  if (newState) return newState;

  // key orders
  newState = keyOrderReducer(state, action);
  if (newState) return newState;

  // reports
  newState = reportReducer(state, action);
  if (newState) return newState;

  // employee tasks
  newState = employeeReducer(state, action);
  if (newState) return newState;
  
  return { ...state };
}
