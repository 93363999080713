import React from 'react';

export default function ({}) {
  const size = { width: "28px", height: "28px" };
  return (
    <div className="margin-big-top pad-big w3-light-grey clearfix">
      <div className="w3-cell w3-cell-middle pad-lg-rht">
        <span className="w3-large bold">Legende:</span>
      </div>
      <div className="w3-cell w3-cell-middle pad-big-lr">
        <span
          className="display-inline-block"
          style={{ background: "black", ...size }}
        ></span>
      </div>
      <div className="w3-cell w3-cell-middle">
        <span className="display-inline-block margin-big-rht w3-large">
          {"aktuell"}
        </span>
      </div>
      <div className="w3-cell w3-cell-middle pad-big-lr">
        <span
          className="display-inline-block margin-big-lft"
          style={{ background: "#959595", ...size }}
        ></span>
      </div>
      <div className="w3-cell w3-cell-middle">
        <span className="display-inline-block w3-large">{"alt"}</span>
      </div>
    </div>
  );
}
