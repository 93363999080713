import React from 'react';
// import PropTypes from 'prop-types';
import EditImage from '../../../components/media/edit.svg';
// import { PrimaryButtonLink, PrimaryButton } from '../../../components/Buttons';
import TechKeyFormModule from './wizard/TechKeyForm';
export const TechKeyForm = TechKeyFormModule;

export const EditIcon = () => (<img src={EditImage} alt="edit" />);


// const mapStateToProps = null;
// const mapDispatchToProps = (dispatch) => ({
//   getCompaniesAsCSV: (...args) => dispatch(exportCompanies(...args)),
// });
// class CompanyListMenuClass extends React.Component {
//   static propTypes = {
//     className: PropTypes.string,
//     getCompaniesAsCSV: PropTypes.func.isRequired
//   }
//   static defaultProps = {
//     className: ''
//   }
//   onExportCompanies = (event) => {
//     if (event) event.preventDefault();
//     this.props.getCompaniesAsCSV();
//   }
//   render() {
//     const { className } = this.props;
//     return (
//       <div className={`company list-menu ${className}`}>
//         <PrimaryButtonLink to={`/wizard/organisation/firma/neu`} name="addCompanyBtn">
//           {`Service Partner hinzufügen`}
//         </PrimaryButtonLink>
//         <PrimaryButton className="margin-lft" name="exportCompanyBtn" onClick={this.onExportCompanies}>
//           {'CSV Exportieren'}
//         </PrimaryButton>
//         {/* <div className={`modems dropdown-list-menu w3-dropdown-hover w3-round`}>
//           <PrimaryButton>{`Optionen`}</PrimaryButton>
//           <div className="w3-dropdown-content w3-bar-block w3-border" style={{right: '0', width: '220px'}}>
//             <ButtonLink
//               className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
//               to="/wizard/aufgabe/bestellung/modems"
//               disabled={!isApprovable}>
//                 {`Bestellungen freigeben`}
//             </ButtonLink>
//             <a href="#" className="w3-bar-item separator"><div className="w3-border-top" style={{margin: '0 -4px'}}/></a>
//             <ButtonLink to="#"
//               className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
//               onClick={this.onDownloadCSV}
//               disabled={!isCSVDownloadable}>
//                 {`CSV herunterladen`}
//             </ButtonLink>
//           </div>
//         </div>
//         <ConfirmationDialog ref={ref => this.dialog = ref}/> */}
//       </div>
//     );
//   }
// }
// export const CompanyListMenu = connect(mapStateToProps, mapDispatchToProps)(CompanyListMenuClass);
