import React from 'react';
import PropTypes from 'prop-types';
import { Label, MultiSelect } from '../../../components/Inputs';
import { PrimaryButton } from '../../../components/Buttons';
import { toDBDateTime } from '../../../components/Utils';

export default class ProductGroupFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    productGroupOptions: PropTypes.array,
    username: PropTypes.string,
    interval: PropTypes.shape({}),
    onSave: PropTypes.func,
    onCancel: PropTypes.func
  }
  static defaultProps = {
    className: '',
    model: {
      productGroups: [],
    },
    productGroupOptions: [],
    interval: {
      fromDate: null,
      toDate: null
    }
  }
  constructor(props) {
    super();
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    if (props.username !== this.props.username) {
      this.setState({ productGroups: [] });
    }
  }
  componentWillUnmount() {
    this.props.onCancel && this.props.onCancel();
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      productGroups: model.productGroups || [],
      error: {
        productGroups: null
      }
    }
  }
  validate = (state, noRefresh) => {
    const { error, productGroups } = (state || this.state);
    // check if all values are set
    let isValid = true;
    if (productGroups === '') {
      error.productGroups = true;
      isValid = false;
    } else {
      error.productGroups = null;
    }

    !noRefresh && this.setState({ error });
    if (!isValid) {
      return null;
    }
    const original = this.props.model || {};
    return { ...original, productGroups };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  setProductGroups = (productGroups) => {
    if (productGroups === '' || productGroups === null) {
      return this.onCancel();
    }
    this.setState({ productGroups });
  }
  onSave = () => {
    const { interval: { fromDate, toDate }, onSave } = this.props;
    if (onSave) {
      const filterConfig = {
        productGroups: this.state.productGroups,
        fromDate: fromDate ? toDBDateTime(fromDate, null) : null,
        toDate: toDate ? toDBDateTime(toDate, null) : null
      };
      onSave({ filterConfig });
    }
  }
  onCancel = () => {
    this.setState(this.getState(this.props));
    this.props.onCancel && this.props.onCancel();
  }
  render() {
    const { className, productGroupOptions, interval } = this.props;
    const { productGroups, error } = this.state;
    const Options = (productGroupOptions || []).map(item => ({ label: item.name || item, value: item.name || item }));
    console.log('ProductGroupFilter.render()', productGroups, Options, interval);
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr form-group-item pad-top ${error.productGroups && 'error-group'}`}>
            <Label htmlFor="productGroups">{`Produktgruppen`}</Label>
            <MultiSelect
              id="productGroups"
              value={productGroups}
              placeholder="Bitte auswählen"
              options={Options}
              onChange={(event) => this.setProductGroups(event.value)}
              maxSelectedLabels={7}
              selectedItemsLabel={`{0} ausgewählte Produktgruppen`}
            />
          </div>
          <div className={`w3-col s6 pad-lr`}>
            <div className="form-group-item pos-rel">
              <div style={{position: 'absolute', bottom: '2px', left: '0px', width: '100%'}}>
                <PrimaryButton
                  className="margin-lft-"
                  onClick={this.onSave}
                  disabled={!interval.fromDate || !interval.toDate}
                >
                  {'Filter anwenden'}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
