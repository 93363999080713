import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { saveDebitorOrderInShoppingCart } from '../../actions';
import ModemShoppingCartContent from '../../components/cart/ModemShoppingCartContent';

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    debitorOrder: state.modem.shoppingCart
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveDebitorOrder: (...args) => dispatch(saveDebitorOrderInShoppingCart(...args))
}); 

class ModemShoppingCart extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    debitorOrder: PropTypes.object,
    // actions
    saveDebitorOrder: PropTypes.func.isRequired,
    // passed by parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    rows: 12
  }
  render() {
    const { debitorOrder } = this.props;
    if (!debitorOrder) {
      return null;
    }
    return (<ModemShoppingCartContent {...this.props}/>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemShoppingCart);
