import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { DEVICE_ACTION_IDS } from '../../../../components/Utils';

export default class DeviceTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: {
      id: '',
      device: '',
      serialNumber: '',
      userInChargeCompanyName: '',
      companyName: '',
      userInCharge: '',
      userPrimary: '',
      userSecondary: '',

      macAddress: '',
      account: '',
    }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    const error = {};
    if (this.isAccountRequired(model)) {
      error.account = null;
    }
    this.state = {
      account: model.account || '',
      error
    };
  }
  isAccountRequired = (model) => {
    return (model.action_id !== DEVICE_ACTION_IDS.DELETE_ACCOUNT);
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    let isChanged = false;
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (model[key] !== originalModel[key]) {
        isChanged = true;
      }
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    // console.log('DeviceTaskForm.validate()', isValid, isChanged, error);
    if (!isValid/*  && !isChanged */) {
      return null;
    }
    return { ...originalModel, ...model };
  }
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  }
  render() {
    const { className, model } = this.props;
    const { account, error } = this.state;
    console.log('DeviceTaskForm.render()', this.state);
    const isAccRequired = this.isAccountRequired(model);
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Device`}</Label>
            <InputText className="w3-block w3-border" value={model.device} disabled/>
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Seriennummer`}</Label>
            <InputText className="w3-block w3-border" value={model.serialNumber} disabled/>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Verantwortlich`}</Label>
            <InputText className="w3-block w3-border" value={model.userInCharge} disabled/>
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Bearbeiter`}</Label>
            <InputText className="w3-block w3-border" value={model.userInChargeAdmin || ''} disabled/>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Hauptbenutzer`}</Label>
            <InputText className="w3-block w3-border" value={model.userPrimary} disabled/>
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Zweiter Benutzer (Vertreter)`}</Label>
            <InputText className="w3-block w3-border" value={model.userSecondary} disabled/>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`MAC-Adresse`}</Label>
            <InputText className="w3-block w3-border" value={model.macAddress} disabled/>
          </div>
          <div className={`w3-col s6 pad-lr`}>
            <div className={`pad-top form-group-item`}>
              <Label>{`Regionen`}</Label>
              <InputText className="w3-block w3-border" value={(model.regions || []).join(', ')} disabled/>
            </div>
            <div className={`pad-top form-group-item ${error.account ? ' error-group' : ''}`}>
              <Label htmlFor="account">{`Account${isAccRequired ? '*' : ''}`}</Label>
              <InputText
                id="useraccountaccountname"
                className="w3-block w3-border"
                value={account}
                onChange={(event) => this.setState({
                  account: event.target.value,
                  error: {...error, account: account === ''}
                })}
                disabled={!isAccRequired}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
