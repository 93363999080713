import * as actionTypes from './actions';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  home: [],
  user: [],
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_IMAGES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_IMAGES_SUCCESS:
      console.log('REDUX REDUCER: images', action);
      return {
        ...state,
        loading: false,
        [action.sliderType]: action.result
      };
    case actionTypes.LOAD_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        [action.sliderType]: [],
        error: action.result
      };
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
