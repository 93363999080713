import React from 'react';
import PropTypes from 'prop-types';
import TimeRangeFilter from '../components/TimeRangeFilter';
import DelphiNrFilter from '../components/DelphiNrFilter';
import TechFilter from '../components/TechFilter';
import FilterChoice from '../components/FilterChoice';
import { PrimaryButton } from '../../../components/Buttons';
import { createSortByName, isKDL } from '../../../components/Utils';

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { getFormData, setFilterType, showDisplay, loadWorkers, setTimeRange } from "../actions/success";
// import { loadSalesWorkers } from '../../Reporting/actions/reporting';

const frontload = async (props) => {
	await props.init({ companies: true, admin: true });
};
const mapStateToProps = (state) => {
	return {
    filterType: state.achievement.filterType,
    delphiNrOptions: state.achievement.delphiNrOptions,
		companies: state.company.companies,
    partners: state.report.salesPartners,
    workers: state.report.tipGivers,
    username: state.achievement.username
	};
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(getFormData(...args)),
	setFilter: (...args) => dispatch(setFilterType(...args)),
	showSales: (...args) => dispatch(showDisplay(...args)),
	getWorkers: (...args) => dispatch(loadWorkers(...args)),
  intervalChanged: (...args) => dispatch(setTimeRange(...args)),
});

class TechFilterPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filterType: PropTypes.string.isRequired,
    delphiNrOptions: PropTypes.array,
    companies: PropTypes.array,
    partners: PropTypes.array,
    workers: PropTypes.array,
    init: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    showSales: PropTypes.func.isRequired,
    getWorkers: PropTypes.func.isRequired,
    intervalChanged: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  constructor() {
    super();
    this.state = {
      isUserSet: false,
      isFormValid: false
    };
  }
  onFilter = () => {
    const worker = this.worker.getModel();
    const model = this.filter ? this.filter.getModel() : null;
    if (!model) {
      return;
    }
    console.log('>> showing sales ...', model, worker);
    this.props.showSales(model, worker);
  }
  toggle = (key, value) => {
    // console.log('FilterPanel.toggleFormState()', isFormValid);
    this.setState({ [key]: value });
  }
  render() {
    const {
      className, filterType, setFilter, delphiNrOptions, /* companies,  */
      partners, workers, init, getWorkers, username, intervalChanged
    } = this.props;
		// const kdlCompanies = companies
		// 	.filter((item) => isKDL(item))
		// 	.sort(createSortByName());
    const Filter = (filterType === 'range') ? TimeRangeFilter : DelphiNrFilter;
    return (
      <div className={`${className}`}>
        <TechFilter
          ref={ref => this.worker = ref}
          className="pad-big"
          // companies={kdlCompanies}
          companies={partners}
          workers={workers}
          getWorkers={getWorkers}
          getFormData={(data = {}) => init({ admin: true, ...data })}
          toggleFormState={value => this.toggle('isUserSet', value)}
        />
        <div className="pos-rel">
          <div className={`w3-modal-local w3-${username ? 'hide' : 'show'}`}/>
          <FilterChoice
            className="pad-big w3-border-top"
            filterType={filterType}
            setFilter={setFilter}
          />
          <div className="pad-big w3-border-top">
            <Filter
              ref={ref => this.filter = ref}
              delphiNrOptions={delphiNrOptions}
              toggleFormState={value => this.toggle('isFormValid', value)}
              intervalChanged={intervalChanged}
            />
          </div>
          <div className="pad-big  w3-border-top w3-light-grey alg-right">
            <PrimaryButton
              onClick={this.onFilter}
              disabled={!this.state.isFormValid || !this.state.isUserSet}
            >
              {`Filter anwenden`}
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(TechFilterPanel)
);