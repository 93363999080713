import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditIconNavButton, DeactivateIconButton, ActivateIconButton } from '../../../components/Buttons';
import { scrollToTop, TableEmptyMessage, USER_STATUS_IDS, USER_STATUS_LABELS } from '../../../components/Utils';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadFilteredWorkers,
  activateWorker,
  deactivateWorker,
  defaultFilter
} from '../actions';
import SalesTableUtil from '../../Reporting/components/SalesTableUtil';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';


const getFilterValues = (props, current) => {
  let values =  { ...defaultFilter };
  if (current) {
    values = {
      ...values,
      rows: (props.rows || defaultFilter.rows),
      ...current
    };
  }
  return values;
};


// const getMultiSortMeta = (sortFields = {}) => {
//   return Object.keys(sortFields)
//     .filter(field => sortFields[field])
//     .map(field => ({ field, order: sortFields[field] }));
// };

// const getNextSortOrder = (order) => {
//   let nextOrder = 0;
//   if (order !== undefined) {
//     if (`${order}` === `1`) {
//       nextOrder = -1;
//     } else if (`${order}` === `-1`) {
//       nextOrder = 0;
//     } else if (order === 0 || order === null) {
//       nextOrder = 1;
//     }
//   }
//   return nextOrder;
// };


const frontload = async (props) => {
  const filterValues = getFilterValues(props);
  await props.getWorkers(null, filterValues);
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.worker.loading,
    header: state.worker.header,
    workers: state.worker.filteredWorkers,
    roles: state.worker.roles,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWorkers: (...args) => dispatch(loadFilteredWorkers(...args)),
  activateWorker: (...args) => dispatch(activateWorker(...args)),
  deactivateWorker: (...args) => dispatch(deactivateWorker(...args)),
});

class WorkerTable extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    workers: PropTypes.array,
    roles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      label: PropTypes.string,
    })),
    activateWorker: PropTypes.func.isRequired,
    deactivateWorker: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: '',
    roles: []
  }
  constructor(props) {
    super();
    this.filterValues = getFilterValues(props);
    this.filterTimerId = null;
    this.sortSelection = [];
    this.state = {
      first: 0,
      filteredCount: (props.workers || []).length
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onEditWorker = (worker, history) => {
    console.log('Editing', worker);
    history.push(`/wizard/organisation/mitarbeiter/${worker.id}`);
  }
  onDeactivateWorker = (worker) => {
    console.log('WorkerTable.deactivateWorker()', worker);
    this.dialog.show(
      true,
      {
        title: 'Mitarbeiter deaktivieren',
        text: `Wollen Sie den Mitarbeiter ${worker.firstname} ${worker.lastname} wirklich deaktivieren?`
      },
      () => this.props.deactivateWorker(worker)
    );
  }
  onActivateWorker = (worker) => {
    console.log('WorkerTable.activateWorker()', worker);
    this.dialog.show(
      true,
      {
        title: 'Mitarbeiter aktivieren',
        text: `Wollen Sie den Mitarbeiter ${worker.firstname} ${worker.lastname} wirklich aktivieren?`
      },
      () => this.props.activateWorker(worker)
    );
  }
  actionTemplate = (rowData/* , column */) => {
    const { isAdmin } = this.props;
    const editId = `editWorkerBtn${rowData.id}`;
    const editBtn = (
      <EditIconNavButton
        id={editId}
        name={editId}
        onClick={(history) => this.onEditWorker(rowData, history)}
      />
    );
    const actId = `actWorkerBtn${rowData.id}`;
    const activateBtn = isAdmin && rowData.status_id === USER_STATUS_IDS.DEACTIVATED && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivateWorker(rowData)}
      />
    );
    const deactId = `deactWorkerBtn${rowData.id}`;
    const deactivateBtn = isAdmin && rowData.status_id === USER_STATUS_IDS.APPROVED && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivateWorker(rowData)}
      />
    );
    return (
      <div>{editBtn}{activateBtn}{deactivateBtn}</div>
    );
  }
  onLazyLoad = (event) => {
    // console.log("WorkerTable.onLazyLoad()", event);
    const filterValues = { ...this.filterValues };
    filterValues.first = event.first;
    filterValues.rows = event.rows;
    this.props.getWorkers(
      () => this.setState({ first: event.first }),
      filterValues
    );
  }
  onValueChange = (nextValue) => {
    // console.log('WorkerTable.onValueChange()', nextValue);
    if (nextValue) {
      this.setState({
        first: 0,
        filteredCount: nextValue.length
      });
    }
  }
  onFilterChange = (field, value, noTimeout, noFilter) => {
    console.log('WorkerTable.onFilterChange()', field, value, noTimeout);
    this.filterValues[field] = value;
    (!noFilter) && this.setState({first: 0});
    if (noTimeout) {
      return (!noFilter) && this.props.getWorkers(null, this.filterValues);
    }
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const self = this;
    this.filterTimerId = setTimeout(() => {
      // console.log('WorkerTable.onFilterChange(): filterValues', self.filterValues);
      (!noFilter) && self.props.getWorkers(null, self.filterValues);
    }, 1000);
  }
  onSort = (event) => {
    // console.log('WorkerTable.onSort()', this.filterValues, event);
    this.filterValues.sortFields = event.multiSortMeta.map(item => ({...item}));
    // console.log('WorkerTable.onSort() new:', this.filterValues);
    this.props.getWorkers(null, { ...this.filterValues });
  }
  render() {
    const { className, isLoading, isAdmin, workers, rows, header, roles } = this.props;
    if (!workers) return null;

    const totalRecords = Number(header ? header["x-result-count"] : workers.length);
    const { first, filteredCount } = this.state;

    // console.log('WorkerTable.render()', workers, isLoading, totalRecords);
    const data = (workers || []).map(worker => {
      let role = '', companyName = '';
      if (worker.companies && worker.companies.length > 0) {
        if (worker.companies.length === 1) {
          companyName = worker.companies[0].name;
          if (worker.companies[0].roles && worker.companies[0].roles.length > 0) {
            worker.companies[0].roles.sort((a, b) => a.role_id - b.role_id);
            role = worker.companies[0].roles[0].role_label;
          }
          if (!role || role === '') {
            role = worker.companies[0].role_label || 'Mitarbeiter'; // alternatively extended roles
          }
        } else {
          companyName = [];
          worker.companies.forEach(pCompany => {
            if (pCompany.role_label) { role = pCompany.role_label; }
            companyName.push(pCompany.name);
          });
          companyName = companyName.join(', ');
        }
      }
      return {
        ...worker,
        role,
        companyName,
        modified: moment(worker.modified_date || worker.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ').format('DD.MM.YYYY HH:mm'),
        status_id: worker.status_id || USER_STATUS_IDS.APPROVED,
        status: worker.status || USER_STATUS_LABELS[worker.status_id || USER_STATUS_IDS.APPROVED]
      };
    });

    // options map
    // let role = isAdmin ? [{ label: 'Admin', value: 'Administrator' }] : [];
    // role = role.concat([
    //   { label: 'Geschäftsführer', value: 'Geschäftsführer' },
    //   { label: 'Mitarbeiter Plus', value: 'Mitarbeiter Plus' },
    //   { label: 'Mitarbeiter', value: 'Mitarbeiter' }
    // ]);
    const role = [];
    roles.map(item => {
      const { label } = item;
      if (item.id === 1) {
        if (isAdmin) {
          role.push({label, value: label});
        }
      } else {
        role.push({label, value: label});
      }
    });
    const status_id = [
      { label: 'Aktiviert', value: USER_STATUS_IDS.APPROVED },
      { label: 'Deaktiviert', value: USER_STATUS_IDS.DEACTIVATED }
    ];
    const optionMap = { role, status_id };

    // filters
    const useInputFilter = SalesTableUtil.createUseInputFilter(this.dt, 400, this.onFilterChange);
    const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(this.dt, data, optionMap, this.onFilterChange);
    const useRangeFilter = SalesTableUtil.createUseRangeFilter(this.dt, this.onFilterChange);
    const usernameFilter = useInputFilter('username', { filterOnChange: true });
    const roleFilter = useMultiSelectFilter('role');
    const companyNameFilter = useInputFilter('companyName', { filterOnChange: true });
    const modifiedFilter = useRangeFilter('modified');
    const statusFilter = useMultiSelectFilter('status_id');
    
    return (
      <div className={`worker-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          ref={ref => this.dt = ref}
          onValueChange={this.onValueChange}
          first={first}
          lazy={true}
          totalRecords={totalRecords}
          onPage={(event) => this.onLazyLoad(event)}
          paginator
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={totalRecords}
              filteredRecords={filteredCount}
              first={first}
              rows={rows}
              emptyText="Keine Mitarbeiter"
              itemName="Mitarbeiter"
              itemNamePlural="Mitarbeiter"
            />
          }
          removableSort
          sortMode="multiple"
          multiSortMeta={data.length <= 1 ? [] : this.filterValues.sortFields}
          onSort={this.onSort}
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Mitarbeiter"/>)}
        >
          <Column
            field="username"
            header="Mitarbeiter"
            sortable={data.length > 1}
            filter
            {...usernameFilter}
          />
          <Column
            field="role"
            header="Position"
          //  sortable={data.length > 1}
            filter
            {...roleFilter}
          />  
          <Column
            field="companyName"
            header="Beschäftigungsfirma"
            // sortable={data.length > 1}
            filter
            {...companyNameFilter}
          />
          <Column
            field="modified"
            header="Letzte Änderung"
            // sortable={data.length > 1}
            filter
            {...modifiedFilter}
            style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter
            {...statusFilter}
            body={rowData => rowData.status}
            style={{textAlign:'center', width: '10em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '7.5em'}}
          />
        </DataTable>
        {/* <div className={`w3-modal w3-${(!workers || workers.length === 0) ? 'show' : 'hide w3-animate-opacity'}`}/> */}
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(WorkerTable)
);
