import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../components/Buttons';
import ItemListManager from '../../../components/ItemListManager';
import UserDeviceForm from './UserDeviceForm';
import { toUIDate, STATUS_LABELS, TableEmptyMessage, CoverText } from '../../../components/Utils';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';


export default class UserDeviceListPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 10,
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  render() {
    const { className, model, rows } = this.props;
    const { first } = this.state;
    const renderItemTable = (items) => {
      if (items.length === 0) {
        return (<CoverText>{'Ihnen wurden noch keine Mesgeräte zugewiesen'}</CoverText>);
      }
      const data = items.map(item => {
        const copy = JSON.parse(JSON.stringify(item));
        return {
          ...copy,
          nextCheckDateStr: toUIDate(copy.nextCheckDate),
          status: item.status || STATUS_LABELS[item.status_id] || ''
        };
      });
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Messgeräte"
              itemName="Messgerät"
              itemNamePlural="Messgeräte"
            />
          }
          emptyMessage={(<TableEmptyMessage itemNamePlural="Messgeräte" min/>)}
        >
          <Column
            field="device"
            header="Messgerät"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="userPrimary"
            header="Hauptbenutzer"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="nextCheckDateStr"
            header="Prüfung fällig"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const userDevice = item;
      console.log('UserDeviceListPanel.renderItemForm()', userDevice, baseData);
      return (
        <div>
          <UserDeviceForm model={userDevice} readonly/>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onCancel={onHide}
            saveDisabled
            saveBtnName="saveUserDeviceBtn"/>
        </div>
      );
    };
    const getItemFormTitle = (userDevice) => {
      return (<h3 className="no-margin">{`Messgerät ansehen`}</h3>);
    }
    return (
      <ItemListManager
        className={className}
        title={(<h3 className="no-margin-top">Messgeräte</h3>)}
        addBtnHidden
        items={model}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="userDevice"
      />
    );
  }
}
