import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  PageHeight40,
  PageTitle,
  createSortByAttr,
  DEVICE_ACTION_IDS,
  DEVICE_STATUS_IDS,
  DEVICE_STATUS_LABELS,
  scrollToTop,
  TableEmptyMessage
} from '../../../../components/Utils';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import DeviceTaskForm from '../../components/devices/DeviceTaskForm';
import DeviceTaskMenu from '../../components/devices/DeviceTaskMenu';
import { filterTasksByTaskType, getTaskFormTitle, getSaveTaskLabel } from '../../components/Utils';

import { connect } from 'react-redux';
import {
  setSelectedDevices,
  exportDevices,
  filterDevices,
  closeDeviceTask,
  deleteDeviceTask
} from '../../actions/device';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    devices: state.task.deviceTasks,
    selection: state.task.selectedDevices,
    deviceTaskType: state.task.deviceTaskType
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedDevices(...args)),
  exportDevices: (...args) => dispatch(exportDevices(...args)),
  filterDevices: (...args) => dispatch(filterDevices(...args)),
  closeTask: (...args) => dispatch(closeDeviceTask(...args)),
  deleteTask: (...args) => dispatch(deleteDeviceTask(...args)),
});

class DeviceTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    devices: PropTypes.array,
    selection: PropTypes.array,
    deviceTaskType: PropTypes.string,
    exportDevices: PropTypes.func.isRequired,
    filterDevices: PropTypes.func.isRequired,
    rows: PropTypes.number
  }
  static defaultProps = {
    className: '',
    rows: 10
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    }
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = (onHide) => {
    const task = this.form.getModel();
    console.log('DeviceTaskList.onSave() ...', task);
    if (!task) return;
    const { closeTask } = this.props;
    const deviceTaskType = task.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT ? 'DELETE-ACCOUNT-DEVICE' : this.props.deviceTaskType;
    console.log(`>> saving device task...`, task, deviceTaskType);
    closeTask({ task, deviceTaskType }, onHide);
  }
  onDelete = (task) => {
    const { deviceTaskType, deleteTask } = this.props;
    console.log(`DeviceTaskList.onDelete()`, task, deviceTaskType);
    deleteTask({ task, deviceTaskType });
  }
  render() {
    console.log('DeviceTaskList.render()', this.props);
    const {
      className, isLoading, rows, devices,
      selection, setSelection, exportDevices,
      filterDevices, deviceTaskType
    } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item, index) => ({
        ...item,
        index,
        status: item.status || DEVICE_STATUS_LABELS[item.status_id]
      })).sort(createSortByAttr('userPrimary'));
      return (
        <DataTable
          key={Math.random}
          value={taskItems}
					paginator={taskItems.length > rows}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {this.setState({first: event.first}); scrollToTop();}}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Messgeräte"
              itemName="Messgerät"
              itemNamePlural="Messgeräte"
            />
          }
          selection={selection}
          onSelectionChange={event => setSelection(event.value)}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Messgeräte"/>)}
        >
          <Column
            selectionMode="multiple"
            style={{width:'3em'}}
          />
          <Column
            field="companyName"
            header="Firma"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="device"
            header="Messgerät"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="serialNumber"
            header="Seriennummer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="requester"
            header="Anforderer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="status"
            header="Status"
            body={rowData => {
              let markup = rowData.status;
              if (rowData.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT) {
                markup = DEVICE_STATUS_LABELS[DEVICE_STATUS_IDS.DELETING_ACCOUNT];
              }
              return markup;
            }}
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center-', width: '9em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '8em'}}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const model = item;
      return (
        <div>
          <DeviceTaskForm
            ref={(ref) => this.form = ref}
            model={model}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveDeviceBtn"
            saveBtnLabel={getSaveTaskLabel(deviceTaskType, model)}/>
        </div>
      );
    };
    const getItemFormTitle = (device) => {
      const title = getTaskFormTitle(deviceTaskType, device);
      return (<h3 className="no-margin">{title}</h3>);
    }
    const title = (
      <PageTitle>
        <DeviceTaskMenu
          className="w3-right"
          selectedDevices={selection}
          exportDevices={exportDevices}
          filterDevices={filterDevices}
          deviceTaskType={deviceTaskType}
          items={devices}
        />
        <h3 className="no-margin pad-btm">{'Messgeräte'}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={devices.filter(item => filterTasksByTaskType(item, deviceTaskType))}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`Device`}
          itemVisibleOnly={deviceTaskType === 'DELETE-DEVICE'}
          itemDeleteable
          deleteTitle={`Messgerätaufgabe löschen`}
          getDeleteText={(item) => `Wollen Sie diese Aufgabe unwiderruflich löschen und so den Ursprungszustand wiederherstellen?`}
          onDelete={this.onDelete}
        />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTaskList);
