import React from 'react';
// import PropTypes from 'prop-types';
// import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadUserSales } from "../actions/success";
import { createSortByName } from '../../../components/Utils';


export const DisplayOptions = [
  { label: 'Gesamt', value: 'all' },
  { label: 'Monat', value: 'YYYY_MM' },
  { label: 'KW', value: 'YYYY_KW' },
  { label: 'Tag', value: 'TT.MM.YYYY' }
];


export const getChildDisplay = (display) => {
  let childDisplay = null;
  const levels = DisplayOptions.map(item => item.value);
  const index = levels.findIndex(item => item === display);
  if (index >= 0 && index < levels.length - 1) {
    childDisplay = levels[index + 1];
  }
  // console.log('getChildDisplay() parent:', display, 'child:', childDisplay);
  return childDisplay;
};


export const DisplayHierarchy = [
  'all',
  'month',
  'week',
  'day'
];

export const getChildrenKeys = (key) => {
  let result = [];
  const index = DisplayHierarchy.findIndex(item => item === key);
  if (index >= 0 && index < DisplayHierarchy.length - 1) {
    result = DisplayHierarchy.slice(index + 1);
    // console.log('getChildrenKeys():', result);
  }
  return result;
}

export const DisplayConfig = {
  'all': {
    key: 'all',
    levelLabel: 'Gesamt',
    levelBody: () => 'Gesamt'
  },
  'YYYY_MM': {
    key: 'month',
    levelLabel: 'Monat',
    levelBody: (item) => item.month
  },
  'YYYY_KW': {
    key: 'week',
    levelLabel: 'KW',
    levelBody: (item) => item.week
  },
  'TT.MM.YYYY': {
    key: 'day',
    levelLabel: 'Tag',
    levelBody: (item) => item.day
  },
  'delphi': {
    key: 'delphi'
  }
};

const COLUMN_NAMES = [
  'ist Netto',
  'offen',
  'FEHLER WO IS C',
  'eingestellt',
  'Widerruf/Storno'
];
export const getDataDisplayColumns = (colNames = COLUMN_NAMES, hideSales) => {
  console.log('>> getDataDisplayColumns()', colNames)
  let columnNames = [];
  if (!hideSales) {
    columnNames.push('sales');
  }
  const LabelMap = {
    'sales': 'Absätze',
    'Widerruf/Storno': 'Widerruf'
  };
  const list = [];
  colNames.forEach(item => {
    if (item) {
      list.push({name: item.toLowerCase(), value: item});
    }
  });
  list.sort(createSortByName());
  columnNames = columnNames.concat(list.map(item => item.value));
  return columnNames.map((columnName, index) => {
    const label = LabelMap[columnName] || columnName;
    return (
      <Column
        key={`col${index}`}
        header={label}
        field={columnName}
        body={item => (item[columnName] || 0)}
        style={{textAlign: 'center', width: '9em'}}
      />
    );
  });
  // return [
  //   <Column
  //     key={`col${0}`}
  //     field="sales"
  //     header="Absätze"
  //     style={{textAlign: 'center', width: '9em'}}
  //   />,
  //   <Column
  //     key={`col${1}`}
  //     field="ist Netto"
  //     header="ist Netto"
  //     body={item => (item['ist Netto'] || 0)}
  //     style={{textAlign: 'center', width: '9em'}}
  //   />,
  //   <Column
  //     key={`col${2}`}
  //     field="offen"
  //     header="offen"
  //     body={item => (item['offen'] || 0)}
  //     style={{textAlign: 'center', width: '9em'}}
  //   />,
  //   <Column
  //     key={`col${3}`}
  //     field="FEHLER WO IS C"
  //     header="FEHLER WO IS C"
  //     body={item => (item['FEHLER WO IS C'] || 0)}
  //     style={{textAlign: 'center', width: '9em'}}
  //   />,
  //   <Column
  //     key={`col${4}`}
  //     field="eingestellt"
  //     header="eingestellt"
  //     body={item => (item['eingestellt'] || 0)}
  //     style={{textAlign: 'center', width: '9em'}}
  //   />,
  //   <Column
  //     key={`col${5}`}
  //     field="Widerruf/Storno"
  //     header="Widerruf"
  //     body={item => (item['Widerruf/Storno'] || 0)}
  //     style={{textAlign: 'center', width: '9em'}}
  //   />
  // ];
};

export const connectDisplay = (Component, stateKey, noFrontLoad) => {
  const frontload = async (props) => {
    // console.log('>> frontload:', props);
    const { getSales, filterConfig , item} = props;
    if (getSales && filterConfig && Object.keys(filterConfig).length > 0) {
      await getSales({ filterConfig, item });
    }
  };
  const mapStateToProps = (state) => {
    return {
      columnNames: state.achievement.saleStates,
      data: state.achievement[`${stateKey}Sales`],
      filterConfig: state.achievement[`${stateKey}FilterConfig`],
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    getSales: (...args) => dispatch(loadUserSales(...args)),
  });
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    noFrontLoad
      ? Component
      : frontloadConnect(frontload, {
        onMount: true,
        onUpdate: false,
      })(Component)
  );
};

export const connectProductDisplay = (Component) => {
  const mapStateToProps = (state) => {
    return {
      filterType: state.achievement.filterType,
      data: state.achievement.productSales,
      columnNames: state.achievement.saleStates,
    };
  };
  const mapDispatchToProps = null;
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component);
};



// export const DelphiDisplay = (props = {}) => {
//   const extraCols = () => (
//     <Column field="product" header="Produkt" />
//   );
//   const levelBody = (item) => item.delphiNr;
//   return (
//     <LevelDisplay
//       {...props}
//       levelLabel="Delphi-Nr"
//       levelBody={levelBody}
//       extraCols={extraCols}
//     />
//   );
// };



// /**
//  * Create a child Component that fetches its data
//  * @param {*} item 
//  * @param {*} key 
//  */
// export const createChildComponent = (config, key) => {
//   const Component = (props = {}) => {
//     const [ data, setData ] = React.useState(null);
//     React.useEffect(() => {
//       if (getData) {
//         console.log('>> fetching data for', childDisplay);
//         getData()
//       }
//     }, []);
//     console.log('ChildLevel.render()', init, data);
//     return (data === null) ? (<div>{`wird geladen...`}</div>) : (<Child data={data} />);
//   };
//   Component.propTypes = {
//     getData: PropTypes.func.isRequired
//   };


//   const frontload = async (props) => {
//     await props.getData(config);
//   };
//   const mapStateToProps = (state) => {
//     return {
//       data: state.achievement.filterType,
//     };
//   };
//   const mapDispatchToProps = (dispatch) => ({
//     getSales: (...args) => dispatch(loadUserSales(...args)),
//   });
//   return connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(
//     frontloadConnect(frontload, {
//       onMount: true,
//       onUpdate: false,
//     })(FilterPanel)
//   );
// }; 

// const LevelDisplayConfig = {
//   'all': {
//     levelLabel: 'Gesamt',
//     levelBody: () => 'Gesamt'
//   },
//   'YYYY_MM': {
//     levelLabel: 'Monat',
//     levelBody: (item) => item.month
//   },
//   'YYYY_KW': {
//     levelLabel: 'KW',
//     levelBody: (item) => item.week
//   },
//   'TT.MM.YYYY': {
//     levelLabel: 'Tag',
//     levelBody: (item) => item.day
//   }
// }

// const getDisplayChild = (display) => {
//   switch(display) {
//     case 'all': return 'YYYY_MM';
//     case 'YYYY_MM': return 'YYYY_KW';
//     case 'YYYY_KW': return 'TT.MM.YYYY';
//     default: break;
//   }
//   return null;
// };

// export const getDisplayComponent = (filterType, filterConfig = {}) => {
//   if (filterType === 'delphi') {
//     return DelphiDisplay;
//   }
//   const { display } = filterConfig;
//   if (!display) {
//     return null;
//   }
//   let ChildComponent = null;
//   const childDisplay = getDisplayChild(display);
//   const Child = getDisplayComponent(filterType, { display: childDisplay });
//   if (Child) {
//     ChildComponent = ({ init, getData }) => {
//       const [ data, setData ] = React.useState(null);
//       React.useEffect(() => {
//         if (getData) {
//           console.log('>> fetching data for', childDisplay);
//           getData()
//         }
//       }, []);
//       console.log('ChildLevel.render()', init, data);
//       return (data === null) ? (<div>{`wird geladen...`}</div>) : (<Child data={data} />);
//     };
//   }
//   const Component = (props = {}) => {
//     const config = LevelDisplayConfig[display] || {};
//     return (
//       <LevelDisplay
//         {...props}
//         {...config}
//         ChildComponent={ChildComponent}
//       />
//     );
//   };
//   return Component;
// };
