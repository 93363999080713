import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileIconButton } from '../../../components/Buttons';
import { createSortById, MoreText, scrollToTop, TableEmptyMessage, toUIDate } from '../../../components/Utils';
import HtmlText from '../../../components/HtmlText';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadProtocols, loadProtocolFile } from '../actions';

const frontload = async (props) => {
  await props.getProtocols();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    protocols: state.news.protocols
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProtocols: (...args) => dispatch(loadProtocols(...args)),
  getProtocolFile: (...args) => dispatch(loadProtocolFile(...args))
});

class ProtocolTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    protocols: PropTypes.array,
    getProtocols: PropTypes.func.isRequired,
    getProtocolFile: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    categoryName: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: '',
    categoryName: 'General'
  }
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onGetFile = (item) => {
    console.log('generating CSV-Protocol file...', item);
    this.props.getProtocolFile(item);
  }
  actionTemplate = (rowData) => {
    return (
      <div>
        <FileIconButton className="pad-sm" onClick={() => this.onGetFile(rowData)}/>
      </div>
    );
  }
  render() {
    const { className, isLoading, protocols, rows } = this.props;
    // console.log('ProtocolTable.render()', news);
    const getSendResult = item => {
      const total = item.successfulCounter +  item.unsuccessfulCounter;
      const sent = item.successfulCounter;
      return `an ${sent} / ${total}`;
      // return `an ${sent} / ${total} Person${total > 1 ? 'en' : ''}`;
    }
    const data = (protocols || []).map((item, index) => ({
      ...item,
      index,
      roles: item.roles ? item.roles.split(',').join(', ') : '',
      companies: item.companies ? item.companies.split(',').join(', ') : '',
      sentDate: toUIDate(item.created_date),
      sent: getSendResult(item),
      status: `${(item.status_id === 1 ? 'wird ' : '')}versendet`,
    }))
    // sort by id in descending order
    .sort(createSortById(true));
    return (
      <div className={`news-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Versandprotokolle"
              itemName="Versandprotokoll"
              itemNamePlural="Versandprotokolle"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Versandprotokolle"/>)}
        >
          <Column
            field="sender"
            header="Ersteller"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '8em'}}
          />
          <Column
            header="Inhalt"
            body={rowData => {
              const text = (rowData.message.indexOf('<p>') >= 0)
                ? (<HtmlText text={rowData.message}/>)
                : rowData.message;
              return (<MoreText className="wide">{text}</MoreText>);
            }}
            // sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '10em'}}
          /> 
          <Column
            field="regions"
            header="Region"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '7em'}}
          />
          <Column
            field="type"
            header="Störungstyp"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '9em'}}
          />
          <Column
            field="companies"
            header="Firmen"
            body={rowData => (<MoreText>{rowData.companies}</MoreText>)}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="roles"
            header="Position"
            body={rowData => (<MoreText>{rowData.roles}</MoreText>)}
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          /> 
          <Column
            field="sentDate"
            header="Datum"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '8em', textAlign: 'center'}}
          />
          <Column
            field="sent"
            header="versendet an"
            style={{width: '7.6em'}}
          /> 
          <Column
            field="status"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '8.5em'}}
          /> 
          <Column
            body={this.actionTemplate}
            className="alg-center"
            style={{width: '4.2em'}}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ProtocolTable)
);
