import * as actionTypes from './actions';
import isArray from 'lodash/isArray';
import { createDebitorTaskMap, createModemOrderWeeklyGroupedOverview, createModemOrderWeeklyOverview, createModemOrderWeeklyOverviewMap, formatFieldModemName } from './components';
import { openFile, notifyInfo, notifyError, notifySuccess, PRODUCT_STATUS_IDS, isProductActivated } from '../../components/Utils';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  modems: [],
  catalogModems: [],
  selectedModem: null,
  selectionPurpose: null,
  dependentModemOrders: [],

  modemOrders: [],
  modemOrderOverview: [],
  modemWeeklyOrders: [],
  debitors: [],
  debitorOrder: null,
  shoppingCart: null,
  debitorTaskMap: null,
  taskWeek: {
    week: 0,
    year: 0
  },
  loaded: false,

  companyOptions: [],
  currentCompanyId: 0,
  currentCompanyName: '',
  regionOptions: [],

  selectedModems: [],
};

function getValidDebitors(debitors) {
  if (!debitors) {
    return [];
  } 
  return debitors.filter(debitor => {
    const address = debitor.address;
    return address && address.companyName !== '' &&
      address.street !== '' &&
      // address.houseNumber !== '' &&
      address.areaCode !== '' &&
      address.city !== '';
  }).sort((a, b) => {
    if (a.debitorKey > b.debitorKey) return 1;
    else if (a.debitorKey < b.debitorKey) return -1;
    return 0;
  });
}

export default (state = initialState, action) => {
  switch (action.type) {
    // modems
    case actionTypes.LOAD_MODEMS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_MODEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        modems: action.result,
        catalogModems: (action.result || [])
        .filter(product => isProductActivated(product))
        .map(product => {
          product.models = product.models.filter(model => isProductActivated(model));
          return product;
        }),
      };
    case actionTypes.LOAD_MODEMS_FAILURE:
      return {
        ...state,
        loading: false,
        modems: [],
        catalogModems: [],
        error: action.result
      };
    // load modem
    case actionTypes.LOAD_MODEM:
        return {
          ...state,
          processing: true
        };
    case actionTypes.LOAD_MODEM_SUCCESS:
      return {
        ...state,
        modem: isArray(action.result) ? action.result[0] : action.result,
        processing: false
      };
    case actionTypes.LOAD_MODEM_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.result
      };
    // save modem
    case actionTypes.SAVE_MODEM:
        return {
          ...state,
          processing: true
        };
    case actionTypes.SAVE_MODEM_SUCCESS:
      notifySuccess({
        summary: `Modem ${action.modem.id ? 'aktualisieren' : 'speichern'}`,
        detail: `Das Modem ${action.modem.name} wurde erfolgreich gespeichert!`
      });
      return {
        ...state,
        modem: null,
        processing: false
      };
    case actionTypes.SAVE_MODEM_FAILURE:
      notifyError({
        summary: `Modem ${action.modem.id ? 'aktualisieren' : 'speichern'}`,
        detail: `Das Modem ${action.modem.name} konnte nicht gespeichert werden!`
      });
      return {
        ...state,
        processing: false,
        error: action.result
      };
    // cancel modem
    case actionTypes.CANCEL_MODEM:
      return {
        ...state,
        modem: null
      };
    // deleting modem
    case actionTypes.DELETE_MODEM:
      return {
        ...state,
        processing: true
      };
    case actionTypes.DELETE_MODEM_SUCCESS:
      notifySuccess({
        summary: `Modem löschen`,
        detail: `Das Produkt ${action.modem.name} wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        processing: false,
        selectedModem: null,
        selectionPurpose: null
      };
    case actionTypes.DELETE_MODEM_FAILURE:
      notifyError({
        summary: `Modem löschen`,
        detail: `Das Produkt ${action.modem.name} konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        processing: false,
        error: action.result,
        selectedModem: null,
        selectionPurpose: null
      };
    // select a modem
    case actionTypes.SELECT_MODEM:
      return {
        ...state,
        selectedModem: action.modem,
        selectionPurpose: action.purpose
      };
    // cancel a modem selection
    case actionTypes.CANCEL_MODEM_SELECTION:
      return {
        ...state,
        selectedModem: null,
        selectionPurpose: null
      };
    // check modem usage
    case actionTypes.CHECK_USAGE:
      return {
        ...state,
        loading: true,
        dependentModemOrders: null
      };
    case actionTypes.CHECK_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dependentModemOrders: action.result
      };
    case actionTypes.CHECK_USAGE_FAILURE:
      return {
        ...state,
        loading: false,
        dependentModemOrders: null,
        error: action.error || action.result
      };
    // change a modem's status
    case actionTypes.CHANGE_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_STATUS_SUCCESS:
      notifySuccess({
        summary: `Modem ${action.modem.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Modem ${action.modem.name} wurde erfolgreich ${action.modem.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false,
        key: null
      };
    case actionTypes.CHANGE_STATUS_FAILURE:
      notifyError({
        summary: `Modem ${action.modem.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Modem ${action.modem.name} konnte nicht ${action.modem.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert werden!`
      });
      return {
        ...state,
        loading: false,
        key: null,
        error: action.result
      };

    
    // modem order companies
    case actionTypes.LOAD_MODEM_ORDER_COMPANIES:
      return {
        ...state,
        companyOptions: action.companyOptions,
        currentWeek: state.currentWeek || action.week,
        currentCompanyId: state.currentCompanyId || 0,
        currentCompanyName: state.currentCompanyName || '',
        regionOptions: state.regionOptions || []
      };

    // modem order debitors
    case actionTypes.LOAD_MODEM_ORDER_DEBITORS:
      return {
        ...state,
        // regionOptions: null,
        currentWeek: action.currentWeek,
        currentYear: action.currentYear,
        currentCompanyId: action.currentCompanyId,
        currentCompanyName: action.currentCompanyName,
        debitors: null,
        savedRegion: null,
        loading: true
      };
    case actionTypes.LOAD_MODEM_ORDER_DEBITORS_SUCCESS: {
      // const region = isArray(action.result) ? action.result[0] : action.result;
      const result = isArray(action.result) ? action.result : [];
      const message = !isArray(action.result) ? action.result : null;
      const regions = result;
      const regionOptions = [];
      const regionNameMap = {};
      regions.forEach(item => {
        if (!regionNameMap[item.name]) {
          regionNameMap[item.name] = true;
          regionOptions.push({ label: item.name, value: item.name });
        }
      });
      console.log('REDUX-REDUCER LOAD_MODEM_ORDER_DEBITORS_SUCCESS - regions:', regions, 'regionOptions:', regionOptions);
      let currentRegionName = action.currentRegionName;
      let debitors = [];
      if (regions.length > 0) {
        if (!currentRegionName || currentRegionName === 'null') {
          currentRegionName = regions[0].name;
          debitors = regions[0].debitors;
          let index = 0;
          do {
            currentRegionName = regions[index].name;
            debitors = regions[index].debitors;
            console.log('>> old debitor list', debitors, 'currentRegionName:', currentRegionName);
            debitors = getValidDebitors(debitors);
            index ++;
          } while(index < regions.length && debitors.length === 0);
        } else {
          const found = regions.find(item => item.name === currentRegionName);
          if (found) {
            debitors = found.debitors;
          }
          console.log('>> old debitor list', debitors, 'currentRegionName:', currentRegionName);
          debitors = getValidDebitors(debitors);
        }
        console.log('>> new debitor list', debitors);
      }
      return {
        ...state,
        loading: false,
        regionOptions: regionOptions,/* state.regionOptions ||  */
        currentRegionName, //: action.currentRegionName,
        debitors, //: region.debitors
        message
      };
    }
    case actionTypes.LOAD_MODEM_ORDER_DEBITORS_FAILURE:
      return {
        ...state,
        loading: false,
        regionOptions: null,
        debitors: null,
        error: action.result
      };
    // set order debitor
    case actionTypes.SET_MODEM_ORDER_DEBITOR: {
      if (action.isArchive) {
        // view an old debitor order (admin)
        return {
          ...state,
          selectedDebitor: { ...action.result.debitor },
          debitorLoaded: false,
          newModems: null
        };
      }
      // making a debitor modem order
      const shoppingCart = /* state.shoppingCart ||  */{};
      return {
        ...state,
        shoppingCart: { ...shoppingCart, ...action.result },
        selectedDebitor: { ...action.result.debitor },
        debitorLoaded: false,
        newModems: null // contains edited version of an existing order
      };
    }
    // get debitor details
    case actionTypes.LOAD_DEBITOR_DETAILS:
      return {
        ...state,
        loadingDebitor: true
      };
    case actionTypes.LOAD_DEBITOR_DETAILS_SUCCESS: {
      console.log('REDUX REDUCER - LOAD_DEBITOR_DETAILS_SUCCESS', action);
      const shoppingCart = state.shoppingCart || { debitor: {}};
      const result = isArray(action.result) ? action.result[0] : action.result;
      if (result) {
        const modems = result.modems || [];
        shoppingCart.week = result.week;
        shoppingCart.modems = [ ...modems ];
      }
      return {
        ...state,
        loadingDebitor: false,
        shoppingCart: { ...shoppingCart },
        debitorLoaded: true // required so as not to reload debitor if user returns to CartModemCatalog 
      };
    }
    case actionTypes.LOAD_DEBITOR_DETAILS_FAILURE:
      return {
        ...state,
        loadingDebitor: false,
        error: action.result
      };
    // save modem debitor order
    case actionTypes.SAVE_MODEM_DEBITOR_ORDER:
      return {
        ...state,
        saving: true
      };
    case actionTypes.SAVE_MODEM_DEBITOR_ORDER_SUCCESS:
      notifySuccess({
        summary: 'Modembestellung',
        detail: `
          ${action.orderNumber ? ('Die Bestellung ' + action.orderNumber ) : 'Der Warenkorb'} wurde erfolgreich
          ${action.orderNumber ? ' aktualisiert' : ' gespeichert'}!`
      });
      return {
        ...state,
        // reset shopping cart and debitorOrder after successfully saving
        // regionOptions: null,
        debitorOrder: null,
        shoppingCart: null,
        selectedDebitor: null,
        saving: false,
        savedWeek: state.currentWeek,
        savedRegion: action.region
      };
    case actionTypes.SAVE_MODEM_DEBITOR_ORDER_FAILURE:
      notifyError({
        summary: 'Modembestellung',
        detail: `
          ${action.orderNumber ? ('Die Bestellung ' + action.orderNumber ) : 'Der Warenkorb'} konnte nicht
          ${action.orderNumber ? ' aktualisiert' : ' gespeichert'} werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // cancel modem debitor order
    case actionTypes.CANCEL_MODEM_DEBITOR_ORDER:
      return {
        ...state,
        currentWeek: null,
        companyOptions: [],
        currentCompanyId: 0,
        currentCompanyName: '',
        regionOptions: null,
        debitorOrder: null,
        shoppingCart: null,
        selectedDebitor: null,
        modemOrder: null,
        newModems: null,
        archiveModems: null,
        savedWeek: null,
        savedRegion: null
      };
    
    // save modem order
    case actionTypes.SAVE_MODEM_ORDER:
      return {
        ...state,
        saving: true
      };
    case actionTypes.SAVE_MODEM_ORDER_SUCCESS:
      notifySuccess({
        summary: 'Modembestellung',
        detail: `Die Bestellung wurde erfolgreich abgegeben!`
      });
      return {
        ...state,
        saving: false
      };
    case actionTypes.SAVE_MODEM_ORDER_FAILURE:
      notifyError({
        summary: 'Modembestellung',
        detail: `Die Bestellung konnte nicht abgegeben werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };


    // modem orders
    case actionTypes.LOAD_MODEM_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_MODEM_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        modemOrders: action.result
      };
    case actionTypes.LOAD_MODEM_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        modemOrders: [],
        error: action.result
      };
    // modem order overview
    case actionTypes.LOAD_MODEM_ORDERS_OVERVIEW:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_MODEM_ORDERS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        modemOrderOverview: action.result
      };
    case actionTypes.LOAD_MODEM_ORDERS_OVERVIEW:
      return {
        ...state,
        loading: false,
        modemOrderOverview: null,
        error: action.result
      };
    // modem weekly orders
    case actionTypes.LOAD_MODEM_WEEKLY_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_MODEM_WEEKLY_ORDERS_SUCCESS: {
      console.log('REDUX REDUCER - LOAD_MODEM_WEEKLY_ORDERS_SUCCESS:', action.result);
      const modemOrderWeeklyOverview = createModemOrderWeeklyOverview(action.result);
      const modemOrderWeeklyOverviewMap = createModemOrderWeeklyOverviewMap(modemOrderWeeklyOverview);
      const modemOrderWeeklyGroupedOverview = createModemOrderWeeklyGroupedOverview(modemOrderWeeklyOverviewMap, action.time, state.catalogModems)
      return {
        ...state,
        loading: false,
        modemOrderWeeklyOverview, // : action.result
        modemOrderWeeklyOverviewMap,
        modemOrderWeeklyGroupedOverview,
        taskWeek: action.time,
        selectedModems: []
      };
    }
    case actionTypes.LOAD_MODEM_WEEKLY_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        modemOrderWeeklyOverview: null,
        error: action.result
      };
    // set regional weekly order
    case actionTypes.SET_MODEM_WEEKLY_REGIONAL_ORDERS: {
      const map = state.modemOrderWeeklyOverviewMap || {};
      const modemOrderWeeklyOverviewRegion = map[action.debitorKey];
      return {
        ...state,
        modemOrderWeeklyOverviewRegion
      }
    }
    // load modem order
    case actionTypes.LOAD_MODEM_ORDER:
        return {
          ...state,
          loading: true
        };
    case actionTypes.LOAD_MODEM_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        modemOrder: action.result,
        debitorLoaded: true // required so as not to reload debitor if user returns to CartModemCatalog 
      };
    case actionTypes.LOAD_MODEM_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        modemOrder: null,
        shoppingCart: null,
        error: action.result
      };
    // get debitor details
    case actionTypes.LOAD_ORDER_DEBITOR_DETAILS:
      return {
        ...state,
        loadingDebitor: true
      };
    case actionTypes.LOAD_ORDER_DEBITOR_DETAILS_SUCCESS: {
      console.log('###reducer:', action);
      const orderDebitorDetails = state.orderDebitorDetails || {};
      if (isArray(action.result)) {
        orderDebitorDetails.modems = [ ...action.result ].map(item => ({ ...item, product_name: item.product_name || item.productName}));
      } else {
        orderDebitorDetails.week = action.result.week;
        orderDebitorDetails.modems = [ ...action.result.modems ];
      }
      return {
        ...state,
        loadingDebitor: false,
        orderDebitorDetails: { ...orderDebitorDetails },
        debitorLoaded: true // required so as to not load order debitor again if user returns to OrderModemCatalog
      };
    }
    case actionTypes.LOAD_ORDER_DEBITOR_DETAILS_FAILURE:
      return {
        ...state,
        loadingDebitor: false,
        error: action.result
      };
    // save changes
    case actionTypes.SAVE_ORDER_DEBITOR_CHANGES: {
      return {
        ...state,
        newModems: [ ...action.result ],
        // debitorLoaded: true // required so as not to reload debitor if user returns to OrderModemCatalog 
      };
    }
    // load archive debitor details
    case actionTypes.LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        archiveModems: action.result
      }
    case actionTypes.LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        archiveModems: null,
        error: action.error
      }
    // set selection
    case actionTypes.SET_SELECTED_MODEMS:
      return {
        ...state,
        selectedModems: action.selected
      };
    // change modem order status
    case actionTypes.CHANGE_MODEM_ORDER_STATUS:
      return {
        ...state,
        saving: true
      };
    case actionTypes.CHANGE_MODEM_ORDER_STATUS_SUCCESS:
      notifySuccess({
        summary: 'Modem Logistik',
        detail: 'Alle ausgewählte neue Bestellungen wurden erfolgreich abgenommen!'
      });
      return {
        ...state,
        saving: false
      };
    case actionTypes.CHANGE_MODEM_ORDER_STATUS_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // get all order debitors (admin)
    case actionTypes.LOAD_TASK_ORDER_DEBITORS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_TASK_ORDER_DEBITORS_SUCCESS: {
      const debitorTaskMap = createDebitorTaskMap(action.result, state.catalogModems);
      return {
        ...state,
        loading: false,
        debitorTaskMap
      }
    }
    case actionTypes.LOAD_TASK_ORDER_DEBITORS_FAILURE:
      return {
        ...state,
        loading: false,
        debitorTaskMap: null,
        error: action.error
      }
    // approving task orders
    case actionTypes.APPROVE_TASK_ORDER_DEBITORS:
      return {
        ...state,
        saving: true
      };
    case actionTypes.APPROVE_TASK_ORDER_DEBITORS_SUCCESS: {
      const detail = action.sendOnly ? 'Die Bestellungen wurden erfolgreich gespeichert.' :
        'Die Bestellungen wurden erfolgreich freigegeben und an Loxxess gesendet! Ein CSV-Export kann nun heruntergeladen werden.';
        notifySuccess({
        summary: 'Modem Logistik',
        detail
      });
      openFile(action.result.filePath);
      return {
        ...state,
        saving: false
      };
    }
    case actionTypes.APPROVE_TASK_ORDER_DEBITORS_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // get all order debitors (admin)
    case actionTypes.LOAD_TASK_ORDERS_AS_CSV:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_TASK_ORDERS_AS_CSV_SUCCESS: {
      if (action.result.filePath) { 
        notifySuccess({
          summary: `Modembestellungsdatei (CSV) herunterladen`,
          detail: `Die CSV-Datei für die ausgewählte Bestellungen wurde erfolgreich erzeugt!`
        });
        openFile(action.result.filePath);
      } else {
        notifyInfo({
          summary: `Modembestellungsdatei (CSV) herunterladen`,
          detail: `Für die ausgewählte Bestellungen wurde keine CSV-Datei erzeugt!`
        });
      }
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.LOAD_TASK_ORDERS_AS_CSV_FAILURE:
      notifyError({
        summary: `Modembestellungsdatei (CSV) herunterladen`,
        detail: `Die CSV-Datei für die ausgewählte Bestellungen konnte nicht erzeugt werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      }
    // cancel approval wizard
    case actionTypes.CANCEL_TASK_ORDER_DEBITORS:
      return {
        ...state,
        debitorTaskMap: null
      }
    // get loxxes addresses (admin)
    case actionTypes.LOAD_LOXXES_ADDRESSES_AS_CSV:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_LOXXES_ADDRESSES_AS_CSV_SUCCESS: {
      if (action.result.filePath) { 
        notifySuccess({
          summary: `Loxxess-Adressen (CSV) herunterladen`,
          detail: `Die CSV-Datei mit Loxxess-Adressen wurde erfolgreich erzeugt und kann heruntergeladen werden!`
        });
        openFile(action.result.filePath);
      }
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.LOAD_LOXXES_ADDRESSES_AS_CSV_FAILURE:
      notifyError({
        summary: `Loxxess-Adressen (CSV) herunterladen`,
        detail: `Die CSV-Datei konnte nicht erzeugt werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      }
    // load order avis file
    case actionTypes.LOAD_ORDER_AVIS_FILE:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOAD_ORDER_AVIS_FILE_SUCCESS: {
      if (action.avis_path) {
        notifySuccess({
          summary: 'AVIS-Datei herunterladen',
          detail: `Die AVIS-Datei der Bestellung ${action.orderNumber} kann heruntergeladen werden!`
        });
        openFile(action.avis_path);
      }
      // openFile(action.result.filePath);
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.LOAD_ORDER_AVIS_FILE_FAILURE:
      notifyError({
        summary: 'AVIS-Datei herunterladen',
        detail: `Die AVIS-Datei der Bestellung ${action.orderNumber} kann nicht heruntergeladen werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      }
    // change week
    case actionTypes.CHANGE_ORDER_WEEK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_ORDER_WEEK_SUCCESS:
      notifySuccess({
        summary: `Modembestellungen verschieben`,
        detail: `Die ausgewählte Bestellungen wurden erfolgreich in die KW${action.week} verschoben!`
      });
      return {
        ...state,
        loading: false
      };
    case actionTypes.CHANGE_ORDER_WEEK_FAILURE: {
      console.log('<< CHANGE WEEK ERROR:', action);
      const error = action.error || action.result || {};
      let detail = `Die ausgewählte Bestellungen konnten nicht in die KW${action.week} verschoben werden!`;
      let props = { life: 8000 };
      if (error.message === 'label.orders.already.exist.in.week') {
        const orderNrs = error.orders.map(item => item.number);
        detail = `Die Verschiebung hat fehlgeschlagen denn für die Debitoren in den folgenden Bestellungen gibt es schon Bestellungen in der Zielwoche: ${orderNrs.join(', ')}`;
        props = { life: 15000 };
      } else if (error.message === "label.order.debitors.not.complete") {
        detail = `Die Verschiebung hat fehlgeschlagen denn für eine/mehrere Bestellungen wurden nicht alle Debitoren selektiert: ${[...new Set(orderNrs)].join(', ')}`;
        props = { life: 15000 };
      }
      notifyError({ summary: `Modembestellungen verschieben`, detail, props });
      return {
        ...state,
        loading: false
      };
    }
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };  

    default:
      return {...state};
  }
}
