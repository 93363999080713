import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Label, InputText, Dropdown } from '../../../../components/Inputs';
import { MinusIconButton } from '../../../../components/Buttons';
import { getContentTypeIdFromType, CONTENT_IMAGE } from '../Utils';
import { isArray } from 'lodash';

export default class ImageUploadForm extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    uploadUrl: PropTypes.string,
    multiple: PropTypes.bool,
    expand: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    model: { body: '[]' },
    uploadUrl: '/data-api/media/upload',
    multiple: true
  }
  constructor(props) {
    super(props);
    const { model } = props;
    let images = [];
    try {
      images = JSON.parse(model.body || '[]');
    } catch(err) {
      console.error('Error:', model, err);
      images = [];
    }
    this.state = { images };
  }
  getModel = (noValidate) => {
    const { images } = this.state;
    // console.log('ImageUploadForm.getModel()', images);
    const getModel = (index) => this[`image${index}`].getModel(noValidate);
    const body = images.map((image, index) => {
      const model = getModel(index);
      // console.log('<< Result:', model)
      return { ...image, style: model.style || image.style };
    }) || [];
    return {
      id: this.props.model.id,
      content_type_id: getContentTypeIdFromType(CONTENT_IMAGE),
      body: JSON.stringify(body)
    };
  }
  addImages = (event) => {
    // console.log('ImageUploadForm.addImages()', event);
    const response = JSON.parse(event.xhr.response);
    const newImages = isArray(response) ? response : [ response ];
    const items = newImages.map(item => ({
      image: '/data-api' + item.filePath,
      style: { width: 'auto', height: 'auto', maxWidth: '100%' }
    }));
    const images = [ ...this.state.images ].concat(items);
    console.log('New Items:', items, images);
    this.setState({ images });
  }
  removeImage = (currentIndex) => {
    const images = [];
    this.state.images.forEach((item, index) => {
      if (index !== currentIndex) {
        images.push(item);
      }
    });
    this.setState({ images });
  }
  render() {
    // console.log('ImageUploadForm.render()', this.props);
    const { className, uploadUrl, multiple, expand } = this.props
    const { images } = this.state;
    console.log('ImageUploadForm.render()', images, this.props);
    let markup = null;
    if (images) {
      const removeImage = (index) => this.removeImage(index);
      markup = images.map((image, index) => {
        const menu = (
          <span className="w3-border w3-round pos-rel display-inline-block" style={{zIndex: 1}}>
            <MinusIconButton onClick={() => removeImage(index)}/>
          </span>
        );
        const menuStyle = { position: 'absolute', right: '0px', top: '43px' };
        return (
          <div key={index} className={'clearfix pad-big-btm'/* expand ? '' : 'pad-sm-top' */}>
            <ImageHolder
              ref={ref => this[`image${index}`] = ref}
              model={image}
              expand={expand}
              menu={<div style={menuStyle}>{menu}</div>}
            />
          </div>
        );
      });
    }
    return (
      <div className={`${className} image-upload-form ${expand ? '' : 'pad'}`}>
        <div className="clearfix">
          <div className="w3-right" style={{height: (images.length > 0) ? '0px' : 'auto', zIndex: 1, position: 'relative'}}>
            <FileUpload
              name="image"
              mode="basic"
              auto
              url={uploadUrl}
              multiple={multiple}
              accept="image/*"
              chooseLabel="Bild hochladen"
              onUpload={event => this.addImages(event)}
              disabled={!multiple && images.length === 1}
            />
          </div>
          {markup}
        </div>
      </div>
    );
  }
}

class ImageHolder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    menu: PropTypes.node,
    expand: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    model: { image: '', style: {} }
  }
  constructor(props) {
    super(props);
    const { model } = props;
    const style = (model || {}).style || {};
    this.state = {
      width: props.expand ? '100%' : (style.width || 'auto'),
      align: style.align || 'left'
    };
  }
  getModel = (noValidate) => {
    // const { model } = this.props;
    const model = this.props.model || {};
    const modelStyle = model.style || {};
    const { width, align } = this.state;
    const style = { ...modelStyle, width, align };
    if (this.props.expand) { style.width = '100%'; }
    // console.log('ImageHolder.getModel() style', style);
    return { ...model, style };
  }
  render() {
    const { className, menu, expand } = this.props;
    const model = this.props.model || {};
    const { width, align } = this.state;
    const AlignOptions = [
      { label: 'Links', value: 'left' },
      { label: 'Zentriert', value: 'center' },
      { label: 'Rechts', value: 'right' }
    ];
    console.log('ImageHolder.render()', model);
    const widthForm =  expand ? null : (
      <div style={{width: '400px'}}>
        <div className="w3-row neg-margin-lr pad-btm">
          <div className="w3-col s2 pad-lr pad-top">
            <Label>{`Breite`}</Label>
          </div>
          <div className="w3-col s4 pad-lr">
            <InputText className="w3-block w3-border" value={width} small
              onChange={(event) => this.setState({width: event.target.value})}/>
          </div>
          <div className="w3-col s2 pad-lr pad-top">
            <Label>{`Position `}</Label>
          </div>
          <div className="w3-col s4 pad-lr">
            <Dropdown
              className="w3-block w3-border"
              value={align}
              options={AlignOptions}
              onChange={(event) => this.setState({align: event.value})}
              showClear
            />
          </div>
        </div>
      </div>
    );
    return (
      <div className={`image-holder ${className} clearfix`}>
        <div className="clearfix">
          {menu}{widthForm}
        </div>
        <div className="clearfix" style={{ textAlign: align }}>
          <img
            src={model.image.replace("/api/", "/data-api/")}
            style={{ ...model.style, width }}
            alt={'Bild'}
          />
        </div>
      </div>
    );
  }
}
