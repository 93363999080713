import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { validateEmail } from '../../../../components/Utils';

export default class EmailForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    model: {
      email: ''
    }
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      email: model.email || '',
      error: {
        email: null,
      }
    };
  }
  validate = () => {
    const { error, ...otherProps } = this.state;
    let model = { ...otherProps };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    // email
    validateEmail(model.email);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className } = this.props;
    const { email, error } = this.state;
    return (
      <div className={className}>
        <div className={`${error.email ? 'error-group' : ''}`}>
          <Label htmlFor="email">{`E-Mail*`}</Label>
          <InputText
            id="email"
            className="w3-block w3-border"
            value={email}
            onChange={(event) => this.setState({
              email: event.target.value,
              error: { email: (event.target.value === '') }
            })}
          />
        </div>
      </div>
    );
  }
}
