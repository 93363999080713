import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'primereact/tree';
import { InputText, Label } from '../../../components/Inputs';
import { createSortById, getLinkParentUrl, sortByOptionalPosition } from '../../../components/Utils';
import { convertListToTree } from '../../MainNav';
import { isArchivePage } from './Utils';
// import { labelToUrl, toDBDateTime, toUIInputDate } from '../../../components/Utils';
// import ListItem from '../../Static/components/content/DownloadListItemForm';

export default class PagePathMovementForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    treeLinks: PropTypes.array,
  }
  static defaultProps = {
    className: '',
    model: {
      label: '',
      url: '',
    },
    treeLinks: []
  }
  constructor(props) {
    super(props);
    const { model, } = props;
    this.state = {
      selectedNodeKey: null,
      newUrl: '',
      error: {
        newUrl: null,
      }
    };
  }
  validate = () => {
    const { url } = this.props.model;
    const parentUrl = getLinkParentUrl(url);
    const { error, selectedNodeKey, newUrl } = this.state;
    // check if all values are set
    let isValid = true;
    if (selectedNodeKey == null || newUrl === '') {
      isValid = false;
      error.newUrl = '';
    } else if (newUrl === parentUrl) {
      isValid = false;
      error.newUrl = 'das Ziel ist gleich die Quelle';
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    return { selectedNodeKey, newUrl };
  }
  getModel = () => {
    const { model } = this.props;
    const data = this.validate();
    if (!data) {
      return null;
    }
    const { position, ...linkData } = model;
    return {
      to: data.newUrl,
      linkData
    };
  }
  onSelectionChange = (value, filteredLinks) => {
    const { url } = this.props.model;
    const parentUrl = getLinkParentUrl(url);
    let newUrl = this.state.newUrl;
    const error = this.state.error;
    if (value != null) {
      const node = filteredLinks.find(item => item.key == value);
      if (node) {
        newUrl = node.url + url.replace(parentUrl, '');
        if (newUrl === '') {
          error.newUrl = '';
        } else if (newUrl === url) {
          error.newUrl = 'das Ziel ist gleich die Quelle';
        } else {
          error.newUrl = null;
        }
      }
    }
    this.setState({
      selectedNodeKey: value,
      newUrl,
      error
    });
  }
  render() {
    const { className, model, treeLinks } = this.props
    const { selectedNodeKey, newUrl, error } = this.state;
    const { url } = model;
    // const parentUrl = getLinkParentUrl(url);
    const filteredLinks = treeLinks
      .filter(item => ((item.url.indexOf(url) < 0) && (!item.data || !isArchivePage(item.data.type))))
      .map((item, index) => ({ ...item, key: index }));
    const tree = convertListToTree(filteredLinks);
    tree.children.sort(createSortById());
    tree.children = sortByOptionalPosition(tree.children, true);
    console.log('PagePathMovementForm.render()', this.state, tree, filteredLinks);
    const style = {
      height: '255px',
      overflowY: 'scroll'
    }
    return (
      <div className={className}>
        <div className={`pad-top- form-group-item`}>
          <Label htmlFor="url">{`Aktueller Pfad`}</Label>
          <InputText
            id="url"
            className="w3-block w3-border"
            value={url}
            readOnly
          />
        </div>
        <div className={`pad-top form-group-item ${error.newUrl && 'error-group'}`}>
          <Label htmlFor="newUrl">{`Pfad nach Verschiebung`}</Label>
          <InputText
            id="newUrl"
            className="w3-block w3-border"
            value={newUrl}
            readOnly
          />
          {error.newUrl && (<span className="w3-small error-group-text">{error.newUrl}</span>)}
        </div>
        <div className={`pad-top form-group-item`}>
          <Label>{`Verschieben nach`}</Label>
          <div style={style}>
            <Tree
              className="w3-block"
              selectionMode="single"
              selectionKeys={selectedNodeKey}
              onSelectionChange={event => this.onSelectionChange(event.value, filteredLinks)}
              value={[ tree ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
