import React from 'react';
import PropTypes from 'prop-types';
import UsernameChangeFormContent from '../components/UsernameChangeFormContent';
import { FormMenu } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { updateBasicData } from '../../../redux/actions/auth';
import { loadWorker } from '../../Worker/actions';

const mapStateToProps = null;/* (state) => {
  return {
    user: state.worker.currentWorker,
  };
}; */
const mapDispatchToProps = (dispatch) => ({
  updateData: (...args) => dispatch(updateBasicData(...args)),
  getWorker: (...args) => dispatch(loadWorker(...args)),
});

class UsernameChangeForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    updateData: PropTypes.func.isRequired,
    getWorker: PropTypes.func.isRequired,
    onClose: PropTypes.func
  }
  static defaultProps =  {
    className: ''
  }
  onSave = () => {
    const { user, updateData, onClose, getWorker } = this.props;
    const model = this.form.getModel();
    if (!model) return;
    console.log('>> updating basic data', model);
    updateData(model, () => {
      onClose();
      getWorker(user);
    });
  }
  onCancel = () => {
    const { onClose } = this.props;
    onClose && onClose();
  }
  render() {
    const { className, user } = this.props;
    return (
      <div className={`${className}`}>
        <UsernameChangeFormContent ref={ref => this.form = ref} model={user}/>
        <FormMenu
          className="margin-lg-top w3-border-top pad-big w3-light-grey"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={this.onCancel}
          saveBtnName="updateUsernameBtn"/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsernameChangeForm);