import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageFactory } from '../index';
import { connect } from "react-redux";
import BreadCrumbs, { SubLinks } from '../../BreadCrumbs';
import { isArchivePage } from '../components/Utils';
import ArchiveFactory from './ArchiveFactory';


const mapStateToProps = (state) => {
  return {
    links: state.staticPage.routes.organisation,
    labelMap: state.staticPage.labelMap.organisation,
  };
};
const mapDispatchToProps = null;


const OrganisationRoutes = ({ className = '', links, labelMap, Home }) => {
  const routes = links.map((link, index) => {
    if (index === 0) {
      return null;
    }
    const { url, label } = link;
    const data = link.data || {};
    const type = data ? data.type : link.type;
    const Component = isArchivePage(type)
      ? ArchiveFactory('organisation', url, 'Archiv: Organisation')
      : PageFactory(url, label, null, null, null, link, { links });
    return (
      <Route
        key={index}
        exact={!isArchivePage(type)}
        path={url}
        component={Component}
      />
    );
  });
  return (
    <div className={className}>
      <BreadCrumbs
        className="margin-big-top"
        labelMap={labelMap}
      />
      <Switch>
        {routes}
        <Route path="/*" component={Home}/>
      </Switch>
      <SubLinks exclude={['/organisation']}/>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationRoutes);
