
const specialChildren = [
  {
    to: "/organisation/firma",
    label: "Firmenprofil",
    isVisibleForFlag: "isWorkerLogisticsAssistant",
  },
  {
    to: "/organisation/firmen",
    label: "Firmenprofile",
    isVisibleForFlag: "isCompanyProfileViewer",
  },
  {
    to: "/organisation/mitarbeiter",
    label: "Mitarbeiterprofile",
    isVisibleForFlag: "isCompanyProfileViewer",
  },
  {
    to: "/organisation/messgeraete",
    label: "Messgeräte",
    isVisibleForFlag: "isDeviceManager",
    subMenu: {
      className: "nav-level-2",
      links: [
        {
          to: "/organisation/messgeraete/mitarbeiter",
          label: "Messgeräte",
          isVisibleForFlag: "isAdmin",
        },
        {
          to: "/organisation/messgeraete/admin",
          label: "Referenzmessgeräte",
          isVisibleForFlag: "isAdmin",
        },
      ],
    },
  },
  // { to: "/organisation/kommunikation", label: "Kommunikationsübersicht" },
  // { to: "/organisation/formulare", label: "Formulare" },
  // {
  //   to: "/organisation/news-service",
  //   label: "News Service",
  //   subMenu: {
  //     className: "nav-level-2",
  //     links: [
  //       {
  //         to: "/organisation/news-service/home",
  //         label: "Übersicht",
  //         isVisibleForFlag: "isAdmin",
  //       },
  //       {
  //         to: "/organisation/news-service/nachrichten",
  //         label: "News Einstellen",
  //         isVisibleForFlag: "isAdmin",
  //       },
  //       {
  //         to: "/organisation/news-service/protokolle",
  //         label: "News Versandprotokolle",
  //         isVisibleForFlag: "isAdmin",
  //       },
  //       {
  //         to: "/organisation/news-service/beitraege",
  //         label: "News Beiträge",
  //         isVisibleForFlag: "isAdmin",
  //       },
  //       {
  //         to: "/organisation/news-service/archiv",
  //         label: "Ad-hoc News Archiv",
  //         isVisibleForFlag: "isAdmin",
  //       },
  //     ],
  //   },
  // },
];

export const buildOrganisationTree = (tree) => {
  const organisationTree = JSON.parse(JSON.stringify(tree));
  organisationTree.children.splice(0, 0, ...specialChildren);
  return organisationTree;
};
