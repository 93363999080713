import * as Test from './test';
import moment from 'moment';
// import * as Auth from '../../redux/actions/auth';
import { getDebitorOrderItemsFromDebitorTaskMap } from './components';
import {
  getCurrentOrderWeek,
  createSortByLabel,
  isContractor,
  MODEM_ORDER_STATUS_IDS,
  MODEM_ORDER_STATUS_LABELS,
  isKDL,
  isSpecialKDL,
  isWorker
} from '../../components/Utils';
moment.locale('de');

export const LOAD_MODEMS = 'modems/all/load';
export const LOAD_MODEMS_SUCCESS = 'modems/all/load/success';
export const LOAD_MODEMS_FAILURE = 'modems/all/load/failure';

export const LOAD_MODEM = 'modems/load';
export const LOAD_MODEM_SUCCESS = 'modems/load/success';
export const LOAD_MODEM_FAILURE = 'modems/load/failure';

export const SAVE_MODEM = 'modems/save';
export const SAVE_MODEM_SUCCESS = 'modems/save/success';
export const SAVE_MODEM_FAILURE = 'modems/save/failure';

export const CANCEL_MODEM = 'modems/cancel';

export const DELETE_MODEM = 'modems/delete';
export const DELETE_MODEM_SUCCESS = 'modems/delete/success';
export const DELETE_MODEM_FAILURE = 'modems/delete/failure';

export const SELECT_MODEM = 'modems/select';
export const CANCEL_MODEM_SELECTION = 'modems/select/cancel';

export const CHECK_USAGE = 'modems/usage/check';
export const CHECK_USAGE_SUCCESS = 'modems/usage/check/success';
export const CHECK_USAGE_FAILURE = 'modems/usage/check/failure';

export const CHANGE_STATUS = 'modems/status/change';
export const CHANGE_STATUS_SUCCESS = 'modems/status/change/success';
export const CHANGE_STATUS_FAILURE = 'modems/status/change/failure';


export const LOAD_MODEM_ORDER_COMPANIES = 'modems/orders/companies/load';

// export const LOAD_MODEM_ORDER_REGIONS = 'modems/orders/regions/load';
// export const LOAD_MODEM_ORDER_REGIONS_SUCCESS = 'modems/orders/regions/load/success';
// export const LOAD_MODEM_ORDER_REGIONS_FAILURE = 'modems/orders/regions/load/failure';

export const LOAD_MODEM_ORDER_DEBITORS = 'modems/orders/debitors/load';
export const LOAD_MODEM_ORDER_DEBITORS_SUCCESS = 'modems/orders/debitors/load/success';
export const LOAD_MODEM_ORDER_DEBITORS_FAILURE = 'modems/orders/debitors/load/failure';

export const LOAD_DEBITOR_DETAILS = 'modems/orders/debitor/details/load';
export const LOAD_DEBITOR_DETAILS_SUCCESS = 'modems/orders/debitor/details/load/success';
export const LOAD_DEBITOR_DETAILS_FAILURE = 'modems/orders/debitor/details/load/failure';

export const SET_MODEM_ORDER_DEBITOR = 'modems/orders/debitor/set';

export const ADD_TO_CART = 'modems/orders/addToCart';

export const CANCEL_MODEM_DEBITOR_ORDER = 'modems/orders/debitors/cancel';

export const SAVE_MODEM_DEBITOR_ORDER = 'modems/orders/debitors/save';
export const SAVE_MODEM_DEBITOR_ORDER_SUCCESS = 'modems/orders/debitors/save/success';
export const SAVE_MODEM_DEBITOR_ORDER_FAILURE = 'modems/orders/debitors/save/failure';



export const LOAD_MODEM_ORDERS = 'modems/orders/all/load';
export const LOAD_MODEM_ORDERS_SUCCESS = 'modems/orders/all/load/success';
export const LOAD_MODEM_ORDERS_FAILURE = 'modems/orders/all/load/failure';

export const LOAD_MODEM_ORDERS_OVERVIEW = 'modems/orders/overview/load';
export const LOAD_MODEM_ORDERS_OVERVIEW_SUCCESS = 'modems/orders/overview/load/success';
export const LOAD_MODEM_ORDERS_OVERVIEW_FAILURE = 'modems/orders/overview/load/failure';

export const LOAD_MODEM_WEEKLY_ORDERS = 'modems/orders/weekly/load';
export const LOAD_MODEM_WEEKLY_ORDERS_SUCCESS = 'modems/orders/weekly/load/success';
export const LOAD_MODEM_WEEKLY_ORDERS_FAILURE = 'modems/orders/weekly/load/failure';

export const SET_MODEM_WEEKLY_REGIONAL_ORDERS = 'modems/orders/weekly/regional/set';

export const LOAD_MODEM_ORDER = 'modems/orders/load';
export const LOAD_MODEM_ORDER_SUCCESS = 'modems/orders/load/success';
export const LOAD_MODEM_ORDER_FAILURE = 'modems/orders/load/failure';

export const LOAD_ORDER_DEBITOR_DETAILS = 'modems/orders/order/debitor/details/load';
export const LOAD_ORDER_DEBITOR_DETAILS_SUCCESS = 'modems/orders/order/debitor/details/load/success';
export const LOAD_ORDER_DEBITOR_DETAILS_FAILURE = 'modems/orders/order/debitor/details/load/failure';

export const SAVE_ORDER_DEBITOR_CHANGES = 'modems/orders/save/changes';

export const UPDATE_MODEM_DEBITOR_ORDER = 'modems/orders/debitors/update';
export const UPDATE_MODEM_DEBITOR_ORDER_SUCCESS = 'modems/orders/debitors/update/success';
export const UPDATE_MODEM_DEBITOR_ORDER_FAILURE = 'modems/orders/debitors/update/failure';

export const SAVE_MODEM_ORDER = 'modems/orders/save';
export const SAVE_MODEM_ORDER_SUCCESS = 'modems/orders/save/success';
export const SAVE_MODEM_ORDER_FAILURE = 'modems/orders/save/failure';


export const CHANGE_MODEM_ORDER_STATUS = 'modems/orders/change/status';
export const CHANGE_MODEM_ORDER_STATUS_SUCCESS = 'modems/orders/change/status/success';
export const CHANGE_MODEM_ORDER_STATUS_FAILURE = 'modems/orders/change/status/failure';

export const LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS = 'modems/orders/archive/debitor/details/load';
export const LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_SUCCESS = 'modems/orders/archive/debitor/details/load/success';
export const LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_FAILURE = 'modems/orders/archive/debitor/details/load/failure';

export const LOAD_TASK_ORDER_DEBITORS = 'modems/orders/task/debitors/load';
export const LOAD_TASK_ORDER_DEBITORS_SUCCESS = 'modems/orders/task/debitors/load/success';
export const LOAD_TASK_ORDER_DEBITORS_FAILURE = 'modems/orders/task/debitors/load/failure';

export const APPROVE_TASK_ORDER_DEBITORS = 'modems/orders/task/debitors/save';
export const APPROVE_TASK_ORDER_DEBITORS_SUCCESS = 'modems/orders/task/debitors/save/success';
export const APPROVE_TASK_ORDER_DEBITORS_FAILURE = 'modems/orders/task/debitors/save/failure';

export const LOAD_TASK_ORDERS_AS_CSV = 'modems/orders/task/csv/load';
export const LOAD_TASK_ORDERS_AS_CSV_SUCCESS = 'modems/orders/task/csv/load/success';
export const LOAD_TASK_ORDERS_AS_CSV_FAILURE = 'modems/orders/task/csv/load/failure';

export const LOAD_LOXXES_ADDRESSES_AS_CSV = 'modems/orders/task/csv/load';
export const LOAD_LOXXES_ADDRESSES_AS_CSV_SUCCESS = 'modems/orders/task/csv/load/success';
export const LOAD_LOXXES_ADDRESSES_AS_CSV_FAILURE = 'modems/orders/task/csv/load/failure';

export const CANCEL_TASK_ORDER_DEBITORS = 'modems/orders/task/debitors/cancel';

export const LOAD_ORDER_AVIS_FILE = 'modems/orders/avis/load';
export const LOAD_ORDER_AVIS_FILE_SUCCESS = 'modems/orders/avis/load/success';
export const LOAD_ORDER_AVIS_FILE_FAILURE = 'modems/orders/avis/load/failure';

export const SET_SELECTED_MODEMS = 'modems/orders/selected/set';

export const CHANGE_ORDER_WEEK = 'modems/orders/change/week';
export const CHANGE_ORDER_WEEK_SUCCESS = 'modems/orders/change/week/success';
export const CHANGE_ORDER_WEEK_FAILURE = 'modems/orders/change/week/failure';



// function getCurrentOrderWeek() {
//   const date = moment();
//   // if day is monday and time is not yet 10:00 am, view last week orders
//   if (date.isoWeekday() === 1 && date.hour() < 10) {
//     date.day(-1);
//   }
//   date.locale('de');
//   return {
//     week: date.week(),
//     weekStr: date.format('ww'),
//     year: date.year()
//   };
// }


export const loadModems = (onSuccess) => {
  console.log('REDUX-ACTION: loadModems() ...');
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    const status = isAdmin ? 'all' : 'enabled';
    dispatch({
      types: [LOAD_MODEMS, LOAD_MODEMS_SUCCESS, LOAD_MODEMS_FAILURE],
      promise: (client) => client.get(`/modems/catalogs/0?status=${status}`),
      onSuccess: () => onSuccess && onSuccess()
    });
  };
  // return {
  //   types: [LOAD_MODEMS, LOAD_MODEMS_SUCCESS, LOAD_MODEMS_FAILURE],
  //   promise: (client) => client.get(`/modems/catalogs/0?status=all`),
  //   onSuccess: () => onSuccess && onSuccess()
  // };
};

export const loadModem = ({ id }) => {
  console.log(`REDUX-ACTION: loadModem(${id}) ...`);
  if (id === 'neu') {
    const modem = {
      name: '',
      status_id: 2
    };
    return { type: LOAD_MODEM_SUCCESS, result: modem };
  }
  if (Test.TESTING) {
    const modem = Test.getModem({ id });
    const error = !modem && { code: 'INVALID_ID' };
    return { type: (modem ? LOAD_MODEM_SUCCESS : LOAD_MODEM_FAILURE), result: (modem || error) };
  }
  return {
    types: [LOAD_MODEM, LOAD_MODEM_SUCCESS, LOAD_MODEM_FAILURE],
    promise: (client) => client.get(`/modems/catalogs/${id}?status=all`)
  };
};

export const saveModem = (modem, onSuccess) => {
  console.log(`REDUX-ACTION: saveModem()`, modem);
  if (Test.TESTING) {
    return (dispatch, getState) => {
      modem.user = getState().auth.user;
      Test.saveModem(modem);
      dispatch({ type: SAVE_MODEM_SUCCESS, modem });
      onSuccess && onSuccess();
    };
  }
  return {
    types: [SAVE_MODEM, SAVE_MODEM_SUCCESS, SAVE_MODEM_FAILURE],
    promise: (client) => client.put(`/modems/catalogs`, { data: modem }),
    onSuccess, modem
  }
};

export const cancelModem = () => ({ type: CANCEL_MODEM });

export const deleteModem = (modem, onSuccess) => {
  console.log(`REDUX-ACTION: deleteModem()`, modem);
  return {
    types: [DELETE_MODEM, DELETE_MODEM_SUCCESS, DELETE_MODEM_FAILURE],
    promise: (client) => client.del(`/modems/catalogs/${modem.id}/remove`),
    onSuccess: (dispatch) => {
      dispatch(loadModems());
      onSuccess && onSuccess();
    },
    modem
  };
}

/**
 * select a modem
 */
export const selectModem = ({ modem, purpose }) => {
  return {
    type: SELECT_MODEM,
    modem,
    purpose
  };
};

/**
 * Cancel a modem selection
 */
export const cancelModemSelection = () => {
  return {
    type: CANCEL_MODEM_SELECTION
  };
};

/**
 * Get all open orders that depend on a particular modem
 * @param {object} modem 
 */
export const checkModemUsage = (modem = {}) => {
  console.log('REDUX-ACTION checkModemUsage()', modem);
  return {
    types: [ CHECK_USAGE, CHECK_USAGE_SUCCESS, CHECK_USAGE_FAILURE ],
    promise: (client) => client.get(`/modems/catalogs/${modem.id}/inUsed`),
    modem
  };
};

/**
 * select a modem for deletion
 * @param {object} modem 
 */
export const selectModemForDeletion = (modem) => {
  return (dispatch) => {
    dispatch(checkModemUsage(modem));
    dispatch(selectModem({ modem, purpose: 'delete' }));
  }
};

/**
 * Change the status of a modem
 * @param {*} modem 
 * @param {*} onSuccess 
 */
export const changeModemStatus = (modem = {}, onSuccess) => {
  console.log('REDUX-ACTION changeModemStatus()', modem);
  const { id, status_id } = modem;
  const data = [{ id, status_id }];
  return {
    types: [ CHANGE_STATUS, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILURE ],
    promise: (client) => client.post(`/modems/catalogs/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadModems());
      onSuccess && onSuccess();
    },
    modem
  };
};

// Modem orders

/**
 * Load companies for which modem orders can be placed
 * for admin              : all KDLs and DLs
 * for logistics manager  : all companies for which they have the required role
 * @param {*} props - contains week (optional, current week is used if absent)
 * @param {*} onFailure - callback in case of failure
 */
export const loadModemOrderCompanies = (props = {}, onFailure) => {
  console.log(`REDUX-ACTION: loadModemOrderCompanies()`, props);
  return (dispatch, getState) => {
    const { isAdmin } = getState().auth;
    const {
      currentCompanyId,
      currentCompanyName,
      currentRegionName,
      currentWeek,
      savedWeek
    } = getState().modem;
    const weekData = getCurrentOrderWeek();
    const week = savedWeek || props.week || currentWeek || weekData.week;
    if (isAdmin) {
      const onSuccess = (_dispatch, _getState, result) => {
        const companyOptions = (result || [])
        // .filter(
        //   item => isKDL(item) || isSpecialKDL(item) // !isContractor(item)
        // )
        .map(item => ({
          label: item.name, value: item.id, id: item.id
        })).sort(createSortByLabel());
        dispatch({
          type: LOAD_MODEM_ORDER_COMPANIES,
          companyOptions,
          week
        });
        if (currentCompanyId != null && currentCompanyId !== 0) {
          console.log(' >> currentCompanyId is set => fetch regions and debitors:', week, currentCompanyId, currentRegionName);
          dispatch(loadModemOrderDebitors({
            // week,
            companyId: currentCompanyId,
            companyName: currentCompanyName,
            regionName: currentRegionName
          }));
        }
      };
      return dispatch({
        types: [
          'modems/orders/allCompanies',
          'modems/orders/allCompanies/success',
          'modems/orders/allCompanies/failure'
        ],
        promise: (client) => client.get(`/companies/0/summaries?related=true&form=true&kdl=true`),
        onSuccess
      });
    }
    const company = getState().company.company;
    if (!company) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return onFailure && onFailure();
    }
    const companyOptions = [{ label: company.name, value: company.id, id: company.id }];
    return dispatch({
      type: LOAD_MODEM_ORDER_COMPANIES,
      companyOptions
    });
  };
}

/**
 * Loads the regions and debitors of a chosen company and chosen week, showing which debitors already have orders in the shopping cart
 * @param {Object} props 
 * @param {Function} onFailure 
 */
export const loadModemOrderDebitors = (props = {}, onFailure) => {
  console.log(`REDUX-ACTION: loadModemOrderDebitors()`, props);
  return (dispatch, getState) => {
    const company = getState().company.company;
    if (!company) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return onFailure && onFailure();
    }
    const { currentWeek, currentYear, savedWeek } = getState().modem;
    const weekData = getCurrentOrderWeek();
    const week = savedWeek || props.week || currentWeek || weekData.week;
    let year = props.year || currentYear || weekData.year;
    if (!year) {
      const found = weekData.weekYears.find(item => item.week === week);
      if (found) {
        year = found.year;
      }
    }
    console.log(`REDUX-ACTION: loadModemOrderDebitors(${week},${year}, 
      companyId: ${props.companyId}, 
      company: ${props.companyName}, 
      region: ${props.regionName || '(not given)'})
    `);
    const companyId = props.companyId || getState().modem.savedCompanyId || company.id;
    const companyName = props.companyName || getState().modem.savedCompany || company.name;
    const regionName = props.regionName  || getState().modem.savedRegion || 'null';
    const debitorId = props.debitorId || 0;
    dispatch({
      types: [
        LOAD_MODEM_ORDER_DEBITORS,
        LOAD_MODEM_ORDER_DEBITORS_SUCCESS,
        LOAD_MODEM_ORDER_DEBITORS_FAILURE
      ],
      promise: (client) => client.get(`/modems/${companyId}/regions/null/debitors/${debitorId}?week=${week}&year=${year}`),
      currentWeek: week,
      currentYear: year,
      currentCompanyId: companyId,
      currentCompanyName: companyName,
      currentRegionName: regionName
    });
  };
};

/**
 * Set the selected debitor from the first page of the modem shop
 * @param {*} debitor 
 * @param {*} isArchive 
 */
export const setDebitor = (debitor, isArchive) => {
  return { type: SET_MODEM_ORDER_DEBITOR, result: { debitor }, isArchive };
};

/**
 * Loads the shopping cart details for the selected debitor (modem shop page 2)
 * @param {*} onFailure 
 */
export const loadCurrentDebitorDetails = (onFailure) => {
  console.log(`REDUX-ACTION: loadCurrentDebitorDetails() ...`);
  return (dispatch, getState) => {
    const shoppingCart = getState().modem.shoppingCart;
    if (!shoppingCart) {
      console.log('<< shoppingCart not set => aborting');
      return onFailure && onFailure();
    }
    // console.log('>>>> current shopping cart', shoppingCart);
    // checking if coming back from shopping cart overview
    const debitorLoaded = getState().modem.debitorLoaded;
    if (debitorLoaded) {
      console.log('<<:: debitor already loaded !!!');
      return ;
    }
    const { debitor } = shoppingCart;
    const company = getState().company.company;
    if (!company || !debitor) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return onFailure && onFailure();
    }
    const companyId = getState().modem.currentCompanyId || company.id;
    // const companyName = getState().modem.currentCompanyName || company.name;
    dispatch({
      types: [
        LOAD_DEBITOR_DETAILS,
        LOAD_DEBITOR_DETAILS_SUCCESS,
        LOAD_DEBITOR_DETAILS_FAILURE
      ],
      // promise: (client) => client.get(`/modems/${encodeURIComponent(companyName)}/shoppingCarts/${debitor.shoppingCartNumber || '0'}/debitors/${debitor.id}`)
      promise: (client) => client.get(`/modems/${encodeURIComponent(companyId)}/shoppingCarts/${debitor.shoppingCartNumber || '0'}/debitors/${debitor.id}`)
    });
  };
};

/**
 * Deprecated
 * @param {*} modem 
 */
export const addToCart = (modem) => {
  console.log('REDUX-ACTION: addToCart()', modem);
  return { type: ADD_TO_CART, result: modem };
};

/**
 * Deprecated
 * @param {*} debitorOrder 
 * @param {*} onSuccess 
 */
export const saveDebitorOrderInShoppingCart = (debitorOrder, onSuccess) => {
  console.log(`REDUX-ACTION: saveDebitorOrderInShoppingCart()`, debitorOrder);
  // const week = moment().week
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyName = getState().modem.currentCompanyName;
    const companyOptions = getState().modem.companyOptions || [];
    const found = companyOptions.find(item => item.name === companyName);
    if (!found) {
      console.error('COMPANY_ID_NOT_SET: companyOptions were not correctly initialized!');
      return onFailure && onFailure();
    }
    const companyId = found.id;
    console.log('>> preparing to save debitor debitorOrder: current company', companyName, companyId, found, companyOptions);
    const debitorOrderItems = debitorOrder.modems.map(debitorOrderItem => {
      const { item, ...others } = debitorOrderItem;
      return {
        ...others,
        identifier: debitorOrder.identifier || null,
        debitor_id: debitorOrder.debitor.id,
        region_id: debitorOrder.debitor.region_id,
        company_id: companyId,
        companyName,
        created_by_user_id: user.id
      }
    });
    console.log('>> saving modem debitorOrder:', debitorOrderItems);
    dispatch({
      types: [
        SAVE_MODEM_DEBITOR_ORDER,
        SAVE_MODEM_DEBITOR_ORDER_SUCCESS,
        SAVE_MODEM_DEBITOR_ORDER_FAILURE
      ],
      promise: (client) => client.put(`/modems/shoppingCarts/week`, { data: debitorOrderItems }),
      onSuccess,
      region: debitorOrder.debitor.region
    });
  }
};

/**
 * Saves the debitor details into the shopping cart
 * @param {*} param0 
 * @param {*} onSuccess 
 * @param {*} onFailure 
 */
export const addToCartAndSaveDebitorOrder = ({ catalogItems, debitorOrder }, onSuccess, onFailure) => {
  console.log(`REDUX-ACTION: addToCartAndSaveDebitorOrder()`, catalogItems, debitorOrder);
  return (dispatch, getState) => {
    // build shopping cart
    const oldCart = getState().shoppingCart;
    // const selectedDebitor = getState().selectedDebitor;
    const shoppingCart = { ...oldCart };
    for (const catalogItem of catalogItems) {
      if (!shoppingCart.modems) {
        shoppingCart.modems = [];
      }
      const index = shoppingCart.modems.findIndex(item => {
        const productName1 = (item.product_name || item.productName);
        const productName2 = (catalogItem.product_name || catalogItem.productName);
        return (productName1 === productName2);
      });
      if (index >= 0) {
        console.log('<< modem already in cart', index);
        // modem is already in cart => add values
        shoppingCart.modems[index] = catalogItem;
      } else {
        console.log('<< adding new modem to cart ...');
        shoppingCart.modems.push(catalogItem);
      }
    }

    // send
    const user = getState().auth.user;
    const companyName = getState().modem.currentCompanyName || companyName;
    const companyOptions = getState().modem.companyOptions || [];
    const found = companyOptions.find(item => item.label === companyName);
    if (!found) {
      console.error('COMPANY_ID_NOT_SET: companyOptions were not correctly initialized!');
      return onFailure && onFailure();
    }
    const companyId = found.id;
    console.log('>> preparing to save debitor debitorOrder: current company', companyOptions, companyName, companyId, debitorOrder);
    const debitorOrderItems = shoppingCart.modems.map(debitorOrderItem => {
      const { item, ...others } = debitorOrderItem;
      return {
        ...others,
        identifier: debitorOrder.identifier || null,
        debitor_id: debitorOrder.debitor.id,
        region_id: debitorOrder.debitor.region_id,
        company_id: companyId,
        companyName,
        created_by_user_id: user.id
      }
    });
    console.log('>> saving modem debitorOrder:', debitorOrderItems);
    dispatch({
      types: [
        SAVE_MODEM_DEBITOR_ORDER,
        SAVE_MODEM_DEBITOR_ORDER_SUCCESS,
        SAVE_MODEM_DEBITOR_ORDER_FAILURE
      ],
      promise: (client) => client.put(`/modems/shoppingCarts`, { data: debitorOrderItems }),
      onSuccess,
      region: debitorOrder.debitor.region
    });

  };
}

/**
 * Reset the modem shop for another order
 */
export const cancelModemOrder = () => ({ type: CANCEL_MODEM_DEBITOR_ORDER });



export const loadModemOrders = () => {
  console.log('REDUX-ACTION: loadModemOrders() ...');
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const company = getState().company.company;
    // OLD: const companyName = encodeURIComponent(company ? company.name : user.company);
    const companyId = encodeURIComponent(company ? company.id : user.companyId);
    dispatch({
      types: [
        LOAD_MODEM_ORDERS,
        LOAD_MODEM_ORDERS_SUCCESS,
        LOAD_MODEM_ORDERS_FAILURE
      ],
      promise: (client) => client.get(`/modems/${companyId}/orders/0/debitors/0`)
    });
  };
};

export const loadModemOrderOverview = () => {
  console.log('REDUX-ACTION: loadModemOrderOverview() ...');
  return {
    types: [
      LOAD_MODEM_ORDERS_OVERVIEW,
      LOAD_MODEM_ORDERS_OVERVIEW_SUCCESS,
      LOAD_MODEM_ORDERS_OVERVIEW_FAILURE
    ],
    promise: (client) => client.get(`/modems/orders/overviews`)
  };
};

/**
 * Loads the Modem Order Tasks for a particular week (default is current week)
 * @param {*} props 
 */
export const loadWeeklyModemOrders = (props = {}) => {
  console.log(`REDUX-ACTION: loadWeeklyModemOrders() props:`, props);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.log('<< Not authorized');
      return;
    }
    const taskWeek = getState().modem.taskWeek || { week: 0, year: 0 };
    const weekData = getCurrentOrderWeek();
    let week = props.week || taskWeek.week || weekData.week;
    let year = props.year || taskWeek.year || weekData.year;
    console.log(`REDUX-ACTION: loadWeeklyModemOrders(year: ${year}, week: ${week}, isTask: ${props.isTask}) ...`);
    // const user = getState().auth.user;
    // const company = getState().company.company;
    // const companyName = encodeURIComponent(company ? company.name : user.company);
    dispatch({
      types: [
        LOAD_MODEM_WEEKLY_ORDERS,
        LOAD_MODEM_WEEKLY_ORDERS_SUCCESS,
        LOAD_MODEM_WEEKLY_ORDERS_FAILURE
      ],
      promise: (client) => client.get(`/modems/orders/overview/years/${year}/week/${week}`),
      time: { week, year }
    });
  };
};

export const loadWeeklyRegionalModemOrders = ({ debitorKey }) => {
  console.log(`REDUX-ACTION: loadWeeklyRegionalModemOrders(${debitorKey}) ...`);
  return { type: SET_MODEM_WEEKLY_REGIONAL_ORDERS, debitorKey };
};

export const loadModemOrder = ({ id, isTask }) => {
  console.log(`REDUX-ACTION: loadModemOrder(id:${id}, isTask:${isTask}) ...`);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const company = getState().company.company;
    const companyId = isTask ? 0 : encodeURIComponent(company ? company.id : (user.companyId || 0));
    // OLD: const companyName = isTask ? 'null' : encodeURIComponent(company ? company.name : (user.company || 'null'));
    dispatch({
      types: [
        LOAD_MODEM_ORDER,
        LOAD_MODEM_ORDER_SUCCESS,
        LOAD_MODEM_ORDER_FAILURE
      ],
      promise: (client) => client.get(`/modems/${companyId}/orders/${id}`)
    });
  };
};

/**
 * Load the debitor details a particular task
 * @param {*} param0 
 */
export const loadCurrentOrderDebitorDetails = ({ onFailure, isTask }) => {
  console.log(`REDUX-ACTION: loadCurrentOrderDebitorDetails(isTask: ${isTask}) ...`);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    const modemOrder = getState().modem.modemOrder;
    if (!modemOrder || modemOrder.length === 0) {
      console.log('<< modemOrder not set => aborting');
      return onFailure && onFailure();
    }
    // check if coming back from order overview
    const debitorLoaded = getState().modem.debitorLoaded;
    if (debitorLoaded) {
      console.log('<<:: debitor already loaded !!!');
      return ;
    }

    const debitor = getState().modem.selectedDebitor;
    const company = getState().company.company;
    console.log('>>>> current company', company, 'current debitor:', debitor);
    if (!company || !debitor) {
      console.error('COMPANY_NOT_SET: session was not correctly initialized!');
      return onFailure && onFailure();
    }
    const companyId = (isTask && isAdmin) ? 0 : company.id;
    // OLD: const companyName = (isTask && isAdmin) ? 'null' : company.name;
    if (isTask) {
      const modems = getState().modem.modems;
      if (!modems || modems.length === 0) {
        // loading modems
        dispatch(loadModems());
      }
    }
    console.log("loadCurrentOrderDebitorDetails() companyId:", companyId, company)
    dispatch({
      types: [
        LOAD_ORDER_DEBITOR_DETAILS,
        LOAD_ORDER_DEBITOR_DETAILS_SUCCESS,
        LOAD_ORDER_DEBITOR_DETAILS_FAILURE
      ],
      promise: (client) => client.get(`/modems/${companyId}/orders/${modemOrder[0].number}/debitors/${debitor.id}`)
    });
  };
};

export const saveOrderDebitorChanges = (modems) => {
  console.log('REDUX-ACTION: saveOrderDebitorChanges()', modems);
  return { type: SAVE_ORDER_DEBITOR_CHANGES, result: modems };
};

export const updateOrderDebitor = ({ debitor, modems, isTask }, onSuccess) => {
  console.log(`REDUX-ACTION: updateOrderDebitor()`, modems, isTask);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    let companyName = 'null';
    let companyId = null;
    if (!isTask) {
      const company = getState().company.company;
      companyName = company ? company.name : user.company;
      companyId = company ? company.id : null;
      if (!company) {
        const role = user.roles.find(item => item.name !== '' && item.name !== null);
        companyId = role.companyId;
      }
    }
    console.log('>> preparing to update an existing debitor order: current company', companyName, companyId);
    let orderNumber = null;
    const debitorOrderItems = modems.map(debitorOrderItem => {
      const { item, ...others } = debitorOrderItem;
      orderNumber = item.orderNumber;
      return {
        ...others,
        // identifier: debitor.identifier || null,
        number: orderNumber,
        orderNumber,
        debitor_id: debitor.id,
        region_id: debitor.region_id,
        company_id: isTask ? item.company_id : companyId,
        companyName,
        created_by_user_id: isTask ? item.created_by_user_id : user.id
      }
    });
    console.log('>> saving modem debitorOrder:', debitorOrderItems);
    dispatch({
      types: [
        SAVE_MODEM_DEBITOR_ORDER,
        SAVE_MODEM_DEBITOR_ORDER_SUCCESS,
        SAVE_MODEM_DEBITOR_ORDER_FAILURE
      ],
      promise: (client) => client.put(`/modems/orders/null`, { data: debitorOrderItems }),
      onSuccess,
      orderNumber
    });
  }
};

/**
 * Save a modem order for a complete region (modem shop page 1)
 * @param {*} regionDebitors 
 * @param {*} onSuccess 
 * @param {*} description 
 */
export const saveModemOrder = (regionDebitors, onSuccess, description = '') => {
  console.log(`REDUX-ACTION: saveModemOrder()`, regionDebitors, description);
  return (dispatch, getState) => {
    let regionId = null;
    let companyId = null;
    if (regionDebitors && regionDebitors.length > 0) {
      regionId = regionDebitors[0].region_id;
      companyId = regionDebitors[0].company_id;
    }
    const { currentWeek, currentYear } = getState().modem;
    const data = {
      company_id: companyId,
      region_id: regionId,
      description
    };
    dispatch({
      types: [
        SAVE_MODEM_ORDER,
        SAVE_MODEM_ORDER_SUCCESS,
        SAVE_MODEM_ORDER_FAILURE
      ],
      promise: (client) => client.put(`/modems/shoppingCarts/orders?week=${currentWeek}&year=${currentYear}`, { data }),
      onSuccess: () => onSuccess()
    });
  }
};

export const loadArchiveOrderDebitorDetails = ({ orderNumber, debitorId }) => {
  console.log(`REDUX-ACTION: loadArchiveOrderDebitorDetails() ...`);
  return {
    types: [
      LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS,
      LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_SUCCESS,
      LOAD_ARCHIVE_ORDER_DEBITOR_DETAILS_FAILURE
    ],
    promise: (client) => client.get(`/modems/0/orders/${orderNumber}/debitors/${debitorId}`)
  }
};

// ADMIN Tasks

export const setSelection = (selected) => {
  console.log(`REDUX-ACTION: setSelection()`, selected);
  return { type: SET_SELECTED_MODEMS, selected };
};

export const lockModemOrders = (selected) => {
  console.log(`REDUX-ACTION: lockModemOrders() selected`, selected);
  return (dispatch, getState) => {
    const debitors = selected || getState().modem.modemOrderWeeklyOverview || [];
    const newOrders = debitors.filter(item => (item.status_id === 1)).map(item => ({
      number: item.number,
      status: 'In Bearbeitung'
    }));
    console.log(`<< Changing the status of ${newOrders.length} orders`, newOrders);
    dispatch({
      types: [
        CHANGE_MODEM_ORDER_STATUS,
        CHANGE_MODEM_ORDER_STATUS_SUCCESS,
        CHANGE_MODEM_ORDER_STATUS_FAILURE
      ],
      promise: (client) => client.post(`/modems/orders/changeStatus`, { data: newOrders }),
      onSuccess: () => dispatch(loadWeeklyModemOrders({ isTask: true })),
      selected
    });
  };
};

export const loadAllOrderDebitorDetails = ({ onFailure }) => {
  console.log(`REDUX-ACTION: loadAllOrderDebitorDetails() ...`);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('NOT PERMITTED => aborting ...');
      return onFailure && onFailure();
    }
    const fetch = () => {
      const selected = getState().modem.selectedModems;
      const data = /* (selected || []) */selected.filter(item => {
        if (item.statusName !== undefined) {
          console.log('<< comparing', item.statusName, 'with', MODEM_ORDER_STATUS_LABELS[MODEM_ORDER_STATUS_IDS.PROCESSING]);
          return item.statusName === MODEM_ORDER_STATUS_LABELS[MODEM_ORDER_STATUS_IDS.PROCESSING];
        }
        if (item.status_id !== undefined) {
          return item.status_id === MODEM_ORDER_STATUS_IDS.PROCESSING;
        }
        return true;
      }).map(item => ({
        number: item.orderNumber,
        status: item.status || MODEM_ORDER_STATUS_LABELS[item.status_id]
      }));
      const taskWeek = getState().modem.taskWeek || { week: 0, year: 0 };
      const weekData = getCurrentOrderWeek();
      let week = taskWeek.week || weekData.week;
      let year = taskWeek.year || weekData.year;
      // console.log(' >> selected modem orders:', data, 'selected', selected);
      dispatch({
        types: [
          LOAD_TASK_ORDER_DEBITORS,
          LOAD_TASK_ORDER_DEBITORS_SUCCESS,
          LOAD_TASK_ORDER_DEBITORS_FAILURE
        ],
        promise: (client) => client.post(`/modems/0/orders/0/toApprove?week=${week}&year=${year}`, { data })
      });
    }
    const modems = getState().modem.modems;
    if (!modems || modems.length === 0) {
      // loading modems
      dispatch(loadModems(fetch));
    } else {
      fetch();
    }
  };
};

export const saveOrdersAndSendToLoxxess = ({ debitorTaskMap, onSuccess, sendOnly }) => {
  console.log(`REDUX-ACTION: saveOrdersAndSendToLoxxess() ...`);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('NOT PERMITTED => aborting ...');
      return onFailure && onFailure();
    }
    let orderItems = getDebitorOrderItemsFromDebitorTaskMap(debitorTaskMap);
    orderItems = orderItems.map(item => ({
      ...item,
      status_id: 3
    }));
    // console.log('###>> saving task order items:', orderItems);
    dispatch({
      types: [
        APPROVE_TASK_ORDER_DEBITORS,
        APPROVE_TASK_ORDER_DEBITORS_SUCCESS,
        APPROVE_TASK_ORDER_DEBITORS_FAILURE
      ],
      promise: (client) => client.put(`/modems/orders/${sendOnly ? 'null' : 'approve'}`, { data: orderItems }),
      onSuccess: (_dispatch) => {
        // !sendOnly && _dispatch(loadLoxxessOrderCSVFile());
        onSuccess && onSuccess();
      },
      sendOnly
    });
  }
}

export const loadLoxxessOrderCSVFile = (selected) => {
  console.log(`REDUX-ACTION: loadLoxxessOrderCSVFile()`, selected);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('NOT PERMITTED => aborting ...');
      return onFailure && onFailure();
    }
    dispatch({
      types: [
        LOAD_TASK_ORDERS_AS_CSV,
        LOAD_TASK_ORDERS_AS_CSV_SUCCESS,
        LOAD_TASK_ORDERS_AS_CSV_FAILURE
      ],
      promise: (client) => {
        if (selected) {
          const data = selected.map(item => ({
            number: item.orderNumber,
            status: item.status || MODEM_ORDER_STATUS_LABELS[item.status_id]
          }));
          return client.put(`/modems/orders/0/load/selected/csv`, { data });
        }
        return client.get(`/modems/orders/0/load/csv`);
      }
    });
  };
}

export const loadLoxxessAddressCSVFile = () => {
  console.log(`REDUX-ACTION: loadLoxxessAddressCSVFile() ...`);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('NOT PERMITTED => aborting ...');
      return; // onFailure && onFailure();
    }
    dispatch({
      types: [
        LOAD_LOXXES_ADDRESSES_AS_CSV,
        LOAD_LOXXES_ADDRESSES_AS_CSV_SUCCESS,
        LOAD_LOXXES_ADDRESSES_AS_CSV_FAILURE
      ],
      promise: (client) => client.get(`/companies/addresses/csv`),
    });
  };
}

export const loadOrderAVISFile = ({ orderNumber, avis_path }) => {
  console.log(`REDUX-ACTION: loadOrderAVISFile(${orderNumber}) ...`);
  return { type: LOAD_ORDER_AVIS_FILE_SUCCESS, orderNumber, avis_path };
  // return {
  //   types: [ LOAD_ORDER_AVIS_FILE, LOAD_ORDER_AVIS_FILE_SUCCESS, LOAD_ORDER_AVIS_FILE_FAILURE],
  //   promise: (client) => client.get(`/modems/orders/${orderNumber}/load/avis`),
  //   orderNumber
  //   // onSuccess
  // };
}

export const cancelTaskOrderDebitorApproval = () => ({ type: CANCEL_TASK_ORDER_DEBITORS });

export const changeOrderWeek = (orders) => {
  console.log(`REDUX-ACTION: changeOrderWeek()`, orders);
  return (dispatch, getState) => {
    const isAdmin = getState().auth.isAdmin;
    if (!isAdmin) {
      console.error('NOT PERMITTED => aborting ...');
      return; // onFailure && onFailure();
    }
    if (!orders || orders.length === 0) {
      console.error('NO ORDERS to move => aborting ...');
      return;
    }
    const data = orders.map(order => ({
      ...order,
      number: order.orderNumber || order.number,
      week: Number(order.week),
      year: Number(order.year),
    }));
    dispatch({
      types: [
        CHANGE_ORDER_WEEK,
        CHANGE_ORDER_WEEK_SUCCESS,
        CHANGE_ORDER_WEEK_FAILURE
      ],
      promise: (client) => client.post(`/modems/orders/changeWeek`, { data }),
      onSuccess: (dispatch) => dispatch(loadWeeklyModemOrders({ isTask: true })),
      week: orders[0].week
    });
  }; 
}
