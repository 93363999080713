import React from 'react';
import PropTypes from 'prop-types';
import DisplayDays from './DisplayDays';
import DisplayWeeks from './DisplayWeeks';
import DisplayMonths from './DisplayMonths';
import DisplayAll from './DisplayAll';
import DisplayDelphi from './DisplayDelphi';

import { connect } from "react-redux";
import { cancelFilter } from '../actions/success';

const mapStateToProps = (state) => {
	return {
    filterType: state.achievement.filterType,
    topLevelDisplay: state.achievement.topLevelDisplay,
    username: state.achievement.username
	};
};
const mapDispatchToProps = (dispatch) => ({
  cancelFilter: (...args) => dispatch(cancelFilter(...args)),
});

class Successes extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isManagement: PropTypes.bool,
    filterType: PropTypes.string,
    topLevelDisplay: PropTypes.string,
    username: PropTypes.string,
    cancelFilter: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  componentWillUnmount() {
    this.props.cancelFilter();
  }
  getDisplay = (type, display) => {
    if (type === 'delphi') {
      return DisplayDelphi;
    }
    switch (display) {
      case 'all': return DisplayAll;
      case 'YYYY_MM': return DisplayMonths;
      case 'YYYY_KW': return DisplayWeeks;
      case 'TT.MM.YYYY': return DisplayDays;
      default: break;
    }
    return null;
  }
  render() {
    // console.log('Sucessess.render()', this.props);
    const { className, filterType, topLevelDisplay, username, isManagement } = this.props;
    // in management mode, there must a chosen username
    if (isManagement && !username) {
      return null;
    }
    // filterType and topLevelDisplay must be set
    if (!filterType || !topLevelDisplay) {
      return null;
    }
    const Component = this.getDisplay(filterType, topLevelDisplay);
    if (!Component) {
      return null;
    }
    const props = filterType === 'delphi' ? { key: Math.random() } : {};
    return (
      <div className={`${className}`}>
        <Component {...props}/>
      </div>
    );
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Successes);
