import React from 'react';
import PropTypes from 'prop-types';
// import { Dialog } from 'primereact/dialog';
import { PrimaryButtonLink, PrimaryButton } from '../../../components/Buttons';
import { connect } from 'react-redux';
import { exportUserDevices } from '../actions';

export const DeviceListMenu = ({ className/* , onAdd */ }) => {
  return (
    <div className={`device list-menu ${className}`}>
      <PrimaryButtonLink to={`/wizard/organisation/messgeraet/admin/neu`}>{`Referenzmessgerät hinzufügen`}</PrimaryButtonLink>
    </div>
  );
};

// export const UserDeviceListMenu = ({ className/* , onAdd */ }) => {
//   return (
//     <div className={`user-device list-menu ${className}`}>
//       <PrimaryButtonLink to={`/wizard/organisation/messgeraet/mitarbeiter/neu`}>{`Messgerät hinzufügen`}</PrimaryButtonLink>
//     </div>
//   );
// };
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getUserDevicesAsCSV: (...args) => dispatch(exportUserDevices(...args)),
});
class UserDeviceListMenuClass extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    getUserDevicesAsCSV: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  onExportUserDevices = (event) => {
    if (event) event.preventDefault();
    this.props.getUserDevicesAsCSV();
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`userDevice list-menu ${className}`}>
        <PrimaryButtonLink to={`/wizard/organisation/messgeraet/mitarbeiter/neu`} name="addUserDeviceBtn">
          {`Messgerät hinzufügen`}
        </PrimaryButtonLink>
        <PrimaryButton className="margin-lft" name="exportUserDeviceBtn" onClick={this.onExportUserDevices}>
          {'CSV Exportieren'}
        </PrimaryButton>
      </div>
    );
  }
}
export const UserDeviceListMenu = connect(mapStateToProps, mapDispatchToProps)(UserDeviceListMenuClass);

