import React from 'react';
import PropTypes from 'prop-types';
import ProductGroupFilter from '../components/ProductGroupFilter';

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadProductGroups, loadProductSales, clearProductSales } from "../actions/success";

const frontload = async (props) => {
	await props.init({});
};
const mapStateToProps = (state) => {
	return {
    filterType: state.achievement.filterType,
    productGroups: state.achievement.productGroups,
    username: state.achievement.username,
    interval: state.achievement.interval,
	};
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(loadProductGroups(...args)),
  getSales: (...args) => dispatch(loadProductSales(...args)),
  clearSales: (...args) => dispatch(clearProductSales(...args))
});

class ProductFilterPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isManagement: PropTypes.bool,
    filterType: PropTypes.string.isRequired,
    productGroups: PropTypes.array,
    username: PropTypes.string,
    interval:  PropTypes.shape({}),
    init: PropTypes.func.isRequired,
    getSales: PropTypes.func.isRequired,
    clearSales: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  constructor() {
    super();
    this.state = {
      isFormValid: false
    };
  }
  onFilter = (model) => {
    if (!model) {
      return;
    }
    this.props.getSales(model);
  }
  render() {
    const {
      className, filterType, productGroups,
      clearSales, username, isManagement,
      interval
    } = this.props;
    // console.log('ProductFilterPanel.render()', interval);
    if (filterType !== 'range') {
      return null;
    }
    return (
      <div className={`${className} pos-rel`}>
        <ProductGroupFilter
          ref={ref => this.filter = ref}
          productGroupOptions={productGroups}
          username={username}
          interval={interval}
          onSave={this.onFilter}
          onCancel={clearSales}
        />
        {isManagement && (<div className={`w3-modal-local w3-${username ? 'hide' : 'show'}`}/>)}
      </div>
    );
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(ProductFilterPanel)
);