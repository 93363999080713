import React from 'react';
import PropTypes from 'prop-types';
import DebitorItem from '../../components/DebitorItem';
import { Label, InputText } from '../../../../components/Inputs';
import { PageHeight40, createSortByAttr } from '../../../../components/Utils';
import { WizardButtonPanel } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadModemOrder, setDebitor } from '../../actions';
import DescriptionForm from '../../components/DescriptionForm';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getModemOrder({ id });
};
const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    modemOrder: state.modem.modemOrder
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModemOrder: (...args) => dispatch(loadModemOrder(...args)),
  setDebitor: (...args) => dispatch(setDebitor(...args)),
});

class OrderDebitorList extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    // actions 
    getModemOrder: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = { debitor: null };
  }
  validate = () => {
    const { debitor } = this.state;
    if (!debitor) {
      return null;
    }
    return { ...debitor };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  setDebitor = () => {
    const { history, match, setDebitor } = this.props;
    const id = match.params.id;
    setDebitor(this.getModel());
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  onSelectDebitor = (debitor) => {
    console.log('>> Debitor double clicked', debitor);
    const { history, match, setDebitor } = this.props;
    const id = match.params.id;
    setDebitor({ ...debitor });
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  render() {
    console.log('OrderDebitorList.render()', this.props);
    const { className, modemOrder, cancelOrder } = this.props;
    if (!modemOrder || modemOrder.length === 0) {
      return null;
    }
    const refOrderItem = modemOrder[0];
    const currentDebitor = this.state.debitor;
    console.log('OrderDebitorList.render()', modemOrder);
    // debitors
    let description = '';
    let isLocked = false;
    const debitorMarkup = modemOrder.sort(
      createSortByAttr('debitorKey')
    ).map((item, index) => {
      if (item.status_id !== 1 && item.debitor) {
        isLocked = true;
        item.debitor.status_id = item.status_id;
      }
      if ( index === 0) {
        description = item.description;
      }
      // use address copy if present
      if (item.debitor_address && item.debitor) {
        try {
          item.debitor.debitorAddress = JSON.parse(item.debitor_address);
          // console.log('OrderDebitorList.render() debitorAddress', item.debitor.debitorAddress);
        } catch (error) {
          console.error('<< Debitor Address could not be parsed:', error, item);
        }
      }
      return item.debitor && (
        <div key={index} className="w3-col s3 pad-lr">
          <DebitorItem
            id={`debitorBtn${index}`}
            className={`w3-block margin-big-top`}
            debitor={item.debitor}
            onClick={(debitor) => this.setState({ debitor })}
            onDoubleClick={this.onSelectDebitor}
            selected={currentDebitor && item.debitor.id === currentDebitor.id}
            hideRegion
            isComplete
          />
        </div>
      );
    });
    const week = `${refOrderItem.week < 10 ? '0' : ''}${refOrderItem.week}`;
    const regionMarkup = refOrderItem.debitor && (
      <div className="w3-row neg-margin-lr">
        <div className="w3-col s4 pad-lr pad-top-">
          <Label htmlFor="week">{`Kalenderwoche`}</Label>
          <InputText
            id="week"
            className="w3-block w3-border"
            value={week}
            disabled
          />
        </div>
        <div className="w3-col s4 pad-lr pad-top-">
          <Label htmlFor="company">{`Firma`}</Label>
          <InputText
            id="company"
            className="w3-block w3-border"
            value={refOrderItem.companyName}
            disabled
          />
        </div>
        <div className="w3-col s4 pad-lr pad-top-">
          <Label htmlFor="region">{`Region`}</Label>
          <InputText
            id="region"
            className="w3-block w3-border"
            value={refOrderItem.debitor.region}
            disabled
          />
        </div>
      </div>
    );
    return (
      <div className={`debitor-list`}>
        <div className={`${className}`}>
          <div className="pad w3-border w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{`Debitoren (KW${week})`}</h3>
          </div>
          <div className="w3-border no-border-top pad-big">
            <PageHeight40>
              {regionMarkup}
              <div className="neg-margin-big-lr margin-big-top w3-border-top pad-big-lr">
                <div className="w3-row neg-margin-lr">
                  {debitorMarkup}
                </div>
              </div>
              <DescriptionForm
                ref={ref => this.form = ref}
                model={{ description }}
                className="pad-big-top"
                disabled
              />
            </PageHeight40>
          </div>
        </div>
        <WizardButtonPanel
          className="margin-big-top w3-border pad-big w3-light-grey"
          onNext={(currentDebitor === null) ? null : this.setDebitor}
          onCancel={cancelOrder}
          nextLabel={`Debitor ${isLocked ? 'ansehen' : 'bearbeiten'}`}
          disableIfAbsent
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(OrderDebitorList)
);
