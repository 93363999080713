
export const DelphiConnectionPermissionOptions = [
  { label: 'KDL D-Mobile Tiefb', value: 'KDL D-Mobile Tiefb' },
  { label: 'KDL D-Mobile NE4', value: 'KDL D-Mobile NE4' },
  { label: 'KDL D-Mobile NE3', value: 'KDL D-Mobile NE3' },
  { label: 'KDL schreibend', value: 'KDL schreibend' },
  { label: 'KDL lesend', value: 'KDL lesend' },
  { label: 'DL schreibend', value: 'DL schreibend' },
  { label: 'DL lesend', value: 'DL lesend' },
  { label: 'DL D-Mobile', value: 'DL D-Mobile' },
];

export const DelphiConnectionPermissionMap = {
  'ew': 'Aussendienst IPO, TB',
  'em': 'Aussendienst Mobile',
  'ro': 'Innendienst Lesend',
  'rw': 'Innendienst Schreibend',
};

export const DEFAULT_DELPHI_DESCRIPTION = 'Kennung/Begründung: ';


/**
 * Determines the main Company (Beschäftigunfsfirma) and all other visible KDLs
 * @param {bool} userChecked 
 * @param {bool} userExists 
 * @param {Object} model 
 */
export const getMainAndVisibleCompaniesForWorkerProfile = (userChecked, userExists, model) => {
  let mainCompany = null;
  const companies = [];
  if (userChecked) {
    // creating new person
    if (userExists) {
      companies.push({ ...model.companies[0] });
    } else {
      mainCompany = { ...model.companies[0] };
    }
  } else {
    for (const company of model.companies) {
      console.log('>> BasicDataForm: checking company', company, 'userChecked', userChecked, 'userExists', userExists);
      if (mainCompany === null && company.role) {
        mainCompany = { ...company };
      } else {
        companies.push({ ...company });
      }
    }
  }
  return {
    mainCompany,
    companies,
  };
}

function didItemCompanyChange(itemCompanyName, isCompanyChanged, companyChanges) {
  if (!isCompanyChanged) {
    return false;
  }
  const changeItem = companyChanges.find(changeItem => changeItem.oldName === itemCompanyName);
  if (changeItem) {
    console.log('<< item company changed from', changeItem.oldName, 'to', changeItem.newName)
  }
  return (changeItem != undefined);
}


function copyCompanyBasedItem (item, isCompanyChanged) {
  // return item;
  // delete id if company was changed
  const data = JSON.parse(JSON.stringify(item));
  if (isCompanyChanged) {
    // delete id if present
    if (data.id !== undefined) {
      console.log('<< company was changed => deleting id from', data);
      delete data.id;
    }
    // copy original status id if present
    if (item.oldStatusId !== undefined) {
      data.status_id = item.oldStatusId;
    }

  }
  return data;
}

export const setCompanyModel = (model, items, companyKey, itemCompanyNameKey = 'company', isCompanyChanged, companyChanges) => {
  if (model && items) {
    for (const item of items) {
      let found = false;
      // put every item in its respective company
      for (const key in model.companies) {
        //TODO item[itemCompanyNameKey] funktioniert nicht bei ["A", "B"]
        if (model.companies[key].name === item[itemCompanyNameKey]) {
          if (!model.companies[key][companyKey]) {
            model.companies[key][companyKey] = [];
          }
          // check if name of target company was changed
          const isItemCompanyChanged = didItemCompanyChange(item[itemCompanyNameKey], isCompanyChanged, companyChanges);
          // delete id if company was changed
          const data = copyCompanyBasedItem(item, isItemCompanyChanged);
          // add item
          model.companies[key][companyKey].push(data);
          found = true;
          break;
        } /* else {
        // WORKAROUND: funkioniert wahrscheinlich, wenn der Mitarbeiter nur bei einer Person arbeitet
          if (!model.companies[key][companyKey]) {
            model.companies[key][companyKey] = [];
          }
          model.companies[key][companyKey].push(item);
        } */
      }
      // abort if item could not be assigned to a company
      if (!found) {
        return item;
      }
    }
  }
  return null;
}


export const setCompanyModelDelphi = (model, delphiConnections, isCompanyChanged, companyChanges) => {
  // console.log('<< setCompanyModelDelphi()', model, delphiConnections);
  if (model && delphiConnections) {
    for (let index = 0; index < delphiConnections.length; index ++) {
      const delphiConnection = delphiConnections[index];
      let found = false;
      // console.log('>> >> searching for', delphiConnection, 'in', model.companies);
      for (const key in model.companies) {
        if (model.companies[key].name === delphiConnection.companyName
            && model.companies[key].contractGiver === delphiConnection.parentCompanyName) {
          // console.log('<< << found company:', model.companies[key]);
          if (!model.companies[key].delphiConnections) {
            model.companies[key].delphiConnections = [];
          }
          // check if name of target company was changed
          const isItemCompanyChanged = didItemCompanyChange(delphiConnection.companyName, isCompanyChanged, companyChanges);
          // delete id if company was changed
          const data = copyCompanyBasedItem(delphiConnection, isItemCompanyChanged);
          // add item
          model.companies[key].delphiConnections.push(data);
          found = true;
          break;
        }
      }
      // abort if delphiConnection could not be assigned to a company
      if (!found) {
        return delphiConnection;
      }
    }
    // delphiConnections.forEach(delphiConnection => {
    //   // console.log('>> >> searching for', delphiConnection, 'in', model.companies);
    //   for (const key in model.companies) {
    //     if (model.companies[key].name === delphiConnection.companyName
    //         && model.companies[key].contractGiver === delphiConnection.parentCompanyName) {
    //       // console.log('<< << found company:', model.companies[key]);
    //       if (!model.companies[key].delphiConnections) {
    //         model.companies[key].delphiConnections = [];
    //       }
    //       model.companies[key].delphiConnections.push(delphiConnection);
    //       return true;
    //     }
    //   }
    // });
  }
  return null;
}

export const getCompanyModel = (model, companyKey, visibleCompanies) => {
  let items = [];
  if (model) {
    const companies = visibleCompanies || model.companies;
    companies.forEach(company => {
      if (company[companyKey] && company[companyKey].length > 0) {
        items = items.concat(company[companyKey]);
      }
    });
    // filter double elements
    const filtered = [];
    items.forEach(item => {
      if (item.id) {
        const found = filtered.find(element => element.id === item.id);
        if(!found) {
          filtered.push(item);
        } else {
          console.log(`<< double ${companyKey} found:`, found);
        }
      } else {
        filtered.push(item);
      }
    });
    items = filtered;
  }
  return items;
};

const umlautMap = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};

export function replaceUmlauts(str) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
      (a) => umlautMap[a]
    );
}

export const generateUsername = ({ firstname, lastname }) => {
  const firstName = replaceUmlauts(firstname.split(' ').join(''));
  const lastName = replaceUmlauts(lastname.split(' ').join(''));
  return `${lastName}.${firstName}`;
};

export const getITConnections = (model) => {
  // ad connection
  let adConnection = {};
  if (model.adConnections && model.adConnections.length > 0) {
    const { id, has_connection, username, password, remark, status_id, created_date, modified_date } = model.adConnections[0];
    adConnection = {
      ad_id: id,
      adConnection: has_connection,
      adUsername: username,
      adPassword: password,
      adRemark: remark,
      adStatus_id: status_id,
      adCreated_date: created_date,
      adModified_date: modified_date
    };
  }
  // pt connection
  let ptConnection = {};
  if (model.ptConnections && model.ptConnections.length > 0) {
    const { id, has_connection, username, password, remark, status_id, created_date, modified_date } = model.ptConnections[0];
    ptConnection = {
      pt_id: id,
      ptConnection: has_connection,
      ptUsername: username,
      ptPassword: password,
      ptRemark: remark,
      ptStatus_id: status_id,
      ptCreated_date: created_date,
      adModified_date: modified_date
    };
  }
  return { ...adConnection, ...ptConnection };
};
