import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  PageTitle,
  createSortByAttr,
  toUIDate,
  PRODUCT_ORDER_STATUS_LABELS,
  PRODUCT_ORDER_STATUS_IDS,
  scrollToTop,
  TableEmptyMessage
} from '../../../../components/Utils';
import KeyOrderMenu from '../../components/keys/KeyOrderMenu';

import { connect } from 'react-redux';
import {
  setSelectedKeyOrders,
  lockKeyOrders,
  exportKeyOrders,
  closeKeyOrder,
  deleteKeyOrder
} from '../../actions/key';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { ViewIconButton, EditIconButton, DeleteIconButton } from '../../../../components/Buttons';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    keyOrders: state.task.keyOrders,
    selection: state.task.selectedKeyOrders
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedKeyOrders(...args)),
  lockOrders: (...args) => dispatch(lockKeyOrders(...args)),
  exportOrders: (...args) => dispatch(exportKeyOrders(...args)),
  closeOrder: (...args) => dispatch(closeKeyOrder(...args)),
  deleteOrder: (...args) => dispatch(deleteKeyOrder(...args)),
});

class KeyOrderTaskOverview extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    keyOrders: PropTypes.array,
    selection: PropTypes.array,
    lockOrders: PropTypes.func.isRequired,
    exportOrders: PropTypes.func.isRequired,
    closeOrder: PropTypes.func.isRequired,
    deleteOrder: PropTypes.func.isRequired,
    rows: PropTypes.number,
    namePrefix: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    namePrefix: '',
    rows: 10
  }
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    }
  }
  componentDidMount() {
    scrollToTop();
  }
  onShowDetails = (keyOrder) => {
    console.log('KeyOrderList.onShowDetails() ...', keyOrder);
    const { history } = this.props;
    history.replace(`/aufgaben/schliessmittel/uebersicht/${keyOrder.id}`);
  }
  onDelete = (keyOrder) => {
    console.log(`KeyOrderList.onDelete()`, keyOrder);
    const { deleteOrder } = this.props;
    this.dialog.show(
      true,
      {
        title: `Schließmittelbestellung löschen`,
        text: `Wollen Sie die Bestellung ${keyOrder.orderNumber} unwiderruflich löschen?`
      },
      () => deleteOrder({ keyOrder })
    )
  }
  render() {
    console.log('KeyOrderList.render() selectedKeyOrders:', this.props.selection);
    const {
      className, isLoading, rows, keyOrders, namePrefix,
      selection, setSelection, lockOrders, exportOrders
    } = this.props;
    const taskItems = keyOrders.map((item, index) => ({
      ...item,
      index,
      orderDate: item.order_date ? toUIDate(item.order_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ') : '',
      createdDate: toUIDate(item.created_date, 'YYYY-MM-DDTHH:mm:ss:SSSZ'),
      status: item.status || PRODUCT_ORDER_STATUS_LABELS[item.status_id]
    })).sort(createSortByAttr('orderNumber', true));
    const actionTemplate = (rowData) => {
      const isEditDisabled = (rowData.status_id !== PRODUCT_ORDER_STATUS_IDS.PROCESSING);
      const EditComponent = isEditDisabled ? ViewIconButton : EditIconButton;
      return (
        <div>
          <div className="w3-cell w3-cell-top">
            <EditComponent
              id={`${namePrefix}-editBtn${rowData.index || rowData.name || ''}`}
              onClick={() => this.onShowDetails(rowData)}
            />
          </div>
          <div className="w3-cell w3-cell-top">
            <DeleteIconButton
              id={`${namePrefix}-deleteBtn${rowData.index || rowData.name || ''}`}
              onClick={() => this.onDelete(rowData)}
              disabled
            />
          </div>
        </div>
      );
    };
    return (
      <div>
        <PageTitle>
          <KeyOrderMenu
            className="w3-right"
            selectedKeyOrders={selection}
            lockOrders={lockOrders}
            exportOrders={exportOrders}
            items={keyOrders}
          />
          <h3 className="no-margin pad-btm">{'Schließmitellbestellungen'}</h3>
        </PageTitle>
        <DataTable
          value={taskItems}
					paginator={taskItems.length > rows}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {this.setState({first: event.first}); scrollToTop();}}
          selection={selection}
          onSelectionChange={event => setSelection(event.value)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Schließmitellbestellungen"
              itemName="Bestellung"
              itemNamePlural="Schließmitellbestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Schließmitellbestellungen"/>)}
        >
          <Column
            selectionMode="multiple"
            style={{width:'3em'}}
          />
          <Column
            field="orderNumber"
            header="Bestellungsnummer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="orderDate"
            header="Bestellungsdatum"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="companyName"
            header="Firma"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '18em'}}
          />
          {/* <Column
            field="createdDate"
            header="Abgeben am"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          /> */}
          <Column
            field="requester"
            header="Anforderer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="status"
            header="Status"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center-', width: '9em'}}
          />
          <Column
            body={actionTemplate}
            style={{textAlign:'center', width: '7.8em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyOrderTaskOverview);
