import moment from 'moment';
import { STATUS_IDS } from '../../../components/Utils';

export const TEST = false;

export const CARDS = [
  {
    name: 'Anhänger für Pegelcheck',
    number: 'TE-07033-T-00',
    comments: '',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Anhänger für Pegelcheck nach ACSO',
    number: 'TE-17338-T-00',
    comments: 'Pegelscheckanhänger nach ACSO D3.1',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'ÜP Aufkleber',
    number: 'TE-00343-T-00',
    comments: 'ÜP Aufkleber - "Wichtiger Hinweis"',
    unit: 'Blatt zu 15 Stück',
    image: '',
  },
  {
    name: 'Störungsaufkleber für Techniker',
    number: 'TE-01056-T-00',
    comments: '"Technische Probleme und Störungen?..."',
    unit: 'Blatt zu 15 Stück',
    image: '',
  },
  {
    name: 'Installationsschacht',
    number: 'TE-02063-T-00',
    comments: '"Benutzung als Schornstein unzulässig"',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Infokarte Kabel Anschluss',
    number: 'TE-00059-T-00',
    comments: 'Einwurfkarte Techniker "Störungen"',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Express Upgrade-Aufkleber',
    number: 'TE-06154-T-00',
    comments: 'rund-rot ',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'BI-Aufkleber',
    number: 'TE-06509-T-00',
    comments: 'rund-rot ',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Diese Anlage ist Eigentum von Vodafone Kabel Deutschland',
    number: 'TE-00061-T-00',
    comments: 'Aufkleber für Schrank rechteckig (ISP)',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'BI-Benachrichtigungskarte',
    number: 'TE-05082-T-00',
    comments: 'Text in deutsch',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'BI-Benachrichtigungskarte',
    number: 'TE-10959-T-00',
    comments: 'Text in englisch',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Infoflyer Installation Internet und Telefon',
    number: 'TE-09277-T-00',
    comments: 'Flyer',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Benachrichtigungskarte NE3/NE4 Bau ',
    number: 'TE-10795-T-00',
    comments: 'Bau des Hausanschlusses',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Kfz-Aufkleber',
    number: 'TE-04737-T-00',
    comments: '"Service on tour"',
    unit: 'Stück',
    image: '',
  },
  {
    name: 'Defektaufkleber (Modem)',
    number: 'TE-07913-T-00',
    comments: 'rot',
    unit: 'Blatt zu 8 Stück',
    image: '',
  },
  {
    name: 'Aufkleber für W-LAN Modem',
    number: 'TE-13703-T-00',
    comments: 'Installation MTA Mehrnutzeranschluss "MNV 5:1", Projektbezogen',
    unit: 'Blatt zu 18 Stück',
    image: '',
  },
  {
    name: 'Installationsanleitung ET1',
    number: 'TE-17855-T-00',
    comments: 'EFH-PIC 1 analoges Endgerät mit 1 Rufnummer',
    unit: 'Blatt',
    image: '',
  },
  {
    name: 'Installationsanleitung ET2',
    number: 'TE-17856-T-00 ',
    comments: 'EFH-PIC 2 analoge Endgeräte mit 2 Rufnummern',
    unit: 'Blatt',
    image: '',
  },
  {
    name: 'Installationsanleitung ET3',
    number: 'TE-17857-T-00 ',
    comments: 'EFH-PIC ISDN Endgerät mit bis zu 10 Rufnummern',
    unit: 'Blatt',
    image: '',
  },
  {
    name: 'Durchziehplombe',
    number: 'TE-18818-T-00',
    comments: 'Durchziehplombe - Bestellmenge 1  = 250 St. im Polybeutel, Auslieferung ab KW 50 ',
    unit: '1 Beutel a 250 St.',
    image: '',
  },
  {
    name: 'Leer',
    number: 'TE-00000-T-00',
    comments: 'Freitext - Verwendung nur für Intern',
    unit: 'Stück',
    image: '',
  }
].map((item, index) => ({ ...item, id: (index + 1)}));

export const ORDERS = [];

const managerOrders = () => {
  return {
    getOrders: () => ([ ...ORDERS ]),
    getOrdersByCompany: (companyId) => {
      console.log(`OrderManager.getOrdersByCompany(${companyId})`);
      return (ORDERS.filter(item => `${item.companyId}` === `${companyId}`));
    },
    getOrderTasks: () => (ORDERS.filter(item => item.status_id && item.status_id !== STATUS_IDS.FINISHED)),
    getOrder: (id) => (ORDERS.find(item => `${item.id}` === `${id}`)),
    addOrder: (order) => {
      order.created_date = moment().format('YYYY-MM-DDTHH:mm:ss:SSSZ');
      order.id = (ORDERS.length + 1);
      order.status_id = order.status_id ? order.status_id : STATUS_IDS.NEW;
      ORDERS.push(order);
      return true;
    },
    updateOrder: (order) => {
      const index = ORDERS.findIndex(item => item.id === order.id);
      if (index >= 0) {
        ORDERS[index] = {
          ...ORDERS[index],
          ...order
        };
        return true;
      }
      return false;
    },
    removeOrder: (orderNumber) => {
      const index = ORDERS.findIndex(item => `${item.orderNumber}` === `${orderNumber}`);
      if (index >= 0) {
        ORDERS.splice(index, 1);
        return true;
      }
      return false;
    }
  }
}
export const OrderManager = managerOrders();
