import React from 'react';
import PropTypes from 'prop-types';
// import { Growl } from 'primereact/growl';
import { Toast } from 'primereact/toast';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { withRouter } from 'react-router';
import { initApp } from './redux/actions/app';

// give custom css a higher priority by listing them last
import './css/w3.css';
import 'primereact/resources/themes/nova-alt/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './css/vodafone/font.css';
import './css/vodafone/styles.css';
import './css/fonts.css';
import './css/App.css';

import Header from './modules/AppHeader';
import Footer from './modules/AppFooter';
import Routes from './pages';
import { initNotifier, scrollToTop, GlobalOverlay } from './components/Utils';

const frontload = async (props) => {
  await props.initialize();
};

const mapStateToProps = (state) => ({
  initializingApp: state.auth.initializingApp,
  isAuthenticated: state.auth.isAuthenticated
});
const mapDispatchToProps = dispatch => {
  return {
    initialize: (...args) => dispatch(initApp(...args))
  }
};

const style= {
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100vh',
  zIndex: 10000,
  background: 'rgba(255,255,255, 0.1)',
  textAlign: 'center'
};
const AppOverlay = () => (
  <div style={style}>
    <div style={{lineHeight: '100vh', fontWeight: 'bold'}}>
      <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>
    </div>
  </div>
);

class App extends React.Component {
  static propTypes = {
    initializingApp: PropTypes.bool,
    // getUser: PropTypes.func.isRequired
    initialize: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  }
  componentDidMount() {
    initNotifier(this.toast);
    scrollToTop();
  }
  // componentDidUpdate() {
  //   scrollToTop();
  // }
  render() {
    const { initializingApp, isAuthenticated } = this.props;
    const markup = initializingApp ? (
      <AppOverlay/>
    ) : (
      <div className="app-root">
        <Header />
        <div className="app-body"><Routes /></div>
        <Footer showLinks={isAuthenticated} />
      </div>
    );
    return (
      <div>
        {markup}
        <Toast ref={ref => this.toast = ref} />
        <GlobalOverlay />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(withRouter(App))
);
