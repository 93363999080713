import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
// import RichTextEditor from '../../../../components/RichTextEditor';
import { MinusIconButton } from '../../../../components/Buttons';
import { getContentTypeIdFromType, CONTENT_PANEL, getPanelContentData, isContentFromClipboard } from '../Utils';
import ConfirmationDialog from './ConfirmationDialog';
import { Accordion, AccordionTab } from '../../../../components/Accordion';
import { DeleteIconButton, UpIconButton, DownIconButton, CopyIconButton } from '../../../../components/Buttons';
import {
  ContentMenuPanel, ContentMenuLabelMap,
  getContentTypeFromId, getContentForm,
  getContentDeleteConfirmationText
} from '../Utils';
import { copyToClipboard, getClipboardSize, isClipboardEmpty, pasteFromClipboard } from '../../ClipBoard';
import { notifyError, notifyInfo, notifySuccess } from '../../../../components/Utils';
import ClipBoardDialog from '../ClipBoardDialog';


export default class PanelForm extends React.Component {
  static propTypes = {
    pageId: PropTypes.string,
    className: PropTypes.string,
    model: PropTypes.object,
    onRemove: PropTypes.func,
  }
  static defaultProps = {
    className: '',
    model: {
      title: '',
      body: ''
    },
  }
  constructor(props) {
    super(props);
    // const model = {
    //   title: props.model.title || '', 
    // };
    const contents = getPanelContentData(props.model);
    this.state = {
      // ...model,
      contents,
      activeIndex: 0,
      // error: { title: null }
    };
    this.isTabOpenList = contents.map(() => false);
  }
  validate = (noValidate) => {
    const { error, contents, ...model} = this.state;
    let isValid = true;
    if (!model.title || model.title === '') {
      error.title = true;
      isValid = false;
    }
    // content list
    const newContents = [];
    contents.forEach((item, index) => {
      if (this[`form${index}`]) {
        let data = this[`form${index}`].getModel();
        if (!data) {
          isValid = false;
          data = item;
        }
        newContents.push({ ...data, position: (index + 1) });
      }
    });
    model.body = JSON.stringify(newContents);
   
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }
    return model;
  }
  validate = () => {
    const { contents } = this.state;
    let isValid = true;
    // title data
    const model = this.titleForm.getModel();
    if (!model) {
      return null
    };
    // content list
    const newContents = [];
    contents.forEach((item, index) => {
      if (this[`form${index}`]) {
        let data = this[`form${index}`].getModel();
        if (!data) {
          isValid = false;
          data = item;
        }
        newContents.push({ ...data, position: (index + 1) });
      }
    });
    model.body = JSON.stringify(newContents);
    if (!isValid) {
      return null;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    if (!model) {
      return null;
    }
    model.content_type_id = getContentTypeIdFromType(CONTENT_PANEL);
    // set model id if present
    const { id } = this.props.model;
    if (id) {
      model.id = id;
    }
    return model;
  }
  getCurrentTabContent = (index) => {
    // const { activeIndex } = this.state;
    const activeIndex = (index !== undefined && index !== null) ? index : this.state.activeIndex;
    if (activeIndex !== null && this[`form${activeIndex}`]) {
      return this[`form${activeIndex}`].getModel(true);
    }
    return null;
  }
  updateCurrentContent = () => {
    const { activeIndex } = this.state;
    // get current tab content before closing it
    const oldContents = this.state.contents;
    if (activeIndex !== null) {
      // get current tab content
      const currentData = this.getCurrentTabContent();
      oldContents[activeIndex] = JSON.parse(JSON.stringify(currentData));
    }
    // check list of open contents
    this.isTabOpenList.forEach((isOpen, index) => {
      if (isOpen) {
        const currentData = this.getCurrentTabContent(index);
        if (currentData) {
          oldContents[index] = JSON.parse(JSON.stringify(currentData));
        }
      }
    });
    return oldContents;
  }
  changeTab = (newIndex) => {
    const { activeIndex } = this.state;
    // get current tab content before closing it
    if (this[`form${activeIndex}`]) {
      const src = this[`form${activeIndex}`].getModel(true);
      const contents = [];
      this.state.contents.forEach((item, index) => {
        if (index === activeIndex) {
          contents.push({ ...item, ...src });
        } else {
          contents.push(item);
        }
      });
      // update state
      this.setState({ contents, activeIndex: newIndex });
    }
  }
  onOpenTabsChanged = (index, isOpen) => {
    this.isTabOpenList[index] = isOpen;
  }
  addContent = (content_type_id, position, type) => {
    console.log('PanelForm.addContent()', content_type_id, position);
    // update old contents
    const oldContents = this.updateCurrentContent();
    // add new content
    const newItemIndex = position ? (position - 1) : contents.length - 1;
    const handleAddition = (items) => {
      oldContents.splice(position - 1, 0, ...items);
      this.setState(() => ({
        contents: oldContents,
        activeIndex: newItemIndex
      }));
    };
    if (isContentFromClipboard(type)) {
      if (isClipboardEmpty()) {
        return notifyInfo({
          summary: 'Zwischenablage is leer',
          detail: 'Es kann nichts hinzugefügt werden!'
        });
      } else if (getClipboardSize() === 1) {
        return handleAddition([ pasteFromClipboard() ]);
      }
      this.clipboardDialog.show(true, handleAddition);
    } else {
      handleAddition([
        {
          content_type_id,
          title: '',
          subtitle: '',
          body: ''
        }
      ]);
    }
  }
  confirmContentRemoval = (type, currentIndex) => {
    const confirmData = getContentDeleteConfirmationText(type);
    if (confirmData) {
      this.dialog.show(
        true,
        confirmData,
        () => this.removeContent(currentIndex)
      );
    }
  }
  removeContent = (currentIndex) => {
    // console.log('PanelForm.removeContent()', currentIndex);
    // update old contents if necessary
    const oldContents = this.updateCurrentContent();
    // remove item at currentIndex
    const contents = [];
    oldContents.forEach((item, index) => {
      if (index !== currentIndex) {
        contents.push(item);
      }
    });
    this.setState(() => ({ contents }));
  }
  move = (currentIndex, up) => {
    const targetIndex = up ? currentIndex - 1 : currentIndex + 1;
    // update old content if necessary
    const oldContents = this.updateCurrentContent();
    // build new content
    const contents = [];
    const src = this[`form${currentIndex}`].getModel(true);
    let tgt = this[`form${targetIndex}`].getModel(true);
    tgt = { ...oldContents[targetIndex], ...tgt };
    for (let index = 0; index < oldContents.length; index ++) {
      if (index === currentIndex) {
        contents.push(tgt);
      } else if (index === targetIndex) {
        contents.push(src);
      } else {
        contents.push({ ...oldContents[index] });
      }
    }
    this.setState(() => ({
      contents,
      activeIndex: targetIndex
    }));
  }
  copy = (index) => {
    const data = this[`form${index}`].getModel(true);
    const num = copyToClipboard(data);
    notifySuccess({
      summary: 'Zwischenablage',
      detail: `Der Inhalt wurde in die Zwischenablage hinzugefügt. Sie enthält nun ${num} Element${num > 0 ? 'e' : ''}`
    });
  }
  render() {
    const { className, onRemove, pageId, model } = this.props
    const { /* title, error,  */contents, activeIndex  } = this.state;
    const prefix = Math.random();
    const panelId = `${pageId}_` + `${prefix}`.split('.').join('-');
    // console.log('PanelForm.render() - activeIndex', activeIndex, 'contents', contents);
    const createRefMaker = index => ref => this[`form${index}`] = ref;
    const bodyMarkup = contents
      .filter(item => item !== null)
      .map((item, index) => {
        const { content_type_id, ...model } = item;
        const type = getContentTypeFromId(content_type_id);
        const Component = getContentForm(type);
        if (!Component) {
          console.error('<< Content Type Error: unknown content type', type, item);
          return null;
        }
        const menu = (
          <div className="display-inline-block- clearfix">
            <UpIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.move(index, true)}
              disabled={!(contents.length > 1 && index > 0)}
            />
            <DownIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.move(index, false)}
              disabled={!(contents.length > 1 && index < contents.length - 1)}
            />
            <CopyIconButton
              className="w3-border margin-rht pad-sm"
              onClick={() => this.copy(index)}
              title="in die Zwischenablage kopieren"
            />
            <DeleteIconButton
              className="w3-border pad-sm"
              onClick={() => this.confirmContentRemoval(type, index)}
            />
          </div>
        );
        const counter = `${index < 9 ? '0' : ''}${index + 1}. `;
        return (
          <AccordionTab key={`${prefix}-${index}`} header={counter + ContentMenuLabelMap[type]} menu={menu} className="margin-top">
            <Component
              ref={createRefMaker(index)}
              className=""
              model={model}
              pageId={panelId}
            />
          </AccordionTab>
        );
      });

    const menu = onRemove && (
      <div className="w3-right">
        <div className="w3-border"><MinusIconButton onClick={onRemove}/></div>
      </div>
    );
    return (
      <div id={panelId} className={`${className} clearfix pad-big`}>
        {menu}
        <PanelContentTitleForm
          ref={ref => this.titleForm = ref}
          model={model}
        />
        {/* <div className={`form-group-item ${error.title ? 'error-group' : ''}`}>
          <Label htmlFor="title">{`Titel*`}</Label>
          <InputText id="title" className="w3-block w3-border" value={title}
            onChange={(event) => this.setState({title: event.target.value})}/>
        </div> */}
        <div className="w3-border-top w3-border-bottom w3-light-grey neg-margin-big-lr margin-big-top pad">
          <ContentMenuPanel
            className="w3-right"
            onAddContent={this.addContent}
            positions={contents.length + 1}
          />
          <h4 className="no-margin pad-sm-tb">{'Abschnittsinhalte'}</h4>
        </div>

        <div className="clearfix pad-btm" style={{paddingTop: '10px'}}>
          <Accordion
            id="pageContents"
            className="clearfix"
            activeIndex={activeIndex}
            onTabChange={(event) => this.changeTab(event.index)}
            notDraggable
            singleActiveIndex={false}
          >
            {bodyMarkup}
          </Accordion>
          <ConfirmationDialog ref={ref => this.dialog = ref}/>
          <ClipBoardDialog ref={ref => this.clipboardDialog = ref}/>
        </div>
        
    </div>
    );
  }
}


class PanelContentTitleForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  static defaultProps = {
    className: '',
    model: { title: '' }
  }
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      title: model.title || '',
      error: {
        title: null
      }
    };
  }
  validate = (noValidate) => {
    const { error, title } = this.state;
    let isValid = true;
    if (!title || title === '') {
      error.title = true;
      isValid = false;
    }
    const model = { title };
    if (!noValidate && !isValid) {
      notifyError({
        summary: `Abschnitt mit Titel`,
        detail: `Sie müssen einen Abschnittstitel angeben!`
      });
      this.setState({ error });
      return null;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  render() {
    // console.log('PanelContentTitleForm.render()', this.state);
    const { className } = this.props
    const { title, error } = this.state;
    return (
      <div className={`${className}`}>
        <div className={`form-group-item ${error.title ? 'error-group' : ''}`}>
          <Label htmlFor="title">{`Titel`}<span>*</span></Label>
          <InputText id="title" className="w3-block w3-border" value={title}
            onChange={(event) => this.setState({title: event.target.value})}/>
          {error.title && (<span className="w3-block w3-red">{error.title}</span>)}
        </div>
      </div>
    );
  }
}

