import React from 'react';
import PropTypes from 'prop-types';
import DebitorOrderOverview from '../components/DebitorOrderOverview';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { cancelModemOrder, loadArchiveOrderDebitorDetails } from '../actions';
import { FormMenu } from '../../../components/Buttons';

const frontload = async (props) => {
  const { orderNumber, debitorId } = props.match.params;
  await props.getDebitorDetails({ orderNumber, debitorId });
};
const mapStateToProps = (state) => {
  return {
    selectedDebitor: state.modem.selectedDebitor,
    modems: state.modem.archiveModems
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDebitorDetails: (...args) => dispatch(loadArchiveOrderDebitorDetails(...args)),
  cancelOrder: (...args) => dispatch(cancelModemOrder(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    selectedDebitor: PropTypes.object,
    modems: PropTypes.array,
    getDebitorDetails: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  cancelOrder = () => {
    console.log('ModemOrderArchiveWizard.onCancel()');
    const { cancelOrder, history, selectedDebitor } = this.props;
    cancelOrder();
    history.replace(`/shop/modems/uebersicht/jahr/${selectedDebitor.year}/woche/${selectedDebitor.week}`);
  }
  render() {
    const { className, selectedDebitor, modems } = this.props;
    console.log('ModemOrderArchiveWizard.render()', selectedDebitor, modems);
    if (!selectedDebitor || !modems) {
      return null;
    }
    const debitor = selectedDebitor.debitor[0];
    return (
      <div className={`modem-order-archive-wizard clearfix ${className}`}>
        <DebitorOrderOverview
          title="Debitor Übersicht"
          hideDebitorSubtitle
          debitor={debitor}
          modems={modems}
          showApproved/>
        <FormMenu
          className="margin-big-top w3-border pad-big w3-light-grey"
          saveHidden
          cancelBtnName="closeArchiveDebitor"
          cancelBtnLabel="Zurück"
          onCancel={this.cancelOrder}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
