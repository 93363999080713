import * as actionTypes from '../actions/device';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';

export const initialState = {
  deviceTasks: [],
  deviceTaskType: '',
  selectedDevices: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // filter devices
    case actionTypes.FILTER_DEVICES: {
      console.log('REDUX REDUCER - filtering devices by task', action.taskType);
      const { taskType } = action;
      return {
        ...state,
        deviceTaskType: taskType
      };
    }
    // setting selected devices
    case actionTypes.SET_SELECTED_DEVICES:
      return {
        ...state,
        selectedDevices: action.selected
      };

    // export Devices as CSV
    case actionTypes.EXPORT_DEVICES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXPORT_DEVICES_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `Messgeräte exportieren`,
        detail: `Die gewählte Messgeräte wurden erfolgreich exportiert.`
      });
      return {
        ...state,
        loading: false,
        selectedDevices: [],
      };
    case actionTypes.EXPORT_DEVICES_FAILURE:
      notifyError({
        summary: `Messgeräte exportieren`,
        detail: `Die gewählte Messgeräte konnten nicht exportiert werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // close connection task
    case actionTypes.CLOSE_DEVICE_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_DEVICE_TASK_SUCCESS:
      notifySuccess({
        summary: `Messgerätaufgabe abschliessen`,
        detail: `Die gewählte Messgerätaufgabe wurde erfolgreich abgeschlossen.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_DEVICE_TASK_FAILURE:
      notifyError({
        summary: `Messgerätaufgabe abschliessen`,
        detail: `Die gewählte Messgerätaufgabe konnte nicht abgeschlossen werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // delete device task
    case actionTypes.DELETE_DEVICE_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_DEVICE_TASK_SUCCESS:
      notifySuccess({
        summary: `Messgerätaufgabe löschen`,
        detail: `Die gewählte Mesgerätsaufgabe wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_DEVICE_TASK_FAILURE:
      notifyError({
        summary: `Mesgerätsaufgabe löschen`,
        detail: `Die gewählte Mesgerätaufgabe konnte nicht gelöscht werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    default:
      return null;
  }
}
