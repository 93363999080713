import * as actionTypes from './actions';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';
import {
  notifyError,
  notifySuccess,
  openFile
} from '../../components/Utils';

const initialState = {
  mappings: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // load mappings
    case actionTypes.LOAD_DEBITOR_MAPPING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_DEBITOR_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        mappings: action.result,
      };
    case actionTypes.LOAD_DEBITOR_MAPPING_FAILURE:
      return {
        ...state,
        loading: false,
        mappings: [],
        error: action.result
      };
    // export mappings 
    case actionTypes.EXPORT_DEBITOR_MAPPING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EXPORT_DEBITOR_MAPPING_SUCCESS: {
      if (action.result.filePath) { 
        notifySuccess({
          summary: `Debitor-Mapping (CSV) herunterladen`,
          detail: `Die CSV-Datei mit dem Debitor-Mapping wurde erfolgreich erzeugt und kann heruntergeladen werden!`
        });
        openFile(action.result.filePath);
      }
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.EXPORT_DEBITOR_MAPPING_FAILURE:
      notifyError({
        summary: `Debitor-Mapping (CSV) herunterladen`,
        detail: `Die CSV-Datei konnte nicht erzeugt werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.error
      }
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };  

    default:
      return {...state};
  }
};

export default reducer;
