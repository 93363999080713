import React from 'react';
import PropTypes from 'prop-types';
import EditImage from '../../../components/media/edit.svg';
import { PrimaryButtonLink, PrimaryButton } from '../../../components/Buttons';
import * as PanelList from './panels';
import { connect } from 'react-redux';
import { exportWorkers } from '../actions';

export const DELPHI_STATUS_CREATE = 1;
export const DELPHI_STATUS_UPDATE = 2;
export const EditIcon = () => (<img src={EditImage} alt="edit" />);


const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getWorkersAsCSV: (...args) => dispatch(exportWorkers(...args)),
});
class WorkerListMenuClass extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    getWorkersAsCSV: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  onExportWorkers = (event) => {
    if (event) event.preventDefault();
    this.props.getWorkersAsCSV();
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`worker list-menu ${className}`}>
        <PrimaryButtonLink
          to={`/wizard/organisation/mitarbeiter/neu`}
          name="addWorkerBtn"
        >
          {`Mitarbeiter hinzufügen`}
        </PrimaryButtonLink>
        <PrimaryButton
          className="margin-lft"
          name="exportWorkerBtn"
          onClick={this.onExportWorkers}
        >
          {'CSV Exportieren'}
        </PrimaryButton>
      </div>
    );
  }
}
export const WorkerListMenu = connect(mapStateToProps, mapDispatchToProps)(WorkerListMenuClass);

export const Panels = PanelList;
