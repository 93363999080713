import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../../pages/Page';
import { withRouter } from 'react-router-dom';
import HtmlText from '../../../components/HtmlText';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadArticle } from '../actions';
import { WizardTitle, PageHeight60, scrollToTop } from '../../../components/Utils';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getArticle({ id/* , isPrivate: false, categoryName: 'General' */ });
};
const mapStateToProps = (state) => {
  return {
    article: state.news.article
  };
};
const mapDispatchToProps = (dispatch) => ({
  getArticle: (...args) => dispatch(loadArticle(...args))
});

class NewsDetails extends React.Component {
  static propTypes = {
    article: PropTypes.object,
    getArticle: PropTypes.func
  }
  static defaultProps = {
    className: 'margin-big-top'
  }
  componentDidMount() {
    scrollToTop();
  }
  render() {
    const { article } = this.props;
    console.log('NewsDetails.render()', this.props);
    if (!article) return null;
    return (
      <Page id="newsDetailsPage" className="news-details-page" title={article.title}
        description={article.subtitle || article.title}>
        <WizardTitle model={{ name: article.title}}/>
        <div className="container">
          <PageHeight60 id="news-beitrag" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
            {article.image && (<img className="image" src={article.image} alt="Bild"/>)}
            {/* {article.subtitle && (<p>{article.subtitle}</p>)} */}
            <HtmlText text={article.body}/>
          </PageHeight60>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false
    })(NewsDetails)
  )
);
