import React from "react";
import PropTypes from "prop-types";
import { PrimaryButtonLink, PrimaryButton } from "../../../components/Buttons";
import {
	W3Dropdown,
	W3DropdownToggler,
	W3DropdownContent,
} from "../../../components/W3Dropdown";
import { createSortByName } from "../../../components/Utils";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { connect } from "react-redux";
import { exportCardOrders, exportKeyOrders } from "../../Task/actions";

export const DEFAULT_CARD = {
	type_id: 1,
	name: "",
	number: "",
	unit: "Stück",
	comment: "",
	files: [],
	status_id: 2,
};

export const DEFAULT_CARD_ORDER = {
	items: [],
	company_id: "",
	companyName: "",
	person: "",
	address: {
		street: "",
		houseNumber: "",
		areaCode: "",
		city: "",
	},
	description: "",
	status_id: 1,
};

function createProductMenu(linkOptions = []) {
	return class ProductMenu extends React.Component {
		static propTypes = {
			className: PropTypes.string,
			selected: PropTypes.array,
		};
		static defaultProps = {
			className: "",
			selected: [],
		};
		onActivate = (selected) => {
			// this.dialog.show(
			//   true,
			//   {
			//     title: 'Neue Karten/Flyer-Bestellungen abnehmen',
			//     text: `
			//       Durch die Abnahme, werden alle offene Bestellungen in die Bearbeitung genommen und so für jegliche
			//       Anpassung durch die Besteller gesperrt. Wollen Sie die ausgewählte Bestellung${newItems.length > 1 ? 'en' : ''} nun abnehmen?
			//     `
			//   },
			//   () => lockOrders(newItems)
			// );
		};
		onDeactivate = (selected) => {};
		render() {
			const { className, selected } = this.props;
			const links = linkOptions.map((link, index) => {
				return (
					<PrimaryButtonLink
						key={index}
						to={link.to}
						className={index > 0 ? "margin-lft" : ""}
					>
						{link.label}
					</PrimaryButtonLink>
				);
			});
			return (
				<div className={`produkt list-menu ${className}`}>
					{links}
					<W3Dropdown className="dropdown-list-menu">
						<W3DropdownToggler
							as={PrimaryButton}
							size="small-"
						>{`Optionen`}</W3DropdownToggler>
						<W3DropdownContent
							className="w3-bar-block w3-border"
							style={{ right: "0", width: "280px" }}
						>
							<ButtonLink
								to="#"
								className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
								onClick={() => this.onActivate(selected)}
								disabled={selected.length === 0}
							>
								{`Produkte aktivieren`}
							</ButtonLink>
							<ButtonLink
								to="#"
								className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
								onClick={() => this.onDeactivate(selected)}
								disabled={selected.length === 0}
							>
								{`Produkte deaktivieren`}
							</ButtonLink>
						</W3DropdownContent>
					</W3Dropdown>
					<ConfirmationDialog ref={(ref) => (this.dialog = ref)} />
				</div>
			);
		}
	};
}

/**
 * Create a Product Order Archive Menu for exporting product orders
 * @param {Object} param0
 */
function createProductOrderArchiveMenu({ btnName, label, typeName }) {
	return class ProductOrderArchiveMenu extends React.Component {
		static propTypes = {
			className: PropTypes.string,
			exportOrders: PropTypes.func.isRequired,
		};
		static defaultProps = {
			className: "",
		};
		onExportOrders = (event) => {
			if (event) event.preventDefault();
			// this.props.exportOrders();
			this.dialog.show(
				true,
				{
					title: `${typeName} exportieren`,
					text: `Wollen sie alle ${typeName} exportieren?`,
				},
				() => this.props.exportOrders()
			);
		};
		render() {
			const { className } = this.props;
			return (
				<div className={`product-order list-menu ${className}`}>
					<PrimaryButton
						className="margin-lft"
						name={btnName}
						onClick={this.onExportOrders}
					>
						{label}
					</PrimaryButton>
					<ConfirmationDialog ref={(ref) => (this.dialog = ref)} />
				</div>
			);
		}
	};
}

export const CardMenu = ({ className, onAdd }) => {
	return (
		<div className={`modems list-menu ${className}`}>
			<PrimaryButtonLink to={`/wizard/produkte/karte/neu`}>
				{`Karte/Flyer hinzufügen`}
			</PrimaryButtonLink>
		</div>
	);
};

export const CardOrderMenu = ({ className /* , onAdd */ }) => {
	return (
		<div className={`cards list-menu ${className}`}>
			<PrimaryButtonLink
				to={`/wizard/shop/bestellung/karte/neu`}
			>{`zum Material-Shop`}</PrimaryButtonLink>
		</div>
	);
};

export const KeyMenu = ({ className, onAdd }) => {
	return (
		<div className={`modems list-menu ${className}`}>
			<PrimaryButtonLink
				id="lockButton"
				to={`/wizard/produkte/schliessmittel/neu`}
			>{`Schließmittel hinzufügen`}</PrimaryButtonLink>
		</div>
	);
};

/**
 * Card Order Archive Menu
 */
const mapStateToPropsCOA = null;
const mapDispatchToPropsCOA = (dispatch) => ({
	exportOrders: (...args) => dispatch(exportCardOrders(...args)),
});
export const CardOrderArchiveMenu = connect(
	mapStateToPropsCOA,
	mapDispatchToPropsCOA
)(
	createProductOrderArchiveMenu({
		btnName: "exportCardOrders",
		label: "Bestellungen exportieren",
		typeName: "Karten/Flyer-Bestellungen",
	})
);

/**
 * Key Order Archive Menu
 */
const mapStateToPropsKOA = null;
const mapDispatchToPropsKOA = (dispatch) => ({
	exportOrders: (...args) => dispatch(exportKeyOrders(...args)),
});
export const KeyOrderArchiveMenu = connect(
	mapStateToPropsKOA,
	mapDispatchToPropsKOA
)(
	createProductOrderArchiveMenu({
		btnName: "exportKeyOrders",
		label: "Bestellungen exportieren",
		typeName: "Schließmittelbestellungen",
	})
);

export const sortProductsByName = (products) => {
	if (!products) {
		return null;
	}
	const list = (products || [])
		.filter((item) => item.name.trim().charAt(0) !== "-")
		.sort(createSortByName());
	// const list1 = list.filter(item => item.unit && item.unit.trim() !== '').sort(createSortByName());
	// const list2 = list.filter(item => !item.unit || item.unit.trim() === '').sort(createSortByName());
	const list3 = (products || [])
		.filter((item) => item.name.trim().charAt(0) === "-")
		.sort(createSortByName());
	// return list1.concat(list2).concat(list3);
	return list.concat(list3);
};

export const createProductModel = (item, index) => {
	if (!item) {
		return null;
	}
	const { id, name, number, comments, description, unit, files } = item;
	const data = {
		index,
		product_id: id,
		productName: name,
		productNumber: number,
		productComments: comments || description,
		productUnit: unit,
		files,
	};
	if (index !== undefined) {
		data.index = index;
	}
	return data;
};

export const DEFAULT_KEY = {
	type_id: 4,
	name: "",
	number: "",
	comment: "",
	// files: []
	status_id: 2,
};

export const DEFAULT_KEY_ORDER = {
	items: [],
	company_id: "",
	companyName: "",
	person: "",
	address: {
		street: "",
		houseNumber: "",
		areaCode: "",
		city: "",
	},
	description: "",
	status_id: 1,
};
