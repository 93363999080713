import React from "react";
import PropTypes from 'prop-types';
import { Calendar, InputText, Label } from "../../../components/Inputs";
import { toDBDate } from "../../../components/Utils";
import { SearchIconButton } from "../../../components/Buttons";

import { connect } from 'react-redux';
import { searchNewsArchives } from '../actions';


const mapDispatchToProps = (dispatch) => ({
  searchArchives: (...args) => dispatch(searchNewsArchives(...args)),
});

class NewsArchiveSearchPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    cellClassName: PropTypes.string,
    mini: PropTypes.bool,
    searchArchives: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  }
  static defaultProps = {
    className: "",
    rowClassName: "",
    cellClassName: ""
  }
  constructor() {
    super();
    this.state = {
      keyword: "",
      fromDate: "",
      toDate: "",
      error: null
    }
  }
  getValidState = (state) => {
    const { error, ...rest } = state;
    const tokens = [];
    Object.keys(rest).forEach(key => {
      if (rest[key] && (rest[key] instanceof Date || rest[key].length > 0)) {
        tokens.push(key)
      }
    });
    return tokens;
  }
  onSearch = () => {
    const { error, ...rest } = this.state;
    const tokens = this.getValidState(this.state);
    // console.log(">> building search params:", rest, tokens);
    // ensure that atleast one value is given
    // if (tokens.length === 0) {
    //   return this.setState({ error: true });
    // }
    const filter = {
      ...rest,
      fromDate: toDBDate(rest.fromDate),
      toDate: toDBDate(rest.toDate),
      id: Math.random(),
    };
    const query = tokens
      .map(key => `${key}=${encodeURIComponent(filter[key])}`)
      .join("&");
    // // console.log(">> searching archives ...");
    this.props.searchArchives({ filter, query, sortFields: {} });
  }
  onChange = (key, value) => {
    const newState = {
      ...this.state,
      [key]: value
    };
    const tokens = this.getValidState(newState);
    newState.error = null; // (tokens.length === 0);
    // console.log("onChange()", newState, tokens);
    this.setState(newState);
  }
  render() {
    const  { className, rowClassName, cellClassName, mini } = this.props;
    const { keyword, fromDate, toDate, error } = this.state;
    const keywordLabel = "Suche (Inhalt und Betreff)";
    const fromLabel = "Datum von";
    const toLabel = "Datum bis";
    const inputClassName = `w3-block w3-border ${error ? 'w3-border-red' : ''}`;
    console.log("SearchPanel:", this.state)
    return (
      <div className={`news-archive-search-panel ${className}`}>
        <div className={`${rowClassName}`}>
          <div className={`w3-cell-row`}>
            <div className="w3-cell w3-cell-middle">
              <div className={cellClassName}>
                <Label
                  htmlFor="keyword"
                  className={mini ? "w3-hide" : ""}
                >
                  {keywordLabel}
                </Label>
                <InputText
                  id="keyword"
                  className={inputClassName}
                  value={keyword}
                  onChange={(event) => this.onChange("keyword", event.target.value)}
                  onKeyUp={(event) => (event.keyCode === 13) && this.onSearch()}
                  placeholder={mini ? keywordLabel : ""}
                />
              </div>
            </div>
            <div className="w3-cell w3-cell-middle" style={{width: "12em"}}>
              <div className={cellClassName}>
                <Label
                  htmlFor="fromDate"
                  className={mini ? "w3-hide" : ""}
                >
                  {fromLabel}
                </Label>
                <Calendar
                  id="fromDate"
                  className={inputClassName}
                  value={fromDate}
                  readOnlyInput={true}
                  dateFormat="dd.mm.yy"
                  maxDate={new Date()}
                  onChange={(event) => this.onChange("fromDate", event.value)}
                  showButtonBar
                  placeholder={mini ? toLabel : "TT.MM.JJJJ"}
                />
                {/* <InputText
                  id="fromDate"
                  className={inputClassName}
                  value={fromDate}
                  onChange={(event) => this.setState({fromDate: event.target.value})}
                  onKeyUp={(event) => (event.keyCode === 13) && this.onSearch()}
                  placeholder={mini ? fromLabel : "TT.MM.JJJJ"}
                /> */}
              </div>
            </div>
            <div className="w3-cell w3-cell-middle" style={{width: "12em"}}>
              <div className={cellClassName}>
                <Label
                  htmlFor="toDate"
                  className={mini ? "w3-hide" : ""}
                >
                  {toLabel}
                </Label>
                <Calendar
                  id="toDate"
                  className={inputClassName}
                  value={toDate}
                  readOnlyInput={true}
                  dateFormat="dd.mm.yy"
                  maxDate={new Date()}
                  onChange={(event) => this.onChange("toDate", event.value)}
                  showButtonBar
                  placeholder={mini ? toLabel : "TT.MM.JJJJ"}
                />
                {/* <InputText
                  id="toDate"
                  className={inputClassName}
                  value={toDate}
                  onChange={(event) => this.setState({toDate: event.target.value})}
                  onKeyUp={(event) => (event.keyCode === 13) && this.onSearch()}
                  placeholder={mini ? toLabel : "TT.MM.JJJJ"}
                /> */}
              </div>
            </div>
            <div className="w3-cell w3-cell-middle" style={{width: "2.75em"}}>
              <div className={cellClassName}>
                <Label className={mini ? "w3-hide" : "text-trans"}>S</Label>
                <SearchIconButton
                  className="w3-border"
                  onClick={() => this.onSearch()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(NewsArchiveSearchPanel);
