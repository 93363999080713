import React from 'react';
import PropTypes from 'prop-types';
import { WizardTitle } from '../../../../components/Utils';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    order: state.card.cardOrder
  };
};
const mapDispatchToProps = null;

class CardWizardTitle extends React.Component {
  static propTypes = {
    // mapped from state
    order: PropTypes.object,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    isTask: PropTypes.bool
  }
  constructor(props) {
    super(props);
    this.state = { menu: null };
  }
  setNavMenu = (menu) => {
    // console.log('CardOrderWizardTitle.setNavMenu()', menu);
    this.setState({ menu });
  }
  render() {
    const { order, isTask } = this.props;
    const isNew =  !isTask && !(order && order.id !== undefined);
    console.log('CardOrderWizardTitle.render()', order, isNew);
    return (
      <div className={`modem-wizard-title`}>
        <WizardTitle
          type="cardOrder"
          model={order}
          isNew={isNew}
          isTask={isTask}
          menu={this.state.menu}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardWizardTitle);
