
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { getTrainingLink } from '../actions';

const frontload = async (props) => {
  await props.getTrainingLink();
};
const mapStateToProps = (state) => {
  return {
    trainingUrl: state.training.trainingUrl
  };
};
const mapDispatchToProps = (dispatch) => ({
  getTrainingLink: (...args) => dispatch(getTrainingLink(...args))
});

class UserTrainingLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    trainingUrl: PropTypes.string
  }
  static defaultProps = {
    className: 'nav'
  }
  render() {
    const { className, trainingUrl } = this.props;
    console.log('')
    return (
      <a className={className} target="_blank" href={trainingUrl || 'http://www.to.kabelcampus.de'}>Schulung</a>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(UserTrainingLink)
);
