import ObjectUtils from "primereact/components/utils/ObjectUtils";
import { MultiSelect } from "primereact/multiselect";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { MultiSelectHolder } from "../../../../components/Inputs";

const normalizeValue = (value) => {
  if (value == null) {
    return "";
  }

  return value;
};

export const filterFunction = (value, filter) => {
  // This is basically the same as Prime's "in" filter constraint, except that
  // it also matches null === null
  if (filter === undefined || filter === null || filter.length === 0) {
    return true;
  }

  if (value === undefined) {
    return false;
  }

  for (let i = 0; i < filter.length; i++) {
    if (normalizeValue(filter[i]) === normalizeValue(value)) {
      return true;
    }
  }

  return false;
};

const MultiSelectFilter = ({ dt, field, rows, onChange, options, value = [] }) => {
  const [selected, setSelected] = useState(value);

  const handleChange = useCallback((event) => {
    if (onChange) {
      onChange(field, event.value);
    } else {
      dt.filter(event.value, field, "custom");
    }
    setSelected(event.value);
  }, [dt, field]);

  const generatedOptions = useMemo(() => {
    return Array.from(
      rows.reduce((set, row) => {
        const value = ObjectUtils.resolveFieldData(row, field);
        set.add(normalizeValue(value));
        return set;
      }, new Set())
    ).map((value) => {
      return { label: String(value), value };
    });
  }, [rows, field]);

  return (
    <MultiSelectHolder
      onClear={() => handleChange({value: []})}
      title="Filter löschen"
    >
      <MultiSelect
        className="w3-border expand"
        value={selected}
        options={options || generatedOptions}
        onChange={handleChange}
        filter
        appendTo={document.body}
        emptyFilterMessage="keine Einträge"
      />
    </MultiSelectHolder>
  );
};

MultiSelectFilter.propTypes = {
  dt: PropTypes.shape({
    filter: PropTypes.func.isRequired
  }),
  field: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.array
};

export default React.memo(MultiSelectFilter);
