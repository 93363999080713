import React from 'react';
import PropTypes from 'prop-types';
import { WizardButtonPanel } from '../../../../../components/Buttons';

import { connect } from 'react-redux';
import { saveCardOrder } from '../../../actions/cards';
import { PRODUCT_ORDER_STATUS_LABELS, PageTitle, PRODUCT_ORDER_STATUS_IDS, } from '../../../../../components/Utils';
import ProductOrderOverview from '../../../components/ProductOrderOverview';

const mapStateToProps = (state) => {
  return {
    cardOrder: state.card.cardOrder
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveOrder: (...args) => dispatch(saveCardOrder(...args)),
});

class OrderOverview extends React.Component {
  static propTypes = {
    // mapped from state
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    cardOrder: PropTypes.object,
    cards: PropTypes.array,
    // actions
    saveOrder: PropTypes.func.isRequired,
    // rest
    isArchive: PropTypes.bool,
    readOnly: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  componentDidMount() {
    const { cardOrder, readOnly, isArchive } = this.props;
    if (!readOnly && !isArchive) {
      if (!cardOrder || !cardOrder.items || cardOrder.items.length === 0) {
        console.log('<< Data Models not set => go to previous page ...');
        this.onPrevious();
      }
    }
  }
  onPrevious = () => {
    const { history, match, onPrevious } = this.props;
    onPrevious && onPrevious({ history, match });
  }
  onNext = () => {
    const { cardOrder, history, match, onNext } = this.props;
    const onSuccess = () => onNext && onNext({ history, match });
    this.props.saveOrder({ cardOrder }, onSuccess)
  }
  onCancel = () => {
    const { history, match, onCancel } = this.props;
    onCancel && onCancel({ history, match });
  }
  renderNav = (className = 'margin-big-top w3-border pad-big w3-light-grey') => {
    const isNotEditable = this.props.isArchive || this.props.readOnly;
    return (
      <WizardButtonPanel
        className={className}
        onPrevious={!isNotEditable ? this.onPrevious : null}
        onNext={!isNotEditable ? this.onNext : null}
        onCancel={this.onCancel}
        nextLabel={`Speichern und senden`}
        cancelLabel={isNotEditable ? `Schließen` : 'Abbrechen'}
        disableIfAbsent={!isNotEditable}
      />
    );
  }
  render() {
    const {
      className,
      cardOrder,
      isArchive,
      readOnly
    } = this.props;
    console.log('OrderOverview.render()', cardOrder);
    if (!cardOrder) {
      return null;
    }
    const title = isArchive ? (
      <PageTitle>
        {/* <h3 className="no-margin">
          <div className="w3-right">{cardOrder.status || PRODUCT_ORDER_STATUS_LABELS[cardOrder.status_id]}</div>
          {`Bestellungsübersicht: ${cardOrder.orderNumber || ''}`}
        </h3> */}
        <div>
          <div className="w3-right">{this.renderNav('')}</div>
          <h3 className="no-margin">{`Bestellungsübersicht: ${cardOrder.orderNumber || ''}`}</h3>
        </div>
      </PageTitle>
    ) : (
      <div className="w3-border no-border-btm pad bg-secondary- w3-light-grey">
        <h3 className="no-margin pad-sm-top pad-lr">{`Bestellungsübersicht`}</h3>
      </div>
    );
    return (
      <div className={`card-catalog`}>
        <div className={`${className}`}>
          {title}
          <ProductOrderOverview
            className=''
            model={cardOrder}
            showUnit
          />
        </div>
        {this.renderNav()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
