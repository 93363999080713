import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
	SimpleAddress,
	PRODUCT_ORDER_STATUS_IDS,
} from "../../../components/Utils";
import HtmlText from "../../../components/HtmlText";
import ProductImageOverlayPanel from "./ProductImageOverlayPanel";
import AvisFileUploadItem from "./AvisFileUploadItem";

export default class ProductOrderOverview extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.shape({
			items: PropTypes.array,
			companyName: PropTypes.string,
			person: PropTypes.string,
			description: PropTypes.string,
			address: PropTypes.object,
		}),
		rows: PropTypes.number,
		isAdmin: PropTypes.bool,
		isArchive: PropTypes.bool,
		showUnit: PropTypes.bool,
		showAvis: PropTypes.bool,
		showAdminFiles: PropTypes.bool,
		uploadUrl: PropTypes.string,
	};
	static defaultProps = {
		className: "",
		model: {
			items: [],
			address: {},
			companyName: "",
			person: "",
			description: "",
		},
		uploadUrl: "/data-api/media/upload",
		rows: 10,
	};
	getAdminFiles = () => {
		const adminDeliveryPath = this.deliveryFileUpload
			? this.deliveryFileUpload.getFilePath()
			: null;
		const adminAvisPath = this.exchangeFileUpload
			? this.exchangeFileUpload.getFilePath()
			: null;
		if (!adminDeliveryPath && !adminAvisPath) {
			return null;
		}
		const { files } = this.props.model;
		return files
			? { ...files, adminDeliveryPath, adminAvisPath }
			: { adminDeliveryPath, adminAvisPath };
	};
	getAvisFile = () => {
		const avisPath = this.avisFileUpload
			? this.avisFileUpload.getFilePath()
			: null;
		if (!avisPath) {
			return null;
		}
		const { files } = this.props.model;
		return files ? { ...files, avisPath } : { avisPath };
	};
	render() {
		const {
			className,
			model,
			isAdmin,
			isArchive,
			showUnit,
			showAvis,
			showAdminFiles,
			uploadUrl,
		} = this.props;
		const { items, companyName, person, address, description, files } =
			model || {};
		if (!items || !address) {
			return null;
		}
		console.log("ProductOrderOverview.render()", items);
		const unitColumn = !showUnit ? null : (
			<Column field="productUnit" header="Einheit" style={{ width: "9em" }} />
		);
		let basicInfoMarkup = (
			<div className={`w3-row neg-margin-lr`}>
				<div className="w3-col s6 pad-lr">
					<h4 className="no-margin pad-btm w3-light-grey-">{`Lieferanschrift`}</h4>
					<div className="pad-top">
						<div className="pad-sm-top">{companyName}</div>
						<div className="pad-sm-top">{person}</div>
						<SimpleAddress address={address} />
					</div>
				</div>
				<div className="w3-col s6 pad-lr">
					<h4 className="no-margin pad-btm w3-light-grey-">{`Bestellvermerk`}</h4>
					<div className="">{description}</div>
				</div>
			</div>
		);
		const rowItems = [basicInfoMarkup];
		const { adminAvisPath, adminDeliveryPath, avisPath } = files || {};
		if (showAdminFiles) {
			rowItems.push(
				<AvisFileUploadItem
					ref={(ref) => (this.deliveryFileUpload = ref)}
					className="display-block"
					label="Lieferschein"
					uploadLabel="Lieferschein hochladen"
					emptyLabel={
						<span className="display-inline">
							<div>{"Kein"}</div>
							<div>{"Lieferschein"}</div>
						</span>
					}
					model={{ path: adminDeliveryPath }}
					uploadUrl={uploadUrl}
					// uploadUrl="/data-api/media/upload"
					accepted={[".pdf"]}
					readOnly={
						!isAdmin || model.status_id !== PRODUCT_ORDER_STATUS_IDS.SENT
					}
				/>
			);
			rowItems.push(
				<AvisFileUploadItem
					ref={(ref) => (this.exchangeFileUpload = ref)}
					className="display-block"
					name="upload"
					label="ubergabeprotokol"
					uploadLabel="Übergabeprotokol hochladen"
					emptyLabel={
						<span className="display-inline">
							<div>{"Kein"}</div>
							<div>{"Übergabeprotokol"}</div>
						</span>
					}
					model={{ path: adminAvisPath }}
					uploadUrl={uploadUrl}
					// uploadUrl="/data-api/media/upload"
					accepted={[".pdf"]}
					readOnly={
						!isAdmin || model.status_id !== PRODUCT_ORDER_STATUS_IDS.SENT
					}
				/>
			);
		}
		if (showAvis) {
			rowItems.push(
				<AvisFileUploadItem
					ref={(ref) => (this.avisFileUpload = ref)}
					className="display-block"
					label="Übergabe"
					emptyLabel={
						<span className="display-inline">
							<div>{"Keine"}</div>
							<div>{"Übergabe"}</div>
						</span>
					}
					model={{ path: avisPath }}
					uploadUrl={uploadUrl}
					// uploadUrl="/data-api/media/upload"
					accepted={[".pdf"]}
					readOnly={
						isArchive ||
						(!isArchive &&
							model.status_id !==
								PRODUCT_ORDER_STATUS_IDS.AWAITING_CONFIRMATION)
					}
				/>
			);
		}
		if (rowItems.length > 1) {
			basicInfoMarkup = rowItems.map((item, index) => {
				const style =
					index === 0 ? null : { width: "160px", paddingLeft: "10px" };
				return (
					<div key={index} className="w3-cell w3-cell-top" style={style}>
						{item}
					</div>
				);
			});
			basicInfoMarkup = <div className="w3-cell-row">{basicInfoMarkup}</div>;
		}
		//
		return (
			<div className={className}>
				<div className="w3-border no-border-btm pad-big">{basicInfoMarkup}</div>
				<DataTable
					value={items}
					// paginator={true}
					// rows={rows}
					rowClassName={() => ({ "pad-tb": true })}
				>
					<Column
						field="productNumber"
						header="VKDG-Bestell-Nr."
						sortable
						filter
						filterMatchMode="contains"
						style={{ textAlign: "center", width: "12em" }}
					/>
					<Column
						field="productName"
						header="VKDG-Bezeichnung"
						body={(item) => <ProductImageOverlayPanel model={item} />}
						sortable
						filter
						filterMatchMode="contains"
						style={{ width: "18em" }}
					/>
					<Column
						field="productComments"
						header="Zusatzbemerkung"
						body={(item) => <HtmlText text={item.productComments} />}
						sortable
						filter
						filterMatchMode="contains"
					/>
					<Column
						field="amount"
						header="Menge"
						body={(rowData) =>
							model.status_id > PRODUCT_ORDER_STATUS_IDS.PROCESSING
								? rowData.approved || 0
								: rowData.amount
						}
						style={{ width: "6em" }}
					/>
					{unitColumn}
				</DataTable>
			</div>
		);
	}
}
