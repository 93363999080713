import * as actionTypes from '../actions';
import { LOAD_CURRENT_USER_SESSION_SUCCESS } from '../../../redux/actions/auth';
import { parseTaskResults, CONNECTION_TASK_TYPES } from '../components/Utils';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';
// import isArray from 'lodash/isArray';

export const initialState = {
  adConnections: [],
  ptConnections: [],
  delphiConnections: [],

  currentADConnectionType: '',
  currentPTConnectionType: '',
  currentDelphiConnectionType: '',
  
  selectedADConnections: [],
  selectedPTConnections: [],
  selectedDelphiConnections: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // filter connections
    case actionTypes.FILTER_CONNECTIONS: {
      console.log('REDUX REDUCER - filtering', action.connectionType, 'connections by task', action.taskType, state.connections);
      const { connectionType, taskType } = action;
      return {
        ...state,
        [`current${connectionType}ConnectionType`]: taskType
      };
    }

    // setting selected connections
    case actionTypes.SET_SELECTED_AD_CONNECTION:
      return {
        ...state,
        selectedADConnections: action.selected
      };
    case actionTypes.SET_SELECTED_PT_CONNECTION:
      return {
        ...state,
        selectedPTConnections: action.selected
      };
    case actionTypes.SET_SELECTED_DEPLPHI_CONNECTION:
      return {
        ...state,
        selectedDelphiConnections: action.selected
      };

    // export AD-Connections, PT Connections, Delphi-Connections as CSV
    case actionTypes.EXPORT_ADCONNECTIONS:
    case actionTypes.EXPORT_PTCONNECTIONS:
    case actionTypes.EXPORT_DELPHICONNECTIONS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXPORT_ADCONNECTIONS_SUCCESS:
    case actionTypes.EXPORT_PTCONNECTIONS_SUCCESS:
    case actionTypes.EXPORT_DELPHICONNECTIONS_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `${action.connectionType}-Zugänge exportieren`,
        detail: `Die gewählte ${action.connectionType}-Zugänge wurden erfolgreich exportiert.`
      });
      const listName = `selected${action.connectionType}Connections`;
      return {
        ...state,
        loading: false,
        [listName]: [],
      };
    case actionTypes.EXPORT_ADCONNECTIONS_FAILURE:
    case actionTypes.EXPORT_PTCONNECTIONS_FAILURE:
    case actionTypes.EXPORT_DELPHICONNECTIONS_FAILURE:
      notifyError({
        summary: `${action.connectionType}-Zugänge exportieren`,
        detail: `Die gewählte ${action.connectionType}-Zugänge konnten nicht exportiert werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // close connection task
    case actionTypes.CLOSE_CONNECTION_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_CONNECTION_TASK_SUCCESS:
      notifySuccess({
        summary: `${action.connectionType}-Zugang abschliessen`,
        detail: `Der gewählte ${action.connectionType}-Zugang wurde erfolgreich abgeschlossen.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_CONNECTION_TASK_FAILURE:
      notifyError({
        summary: `${action.connectionType}-Zugang abschliessen`,
        detail: `Der gewählte ${action.connectionType}-Zugang konnte nicht abgeschlossen werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // delete connection task
    case actionTypes.DELETE_CONNECTION_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_CONNECTION_TASK_SUCCESS:
      notifySuccess({
        summary: `${action.connectionType}-Zugangsaufgabe löschen`,
        detail: `Der gewählte ${action.connectionType}-Zugangsaufgabe wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_CONNECTION_TASK_FAILURE:
      notifyError({
        summary: `${action.connectionType}-Zugang löschen`,
        detail: `Die gewählte ${action.connectionType}-Zugangsaufgabe konnte nicht gelöscht werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    default:
      return null;
  }
}
