import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PageHeightAuto, toUIDate } from '../../../components/Utils';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadHomeNews, loadWorkerNews } from '../actions';

const frontload = async (props) => {
  const { max, categoryName, isPrivate } = props;
  if (isPrivate) {
    await props.loadWorkerNews({ max, categoryName });
  } else {
    await props.loadHomeNews({ max, categoryName });
  }
};
const mapStateToProps = (state) => {
  return {
    teasers: state.news.teasers
  };
};
const mapDispatchToProps = (dispatch) => ({
  loadHomeNews: (...args) => dispatch(loadHomeNews(...args)),
  loadWorkerNews: (...args) => dispatch(loadWorkerNews(...args))
});

class TeaserList extends React.Component {
  static propTypes = {
    teasers: PropTypes.array,
    loadLatest: PropTypes.func,
    className: PropTypes.string,
    max: PropTypes.number,
    categoryName: PropTypes.string,
    isPrivate: PropTypes.bool
  }
  static defaultProps = {
    className: 'margin-big-top',
    max: null,
    categoryName: 'General'
  }
  renderDefault = (teaser) => {
    return (
      <Link to={`/news/${teaser.id}`} className="w3-block news-teaser-item w3-border pad-big alg-left">
        {teaser.image && (<img className="icon" src={teaser.image} alt="Teaser Bild"/>)}
        <h4 className="title">{teaser.title}</h4>
        <div className="date w3-border-top pad-top">{toUIDate(teaser.modified_date || teaser.publication_date)}</div> 
        {teaser.subtitle && (<div className="subtitle">{teaser.subtitle}</div>)}
      </Link>
    );
  }
  renderLink = (teaser) => {
    let body = {title: null, url: null, type: 'intern'};
    try {
      body = JSON.parse(teaser.body || '');
    } catch(error) {}
    // const link = body.type === 'extern'
    //   ? (<a href={body.url || '#'} className="w3-block pad-big-tb" target="_blank">{body.title || 'Weiter'}</a>)
    //   : (<Link to={body.url || '#'} className="w3-block pad-big-tb">{body.title || 'Weiter'}</Link>);
    const markup = (
      <div className="w3-block news-teaser-item w3-border pad-big alg-left">
        <h4 className="title">{teaser.title}</h4>
        <div className="date w3-border-top pad-top">{toUIDate(teaser.modified_date || teaser.publication_date)}</div>
        {teaser.subtitle && (<div className="subtitle">{teaser.subtitle}</div>)}
      </div>
    );
    return body.type === 'extern'
    ? (<a href={body.url || '#'} className="w3-block pad-big-tb-" target="_blank">{markup}</a>)
    : (<Link to={body.url || '#'} className="w3-block pad-big-tb-">{markup}</Link>);
  }
    return 
  render() {
    const { className, teasers } = this.props;
    console.log('TeaserList.render()', teasers);
    const markup = teasers.map((teaser, index) => {
      const markup = teaser.type_id === 2 ? this.renderLink(teaser) : this.renderDefault(teaser);
      return (<div key={index} className="w3-col m4 pad-lr pad-big-top">{markup}</div>);
    });
    return (
      <PageHeightAuto id="/nachrichten-teasers" className={`${className}`}>
        <div className="neg-margin-lr w3-row">{markup}</div>
      </PageHeightAuto>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(TeaserList)
);
