import * as actionTypes from '../actions/debitor';
import { openFile, notifySuccess, notifyError } from '../../../components/Utils';

export const initialState = {
  debitorTasks: [],
  debitorTaskType: '',
  selectedDebitors: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // filter debitors
    case actionTypes.FILTER_DEBITORS: {
      console.log('REDUX REDUCER - filtering debitors by task', action.taskType);
      const { taskType } = action;
      return {
        ...state,
        debitorTaskType: taskType
      };
    }
    // setting selected debitors
    case actionTypes.SET_SELECTED_DEBITORS:
      return {
        ...state,
        selectedDebitors: action.selected
      };

    // close debitor task
    case actionTypes.CLOSE_DEBITOR_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CLOSE_DEBITOR_TASK_SUCCESS:
      notifySuccess({
        summary: `Debitor bestätigen`,
        detail: `Der gewählte Debitor ${action.task.debitorKey} wurde erfolgreich bestätigt.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_DEBITOR_TASK_FAILURE:
      notifyError({
        summary: `Debitor bestätigen`,
        detail: `Der gewählte Debitor ${action.task.debitorKey} konnte nicht bestätigt werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // delete debitor task
    case actionTypes.DELETE_DEBITOR_TASK:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_DEBITOR_TASK_SUCCESS:
      notifySuccess({
        summary: `Debitoraufgabe löschen`,
        detail: `Die gewählte Debitoraufgabe wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_DEBITOR_TASK_FAILURE:
      notifyError({
        summary: `Debitoraufgabe löschen`,
        detail: `Die gewählte Debitoraufgabe konnte nicht gelöscht werden.`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    default:
      return null;
  }
}
