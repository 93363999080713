import React from 'react';
import PropTypes from 'prop-types';
import TaskModemOverviewContent from '../../components/task/TaskModemOverviewContent';

import { connect } from 'react-redux';
import { updateOrderDebitor } from '../../actions';

const mapStateToProps = (state) => {
  return {
    selectedDebitor: state.modem.selectedDebitor,
    newModems: state.modem.newModems,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateOrderDebitor: (...args) => dispatch(updateOrderDebitor(...args)),
}); 

class TaskModemOverview extends React.Component {
  static propTypes = {
    // mapped from state
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    // actions
    updateOrderDebitor: PropTypes.func.isRequired,
    // passed by parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  render() {
    // console.log('TaskModemOverview.render()', this.props)
    return (<TaskModemOverviewContent {...this.props}/>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskModemOverview);
