import { TriStateCheckbox } from "primereact/tristatecheckbox";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

const BooleanFilter = ({ dt, field, onChange }) => {
  const [checked, setChecked] = useState(null);

  const handleChange = useCallback((event) => {
    if (onChange) {
      onChange(field, event.value ? 1 : 0);
    } else {
      dt.filter(event.value, field, "custom");
    }
    setChecked(event.value);
  }, [dt, field]);

  return (
    <TriStateCheckbox value={checked} onChange={handleChange} />
  );
};

BooleanFilter.propTypes = {
  dt: PropTypes.shape({
    filter: PropTypes.func.isRequired
  }),
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default React.memo(BooleanFilter);
