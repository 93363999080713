import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { saveSubscription } from '../actions';
import SubscriptionForm from '../components/SubscriptionForm';
import { FormMenu } from '../../../components/Buttons';
import { scrollToTop } from '../../../components/Utils';

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveSubscription: (...args) => dispatch(saveSubscription(...args))
});

class NewsSubscriptionPanel extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    saveSubscription: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: ''
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = () => {
    const model = this.form.getModel();
    this.props.saveSubscription(model);
  }
  render() {
    const { className, user } = this.props;
    console.log('NewsSubscriptionPanel.render()', this.props);
    if (!user) return null;
    return (
      <div className={className}>
        <SubscriptionForm
          ref={ref => this.form = ref}
          model={user}/>
        <FormMenu
          className="neg-margin-big-lr neg-margin-big-btm margin-top w3-border-top pad-big w3-light-grey"
          style={{/* marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em' */}}
          onSave={this.onSave}
          cancelHidden
          saveBtnName="saveNewsSettingsBtn"/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsSubscriptionPanel);
