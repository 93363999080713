import React from 'react';
import Page from '../Page';

export default () => (
  <Page
    id="not-found"
    title="Not Found"
    description="This is embarrassing."
    noCrawl
  >
    <div className="container">
      <h3>Seite existiert nicht!</h3>
    </div>
  </Page>
);