import React from 'react';
import SlickSlider from 'react-slick';

const Slider = ({ className = '', config, children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...config
  };
  return (
    <div className={className}>
      <SlickSlider {...settings}>{children}</SlickSlider>
    </div>
  );
};

export default Slider;
