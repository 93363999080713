import React from "react";
import PropTypes from "prop-types";
import { Label } from "../../../components/Inputs";
import { SimpleTextEditor, ImageEditor } from "../../../components/RichTextEditor";
import HtmlText from "../../../components/HtmlText";


// export const buildSignature = ({ text, image }) => (`
//   <div style="display:flex; align-items:flex-start; gap:12px">
//     <div>${image}</div>
//     <div style="flex-grow:1">${text.split("<p>").join(`<p style="margin-block:0.2em">`)}</div>
//   </div>
// `);
export const buildSignature = ({ text, image }) => (`
  <table style="width:fit-content">
    <tbody>
      <tr>
        <td style="vertical-align:top;">
          ${image}
        </td>
        <td style="vertical-align:top;padding-left:16px">
          ${text.split("<p>").join(`<p style="margin-block:0.2em">`)}
        </td>
      </tr>
    </tbody>
  </table>
`);


export default class SignatureForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  }
  static defaultProps = {
    className: "",
    model: {
      text: "",
      image: "",
    },
  }
  constructor(props) {
    super(props);
    const model = this.getState(props.model);
    // console.log("SignatureForm()", props)
    this.state = {
      ...model,
      error: {
        text: null
      }
    };
  }
  componentDidMount() {
    console.log("SignatureForm.componentDidMount()", this.props, this.state);
  }
  componentDidUpdate() {
    console.log("SignatureForm.componentDidUpdate()", this.props, this.state);
  }
  getState = (model = {}) => {
    return {
      text: model.text,
      image: model.image,
    };
  }
  validate = (noValidate, noUpdate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (!noValidate && error[key] !== undefined) {
        const test = (model[key] === "");
        if (test) {
          error[key] = true;
          isValid = false;
        } else {
          error[key] = null;
        }
      }
    });
    if (!isValid) {
      if(!noUpdate) this.setState({ error });
      return null;
    }
    model.name = this.props.model.name;
    return model;
  }
  getModel = (noValidate, noUpdate) => {
    const model = this.validate(noValidate, noUpdate);
    return model;
  }
  reset = () => {
    this.setState(this.getState());
  }
  render() {
    const { className } = this.props;
    const { text, image, error } = this.state;
    // console.log("SignatureForm.render() text:", text, this.props)
    return (
      <div className={`${className} pos-rel`}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          <div>
            <Label htmlFor="image">{`Bild/Logo`}</Label>
            <ImageEditor
              id="image"
              className="w3-white"
              value={image}
              onChange={(event) => this.setState({
                image: event.target.value,
              })}
              rows={10}
            />
          </div>
          <div style={{flexGrow:1}}>
            <Label htmlFor="text">{`Text`}</Label>
            <SimpleTextEditor
              id="text"
              className="w3-white"
              value={text}
              onChange={(event) => this.setState({
                text: event.target.value,
                error: {
                  ...error,
                  text: (event.target.value === "")
                },
              })}
              showFonts
              rows={10}
            />
          </div>
        </div>
        <div className="margin-tb pad bold alg-center w3-light-grey">{"Vorshau"}</div>
        <div style={{display:"flex",justifyContent:"center"}}>
          <HtmlText text={buildSignature({ text, image })}/>
        </div>
        {/* <div className="w3-row neg-margin-lr">
          <div className="w3-col s2 pad-lr">
            <Label htmlFor="image">{`Bild/Logo`}</Label>
            <ImageEditor
              id="image"
              className="w3-white"
              value={image}
              onChange={(event) => this.setState({
                image: event.target.value,
              })}
              rows={10}
            />
          </div>
          <div className="w3-col s10 pad-lr">
            <Label htmlFor="text">{`Text`}</Label>
            <SimpleTextEditor
              id="text"
              className="w3-white"
              value={text}
              onChange={(event) => this.setState({
                text: event.target.value,
                error: {
                  ...error,
                  text: (event.target.value === "")
                },
              })}
              showFonts
              rows={10}
            />
          </div>
        </div> */}
      </div>
    );
  }
}
