import React from 'react';
import PropTypes from 'prop-types';
import { PageHeight60, scrollToTop } from '../../../components/Utils';
import NewsArticleForm from '../components/NewsArticleForm';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadArticle, saveArticle, cancelArticle } from '../actions';
import { PrimaryButton, FormMenu } from '../../../components/Buttons';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getArticle({ id });
};
const mapStateToProps = (state) => {
  return {
    isNewsAssistant: state.auth.isNewsAssistant,
    article: state.news.article
  };
};
const mapDispatchToProps = (dispatch) => ({
  getArticle: (...args) => dispatch(loadArticle(...args)),
  saveArticle: (...args) => dispatch(saveArticle(...args)),
  cancelArticle: (...args) => dispatch(cancelArticle(...args))
});

class NewsWizard extends React.Component {
  static propTypes = {
    isNewsAssistant: PropTypes.bool,
    article: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    categories: PropTypes.array,
    getArticle: PropTypes.func.isRequired,
    saveArticle: PropTypes.func.isRequired,
    cancelArticle: PropTypes.func.isRequired,
    className: PropTypes.string,
    categoryName: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    categoryName: 'general'
  }
  componentDidMount() {
    scrollToTop();
  }
  componentWillUnmount() {
    this.props.cancelArticle();
  }
  onSave = () => {
    const model = this.form.getModel();
    if (!model) {
      return;
    }
    // category
    let category_id = 1;
    switch(this.props.categoryName) {
      case 'Sales':
        category_id = 2; break;
      default:
        break;
    }
    model.category_id = category_id;
    console.log('saving ...', model);
    this.props.saveArticle(model, this.onCancel);
  }
  onCancel = () => {
    const { history, categoryName } = this.props;
    let url = '#';
    switch(categoryName) {
      case 'Sales':
        url = `/sales/news-feed`; break;
      default:
        url = `/organisation/news-service/beitraege`; break;
    }
    history.replace(url);
  }
  onFormUpdated = (isValid) => {
    // console.log('NewsWizard.onFormUpdated()', isValid);
    this.btnPanel && this.btnPanel.toggleDisabled(!isValid);
  }
  render() {
    const { className, isNewsAssistant, article } = this.props;
    if (!isNewsAssistant || !article) return null;
    return (
      <PageHeight60 id="/nachrichten">
        <NewsArticleForm
          ref={ref => this.form = ref}
          model={article}
          className={`w3-border no-border-top pad-big ${className}`}
          onUpdated={this.onFormUpdated}/>
        <FormMenu
          className="w3-border no-border-top pad-big w3-light-grey"
          style={{/* marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em' */}}
          onSave={this.onSave} onCancel={this.onCancel}
          saveBtnName="saveNewsArticleBtn"/>
      </PageHeight60>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(NewsWizard)
);
