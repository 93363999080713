import React from "react";
import PropTypes from "prop-types";
import Dashboard from "../../../components/Dashboard";
import InPageNavMenu from "../../../components/InPageNavMenu";
import {
  COMPANY_STATUS_ACTIONS,
  COMPANY_STATUS_IDS,
  COMPANY_STATUS_LABELS,
  createSortByName,
  isCompanyActivated,
  isContractor,
  isSpecialKDL,
  notifyError,
  PageHeight80,
  PageHeight30,
  PageHeightAuto,
  WizardTitle
} from "../../../components/Utils";
import { FormMenu } from "../../../components/Buttons";

import BasicDataForm from "../components/wizard/BasicDataForm";
import GroupForm from "../components/wizard/GroupForm";
import TechKeyForm from "../components/wizard/TechKeyForm";
import RegionListForm from "../components/wizard/RegionListForm";
import DebitorListForm from "../components/wizard/DebitorListForm";
import ContractGiverForm from "../components/wizard/ContractGiverForm";
import ContractorListForm from "../components/wizard/ContractorListForm";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadCompanyTypes,
  loadContractors,
  loadCompany,
  changeCompany,
  saveCompany,
  cancelCompany,
  exportSubCompanies,
} from "../actions";
import { loadKeys } from "../../Product/actions/keys";
import AdHocNewsRecipentListForm from "../components/wizard/AdHocNewsRecipientListForm";
import StatusForm from "../../../components/StatusForm";

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getCompanyTypes();
  await props.getContractors();
  await props.getTechkeys();
  await props.getCompany({ id, wizard: true });
};
const mapStateToProps = (state) => {
  return {
    // user: state.auth.user,
    isAdmin: state.auth.isAdmin,
    isCEO: state.auth.isCEO,
    isWorkerPlus: state.auth.isWorkerPlus,
    isWorkerLogisticsAssistant: state.auth.isWorkerLogisticsAssistant,
    types: state.company.wizardTypes,
    techKeys: state.key.catalogKeys,
    userCompany: state.company.company,
    company: state.company.wizardCompany,
    companyWorkers: state.worker.companyWorkers,
    companies: state.company.contractors// companies
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCompanyTypes: (...args) => dispatch(loadCompanyTypes(...args)),
  getContractors: (...args) => dispatch(loadContractors(...args)),
  getTechkeys: (...args) => dispatch(loadKeys(...args)),
  getCompany: (...args) => dispatch(loadCompany(...args)),
  changeCompany: (...args) => dispatch(changeCompany(...args)),
  saveCompany: (...args) => dispatch(saveCompany(...args)),
  cancelCompany: (...args) => dispatch(cancelCompany(...args)),
  exportSubCompanies: (...args) => dispatch(exportSubCompanies(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    // user: PropTypes.object,
    isAdmin: PropTypes.bool,
    isCEO: PropTypes.bool,
    isWorkerPlus: PropTypes.bool,
    isWorkerLogisticsAssistant: PropTypes.bool,
    types: PropTypes.array,
    techKeys: PropTypes.array,
    userCompany: PropTypes.object,
    company: PropTypes.object,
    companyWorkers: PropTypes.array,
    companies: PropTypes.array,
    regions: PropTypes.array,
    getCompanyTypes: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    changeCompany: PropTypes.func.isRequired,
    saveCompany: PropTypes.func.isRequired,
    cancelCompany: PropTypes.func.isRequired,
    exportSubCompanies: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: "",
    regions: ["1", "2", "3", "4", "7", "9"],
    companies: []
  }
  componentWillUnmount() {
    this.props.cancelCompany();
  }
  scrollIntoView = (element) => {
    if (!element) {
      return;
    }
    window.scrollTo(0, element.offsetTop);
  }
  getModel = (noValidate) => {
    const statusData = this.statusData.getModel();
    let basicData = this.basicData.getModel(noValidate);
    if (!noValidate && !basicData) {
      notifyError({
        summary: "Firmenprofil",
        detail: `Die Firma konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
      return window.scrollTo(0, this.basicData.offsetTop);
    }
    basicData = basicData || { name: "", type: "", address: {}, contractGivers: [] };
    const { name, type_id, address } = basicData;
    // groups
    const groups = !this.groupDaten ? [] : this.groupDaten.getModel(noValidate);
    // techKeys
    const techKeys = !this.techKeys ? [] : this.techKeys.getModel(noValidate);
    // regions
    let regions = (!this.regions ? [] : this.regions.getModel(noValidate)) || [];
    // debitors
    const debitors = !this.debitors ? null : this.debitors.getModel(noValidate) || [];
    if (regions && regions.length > 0 && debitors) {
      console.log("<< saving these debitors", debitors);
      debitors.forEach(debitor => {
        for (let index = 0; index < regions.length; index ++) {
          if (regions[index].name === debitor.region) {
            if (regions[index].debitors === undefined) {
              regions[index].debitors = [];
            }
            regions[index].debitors.push({ ...debitor });
          }
        }
      });
    }
    // contractGivers
    let contractGivers = basicData.contractGivers;
    if (this.contractGivers) {
      contractGivers = this.contractGivers.getModel();
    }
    // contractors
    let contractors = null;
    if (this.contractors) {
      contractors = this.contractors.getModel()
    }
    // ad-hoc news recipients
    let newsRecipients = null;
    if (this.newsRecipients) {
      newsRecipients = this.newsRecipients.getModel();
      if (!newsRecipients) {
        notifyError({
          summary: "Firmenprofil",
          detail: `Die Firma konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
        });
        return this.scrollIntoView(document.getElementById("adhoc-news-verteiler"));
        // return this.scrollIntoView(this.newsRecipients);
      }
    }
    const model = {
      name,
      type_id,
      address,
      groups,
      techKeys,
      regions,
      contractGivers,
      contractors,
      newsRecipients,
      status_id: statusData.status_id,
    };
    if ((this.props.match.params.id !== "neu") && this.props.company.id) {
      model.id = this.props.company.id;
    }
    return model;
  }
  onSave = () => {
    const model = this.getModel();
    if (!model) return;
    // console.log("Saving Model:", model);
    const { history, isWorkerLogisticsAssistant } = this.props;
    const onSuccess = () => history.replace(`/organisation/${isWorkerLogisticsAssistant ? "firma" : "firmen"}`);
    this.props.saveCompany(model, onSuccess);
  }
  onCancel = () => {
    // console.log("CompanyWizard.onCancel()");
    const { history, isWorkerLogisticsAssistant } = this.props;
    this.props.cancelCompany();
    history.replace(`/organisation/${isWorkerLogisticsAssistant ? "firma" : "firmen"}`);
  }
  onCompanyTypeChanged = (newType_id) => {
    const model = this.getModel(true);
    model.type_id = newType_id;
    this.props.changeCompany(model);
  }
  onRegionsChanged = (regions) => {
    this.debitors.setRegions(regions);
  }
  getRegionBaseData = () => {
    return {
      getAddress: () => this.basicData.getModel(true)
    }
  }
  onDebitorsChanged = (debitors) => {
    this.regions.setDebitors(debitors);
  }
  getDebitorBaseData = () => {
    return {
      regions: this.regions.getModel()
    };
  }
  renderMenu = (isContractor, isContractGiverVisible) => {
    let links = [
      { isVisible: true, to: "basisdaten", label: "Allgemeine Daten" },
      { isVisible: true, to: "gewerke", label: "Gewerke" },
    ];
    if (!isContractor) {
      links = links.concat([
        { isVisible: true, to: "technikerschlüssel", label: "Technikerschlüssel" },
        { isVisible: true, to: "regionen", label: "Regionen" },
        { isVisible: true, to: "debitoren", label: "Debitoren" },
      ]);
    }
    links.push({ isVisible: isContractGiverVisible, to: "auftraggeber", label: "Auftraggeber"});
    links.push({ isVisible: true, to: "adhoc-news-verteiler", label: "Ad-hoc News Verteiler" });
    if (!isContractor) {
      // links.push({ isVisible: true, to: "auftragnehmer", label: "Auftragnehmer (NAN)" });
      links.push({ isVisible: true, to: "subunternehmen", label: "Subunternehmen" });
    }
    return (
      <InPageNavMenu
        className="under-wizard-title"
        links={links}
      />
    );
  }
  render() {
    const {
      className, types, techKeys, userCompany, company, isAdmin,
      isCEO, isWorkerLogisticsAssistant, companyWorkers,
      regions, companies, match
    } = this.props;
    const id = match.params.id;
    if (!company || (id === "neu" && company.id != null) || !types) {
      return null;
    }
    const isCompanyContractor = isContractor(company);
    const isCompanySpecialKDL = isSpecialKDL(company);
    const isContractGiverVisible = isAdmin || (id === "neu" || company.id == null);
    const Menu = this.renderMenu(isCompanyContractor, isContractGiverVisible);
    
    // debitors
    let debitors = [];
    company.regions && company.regions.forEach(region => {
      if (region.debitors && region.debitors.length > 0) {
        debitors = debitors.concat(region.debitors);
      }
    });
    if (company.changedDebitors) {
      const newDebitors = [];
      debitors.forEach(item => {
        const found = company.changedDebitors.find(debitor => debitor.id === item.id);
        if (!found) {
          newDebitors.push(item);
        }
      });
      debitors = company.changedDebitors.map(item => ({
        ...item,
        readOnly: true
      })).concat(newDebitors);
    }

    // contractors
    const companyList = [ ...companies ].sort(createSortByName());
    // remove company name from the list of possible contractors
    const contractorList = companyList.filter(item => item.id !== company.id);

    return (
      <div className={`company-wizard clearfix ${className}`}>
        <WizardTitle
          type="company"
          model={company}
          menu={<FormMenu className="pad-big-top" onSave={this.onSave} onCancel={this.onCancel} saveBtnName="saveCompanyBtn2"/>}
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight80 className="">
              <PageHeight30
                id="basisdaten"
                className="basicData w3-border margin-big-top pad-big"
                isUnterWizardTitle
              >
                <StatusForm
                  ref={ref => this.statusData = ref}
                  className="status-form w3-right"
                  model={{ status_id: company.status_id }}
                  statusIds={COMPANY_STATUS_IDS}
                  statusLabels={COMPANY_STATUS_LABELS}
                  statusActions={COMPANY_STATUS_ACTIONS}
                  disabled={!isAdmin || (company.activeEmployees > 0 && isCompanyActivated(company))}
                />
                <h3 className="no-margin-top">Allgemeine Daten</h3>
                <BasicDataForm
                  ref={ref => this.basicData = ref}
                  model={company}
                  types={types}
                  userCompany={userCompany}
                  onCompanyTypeChanged={this.onCompanyTypeChanged}
                  disabled={isWorkerLogisticsAssistant}
                  isAdmin={isAdmin}
                  onlyServicePartner
                />
              </PageHeight30>
              <PageHeight30
                id="gewerke"
                className="groups w3-border margin-big-top pad-big"
                isUnterWizardTitle
              >
                <h3 className="no-margin-top">Gewerke</h3>
                <GroupForm
                  ref={ref => this.groupDaten = ref}
                  model={company.groups}
                  disabled={!isAdmin/* isWorkerLogisticsAssistant */}
                />
              </PageHeight30>
              {!isCompanyContractor && (
                <div>
                  <PageHeight30 
                    id="technikerschlüssel"
                    className="techKeys w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">Technikerschlüssel</h3>
                    <TechKeyForm
                      ref={ref => this.techKeys = ref}
                      model={company.techKeys}
                      techKeys={techKeys}
                      disabled={!isAdmin/* isWorkerLogisticsAssistant */}
                    />
                  </PageHeight30>
                  <PageHeight30
                    id="regionen"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <RegionListForm
                      className="regions"
                      ref={ref => this.regions = ref}
                      model={company.regions}
                      regions={regions}
                      debitors={debitors}
                      isAdmin={isAdmin}
                      getBaseData={this.getRegionBaseData}
                      onChange={this.onRegionsChanged}
                      isCompanySpecialKDL={isCompanySpecialKDL}
                      disabled={!isAdmin/* isWorkerLogisticsAssistant */}
                    />
                  </PageHeight30>
                  <PageHeight30
                    id="debitoren"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <DebitorListForm
                      className="debitors"
                      ref={(ref) => this.debitors = ref}
                      model={debitors}
                      regions={company.regions}
                      workers={companyWorkers}
                      getBaseData={this.getDebitorBaseData}
                      isCompanySpecialKDL={isCompanySpecialKDL}
                      contractors={company.contractors}
                      company={company}
                      getCompany={() => this.basicData.getModel(true)}
                      onChange={this.onDebitorsChanged}
                    />
                  </PageHeight30>
                </div>
              )}
              {isContractGiverVisible && (
                <PageHeightAuto
                  id="auftraggeber"
                  className="contractGivers w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <h3 className="no-margin-top">Auftraggeber</h3>
                  <ContractGiverForm
                    ref={ref => this.contractGivers = ref}
                    model={company}
                    companies={companyList}
                    userCompany={userCompany}
                    isAdmin={isAdmin}
                    disabled={isWorkerLogisticsAssistant}
                  />
                </PageHeightAuto>
              )}
              <PageHeight30
                id="adhoc-news-verteiler"
                className="w3-border margin-big-top pad-big"
                isUnterWizardTitle
              >
                {/* <h3 className="no-margin-top">Ad-hoc News Verteiler</h3> */}
                <AdHocNewsRecipentListForm
                  className="newsRecipients"
                  ref={ref => this.newsRecipients = ref}
                  model={company.newsRecipients}
                  disabled={!isCEO || isWorkerLogisticsAssistant}
                />
              </PageHeight30>
              {!isCompanyContractor && (
                <PageHeightAuto
                  id="subunternehmen"
                  className="w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <ContractorListForm
                    className="contractors"
                    ref={ref => this.contractors = ref}
                    model={company.contractors}
                    companies={contractorList}
                    disabled={isWorkerLogisticsAssistant}
                    getBaseData={() => ({
                      isAdmin,
                      isWorkerLogisticsAssistant,
                      types,
                      // userCompany,
                      getCompany: () => this.basicData.getModel(true),
                    })}
                    exportSubCompanies={this.props.exportSubCompanies}
                  />
                </PageHeightAuto>
              )}
              <FormMenu
                className="w3-border margin-big-top pad-big w3-light-grey"
                onSave={this.onSave}
                onCancel={this.onCancel}
                saveBtnName="saveCompanyBtn"
              />
            </PageHeight80>
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
