import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../components/W3Dropdown';
import { PrimaryButton, ButtonLink } from '../../../components/Buttons';
import PasswordResetConfirmationDialog from '../../Login/containers/PasswordResetConfirmationDialog';
import UsernameChangeForm from '../../Login/containers/UsernameChangeForm';

const MODETYPES = {
  DEFAULT: 1,
  RESETING_PASSWORD: 2,
  EDITING_USERNAME: 3
};

export default class BasicProfileDataMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.shape({}),
    isAdmin: PropTypes.bool,
  }
  static defaultProps = {
    className: ''
  }
  constructor() {
    super();
    this.state = {
      mode: MODETYPES.DEFAULT
    }
  }
  onResetPassword = (event) => {
    if (event) event.preventDefault();
    this.setState({ mode: MODETYPES.RESETING_PASSWORD });
  }
  onChangeUsername = (event) => {
    if (event) event.preventDefault();
    this.setState({ mode: MODETYPES.EDITING_USERNAME });
  }
  onHide = () => {
    this.setState({ mode: MODETYPES.DEFAULT });
  }
  renderPasswordReset = () => {
    return {
      title: (<h3 className="no-margin">{`Passwort zurücksetzen`}</h3>),
      body: (
        <PasswordResetConfirmationDialog
          user={this.props.user}
          onClose={this.onHide}
        />
      )
    };
  }
  renderUsernameEdit = () => {
    return {
      title: (<h3 className="no-margin">{`Namen ändern`}</h3>),
      body: (
        <UsernameChangeForm
          user={this.props.user}
          onClose={this.onHide}
        />
      )
    };
  }
  render() {
    const { className } = this.props;
    const { mode } = this.state;
    let data = null;
    switch(mode) {
      case MODETYPES.RESETING_PASSWORD:
        data = this.renderPasswordReset();
        break;
      case MODETYPES.EDITING_USERNAME:
        data = this.renderUsernameEdit();
        break;
      default:
        break;
    }
    const style = { width: '400px' };
    const dialog = data && (
      <Dialog
        header={(<div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>{data.title}</div>)}
        visible
        style={style}
        modal
        onHide={this.onHide}>
        {data.body}
      </Dialog>
    );
    const changeUsernameBtn = !this.props.isAdmin ? null : [
      <a key="cu0" href="#" className="w3-bar-item separator">
        <div className="w3-border-top" style={{margin: '0 -3px'}}/>
      </a>,
      <ButtonLink key="cu1" to="#"
        className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
        onClick={this.onChangeUsername}>
          {`Namen ändern`}
      </ButtonLink>
    ];
    return (
      <div className={className}>
        <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler as={PrimaryButton} size="small-">{`Optionen`}</W3DropdownToggler>
          <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '240px'}}>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onResetPassword}>
                {`Passwort zurücksetzen`}
            </ButtonLink>
            {changeUsernameBtn}
          </W3DropdownContent>
        </W3Dropdown>
        {dialog}
      </div>
    );
  }
}
