import React from 'react';
import PropTypes from 'prop-types';
import { InputText, Label } from '../../../../components/Inputs';
import { validateEmail } from '../../../../components/Utils';

export default class AdHocNewsRecipentForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    disabled: PropTypes.bool,
    hideLabels: PropTypes.bool,
    autoFocus: PropTypes.bool,
    namePrefix: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    model: {
      name: '',
      email: ''
    },
    namePrefix: "",
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  getState = (props) => {
    const { model } = props;
    return {
      name: model.name || '',
      email: model.email || '',
      error: {
        name: null,
        email: null
      }
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    let isValid = true;
    // name
    if (model.name === '') {
      error.name = true;
      isValid = false;
    }
    // email
    if (!validateEmail(model.email)) {
      error.email = true;
      isValid = false;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    const { id, index } = this.props.model;
    return { id, index, ...model };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  reset = () => {
    this.setState(this.getState({ model: {} }));
  }
  onChange = (key, value) => {
    this.setState({
      [key]: value,
      error: {
        [key]: value === '' ? true : null
      }
    });
  }
  render() {
    const { className, disabled, hideLabels, autoFocus, namePrefix } = this.props;
    const { name, email, error } = this.state;
    const errorName = error.name ? 'error-group' : '';
    const errorEmail = error.email ? 'error-group' : '';
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item- ${errorName}`}>
            {!hideLabels && (<Label htmlFor={`${namePrefix}recipient_name`}>{`Name`}</Label>)}
            <InputText
              id={`${namePrefix}recipient_name`}
              className="w3-block w3-border"
              value={name}
              onChange={(event) => this.onChange('name', event.target.value)}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item- ${errorEmail}`}>
            {!hideLabels && (<Label htmlFor={`${namePrefix}recipient_email`}>{`E-Mail`}</Label>)}
            <InputText
              id={`${namePrefix}recipient_email`}
              className="w3-block w3-border"
              value={email} 
              onChange={(event) => this.onChange('email', event.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export class InputValue extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dataKey: PropTypes.string,
    value: PropTypes.string,
    validate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hideLabels: PropTypes.bool,
    autoFocus: PropTypes.bool
  }
  static defaultProps = {
    className: '',
    dataKey: '',
    value: ''
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  getState = (props) => {
    return {
      value: props.value || '',
      error: null
    };
  }
  validate = () => {
    const { value } = this.state;
    let isValid = true;
    // value
    let error = false;
    if (!this.props.validate(value)) {
      error = true;
      isValid = false;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    return value;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  reset = () => {
    this.setState(this.getState({}));
  }
  onChange = (value) => {
    this.setState({
      value,
      error: !this.props.validate(value) ? true : null
    });
  }
  render() {
    const { className, dataKey, disabled } = this.props;
    const { value, error } = this.state;
    return (
      <div className={`${className} ${error ? 'error-group' : ''}`}>
        <InputText
          id={dataKey}
          className="w3-block w3-border"
          value={value}
          onChange={(event) => this.onChange(event.target.value)}
          readonly={disabled}
        />
      </div>
    );
  }
}
