import { loadOpenTasks } from '.';
import { STATUS_IDS, PRODUCT_ORDER_STATUS_LABELS, PRODUCT_ORDER_STATUS_IDS } from '../../../components/Utils';

export const LOAD_KEY_ORDERS = 'task/keys/orders/load';
export const LOAD_KEY_ORDERS_SUCCESS = 'task/keys/orders/load/success';
export const LOAD_KEY_ORDERS_FAILURE = 'task/keys/orders/load/failure';

export const FILTER_KEYS = 'tasks/keys/orders/filter';

export const SET_SELECTED_KEY_ORDERS = 'task/keys/orders/set';

export const CHANGE_KEY_ORDER_STATUS = 'task/keys/orders/status';
export const CHANGE_KEY_ORDER_STATUS_SUCCESS = 'task/keys/orders/status/success';
export const CHANGE_KEY_ORDER_STATUS_FAILURE = 'task/keys/orders/status/failure';

export const EXPORT_KEY_ORDERS = 'task/key/export';
export const EXPORT_KEY_ORDERS_SUCCESS = 'task/key/export/success';
export const EXPORT_KEY_ORDERS_FAILURE = 'task/key/export/failure';

export const DELETE_KEY_ORDER = 'tasks/keys/orders/delete';
export const DELETE_KEY_ORDER_SUCCESS = 'tasks/keys/orders/delete/success';
export const DELETE_KEY_ORDER_FAILURE = 'tasks/keys/orders/delete/failure';

export const CLOSE_KEY_ORDER = 'tasks/keys/orders/close';
export const CLOSE_KEY_ORDER_SUCCESS = 'tasks/keys/orders/close/success';
export const CLOSE_KEY_ORDER_FAILURE = 'tasks/keys/orders/close/failure';

export const CANCEL_KEY_ORDER = 'tasks/key/orders/cancel';


export const loadKeyOrderTasks = () => {
  return {
    types: [
      LOAD_KEY_ORDERS,
      LOAD_KEY_ORDERS_SUCCESS,
      LOAD_KEY_ORDERS_FAILURE
    ],
    promise: (client) => client.get(`/keys/orders/tasks`)
  }
};

export const filterKeys = (taskType) => {
  return { 
    type: FILTER_KEYS, 
    taskType 
  };
};

export const setSelectedKeyOrders = (selected) => {
  console.log(`REDUX-ACTION: setSelectedKeyOrders()`, selected);
  return {
    type: SET_SELECTED_KEY_ORDERS,
    selected
  };
};

export const lockKeyOrders = (selected) => {
  console.log(`REDUX-ACTION: lockKeyOrders() selected`, selected);
  return (dispatch, getState) => {
    const orders = selected || getState().task.selectedKeyOrders || [];
    const newOrders = orders.filter(item => (item.status_id === STATUS_IDS.NEW)).map(item => ({
      id: item.id,
      type_id: item.type_id,
      status: PRODUCT_ORDER_STATUS_LABELS[PRODUCT_ORDER_STATUS_IDS.PROCESSING],
      status_id: PRODUCT_ORDER_STATUS_IDS.PROCESSING
    }));
    console.log(`<< Changing the status of ${newOrders.length} orders`, newOrders);
    dispatch({
      types: [
        CHANGE_KEY_ORDER_STATUS,
        CHANGE_KEY_ORDER_STATUS_SUCCESS,
        CHANGE_KEY_ORDER_STATUS_FAILURE
      ],
      promise: (client) => client.post(`/fccShops/orders/changeStatus`, { data: newOrders }),
      onSuccess: () => dispatch(loadOpenTasks()),
      selected
    });
  };
};

export const exportKeyOrders = () => {
  console.log(`REDUX-ACTION: exportKeyOrders()`);
  return (dispatch, getState) => {
    const { selectedKeyOrders } = getState().task;
    const data = selectedKeyOrders.map(item => ({ id: item.id, orderNumber: item.orderNumber }));
    dispatch({
      types: [
        EXPORT_KEY_ORDERS,
        EXPORT_KEY_ORDERS_SUCCESS,
        EXPORT_KEY_ORDERS_FAILURE
      ],
      promise: client => client.post(`/fccShops/orders/load/productTypes/key/csv`, { data }),
    });
  }
};

export const closeKeyOrder = ({ keyOrder, saveOnly }, onSuccess) => {
  console.log(`REDUX-ACTION: closeKeyOrder()`, keyOrder);
  const companyId = keyOrder.companyId || 0;
  return {
    types: [
      CLOSE_KEY_ORDER,
      CLOSE_KEY_ORDER_SUCCESS,
      CLOSE_KEY_ORDER_FAILURE
    ],
    promise: (client) => client.put(`/fccShops/companies/${companyId}/productTypes/key/orders`, { data: keyOrder }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    keyOrder,
    saveOnly
  };
};

export const deleteKeyOrder = ({ keyOrder }, onSuccess) => {
  console.log(`REDUX-ACTION: deleteKeyOrder()`, keyOrder);
  return {
    types: [
      DELETE_KEY_ORDER,
      DELETE_KEY_ORDER_SUCCESS,
      DELETE_KEY_ORDER_FAILURE
    ],
    promise: client => client.del(`/keys/orders`, { data: keyOrder }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks());
      onSuccess && onSuccess();
    },
    keyOrder
  };
};

export const cancelKeyOrder = () => {
  return { type: CANCEL_KEY_ORDER };
};
