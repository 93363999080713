import React, { useState } from 'react';
// import PropTypes from "prop-types";
import PrimeCalendar from "./Calendar";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect';

export const Label = ({
  className = 'w3-block',
  children,
  htmlFor,
  noMargin = false,
}) => (
  <label
    className={`bold ${noMargin ? "" : "margin-sm-btm"} ${className}`}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

const InputTextModule = ({ forwardedRef, className, readonly, small, ...props }) => {
  if (readonly) {
    const { value, ...others } = props;
    return (<div {...others} className={`${className} w3-round- pad-`}>{value}</div>);
  }
  const pad = `pad${small ? '-lr pad-sm-tb' : ''}`;
  return (
    <input ref={forwardedRef} {...props} type="text" className={`${className} w3-border w3-block w3-round ${pad}`}/>
  );
};
export const InputText = React.forwardRef((props, ref) => {
  return <InputTextModule {...props} forwardedRef={ref} />;
});

export const InputTextHolder = ({ onClear, title, children }) => (
  <div className="inputtext-clear-icon-holder pos-rel expand">
    {children}
    <span
      className="inputtext-clear-icon clickable"
      onClick={() => onClear && onClear()}
      title={title || 'löschen'}
    >
      <i className="p-dropdown-clear-icon pi pi-times"/>
    </span>
  </div>
);

// Password
const PasswordModule = ({ forwardedRef, className, small, ...props }) => {
  const pad = `pad${small ? '-lr pad-sm-tb' : ''}`;
  return (
    <input ref={forwardedRef} {...props} type="password" className={`${className} w3-border w3-block w3-round ${pad}`}/>
  );
};
export const Password = React.forwardRef((props, ref) => {
  return <PasswordModule {...props} forwardedRef={ref} />;
});

// Calendar
export const Calendar = PrimeCalendar;


// Dropdown
const DropdownModule = ({ forwardedRef, className, options, readOnly, ...props }) => {
  if (readOnly) {
    return (
      <InputText
        className={`${className} pad w3-round`}
        readonly={readOnly}
        {...props}
      />
    );
  }
  return (
    <PrimeDropdown
      ref={forwardedRef}
      className={`${className} w3-border w3-block w3-round`}
      appendTo={document.body}
      options={options}
      filter={options.length > 5}
      {...props}
    />
  );
};
export const Dropdown = React.forwardRef((props, ref) => {
  return <DropdownModule {...props} forwardedRef={ref} />;
});

// CalendarRange
export const CalendarRange = ({ value, onChange, ...props }) => {
  const [dates, setDates] = useState(value || []);
  // check for two values
  const isComplete = (list) => {
    return (list && !list[1]) ? false : true;
  };

  const handleChange = (event) => {
    if (isComplete(event.value)) {
      // console.log('CalendarRange.handleChange()', event.value);
      if (onChange) {
        onChange(event);
      }
    }
    setDates(event.value);
  };
  return (
    <PrimeCalendar
      className="w3-block w3-border w3-round"
      value={dates}
      onChange={handleChange}
      selectionMode="range"
      showButtonBar
      readOnlyInput
      // numberOfMonths={3}
      // touchUI
      {...props}
    />
  );
};

// MultiSelect
export const MultiSelectHolder = ({ onClear, title, children }) => (
  <div className="pos-rel expand">
    {children}
    <span
      className="multiselect-clear-icon clickable"
      onClick={() => onClear && onClear()}
      title={title || 'löschen'}
    >
      <i className="p-dropdown-clear-icon pi pi-times"/>
    </span>
  </div>
);
const MultiSelectModule = ({ forwardedRef, className, onChange, clearTitle, readOnly, ...props }) => {
  if (readOnly) {
    return (
      <InputText
        className={`${className} pad w3-round`}
        readonly={readOnly}
        value={props.value}
      />
    );
  }
  return (
    <MultiSelectHolder
      onClear={() => onChange && onChange({ value: [] })}
      title={clearTitle}
    >
      <PrimeMultiSelect
        ref={forwardedRef}
        appendTo={document.body}
        {...props}
        onChange={onChange}
        className={`${className} w3-border expand w3-round`}
      />
    </MultiSelectHolder>
  );
};
export const MultiSelect = React.forwardRef((props, ref) => {
  return <MultiSelectModule {...props} forwardedRef={ref} />;
});
