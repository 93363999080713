import React from 'react';
import PropTypes from 'prop-types';
import { SelectButton } from 'primereact/selectbutton';
import { InputText, Label } from '../../../components/Inputs';
import { validateEmail, validateTelNumber, USER_CONTACT_OPTIONS } from '../../../components/Utils';

export default class BasicDataChangeFormContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }
  constructor(props) {
    super();
    const model = props.model || {};
    this.state = {
      email: model.email || '',
      cellNumber: model.cellNumber || '',
      preferredContactMethod: model.preferredContactMethod || 'cell_number',
      error: {
        cellNumber: null,
        email: null
      }
    }
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = '';
        isValid = false;
      }
    });
    // email
    model.email = model.email.trim();
    console.log('>> validating email ...', model.email, validateEmail(model.email));
    if (!validateEmail(model.email)) {
      error.email = 'ungültige E-Mail';
      isValid = false;
    }
    // cell number
    model.cellNumber = model.cellNumber.trim();
    console.log('>> validating cellNumber ...', model.cellNumber, validateTelNumber(model.cellNumber));
    if (!validateTelNumber(model.cellNumber)) {
    // if (model.cellNumber.indexOf('+') !== 0 || (model.cellNumber.indexOf('0') >= 0 && model.cellNumber.indexOf('0') < 2)) {
      error.cellNumber = 'ungültige Handynummer';
      isValid = false;
    }
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    // copy original data
    const { id, firstname, lastname, username } = this.props.model;
    return { ...model, id, firstname, lastname, username };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  submit = () => {
    const model = this.getModel();
    if (!model) return;
    // validate
    this.props.onSubmit(model);
  }
  render() {
    const { className } = this.props;
    const { email, cellNumber, preferredContactMethod, error } = this.state;
    return (
      <div className={className}>
        <div className={`${error.email && 'error-group'}`}>
          <Label htmlFor="email">{`E-Mail`}<span>*</span></Label>
          <InputText
            id="email"
            autoFocus
            className="" value={email}
            onChange={(event) => this.setState({email: event.target.value})}/>
        </div>
        <div className={`pad-top ${error.cellNumber && 'error-group'}`}>
          <Label htmlFor="cellNumber">{`Telefon (mobil)/Festnetz`}<span>*</span></Label>
          <InputText
            id="cellNumber" className="" value={cellNumber}
            onChange={(event) => this.setState({cellNumber: event.target.value})}/>
          {error.cellNumber && (<div className="pad-sm-top">{error.cellNumber}</div>)}
        </div>
        <div className={`pad-top`}>
          <Label htmlFor="contact">{`2FA`}</Label>
          <SelectButton id="contact" options={USER_CONTACT_OPTIONS} value={preferredContactMethod}
            onChange={(event) => this.setState({preferredContactMethod: event.value})}/>
        </div>
      </div>
    );
  }
}
