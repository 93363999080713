import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { InputText } from '../../../components/Inputs';
import { SearchIconButton, CancelIconButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { search, cancelSearch } from '../actions';

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  search: (...args) => dispatch(search(...args)),
  cancelSearch: (...args) => dispatch(cancelSearch(...args)),
});

class SearchForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    match: PropTypes.object,
    search: PropTypes.func,
    cancelSearch: PropTypes.func
  }
  constructor(props) {
    super(props);
    const keyword = props.match.params.id;
    this.state = { text: (keyword || '') };
  }
  componentDidMount() {
    this.field && this.field.focus();
  }
  componentWillUnmount() {
    // this.props.cancelSearch();
  }
  onReset = () => {
    this.setState({text: ''});
    this.field && this.field.focus();
  }
  onSearch = () => {
    const { text } = this.state;
    if (text === '') {
      return;
    }
    this.props.search({ text });
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`search-form w3-light-grey w3-border-bottom pad-big ${className}`}>
        <div className="container">
          <div className="w3-cell-row" style={{width: '600px', margin: '0 auto'}}>
            <div className="w3-cell w3-cell-middle">
              <div className="clearfix search-input-holder">
                <InputText
                  className="search-input no-corner-rht"
                  value={this.state.text}
                  ref={ref => this.field = ref}
                  onChange={(event) => this.setState({text: event.target.value})}
                  onKeyUp={(event) => (event.keyCode === 13) && this.onSearch()}
                />
                <CancelIconButton
                  className="reset-btn pad no-border"
                  style={{padding: '7px'}}
                  onClick={this.onReset}
                />
              </div>
            </div>
            <div className="w3-cell w3-cell-middle" style={{width: '56px'}}>
              <SearchIconButton className="pad w3-border w3-round no-border-lft no-corner-lft"
                onClick={this.onSearch}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(SearchForm)
);
