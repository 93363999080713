import React from 'react';

export const DEFAULT_USER_DEVICE = {
  device: '',
  serialNumber: '',
  companyName: '',
  userInCharge: '',
  userPrimary: '',
  userSecondary: '',
  userInChargeId: 0,
  userPrimaryId: 0,
  userSecondaryId: 0,
  isCalibrated: 0,
  comparismDevice: '',
  macAddress: '',
  lastCheckDate: '',
  nextCheckDate: '',
  modifyDate: '',
  locationText: '',
  account: '',
  options: [],
  regions: [],
  status_id: 0, // STATUS_IDS.NEW,
  action_id: 0
};

export const getDeviceDeleteMsg = (userDevice, isAdmin) => {
  const account = (userDevice.account && userDevice.account !== '') ? userDevice.account : null;
  let comment = `Das Messgerät wird unwiderruflich gelöscht!`;
  if (account) {
    comment = isAdmin ? (
      `Das Messgerät wird ohne anschließende Aufgabe unwiderruflich gelöscht!`
    ) : (
      `Ein Antrag auf Löschung des Messgerätes wurde eingestellt.`
    );
  }
  return (
    <div>
      <div>{`Wollen Sie das folgende Messgerät löschen?`}</div>
      <div className="pad-tb">
        {/* <div>{`${userDevice.device}`}</div> */}
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">{`Messgerät:`}</div>
          <div className="w3-col s8 pad-lr bold">{userDevice.device}</div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">{`Seriennummer:`}</div>
          <div className="w3-col s8 pad-lr bold">{userDevice.serialNumber}</div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">{`Hauptbenutzer:`}</div>
          <div className="w3-col s8 pad-lr bold">{userDevice.userPrimary}</div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">{`Firma:`}</div>
          <div className="w3-col s8 pad-lr bold">{userDevice.companyName}</div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s4 pad-lr">{`Account:`}</div>
          <div className="w3-col s8 pad-lr bold">{account || 'Keins'}</div>
        </div>
      </div>
      <div className="pad-top">
        <span className="bold margin-rht">{`Hinweis:`}</span>
        <span>{comment}</span>
      </div>
      {/* {(isAdmin && account) &&
        (<div className="pad-top">
          <span className="bold margin-rht">{`Hinweis:`}</span>
          <span>{`Das Messgerät wird ohne anschliende Aufgabe und unwiderruflich gelöscht!`}</span>
        </div>)
      } */}
    </div>
  );
};
