import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { Dropdown } from '../../../../components/Inputs';
import { cleanUpDropdownOptions } from '../../../../components/Utils';
// import { isContractor } from '../../../../components/Utils';

export default class ContractForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companies: PropTypes.array,
    userCompany: PropTypes.object,
    isAdmin: PropTypes.bool,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {},
    companies: []
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    // const { model } = props;
    // const contractGivers = (model || {}).contractGivers || [];
    const { userCompany } = props;
    const contractGiver = (userCompany || {}).id || '';
    return {
      contractGiver,
      error: {
        contractGiver: null
      }
    };
  }
  validate = () => {
    const { contractGiver, error } = this.state;
    // console.log('ContractGiverForm.validate()', contractGiver);
    let isValid = true;
    if (error.contractGiver !== undefined && contractGiver === '') {
      error.contractGiver = true;
      isValid = false;
    }
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    const model = [];
    const found = (this.props.companies || []).find(item => item.id === contractGiver);
    if (found) {
      model.push({ id: found.id, name: found.name });
    }
    // console.log('ContractGiverForm.validate()', contractGiver, found, model);
    return model;
  }
  getModel = () => {
    // console.log('ContractGiverForm.getModel()', this.props.model);
    const model = this.props.model.id
      ? ((this.props.model || {}).contractGivers || []).map(item => ({ id: item.id, name: item.name }))
      : this.validate();
    return model;
  }
  render() {
    const { className, companies, isAdmin, model, userCompany, disabled } = this.props;
    // console.log('ContractGiverForm.render()', companies, userCompany, isAdmin, model);

    // if editing, show the unchangeable list of contract givers
    if ((model || {}).id !== undefined) {
      const markup = ((model || {}).contractGivers || []).map((item, index) => {
        return (<label key={index} className="w3-block   pad-btm">{item.name}</label>);
      });
      return (<div className={className}>{markup}</div>);
    }

    // this code is executed when creating a new company(either by Admin or WorkerPlus)
    const { contractGiver, error } = this.state;

    // add user company to list if not present (sometimes the list is empty)
    if (userCompany) {
      const found = companies.find(company => company.id === userCompany.id);
      // console.log('ContractGiverForm: searching for', userCompany.id, 'in', companies, ' => found:', found);
      if (!found) {
        companies.push({ ...userCompany });
      }
    }
    const CompanyOptions = cleanUpDropdownOptions((companies || [])/* .filter(item => !isContractor(item)) */.map(company => ({
      label: company.name, value: company.id
    })));
    console.log("ContractGiverForm:", contractGiver, CompanyOptions);

    // // old: only admins could choose from the list of contract givers
    // const markup = isAdmin ? (
    //   <Dropdown id="contractGiver" className="w3-block w3-border" value={contractGiver} options={CompanyOptions}
    //     placeholder="Bitte auswählen" onChange={(event) => this.setState({contractGiver: event.value})}/>
    // ) : (
    //   <Label>{contractGiver}</Label>
    // );
    // new: WorkerPlus can also choose
    const markup = (
      <Dropdown
        id="contractGiver"
        className="w3-block w3-border"
        value={contractGiver}
        options={CompanyOptions}
        placeholder="Bitte auswählen"
        onChange={(event) => this.setState({contractGiver: event.value})}
        showClear
        disabled={disabled}
      />
    );
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr form-group-item ${error.contractGiver && 'error-group'}`}>
            {markup}
          </div>
        </div>
      </div>
    );
  }
}
