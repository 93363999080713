import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleImage } from '../../../components/Buttons';
import { extractObject } from '../../../components/Utils';

export default class DebitorItem extends React.Component {
  static propTypes = {
    debitor: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    selected: PropTypes.bool,
    tableView: PropTypes.bool,
    hideRegion: PropTypes.bool,
    isComplete: PropTypes.bool,
  }
  static defaultProps = {
    className: ''
  }
  renderTableView = () => {
    const { id, className, debitor, hideRegion } = this.props;
    const { region, debitorKey, name, debitorAddress/* , address, person  */} = debitor;
    console.log('>> DebitorItem.render() debitorAddress', debitorAddress);
    let address = debitorAddress || debitor.address || extractObject(debitor, 'address_');
    if (!address) {
      const { street, house, houseNumber, house_number, city, areaCode, areacode, area_code } = debitor;
      if (street) {
        address = { street, house, houseNumber, house_number, city, areaCode, areacode, area_code };
      }
    }
    const person = debitor.person || extractObject(debitor, 'contact_person_');
    let addressMarkup = (<div className="pad-sm-top">{`${name}`}</div>);
    if (address) {
      addressMarkup = (
        <div>
          <div className="pad-sm-top">{`${name}`}</div>
          <div className="pad-sm-top">{`${address.street} ${address.houseNumber || address.housenumber || address.house_number || address.house || ''}`}</div>
          <div className="pad-sm-top">{`${address.areaCode || address.areacode || address.area_code || ''} ${address.city || ''}`}</div>
        </div>
      );
    } 
  let personMarkup = (<span>{`-`}</span>);
    if (person && (person.firstname || person.lastname)) {
      personMarkup = (
        <div>
          <div className="pad-sm-top">{`${person.firstname || ''} ${person.lastname || ''}`}</div>
          {(person.cellNumber || person.cell_number) && (<div className="pad-sm-top"><span className="bold">Mobil: </span>{person.cellNumber || person.cell_number || ''}</div>)}
          {(<div className="pad-sm-top"><span className="bold">Telefon: </span>{person.telephoneNumber || person.telephone_number || ''}</div>)}
          <div className="pad-sm-top"><span className="bold">E-Mail: </span>{person.email || ''}</div>
          <div className="pad-sm-top"><span className="bold">E-Mail AVIS: </span>{person.email_avis || ''}</div>
        </div>
      );
    } 
    return (
      <div id={`debitor-item_${debitorKey}`} className={`debitor-item-table clearfix ${className}`}>
        <div className="neg-margin-lr w3-row">
          <div className="w3-col s6 pad-lr">
            <div className="neg-margin-lr w3-row">
              <div className="w3-col s5 pad-lr pad-sm-top bold">{'Debitor-Key:'}</div>
              <div className="w3-col s7 pad-lr pad-sm-top">{debitorKey}</div>
            </div>
            {!hideRegion && (<div className="neg-margin-lr w3-row">
              <div className="w3-col s5 pad-lr pad-sm-top bold">{'Region:'}</div>
              <div className="w3-col s7 pad-lr pad-sm-top">{region}</div>
            </div>)}
            <div className="neg-margin-lr w3-row">
              <div className="w3-col s5 pad-lr pad-sm-top bold">{'Ansprechpartner:'}</div>
              <div className="w3-col s7 pad-lr pad-sm-top">{personMarkup}</div>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="neg-margin-lr w3-row">
              <div className="w3-col s4 pad-lr pad-sm-top bold">{'Anschrift:'}</div>
              <div className="w3-col s8 pad-lr pad-sm-top-">{addressMarkup}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderDefaultView = () => {
    const { id, className, debitor, hideRegion, onClick, onDoubleClick, selected } = this.props;
    const { region, debitorKey, name, debitorAddress, isComplete } = debitor;
    console.log('>> DebitorItem.render() debitorAddress', debitorAddress);
    let address = debitorAddress || debitor.address || extractObject(debitor, 'address_');
    const bgClassName = `clickable ${selected ? 'bg-secondary' : 'w3-border w3-white'} ${selected ? '' : 'w3-hover-light-grey'}`;
    let checkImg = null;
    if (this.props.isComplete || isComplete) {
      const checkStyle = {
        position: 'absolute',
        top: '5px',
        right: '5px',
        width: '54px',
      };
      checkImg = (<img src={CheckCircleImage} alt="check" style={checkStyle}/>);
    }
    let addressMarkup = (<div className="pad-sm-top">{`${name}`}</div>);
    if (address) {
      addressMarkup = (
        <div>
          <div className="pad-sm-top">{`${name}`}</div>
          <div className="pad-sm-top">{`${address.street || ''} ${address.houseNumber || address.house || address.house_number || ''}`}</div>
          <div className="pad-sm-top">{`${address.areaCode || address.areacode || address.area_code || ''} ${address.city || ''}`}</div>
        </div>
      );
    } 
    return (
      <div id={`debitor-item_${debitorKey}`} className={`debitor-item clearfix ${className} ${bgClassName}`} onClick={() => onClick(debitor)}
        onDoubleClick={() => onDoubleClick(debitor)}>
        <div className="pad-big alg-lft clearfix" style={{lineHeight: '120%'}}>
          <h4 className="no-margin-btm-">{debitorKey}</h4>
          {!hideRegion && (<div className="pad-sm-top">
            <span className="bold">{`Region: `}</span>
            <span className="">{region}</span>
          </div>)}
          <div className="pad-sm-top" style={{fontWeight: 'normal'}}>{addressMarkup}</div>
        </div>
        {checkImg}
      </div>
    );
  }
  render() {
    const { debitor, tableView } = this.props;
    // console.log('DebitorItem.render()', debitor);
    if (!debitor) {
      return null;
    }
    return tableView ? this.renderTableView() : this.renderDefaultView();
  }
}
