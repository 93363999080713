import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
// // import { Calendar } from 'primereact/calendar';
// import Calendar from '../../../components/Calendar';
import { InputText, Label, Dropdown, Calendar } from '../../../components/Inputs';
import {
  // isWorkerPlus, isDeviceManager, isAdmin,
  toDBDate, toUIInputDate, toUIDate, createSortByLabel,
  PageHeight40,
  validateSerialNumber
} from '../../../components/Utils';

const createOptionList = (item) => ({
  label: `${item.firstname} ${item.lastname}`,
  value: item.id
});

const getNewStateValue = (parKey, chdkey, state, newValue, defaultValue) => {
  let result = state[key];
  if (newValue !== result) {
    result = defaultValue;
  }
  return result;
};

export default class UserDeviceForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    isLoading: PropTypes.bool,
    devices: PropTypes.array,
    deviceManagerCompanies: PropTypes.array,
    deviceManagers: PropTypes.array,
    companies: PropTypes.array,
    users: PropTypes.array,
    isAdmin: PropTypes.bool,
    currentWorker: PropTypes.object,
    reset: PropTypes.func.isRequired,
    getCompanies: PropTypes.func/* .isRequired */,
    getWorkers: PropTypes.func/* .isRequired */,
    onMacAddressChanged: PropTypes.func,
    optionalPrimaryUser: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {
      device: '',
      serialNumber: '',
      userInChargeCompanyId: 0,
      companyId: 0,
      userInCharge: '',
      userPrimary: '',
      userSecondary: '',
      userInChargeId: 0,
      userPrimaryId: 0,
      userSecondaryId: 0,
      isCalibrated: 0,
      comparismDevice: '',
      macAddress: '',
      lastCheckDate: '',
      nextCheckDate: '',
      modifyDate: '',
      locationText: '',
      options: [],
      regions: [],
    },
    devices: [],
    deviceManagerCompanies: [],
    deviceManagers: [],
    companies: [],
    users: []
  }
  constructor(props) {
    super(props);
    const { model, devices } = props;
    const optionList = this.getOptionList(devices, model.device);
    this.state = {
      device: model.device || '',
      serialNumber: model.serialNumber || '',
      userInChargeCompanyIsKDLFlag: null,
      userInChargeCompanyId: model.userInChargeCompanyId || 0,
      companyId: model.company_id || model.companyId || 0,
      userInChargeId: model.userInChargeId || 0,
      userPrimaryId: model.userPrimaryId || 0,
      userSecondaryId: model.userSecondaryId || 0,
      isCalibrated: model.isCalibrated,
      comparismDevice: model.comparismDevice || '',
      macAddress: model.macAddress || '',
      lastCheckDate: toUIInputDate(model.lastCheckDate),
      nextCheckDate: toUIDate(model.nextCheckDate),
      modifyDate: toUIDate(model.modifyDate),
      locationText: model.locationText || '',
      account: model.account || '',
      options: model.options || [],
      regions: model.regions || [],
      optionList,
      error: {
        device: null,
        serialNumber: null,
        userInChargeCompanyId: null,
        companyId: null,
        userInCharge: null,
        lastCheckDate: null,
        nextCheckDate: null,
        regions: null,
      }
    };
    if (!props.optionalPrimaryUser) {
      this.state.error.userPrimary = null;
    }
    console.log('UserDeviceForm.constructor()', this.state, props);
  }
  componentDidMount() {
    const {
      isAdmin, currentWorker, model, 
      deviceManagerCompanies, deviceManagers,
      getCompanies, getWorkers
    } = this.props;
    console.log('UserDeviceForm.componentDidMount()', isAdmin, currentWorker, deviceManagerCompanies, deviceManagers, model);
    const {
      userInChargeCompanyId,
      userInChargeId,
      companyId,
    } = this.state;
    // not admin
    const state = {
      userInChargeCompanyId,
      userInChargeId,
      companyId,
    };
    if (!isAdmin) {
      // set manager company name
      if (userInChargeCompanyId === 0 && deviceManagerCompanies.length === 1) {
        state.userInChargeCompanyId = deviceManagerCompanies[0].id;
      }
      // set device manager
      if (userInChargeId === 0 && deviceManagers.length === 1) {
        state.userInChargeId = deviceManagers[0].id;
      }
      console.log('UserDeviceForm.componentDidMount() new state', state);
      this.setState(state);
    }
    if (state.userInChargeCompanyId !== 0) {
      console.log(`>> fetching device user companies for device manager company ${userInChargeCompanyId} ...`);
      // this.setManagerCompany(state.userInChargeCompanyId, true);
      // getWorkers(
      //   {
      //     isManager: true,
      //     companyId: userInChargeCompanyId, // model.parent_company_id, //
      //   },
      //   () => getCompanies(
      //     { companyId: model.parent_company_id },
      //     // { companyId: userInChargeCompanyId },
      //     () => {
      //       if (companyId) {
      //         console.log(`>> fetching device users for company ${companyId} ...`);
      //         getWorkers({
      //           companyId,
      //           deviceManagerCompany: userInChargeCompanyId
      //         });
      //       }
      //     }
      //   )
      // );
      getWorkers(
        {
          isManager: true,
          companyId: state.userInChargeCompanyId
        },
        () => getCompanies(
          { companyId: state.userInChargeCompanyId },
          () => {
            if (companyId) {
              getWorkers({
                companyId: state.companyId,
                deviceManagerCompany: state.userInChargeCompanyId
              });
            }
          }
        )
      );
    }
  }
  componentWillUnmount() {
    console.log("UserDeviceForm.componentWillUnmount()")
    this.props.reset();
  }
  getOptionList = (devices, device) => {
    let optionList = null;
    if (devices && device !== '') {
      const found = (devices || []).find(item => (item.label === device));
      optionList = (found && found.options) ? found.options : null;
    }
    return optionList;
  }
  findUserAttributes = (users, value, attributeName, user) => {
    let found =  user && user.id === value;
    if (!found) found = users.find(item => item.id === value);
    if (found) {
      return {
        [attributeName]: `${found.firstname} ${found.lastname}`,
        [`${attributeName}Id`]: found.id
      };
    }
    return null;
  }
  validate = () => {
    const { error, optionList, userInChargeCompanyIsKDLFlag, ...otherProps } = this.state;
    let model = { ...otherProps };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined) {
        const invalid = (key === 'regions') ? (model[key].length === 0) : (model[key] === '');
        if (invalid) {
          error[key] = true;
          isValid = false;
          console.error('<< validation ERROR', key, model[key]);
        } else {
          error[key] = null;
        }
      }
    });
    // serial number
    if (!validateSerialNumber(model.serialNumber)) {
      error.serialNumber = true;
      isValid = false;
    } else {
      error.serialNumber = null;
    }
    // console.log('UserDeviceForm.validate()', model, isValid, error);
    this.setState({ error });
    if (!isValid) {
      return null;
    }

    const { deviceManagerCompanies, deviceManagers, users } = this.props;
    // device manager company
    const deviceManagerCompany = deviceManagerCompanies.find(item => item.id === model.userInChargeCompanyId);
    if (deviceManagerCompany) {
      model.userInChargeCompanyName = deviceManagerCompany.name || "";
    }
    // company
    const company = this.props.companies.find(item => item.id === model.companyId);
    if (company) {
      model.companyName = company.name || "";
      model.contractGiver = company.contractGiver || null;
    }
    // set users
    const userInCharge = this.findUserAttributes(deviceManagers, model.userInChargeId, 'userInCharge');
    if (userInCharge) {
      model = { ...model, ...userInCharge };
    }
    const userPrimary = this.findUserAttributes(users, model.userPrimaryId, 'userPrimary');
    if (userPrimary) {
      model = { ...model, ...userPrimary };
    }
    const userSecondary = this.findUserAttributes(users, model.userSecondaryId, 'userSecondary');
    if (userSecondary) {
      model = { ...model, ...userSecondary };
    }
    // dates
    model.lastCheckDate = toDBDate(model.lastCheckDate);
    model.nextCheckDate = toDBDate(model.nextCheckDate, 'DD.MM.YYYY'); // toDBDate(this.props.model.nextCheckDate, 'DD.MM.YYYY');
    model.modifyDate = this.props.model.modifyDate;
    //
    const { id, index, status_id, status } = this.props.model;
    return { id, index, status_id, status, oldStatusId: status_id, ...model };
    // console.log('>> saving device', { id, index, status_id, status, oldStatusId: status_id, ...model });
    // return null;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  setManagerCompany = (value, noInit) => {
    const {
      isAdmin, currentWorker, deviceManagerCompanies,
      getCompanies, getWorkers
    } = this.props;
    const state = {
      userInChargeCompanyId: value,
      userInChargeCompanyIsKDLFlag: null,
      error: {
        ...this.state.error,
        userInChargeCompanyId: isNaN(value) ? true : null
      }
    };
    if (!noInit && value !== this.state.userInChargeCompanyId) {
      state.companyId = 0;
      state.userPrimaryId = 0;
      state.userSecondaryId = 0;
    }
    let companyId = value;
    // // special case for admin user on the page Organisation/Userdevices (there is no currentWorker)
    // const managerCompany = deviceManagerCompanies.find(item => item.id === value);
    // if (isAdmin && !currentWorker && managerCompany) {
    //   // check if company is KDL or not
    //   const separatorIndex = managerCompany.name.indexOf("(");
    //   if (separatorIndex > 0) {
    //     // company is NOT KDL
    //     state.userInChargeCompanyIsKDLFlag = 0;
    //     const kdlName = managerCompany.name.substr(
    //       separatorIndex + 1,
    //       managerCompany.name.length - separatorIndex - 2
    //     );
    //     console.log("<< KDL Name:", kdlName);
    //     const kdlCompany = deviceManagerCompanies.find(item => item.name === kdlName);
    //     if (kdlCompany) {
    //       companyId = kdlCompany.id;
    //       console.log("<< KDL Id:", companyId);
    //     }
    //   } else {
    //     state.userInChargeCompanyIsKDLFlag = 1;
    //   }
    // }
    this.setState(state);
    getWorkers(
      { isManager: true, companyId: value },
      () => getCompanies({ companyId })
    );
  }
  setCompany = (value) => {
    const { getWorkers } = this.props;
    this.setState({
      companyId: value,
      error: {
        ...this.state.error,
        companyId: isNaN(value) ? true : null,
      }
    });
    getWorkers({
      companyId: value,
      deviceManagerCompany: this.state.userInChargeCompanyId
    });
  }
  setDevice = (value) => {
    const optionList = this.getOptionList(this.props.devices, value);
    this.setState({
      device: value,
      optionList,
      error: {
        ...this.state.error,
        device: (value === '')
      }
    })
  }
  onOptionCheckboxChange = (event) => {
    let options = [ ...this.state.options ];
    if(event.checked) {
      options.push(event.value);
    } else {
      options.splice(options.indexOf(event.value), 1);
    }
    this.setState({ options });
  }
  setLastCheckDate = (value) => {
    const state = {
      lastCheckDate: value,
      error: {
        ...this.state.error,
        lastCheckDate: (value === '')
      }
    };
    const lastCheckDate = moment(value);
    const device = this.props.devices.find(item => item.label === this.state.device);
    if (device && lastCheckDate.isValid()) {
      let add = parseInt(device.checkInterval);
      if (isNaN(add)) add = 24; 
      state.nextCheckDate = lastCheckDate.add(add, 'months').format('DD.MM.YYYY');
      state.error.nextCheckDate = '';
      // console.log('>> UserDeviceForm.setLastCheckDate()', value, device, state);
    }
    this.setState(state);
  }
  onMacAddressChanged = (value) => {
    const { onMacAddressChanged } = this.props;
    this.setState({ macAddress: value });
    onMacAddressChanged && onMacAddressChanged(value);
  }
  onRegionCheckboxChange = (event) => {
    let regions = [ ...this.state.regions ];
    if(event.checked) {
      regions.push(event.value);
    } else {
      regions.splice(regions.indexOf(event.value), 1);
    }
    this.setState({
      regions,
      error: {
        ...this.state.error,
        regions: (regions.length === 0)
      }
    });
  }
  renderRegions = (regions, holderClassName = '', className = '') => {
    const createCheckbox = (prefix) => (value, index) => (
      <div key={index} className={`w3-col ${className}`}>
        <div className="p-col-12">
          <Checkbox inputId={`${prefix}${index}`} value={value} onChange={this.onRegionCheckboxChange}
            checked={regions.includes(value)}>
          </Checkbox>
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
        </div>
      </div>
    );
    const regionList = [ 1, 2, 3, 4, 7, 9 ];
    const items = (regionList || []).map(createCheckbox('refDeviceCb-'));
    return (
      <div className={holderClassName}>
        <Label>{`Regionen*`}</Label>
        <div className="neg-margin-lr" style={{color: '#000 !important'}}>
          <div className="w3-row devicesRegionCheckbox">{items}</div>
        </div>
      </div>
    );
  }
  render() {
    const {
      className, devices,
      deviceManagerCompanies, deviceManagers,
      users, companies, isAdmin,
      optionalPrimaryUser
    } = this.props
    const {
      device, serialNumber, companyId, userInChargeCompanyId, userInChargeCompanyIsKDLFlag,
      userInChargeId, userPrimaryId, userSecondaryId, comparismDevice,
      isCalibrated, macAddress, lastCheckDate, nextCheckDate, modifyDate, locationText, account,
      options, optionList, regions, error,
    } = this.state;
    const DeviceOptions = devices.map(item => ({ label: item.label, value: item.label }));
    if (isAdmin && deviceManagerCompanies.length === 0) { // admin and device manager companies not yet loaded
      console.log('UserDeviceForm: no render ...');
      return null;
    }
    // console.log("UserDeviceForm.render()\n>> props", this.props, '\>> state', this.state);

    // device manager
    const deviceManagerCompanyOptions = deviceManagerCompanies.map(item => ({
      label: item.name,
      value: item.id
    })).sort(createSortByLabel());
    const deviceManagerOptions = deviceManagers.map(createOptionList).sort(createSortByLabel());

    // user options
    const UserOptions = users.map(createOptionList).sort(createSortByLabel());
    const PrimaryUserOptions = [{ label: 'Keiner', value: 0 }].concat(UserOptions);
    const SecondaryUserOptions = UserOptions.length === 0 ? [] : [{ label: 'Keiner', value: 0 }].concat(
      UserOptions.filter(item => item.value !== userPrimaryId)
    );
    // companyId options
    let CompanyOptions = companies.map(item => ({ label: item.name, value: item.id }));
    // if device manager company (userInChargeCompanyId) is not a KDL,
    // then they can only manage devices for THEIR company
    if (isAdmin && userInChargeCompanyIsKDLFlag === 0) {
      CompanyOptions = CompanyOptions.filter(item => item.value === userInChargeCompanyId);
    }
    // console.log("UserDeviceForm::", this.state)

    // options
    const createCheckbox = (prefix) => (value, index) => (
      <div key={index} className="w3-col s4">
        <div className="p-col-12">
          <Checkbox
            inputId={`${prefix}${index}`}
            value={value}
            onChange={this.onOptionCheckboxChange}
            checked={options.includes(value)}
          >
          </Checkbox>
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">{value}</label>
        </div>
      </div>
    );
    const optionsMarkup = !optionList ? null : optionList.map(createCheckbox('usrDev-'));
    // companyInCharge
    const companyInChargeMarkup = !isAdmin ? null : ( // currentWorker ? null : (
      <div className={`pad-top form-group-item ${error.userInChargeCompanyId && 'error-group'}`}>
        <Label htmlFor="userInChargeCompanyId">{`Firma des Verantwortlichen*`}</Label>
        <Dropdown
          id="userInChargeCompanyId"
          className="w3-block w3-border"
          value={userInChargeCompanyId}
          placeholder="Bitte auswählen"
          options={deviceManagerCompanyOptions}
          onChange={(event) => this.setManagerCompany(event.value)}
          showClear
        />
      </div>
    );
    return (
      <PageHeight40 className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className="neg-margin-lr w3-row pad-top-">
              <div className={`w3-col s6 pad-lr form-group-item ${error.device && 'error-group'}`}>
                <Label htmlFor="device">{`Messgerät*`}</Label>
                <Dropdown
                  id="device"
                  className="w3-block w3-border"
                  value={device}
                  placeholder="Bitte auswählen"
                  options={DeviceOptions}
                  onChange={(event) => this.setDevice(event.value)}
                  showClear
                />
              </div>
              <div className={`w3-col s6 pad-lr form-group-item ${error.serialNumber && 'error-group'}`}>
                <Label htmlFor="serialNumber">{`Seriennummer*`}</Label>
                <InputText
                  id="serialNumber"
                  className="w3-block w3-border"
                  value={serialNumber}
                  onChange={(event) => this.setState({
                    serialNumber: event.target.value,
                    error: { ...error, serialNumber: (event.target.value === '') }
                  })}
                />
              </div>
            </div>
            {companyInChargeMarkup}
            <div className={`pad-top form-group-item ${error.userInChargeId && 'error-group'}`}>
              <Label htmlFor="userInChargeId">{`Verantwortlich*`}</Label>
              <Dropdown
                id="userInChargeId"
                className="w3-block w3-border"
                value={userInChargeId}
                placeholder="Bitte auswählen"
                options={deviceManagerOptions}
                onChange={(event) => this.setState({userInChargeId: event.value})}
                showClear
              />
            </div>
            <div className={`pad-top form-group-item ${error.companyId && 'error-group'}`}>
              <Label htmlFor="companyId">{`Firma*`}</Label>
              <Dropdown
                id="companyId"
                className="w3-block w3-border"
                value={companyId}
                placeholder="Bitte auswählen"
                options={CompanyOptions}
                onChange={(event) => this.setCompany(event.value)}
                showClear
              />
            </div>
            <div className="neg-margin-lr w3-row">
              <div className="w3-col s6 pad-lr pad-top form-group-item">
                <Label htmlFor="userPrimary">{`Hauptbenutzer${optionalPrimaryUser ? '' : '*'}`}</Label>
                <Dropdown
                  id="userPrimary"
                  className="w3-block w3-border"
                  value={userPrimaryId}
                  placeholder="Bitte auswählen"
                  options={PrimaryUserOptions}
                  onChange={(event) => this.setState({userPrimaryId: event.value})}
                  showClear
                />
              </div>
              <div className="w3-col s6 pad-lr pad-top form-group-item">
                <Label htmlFor="userSecondary">{`Zweiter Benutzer (Vertreter)`}</Label>
                <Dropdown
                  id="userSecondary"
                  className="w3-block w3-border"
                  value={userSecondaryId}
                  placeholder="Keiner"
                  options={SecondaryUserOptions}
                  onChange={(event) => this.setState({userSecondaryId: event.value})}
                  showClear
                />
              </div>
            </div>
            <div className="pad-top form-group-item">
              <Label>{'Ist ein kalibriertes Messgerät?'}</Label>
              <div className="w3-row">
                <div className="w3-col s6">
                  <div className="p-col-12">
                    <RadioButton
                      inputId={`ch0`}
                      name="isCalibrated"
                      value={1}
                      checked={isCalibrated === 1}
                      onChange={(event) => this.setState({isCalibrated: event.value})}
                    />
                    <label htmlFor={`ch0`} className="p-radiobutton-label">{`Ja`}</label>
                  </div>
                </div>
                <div className="w3-col s6">
                  <div className="p-col-12">
                    <RadioButton
                      inputId={`ch1`}
                      name="isCalibrated"
                      value={0}
                      checked={isCalibrated === 0}
                      onChange={(event) => this.setState({isCalibrated: event.value})}
                    />
                    <label htmlFor={`ch1`} className="p-radiobutton-label">{`Nein`}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pad-top">
              <Label htmlFor="comparismDevice">{`Geprüft mit kalibriertem Vergleichsgerät`}</Label>
              <InputText
                id="comparismDevice"
                className="w3-block w3-border"
                value={comparismDevice}
                onChange={(event) => this.setState({comparismDevice: event.target.value})}/>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="pad-top- form-group-item">
              <Label id="options">{`Optionen`}</Label>
              {optionsMarkup}
            </div>
            <div className={`pad-top form-group-item ${error.lastCheckDate && 'error-group'}`}>
              <Label htmlFor= "lastCheckDate">{`Geprüft am*`}</Label>
              <Calendar
                id="lastCheckDate"
                className="w3-block w3-border"
                value={lastCheckDate}
                readOnlyInput={true}
                dateFormat="dd.mm.yy"
                maxDate={new Date()}
                disabled={device === ''}
                onChange={(event) => this.setLastCheckDate(event.value)}
                showButtonBar
              />
            </div>
            <div className={`pad-top form-group-item ${error.nextCheckDate && 'error-group'}`}>
              <Label htmlFor="nextCheckDate">{`Prüfung fällig am*`}</Label>
              <InputText
                id="nextCheckDate"
                className="w3-block w3-border"
                value={nextCheckDate}
                disabled
              />
            </div>
            <div className="pad-top form-group-item">
              <Label htmlFor="modifyDate">{`Letzte Änderung`}</Label>
              <InputText
                id="modifyDate"
                className="w3-block w3-border"
                value={modifyDate}
                disabled
              />
            </div>
            <div className="pad-top form-group-item">
              <Label htmlFor="locationText">{`Standort/Messabweichungen (250 Zeichen)`}</Label>
              <InputTextarea
                id="locationText"
                className="w3-block w3-border"
                value={locationText}
                onChange={(event) => this.setState({locationText: event.target.value})}
                autoResize
                style={{ height: '111px'}}
              />
            </div>
            {
              this.renderRegions(
                regions,
                `pad-top form-group-item ${error.regions && 'error-group'}`,
                's2 pad-lr'
              )
            }
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-top pad-lr">
            <Label htmlFor="macAddress">{`CM1 MAC`}</Label>
            <InputText
              id="macAddress"
              className="w3-block w3-border"
              value={macAddress}
              onChange={(event) => this.onMacAddressChanged(event.target.value)}
              />
          </div>
          <div className="w3-col s6 pad-top pad-lr">
            <Label htmlFor="account">{`Account`}</Label>
            <InputText
              id="account"
              className="w3-block w3-border"
              value={account}
              disabled
            />
          </div>
        </div>
        {/* <div className={`w3-modal w3-${this.props.isLoading ? 'show' : 'hide'}`}/> */}
      </PageHeight40>
    );
  }
}
